import EnvironmentConfig from 'global/environments/environment';

const stagingConfig: EnvironmentConfig = {
  firebaseConfig: {
    apiKey: process.env.REACT_APP_FIREBASE_STAGING_API_KEY as string,
    authDomain: 'akooda-stage.firebaseapp.com',
    databaseURL: 'https://akooda-stage.firebaseio.com',
    projectId: 'akooda-stage',
    storageBucket: 'akooda-stage.appspot.com',
    messagingSenderId: '165810250936',
    appId: '1:165810250936:web:9ffb8fcf2f4aa016ddc04d',
    measurementId: 'G-4BRYEFPRVJ',
  },
  apiBaseUrl: 'https://api-stage.akooda.co/api/v1',
  appUrl: process.env.REACT_APP_BASE_URL as string,
  segmentWriteKey: process.env.SEGMENT_STAGING_WRITE_KEY as string,
};

export default stagingConfig;
