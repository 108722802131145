import { useAsync } from 'react-use';

import Api from 'global/api/platformApi';
import useTenantContext from 'screens/platform/cross-platform-components/context/tenant/TenantContext';

export default function useAkoodaiIntegration() {
  const { tenant } = useTenantContext();
  const {
    value: isAkoodaiIntegrationAvailable,
    loading: isAkoodaiConfigurationLoading,
  } = useAsync(async () => {
    const response = await Api.Tenant.AkoodaiApproval.getAkoodaiApprovalStatus(tenant.id);
    return response?.data;
  });

  return { isAkoodaiIntegrationAvailable, isAkoodaiConfigurationLoading };
}
