import { MetadataReducerStateType } from 'screens/platform/cross-platform-components/context/metadata/MetadataReducer';
import { PlatformReducerStateType } from 'screens/platform/cross-platform-components/context/platform/PlatformReducer';
import DebuggerConsole from 'utils/DebuggerConsole';

export default class ReducersHelper {
  static initialize(state: PlatformReducerStateType | MetadataReducerStateType, payload) {
    try {
      let curr = '';
      // eslint-disable-next-line no-return-assign
      if (Object.keys(payload).every((key) => Object.keys(state).includes(curr = key))) {
        return { ...state, ...payload };
      }
      throw new Error(`Invalid platform state initialization: ${curr} isn't a state member`);
    } catch (err) {
      DebuggerConsole.error(err);
    }
    return state;
  }
}
