import classNames from 'classnames';
import React, { useMemo, useState } from 'react';

import Button from 'common-ui-components/Button';
import Caret from 'common-ui-components/Caret';
import Dropdown from 'common-ui-components/Dropdown';
import StaticConfigLabel from 'common-ui-components/DynamicTitle/StaticConfigLabel';
import Tooltip from 'common-ui-components/Tooltip';
import { ListOptions } from 'global/ListOptions';
import Size from 'global/lists/Size';

import style from 'common-ui-components/DynamicTitle/style.module.scss';

interface Props<T> {
  value: T;
  setValue: (nextValue: T) => void;
  options: ListOptions<T>;
  tooltip: string | null;
  showCaret?: boolean;
  buttonClassName?: string;
}

export default function InlineOptionsDropdown<T>({
  value,
  setValue,
  options,
  tooltip,
  showCaret,
  buttonClassName,
}: Props<T>) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const currentValueLabel = useMemo(
    () => options.find((option) => option.value === value)?.label,
    [value, options],
  );

  return (options.length > 1
    ? (
      <Dropdown
        dropdownClassName={style.verticalDropdown}
        isDropdownOpen={isDropdownOpen}
        closeDropdown={() => setIsDropdownOpen(false)}
        placement="bottom-start"
        toggleButtonElement={(
          <Tooltip content={tooltip}>
            <Button
              aria-label={`dropdown-button-${currentValueLabel}`}
              className={classNames(style.config, buttonClassName)}
              onClick={() => setIsDropdownOpen((prev) => !prev)}
            >
              {currentValueLabel}
              &nbsp;
              {showCaret && <Caret isOpen={isDropdownOpen} size={Size.SMALL} />}
            </Button>
          </Tooltip>
          )}
      >
        {options.map((option) => (
          <Button
            key={option.label}
            color="gray"
            className={classNames(style.option, option.value === value && style.selected)}
            onClick={() => {
              setValue(option.value);
              setIsDropdownOpen(false);
            }}
          >
            {option.label}
          </Button>
        ))}
      </Dropdown>
    ) : (
      <StaticConfigLabel
        label={options[0]?.label}
        tooltip={tooltip}
        className={buttonClassName}
      />
    ));
}
