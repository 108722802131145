export enum SlackSubDivisionId {
  SLACK_PROFESSIONAL_CHANNELS = '101-1',
  SLACK_SOCIAL_CHANNELS = '101-2',
  SLACK_BOT_CHANNELS = '101-3',
  SLACK_PRIVATE_CHANNELS = '101-4',
}

export const SlackSubDivisions = {
  [SlackSubDivisionId.SLACK_PROFESSIONAL_CHANNELS]: 'Slack Professional',
  [SlackSubDivisionId.SLACK_SOCIAL_CHANNELS]: 'Slack Social',
  [SlackSubDivisionId.SLACK_BOT_CHANNELS]: 'Slack Integrations',
  [SlackSubDivisionId.SLACK_PRIVATE_CHANNELS]: 'Slack Private Channels',
};

export function isSlackSubDivision(appId: string): boolean {
  return Object.values<string>(SlackSubDivisionId).includes(appId);
}
