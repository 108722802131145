import { useMemo } from 'react';

import { App, hasChannels } from 'global/lists/apps';

export default function useChannelDisplayName(
  appId: App,
  channelName: string | null,
  isPublic: boolean,
  id: string,
) {
  return useMemo(() => {
    if (!hasChannels(appId)) return null;

    if (appId === App.ATLASSIAN_JIRA) return id;

    return appId === App.SLACK ? `#${channelName}` : channelName;
  }, [appId, channelName, isPublic, id]);
}
