import React, { useEffect, useRef, useState } from 'react';

import ActionableBadge from 'common-ui-components/ActionableBadge/index';
import {
  useMasterFilters,
} from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFiltersContext';
import {
  HierarchicalItemType,
} from 'screens/platform/cross-platform-components/HierarchicalSearchInput/HierarchicalItemTypeConfig';
import FilterMenu from 'screens/platform/cross-platform-components/MasterFiltersPanel/FilterMenu';
import {
  useAppsFilterConversionToMasterFilter,
  useAppsFilterSelection,
  useIdentifiedAppsClassification,
} from 'screens/platform/cross-platform-components/MasterFiltersPanel/FilterSection/AppsFilterSection/AppsFilterSectionUtils';
import HierarchicalSearchInputDropdown
  from 'screens/platform/cross-platform-components/MasterFiltersPanel/FilterSection/HierarchicalSearchInputDropdown/index';
import FilterWrapper from 'screens/platform/cross-platform-components/MasterFiltersPanel/FilterWrapper/index';
import useAppsLabel
  from 'screens/platform/cross-platform-components/MasterFiltersPanel/masterFiltersUtils/AppsLabelHook/AppsLabelHook';

export default function AppsFilterSection() {
  const { dispatchMasterFilters } = useMasterFilters();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const isFirstRender = useRef(true);

  const appsClassification = useIdentifiedAppsClassification();
  const appsFilter = useAppsFilterSelection(appsClassification);
  const convertAppsFilter = useAppsFilterConversionToMasterFilter(appsClassification);

  useEffect(() => {
    if (
      !isDropdownOpen
      && !appsFilter.isNotSelected()
      && !isFirstRender.current
    ) {
      dispatchMasterFilters({
        type: 'CHANGE_APPS',
        payload: { nextApps: convertAppsFilter(appsFilter) },
      });
    }
    isFirstRender.current = false;
  }, [isDropdownOpen]);

  return (
    <FilterWrapper filter={FilterMenu.APPS}>
      <HierarchicalSearchInputDropdown
        selection={appsFilter}
        isDropdownOpen={isDropdownOpen}
        setIsDropdownOpen={setIsDropdownOpen}
        type={HierarchicalItemType.CHANNEL}
        toggleButton={(
          <ActionableBadge
            label={useAppsLabel()}
            iconType="edit"
            onClick={() => setIsDropdownOpen((prev) => !prev)}
          />
        )}
        searchPlaceholder="Search for apps or channels"
      />
    </FilterWrapper>
  );
}
