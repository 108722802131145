import { useMemo } from 'react';

import { MasterFilters } from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFilters';
import {
  useDefaultMasterFilters,
} from 'screens/platform/cross-platform-components/context/MasterFiltersContext/utils/DefaultMasterFiltersUtils';
import useTenantContext from 'screens/platform/cross-platform-components/context/tenant/TenantContext';
import {
  flattenMasterFiltersToParams,
  generateUrlParams,
} from 'screens/platform/cross-platform-components/context/UrlParams/convertMasterFiltersToUrlParams';

export default function useMasterFiltersUrlParamsGenerator() {
  const defaultMasterFilters = useDefaultMasterFilters();
  const { tenant } = useTenantContext();

  const flatDefaultMasterFilters = useMemo(
    () => flattenMasterFiltersToParams(defaultMasterFilters, tenant.departments),
    [defaultMasterFilters, tenant.departments],
  );

  return (masterFiltersToConvert: MasterFilters) => {
    const flatMasterFilters = flattenMasterFiltersToParams(
      masterFiltersToConvert,
      tenant.departments,
    );
    return generateUrlParams(
      flatMasterFilters,
      flatDefaultMasterFilters,
      defaultMasterFilters.datesRange,
    );
  };
}
