import React, { useEffect } from 'react';

import Api from 'global/api/platformApi';
import useTenantContext from 'screens/platform/cross-platform-components/context/tenant/TenantContext';
import InfoPopover from 'screens/settings/AppsScreen/components/ConnectorRow/components/InfoPopover';
import DebuggerConsole from 'utils/DebuggerConsole';
import { useMountedState } from 'utils/hooks';

export default function GmailConnectedEmailPopover() {
  const { tenant } = useTenantContext();

  const [connectedEmail, setConnectedEmail] = useMountedState<string | null>(null);

  useEffect(() => {
    const fetchConnectedEmail = async () => {
      try {
        const res = await Api.Setup.getGmailConnectedEmail(tenant.id);
        if (res && res.data) {
          setConnectedEmail(res.data);
        }
      } catch (err) {
        DebuggerConsole.error(err);
      }
    };

    fetchConnectedEmail();
  }, []);

  return connectedEmail ? (
    <InfoPopover
      title="Email Logging Address"
      content={`Emails sent to ${connectedEmail} will be processed as interactions. Note that you can utilize the "+" sign to split them to different inboxes.`}
    />
  ) : null;
}
