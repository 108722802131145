import React, { ReactNode } from 'react';
import { useAsync } from 'react-use';

import Api from 'global/api/platformApi';
import { OrganizationRisk, OrganizationSize } from 'model/Organization';
import { NullableValuesSelectionConstants } from 'screens/platform/cross-platform-components/context/MasterFiltersContext/NullableValuesSelection';
import OrganizationsMetadataContext from 'screens/platform/cross-platform-components/context/OrganizationsMetadata/OrganizationsMetadataContext';
import parseOrganizationsResponse from 'screens/platform/cross-platform-components/context/OrganizationsMetadata/utils/OrganizationsMetadataUtils';
import useTenantContext from 'screens/platform/cross-platform-components/context/tenant/TenantContext';
import DebuggerConsole from 'utils/DebuggerConsole';

const { UNDEFINED_PROPERTY } = NullableValuesSelectionConstants;

const emptyOrganizationsMetadata = {
  allOrganizationsMetadata: {},
  risksToOrganizationsMap: {
    [OrganizationRisk.HIGH]: [],
    [OrganizationRisk.MEDIUM]: [],
    [OrganizationRisk.LOW]: [],
  },
  sizesToOrganizationsMap: {
    [OrganizationSize.LARGE]: [],
    [OrganizationSize.MEDIUM]: [],
    [OrganizationSize.SMALL]: [],
    [OrganizationSize.VERY_SMALL]: [],
    [UNDEFINED_PROPERTY]: [],
  },
  segmentsToOrganizationsMap: {
    [UNDEFINED_PROPERTY]: [],
  },
};

type Props = {
  children: ReactNode;
};

export default function OrganizationsMetadataContextProvider({
  children,
}: Props) {
  const {
    tenant: { id: tenantId },
  } = useTenantContext();
  const { value, loading } = useAsync(async () => {
    try {
      const res = await Api.Organization.getAllOrganizationsMetadata(tenantId);
      if (res) {
        return parseOrganizationsResponse(res.data);
      }
    } catch (error) {
      DebuggerConsole.error('Failed to load all organizations metadata');
    }
    return null;
  }, [tenantId]);

  return (
    <OrganizationsMetadataContext.Provider
      value={{ ...(value ?? emptyOrganizationsMetadata), loading }}
    >
      {children}
    </OrganizationsMetadataContext.Provider>
  );
}
