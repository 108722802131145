import { useEffect, useRef } from 'react';

import { TRANSITION_ANIMATION_LENGTH } from 'global/constants';
import { useInteractionsContext } from 'screens/platform/cross-platform-components/Interactions/InteractionsContext';
import { useMountedState } from 'utils/hooks';

/**
 * The interactions table component is heavy to render. Therefore, it causes
 * performance issues when toggling this switch.
 * By using a local state we guarantee this component will transition smoothly
 * and once the CSS transition is done, we can safely update the context
 */
export default function useDebouncedSummaryToggle() {
  const { interactionsMode } = useInteractionsContext();

  const isInitiallyToggled = interactionsMode.value === 'summary';
  const [isToggled, setIsToggled] = useMountedState(isInitiallyToggled);
  const summaryModeRef = useRef(isInitiallyToggled);

  function updateSummaryMode() {
    const timeoutId = setTimeout(() => {
      if (isToggled === summaryModeRef.current) {
        interactionsMode.set(isToggled ? 'summary' : 'table');
      }
    }, TRANSITION_ANIMATION_LENGTH);

    return () => clearTimeout(timeoutId);
  }

  function handleOutsideToggle() {
    const isSummaryModeToggled = interactionsMode.value === 'summary';
    if (isToggled !== isSummaryModeToggled) {
      setIsToggled(isSummaryModeToggled);
    }
  }

  useEffect(updateSummaryMode, [isToggled]);
  useEffect(handleOutsideToggle, [interactionsMode.value]);

  function onChange(nextValue: boolean) {
    setIsToggled(nextValue);
    summaryModeRef.current = nextValue;
  }

  return { isToggled, onChange };
}
