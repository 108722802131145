import React from 'react';

import OrganizationProfileImage from 'common-ui-components/MultiImages/ProfileImage/OrganizationProfileImage';
import Size from 'global/lists/Size';
import Organization from 'model/Organization';
import { getOrganizationName } from 'utils/OrganizationUtils';

import style from 'es-src/screens/HomeScreen/components/SearchWithAutocomplete/AutocompleteResultCategory/OrganizationResult/style.module.scss';

interface Props {
  organization: Organization;
}

export default function OrganizationResult({ organization }: Props) {
  if (!organization) return null;

  return (
    <div className={style.organizationAutocompleteResult}>
      <OrganizationProfileImage organization={organization} size={Size.MEDIUM} />
      <div>{getOrganizationName(organization)}</div>
    </div>
  );
}
