import colors from 'global/colors';
import constants from 'screens/platform/constants';

const heatMapCellHeight = 35;

export default {
  lineChart: {
    theme: {
      axis: { ticks: { text: { fontSize: 12, fill: colors.DARK_GRAY } } },
      labels: { text: { fontSize: 12 } },
    },
    props: {
      lineWidth: 2,
      enableGridX: false,
      enableGridY: false,
      enableArea: false,
      useMesh: true,
      pointSize: 10,
    },
  },
  barChart: {
    theme: {
      axis: {
        legend: {
          text: {
            fontSize: 12,
            fill: colors.DARK_GRAY,
          },
        },
        ticks: {
          text: {
            fill: colors.DARK_GRAY,
          },
        },
      },
    },
    margin: {
      top: 0,
      left: constants.TIMELINE_SCREEN_CANVAS_LEFT_SECTION_WIDTH,
      right: 0,
      bottom: 30,
    },
    axisLeft: {
      legendOffset: -20,
      tickSize: 0,
      format: () => '',
    },
  },
  bubbleChart: {
    theme: {
      axis: {
        ticks: {
          text: { fontSize: 12, fill: colors.DARK_GRAY },
          line: {
            stroke: colors.DARK_GRAY,
            strokeWidth: 1,
          },
        },
      },
      grid: {
        line: {
          stroke: colors.DARK_GRAY,
          strokeWidth: 1,
        },
      },
      labels: { text: { fontSize: 12 } },
    },
    margin: {
      top: 40,
      right: 0,
      bottom: 20,
      left: constants.TIMELINE_SCREEN_CANVAS_LEFT_SECTION_WIDTH,
    },
    axisLeft: {
      tickSize: 0,
      tickPadding: 25,
    },
    lineHeight: 30,
  },
  heatMap: {
    maxInteractiveCells: 60,
    axisTop: { tickSize: 0, tickPadding: 10 },
    axisLeft: null,
    cellHeight: heatMapCellHeight,
    margin: {
      top: heatMapCellHeight,
      left: 8,
      right: 0,
      bottom: 0,
    },
    borderColor: colors.WHITE,
    borderWidth: 2,
  },
};
