import React from 'react';

import { getMetricConfig } from 'global/lists/Metric';
import EmptyMetricValue from 'screens/platform/directory/components/metricValue/EmptyMetricValue';
import MetricValueProps from 'screens/platform/directory/components/metricValue/MetricValueProps';

export default function NumericMetricValue({ metric, metricValue }: MetricValueProps) {
  const metricConfig = getMetricConfig(metric);
  if (metricValue === null) return <EmptyMetricValue />;

  return <div>{metricConfig.getStringValue(metricValue)}</div>;
}
