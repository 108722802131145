import { TippyProps } from '@tippyjs/react';
import classNames from 'classnames';
import React, {
  CSSProperties, RefObject, useEffect, useRef, useState,
} from 'react';

import Tooltip from 'common-ui-components/Tooltip';
import Size from 'global/lists/Size';
import ColorUtils from 'utils/ColorUtils';

import styles from 'common-ui-components/Badge/badge.module.scss';

const badgeSizeStyles: Record<Size.SMALL | Size.MEDIUM | Size.BIG, string> = {
  [Size.SMALL]: styles.small,
  [Size.MEDIUM]: styles.medium,
  [Size.BIG]: styles.big,
};

interface BadgeProps {
  text: string;
  tooltip?: { text: string | any; props?: TippyProps };
  color?: string;
  className?: string;
  shouldFixLongText?: boolean;
  size?: Size.SMALL | Size.MEDIUM | Size.BIG;
}

function checkIsTooLong(container: RefObject<any>, text: string): boolean {
  function getMockDiv(mockElementStyle: CSSProperties) {
    const mockDiv = document.createElement('div');

    Object.entries(mockElementStyle).forEach(([key, value]) => {
      mockDiv.style[key] = value;
    });

    mockDiv.classList.add(styles.badge);
    mockDiv.innerText = text;
    return mockDiv;
  }

  function getElementWidth(parent: HTMLElement, element: HTMLDivElement): number {
    parent.appendChild(element);
    const { width } = element.getBoundingClientRect();
    parent.removeChild(element);
    return width;
  }

  const fullTextWidthDiv = getMockDiv({ display: 'inline', whiteSpace: 'nowrap' });
  const fullTextWidth = getElementWidth(document.body, fullTextWidthDiv);

  const maxWidthElement = getMockDiv({ width: '100%' });
  const maxWidth = getElementWidth(container.current, maxWidthElement);

  return maxWidth < fullTextWidth;
}

export default function Badge({
  text,
  tooltip,
  color,
  className,
  shouldFixLongText = true,
  size = Size.MEDIUM,
}: BadgeProps) {
  const textLengthTester = useRef<HTMLDivElement>(null);
  const [isTextTooLong, setIsTextTooLong] = useState<boolean | null>(null);

  const textColor = color && ColorUtils.darken(color, 0.50);
  const backgroundColor = color && ColorUtils.lighten(color, 0.50);

  const content = (
    <div
      style={{ color: textColor, backgroundColor }}
      className={classNames(styles.badge, badgeSizeStyles[size], className)}
    >
      {text}
    </div>
  );

  useEffect(() => {
    if (textLengthTester.current && isTextTooLong === null && shouldFixLongText) {
      setIsTextTooLong(() => checkIsTooLong(textLengthTester, text));
    }
  }, [textLengthTester.current, text]);

  // Makes sure long department names have proper width
  function LongTextWrapper({ children }) {
    return isTextTooLong === true ? (
      <div style={{ maxWidth: 0 }}>
        {children}
      </div>
    ) : <>{children}</>;
  }

  function OptionalTooltipWrapper({ children }) {
    return tooltip ? (
      <Tooltip theme="dark" className="badge-tippy" content={tooltip.text} {...tooltip.props}>
        {children}
      </Tooltip>
    ) : <>{children}</>;
  }

  return (
    <>
      {shouldFixLongText && isTextTooLong === null ? <div ref={textLengthTester} /> : null}
      <LongTextWrapper>
        <OptionalTooltipWrapper>
          {content}
        </OptionalTooltipWrapper>
      </LongTextWrapper>
    </>
  );
}
