import { millisecondsToHours } from 'date-fns';

import addHours from 'date-fns/addHours';
import startOfDay from 'date-fns/startOfDay';
import subDays from 'date-fns/subDays';
import subMonths from 'date-fns/subMonths';
import { DatesRange } from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFilters';
import { convertUTCToUserTimezone } from 'utils/DateUtils';

interface PresetTimeRange {
  label: string;
  isSelected: (datesRange: DatesRange) => boolean;
  onClick: () => void;
}

function getStartOfDayTime(date?: Date): number {
  return startOfDay(date || new Date()).getTime();
}

export default function getPresetTimeRanges(
  setDatesRange: ({ from, to }: { from: Date; to: Date }) => void,
): PresetTimeRange[] {
  function changeDatesRangeByDaysBack(daysBack: number): void {
    const today = new Date();
    const payload = { from: subDays(today, daysBack - 1), to: today };
    setDatesRange(payload);
  }

  function changeDatesRangeByMonthBack(monthsBack: number): void {
    const today = new Date();
    const payload = { from: subMonths(today, monthsBack), to: today };
    setDatesRange(payload);
  }

  function getIsSelected(daysInPresetRange: number) {
    return (datesRange: DatesRange) => {
      // eslint-disable-next-line max-len
      const millisInSelectedRange = getStartOfDayTime(datesRange.to) - getStartOfDayTime(datesRange.from);
      const daysInSelectedRange = millisecondsToHours(millisInSelectedRange) / 24;

      const startOfToday = getStartOfDayTime();
      const startOfTo = getStartOfDayTime(
        addHours(datesRange.to, (new Date().getTimezoneOffset() / 60)),
      );

      return (startOfToday === startOfTo) && (daysInSelectedRange === daysInPresetRange);
    };
  }

  function getIsSelectedForLastMonths(monthsBack: number) {
    return (datesRange: DatesRange) => {
      const todayStart = getStartOfDayTime();
      const monthsAgoStart = getStartOfDayTime(subMonths(new Date(), monthsBack));

      const toStart = getStartOfDayTime(convertUTCToUserTimezone(datesRange.to));
      const fromStart = getStartOfDayTime(convertUTCToUserTimezone(datesRange.from));

      return (todayStart === toStart) && (monthsAgoStart === fromStart);
    };
  }

  return [
    {
      label: 'Today',
      isSelected: getIsSelected(1),
      onClick: () => changeDatesRangeByDaysBack(1),
    }, {
      label: 'Last 7 Days',
      isSelected: getIsSelected(7),
      onClick: () => changeDatesRangeByDaysBack(7),
    }, {
      label: 'Last 28 Days',
      isSelected: getIsSelected(28),
      onClick: () => changeDatesRangeByDaysBack(28),
    }, {
      label: 'Last 3 Months',
      isSelected: getIsSelectedForLastMonths(3),
      onClick: () => changeDatesRangeByMonthBack(3),
    },
  ];
}
