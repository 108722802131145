import React, { FC } from 'react';

import { MetricValueResponse } from 'global/api/controller/MetricController';
import Metric from 'global/lists/Metric';
import LastEngagementMetricValue
  from 'screens/platform/directory/components/metricValue/LastEngagementMetricValue/index';
import NumericMetricValue from 'screens/platform/directory/components/metricValue/NumericMetricValue';
import {
  OrganizationMetric,
} from 'screens/platform/directory/organizations/OrganizationsDirectoryScreen/OrganizationsTable/utils/OrganizationWithMetrics';

interface Props {
  metricValue: MetricValueResponse | undefined;
  metric: Metric;
}

type MetricCellComponent = FC<{
  metricValue: MetricValueResponse | null;
  metric: Metric;
}>;

export default function OrganizationMetricValue({ metricValue, metric }: Props) {
  const metricToColumnConfig: Record<OrganizationMetric, MetricCellComponent> = {
    [Metric.ORGANIZATION_LAST_MEETING]: LastEngagementMetricValue,
    [Metric.ORGANIZATION_TOTAL_ENGAGEMENTS]: NumericMetricValue,
    [Metric.ORGANIZATION_ENGAGEMENT_FREQUENCY]: NumericMetricValue,
    [Metric.ORGANIZATION_MEETING_FREQUENCY]: NumericMetricValue,
    [Metric.ORGANIZATION_TOTAL_MENTIONS]: NumericMetricValue,
    [Metric.ORGANIZATION_TOTAL_PEOPLE]: NumericMetricValue,
    [Metric.ORGANIZATION_TOTAL_CONTACTS]: NumericMetricValue,
    [Metric.ORGANIZATION_TIME_SINCE_FIRST_MENTION]: LastEngagementMetricValue,
    [Metric.ORGANIZATION_TOTAL_OUTBOUND_INTERACTIONS]: NumericMetricValue,
    [Metric.ORGANIZATION_TOTAL_INBOUND_INTERACTIONS]: NumericMetricValue,
    [Metric.ORGANIZATION_TOTAL_ENGAGEMENTS_VOLUME]: NumericMetricValue,
    [Metric.ORGANIZATION_TOTAL_MENTIONS_VOLUME]: NumericMetricValue,
    [Metric.ORGANIZATION_TOTAL_CONVERSATIONS]: NumericMetricValue,
    [Metric.ORGANIZATION_TOTAL_MEETINGS]: NumericMetricValue,
    [Metric.ORGANIZATION_TIME_IN_MEETINGS_LAST_THREE_MONTHS]: NumericMetricValue,
    [Metric.ORGANIZATION_TIME_IN_MEETINGS_LAST_SIX_MONTHS]: NumericMetricValue,
    [Metric.ORGANIZATION_TIME_IN_MEETINGS_LAST_NINE_MONTHS]: NumericMetricValue,
    [Metric.ORGANIZATION_TIME_IN_MEETINGS_LAST_TWELVE_MONTHS]: NumericMetricValue,
  };

  const Component = metricToColumnConfig[metric];
  return <Component metric={metric} metricValue={metricValue ?? null} />;
}
