import DebuggerConsole from 'utils/DebuggerConsole';
import ObjectUtils from 'utils/ObjectUtils';

export default class StorageUtils<K extends string> {
  private storage: Storage;

  constructor(storage: Storage) {
    this.storage = storage;
  }

  setItem<T>(key: K, value: T) {
    this.storage.setItem(key, JSON.stringify(value));
  }

  getItem<T>(key: K, validateScheme?: (val: any) => val is T): T | null {
    const item = this.storage.getItem(key);
    if (!item) return null;
    const value = JSON.parse(item) as T;

    if (!validateScheme || validateScheme(value)) return value;

    DebuggerConsole.warn('Tried to read an invalid value from localStorage', { key, value });
    this.removeItem(key);
    return null;
  }

  /**
   * Makes sure that the app won't crash - or behave unexpectedly -
   * if the state's structure is changed (e.g. a new field is added)
   */
  getItemWithDefaultValue<T>(key: K, defaultValue: T): T {
    const item = this.storage.getItem(key);
    if (item) {
      const parsedItem = JSON.parse(item) as T;

      if (ObjectUtils.isObject(parsedItem) && ObjectUtils.isObject(defaultValue)) {
        const objectWithVerifiedScheme = ObjectUtils
          .calculateObjectWithFallback(parsedItem, defaultValue);
        this.setItem(key, objectWithVerifiedScheme);
        return objectWithVerifiedScheme;
      }

      return parsedItem;
    }
    return defaultValue;
  }

  removeItem(key: K) {
    this.storage.removeItem(key);
  }
}
