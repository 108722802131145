import FilterableProperty from 'global/api/controller/utils/filtering/FilterableProperty';
import { BaseFilterCondition, FilterFieldType } from 'global/api/controller/utils/filtering/FilterCondition';

interface IdFilterCondition<FieldNameType extends FilterableProperty>
  extends BaseFilterCondition<FieldNameType, (string | null)[]> {
  fieldType: FilterFieldType.ID;
  operator: IdFilterOperator;
}

export enum IdFilterOperator {
  IS_ANY_OF = 'IS_ANY_OF',
  EXISTS = 'EXISTS',
}

const operatorLabels: Record<IdFilterOperator, string> = {
  [IdFilterOperator.IS_ANY_OF]: 'is any of',
  [IdFilterOperator.EXISTS]: 'exists',
};
export function getIdFilterOperatorLabel(
  operator: IdFilterOperator,
  filterValues: (string | null)[],
) {
  if (operator === IdFilterOperator.IS_ANY_OF && filterValues.length === 1) {
    return 'is';
  }
  return operatorLabels[operator];
}

export default IdFilterCondition;
