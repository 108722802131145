import React, { useEffect } from 'react';

import useShareOfAttentionDataFetcher from 'screens/platform/contentScreens/AnalyticsScreen/widgets/ShareOfAttentionWidget/visualizations/ShareOfAttentionTable/ShareOfAttentionDataFetcherHook';
import ShareOfAttentionHeatMap, { ShareOfAttentionGroupType } from 'screens/platform/contentScreens/AnalyticsScreen/widgets/ShareOfAttentionWidget/visualizations/ShareOfAttentionTable/ShareOfAttentionHeatMap';
import { WidgetConfig } from 'screens/platform/contentScreens/AnalyticsScreen/widgets/widgetConfig';
import ChartStatesHandler from 'screens/platform/cross-platform-components/ChartStatesHandler';
import { useMasterFilters } from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFiltersContext';

import style from 'screens/platform/contentScreens/AnalyticsScreen/widgets/ShareOfAttentionWidget/visualizations/ShareOfAttentionTable/style.module.scss';

interface Props extends WidgetConfig {
  group: ShareOfAttentionGroupType;
  disableClicking?: boolean;
  onFinishLoading?: () => void;
  entitiesLimit?: number;
}

export default function ShareOfAttentionTable({
  group,
  settings,
  disableClicking = false,
  onFinishLoading,
  entitiesLimit,
}: Props) {
  const includeZeros = Boolean(settings?.includeZeros);
  const { currentFilters } = useMasterFilters();
  const {
    shareOfAttentionData: data,
    isNetworkError,
  } = useShareOfAttentionDataFetcher(group, includeZeros, currentFilters, entitiesLimit);

  useEffect(() => {
    if (data && onFinishLoading) {
      onFinishLoading();
    }
  }, [data]);

  return (
    <div className={style.shareOfAttentionTableWrapper}>
      <ChartStatesHandler
        loading={!data}
        emptyData={data?.length === 0}
        isNetworkError={isNetworkError}
      >
        {data && (
          <ShareOfAttentionHeatMap
            data={data}
            group={group}
            disableClicking={disableClicking}
          />
        )}
      </ChartStatesHandler>
    </div>
  );
}
