export type WithRequired<T, K extends keyof T> = T & { [P in K]-?: T[P] }
export type PickRequired<T, K extends keyof T> = Pick<T, K> & { [P in K]-?: Required<T[P]> }
export type WithOptional<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>

export type UnionSubset<T, U extends T> = U;

export type Modify<T, R> = Omit<T, keyof R> & R;

export type DeepReadonly<T> = {
  readonly [P in keyof T]: DeepReadonly<T[P]>
}

export type ComponentProps<T extends (...args: any[]) => any> = Parameters<T>[0];

export function assertSwitchIsExhaustive(x: never): never {
  throw new Error(`Unhandled switch case for: ${x}`);
}
