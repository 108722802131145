import { MasterFilters } from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFilters';

interface OrganizationsFlatUrlParams {
  selectedOrganizationsIds: MasterFilters['organizations']['organizationsIds']['selected'];
  selectAllOrganizationsIds: boolean;
  includeUndefinedOrganizations: MasterFilters['organizations']['organizationsIds']['includeUndefined'];

  selectedOrganizationsRisks: MasterFilters['organizations']['selectedRisks']['selected'];
  selectAllOrganizationsRisks: boolean;
  includeUndefinedOrganizationsRisk: MasterFilters['organizations']['selectedRisks']['includeUndefined'];

  selectedOrganizationsSizes: MasterFilters['organizations']['selectedSizes']['selected'];
  selectAllOrganizationsSizes: boolean;
  includeUndefinedOrganizationsSize: MasterFilters['organizations']['selectedSizes']['includeUndefined'];

  selectedOrganizationsSegments: MasterFilters['organizations']['selectedSegments']['selected'];
  selectAllOrganizationsSegments: boolean;
  includeUndefinedOrganizationsSegment: MasterFilters['organizations']['selectedSegments']['includeUndefined'];
}

export interface FlatUrlParams extends OrganizationsFlatUrlParams {
  from: MasterFilters['datesRange']['from'];
  to: MasterFilters['datesRange']['to'];
  categories: string[];
  tags: string[];
  includeUntagged: MasterFilters['categories']['includeUntagged'];
  departments: MasterFilters['people']['departments'];
  selectedTeams: MasterFilters['people']['teams']['selected'];
  selectAllTeams: boolean;
  includeUndefinedTeam: MasterFilters['people']['teams']['includeUndefined'];
  peopleIds: MasterFilters['people']['internalPeopleIds'];
  includeUnidentified: MasterFilters['people']['includeUnidentified'];
  apps: string[];
  channels: string[];
  directionality: MasterFilters['directionality'];
}

export function isFlatURLParamsKey(key: string): key is keyof FlatUrlParams {
  const flatParamsMap: Record<keyof FlatUrlParams, any> = Object.freeze({
    apps: undefined,
    categories: undefined,
    channels: undefined,
    departments: undefined,
    from: undefined,
    to: undefined,
    includeUnidentified: undefined,
    includeUntagged: undefined,
    selectedOrganizationsIds: undefined,
    includeUndefinedOrganizations: undefined,

    selectAllOrganizationsIds: undefined,
    selectedOrganizationsRisks: undefined,
    selectAllOrganizationsRisks: undefined,
    includeUndefinedOrganizationsRisk: undefined,
    selectedOrganizationsSizes: undefined,
    selectAllOrganizationsSizes: undefined,
    includeUndefinedOrganizationsSize: undefined,
    selectedOrganizationsSegments: undefined,
    selectAllOrganizationsSegments: undefined,
    includeUndefinedOrganizationsSegment: undefined,

    peopleIds: undefined,
    selectedTeams: undefined,
    selectAllTeams: undefined,
    includeUndefinedTeam: undefined,
    tags: undefined,
    directionality: undefined,
  });
  return Object.keys(flatParamsMap).includes(key);
}
