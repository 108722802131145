import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
} from 'react';

import useInteractionsTableConfig
  from 'screens/platform/cross-platform-components/context/platform/InteractionsTableConfigHook';
import { PlatformReducerAction, PlatformReducerStateType } from 'screens/platform/cross-platform-components/context/platform/PlatformReducer';

export interface PlatformContextType extends PlatformReducerStateType {
  dispatchPlatformContext: Dispatch<PlatformReducerAction>;
  shouldReloadListener: boolean;
  reload: () => void;
  interactionsTable: ReturnType<typeof useInteractionsTableConfig>;
  eventsDrawer: {
    isEventsDrawerOpen: boolean;
    setEventsDrawerOpen: Dispatch<SetStateAction<boolean>>;
  };
  widgetsDrawer: {
    isWidgetsDrawerOpen: boolean;
    setWidgetDrawerOpen: Dispatch<SetStateAction<boolean>>;
  };
}

export const usePlatformContext:
  () => PlatformContextType = () => useContext<PlatformContextType>(PlatformContext);

const PlatformContext = createContext<PlatformContextType>({} as any);
export default PlatformContext;
