import {
  Day, endOfWeek, format, startOfWeek,
} from 'date-fns';
import { MutableRefObject, useCallback, useMemo } from 'react';

import constants from 'screens/platform/constants';
import TimeSeriesChartLabelsHookUtils, {
  LabelsMap,
} from 'screens/platform/contentScreens/AnalyticsScreen/utils/TimeSeriesChartLabelsHook/TimeSeriesChartLabelsHookUtils';
import { DateRangeGranularity } from 'screens/platform/contentScreens/AnalyticsScreen/widgets/widgetConfig';
import { DatesRange } from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFilters';
import {
  useMasterFilters,
} from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFiltersContext';
import DateUtils, { MONDAY_INDEX } from 'utils/DateUtils';
import { useResizeListener } from 'utils/hooks';

const BAR_LABEL_WIDTH = 45;

export function getFormattedDateForChartLabels(date: Date | number | string): string {
  return format(Number(date), DateUtils.DateFormat.MONTH_DAY);
}

export function useTimeSeriesChartLabelFormatter(labels: LabelsMap): (d: Date) => string {
  return useCallback(
    (date: Date) => labels[getFormattedDateForChartLabels(date)] || '',
    [labels],
  );
}

interface TimeSeriesChartLabelsHookProps {
  granularity: DateRangeGranularity;
  datesRange: DatesRange;
  leftMargin: number;
  isTableChart: boolean;
  weekStartIndex: Day;
}

export default function useTimeSeriesChartLabels({
  granularity = DateRangeGranularity.DAY,
  datesRange: overridingDatesRange,
  leftMargin = constants.TIMELINE_SCREEN_CANVAS_LEFT_SECTION_WIDTH,
  isTableChart = false,
  weekStartIndex = MONDAY_INDEX,
}: Partial<TimeSeriesChartLabelsHookProps>): { ref: MutableRefObject<any>; labels: LabelsMap } {
  const { currentFilters } = useMasterFilters();
  const datesRange = overridingDatesRange || currentFilters.datesRange;
  const isChartInWeeksMode = granularity === DateRangeGranularity.WEEK;

  const {
    width: chartWidthWithPadding,
    ref,
  } = useResizeListener();

  const allDatesInRange = useMemo<Date[]>(() => {
    if (isChartInWeeksMode) {
      const { from, to } = datesRange;
      const weeksDatesRange = {
        from: startOfWeek(from, { weekStartsOn: weekStartIndex }),
        to: endOfWeek(to, { weekStartsOn: weekStartIndex }),
      };
      return DateUtils.getDatesInRange(weeksDatesRange);
    }
    return DateUtils.getDatesInRange(datesRange);
  }, [datesRange, isChartInWeeksMode]);

  return useMemo(() => {
    const netChartWidth = chartWidthWithPadding && (chartWidthWithPadding - leftMargin);
    const ticksAmount = allDatesInRange.length / (granularity === DateRangeGranularity.DAY ? 1 : 7);
    const roomForTicks = netChartWidth && Math.floor(netChartWidth / BAR_LABEL_WIDTH);

    const labels = TimeSeriesChartLabelsHookUtils.getLabels(
      allDatesInRange,
      ticksAmount,
      roomForTicks,
      isChartInWeeksMode,
      isTableChart && granularity === DateRangeGranularity.WEEK,
      weekStartIndex,
    );
    return { ref, labels };
  }, [chartWidthWithPadding, granularity, datesRange]);
}
