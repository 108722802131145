import { Duration, formatDuration, intervalToDuration } from 'date-fns';

import { MetricValueResponse } from 'global/api/controller/MetricController';
import DateUtils from 'utils/DateUtils';

export const EMPTY_METRIC = '-';

export function numericMetricFormatter(
  metricValueResponse: MetricValueResponse | undefined,
): string {
  if (!metricValueResponse) return EMPTY_METRIC;
  return (metricValueResponse.value ?? 0).toFixed(0);
}

export function percentMetricFormatter(
  metricValueResponse: MetricValueResponse | undefined,
): string {
  if (!metricValueResponse) return EMPTY_METRIC;
  const { value } = metricValueResponse;
  return value ? `${value.toFixed(1)}%` : EMPTY_METRIC;
}

export function timeSinceMetricFormatter(
  metricValueResponse: MetricValueResponse | undefined,
): string {
  if (!metricValueResponse || !metricValueResponse.interaction?.end) return EMPTY_METRIC;
  return DateUtils.getTimeAgoLabel(
    new Date(metricValueResponse.interaction.end),
  );
}

export function timeInMillisToDurationFormatter(
  metricValueResponse: MetricValueResponse | undefined,
  hoursAndMinutesFormat?: boolean,
): string {
  if (!metricValueResponse || !metricValueResponse.value) return EMPTY_METRIC;
  const duration = intervalToDuration({
    start: 0,
    end: metricValueResponse.value,
  });
  if (hoursAndMinutesFormat) {
    const durationOnlyInHoursAndMinutes = getDurationOnlyInHoursAndMinutes(duration);
    return formatDuration(durationOnlyInHoursAndMinutes, {
      format: ['hours', 'minutes'],
    });
  }
  return formatDuration(duration);
}

function getDurationOnlyInHoursAndMinutes(duration: Duration) {
  const {
    minutes, days, months, years,
  } = duration;
  const hoursInDay = 24;
  const daysInMonth = 30;
  const monthsInYear = 12;

  let hours = duration.hours || 0;
  if (days && days > 0) hours += days * hoursInDay;
  if (months && months > 0) hours += months * daysInMonth * hoursInDay;
  if (years && years > 0) hours += years * monthsInYear * daysInMonth * hoursInDay;

  return { hours, minutes };
}
