import React, { ReactNode } from 'react';

import CustomAtomsFiltersContextProvider
  from 'screens/platform/cross-platform-components/context/CustomFilters/CustomAtomsFilters/CustomAtomsFiltersContextProvider';
import CustomEventsFiltersContextProvider from 'screens/platform/cross-platform-components/context/CustomFilters/CustomEventsFilters/CustomEventsFiltersContextProvider';
import DOMElementsContextProvider from 'screens/platform/cross-platform-components/context/DOMElementsContextProvider';
import GlobalModalContextProvider from 'screens/platform/cross-platform-components/context/globalModal/GlobalModalContextProvider';
import GraphFeaturesContextProvider from 'screens/platform/cross-platform-components/context/graphFeatures/GraphFeaturesContextProvider';
import MasterFiltersContextProvider from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFiltersContextProvider';
import PlatformContextProvider from 'screens/platform/cross-platform-components/context/platform/PlatformContextProvider';

export default function SessionContexts({ children }: { children: ReactNode }) {
  return (
    <GraphFeaturesContextProvider>
      <MasterFiltersContextProvider>
        <CustomAtomsFiltersContextProvider>
          <CustomEventsFiltersContextProvider>
            <PlatformContextProvider>
              <GlobalModalContextProvider>
                <DOMElementsContextProvider>
                  { children }
                </DOMElementsContextProvider>
              </GlobalModalContextProvider>
            </PlatformContextProvider>
          </CustomEventsFiltersContextProvider>
        </CustomAtomsFiltersContextProvider>
      </MasterFiltersContextProvider>
    </GraphFeaturesContextProvider>
  );
}
