import React from 'react';

import Analytics, { AnalyticsEvent } from 'global/Analytics';
import Authentication from 'global/authentication/Authentication';
import Intercom from 'global/Intercom';
import { PopulatedUserState, UserState } from 'screens/platform/cross-platform-components/context/user/UserContext';
import { Action } from 'screens/platform/cross-platform-components/context/user/UserReducer';
import DebuggerConsole from 'utils/DebuggerConsole';

/**
 * The purpose of this file is to make sure that adding/updating actions is done only twice:
 *   1. Inside the UserActions class
 *   2. Inside the UserActions type
 *  */

export type LoginMethod = 'google' | 'cached_firebase_user';

// This dictates the TYPES outside this file
export type UserActionsType = {
  startUserSession: (user: UserState, loginMethod: LoginMethod) => void;
  endUserSession: () => void;
  setFirebaseId: (firebaseId: string) => void;
}

// This dictates the LOGIC outside of this file
const UserActions: {
  [action in keyof UserActionsType]: ((dispatch: React.Dispatch<Action>) => UserActionsType[action])
} = {
  startUserSession: (dispatch) => (user, loginMethod) => {
    DebuggerConsole.log('startUserSession', { user });
    if (user) {
      const populatedUser = user as PopulatedUserState;
      const authenticatedUserData = { ...populatedUser, isLoggedIn: true };
      dispatch({ type: 'UPDATE_USER', payload: authenticatedUserData });

      Analytics.identify(populatedUser);
      Analytics.trackEvent(AnalyticsEvent.USER_LOGGED_IN, { method: loginMethod });
      Intercom.identify(populatedUser.email, populatedUser.personId);
    }
  },

  endUserSession: (dispatch) => () => {
    Intercom.endSession();
    Authentication.logoutFromFirebase();
    dispatch({ type: 'LOGOUT' });
  },

  setFirebaseId: (dispatch) => (firebaseId: string) => {
    dispatch({ type: 'SET_FIREBASE_ID', payload: { firebaseId } });
  },
};

export default function getUserActions(dispatch: React.Dispatch<Action>): UserActionsType {
  return Object.keys(UserActions).reduce((acc, actionKey) => {
    acc[actionKey] = UserActions[actionKey](dispatch);
    return acc;
  }, {}) as any;
}
