import React, { ReactNode, useMemo } from 'react';

import Spinner from 'common-ui-components/Spinner';
import SingularPluralNouns from 'global/lists/SingularPluralNouns';
import Size from 'global/lists/Size';
import PeopleMasterFilter from 'screens/platform/cross-platform-components/context/MasterFiltersContext/PeopleMasterFilter';
import {
  useSelectedPeopleFromMasterFilter,
} from 'screens/platform/cross-platform-components/context/MasterFiltersContext/PeopleMasterFilter/SelectedPeopleFromMasterFilterHook';
import usePersonsMetadata from 'screens/platform/cross-platform-components/context/metadata/hooks/personsMetadataHook';
import { useMetadataContext } from 'screens/platform/cross-platform-components/context/metadata/MetadataContext';
import {
  EMPTY_SELECTION_LABEL,
  getAmountsOnly,
} from 'screens/platform/cross-platform-components/MasterFiltersPanel/masterFiltersUtils/index';
import joinOneOrTwoElements, {
  hasOneOrTwoElements,
} from 'screens/platform/cross-platform-components/MasterFiltersPanel/masterFiltersUtils/MasterFiltersLabelsUtils';

const ALL_PEOPLE_SELECTION_LABEL = 'All People';
const UNKNOWN_PERSON_NAME = 'Unknown person';

function useRelevantPeopleData(peopleIdsToFetch: string[], showDepartmentsOnly: boolean) {
  const { value: peopleData } = usePersonsMetadata(
    showDepartmentsOnly ? [] : peopleIdsToFetch,
  );
  return peopleData;
}

export default function usePeopleLabel(
  peopleFilter: PeopleMasterFilter,
  showDepartmentsOnly = false,
): ReactNode {
  const { persons: { allPeopleMetadata, departmentsToPersonsMap } } = useMetadataContext();
  const selectedPeopleIds = useSelectedPeopleFromMasterFilter(peopleFilter, showDepartmentsOnly);
  const peopleIdsToFetch = useMemo(
    () => (!showDepartmentsOnly && selectedPeopleIds.length < 3 ? selectedPeopleIds : []),
    [selectedPeopleIds],
  );

  const peopleData = useRelevantPeopleData(peopleIdsToFetch, showDepartmentsOnly);

  return useMemo(() => {
    if (peopleFilter.isAllSelected()) return ALL_PEOPLE_SELECTION_LABEL;
    if (peopleFilter.isEmpty()) return EMPTY_SELECTION_LABEL;

    const selectedDepartments = peopleFilter.departments;
    const noSelectedPeopleOutsideDepartments = peopleFilter.internalPeopleIds
      && peopleFilter.internalPeopleIds.every((personId) => {
        const personMetadata = allPeopleMetadata[personId];
        return personMetadata
          && (selectedDepartments === null
          || selectedDepartments.includes(personMetadata.department));
      });
    const areAllDepartmentsFullySelected = selectedDepartments
      && selectedDepartments.every((department) => {
        const departmentPeople = departmentsToPersonsMap[department];
        return departmentPeople
          && departmentPeople.every((personId) => selectedPeopleIds.includes(personId));
      });
    const isDepartmentsOnlySelection = noSelectedPeopleOutsideDepartments
      && areAllDepartmentsFullySelected;

    if (showDepartmentsOnly || isDepartmentsOnlySelection) {
      if (selectedDepartments === null) return ALL_PEOPLE_SELECTION_LABEL;
      if (hasOneOrTwoElements(selectedDepartments)) {
        return joinOneOrTwoElements(selectedDepartments, SingularPluralNouns.department);
      }
      return getAmountsOnly([
        { amount: selectedDepartments.length, nouns: SingularPluralNouns.department },
      ]);
    }

    if (hasOneOrTwoElements(selectedPeopleIds)) {
      if (!peopleData) return <Spinner size={Size.SMALL} />;
      const getPersonName = (personId: string) => peopleData[personId]?.name
          || UNKNOWN_PERSON_NAME;
      return joinOneOrTwoElements(selectedPeopleIds, SingularPluralNouns.person, getPersonName);
    }
    return getAmountsOnly([{
      amount: selectedPeopleIds.length,
      nouns: SingularPluralNouns.person,
    }]);
  }, [peopleFilter, peopleData]);
}
