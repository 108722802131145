import { useEffect } from 'react';

import Person from 'model/Person';
import { EXTERNAL_ORGANIZATIONS_DEPARTMENT } from 'model/Tenant';
import { useMetadataContext } from 'screens/platform/cross-platform-components/context/metadata/MetadataContext';
import DebuggerConsole from 'utils/DebuggerConsole';
import { useMountedState } from 'utils/hooks';

export default function usePersonTooltipSubtitle(person: Person): {
  subtitle: string;
  loading: boolean;
} {
  const [organizationName, setOrganizationName] = useMountedState('');
  const [loading, setLoading] = useMountedState(person.isExternal);

  const { organizations } = useMetadataContext();

  useEffect(() => {
    if (person.isExternal) {
      setLoading(true);

      organizations.getById(person.organizationId)
        .then((organization) => {
          if (organization !== undefined) {
            setOrganizationName(organization.name ?? organization.domain);
          }
        })
        .catch((err) => {
          DebuggerConsole.error('Unable to fetch organization', { organizationId: person.organizationId, err });
          setOrganizationName(EXTERNAL_ORGANIZATIONS_DEPARTMENT);
        })
        .finally(() => setLoading(false));
    }
  }, [person]);

  return person.isExternal
    ? { subtitle: organizationName, loading }
    : { subtitle: person.department, loading };
}
