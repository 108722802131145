import React from 'react';

import Role from 'global/lists/Role';
import useOrganizationFeatureConfig
  from 'screens/platform/cross-platform-components/context/configuration/OrganizationFeatureConfigHook';
import { useMetadataContext } from 'screens/platform/cross-platform-components/context/metadata/MetadataContext';
import OrganizationsMetadataContextProvider from 'screens/platform/cross-platform-components/context/OrganizationsMetadata/OrganizationsMetadataContextProvider';
import { useUserContext } from 'screens/platform/cross-platform-components/context/user/UserContext';
import LeftPanel from 'screens/platform/cross-platform-components/LeftPanel';
import AppsFilterSection
  from 'screens/platform/cross-platform-components/MasterFiltersPanel/FilterSection/AppsFilterSection/index';
import CategoriesFilterSection
  from 'screens/platform/cross-platform-components/MasterFiltersPanel/FilterSection/CategoriesFilterSection';
import DatesRangeFilterSection
  from 'screens/platform/cross-platform-components/MasterFiltersPanel/FilterSection/DatesRangeFilterSection';
import DirectionalityFilterSection
  from 'screens/platform/cross-platform-components/MasterFiltersPanel/FilterSection/DirectionalityFilterSection';
import OrganizationsFilterSection
  from 'screens/platform/cross-platform-components/MasterFiltersPanel/FilterSection/OrganizationsFilterSection/index';
import PeopleFilterSection
  from 'screens/platform/cross-platform-components/MasterFiltersPanel/FilterSection/PeopleFilterSection';
import MasterFiltersPanelHeader
  from 'screens/platform/cross-platform-components/MasterFiltersPanel/MasterFiltersPanelHeader';

import masterFiltersPanelStyles
  from 'screens/platform/cross-platform-components/MasterFiltersPanel/masterFiltersPanelStyles.module.scss';

function useMasterFiltersAccessValidator() {
  const { isUserAllowed } = useUserContext();
  const MINIMUM_ROLE_TO_VIEW_MASTER_FILTERS = Role.ANALYST;
  return isUserAllowed(MINIMUM_ROLE_TO_VIEW_MASTER_FILTERS);
}

export default function MasterFiltersPanel() {
  const { connectedApps } = useMetadataContext();
  const { value: isOrganizationsFeatureEnabled } = useOrganizationFeatureConfig();
  const shouldShowMasterFilters = useMasterFiltersAccessValidator();

  if (!connectedApps || !shouldShowMasterFilters) {
    return null;
  }

  return (
    <LeftPanel>
      <div className={masterFiltersPanelStyles.masterFilters}>
        <MasterFiltersPanelHeader />
        <div className={masterFiltersPanelStyles.filtersContainer}>
          <div>
            <DatesRangeFilterSection />
            <CategoriesFilterSection />
            <PeopleFilterSection />
            {isOrganizationsFeatureEnabled && (
              <>
                <DirectionalityFilterSection />
                <OrganizationsMetadataContextProvider>
                  <OrganizationsFilterSection />
                </OrganizationsMetadataContextProvider>
              </>
            )}
            <AppsFilterSection />
          </div>
        </div>
      </div>
    </LeftPanel>
  );
}
