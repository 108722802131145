import classNames from 'classnames';
import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

const type = 'DraggableColumnHeaderCell';

type Props = {
  index: number;
  onDrop: (fromIndex: number, toIndex: number) => void;
  className?: string;
};

type DragObject = { index: number };
type DropResult = void;
type CollectedProps = { isOver?: boolean; dropClassName?: string };

export default function DraggableColumnHeaderCell({
  index,
  onDrop,
  className,
  ...restProps
}: Props) {
  const ref = useRef(null);
  const [{ isOver, dropClassName }, drop] = useDrop<
    DragObject,
    DropResult,
    CollectedProps
  >({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex > index ? 'drop-over-left' : 'drop-over-right',
      };
    },
    drop: (item) => {
      onDrop(item.index, index);
    },
  });
  const [, drag] = useDrag({
    type,
    item: { index },
  });
  drop(drag(ref));

  return (
    <th
      ref={ref}
      className={classNames(
        className,
        isOver && dropClassName,
        'draggable-header-cell',
      )}
      {...restProps}
    />
  );
}
