import React, { ReactNode, useState } from 'react';

import SettingsPopover from 'common-ui-components/SettingsPopover/index';

export interface SimpleSettingsPopoverProps {
  buttonClassName?: string;
  children: ReactNode;
}

/**
 * As opposed to `SettingsPopover`, this popover doesn't close when the user clicks its contents
 */
export default function SimpleSettingsPopover({
  buttonClassName,
  children,
}: SimpleSettingsPopoverProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <SettingsPopover
      buttonClassName={buttonClassName}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      {children}
    </SettingsPopover>
  );
}
