import React, {
  ReactNode, Suspense,
} from 'react';

import GlobalModalContext from 'screens/platform/cross-platform-components/context/globalModal/GlobalModalContext';
import useGlobalModalReducer, { OpenGlobalModalArgs } from 'screens/platform/cross-platform-components/context/globalModal/GlobalModalReducer';
import lazyWithRetry from 'utils/lazyWithRetry';

const GlobalModal = lazyWithRetry(() => import('screens/platform/cross-platform-components/GlobalModal'));

export default function GlobalModalContextProvider({ children }: { children: ReactNode }) {
  const [globalModalState, dispatchGlobalModal] = useGlobalModalReducer();

  return (
    <GlobalModalContext.Provider value={{
      openGlobalModal: (args: OpenGlobalModalArgs) => dispatchGlobalModal({ type: 'OPEN_GLOBAL_MODAL', payload: args }),
      closeGlobalModal: () => dispatchGlobalModal({ type: 'CLOSE_GLOBAL_MODAL' }),
      preventInteractionsDrawerClosing: globalModalState.preventInteractionsDrawerClosing ?? false,
    }}
    >
      <Suspense fallback={null}>
        <GlobalModal {...globalModalState} />
      </Suspense>
      {children}
    </GlobalModalContext.Provider>
  );
}
