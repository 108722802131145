import classNames from 'classnames';
import React, {
  FC, ReactNode, SVGProps,
  useState,
} from 'react';

import Button from 'common-ui-components/Button';
import CardContainer from 'common-ui-components/CardContainer';
import Content from 'es-src/screens/HomeScreen/components/ResultSection/SearchResultCard/Content';

import style from 'es-src/screens/HomeScreen/components/ResultSection/SearchResultCard/style.module.scss';

interface Props {
  icon: FC<SVGProps<SVGSVGElement>>;
  title: string;
  children: ReactNode;
  collapsed?: boolean;
  collapsedText?: string;
  hasError?: boolean;
  className?: string;
}

export default function SearchResultCard({
  className,
  icon: Icon,
  title,
  collapsed,
  collapsedText,
  children,
  hasError,
}: Props) {
  const [expandClicked, setExpandClicked] = useState(false);

  return (
    <CardContainer className={classNames(
      style.searchResultCard,
      className,
      !expandClicked && collapsed && style.collapsed,
    )}
    >
      <Content hasError={hasError} title={title} icon={Icon}>
        {children}
      </Content>
      {!expandClicked && collapsed && (
        <Button
          className={style.showResultsButton}
          onClick={() => setExpandClicked(true)}
        >
          {collapsedText}
        </Button>
      )}
    </CardContainer>
  );
}
