import React from 'react';

import HiddenEntitiesNode from 'common-ui-components/MultiImages/HiddenEntitiesNode';
import { ImageShape } from 'common-ui-components/MultiImages/NodeImage';
import Size from 'global/lists/Size';

import style from 'screens/platform/cross-platform-components/Interactions/components/InteractionsTable/components/LinkedInteractions/style.module.scss';

type Props = {
  notAllowedCount: number;
  description: string;
};

export default function AdditionalPrivateInteractions({ notAllowedCount, description }: Props) {
  return notAllowedCount === 0
    ? null
    : (
      <HiddenEntitiesNode
        entities={[`There are ${notAllowedCount} additional private ${description}.`]}
        size={Size.MEDIUM}
        dark
        shape={ImageShape.ROUND}
        className={style.additionalPrivateNode}
      />
    );
}
