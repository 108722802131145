import classNames from 'classnames';
import React, {
  ChangeEvent, Dispatch, RefObject, useEffect, useRef, useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import TextareaAutosize from 'react-textarea-autosize';

import BetaBadge from 'common-ui-components/Badge/BetaBadge';
import Button from 'common-ui-components/Button';
import { useEnterpriseSearchContext } from 'es-src/screens/HomeScreen/EnterpriseSearchContext/EnterpriseSearchContext';
import {
  ENTERPRISE_SEARCH_QUESTION_KEY,
} from 'screens/platform/cross-platform-components/context/UrlParams/UrlParamsGenerator';
import DebuggerConsole from 'utils/DebuggerConsole';
import { useKeystrokesListener, useQuery } from 'utils/hooks';

import { ReactComponent as SearchButtonIcon } from 'assets/img/icon/arrow-right-icon.svg';
import { ReactComponent as ClearIcon } from 'assets/img/icon/x-icon.svg';

import style from 'es-src/screens/HomeScreen/components/EnterpriseSearch/SearchInput/style.module.scss';

type Props = {
  searchQuery: string;
  setSearchQuery: Dispatch<React.SetStateAction<string>>;
  placeholder: string;
  customSearchHandler?: () => void;
  compact?: boolean;
  customInputRef?: RefObject<HTMLTextAreaElement>;
  isDropdownOpen?: boolean;
  isEntityClicked?: boolean;
  autofocus?: boolean;
}

const MAX_LENGTH = 150;

export default function SearchInput({
  searchQuery,
  setSearchQuery,
  placeholder,
  customSearchHandler,
  autofocus = false,
  compact = false,
  customInputRef,
  isDropdownOpen,
  isEntityClicked,
}: Props) {
  const query = useQuery();
  const history = useHistory();
  const {
    originalQuestion, loading, handleEnterpriseSearch, handleAbortSearch, isResultCardOpen,
  } = useEnterpriseSearchContext();

  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [focused, setFocused] = useState(autofocus);
  const compactMode = compact || isResultCardOpen || loading;

  useEffect(() => {
    const questionParam = query.get(ENTERPRISE_SEARCH_QUESTION_KEY);
    if (questionParam && !loading) {
      setSearchQuery(questionParam);
      handleSearch(questionParam);
    }
  }, []);

  useEffect(() => {
    const handlePopState = () => {
      const questionParam = new URLSearchParams(history.location.search)
        .get(ENTERPRISE_SEARCH_QUESTION_KEY);
      if (questionParam) {
        handleSearch(questionParam);
      } else {
        setSearchQuery('');
        history.go(0);
      }
    };

    window.addEventListener('popstate', handlePopState);
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  useEffect(() => {
    setSearchQuery(originalQuestion);
    if (originalQuestion) {
      const questionParam = new URLSearchParams(history.location.search)
        .get(ENTERPRISE_SEARCH_QUESTION_KEY);
      if (questionParam !== originalQuestion) {
        history.push(`?s=${originalQuestion}`);
      }
    }
  }, [originalQuestion]);

  const handleInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setSearchQuery(event.target.value);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !isSearchButtonDisabled && !isEntityClicked) {
      event.preventDefault();
      handleSearch(searchQuery);
    }
  };

  const handleFocus = () => setFocused(true);
  const handleBlur = () => setFocused(false);

  function handleSearch(value: string) {
    setFocused(false);
    if (customSearchHandler) {
      customSearchHandler();
      return;
    }
    handleEnterpriseSearch(value, 'userInput');
    DebuggerConsole.log('Search value:', value);
  }
  const isSearchButtonDisabled = searchQuery.length < 1;

  function handleClearIconClick() {
    if (loading) {
      handleAbortSearch(searchQuery);
    } else {
      setSearchQuery('');
    }
    inputRef.current?.focus();
    customInputRef?.current?.focus();
  }

  function handleSearchIconClick() {
    handleSearch(searchQuery);
  }

  useEffect(() => {
    if (!loading && inputRef.current && !isSearchButtonDisabled) {
      const inputElement = inputRef.current;
      inputElement.focus();
      // Move the cursor to the end of the input value
      const valueLength = inputElement.value.length;
      inputElement.setSelectionRange(valueLength, valueLength);
    }
  }, [loading, isSearchButtonDisabled]);

  useKeystrokesListener({
    '/': () => {
      setTimeout(() => {
        inputRef.current?.focus();
        customInputRef?.current?.focus();
      }, 1); // So the '/' won't be typed after the input element is focused
    },
  });

  const isClear = originalQuestion !== '' && searchQuery !== '' && (loading || originalQuestion === searchQuery);

  return (
    <div className={classNames(
      style.searchContainer,
      loading && style.disabled,
      focused && style.focused,
      compactMode && style.compact,
      isDropdownOpen && !loading && style.autoComplete,
    )}
    >
      <TextareaAutosize
        placeholder={placeholder}
        value={searchQuery}
        onChange={handleInputChange}
        onKeyDown={handleKeyPress}
        maxLength={MAX_LENGTH}
        ref={customInputRef ?? inputRef}
        autoFocus={autofocus}
        spellCheck={false}
        disabled={loading}
        onFocus={handleFocus}
        onBlur={handleBlur}
        className={style.searchInput}
      />

      <div className={style.actions}>
        <BetaBadge disabled={loading} />
        { isClear
          ? (
            <Button
              transparent
              onClick={handleClearIconClick}
              className={style.searchButtonIcon}
              disabled={isSearchButtonDisabled}
              data-testid="clear-button"
            >
              <ClearIcon className={style.clearIcon} />
            </Button>
          )
          : (
            <Button
              transparent
              onClick={handleSearchIconClick}
              className={style.searchButtonIcon}
              disabled={isSearchButtonDisabled}
              data-testid="search-button"
            >
              <SearchButtonIcon className={style.searchIcon} />
            </Button>
          )}
      </div>
    </div>
  );
}
