import React from 'react';

import Tooltip from 'common-ui-components/Tooltip';

import style from 'screens/platform/contentScreens/AnalyticsScreen/widgets/ShareOfAttentionWidget/visualizations/CustomTicks/style.module.scss';

interface Props {
  data: string[];
}

const MAX_CHARACTERS = 21;

export default function CustomTicks({ data }: Props) {
  return (
    <div className={style.shareOfAttentionTicks}>
      {data.map((tick) => (
        <div key={tick} className={style.tick}>
          {tick.length <= MAX_CHARACTERS ? (
            tick
          ) : (
            <Tooltip content={tick}>
              <span className={style.truncatedTick}>{`${tick.substring(0, MAX_CHARACTERS)}...`}</span>
            </Tooltip>
          )}
        </div>
      ))}
    </div>
  );
}
