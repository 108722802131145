import { useEffect } from 'react';

import { useMetadataContext } from 'screens/platform/cross-platform-components/context/metadata/MetadataContext';
import { useMountedState } from 'utils/hooks';
import LocalStorageUtils, { InternalFeatureStorageKey } from 'utils/LocalStorageUtils';

export default function useOrganizationImage(organizationId: string | null): string | null {
  const { organizations: { getOrganizationImageById } } = useMetadataContext();
  const [image, setImage] = useMountedState<string | null>(null);

  useEffect(() => {
    const shouldAnonymize = LocalStorageUtils.getItem<boolean>(
      InternalFeatureStorageKey.SHOULD_ANONYMIZE_ORGANIZATIONS,
    );
    if (organizationId !== null && shouldAnonymize !== true) {
      getOrganizationImageById(organizationId).then((nextImage) => setImage(nextImage || null));
    }
  }, [organizationId]);

  return image;
}
