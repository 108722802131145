import { useCallback, useMemo } from 'react';

import { TableColumn } from 'common-ui-components/Table';
import { TableColumnsOrder } from 'common-ui-components/Table/TableTypes';
import FilterConfig, { FiltersConfig, FilterValue } from 'screens/platform/directory/components/GenericDirectoryScreen/FiltersList/FilterConfig';

export interface DirectoryTableColumn<
  T,
  TableColumnId extends string,
  AlternativeSortingKey = never
> extends Omit<TableColumn<T>, 'sorting'> {
  key: TableColumnId;
  customSortingConfig?: AlternativeSortingKey;
}

export function convertDirectoryTableColumnToTableColumn <
  T,
  TableColumnId extends string,
  AlternativeSortingKey = never
>(
  column: DirectoryTableColumn<T, TableColumnId, AlternativeSortingKey>,
  isSortable: boolean,
  filterConfig?: FilterConfig<FilterValue>,
): TableColumn<T, TableColumnId> & { key: TableColumnId } {
  return {
    ...column,
    sorting: isSortable ? { byKey: true } : undefined,
    filterConfig,
  };
}

export default function useDirectoryTableColumnsHook<
  T,
  TableColumnId extends string,
  AlternativeSortingKey = never
>(
  allColumns: DirectoryTableColumn<T, TableColumnId, AlternativeSortingKey>[],
  sortableColumnsIds: Readonly<TableColumnId[]>,
  columnsOrder: TableColumnsOrder<TableColumnId>,
  defaultColumnsOrder: TableColumnsOrder<TableColumnId>,
  setColumnsOrder: (nextColumns: TableColumnsOrder<TableColumnId>) => void,
  filtersConfig?: FiltersConfig<string>,
): {
  columns: (TableColumn<T, TableColumnId> & { key: TableColumnId })[];
  onColumnMoved: (fromIndex: number, toIndex: number) => void;
} {
  let persistentColumns: [string, number][] = Object.entries(columnsOrder);

  const existingColumnsMap = allColumns.reduce<
    Record<string, DirectoryTableColumn<T, TableColumnId, AlternativeSortingKey>>
  >((acc, c) => ({ ...acc, [c.key]: c }), {});
  const areAllColumnsDefined = persistentColumns.every(([columnKey]) =>
    existingColumnsMap[columnKey] !== undefined);

  if (!areAllColumnsDefined) {
    setColumnsOrder(defaultColumnsOrder);
    persistentColumns = Object.entries(defaultColumnsOrder);
  }

  const columns = useMemo(
    () =>
      persistentColumns
        .sort(([, indexA], [, indexB]) => indexA - indexB)
        .reduce<(TableColumn<T, TableColumnId> & { key: TableColumnId })[]>((acc, [columnId]) => {
          const column = allColumns.find((c) => c.key === columnId);
          if (!column) return acc;

          const tableColumn = convertDirectoryTableColumnToTableColumn(
            column,
            sortableColumnsIds.includes(column.key),
            filtersConfig?.[column.key],
          );
          return [...acc, tableColumn];
        }, []),
    [columnsOrder, allColumns, filtersConfig],
  );

  const onColumnMoved = useCallback(
    (fromIndex: number, toIndex: number) => {
      const nextColumnsArray = [...columns];
      const item = nextColumnsArray.splice(fromIndex, 1)[0];
      nextColumnsArray.splice(toIndex, 0, item);
      const nextColumnsConfig = nextColumnsArray.reduce(
        (acc, c, index) => ({
          ...acc,
          [c.key]: index,
        }),
        {},
      );
      setColumnsOrder(nextColumnsConfig);
    },
    [setColumnsOrder, columns],
  );

  return { columns, onColumnMoved };
}
