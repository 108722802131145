import React, { FC } from 'react';

import Metric from 'global/lists/Metric';
import LastEngagementMetricValue from 'screens/platform/directory/components/metricValue/LastEngagementMetricValue';
import MetricValueProps from 'screens/platform/directory/components/metricValue/MetricValueProps';
import NumericMetricValue from 'screens/platform/directory/components/metricValue/NumericMetricValue';

type PersonMetric = Extract<Metric,
  | Metric.PERSON_LAST_INTERACTION
  | Metric.PERSON_TIME_IN_MEETINGS_LAST_7_DAYS
  | Metric.PERSON_TIME_IN_MEETINGS_LAST_28_DAYS
  | Metric.PERSON_TIME_IN_MEETINGS_LAST_3_MONTHS
  | Metric.PERSON_TIME_IN_MEETINGS_LAST_6_MONTHS
  | Metric.PERSON_TIME_IN_MEETINGS_LAST_9_MONTHS
  | Metric.PERSON_TIME_IN_MEETINGS_LAST_12_MONTHS
>

export default function PersonMetricValue({ metricValue, metric }: MetricValueProps) {
  const metricToComponent: Record<PersonMetric, FC<MetricValueProps>> = Object.freeze({
    [Metric.PERSON_LAST_INTERACTION]: LastEngagementMetricValue,
    [Metric.PERSON_TIME_IN_MEETINGS_LAST_7_DAYS]: NumericMetricValue,
    [Metric.PERSON_TIME_IN_MEETINGS_LAST_28_DAYS]: NumericMetricValue,
    [Metric.PERSON_TIME_IN_MEETINGS_LAST_3_MONTHS]: NumericMetricValue,
    [Metric.PERSON_TIME_IN_MEETINGS_LAST_6_MONTHS]: NumericMetricValue,
    [Metric.PERSON_TIME_IN_MEETINGS_LAST_9_MONTHS]: NumericMetricValue,
    [Metric.PERSON_TIME_IN_MEETINGS_LAST_12_MONTHS]: NumericMetricValue,
  });

  const Component = metricToComponent[metric];
  return <Component metric={metric} metricValue={metricValue} />;
}
