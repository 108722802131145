import Tippy, { TippyProps } from '@tippyjs/react';
import React, { ReactNode } from 'react';

import 'tippy.js/animations/scale.css';

import 'common-ui-components/tooltipStyle.scss';

const MAX_TOOLTIP_WIDTH = 220;

export interface TooltipProps extends Omit<TippyProps, 'appendTo'> {
  content: ReactNode;
}

export default function Tooltip(props: TooltipProps) {
  const {
    children, maxWidth, animation = 'scale', ...inlineProps
  } = props;
  return props.content === null
    ? <>{children}</>
    : (
      <Tippy
        {...inlineProps}
        animation={animation}
        maxWidth={maxWidth ?? MAX_TOOLTIP_WIDTH}
        appendTo={inlineProps.interactive ? () => document.body : undefined}
      >
        {children}
      </Tippy>
    );
}
