import React from 'react';

import Badge from 'common-ui-components/Badge';
import ColorSquare from 'common-ui-components/ColorSquare';
import { ImageShape } from 'common-ui-components/MultiImages/NodeImage';
import Tooltip from 'common-ui-components/Tooltip';
import { NounsType } from 'global/lists/SingularPluralNouns';
import Size from 'global/lists/Size';

import style from 'common-ui-components/HiddenItemsTooltip/style.module.scss';

interface Props {
  items: string[];
  colorMapper?: (item: string) => string;
  noun: NounsType;
  maxItems?: number;
  shape?: ImageShape;
}

export default function HiddenItemsTooltip({
  items, colorMapper, noun, maxItems = 10, shape = ImageShape.ROUND,
}: Props) {
  if (items.length === 0) return null;

  const content = (() => {
    const hiddenItemsCount = items.length - maxItems;
    const hiddenItemsLabel = hiddenItemsCount === 1 ? noun.singular : noun.plural;

    return (
      <div className={style.hiddenItemsTooltip}>
        {items.slice(0, maxItems).map((item) => (
          <div key={item} className={style.item}>
            {colorMapper && (
              <ColorSquare color={colorMapper(item)} size={Size.SMALL} />
            )}
            <span>{item}</span>
          </div>
        ))}
        {hiddenItemsCount > 0
          ? `+${hiddenItemsCount} more ${hiddenItemsLabel.toLowerCase()}`
          : null}
      </div>
    );
  })();

  return (
    <Tooltip content={content}>
      <div className={style.hiddenItemsBadge}>
        <Badge text={`+${items.length}`} className={shape === ImageShape.SQUARE ? style.rounded : undefined} />
      </div>
    </Tooltip>
  );
}
