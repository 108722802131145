import { getAmountLabel, NounsType } from 'global/lists/SingularPluralNouns';
import DebuggerConsole from 'utils/DebuggerConsole';

export type SingleOrTuple<T> = [T] | [T, T];

export function hasOneOrTwoElements<T>(elements: T[] | null): elements is SingleOrTuple<T> {
  return elements !== null
    && (elements.length === 1 || elements.length === 2);
}

export default function joinOneOrTwoElements(
  elements: SingleOrTuple<string>,
  nounsLabels: NounsType,
  mapper: (value: any) => string = (value: string) => value,
): string {
  try {
    const mappedElements = elements.map(mapper);
    if (mappedElements.length === 1 || mappedElements[0] === mappedElements[1]) {
      return mappedElements[0];
    }
    return mappedElements.join(' or ');
  } catch (err) {
    DebuggerConsole.error(err);
    return getAmountLabel(elements.length, nounsLabels);
  }
}
