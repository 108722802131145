import React, { ReactNode } from 'react';

import Button from 'common-ui-components/Button';

interface ConditionalButtonWrapperProps {
  isButton: boolean;
  onClick: (e: React.MouseEvent) => void;
  children: ReactNode;
  className?: string;
}

export default function ConditionalButtonWrapper({
  isButton,
  className,
  onClick,
  children,
}: ConditionalButtonWrapperProps) {
  return isButton
    ? (
      <Button className={className} onClick={onClick}>
        { children }
      </Button>
    ) : (
      <div className={className}>
        { children }
      </div>
    );
}
