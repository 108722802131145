import { format } from 'date-fns';
import React from 'react';

import EmptyMetricValue from 'screens/platform/directory/components/metricValue/EmptyMetricValue';
import MetricDateDisplay from 'screens/platform/directory/components/metricValue/MetricDateDisplay';
import DateUtils from 'utils/DateUtils';

interface Props {
  date?: string;
}

export default function OrganizationDateMetric({ date }: Props) {
  if (!date) return <EmptyMetricValue />;

  const timeAgoLabel = DateUtils.getTimeAgoLabel(new Date(date));
  const dateLabel = format(new Date(date), DateUtils.DateFormat.WEEKDAY_MONTH_DAY_TH);

  return <MetricDateDisplay timeAgoLabel={timeAgoLabel} dateLabel={dateLabel} />;
}
