import React from 'react';

import FilterConfig, {
  FilterValue,
} from 'screens/platform/directory/components/GenericDirectoryScreen/FiltersList/FilterConfig';
import GenericFilter from 'screens/platform/directory/components/ProfileTabs/components/Filters/GenericFilter';
import useAppsFilterConfig
  from 'screens/platform/directory/components/ProfileTabs/components/Filters/hooks/AppsFilterConfigHook';

interface Props {
  disabled?: boolean;
}

export default function AppsFilter({ disabled }: Props) {
  const filterConfig = useAppsFilterConfig() as FilterConfig<FilterValue>;

  return (
    <GenericFilter
      filterType="apps"
      filterConfig={filterConfig}
      disabled={disabled}
    />
  );
}
