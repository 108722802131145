import { useAsync } from 'react-use';

import Api from 'global/api/platformApi';
import useTenantContext from 'screens/platform/cross-platform-components/context/tenant/TenantContext';

export default function useSuggestedQuestions() {
  const {
    tenant: { id: tenantId },
  } = useTenantContext();
  const { value: suggestedQuestions, loading } = useAsync(async () => {
    const response = await Api.EnterpriseSearch.getSuggestedQuestions(tenantId);
    return response?.data ?? [];
  }, [tenantId]);

  return {
    suggestedQuestions,
    loading,
  };
}
