export default {
  getDomain(url: string): string | null {
    try {
      return new URL(url)
        .hostname
        .replace('www.', '')
        .trim();
    } catch (e) {
      return null;
    }
  },
};
