import React, {
  ReactNode,
  useEffect,
  useState,
} from 'react';

import { AtomsCount } from 'global/api/controller/AtomController';
import MasterFiltersContext, {
  MasterFiltersContextType,
} from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFiltersContext';
import MasterFiltersReducer from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFiltersReducer';
import { useDefaultMasterFilters } from 'screens/platform/cross-platform-components/context/MasterFiltersContext/utils/DefaultMasterFiltersUtils';
import useMasterFiltersVersion
  from 'screens/platform/cross-platform-components/context/MasterFiltersContext/utils/MasterFiltersVersionHook';
import useMasterFiltersFromUrlParams
  from 'screens/platform/cross-platform-components/context/UrlParams/convertUrlParamsToMasterFilters';
import { useCustomReducer } from 'utils/hooks';
import ObjectUtils from 'utils/ObjectUtils';

export function useMasterFiltersContextProvider(): MasterFiltersContextType {
  const defaultCurrentFilters = useDefaultMasterFilters();
  const [totalAtomsCount, setTotalAtomsCount] = useState<AtomsCount | null>(null);
  const masterFiltersFromUrl = useMasterFiltersFromUrlParams();

  const [masterFilters, dispatchMasterFilters] = useCustomReducer(MasterFiltersReducer, {
    currentFilters: {
      ...defaultCurrentFilters,
      ...masterFiltersFromUrl,
    },
    defaultState: ObjectUtils.deepClone(defaultCurrentFilters),
    shouldPushParamsToUrl: false,
    shouldCommChartIgnoreChange: false,
    overriddenFiltersOriginalValues: null,
  });

  useEffect(() => {
    if (!ObjectUtils.equals(defaultCurrentFilters, masterFilters.defaultState)) {
      dispatchMasterFilters({ type: 'CHANGE_DEFAULT_FILTERS', payload: { nextDefaultFilters: defaultCurrentFilters } });
    }
  }, [defaultCurrentFilters]);

  const currentVersion = useMasterFiltersVersion(masterFilters.currentFilters.version);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { defaultState, ...masterFiltersState } = masterFilters;

  return {
    ...masterFiltersState,
    dispatchMasterFilters,
    totalAtomsCount,
    setTotalAtomsCount,
    currentVersionRef: currentVersion,
  };
}

export default function MasterFiltersContextProvider({ children }: {
  children: ReactNode;
}) {
  const value = useMasterFiltersContextProvider();
  return (
    <MasterFiltersContext.Provider value={value}>
      { children }
    </MasterFiltersContext.Provider>
  );
}
