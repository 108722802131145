import { useMemo } from 'react';

import NullableValuesSelection
, {
  NullableValuesSelectionConstants,
} from 'screens/platform/cross-platform-components/context/MasterFiltersContext/NullableValuesSelection';
import ArrayUtils from 'utils/ArrayUtils';
import DebuggerConsole from 'utils/DebuggerConsole';
import HierarchicalGroup from 'utils/HierarchicalDataStructures/HierarchicalGroup';
import HierarchicalInterface from 'utils/HierarchicalDataStructures/HierarchicalInterface';

const { UNDEFINED_PROPERTY } = NullableValuesSelectionConstants;

const FilterSectionUtils = {
  toggleMultipleHierarchicalEntities(
    isSelected: boolean,
    groups: (HierarchicalInterface | undefined)[],
  ) {
    groups
      .filter(ArrayUtils.isDefined)
      .forEach((group) =>
        (isSelected ? group.select() : group.deselect()));
  },

  handleGroupToggle(
    id: string | null,
    groupsSelection: HierarchicalGroup,
    itemsSelection: HierarchicalGroup,
    groupToItemsMap: Partial<Record<string, string[]>>,
    handleItemChange: (id: string, isSelected: boolean) => void,
  ) {
    if (id === null) {
      const isSelected = groupsSelection.toggle();
      if (isSelected) {
        groupsSelection.getParent()?.select();
      } else {
        groupsSelection.getParent()?.deselect();
      }
    } else { // Specific item (e.g. department) was toggled
      const groupSelection = groupsSelection.getChild(id);
      if (!groupSelection) return;

      const isSelected = groupSelection.toggle();
      const matchingItemsIds = groupToItemsMap[id];
      if (!matchingItemsIds) return;

      matchingItemsIds.forEach((itemId) => handleItemChange(itemId, isSelected));
    }
  },

  convertToNullableValuesSelection<T extends string>(
    group: HierarchicalGroup,
  ): NullableValuesSelection<T> {
    const undefinedSelection = group.getChild(UNDEFINED_PROPERTY);

    let includeUndefined;
    if (undefinedSelection?.isParent()) {
      includeUndefined = undefinedSelection.isSelected || undefinedSelection.isPartiallySelected;
    } else {
      DebuggerConsole.error('Group should include UNDEFINED option', group.id);
      includeUndefined = true;
    }

    const selectedChildrenIds = group.getSelectedItems(true);
    const definedSelectedChildrenIds = selectedChildrenIds
      .filter((childId) => childId !== UNDEFINED_PROPERTY);
    const allChildren = group.getChildren();

    const areAllSelected = allChildren.some((child) => child.id === UNDEFINED_PROPERTY)
      ? allChildren.length - 1 === definedSelectedChildrenIds.length
      : allChildren.length === definedSelectedChildrenIds.length;
    const selected = areAllSelected
      ? null
      : definedSelectedChildrenIds as T[];
    return new NullableValuesSelection<T>(selected, Boolean(includeUndefined));
  },

  checkFilterHasDefinedValues(group: HierarchicalGroup): boolean {
    return group.getChildren().some(({ id }) => id !== UNDEFINED_PROPERTY);
  },

  useShouldShowFilterColumnsView(
    selection: HierarchicalGroup | null,
    basicUnitsGroup: HierarchicalGroup | undefined,
  ): boolean {
    return useMemo(() => {
      if (!selection || !basicUnitsGroup) return false;
      return selection.getChildrenEntries()
        .filter(([groupId]) => groupId !== basicUnitsGroup.id)
        .some(([, group]) =>
          group.isParent()
        && this.checkFilterHasDefinedValues(group));
    }, [selection, basicUnitsGroup?.id]);
  },
};

export default FilterSectionUtils;
