import { RequestPaging } from 'global/api/controller/utils/commonControllerUtils';
import FilterCondition from 'global/api/controller/utils/filtering/FilterCondition';
import OrganizationFilterableProperty from 'global/api/controller/utils/filtering/OrganizationFilterableProperty';
import {
  OrganizationRequestSorting,
} from 'global/api/controller/utils/OrganizationControllerSortingUtils';
import { getRequest, HttpResponse, postRequest } from 'global/api/platformApiHelpers';
import AggregatedOrganizationMetric from 'global/lists/AggregatedOrganizationMetric';
import Metric from 'global/lists/Metric';
import Organization, {
  EnrichedOrganization, OrganizationProperties, OrganizationRisk, OrganizationSize,
} from 'model/Organization';
import Tenant from 'model/Tenant';
import AllOrganizationsMetadata
  from 'screens/platform/cross-platform-components/context/OrganizationsMetadata/dataStructures/AllOrganizationsMetadata';
import OrganizationsTableCommonColumnId from 'screens/platform/directory/organizations/OrganizationsDirectoryScreen/OrganizationsTable/utils/OrganizationsTablePropertyColumnId';
import OrganizationWithMetrics
  from 'screens/platform/directory/organizations/OrganizationsDirectoryScreen/OrganizationsTable/utils/OrganizationWithMetrics';
import LocalStorageUtils, { InternalFeatureStorageKey } from 'utils/LocalStorageUtils';

export type EditOrganizationPayload = Partial<
  Pick<Organization, 'name' | 'isEnabled'> &
  Pick<OrganizationProperties, 'nicknames' | 'size' | 'risk' | 'segments'>
>;

interface ActiveOrganizationsRequest {
  filterConditions: FilterCondition<OrganizationFilterableProperty>[];
  sortingConfig: OrganizationRequestSorting;
  pagingConfig: RequestPaging;
  metrics: Metric[];
}

interface OrganizationsByEnabledStateRequest {
  isEnabled: boolean | null;
  searchTerm: string;
  sortingConfig: OrganizationRequestSorting;
  pagingConfig: RequestPaging;
}

interface OrganizationsByEnabledStateResponse {
  organizations: Organization[];
  totalOrganizationsCount: number;
}

interface EngagedOrganizationsResponse {
  organizations: OrganizationWithMetrics[];
  totalOrganizationsCount: number;
}

interface OrganizationFilterOptionsResponse {
  stages: string[];
  segments: string[];
}

interface AggregatedOrganizationMetricsResponse {
  metricValues: Record<AggregatedOrganizationMetric, number>;
  totalOrganizationsCount: number;
}

type ExportOrganizationsTableRequest = Omit<ActiveOrganizationsRequest, 'pagingConfig'> & {
  selectedPropertyColumns: OrganizationsTableCommonColumnId[];
}

export interface GroupedOrganizations {
  allOrganizationsIds: string[];
  risks: string[];
  sizes: string[];
  segments: string[];
}

export interface OrganizationMetadataApiResponse {
  risk: OrganizationRisk | null;
  size: OrganizationSize | null;
  segments: string[] | null;
}

export type AllOrganizationsMetadataApiResponse =
  AllOrganizationsMetadata<OrganizationMetadataApiResponse>;

function checkShouldAnonymize(): boolean | undefined {
  return LocalStorageUtils.getItem<boolean | undefined>(
    InternalFeatureStorageKey.SHOULD_ANONYMIZE_ORGANIZATIONS,
  ) ?? undefined;
}

const OrganizationController = {
  async getAllOrganizationsMetadata(tenantId: Tenant['id']): HttpResponse<AllOrganizationsMetadataApiResponse> {
    return getRequest('organization', { tenantId });
  },

  async getOrganizationsByEnabledState(
    tenantId: Tenant['id'],
    request: OrganizationsByEnabledStateRequest,
  ): HttpResponse<OrganizationsByEnabledStateResponse> {
    return postRequest('organization/settings', request, { params: { tenantId } });
  },

  async findOrganizationsByIds(
    tenantId: Tenant['id'],
    organizationIds: string[],
    abortSignal?: AbortSignal,
  ): HttpResponse<Record<string, Organization>> {
    const shouldAnonymize = checkShouldAnonymize();
    return postRequest(
      'organization',
      organizationIds,
      { params: { tenantId, showDisplayNamesOnly: true, shouldAnonymize }, signal: abortSignal },
    );
  },

  async findEnrichedOrganizationsByIds(
    tenantId: Tenant['id'],
    organizationIds: string[],
    abortSignal?: AbortSignal,
  ): HttpResponse<Record<string, EnrichedOrganization>> {
    const shouldAnonymize = checkShouldAnonymize();
    return postRequest(
      'organization',
      organizationIds,
      { params: { tenantId, showDisplayNamesOnly: false, shouldAnonymize }, signal: abortSignal },
    );
  },

  async getActiveOrganizations(
    tenantId: Tenant['id'],
    requestBody: ActiveOrganizationsRequest,
  ): HttpResponse<EngagedOrganizationsResponse> {
    const shouldAnonymize = checkShouldAnonymize();
    return postRequest(
      'organization/active-new',
      requestBody,
      { params: { tenantId, shouldAnonymize } },
    );
  },

  async getOrganizationsByQuery(
    tenantId: Tenant['id'],
    query?: string,
  ): HttpResponse<GroupedOrganizations> {
    return getRequest('organization/query', { tenantId, query });
  },

  async editOrganization(
    tenantId: Tenant['id'],
    organizationId: Organization['id'],
    organizationPayload: EditOrganizationPayload,
  ): HttpResponse<void> {
    return postRequest(`organization/${organizationId}`, organizationPayload, {
      params: { tenantId },
    });
  },

  async exportOrganizationsTable(
    tenantId: Tenant['id'],
    requestBody: ExportOrganizationsTableRequest,
  ): HttpResponse<void> {
    return postRequest('export/directory/organization-new', requestBody, {
      params: { tenantId },
    });
  },

  async getAggregatedOrganizationMetrics(
    tenantId: Tenant['id'],
    requestBody: Omit<ActiveOrganizationsRequest, 'sortingConfig' | 'pagingConfig'>,
  ): HttpResponse<AggregatedOrganizationMetricsResponse> {
    return postRequest('organization/metrics', requestBody, {
      params: { tenantId },
    });
  },

  async findOrganizationFilterOptions(
    tenantId: Tenant['id'],
  ): HttpResponse<OrganizationFilterOptionsResponse> {
    return getRequest('organization/filterOptions', { tenantId });
  },
};

export default OrganizationController;
