import { EventType } from 'model/Events/Event';

enum EventCategory {
    COLLABORATION_PATTERNS = 'Collaboration Patterns',
    WORK_EFFORTS = 'Work Efforts',
    ACCOUNT_MANAGEMENT = 'Account Management',
    ORG_STRUCTURE = 'Org Structure',
}

export const categoryToEventTypes: {[key in EventCategory]: EventType[] } = {
  [EventCategory.COLLABORATION_PATTERNS]: [EventType.COMMUNICATION_FLOW],
  [EventCategory.WORK_EFFORTS]: [
    EventType.TRENDING_TOPIC,
    EventType.CHANGE_IN_ATTENTION,
  ],
  [EventCategory.ACCOUNT_MANAGEMENT]: [
    EventType.ORGANIZATION_FIRST_ENGAGEMENT,
    EventType.ORGANIZATION_LAST_ENGAGEMENT,
    EventType.ORGANIZATION_FIRST_EXECUTIVE_ENGAGEMENT,
  ],
  [EventCategory.ORG_STRUCTURE]: [
    EventType.PERSON_OFFBOARDED,
    EventType.PERSON_ONBOARDED,
  ],
};

const DEFAULT_CATEGORY = EventCategory.WORK_EFFORTS;

export function isOrganizationEvent(category: EventCategory): boolean {
  return category === EventCategory.ACCOUNT_MANAGEMENT;
}

export function findEventCategory(type: EventType): EventCategory {
  return Object.entries(categoryToEventTypes)
    .find(([_, types]) => types.includes(type))
    ?.[0] as EventCategory || DEFAULT_CATEGORY;
}

export default EventCategory;
