import React from 'react';

import HeatMapChart from 'screens/platform/contentScreens/AnalyticsScreen/components/HeatMapChart';
import useTimeSeriesChartLabels from 'screens/platform/contentScreens/AnalyticsScreen/utils/TimeSeriesChartLabelsHook/TimeSeriesChartLabelsHook';
import CustomTicks from 'screens/platform/contentScreens/AnalyticsScreen/widgets/ShareOfAttentionWidget/visualizations/CustomTicks';
import ShareOfAttentionTableUtils, {
  ShareOfAttentionTableColumn,
} from 'screens/platform/contentScreens/AnalyticsScreen/widgets/ShareOfAttentionWidget/visualizations/ShareOfAttentionTable/ShareOfAttentionTableUtils';
import { DateRangeGranularity, GroupType } from 'screens/platform/contentScreens/AnalyticsScreen/widgets/widgetConfig';
import widgetConstants from 'screens/platform/contentScreens/AnalyticsScreen/widgets/widgetConstants';
import {
  useCustomAtomsFilters,
} from 'screens/platform/cross-platform-components/context/CustomFilters/CustomAtomsFilters/CustomAtomsFiltersContextProvider';
import {
  CustomAtomsFiltersState,
} from 'screens/platform/cross-platform-components/context/CustomFilters/CustomAtomsFilters/CustomAtomsFiltersReducer';
import { useMetadataContext } from 'screens/platform/cross-platform-components/context/metadata/MetadataContext';
import useTenantContext from 'screens/platform/cross-platform-components/context/tenant/TenantContext';
import { useIsOrganizationId } from 'utils/OrganizationUtils';

export type ShareOfAttentionGroupType =
  | GroupType.DEPARTMENTS
  | GroupType.TAGS
  | GroupType.ORGANIZATIONS;

interface Props {
  data: ShareOfAttentionTableColumn[];
  group: ShareOfAttentionGroupType;
  disableClicking?: boolean;
}

export default function ShareOfAttentionHeatMap({
  data,
  group,
  disableClicking = false,
}: Props) {
  const { dispatchCustomAtomsFilters } = useCustomAtomsFilters();
  const { tenant } = useTenantContext();
  const { categories } = useMetadataContext();
  const isOrganizationId = useIsOrganizationId();
  const height = data.length * widgetConstants.heatMap.cellHeight
    + widgetConstants.heatMap.margin.top
    + widgetConstants.heatMap.margin.bottom;
  const cellsCount = (data[0]?.data && data.length * data[0].data.length) || 0;
  const shouldDisableOpacity = cellsCount <= widgetConstants.heatMap.maxInteractiveCells;
  const conditionalProps = shouldDisableOpacity ? { activeOpacity: 1, inactiveOpacity: 1 } : {};

  const {
    ref: shareOfAttentionHeatMap,
    labels,
  } = useTimeSeriesChartLabels({ granularity: DateRangeGranularity.WEEK, isTableChart: true });

  const onCellClick = async (cell) => {
    const { data: { datesRange: { from, to } }, serieId } = cell;
    const payload: CustomAtomsFiltersState = { datesRange: { from, to } };
    if (Object.keys(tenant.departments).includes(serieId)) {
      payload.departments = [serieId];
    } else if (Object.values(categories).flat().includes(serieId)) {
      payload.tag = serieId;
    } else if (await isOrganizationId(serieId)) {
      payload.organizations = [serieId];
    }
    dispatchCustomAtomsFilters({
      type: 'SET_FILTERS',
      payload,
    });
  };

  const customTicksData = [...new Set(data.map(({ id }) => id))];

  return (
    <div ref={shareOfAttentionHeatMap} style={{ width: '100%', display: 'flex', height }}>
      <CustomTicks data={customTicksData} />
      <HeatMapChart
        data={data}
        granularity={DateRangeGranularity.WEEK}
        valueFormat={(value) => `${value}%`}
        onCellClick={disableClicking ? undefined : onCellClick}
        colors={ShareOfAttentionTableUtils.calculateColor}
        labels={labels}
        group={group}
        {...conditionalProps}
      />
    </div>
  );
}
