import React, { forwardRef } from 'react';

import AnalyticsScreenSection from 'screens/platform/contentScreens/AnalyticsScreen/components/AnalyticsScreenSection/index';
import WidgetHeader from 'screens/platform/contentScreens/AnalyticsScreen/components/WidgetHeader/index';
import recipes from 'screens/platform/contentScreens/AnalyticsScreen/widgets/CommChartWidget/commChartRecipes';
import { WidgetType } from 'screens/platform/contentScreens/AnalyticsScreen/widgets/widgetConfig';
import CommChartSettings from 'screens/platform/cross-platform-components/CommChartGraph/components/CommChartActions/components/CommChartSettings/index';
import LazyLoadedCommChartGraph from 'screens/platform/directory/components/LazyLoadedCommChartGraph';

import styles from 'screens/platform/contentScreens/AnalyticsScreen/widgets/CommChartWidget/style.module.scss';

const CommChartWidget = forwardRef<HTMLElement>((_, ref) => (
  <AnalyticsScreenSection ref={ref}>
    <header className={styles.header}>
      <WidgetHeader
        type={WidgetType.PARTICIPANTS}
        recipes={recipes}
      />
      <CommChartSettings />
    </header>
    <LazyLoadedCommChartGraph />
  </AnalyticsScreenSection>
));

export default CommChartWidget;
