import classNames from 'classnames';
import React from 'react';

import Button from 'common-ui-components/Button';
import { TableFilterDropdownProps } from 'common-ui-components/Table/TableFilterDropdown';

import style from 'common-ui-components/Table/TableFilterDropdown/style.module.scss';

export default function SingleSelectionFilter({
  filterConfig,
  confirm,
  selectedKeys,
  setSelectedKeys,
  hasDoneButton = true,
}: TableFilterDropdownProps) {
  const { options } = filterConfig;
  const value = selectedKeys[0];

  const currentOptions = options?.getCurrentOptions();

  return (
    <>
      <div className={style.container}>
        {currentOptions?.map((option) => (
          <Button
            key={option.value}
            color="gray"
            className={classNames(
              style.optionItem,
              option.value === value && style.selected,
            )}
            onClick={() => {
              setSelectedKeys([option.value]);
              confirm({ closeDropdown: true });
            }}
          >
            {option.label}
          </Button>
        ))}
      </div>
      {value !== undefined && hasDoneButton && (
        <>
          <div className={style.separator} />
          <div className={style.container}>
            <Button
              color="gray"
              className={style.optionItem}
              onClick={() => {
                setSelectedKeys([]);
                confirm({ closeDropdown: true });
              }}
            >
              Clear Filter
            </Button>
          </div>
        </>
      )}
    </>
  );
}
