import classNames from 'classnames';
import React, { FC } from 'react';

import Button from 'common-ui-components/Button';
import DropdownSelector from 'common-ui-components/DropdownSelector';
import PopoverSection from 'common-ui-components/SettingsPopover/PopoverSection';
import {
  ClusteringTypeKey,
  useGraphFeatures,
} from 'screens/platform/cross-platform-components/context/graphFeatures/GraphFeaturesContext';
import useTenantContext from 'screens/platform/cross-platform-components/context/tenant/TenantContext';
import { useToggle } from 'utils/hooks';

import dropdownStyle from 'common-ui-components/DropdownSelector/style.module.scss';
import style from 'screens/platform/cross-platform-components/CommChartGraph/components/CommChartActions/components/CommChartSettings/ClusteringOptions/style.module.scss';

type ClusteringOption = { value: string; label: string };

const ClusteringOptions: FC = () => {
  const [isOpen, toggleDropdown] = useToggle(false);

  const { tenant } = useTenantContext();
  const { clusteringType, dispatchGraphFeatures } = useGraphFeatures();

  function setClusteringType(newType: string) {
    if (newType !== clusteringType) {
      dispatchGraphFeatures({
        type: 'CHANGE_CLUSTERING_TYPE',
        payload: { newType },
      });
    }
    toggleDropdown();
  }

  const clusteringOptions: ClusteringOption[] = [
    { value: ClusteringTypeKey.SMART, label: 'Smart Clustering' },
    { value: ClusteringTypeKey.DEPARTMENT, label: 'Departments' },
    { value: ClusteringTypeKey.INTERNAL_EXTERNAL, label: 'Internal/External' },
  ];

  if (tenant.personProperties) {
    Object.keys(tenant.personProperties).forEach((value) => {
      clusteringOptions.push({ value, label: value });
    });
  }

  const renderOptions = (options: ClusteringOption[]) =>
    options.map(({ value, label }) => (
      <Button
        key={value}
        className={classNames(
          style.option,
          dropdownStyle.option,
          value === clusteringType && dropdownStyle.selected,
        )}
        color="gray"
        onClick={() => setClusteringType(value)}
      >
        {label}
      </Button>
    ));

  return (
    <PopoverSection title="Cluster By">
      <DropdownSelector
        isDropdownOpen={isOpen}
        toggleDropdown={toggleDropdown}
        toggleButtonClassName={style.dropdownSelectorBtn}
        label={
            clusteringOptions.find((o) => o.value === clusteringType)!.label
          }
      >
        <span className={dropdownStyle.dropdownLabel}>AUTOMATIC</span>
        {renderOptions([clusteringOptions[0]])}
        <span className={dropdownStyle.dropdownLabel}>ATTRIBUTES</span>
        {renderOptions(clusteringOptions.slice(1))}
      </DropdownSelector>
    </PopoverSection>
  );
};

export default ClusteringOptions;
