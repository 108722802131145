import { FeedbackType } from 'common-ui-components/Feedback/SingleFeedbackButton';
import { PersonWithSettingsData } from 'global/api/controller/PersonController';
import { UploadResponse } from 'global/api/controller/SetupController';
import utils from 'global/api/controller/utils/BackofficeControllerUtils';
import { RequestPaging } from 'global/api/controller/utils/commonControllerUtils';
import {
  deleteRequest,
  getRequest,
  HttpResponse,
  HttpResponseResult,
  postRequest,
  putRequest,
} from 'global/api/platformApiHelpers';
import App from 'global/lists/apps';
import { Atom } from 'model/Atom';
import { Configuration, NewlyCreatedConfiguration } from 'model/Configuration';
import Person from 'model/Person';
import Tag from 'model/Tag';
import Tenant from 'model/Tenant';
import OdsDataType from 'screens/backoffice/screens/ods/OdsDataType';

export type UploadCSVRes = {
  successMsg: string | null;
  errors: string[];
  warnings: string[];
};

export enum OfflineScanType {
  METRIC = 'metric',
  ATTENTION = 'attention',
  EVENT = 'event',
  OBJECTIVE = 'objective'
}

export enum SessionType {
  INTERACTIONS = 'INTERACTIONS',
  TOPIC = 'TOPIC',
  ORGANIZATION = 'ORGANIZATION',
}

export enum LanguageModel {
  OpenAI = 'OpenAI',
  VertexAI = 'VertexAI',
}

export enum TagSource {
  USER = 'USER',
  THIRD_PARTY_APP = 'THIRD_PARTY_APP',
  AKOODA = 'AKOODA',
  API = 'API',
}

interface SuggestedTagsRequest {
  pagingConfig: RequestPaging;
  source: TagSource | null;
  searchTerm: string;
  category: string | null;
}

export interface SessionResponse {
  id: string;
  tenantId: number;
  personId: Person['id'];
  prompt: string;
  answer: string;
  type: SessionType;
  createdAt: Date;
  feedback?: {
    content: string;
    type: FeedbackType;
  };
  languageModel: LanguageModel;
  responseTime: number; // In milliseconds
  isShadow: boolean;
}

export type RequestMapperType = Record<OfflineScanType, () => Promise<HttpResponseResult<string>>>

export interface AkoodaGPTResponse {
  answer: string;
  atoms: Atom[];
  error: string | null;
}

const BackofficeController = {
  Tenant: {
    async createTenant(tenantName: string, tenantDomain: string): HttpResponse<string> {
      return putRequest('tenant', null, { params: { tenantName, tenantDomain } });
    },

    async setDepartmentsColors(tenantId: Tenant['id'], departmentsColors: Record<string, string>): HttpResponse<string> {
      return putRequest('tenant/department/color', departmentsColors, { params: { tenantId } });
    },

    async setSetupState(tenantId: Tenant['id'], setupState: Tenant['setupState']): HttpResponse<string> {
      return putRequest('tenant/setupState', null, { params: { tenantId, setupState } });
    },

    async updateLogo(tenantId: Tenant['id'], file: File): HttpResponse<UploadResponse> {
      const formData = new FormData();
      formData.append('file', file);
      const requestConfig = {
        params: { tenantId },
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      return postRequest('setup/logo', formData, requestConfig);
    },
  },

  Config: {
    async getAllConfigurations():
      HttpResponse<Configuration[]> {
      return getRequest('config');
    },

    async getAkoodaEmployees(): HttpResponse<{ _id: string; name: string }[]> {
      return getRequest('backoffice/akooda-employees');
    },

    async setConfig(config: NewlyCreatedConfiguration): HttpResponse<string> {
      return putRequest('config', config);
    },

    async deleteConfig(config: Configuration): HttpResponse<string> {
      return deleteRequest('config', { key: config.key, tenantId: config._tid, personId: config.personId });
    },
  },

  Person: {
    async getPersonsByTenant(
      tenantId: Tenant['id'],
    ): HttpResponse<Record<string, PersonWithSettingsData>> {
      return getRequest('person/settings', { tenantId });
    },

    async uploadOrgChart(tenantId: Tenant['id'], csvFormattedFile: File): HttpResponse<UploadCSVRes> {
      const formData = new FormData();
      formData.append('csvFormattedFile', csvFormattedFile);
      const requestConfig = {
        params: { tenantId },
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      return postRequest('person/orgchart', formData, requestConfig);
    },
  },

  Nudge: {
    async sendNudge(
      tenantId: Tenant['id'],
      personsIds: string[],
      content: string,
    ): HttpResponse<any> {
      return postRequest(
        'notification', // TODO: this should be change to `nudge` in the future
        { personsIds, content },
        { params: { tenantId } },
      );
    },
  },

  Ods: {
    async requestOdsScans({
      tenantId, config, totalTimeToScanInSeconds, areAllAppsSelected,
    }: {
      tenantId: string | null;
      config: Record<App, OdsDataType[]>;
      totalTimeToScanInSeconds: number | null;
      areAllAppsSelected: boolean;
    }) {
      const odsTasks = areAllAppsSelected && tenantId === null
        ? utils.getOdsTasksForAllDataTypes(config, totalTimeToScanInSeconds)
        : utils.getOdsTasks(tenantId, config, totalTimeToScanInSeconds);

      return Promise.all(odsTasks.map((task) => task()));
    },
  },

  OfflineScan: {
    async requestOfflineScan({ scanType, tenantIds, totalTimeToScanInSeconds }: {
      scanType: OfflineScanType;
      tenantIds: number[] | null;
      totalTimeToScanInSeconds: number | null;
    }) {
      const scanTypeToRequestMapper: RequestMapperType = {
        [OfflineScanType.METRIC]: () => postRequest('metricDetection', { tenantIds }),
        [OfflineScanType.ATTENTION]: () => postRequest('attention/build', { tenantIds, totalTimeToScanInSeconds }),
        [OfflineScanType.EVENT]: () => postRequest('eventDetection', { tenantIds, totalTimeToScanInSeconds }),
        [OfflineScanType.OBJECTIVE]: () => postRequest('objectiveTracker', { tenantIds, totalTimeToScanInSeconds }),
      };
      return scanTypeToRequestMapper[scanType]();
    },
  },

  TagsSet: {
    async getSuggestedTags(tenantId: Tenant['id'], requestBody: SuggestedTagsRequest): HttpResponse<{
      tags: Tag[];
      totalTags: number;
    }> {
      return postRequest('backoffice/tags-set/suggested', requestBody, { params: { tenantId } });
    },
    async getSuggestedTagsCategories(tenantId: Tenant['id'], searchTerm: string): HttpResponse<string[]> {
      return getRequest('backoffice/tags-set/suggested/categories', { tenantId, searchTerm });
    },
    async convertSuggestedTags(tenantId: Tenant['id'], suggestedTags: string[]): HttpResponse<void> {
      return postRequest('backoffice/tags-set/suggested/convert', suggestedTags, { params: { tenantId } });
    },
  },

  AkoodaGPT: {
    async getAnswer(question: string): HttpResponse<AkoodaGPTResponse> {
      return postRequest('free-text-question', { question });
    },
  },

  GPTSession: {
    async getTenantSessions(tenantId: Tenant['id']): HttpResponse<SessionResponse[]> {
      return getRequest('backoffice/session', { tenantId });
    },
    async getPersonSessions(tenantId: Tenant['id'], personId: Person['id']): HttpResponse<SessionResponse[]> {
      return getRequest(`backoffice/session/person/${personId}`, { tenantId });
    },
    async getSessionById(tenantId: Tenant['id'], sessionId: string): HttpResponse<SessionResponse> {
      return getRequest(`backoffice/session/${sessionId}`, { tenantId });
    },
  },

  EmailApp: {
    async deleteInboxContent(tenantId: Tenant['id']): HttpResponse<void> {
      return deleteRequest('backoffice/email-app', { tenantId });
    },
    async getIgnoredSenders(tenantId: Tenant['id']): HttpResponse<string> {
      return getRequest('backoffice/email-app/ignored-senders', { tenantId });
    },
    async updateIgnoredSenders(tenantId: Tenant['id'], ignoredSenders: string): HttpResponse<void> {
      return putRequest(
        'backoffice/email-app/ignored-senders',
        { ignoredSenders },
        { params: { tenantId } },
      );
    },
  },
};

export default BackofficeController;
