import classNames from 'classnames';
import React, { FC, ReactNode, SVGProps } from 'react';

import { Heading2 } from 'common-ui-components/Typograhpy';

import style from 'es-src/screens/HomeScreen/components/ResultSection/SearchResultCard/style.module.scss';

interface Props {
  icon: FC<SVGProps<SVGSVGElement>>;
  title: string;
  children: ReactNode;
  hasError?: boolean;
}

export default function Content({
  title,
  icon: Icon,
  hasError,
  children,
}: Props) {
  return (
    <div className={classNames(
      style.searchResultContent,
      hasError && style.error,
    )}
    >
      <div className={style.iconWrapper}>
        <Icon />
      </div>
      <div className={style.resultDetails}>
        <Heading2 className={style.question}>
          {title}
        </Heading2>
        <div className={style.content}>
          {children}
        </div>
      </div>
    </div>
  );
}
