import React from 'react';

import Lottie from 'common-ui-components/Lottie';
import Size from 'global/lists/Size';

import blueSpinner from 'assets/video/minor-loading.json';
import graySpinner from 'assets/video/minor-loading-gray.json';
import whiteSpinner from 'assets/video/minor-loading-white.json';

const spinnerSizeMap: Record<Size.SMALL | Size.MEDIUM | Size.BIG, number> = {
  [Size.BIG]: 30,
  [Size.MEDIUM]: 20,
  [Size.SMALL]: 10,
};

const spinnerMap = {
  blue: blueSpinner,
  gray: graySpinner,
  white: whiteSpinner,
};

type Props = {
  size?: Size.SMALL | Size.MEDIUM | Size.BIG;
  color?: 'white' | 'blue' | 'gray';
};

export default function Spinner({ size = Size.MEDIUM, color = 'blue' }: Props) {
  const spinnerFile = spinnerMap[color];

  return (
    <Lottie
      file={spinnerFile}
      width={spinnerSizeMap[size]}
      height={spinnerSizeMap[size]}
    />
  );
}
