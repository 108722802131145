const BLUE = '#0095EC';
const DARK_BLUE = '#070847';
const DARK_GRAY = '#6B6680';
const GRAY_2 = '#B1AFBC';
const GRAY_4 = '#F1F0F5';
const HOVER_GRAY = '#F1F0F555';
const WHITE = '#FFFF';
const GREEN = '#27D2B1';
const RED = '#E91E63';
const YELLOW = '#FFBF42';
const PURPLE = '#AB9EFF';

const colors = {
  BLUE,
  DARK_BLUE,
  DARK_GRAY,
  GRAY_2,
  GRAY_4,
  HOVER_GRAY,
  WHITE,
  GREEN,
  RED,
  YELLOW,
  PURPLE,
};

export default colors;
