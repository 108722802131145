import { useMemo } from 'react';

import {
  NullableValuesSelectionConstants,
} from 'screens/platform/cross-platform-components/context/MasterFiltersContext/NullableValuesSelection';
import PeopleMasterFilter
  from 'screens/platform/cross-platform-components/context/MasterFiltersContext/PeopleMasterFilter';
import PeopleMasterFilterHierarchyGroup
  from 'screens/platform/cross-platform-components/context/MasterFiltersContext/PeopleMasterFilter/PeopleMasterFilterHierarchyGroup';
import {
  useSelectedPeopleFromMasterFilter,
} from 'screens/platform/cross-platform-components/context/MasterFiltersContext/PeopleMasterFilter/SelectedPeopleFromMasterFilterHook';
import { useMetadataContext } from 'screens/platform/cross-platform-components/context/metadata/MetadataContext';

const { UNDEFINED_PROPERTY } = NullableValuesSelectionConstants;

export default function usePeopleFilterToHierarchyGroupConverter(
  filterToConvert: PeopleMasterFilter,
): PeopleMasterFilterHierarchyGroup {
  const {
    persons: { allPeopleMetadata, departmentsToPersonsMap, teamsToPeopleMap },
  } = useMetadataContext();

  const selectedPeopleIds = useSelectedPeopleFromMasterFilter(filterToConvert, false);

  return useMemo(() => {
    const ans = new PeopleMasterFilterHierarchyGroup({
      people: {
        mapping: Object.keys(allPeopleMetadata),
        isSelected: filterToConvert.internalPeopleIds === null,
      },
      departments: {
        mapping: departmentsToPersonsMap,
        isSelected: filterToConvert.departments === null,
      },
      teams: {
        mapping: teamsToPeopleMap,
        isSelected: filterToConvert.teams.isAllSelected(),
      },
    });

    const teamsGroup = ans.getTeamsGroup();
    const peopleGroup = ans.getPeopleGroup();

    selectedPeopleIds.forEach((selectedPersonId) => {
      peopleGroup.getChild(selectedPersonId)?.select();

      const selectedPersonMetadata = allPeopleMetadata[selectedPersonId];
      if (selectedPersonMetadata) {
        const { department } = selectedPersonMetadata;
        const personTeams = selectedPersonMetadata.getTeams();

        ans.getDepartmentsGroup().getChild([department, selectedPersonId])?.select();

        if (personTeams === null) {
          teamsGroup.getChild([UNDEFINED_PROPERTY, selectedPersonId])?.select();
        } else {
          personTeams.forEach((team) => {
            teamsGroup.getChild([team, selectedPersonId])?.select();
          });
        }
      }
    });

    return ans;
  }, [filterToConvert]);
}
