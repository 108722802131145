import classnames from 'classnames';
import React from 'react';

import CSSVariableDefiner from 'common-ui-components/CSSVariableDefiner';
import ImageWithFallback from 'common-ui-components/ImageWithFallback';
import { ImageShape } from 'common-ui-components/MultiImages/NodeImage';

import style from 'common-ui-components/MultiImages/ImageTooltip/style.module.scss';

interface Props {
  name: string;
  subtitle: string;
  highlightColor: string;
  imageUrl: string | null;
  imageShape?: ImageShape;
}

export default function ImageTooltip({
  name, subtitle, highlightColor, imageUrl, imageShape,
}: Props) {
  return (
    <CSSVariableDefiner variables={{ '--border-color': highlightColor }}>
      <div className={style.tooltip}>
        <ImageWithFallback
          className={classnames(
            style.tooltipImage,
            imageShape === ImageShape.ROUND ? style.round : style.square,
          )}
          imageUrl={imageUrl}
          name={name}
        />
        <div className={style.tooltipContent}>
          <b>{name}</b>
          <span style={{ color: highlightColor }}>
            {subtitle}
          </span>
        </div>
      </div>
    </CSSVariableDefiner>
  );
}
