/* eslint-disable no-useless-escape */
import punycode from 'punycode';

import emojiData from 'common-ui-components/InteractionTitle/parsers/emoji_pretty.json';

export default function emojiParser(text: string) {
  const emojiRegex = /:([a-zA-Z0-9\-_+]+):(?::([a-zA-Z0-9\-_+]+):)?/g;

  let newText = text;
  let match;

  // Find all Slack emoji in the message
  // eslint-disable-next-line no-cond-assign
  while (match = emojiRegex.exec(text)) {
    // eslint-disable-next-line no-loop-func
    const matchingEmoji = emojiData.find((el) => el.short_name === match[1]);
    if (matchingEmoji) {
      const points = matchingEmoji.unified.split('-');
      const pointsAsNumbers = points.map((p) => parseInt(p, 16));
      newText = newText.replace(match[0], punycode.ucs2.encode(pointsAsNumbers));
    }
  }

  return newText;
}
