import { Pagination as AntPagination } from 'antd';
import classNames from 'classnames';
import React from 'react';

import { PaginationProps } from 'antd/lib/pagination/Pagination';
import Button from 'common-ui-components/Button';

import { ReactComponent as Caret } from 'assets/img/icon/caret-left.svg';

import 'common-ui-components/Pagination/style.scss';

interface CustomPaginationProps extends PaginationProps {
  hasNavigationButtons?: boolean;
}

export default function Pagination(
  { hasNavigationButtons = false, ...props }: CustomPaginationProps,
) {
  const itemRender = props.itemRender ?? createItemRendererWithNavButtons(hasNavigationButtons);

  return (
    <AntPagination
      size="small"
      showTitle={false}
      itemRender={itemRender}
      {...props}
    />
  );
}

function createItemRendererWithNavButtons(hasNavigationButtons: boolean): PaginationProps['itemRender'] | undefined {
  return hasNavigationButtons
    ? (_, type, originalElement) => {
      if (type === 'prev') {
        return (
          <Button transparent className="paginationButton">
            <Caret className="caret" />
            <span className="text">Previous</span>
          </Button>
        );
      }
      if (type === 'next') {
        return (
          <Button transparent className="paginationButton">
            <span className="text">Next</span>
            <Caret className={classNames('caret', 'pointingRight')} />
          </Button>
        );
      }
      return originalElement;
    }
    : undefined;
}
