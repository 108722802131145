import React from 'react';
import {
  BrowserRouter, Redirect, Route, Switch,
} from 'react-router-dom';

import LoginPage from 'screens/public/LoginPage';

export const ACCOUNT_PATH_PREFIX = '/account';
export const LOGIN_PATH_PREFIX = '/login';
export const ACCOUNT_LOGIN_PATH = `${ACCOUNT_PATH_PREFIX}${LOGIN_PATH_PREFIX}`;

export default function AccountRouter() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path={ACCOUNT_LOGIN_PATH} render={() => <LoginPage />} />
        <Redirect to={ACCOUNT_LOGIN_PATH} />
      </Switch>
    </BrowserRouter>
  );
}
