import classNames from 'classnames';
import React, { useMemo } from 'react';

import { ReactComponent as TrendingDown } from 'assets/img/icon/trending-down.svg';
import { ReactComponent as TrendingStatic } from 'assets/img/icon/trending-static.svg';
import { ReactComponent as TrendingUp } from 'assets/img/icon/trending-up.svg';

import style from 'common-ui-components/TrendCard/TrendBadge/style.module.scss';

interface Props {
  value: number;
  previousPeriodValue: number;
  label?: string;
}

function getComponentContentByTrend(
  value: number,
  previousPeriodValue: number,
): { Icon: typeof TrendingUp; badgeClass: string } {
  if (value < previousPeriodValue) {
    return { Icon: TrendingDown, badgeClass: style.decreasing };
  }
  if (value > previousPeriodValue) {
    return { Icon: TrendingUp, badgeClass: style.increasing };
  }
  return { Icon: TrendingStatic, badgeClass: style.noChange };
}

export default function TrendBadge(
  { value, previousPeriodValue, label }: Props,
) {
  const { Icon, badgeClass } = getComponentContentByTrend(value, previousPeriodValue);

  const trendLabel = useMemo(() => {
    if (label) return label;
    if (previousPeriodValue === value) return '0.0%';
    if (previousPeriodValue === 0) {
      const isInteger = Math.round(value) === value;
      return `+${isInteger ? value : value.toFixed(1)}`;
    }
    const change = Math.abs((value - previousPeriodValue) / previousPeriodValue);
    return `${(change * 100).toFixed(1)}%`;
  }, [value, previousPeriodValue]);

  return (
    <div className={classNames(style.trendBadge, badgeClass)}>
      <Icon className={style.icon} />
      {' '}
      {trendLabel}
    </div>
  );
}
