import { BubbleChartGroup, BubbleDatum, BubbleShape } from 'screens/platform/contentScreens/AnalyticsScreen/widgets/EventsChart/BubbleChart';

const BUBBLES_SIZES_COUNT = 6;
interface ChartData<T> {
  id: string;
  title: string;
  data: {
      x: Date;
      y: number;
      datum: T;
      nodes: BubbleDatum<T>['nodes'];
  }[];
}

export function buildChartData<T>(
  chartGroups: BubbleChartGroup<T>[],
  minBubbleSize: number,
  maxBubbleSize: number,
): ChartData<T>[] {
  const groupIds = chartGroups.map(({ id }) => id);
  return chartGroups.map(({
    id, groupData, title,
  }) => {
    const y = groupIds.findIndex((groupId) => groupId === id) || 0;
    const bubblesFixedSizes = Array.from({ length: BUBBLES_SIZES_COUNT }, (_, idx) => {
      const indexFactor = idx / (BUBBLES_SIZES_COUNT - 1);
      return ((maxBubbleSize - minBubbleSize) * indexFactor) + minBubbleSize;
    }).slice(1);

    const calcBubbleFixedSize = (size: number) => {
      if (size === 0) return 0;
      return bubblesFixedSizes
        .reduce((prev, curr) => (Math.abs(curr - size) < Math.abs(prev - size) ? curr : prev));
    };

    const data = groupData.map(({ date, datum, nodes }) => {
      const fixedSizeNodes = nodes.map((node) =>
        ({ ...node, size: calcBubbleFixedSize(node.size) }));
      return {
        x: date, y, datum, nodes: fixedSizeNodes,
      };
    });

    return {
      id, title, data,
    };
  });
}

export function getAllDatesSorted<T>(chartGroups: BubbleChartGroup<T>[]): Date[] {
  const sortedDates = new Set(chartGroups
    .flatMap((group) => group.groupData)
    .flatMap(({ date }) => date.getTime())
    .sort());

  return [...sortedDates].map((dateTime) => new Date(dateTime));
}

export function calcMiddleDates(sortedDates: Date[]): Date[] {
  return sortedDates.map((date, index) => {
    if (index === 0) return date;
    const currentDateTime = date.getTime();
    const prevDateTime = sortedDates[index - 1].getTime();
    const middleDate = currentDateTime - ((currentDateTime - prevDateTime) / 2);
    return new Date(middleDate);
  }).slice(1);
}

export function getMinAndMaxDates(sortedDates: Date[], middleDates: Date[]) {
  const firstDate = sortedDates[0];
  const firstDateTime = firstDate.getTime();
  const lastDateTime = sortedDates[sortedDates.length - 1].getTime();
  if (firstDateTime === lastDateTime) {
    return { min: firstDate, max: firstDate };
  }
  return {
    min: new Date(2 * firstDateTime - middleDates[0].getTime()),
    max: new Date(2 * lastDateTime - middleDates[middleDates.length - 1].getTime()),
  };
}

export function buildNodesShapesOffsetMap(
  nodes: BubbleDatum<any>['nodes'],
  maxNodeSize: number,
): Partial<Record<BubbleShape, number>> {
  const getShapeSize = (shape: BubbleShape): number => {
    const nodeSize = nodes.find((n) => n.shape === shape)?.size || 0;
    return getFixedNodeSize(nodeSize, maxNodeSize);
  };
  const shapesSizesList = nodes.map(({ shape }) => ({ shape, size: getShapeSize(shape) }));
  const totalSize = shapesSizesList.reduce((acc, { size }) => acc + size, 0);
  const startPoint = -1 * (totalSize / 2);

  return shapesSizesList
    .reduce((acc, node) => {
      const offset = shapesSizesList
        .filter(({ shape }) => shape < node.shape)
        .reduce((offsetAcc, { size }) => offsetAcc + size - 5, 0);

      return { ...acc, [node.shape]: startPoint + offset };
    }, {});
}

export function getFixedNodeSize(size: number, maxNodeSize: number): number {
  const maxShapeSize = 50;
  const minShapeSize = 5;
  const fixedSize = minShapeSize + (size / maxNodeSize) * maxShapeSize;
  return Math.round(fixedSize);
}

export function drawDiamond(
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  size: number,
  color: string,
) {
  const shapeSize = (Math.sqrt(2) / 2) * size;
  const offsetY = y + ((-1 * Math.sqrt(2)) / 2) * shapeSize;
  const offsetX = x;
  const rectRadius = shapeSize / 5;

  ctx.save();
  ctx.globalAlpha = 0.8;
  ctx.translate(offsetX, offsetY);
  ctx.beginPath();
  ctx.rotate(Math.PI / 4);
  ctx.moveTo(rectRadius, 0);
  ctx.lineTo(shapeSize - rectRadius, 0);
  ctx.quadraticCurveTo(shapeSize, 0, shapeSize, rectRadius);
  ctx.lineTo(shapeSize, shapeSize - rectRadius);
  ctx.quadraticCurveTo(shapeSize, shapeSize, shapeSize - rectRadius, shapeSize);
  ctx.lineTo(rectRadius, shapeSize);
  ctx.quadraticCurveTo(0, shapeSize, 0, shapeSize - rectRadius);
  ctx.lineTo(0, rectRadius);
  ctx.quadraticCurveTo(0, 0, rectRadius, 0);
  ctx.closePath();
  ctx.fillStyle = color;
  ctx.fill();
  ctx.restore();
}

export function drawSquare(
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  size: number,
  color: string,
) {
  const shapeSize = (Math.sqrt(2) / 2) * size;
  const rectRadius = shapeSize / 5;
  ctx.save();
  ctx.globalAlpha = 0.8;
  ctx.translate(x - shapeSize / 2, y - shapeSize / 2);
  ctx.beginPath();
  ctx.moveTo(rectRadius, 0);
  ctx.lineTo(shapeSize - rectRadius, 0);
  ctx.quadraticCurveTo(shapeSize, 0, shapeSize, rectRadius);
  ctx.lineTo(shapeSize, shapeSize - rectRadius);
  ctx.quadraticCurveTo(shapeSize, shapeSize, shapeSize - rectRadius, shapeSize);
  ctx.lineTo(rectRadius, shapeSize);
  ctx.quadraticCurveTo(0, shapeSize, 0, shapeSize - rectRadius);
  ctx.lineTo(0, rectRadius);
  ctx.quadraticCurveTo(0, 0, rectRadius, 0);
  ctx.closePath();
  ctx.fillStyle = color;
  ctx.fill();
  ctx.restore();
}

export function drawCircle(
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  size: number,
  color: string,
) {
  ctx.beginPath();
  ctx.arc(x, y, size / 2, 0, 2 * Math.PI);
  ctx.fillStyle = color;
  ctx.fill();
  ctx.closePath();
}

export function drawTriangle(
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  size: number,
  color: string,
) {
  ctx.save();
  ctx.beginPath();
  ctx.translate(x - size / 2, y - size / 2);
  ctx.lineTo(size / 2, 0);
  ctx.lineTo(size, size);
  ctx.lineTo(0, size);
  ctx.closePath();
  ctx.fillStyle = color;
  ctx.fill();
  ctx.restore();
}
