export enum SignificanceColor {
  LOWEST,
  LOW,
  MEDIUM,
  HIGH,
  HIGHEST,
}

export interface SignificanceColorsData {
  color: string;
  label: string;
}

export const significanceColorsData: Record<SignificanceColor, SignificanceColorsData> = {
  [SignificanceColor.LOWEST]: { color: '#D0EBFC', label: 'Very Low' },
  [SignificanceColor.LOW]: { color: '#83CCF6', label: 'Low' },
  [SignificanceColor.MEDIUM]: { color: '#0095EC', label: 'Medium' },
  [SignificanceColor.HIGH]: { color: '#1C5BEA', label: 'High' },
  [SignificanceColor.HIGHEST]: { color: '#112E92', label: 'Very High' },
};

export const getSignificanceColorByPercentage = (volumePercentage: number): string => {
  // volumePercentage is between 0 and 100
  const index = Math.min(SignificanceColor.HIGHEST, Math.floor(volumePercentage / 20));
  return significanceColorsData[index || SignificanceColor.LOWEST].color;
};

export const getSignificanceLabel = (significanceIndex: number): string => {
  const prev = Math.round(significanceIndex);
  const normalized = Math.min(
    SignificanceColor.HIGHEST,
    Math.max(SignificanceColor.LOWEST, prev),
  );
  return significanceColorsData[normalized].label;
};
