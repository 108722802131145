import classNames from 'classnames';
import React from 'react';

import Button from 'common-ui-components/Button';
import Caret from 'common-ui-components/Caret';
import ImageWithFallback from 'common-ui-components/ImageWithFallback';
import { useCurrentAppScreen } from 'global/lists/AppScreen';
import usePersonImage from 'screens/platform/cross-platform-components/context/metadata/hooks/PersonImageHook';
import useTenantContext from 'screens/platform/cross-platform-components/context/tenant/TenantContext';
import { useUserContext } from 'screens/platform/cross-platform-components/context/user/UserContext';
import ScreenButtonTooltipWrapper
  from 'screens/platform/cross-platform-components/Navbar/components/ScreenButton/ScreenButtonTooltipWrapper';
import { useNavbarContext } from 'screens/platform/cross-platform-components/Navbar/NavbarContext';

import { ReactComponent as IncognitoIcon } from 'assets/img/icon/incognito.svg';

import style
  from 'screens/platform/cross-platform-components/Navbar/components/BottomActions/UserAvatarButton/style.module.scss';
import revampedStyle from 'screens/platform/cross-platform-components/Navbar/components/ScreenButton/style.module.scss';

interface Props {
  isDropdownOpen: boolean;
  toggleDropdown: () => void;
}

export default function UserAvatarButton({ isDropdownOpen, toggleDropdown }: Props) {
  const { name, personId } = useUserContext();
  const { isImpersonationMode, tenantLogo } = useTenantContext();
  const image = usePersonImage(personId);
  const { isNavbarMinimized } = useNavbarContext();

  const { isSettingsPageScreen, isUserProfileScreen, isBackofficeScreen } = useCurrentAppScreen();
  const isProfileSelected = Boolean(
    isSettingsPageScreen
    || isBackofficeScreen
    || isUserProfileScreen,
  );

  const userName = name ?? 'Unknown';

  return (
    <ScreenButtonTooltipWrapper label="My Profile">
      <Button
        className={classNames(
          style.profileButton,
          revampedStyle.screenButton,
          isNavbarMinimized && style.minimized,
          isNavbarMinimized && revampedStyle.minimized,
        )}
        transparent
        onClick={toggleDropdown}
      >
        <div className={style.imageWrapper}>
          {isImpersonationMode
            ? <IncognitoIcon width={24} height={24} />
            : (
              <ImageWithFallback
                className={classNames(style.profileImage, isProfileSelected && style.selected)}
                imageUrl={image}
                name={userName}
              />
            )}
          {tenantLogo !== null && <img src={tenantLogo} className={style.tenantLogo} alt="Tenant Logo" />}
        </div>
        <div className={revampedStyle.label}>
          {name}
        </div>
        <Caret
          isOpen={isDropdownOpen}
          className={classNames(revampedStyle.caret, isNavbarMinimized && revampedStyle.minimized)}
          color="darkGray"
        />
      </Button>
    </ScreenButtonTooltipWrapper>
  );
}
