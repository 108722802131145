import React from 'react';

import Spinner from 'common-ui-components/Spinner';
import EventsHeader from 'screens/platform/cross-platform-components/EventsPanel/EventsHeader';
import EventsListDrawer from 'screens/platform/cross-platform-components/EventsPanel/EventsListDrawer';
import EventsSummary from 'screens/platform/cross-platform-components/EventsPanel/EventsSummary';
import useFilteredEvents from 'screens/platform/cross-platform-components/EventsPanel/FilteredEventsHook';

import style from 'screens/platform/cross-platform-components/EventsPanel/style.module.scss';

export default function EventsPanel() {
  const filteredEvents = useFilteredEvents();
  const filteredEventsAmount = Object.values(filteredEvents || {}).flat().length;
  const isLoading = filteredEvents === null;

  return (
    <div className={style.container}>
      <div className={style.header}>
        <EventsHeader />
        <div className={style.summary}>
          { isLoading ? <div /> : <EventsSummary filteredEventsAmount={filteredEventsAmount} /> }
        </div>
      </div>
      <div className={style.content}>
        { isLoading ? <Loader /> : <EventsListDrawer events={filteredEvents} /> }
      </div>
    </div>
  );
}

function Loader() {
  return (
    <div className={style.loadingSpinner}>
      <Spinner />
    </div>
  );
}
