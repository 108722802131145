import React from 'react';

import Dropdown from 'common-ui-components/Dropdown';
import UserAvatarButton from 'screens/platform/cross-platform-components/Navbar/components/BottomActions/UserAvatarButton';
import UserDropdownContent from 'screens/platform/cross-platform-components/Navbar/components/BottomActions/UserDropdownContent';
import { NAVBAR_DROPDOWN_DISTANCE } from 'screens/platform/cross-platform-components/Navbar/index';
import { useToggle } from 'utils/hooks';

import style from 'screens/platform/cross-platform-components/Navbar/components/ScreenButton/style.module.scss';

const NAVBAR_DROPDOWN_OFFSET = -60;

export default function UserProfile() {
  const [isDropdownOpen, toggleDropdown] = useToggle(false);

  return (
    <Dropdown
      closeDropdown={toggleDropdown}
      isDropdownOpen={isDropdownOpen}
      placement="right"
      toggleButtonElement={(
        <UserAvatarButton
          isDropdownOpen={isDropdownOpen}
          toggleDropdown={toggleDropdown}
        />
      )}
      dropdownClassName={style.dropdown}
      wrapperClassName={style.dropdownWrapper}
      toggleClassName={style.toggleWrapper}
      popperOptions={{ distance: NAVBAR_DROPDOWN_DISTANCE, offset: NAVBAR_DROPDOWN_OFFSET }}
    >
      <UserDropdownContent isDropdownOpen={isDropdownOpen} toggleDropdown={toggleDropdown} />
    </Dropdown>
  );
}
