import { Placement } from '@popperjs/core';
import classNames from 'classnames';
import React, { FC } from 'react';

import Button from 'common-ui-components/Button';
import Caret from 'common-ui-components/Caret';
import Dropdown from 'common-ui-components/Dropdown';

import style from 'common-ui-components/DropdownSelector/style.module.scss';

type Props = {
  label: string;
  loading?: boolean;
  isDropdownOpen: boolean;
  toggleDropdown: () => void;
  toggleButtonClassName?: string;
  placement?: Placement;
};

const DropdownSelector: FC<Props> = ({
  children,
  loading,
  label,
  isDropdownOpen,
  toggleDropdown,
  toggleButtonClassName,
  placement = 'bottom-start',
}) => (
  <Dropdown
    toggleButtonElement={(
      <Button
        className={classNames(style.dropdownBtn, toggleButtonClassName)}
        loading={loading}
        onClick={toggleDropdown}
      >
        <span>{label}</span>
        <Caret isOpen={isDropdownOpen} color="darkGray" />
      </Button>
    )}
    placement={placement}
    popperOptions={{ enablePreventOverflowing: false, enableSameWidth: true }}
    isDropdownOpen={isDropdownOpen}
    closeDropdown={toggleDropdown}
    dropdownClassName={style.dropdownMenu}
    strategy="fixed"
  >
    {children}
  </Dropdown>
);

export default DropdownSelector;
