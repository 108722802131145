import React from 'react';

import FilterConfig, {
  FilterValue,
} from 'screens/platform/directory/components/GenericDirectoryScreen/FiltersList/FilterConfig';
import GenericFilter from 'screens/platform/directory/components/ProfileTabs/components/Filters/GenericFilter';
import {
  useTopicValueFilterConfig,
} from 'screens/platform/directory/topics/TopicsDirectoryScreen/TopicsTable/utils/TopicsFiltersConfig';

interface Props {
  requiredTopic: string | undefined;
  disabled?: boolean;
}

export default function TopicsFilter({ requiredTopic, disabled }: Props) {
  const filterConfig = useTopicValueFilterConfig(requiredTopic) as FilterConfig<FilterValue>;
  return (
    <GenericFilter
      filterType="topics"
      filterConfig={filterConfig}
      disabled={disabled}
    />
  );
}
