import * as process from 'process';

import { SETUP_STATE } from 'model/Tenant';
import DebuggerConsole from 'utils/DebuggerConsole';

export const INTERCOM_CONTACT_US_ID = 'intercom_contact_us';

type IntercomClient = Function;

class Intercom {
  private intercomAppId = process.env.INTERCOM_APP_ID;
  private currentSession: 'anonymous' | 'identified' | undefined;

  constructor() {
    if (this.intercomAppId !== undefined) {
      // @ts-ignore
      window.intercomSettings = {
        app_id: this.intercomAppId,
        custom_launcher_selector: `#${INTERCOM_CONTACT_US_ID}`,
      };
      const APP_ID = this.intercomAppId;

      // eslint-disable-next-line func-names
      (function () {
        const w = window; // @ts-ignore
        // eslint-disable-next-line prefer-rest-params,func-names,max-statements-per-line
        const ic = w.Intercom; if (typeof ic === 'function') { ic('reattach_activator'); ic('update', w.intercomSettings); } else { const d = document; const i = function () { i.c(arguments); }; i.q = []; i.c = function (args) { i.q.push(args); }; w.Intercom = i; const l = function () { const s = d.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = `https://widget.intercom.io/widget/${APP_ID}`; const x = d.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x); }; if (document.readyState === 'complete') { l(); } else if (w.attachEvent) { w.attachEvent('onload', l); } else { w.addEventListener('load', l, false); } }
      }());
    }
  }

  startAnonymousSession() {
    const client = Intercom.client();
    if (this.intercomAppId === undefined || client === undefined) {
      DebuggerConsole.error('Error initializing Intercom', {
        appId: this.intercomAppId,
        isClientDefined: client !== undefined,
      });
      return;
    }

    this.resetToAnonymousSession(client, this.intercomAppId);
  }

  identify(email: string, userId: string) {
    const client = Intercom.client();
    if (client === undefined || this.intercomAppId === undefined) {
      DebuggerConsole.error('Intercom client is undefined');
      return;
    }

    this.identifyUser(client, this.intercomAppId, email, userId);
  }

  updateTenantMode(tenantSetupState: SETUP_STATE) {
    const client = Intercom.client();
    if (client === undefined || this.intercomAppId === undefined) {
      DebuggerConsole.error('Intercom client is undefined');
      return;
    }

    client('update', {
      tenant_mode: tenantSetupState.toString(),
    });
  }

  endSession() {
    const client = Intercom.client();
    if (client === undefined) {
      DebuggerConsole.error('Intercom client is undefined');
      return;
    }

    if (this.intercomAppId !== undefined) {
      this.resetToAnonymousSession(client, this.intercomAppId);
    }
  }

  showArticle(articleId: number) {
    const client = Intercom.client();
    if (client === undefined || this.intercomAppId === undefined) {
      DebuggerConsole.error('Intercom client is undefined');
      return;
    }

    client('showArticle', articleId);
  }

  private resetToAnonymousSession(
    client: IntercomClient,
    appId: string,
  ) {
    if (this.currentSession === 'anonymous') {
      return;
    }

    client('shutdown');
    DebuggerConsole.log('Intercom ended session');

    client('boot', { app_id: appId });
    this.currentSession = 'anonymous';
    DebuggerConsole.log('Intercom started a new anonymous session');
  }

  private identifyUser(
    client: IntercomClient,
    appId: string,
    email: string,
    userId: string,
  ) {
    if (this.currentSession === 'identified') {
      return;
    }

    client('update', {
      app_id: appId,
      email,
      user_id: userId,
    });
    this.currentSession = 'identified';
    DebuggerConsole.log('Intercom started a new identified session', { email, userId });
  }

  private static client(): IntercomClient | undefined {
    // @ts-ignore
    return window.Intercom;
  }
}

export default new Intercom();
