import {
  deleteRequest, getRequest, HttpResponseResult, postRequest, putRequest,
} from 'global/api/platformApiHelpers';
import DepartmentInclusionObjective from 'model/Objectives/DepartmentInclusionObjective';
import NeglectedAccountsObjective from 'model/Objectives/NeglectedAccountsObjective';
import Objective from 'model/Objectives/Objective';
import SOAAlignmentObjective from 'model/Objectives/SOAAlignmentObjective';
import SOAThresholdByOrganizationsObjective from 'model/Objectives/SOAThresholdByOrganizationsObjective';
import SOAThresholdObjective from 'model/Objectives/SOAThresholdObjective';
import Tenant from 'model/Tenant';
import DebuggerConsole from 'utils/DebuggerConsole';

export type ObjectiveRequest = Pick<Objective, 'name' | 'type' | 'threshold' | 'isEnabled'>
export type NeglectedAccountsObjectiveRequest = Pick<NeglectedAccountsObjective, 'communicationAction' | 'departments' | 'appsIds' | 'tags' | 'channelsIds' | 'categories' | 'lastEngagementThresholdInDays' | 'organizationIds'> & ObjectiveRequest;
export type SOAThresholdObjectiveRequest = Pick<SOAThresholdObjective, 'categories' | 'departments' | 'comparedDepartments' | 'appsIds' | 'channelsIds' | 'tags' | 'weeksCount'> & ObjectiveRequest
export type SOAThresholdByOrganizationsObjectiveRequest = Pick<SOAThresholdByOrganizationsObjective, 'organizationIds' | 'directionality' | 'departments' | 'appsIds' | 'channelsIds' | 'weeksCount'> & ObjectiveRequest
export type SOAAlignmentObjectiveRequest = Pick<SOAAlignmentObjective, 'categories' | 'departments' | 'comparedDepartments' | 'tags' | 'weeksCount'> & ObjectiveRequest
export type DepartmentInclusionObjectiveRequest = Pick<DepartmentInclusionObjective, 'categories' | 'departments' | 'tags' | 'daysCount'> & ObjectiveRequest

const ObjectiveController = {
  async getObjectives(tenantId: Tenant['id'], enabledOnly: Boolean): Promise<Objective[]> {
    try {
      const response: HttpResponseResult<Objective[]> = await getRequest('objective', { tenantId, enabledOnly });
      if (response?.data) {
        return response.data;
      }
    } catch (err) {
      DebuggerConsole.error('Failed fetching objectives', err, { tenantId });
    }
    return [];
  },
  async getObjectiveById(tenantId: Tenant['id'], objectiveId: String): Promise<Objective | null> {
    try {
      const response: HttpResponseResult<Objective> = await getRequest(`objective/${objectiveId}`, { tenantId });
      if (response?.data) {
        return response.data;
      }
    } catch (err) {
      DebuggerConsole.error('Failed fetching objective', err, { tenantId, objectiveId });
    }
    return null;
  },
  async createObjective(tenantId: Tenant['id'], objectiveRequest: ObjectiveRequest): Promise<Objective | null> {
    try {
      const response: HttpResponseResult<Objective> = await postRequest('objective', { ...objectiveRequest }, { params: { tenantId } });
      if (response?.data) {
        return response.data;
      }
    } catch (err) {
      DebuggerConsole.error('Failed creating objective', err, { tenantId });
    }
    return null;
  },
  async updateObjective(tenantId: Tenant['id'], objectiveId: String, objectiveRequest: ObjectiveRequest): Promise<Objective | null> {
    try {
      const response: HttpResponseResult<Objective> = await putRequest(`objective/${objectiveId}`, objectiveRequest, { params: { tenantId } });
      if (response?.data) {
        return response.data;
      }
    } catch (err) {
      DebuggerConsole.error('Failed updating objective', err, { tenantId, objectiveId });
    }
    return null;
  },
  async removeObjective(tenantId: Tenant['id'], objectiveId: String): Promise<String | null> {
    try {
      const response: HttpResponseResult<String> = await deleteRequest(`objective/${objectiveId}`, { tenantId });
      if (response?.data) {
        return response.data;
      }
    } catch (err) {
      DebuggerConsole.error('Failed removing objectives', err, { tenantId, objectiveId });
    }
    return null;
  },
};

export default ObjectiveController;
