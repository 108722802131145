import React, { useMemo } from 'react';

import { MetricSortingConfig } from 'global/api/controller/utils/OrganizationControllerSortingUtils';
import { getMetricDisplayName } from 'global/lists/Metric';
import { useMetadataContext } from 'screens/platform/cross-platform-components/context/metadata/MetadataContext';
import { DirectoryTableColumn } from 'screens/platform/directory/directoryTableColumnsHook';
import OrganizationMetricButton
  from 'screens/platform/directory/organizations/OrganizationsDirectoryScreen/OrganizationsTable/components/OrganizationMetricButton';
import OrganizationMetricValue
  from 'screens/platform/directory/organizations/OrganizationsDirectoryScreen/OrganizationsTable/components/OrganizationMetricValue';
import {
  OrganizationsTableColumnId,
} from 'screens/platform/directory/organizations/OrganizationsDirectoryScreen/OrganizationsTable/utils/organizationsTableColumnsConfig';
import OrganizationWithMetrics, {
  organizationMetrics,
} from 'screens/platform/directory/organizations/OrganizationsDirectoryScreen/OrganizationsTable/utils/OrganizationWithMetrics';

type MetricColumns = DirectoryTableColumn<
  OrganizationWithMetrics,
  OrganizationsTableColumnId,
  MetricSortingConfig
>[];

export default function useMetricColumns(): MetricColumns {
  const { isMetricEnabled } = useMetadataContext();

  return useMemo(
    () => organizationMetrics
      .filter((metric) => isMetricEnabled(metric))
      .map((metric) => ({
        title: getMetricDisplayName(metric),
        key: metric,
        customSortingConfig: { metricType: metric },
        render: (_, { id, metricValues }) => {
          const metricValue = metricValues?.[metric];
          return (
            <OrganizationMetricButton
              organizationId={id}
              metric={metric}
              metricValue={metricValue}
            >
              <OrganizationMetricValue
                metric={metric}
                metricValue={metricValue}
              />
            </OrganizationMetricButton>
          );
        },
      })),
    [isMetricEnabled],
  );
}
