import React from 'react';

import DepartmentBadge from 'common-ui-components/Badge/DepartmentBadge';
import TagBadge from 'common-ui-components/Badge/TagBadge';
import TrendingTopicEvent from 'model/Events/TrendingTopicEvent';

import style from 'screens/platform/cross-platform-components/EventsPanel/EventCell/EventTitle/style.module.scss';

interface Props {
  event: TrendingTopicEvent;
}

export default function TrendingTopicTitle({ event }: Props) {
  const {
    tag, changeFromAverage, firstDepartment, secondDepartment,
  } = event;
  const changePercentage = Math.abs(Math.ceil(changeFromAverage));
  const trendText = changeFromAverage > 0 ? 'up' : 'down';

  function DepartmentsBadges() {
    if (firstDepartment && secondDepartment) {
      return (
        <>
          <span>between</span>
          <DepartmentBadge department={firstDepartment} />
          and
          <DepartmentBadge department={secondDepartment} />
        </>
      );
    }
    return <>&nbsp;</>;
  }

  return (
    <div className={style.title}>
      Activity on
      <TagBadge tag={tag} />
      {`trended ${trendText}`}
      <DepartmentsBadges />
      {`by ${changePercentage}%`}
    </div>
  );
}
