import classNames from 'classnames';
import React, { useMemo } from 'react';

import Size from 'global/lists/Size';
import { assertSwitchIsExhaustive } from 'utils/TypescriptTricks';

import style from 'common-ui-components/CircleIcon/style.module.scss';

type SecondaryBadge = {
  path: string;
  alt: string;
};

export interface CircleIconProps {
  source: string;
  className?: string;
  title?: string;
  secondaryBadge?: SecondaryBadge;
  hasBorder?: boolean;
  showTitle?: boolean;
  size?: Size;
}

export default function CircleIcon({
  source,
  className,
  title,
  secondaryBadge,
  showTitle = true,
  hasBorder = true,
  size = Size.MEDIUM,
}: CircleIconProps) {
  const sizeClassName = useMemo(() => {
    switch (size) {
      case Size.BIG: return style.big;
      case Size.MEDIUM: return style.medium;
      case Size.SMALL: return style.small;
      case Size.EXTRA_BIG: return style.extraBig;
      default: return assertSwitchIsExhaustive(size);
    }
  }, [size]);

  return (
    <div
      className={classNames(
        style.circleIcon,
        sizeClassName,
        hasBorder && style.border,
        className,
      )}
    >
      {secondaryBadge && (
        <img
          src={secondaryBadge.path}
          alt={secondaryBadge.alt}
          title={showTitle ? secondaryBadge.alt : undefined}
          className={style.secondaryBadge}
        />
      )}
      <img
        src={source}
        alt={title}
        title={showTitle ? title : undefined}
        className={style.image}
        loading="lazy"
      />
    </div>
  );
}
