import { useAsync } from 'react-use';

import { AtomHighlight } from 'global/api/controller/AtomController';
import Api from 'global/api/platformApi';
import useTenantContext from 'screens/platform/cross-platform-components/context/tenant/TenantContext';
import { useUserContext } from 'screens/platform/cross-platform-components/context/user/UserContext';
import DebuggerConsole from 'utils/DebuggerConsole';
import { useMountedState } from 'utils/hooks';

export interface AtomHighlightsHookResponse {
  atomHighlightsData: (AtomHighlight & { id: string })[] | undefined;
  atomHighlightsLoading: boolean;
  hasAtomHighlightsNetworkError: boolean;
}

export default function useAtomHighlightsDataHook(): AtomHighlightsHookResponse {
  const { tenant: { id: tenantId } } = useTenantContext();
  const { personId } = useUserContext();
  const [hasAtomHighlightsNetworkError, setError] = useMountedState<boolean>(false);
  const {
    value: atomHighlightsData,
    loading: atomHighlightsLoading,
  } = useAsync(async () => {
    try {
      setError(false);
      const response = await Api.Atom.getAtomHighlights(tenantId);
      return response?.data.map((highlight) => ({
        ...highlight,
        // This is a temp solution until we fetch uuid from the BE
        id: `${tenantId}-${personId}-${highlight.permalink}`,
        atom: {
          ...highlight.atom,
          appId: highlight.appId,
          participatingExternalOrganizations: highlight.atom.externalOrganizationsIds || [],
        },
      }));
    } catch (err) {
      DebuggerConsole.error('Failed to fetch atom highlights', err);
      setError(true);
      return undefined;
    }
  }, [tenantId]);

  return {
    atomHighlightsData,
    atomHighlightsLoading,
    hasAtomHighlightsNetworkError,
  };
}
