import { useCallback } from 'react';

import PeopleMasterFilterHierarchyGroup
  from 'screens/platform/cross-platform-components/context/MasterFiltersContext/PeopleMasterFilter/PeopleMasterFilterHierarchyGroup';
import PersonMetadata from 'screens/platform/cross-platform-components/context/metadata/dataStructures/PersonMetadata';
import {
  useMetadataContext,
} from 'screens/platform/cross-platform-components/context/metadata/MetadataContext';
import Actions
  from 'screens/platform/cross-platform-components/MasterFiltersPanel/FilterSection/PeopleFilterSection/components/PeopleHierarchicalSearchResults/PeopleHierarchicalSearchResultsReducerActions';
import HierarchicalGroup from 'utils/HierarchicalDataStructures/HierarchicalGroup';
import { useCustomReducer } from 'utils/hooks';

type PersonId = string;
type TeamId = string;
type Department = string;

export interface PeopleHierarchicalSearchResultsState {
  peopleData: Partial<Record<PersonId, PersonMetadata>>;
  departmentsToPeople: Partial<Record<Department, PersonId[]>>;
  teamsToPeople: Partial<Record<TeamId, PersonId[]>>;
  peopleSelection: HierarchicalGroup;
  teamsSelection: HierarchicalGroup;
  departmentsSelection: HierarchicalGroup;
}

export type PeopleHierarchicalSearchResultsReducerAction = {
  type: 'TOGGLE_PERSON' | 'TOGGLE_DEPARTMENT' | 'TOGGLE_TEAM';
  payload: { id: string | null };
}

function PeopleHierarchicalSearchResultsReducer(
  state: PeopleHierarchicalSearchResultsState,
  action: PeopleHierarchicalSearchResultsReducerAction,
): PeopleHierarchicalSearchResultsState {
  switch (action.type) {
    case 'TOGGLE_PERSON': return Actions.handlePersonToggle(state, action.payload);
    case 'TOGGLE_DEPARTMENT': return Actions.handleDepartmentToggle(state, action.payload);
    case 'TOGGLE_TEAM': return Actions.handleTeamToggle(state, action.payload);
    default: return state;
  }
}

export default function usePeopleHierarchicalSearchResultsReducer(
  selection: PeopleMasterFilterHierarchyGroup,
) {
  const {
    persons: {
      allPeopleMetadata,
      departmentsToPersonsMap,
      teamsToPeopleMap,
    },
  } = useMetadataContext();

  const [state, dispatch] = useCustomReducer(
    PeopleHierarchicalSearchResultsReducer,
    {
      peopleData: allPeopleMetadata,
      departmentsToPeople: departmentsToPersonsMap,
      teamsToPeople: teamsToPeopleMap,
      peopleSelection: selection.getPeopleGroup(),
      departmentsSelection: selection.getDepartmentsGroup(),
      teamsSelection: selection.getTeamsGroup(),
    },
  );

  const getDispatchFunction = useCallback((actionType: PeopleHierarchicalSearchResultsReducerAction['type']) => (id: string | null) =>
    dispatch({ type: actionType, payload: { id } }), [dispatch]);

  return { state, getDispatchFunction };
}
