import classNames from 'classnames';
import React, { forwardRef } from 'react';

import Input, { InputProps } from 'common-ui-components/Input';

import { ReactComponent as SearchIcon } from 'assets/img/icon/search.svg';

import style from 'common-ui-components/SearchInput/style.module.scss';

type SearchInputProps = Omit<InputProps, 'leftIcon'>;

const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(
  (props, ref) => (
    <Input
      ref={ref}
      {...props}
      leftIcon={({ isFocused }) => (
        <SearchIcon
          className={classNames(style.searchIcon, isFocused && style.focused)}
        />
      )}
    />
  ),
);

export default SearchInput;
