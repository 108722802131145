import classNames from 'classnames';
import React from 'react';

import ExternalLink from 'common-ui-components/ExternalLink';
import Size from 'global/lists/Size';
import DomainImage from 'screens/platform/cross-platform-components/DomainImage';

import style from 'common-ui-components/ExternalLinkDescription/style.module.scss';

const URL_CAP_LENGTH = 256;

type DomainCardProps = {
  url: string;
  domain: string | null;
  className?: string;
}

function displayUrl(url: string) {
  return url.length <= URL_CAP_LENGTH
    ? url
    : `${url.substring(0, URL_CAP_LENGTH)}...`;
}

export default function ExternalLinkCard({ url, domain, className }: DomainCardProps) {
  return (
    <div className={classNames(style.container, className)}>
      <DomainImage domain={domain} size={Size.BIG} />
      <div className={style.description}>
        <ExternalLink to={url} title="Go to URL" className={style.url}>
          { displayUrl(url) }
        </ExternalLink>
      </div>
    </div>
  );
}
