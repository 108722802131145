import classNames from 'classnames';
import React from 'react';

import Badge from 'common-ui-components/Badge/index';

import style from 'common-ui-components/Badge/AIBadge/style.module.scss';

interface Props {
  className?: string;
}

export default function AIBadge({ className }: Props) {
  return (
    <Badge text="AI" className={classNames(style.aiBadge, className)} />
  );
}
