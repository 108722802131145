import Tenant, { INITIAL_DEPARTMENTS_COLORS } from 'model/Tenant';
import ObjectUtils from 'utils/ObjectUtils';

export interface TenantReducerState {
  currTenant: Tenant;
  allTenants?: Tenant[];
  tenantLogo: string | null;
}

export type TenantReducerAction =
| { type: 'INITIALIZE'; payload: { newTenant: Tenant } }
| { type: 'SET_ALL_TENANTS'; payload: { allTenants: Tenant[] } }
| { type: 'SET_LOGO'; payload: { url: string | null } }

function initializeNewTenant(
  state: TenantReducerState,
  currTenant: Tenant,
): TenantReducerState {
  return {
    ...state,
    currTenant: {
      ...currTenant,
      departments: ObjectUtils.isEmpty(currTenant.departments)
        ? INITIAL_DEPARTMENTS_COLORS
        : currTenant.departments,
    },
  };
}

const TenantReducer = (state: TenantReducerState, action: TenantReducerAction) => {
  switch (action.type) {
    case 'INITIALIZE': return initializeNewTenant(state, action.payload.newTenant);
    case 'SET_ALL_TENANTS': return {
      ...state,
      allTenants: action.payload.allTenants
        .sort(ObjectUtils.compareByKeyAlphabetically('name')),
    };
    case 'SET_LOGO': return {
      ...state,
      tenantLogo: action.payload.url,
    };
    default: return state;
  }
};

export default TenantReducer;
