import classNames from 'classnames';
import React, { useMemo } from 'react';

import MultiImages from 'common-ui-components/MultiImages/index';
import {
  ImageType,
  MultiImagesSpecificIdentityProps,
  useImageClickHandler,
} from 'common-ui-components/MultiImages/MultiImagesUtils';
import MultiOrganizationsImagesUtils from 'common-ui-components/MultiImages/MultiOrganizationsImages/MultiOrganizationsImagesUtils';
import MultiPersonsImagesUtils from 'common-ui-components/MultiImages/MultiPersonsImages/MultiPersonsImagesUtils';
import { ImageShape } from 'common-ui-components/MultiImages/NodeImage';
import { FilterFieldType } from 'global/api/controller/utils/filtering/FilterCondition';
import { IdFilterOperator } from 'global/api/controller/utils/filtering/IdFilterCondition';
import PersonFilterableProperty from 'global/api/controller/utils/filtering/PersonFilterableProperty';
import Size from 'global/lists/Size';
import usePersonsMetadata from 'screens/platform/cross-platform-components/context/metadata/hooks/personsMetadataHook';
import { usePlatformContext } from 'screens/platform/cross-platform-components/context/platform/PlatformContext';
import useTenantContext from 'screens/platform/cross-platform-components/context/tenant/TenantContext';
import {
  InteractionsTableState,
} from 'screens/platform/cross-platform-components/Panels/BottomPanel/CollapsibleInteractionsDrawer';
import useRedirectToFilteredDirectory from 'screens/platform/directory/hooks/redirectToFilteredDirectoryHook';
import ArrayUtils from 'utils/ArrayUtils';

import multiImagesStyle from 'common-ui-components/MultiImages/style.module.scss';

const { OrganizationImage } = MultiOrganizationsImagesUtils;
const { PersonImage } = MultiPersonsImagesUtils;

export default function MultiPersonsImages({
  ids,
  size = Size.MEDIUM,
  hoverable = true,
  onHiddenEntitiesClick,
  externalOrganizationId = '',
  maxImagesThreshold,
}: MultiImagesSpecificIdentityProps) {
  const handleImageClick = useImageClickHandler();
  const redirectToFilteredDirectory = useRedirectToFilteredDirectory();
  const { interactionsTable } = usePlatformContext();
  const { getDepartmentColor } = useTenantContext();

  const { value: persons, loading } = usePersonsMetadata(ids);

  function getVisibleImages(visibleIds: string[]) {
    let visibleImages = (
      visibleIds
        .map((id) => persons?.[id])
        .filter(ArrayUtils.isDefined)
        .map((person, idx) => (
          <PersonImage
            key={person.id}
            person={person}
            name={person.name}
            borderColor={getDepartmentColor(person.department)}
            shape={person.isExternal ? ImageShape.SQUARE : ImageShape.ROUND}
            size={size}
            hoverable={hoverable}
            onClick={hoverable ? handleImageClick : undefined}
            order={idx + (externalOrganizationId ? 1 : 0)}
          />
        ))
    );
    if (externalOrganizationId) {
      visibleImages = [
        <OrganizationImage
          key={externalOrganizationId}
          organizationId={externalOrganizationId}
          name={externalOrganizationId}
          size={size}
          hoverable={hoverable}
          onClick={handleImageClick}
          order={0}
        />,
      ].concat(visibleImages);
    }
    return visibleImages;
  }

  function getHiddenEntitiesLabels(hiddenIds: string[]): string[] {
    return hiddenIds
      .map((personId) => persons?.[personId]?.name)
      .filter(ArrayUtils.isDefined);
  }

  const isExternalPeopleIncluded = useMemo(
    () => ids.some((id) => persons?.[id]?.isExternal),
    [persons, ids],
  );

  const hiddenEntitiesDefaultClickHandler = () => {
    if (isExternalPeopleIncluded) return;
    redirectToFilteredDirectory({
      screen: 'people',
      filterConditions: {
        [PersonFilterableProperty.ID]: {
          fieldType: FilterFieldType.ID,
          fieldName: PersonFilterableProperty.ID,
          operator: IdFilterOperator.IS_ANY_OF,
          value: ids,
        },
      },
    });
    if (interactionsTable.openState !== InteractionsTableState.CLOSED) {
      interactionsTable.setOpenState(InteractionsTableState.CLOSED, null);
    }
  };

  return (
    <MultiImages
      ids={externalOrganizationId ? [externalOrganizationId].concat(ids) : ids}
      getVisibleImages={getVisibleImages}
      getHiddenEntitiesLabels={getHiddenEntitiesLabels}
      onHiddenEntitiesClick={onHiddenEntitiesClick || hiddenEntitiesDefaultClickHandler}
      size={size}
      imageType={
        isExternalPeopleIncluded
          ? ImageType.CONTACTS
          : ImageType.PEOPLE
      }
      hiddenEntitiesNodeClassName={
        classNames({
          [multiImagesStyle.personImageWrapperHidden]: size === Size.BIG,
          [multiImagesStyle.personImageWrapperHiddenMd]: size === Size.MEDIUM,
          [multiImagesStyle.personImageWrapperHiddenSm]: size === Size.SMALL,
        })
      }
      loading={loading}
      maxImagesThreshold={maxImagesThreshold}
    />
  );
}
