import Organization from 'model/Organization';
import useAsync, { AsyncState } from 'react-use/lib/useAsync';
import { useMetadataContext } from 'screens/platform/cross-platform-components/context/metadata/MetadataContext';
import ArrayUtils from 'utils/ArrayUtils';

export default function useOrganizationsMetadata(
  ids: string[] | undefined | null,
): AsyncState<Partial<Record<string, Organization>> | undefined> {
  const { organizations } = useMetadataContext();
  return useAsync(
    async () =>
      (ids ? (await Promise.all(ids.map(organizations.getById)))
        .filter(ArrayUtils.isDefined)
        .reduce(
          (acc, org) => ({
            ...acc,
            [org.id]: org,
          }),
          {} as Partial<Record<string, Organization>>,
        ) : undefined),
    [ids],
  );
}
