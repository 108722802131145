import { createContext, useContext } from 'react';

import DirectoryContextType from 'screens/platform/directory/components/GenericDirectoryScreen/context/DirectoryContext';
import { OrganizationsTableColumnsOrder } from 'screens/platform/directory/organizations/OrganizationsDirectoryScreen/OrganizationsTable/hooks/OrganizationsDirectoryTableColumnsHook';
import { OrganizationsFiltersState } from 'screens/platform/directory/organizations/OrganizationsDirectoryScreen/OrganizationsTable/hooks/organizationsFilterHook';

export type OrganizationsDirectoryContextType =
  DirectoryContextType<OrganizationsTableColumnsOrder> & {
    organizationFilters: OrganizationsFiltersState;
  };

export function useOrganizationsDirectoryContext(): OrganizationsDirectoryContextType {
  return useContext<OrganizationsDirectoryContextType>(
    OrganizationsDirectoryContext,
  );
}

const OrganizationsDirectoryContext = createContext<OrganizationsDirectoryContextType>({} as any);
export default OrganizationsDirectoryContext;
