import classNames from 'classnames';
import React, { ReactNode } from 'react';

import style from 'screens/platform/cross-platform-components/LeftPanel/style.module.scss';

interface Props {
  isCollapsed?: boolean;
  children: ReactNode;
}

export default function LeftPanel({ isCollapsed = false, children }: Props) {
  return (
    <div
      className={classNames(style.leftPanel, isCollapsed && style.collapsed)}
    >
      {children}
    </div>
  );
}
