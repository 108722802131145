import classNames from 'classnames';
import React, { ReactNode } from 'react';

import style from 'common-ui-components/DynamicTitle/style.module.scss';

type Props = {
  children: ReactNode;
  className?: string;
};

export default function DynamicTitle({ children, className }: Props) {
  return (
    <div className={classNames(style.dynamicTitle, className)}>{children}</div>
  );
}
