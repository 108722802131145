import { MetricValueResponse } from 'global/api/controller/MetricController';
import { RequestPaging } from 'global/api/controller/utils/commonControllerUtils';
import FilterCondition from 'global/api/controller/utils/filtering/FilterCondition';
import TagFilterableProperty from 'global/api/controller/utils/filtering/TagFilterableProperty';
import { TagRequestSorting } from 'global/api/controller/utils/TagsControllerSortingUtils';
import {
  deleteRequest,
  getRequest,
  HttpResponse,
  postRequest, putRequest,
} from 'global/api/platformApiHelpers';
import ItemsByGroup from 'global/ItemsByGroup';
import Metric from 'global/lists/Metric';
import Tag from 'model/Tag';
import Tenant from 'model/Tenant';
import TopicField from 'screens/platform/directory/topics/TopicsDirectoryScreen/TopicsTable/utils/TopicField';
import { convertTagsToTagsByCategoriesMap } from 'utils/TagUtils';

export type CategoryColorsResponse = {
  categoriesColors: Record<string, string>;
  categoryColorsOrdinals: Record<string, number>;
};

export type MinimalTag = Pick<Tag, 'value' | 'isTopic' | 'categories' >;

export type DirectoryTopic = {
  name: string;
  keywords: string[];
  categories: string[];
  peopleIds: string[];
  departments: string[];
  organizationsIds: string[];
  metrics: Partial<Record<Metric, MetricValueResponse>>;
}

type DirectoryTopicsResponse = {
  topics: DirectoryTopic[];
  totalTopicsCount: number;
}

type DirectoryTagsRequest = {
  pagingConfig: RequestPaging;
  sortingConfig: TagRequestSorting;
  filterConditions: FilterCondition<TagFilterableProperty>[];
  requestedFields: TopicField[];
}

export type CategoriesTagsKeywords = Record<string, Record<string, string[]>>

const TagsSetController = {
  async getTagsSet(
    tenantId: Tenant['id'],
    query?: string,
  ): HttpResponse<MinimalTag[]> {
    return getRequest('tags-set', { tenantId, query: query || null });
  },

  async getTagsData(
    tenantId: Tenant['id'],
  ): HttpResponse<Tag[]> {
    return getRequest('tag', { tenantId });
  },

  async getTagsSetByCategories(
    tenantId: Tenant['id'],
    query?: string,
  ): HttpResponse<ItemsByGroup[]> {
    const response = await TagsSetController.getTagsSet(tenantId, query);
    return {
      data: Object.entries(convertTagsToTagsByCategoriesMap(response?.data)).reduce(
        (acc, [category, tags]) => {
          acc.push({ group: category, items: tags });
          return acc;
        },
        [] as ItemsByGroup[],
      ),
    };
  },

  async getCategoriesColors(
    tenantId: Tenant['id'],
  ): HttpResponse<CategoryColorsResponse> {
    return getRequest('tags-set/categories-colors', { tenantId });
  },

  async addNewTags(
    tenantId: Tenant['id'],
    tags: Tag[],
    isSuggested = false,
  ): HttpResponse<Record<string, string[]>> {
    return postRequest('tags-set', { tags }, { params: { tenantId, isSuggested } });
  },

  async deleteTags(
    tenantId: Tenant['id'],
    values: string[],
    isSuggested = false,
  ): HttpResponse<void> {
    return deleteRequest('tags-set', { tenantId, isSuggested }, { values });
  },

  async getSuggestedTags(tenantId: Tenant['id']): HttpResponse<CategoriesTagsKeywords> {
    return getRequest('tags-set/suggested', { tenantId });
  },

  async getDirectoryTopics(tenantId: Tenant['id'], requestBody: DirectoryTagsRequest): HttpResponse<DirectoryTopicsResponse> {
    return postRequest('tag/directory', requestBody, { params: { tenantId } });
  },

  async getDirectoryTag(tenantId: Tenant['id'], tag: string): HttpResponse<DirectoryTopic> {
    return getRequest(`tag/directory/${tag}`, { tenantId });
  },

  Tag: {
    async add(tenantId: Tenant['id'], tag: string, keywords: string[], isTopic: boolean, categories: string[]): HttpResponse<void> {
      return putRequest('tags-set/tag', {
        tag, keywords, isTopic, categories,
      }, { params: { tenantId } });
    },

    async edit(
      tenantId: Tenant['id'],
      newLabel: string | null,
      previousLabel: string,
      keywords: string[],
      isTopic: boolean,
      categories: string[],
    ): HttpResponse<void> {
      // TODO: should be 'PUT tags-set/tag/:id'
      return postRequest('tags-set/tag', {
        newLabel, previousLabel, keywords, isTopic, categories,
      }, { params: { tenantId } });
    },

    async delete(tenantId: Tenant['id'], tag: string): HttpResponse<void> {
      // TODO: should be 'DELETE tags-set/category/:id'
      return deleteRequest(
        'tags-set/tag',
        { tenantId },
        { tag },
      );
    },
  },
};

export default TagsSetController;
