import React, { useEffect, useRef } from 'react';

import { AtomsSummary } from 'global/api/controller/AtomController';
import Api from 'global/api/platformApi';
import {
  useCustomAtomsFilters,
} from 'screens/platform/cross-platform-components/context/CustomFilters/CustomAtomsFilters/CustomAtomsFiltersContextProvider';
import {
  useMasterFilters,
} from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFiltersContext';
import useInteractionsFetching
  from 'screens/platform/cross-platform-components/Interactions/hooks/InteractionsFetchingHook';
import { useInteractionsContext } from 'screens/platform/cross-platform-components/Interactions/InteractionsContext';
import DebuggerConsole from 'utils/DebuggerConsole';
import { useMountedState } from 'utils/hooks';
import ToastManager, { ToastKey } from 'utils/ToastManager';

export default function useInteractionsSummaryFetching() {
  const { currentFilters } = useMasterFilters();
  const { customAtomsFilters } = useCustomAtomsFilters();
  const { loading, pagination, interactionsMode } = useInteractionsContext();

  const [data, setData] = useMountedState<AtomsSummary | undefined>(undefined);

  const queryRef = useRef(0);

  const fetchInteractions = useInteractionsFetching(Api.Atom.getAtomsSummary);

  function handleError() {
    ToastManager.show(
      (
        <div>
          Something went wrong.
          <br />
          Please try again in a few minutes.
        </div>
      ), { key: ToastKey.INTERACTIONS_SUMMARY, type: 'warning' },
    );
    interactionsMode.set('table');
  }

  useEffect(() => {
    async function fetchData() {
      loading.set(true);

      queryRef.current += 1;
      const currentQueryRef = queryRef.current;

      try {
        const response = await fetchInteractions(pagination.value, false);
        if (response?.data && currentQueryRef === queryRef.current) {
          setData(response.data);
        } else {
          handleError();
        }
      } catch (err) {
        DebuggerConsole.error(err);
        handleError();
      } finally {
        loading.set(false);
      }
    }

    fetchData().then();
  }, [
    currentFilters.version,
    customAtomsFilters,
    interactionsMode.value,
  ]);

  return { data, loading: loading.value };
}
