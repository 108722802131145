import classNames from 'classnames';
import React from 'react';

import FadeOutWrapper from 'common-ui-components/FadeOutWrapper';
import Lottie from 'common-ui-components/Lottie';

import akoodaLogoAnimation from 'assets/video/akooda-logo-loading.json';

import style from 'common-ui-components/LoadingModal/style.module.scss';

interface LoadingModalProps {
  isLoading: boolean;
  text?: string;
  isFullScreen?: boolean;
}

export default function LoadingModal({
  isLoading,
  text,
  isFullScreen = false,
}: LoadingModalProps) {
  return (
    <FadeOutWrapper isLoading={isLoading} allScreen transparent={!isFullScreen}>
      <div
        className={classNames(
          style.loadingModal,
          isFullScreen && style.fullScreen,
        )}
      >
        <Lottie
          file={akoodaLogoAnimation}
          width={85}
          height={85}
        />
        <span>{text || 'Loading...'}</span>
      </div>
    </FadeOutWrapper>
  );
}
