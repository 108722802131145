import React, { useEffect, useMemo } from 'react';

import Button from 'common-ui-components/Button';
import Caret from 'common-ui-components/Caret';
import getAppDisplayName from 'global/lists/appsNames';
import { useMetadataContext } from 'screens/platform/cross-platform-components/context/metadata/MetadataContext';
import GroupCheckbox from 'screens/platform/cross-platform-components/HierarchicalSearchInput/components/GroupCheckbox';
import getSizeLabel
  from 'screens/platform/cross-platform-components/HierarchicalSearchInput/components/groupLabelUtils';
import CollapsibleGroupItems
  from 'screens/platform/cross-platform-components/HierarchicalSearchInput/components/HierarchicalSearchResults/CollapsibleGroup/CollapsibleGroupItems';
import useCollapsibleGroupSelectionStatus
  from 'screens/platform/cross-platform-components/HierarchicalSearchInput/components/HierarchicalSearchResults/CollapsibleGroup/CollapsibleGroupSelectionStatusHook';
import {
  HierarchicalItemType,
} from 'screens/platform/cross-platform-components/HierarchicalSearchInput/HierarchicalItemTypeConfig';
import HierarchicalGroup from 'utils/HierarchicalDataStructures/HierarchicalGroup';
import { useToggle } from 'utils/hooks';

import style from 'screens/platform/cross-platform-components/HierarchicalSearchInput/style.module.scss';

export interface CollapsibleGroupProps {
  group: HierarchicalGroup;
  updateParentSelectionStatus: () => void;
  parentGroupSelection: { isSelected: boolean; isPartiallySelected: boolean };
  visibleItemsIds: string[];
  open: boolean;
  type: HierarchicalItemType;
  showEmptyGroupAsCollapsible?: boolean;
  showGroupSize?: boolean;
}

export default function CollapsibleGroup({
  group,
  updateParentSelectionStatus,
  parentGroupSelection,
  visibleItemsIds,
  open,
  type,
  showEmptyGroupAsCollapsible = true,
  showGroupSize = true,
}: CollapsibleGroupProps) {
  const [isOpen, toggleIsOpen] = useToggle(open);

  const {
    selectionStatus,
    updateSelectionStatus,
  } = useCollapsibleGroupSelectionStatus({
    group,
    postUpdateCallback: updateParentSelectionStatus,
  });

  useEffect(() => {
    const subscriptionId = group.subscribe((_, __) => {
      updateSelectionStatus();
    });
    return () => group.unsubscribe(subscriptionId);
  }, [group]);

  useEffect(() => {
    if (group.isSelected !== selectionStatus.isSelected) updateSelectionStatus();
  }, [parentGroupSelection]);

  const groupId = group.id;
  const metadataContext = useMetadataContext();

  function toggleCurrentGroup() {
    group.toggle();
  }

  const groupNameByType = useMemo(() => (
    type === HierarchicalItemType.CHANNEL
      ? getAppDisplayName(groupId)
      : groupId
  ), [metadataContext, type, groupId]);

  const hasItems = visibleItemsIds.length > 0;
  const showAsCollapsible = hasItems || showEmptyGroupAsCollapsible;

  const sizeLabel = useMemo(() => getSizeLabel(
    visibleItemsIds.length,
    type,
    groupId,
  ), [visibleItemsIds, group, type]);

  return (
    <div className={style.collapsibleGroup}>
      <div className={style.groupTitle}>
        <div>
          <GroupCheckbox
            type={type}
            selectionStatus={selectionStatus}
            groupId={groupId}
            onClick={toggleCurrentGroup}
          />
        </div>
        <Button
          className={style.titleLeft}
          onClick={showAsCollapsible ? toggleIsOpen : toggleCurrentGroup}
        >
          <span className={style.groupLabel}>
            {groupNameByType}
            {showAsCollapsible && showGroupSize && (
              <span className={style.groupSize}>{sizeLabel}</span>
            )}
          </span>
          {showAsCollapsible && <Caret isOpen={isOpen} />}
        </Button>
      </div>
      {showAsCollapsible && (
        <CollapsibleGroupItems {...{
          isOpen,
          visibleItemsIds,
          group,
          type,
          updateSelectionStatus,
        }}
        />
      )}
    </div>
  );
}
