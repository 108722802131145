import { colord } from 'colord';

import format from 'date-fns/format';
import { ShareOfAttentionTableDataResponse } from 'global/api/controller/TimelineController';
import colors from 'global/colors';
import { DatesRange } from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFilters';
import DateUtils, { convertUserTimezoneToUTC } from 'utils/DateUtils';

export type ShareOfAttentionTableColumn = {
  id: string;
  data: {
    x: string;
    y: number;
    datesRange: DatesRange;
  }[];
}

export default class ShareOfAttentionTableUtils {
  static parseShareOfAttentionData(
    fetchedData: ShareOfAttentionTableDataResponse,
  ): ShareOfAttentionTableColumn[] {
    return fetchedData.map((row) => ({
      id: row.id,
      data: row.data.map((column) => {
        const fromDate = convertUserTimezoneToUTC(new Date(column.datesRange.from));
        const toDate = convertUserTimezoneToUTC(new Date(column.datesRange.to));
        const label = format(fromDate, DateUtils.DateFormat.MONTH_DAY);
        return {
          x: label,
          y: column.value,
          datesRange: {
            from: fromDate,
            to: toDate,
          },
        };
      }),
    }));
  }

  static calculateColor(cellData): string {
    const percentage = (cellData.value || 0) / 100;
    const opacity = Math.max(0, Math.min(percentage, 1));
    return colord(colors.BLUE).alpha(opacity).toHex();
  }

  static calculateTextColor(cellData): string {
    const percentage = ((cellData.value || 0) + 40) / 100;
    const opacity = Math.max(0, Math.min(percentage, 1));
    return colord(colors.DARK_BLUE).alpha(opacity).toHex();
  }
}
