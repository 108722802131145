import { useCallback } from 'react';

import Organization, { OrganizationRisk, OrganizationSize } from 'model/Organization';
import OrganizationsMasterFilterHierarchyGroup
  from 'screens/platform/cross-platform-components/context/MasterFiltersContext/OrganizationsMasterFilter/OrganizationsMasterFilterHierarchyGroup';
import { OrganizationsMetadata, useOrganizationsMetadataContext } from 'screens/platform/cross-platform-components/context/OrganizationsMetadata/OrganizationsMetadataContext';
import Actions
  from 'screens/platform/cross-platform-components/MasterFiltersPanel/FilterSection/OrganizationsFilterSection/components/OrganizationsHierarchicalSearchResults/OrganizationsHierarchicalSearchResultsReducerActions';
import HierarchicalGroup from 'utils/HierarchicalDataStructures/HierarchicalGroup';
import { useCustomReducer } from 'utils/hooks';

export interface OrganizationsHierarchicalSearchResultsState {
  organizationsSelection: HierarchicalGroup;
  riskSelection: HierarchicalGroup;
  sizeSelection: HierarchicalGroup;
  segmentSelection: HierarchicalGroup;
}

export interface ToggleActionPayload<IdType extends string> {
  id: IdType | null;
  organizationsMetadata: OrganizationsMetadata;
}
interface OrganizationsHierarchicalSearchResultsReducerActionMap {
  TOGGLE_ORGANIZATION: { type: 'TOGGLE_ORGANIZATION'; payload: ToggleActionPayload<Organization['id']> };
  TOGGLE_RISK: { type: 'TOGGLE_RISK'; payload: ToggleActionPayload<OrganizationRisk | 'UNDEFINED_PROPERTY'> };
  TOGGLE_SIZE: { type: 'TOGGLE_SIZE'; payload: ToggleActionPayload<OrganizationSize | 'UNDEFINED_PROPERTY'> };
  TOGGLE_SEGMENT: { type: 'TOGGLE_SEGMENT'; payload: ToggleActionPayload<string | 'UNDEFINED_PROPERTY'> };
}
type ReducerActionType = keyof OrganizationsHierarchicalSearchResultsReducerActionMap;
type ReducerAction = OrganizationsHierarchicalSearchResultsReducerActionMap[ReducerActionType];

function OrganizationsHierarchicalSearchResultsReducer(
  state: OrganizationsHierarchicalSearchResultsState,
  action: ReducerAction,
): OrganizationsHierarchicalSearchResultsState {
  switch (action.type) {
    case 'TOGGLE_ORGANIZATION': return Actions.handleOrganizationToggle(state, action.payload);
    case 'TOGGLE_RISK': return Actions.handleRiskToggle(state, action.payload);
    case 'TOGGLE_SIZE': return Actions.handleSizeToggle(state, action.payload);
    case 'TOGGLE_SEGMENT': return Actions.handleSegmentToggle(state, action.payload);
    default: return state;
  }
}

export default function useOrganizationsHierarchicalSearchResultsReducer(
  selection: OrganizationsMasterFilterHierarchyGroup,
) {
  const initialState: OrganizationsHierarchicalSearchResultsState = {
    organizationsSelection: selection.getOrganizationsGroup(),
    riskSelection: selection.getRisksGroup(),
    sizeSelection: selection.getSizesGroup(),
    segmentSelection: selection.getSegmentsGroup(),
  };
  const [state, dispatch] = useCustomReducer(
    OrganizationsHierarchicalSearchResultsReducer,
    initialState,
  );

  const organizationsMetadata = useOrganizationsMetadataContext();

  const getDispatchFunction = useCallback((actionType: ReducerActionType) =>
    <T extends string>(id: T | null) => {
      dispatch({
        type: actionType,
        payload: {
          id: id as any,
          organizationsMetadata,
        },
      });
    }, [dispatch]);

  return { state, getDispatchFunction };
}
