import { OrganizationRisk, OrganizationSize } from 'model/Organization';
import NullableValuesSelection from 'screens/platform/cross-platform-components/context/MasterFiltersContext/NullableValuesSelection';
import UnionBasedMasterFilter
  from 'screens/platform/cross-platform-components/context/MasterFiltersContext/UnionBasedMasterFilter';

export default class OrganizationsMasterFilter
  extends UnionBasedMasterFilter<OrganizationsMasterFilter> {
  readonly organizationsIds: NullableValuesSelection<string>;
  readonly selectedRisks: NullableValuesSelection<OrganizationRisk>;
  readonly selectedSizes: NullableValuesSelection<OrganizationSize>;
  readonly selectedSegments: NullableValuesSelection<string>;

  constructor(initializer: {
    organizationsIds: NullableValuesSelection<string>;
    selectedRisks: NullableValuesSelection<OrganizationRisk>;
    selectedSizes: NullableValuesSelection<OrganizationSize>;
    selectedSegments: NullableValuesSelection<string>;
  }) {
    super();
    this.organizationsIds = initializer.organizationsIds;
    this.selectedRisks = initializer.selectedRisks;
    this.selectedSizes = initializer.selectedSizes;
    this.selectedSegments = initializer.selectedSegments;
  }

  clone() {
    return new OrganizationsMasterFilter({
      organizationsIds: this.organizationsIds,
      selectedRisks: this.selectedRisks,
      selectedSizes: this.selectedSizes,
      selectedSegments: this.selectedSegments,
    });
  }

  equalsIgnoreFlags(other: any): boolean {
    if (!super.isSameType(other)) return false;

    return this.organizationsIds.equals(other)
      && this.selectedRisks.equals(other.selectedRisks)
      && this.selectedSizes.equals(other.selectedSizes)
      && this.selectedSegments.equals(other.selectedSegments);
  }

  static generateAllSelected(includeUndefined = true): OrganizationsMasterFilter {
    return new OrganizationsMasterFilter({
      organizationsIds: new NullableValuesSelection(null, includeUndefined),
      selectedRisks: NullableValuesSelection.getAllSelected(),
      selectedSizes: NullableValuesSelection.getAllSelected(),
      selectedSegments: NullableValuesSelection.getAllSelected(),
    });
  }

  static generateEmpty(): OrganizationsMasterFilter {
    return new OrganizationsMasterFilter({
      organizationsIds: NullableValuesSelection.getEmpty(),
      selectedRisks: NullableValuesSelection.getEmpty(),
      selectedSizes: NullableValuesSelection.getEmpty(),
      selectedSegments: NullableValuesSelection.getEmpty(),
    });
  }

  static generateNoOrganizations(): OrganizationsMasterFilter {
    return new OrganizationsMasterFilter({
      organizationsIds: new NullableValuesSelection([], true),
      selectedRisks: NullableValuesSelection.getEmpty(),
      selectedSizes: NullableValuesSelection.getEmpty(),
      selectedSegments: NullableValuesSelection.getEmpty(),
    });
  }

  isAllSelected(): boolean {
    return this.organizationsIds.isAllSelected()
      && this.selectedRisks.isAllSelected()
      && this.selectedSizes.isAllSelected()
      && this.selectedSegments.isAllSelected();
  }

  isEmpty(): boolean {
    return this.organizationsIds.isEmpty();
  }
}
