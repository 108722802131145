import React, { useEffect, useMemo } from 'react';

import Switch from 'common-ui-components/Switch';
import Analytics, { AnalyticsEvent } from 'global/Analytics';
import {
  useCustomAtomsFilters,
} from 'screens/platform/cross-platform-components/context/CustomFilters/CustomAtomsFilters/CustomAtomsFiltersContextProvider';
import useDebouncedSummaryToggle
  from 'screens/platform/cross-platform-components/Interactions/components/ExtendedInteractionsTableHeader/InteractionsSummaryToggle/DebouncedSummaryToggleHook';
import { useInteractionsContext } from 'screens/platform/cross-platform-components/Interactions/InteractionsContext';

import sparklesIcon from 'assets/img/icon/sparkles.svg';

import style from 'screens/platform/cross-platform-components/Interactions/components/ExtendedInteractionsTableHeader/style.module.scss';

export default function InteractionsSummaryToggle() {
  const { customAtomsFilters: { searchQuery } } = useCustomAtomsFilters();
  const { loading, interactionsMode, noInteractions } = useInteractionsContext();
  const { isToggled, onChange } = useDebouncedSummaryToggle();

  useEffect(() => {
    if (isToggled) {
      Analytics.trackEvent(
        AnalyticsEvent.INTERACTIONS_SUMMARY_CLICK,
        { textQuery: searchQuery },
      );
    }
  }, [isToggled]);

  const isDisabled = useMemo(() => {
    if (noInteractions.value) return true;
    return loading.value && interactionsMode.value === 'table';
  }, [loading.value, interactionsMode.value]);

  return interactionsMode.isSummaryModeAvailable ? (
    <Switch
      active={isToggled}
      onChange={onChange}
      loading={loading.value}
      disabled={isDisabled}
      color="yellow"
      customContent={(
        <div className={style.insideSwitch}>
          Summarize
        </div>
      )}
      ballIcon={sparklesIcon}
      data-testid="interactions-summary-toggle"
      title={isDisabled ? 'No interactions to summarize 😕' : undefined}
    />
  ) : null;
}
