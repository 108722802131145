import classNames from 'classnames';
import React from 'react';

import Button from 'common-ui-components/Button';
import { ImageShape } from 'common-ui-components/MultiImages/NodeImage';
import Tooltip from 'common-ui-components/Tooltip';
import Size from 'global/lists/Size';

import style from 'common-ui-components/MultiImages/HiddenEntitiesNode/style.module.scss';

const MAX_ENTITIES = 99;

interface Props {
    entities: string[];
    size: Size;
    shape?: ImageShape;
    dark?: boolean;
    className?: string;
    onClick?: () => void;
    titleAttributeText?: string;
}

export default function HiddenEntitiesNode({
  entities,
  size,
  shape,
  dark = false,
  className,
  onClick,
  titleAttributeText,
}: Props) {
  if (entities.length === 0) {
    return null;
  }

  return (
    <Tooltip
      content={(
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {Array.from(new Set(entities)).map((entity) => (
            <span key={entity}>
              {entity}
            </span>
          ))}
        </div>
    )}
    >
      <div className={className}>
        <Button
          className={classNames(
            style.button,
            size === Size.BIG && style.large,
            dark && style.darkColors,
            shape === ImageShape.SQUARE && style.square,
          )}
          onClick={onClick}
          title={titleAttributeText}
        >
          {`+${Math.min(entities.length, MAX_ENTITIES)}`}
        </Button>
      </div>
    </Tooltip>
  );
}
