import { RewriteFrames as RewriteFramesIntegration } from '@sentry/integrations';
import * as Sentry from '@sentry/react';

import { isRunningLocally } from 'global/config';
import Environment from 'global/lists/environments';

export function shouldRunSentry() {
  return !isRunningLocally() && process.env.CLIENT_ENV === Environment.PRODUCTION;
}

export default function initializeSentry() {
  if (shouldRunSentry()) {
    Sentry.init({
      dsn: 'https://84b73a8187b54e838b77e975fcef83cc@o818634.ingest.sentry.io/5808134',
      environment: process.env.CLIENT_ENV,
      release: process.env.SENTRY_RELEASE,
      dist: process.env.SENTRY_RELEASE,
      integrations: [
        new RewriteFramesIntegration({
          prefix: '~/', // This defines a prefix to the artifacts (i.e. the files that Sentry saves as the sourcemap)
        }),
      ],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      tracesSampleRate: 0.2,
    });
  }
}
