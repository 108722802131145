import React, { createContext, useContext } from 'react';

import { useToggle } from 'utils/hooks';

interface NavbarContextType {
  isNavbarMinimized: boolean;
  toggleNavbar: () => void;
}

const NavbarContext = createContext<NavbarContextType | undefined>(undefined);

export const useNavbarContext = () => {
  const context = useContext(NavbarContext);
  if (context === undefined) {
    throw new Error('useNavbarContext must be used within a NavbarContextProvider');
  }
  return context;
};

export const NavbarContextProvider: React.FC = ({ children }) => {
  const [isNavbarMinimized, toggleNavbar] = useToggle(true);

  return (
    <NavbarContext.Provider value={{ isNavbarMinimized, toggleNavbar }}>
      {children}
    </NavbarContext.Provider>
  );
};
