import classNames from 'classnames';
import React, { forwardRef } from 'react';

import HierarchicalSearchInputWrapper, {
  HierarchicalSearchInputWrapperProps,
} from 'screens/platform/cross-platform-components/HierarchicalSearchInput/HierarchicalSearchInputWrapper';

import style from 'screens/platform/cross-platform-components/MasterFiltersPanel/FilterSection/ColumnsHierarchicalSearchInputWrapper/style.module.scss';

const ColumnsHierarchicalSearchInputWrapper = forwardRef<
  HTMLInputElement,
  HierarchicalSearchInputWrapperProps
>((props, ref) => {
  const { className, children, ...otherProps } = props;

  const parsedClassName = classNames(className, style.columnsFilterPopover);

  return (
    <HierarchicalSearchInputWrapper
      className={parsedClassName}
      ref={ref}
      scrollable={false}
      {...otherProps}
    >
      {children}
    </HierarchicalSearchInputWrapper>
  );
});

export default ColumnsHierarchicalSearchInputWrapper;
