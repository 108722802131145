import HierarchicalMasterFilter
  from 'screens/platform/cross-platform-components/context/MasterFiltersContext/HierarchicalMasterFilter';
import {
  DefaultFilterLabels,
} from 'screens/platform/cross-platform-components/context/MasterFiltersContext/utils/DefaultMasterFiltersUtils';
import { OrganizationsMetadata } from 'screens/platform/cross-platform-components/context/OrganizationsMetadata/OrganizationsMetadataContext';
import HierarchicalGroup from 'utils/HierarchicalDataStructures/HierarchicalGroup';

const ORGANIZATIONS_GROUP_KEY = 'ORGANIZATIONS_GROUP_KEY';
const RISK_GROUP_KEY = 'RISK_GROUP_KEY';
const SIZE_GROUP_KEY = 'SIZE_GROUP_KEY';
const SEGMENT_GROUP_KEY = 'SEGMENT_GROUP_KEY';

interface SubGroupConfig<T> {
  mapping: T;
  isSelected: boolean;
}

interface Props {
  organizations: SubGroupConfig<string[]>;
  risks: SubGroupConfig<OrganizationsMetadata['risksToOrganizationsMap']>;
  sizes: SubGroupConfig<OrganizationsMetadata['sizesToOrganizationsMap']>;
  segments: SubGroupConfig<OrganizationsMetadata['segmentsToOrganizationsMap']>;
}

export default class OrganizationsMasterFilterHierarchyGroup extends HierarchicalMasterFilter {
  constructor(config: Props) {
    super(
      DefaultFilterLabels.ALL_ORGANIZATIONS,
      [
        new HierarchicalGroup(
          RISK_GROUP_KEY,
          config.risks.mapping,
          config.risks.isSelected,
        ),
        new HierarchicalGroup(
          SIZE_GROUP_KEY,
          config.sizes.mapping,
          config.sizes.isSelected,
        ),
        new HierarchicalGroup(
          SEGMENT_GROUP_KEY,
          config.segments.mapping,
          config.segments.isSelected,
        ),
        new HierarchicalGroup(
          ORGANIZATIONS_GROUP_KEY,
          config.organizations.mapping,
          config.organizations.isSelected,
        ),
      ],
      false,
    );
  }

  getOrganizationsGroup(): HierarchicalGroup {
    return this.getChild(ORGANIZATIONS_GROUP_KEY) as HierarchicalGroup;
  }

  getRisksGroup(): HierarchicalGroup {
    return this.getChild(RISK_GROUP_KEY) as HierarchicalGroup;
  }

  getSizesGroup(): HierarchicalGroup {
    return this.getChild(SIZE_GROUP_KEY) as HierarchicalGroup;
  }

  getSegmentsGroup(): HierarchicalGroup {
    return this.getChild(SEGMENT_GROUP_KEY) as HierarchicalGroup;
  }
}
