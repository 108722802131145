import classNames from 'classnames';
import React, { ReactNode } from 'react';

import ShadowedScrollableDiv from 'common-ui-components/ShadowedScrollableDiv';

import style from 'screens/platform/cross-platform-components/MetricsContainer/style.module.scss';

type Props = {
  children: ReactNode;
  horizontallyScrollable?: boolean;
};

export default function MetricsContainer({
  children,
  horizontallyScrollable = false,
}: Props) {
  return horizontallyScrollable ? (
    <ShadowedScrollableDiv
      scrollDirection="horizontal"
      className={classNames(style.metricsContainer, style.scrollable)}
    >
      {children}
    </ShadowedScrollableDiv>
  ) : (
    <div className={style.metricsContainer}>{children}</div>
  );
}
