import { useMemo } from 'react';

import ConfigurationKey from 'global/lists/ConfigurationKey';
import { useConfigurationContext } from 'screens/platform/cross-platform-components/context/configuration/ConfigurationContext';
import { ConfigType, defaultConfigValues } from 'screens/platform/cross-platform-components/context/configuration/ConfigurationContextProvider';
import DebuggerConsole from 'utils/DebuggerConsole';

export type ConfigTypeLabel = 'string' | 'boolean' | 'number';
type ConfigResponse<T extends ConfigType> = { value: T; loading: boolean };

export function useConfiguration(key: ConfigurationKey, type: 'string'): ConfigResponse<string>;
export function useConfiguration(key: ConfigurationKey, type: 'boolean'): ConfigResponse<boolean>;
export function useConfiguration(key: ConfigurationKey, type: 'number'): ConfigResponse<number>;
export function useConfiguration<T extends ConfigType>(
  key: ConfigurationKey,
  type: ConfigTypeLabel,
): ConfigResponse<T> {
  const { getConfiguration } = useConfigurationContext();

  return useMemo(() => {
    const nextValue = getConfiguration<T>(key);
    // eslint-disable-next-line valid-typeof
    if (nextValue !== undefined && typeof nextValue !== type) {
      DebuggerConsole.error(
        'Wrong configuration casting. Fall back to default value',
        { key, nextValue, type },
      );
      return {
        value: defaultConfigValues[key] as T,
        loading: false,
      };
    }
    return {
      value: nextValue !== undefined ? nextValue : (defaultConfigValues[key] as T),
      loading: nextValue === undefined,
    };
  }, [getConfiguration, key, type]);
}
