import classNames from 'classnames';
import React, { ReactNode } from 'react';

import Button from 'common-ui-components/Button';

import style from 'screens/platform/directory/components/ProfileTabs/components/Filters/FilterButton/style.module.scss';

interface Props {
  closeFilterDropdown: () => void;
  hasSelectedValues: boolean;
  isDropdownOpen: boolean;
  children: ReactNode;
  disabled?: boolean;
}

export default function FilterButton({
  closeFilterDropdown, hasSelectedValues, isDropdownOpen, children, disabled,
}: Props) {
  return (
    <Button
      onClick={closeFilterDropdown}
      rounded
      className={classNames(
        style.filterButton,
        hasSelectedValues && style.selected,
        isDropdownOpen && style.focused,
      )}
      disabled={disabled}
    >
      {children}
    </Button>
  );
}
