import React, { ReactNode, useMemo } from 'react';

import Spinner from 'common-ui-components/Spinner';
import SingularPluralNouns from 'global/lists/SingularPluralNouns';
import Size from 'global/lists/Size';
import { UNKNOWN_ORGANIZATION } from 'model/Tenant';
import NullableValuesSelection
  from 'screens/platform/cross-platform-components/context/MasterFiltersContext/NullableValuesSelection';
import OrganizationsMasterFilter
  from 'screens/platform/cross-platform-components/context/MasterFiltersContext/OrganizationsMasterFilter';
import {
  DefaultFilterLabels,
} from 'screens/platform/cross-platform-components/context/MasterFiltersContext/utils/DefaultMasterFiltersUtils';
import useOrganizationsMetadata
  from 'screens/platform/cross-platform-components/context/metadata/hooks/organizationsMetadataHook';
import {
  EMPTY_SELECTION_LABEL,
  getAmountsOnly,
} from 'screens/platform/cross-platform-components/MasterFiltersPanel/masterFiltersUtils/index';
import joinOneOrTwoElements, {
  hasOneOrTwoElements,
} from 'screens/platform/cross-platform-components/MasterFiltersPanel/masterFiltersUtils/MasterFiltersLabelsUtils';
import { getOrganizationName } from 'utils/OrganizationUtils';

function uncheckIncludeUnidentified(
  organizationsFilter: OrganizationsMasterFilter,
): OrganizationsMasterFilter {
  return new OrganizationsMasterFilter({
    ...organizationsFilter,
    organizationsIds: new NullableValuesSelection(
      organizationsFilter.organizationsIds.selected,
      false,
    ),
  });
}

export default function useOrganizationsLabel(
  organizationsFilter: OrganizationsMasterFilter,
): ReactNode {
  const neededOrganizationIds = useMemo<string[]>(() => {
    const selectedItems = organizationsFilter.organizationsIds.selected;
    return hasOneOrTwoElements(selectedItems) ? selectedItems : [];
  }, [organizationsFilter.organizationsIds]);
  const { value: organizations } = useOrganizationsMetadata(neededOrganizationIds);

  function getOrganizationLabel(id: string): string {
    const organization = organizations?.[id];
    return getOrganizationName(organization, id) || UNKNOWN_ORGANIZATION;
  }

  return useMemo(() => {
    if (organizationsFilter.isAllSelected()) return DefaultFilterLabels.ALL_ORGANIZATIONS;

    const organizationsFilterWithoutIncludeUnidentified = uncheckIncludeUnidentified(
      organizationsFilter,
    );
    if (organizationsFilterWithoutIncludeUnidentified.isEmpty()) return EMPTY_SELECTION_LABEL;

    const { organizationsIds: { selected: selectedOrganizationsIds } } = organizationsFilter;
    if (hasOneOrTwoElements(selectedOrganizationsIds)) {
      if (selectedOrganizationsIds?.length === 1) {
        const organizationLabel = getOrganizationLabel(selectedOrganizationsIds[0]);
        if (!organizationLabel) return <Spinner size={Size.SMALL} />;

        return joinOneOrTwoElements(
          selectedOrganizationsIds,
          SingularPluralNouns.organization,
          getOrganizationLabel,
        );
      }
    }
    if (selectedOrganizationsIds === null) {
      return DefaultFilterLabels.ALL_ORGANIZATIONS;
    }
    return getAmountsOnly([{
      amount: selectedOrganizationsIds.length,
      nouns: SingularPluralNouns.organization,
    }]);
  }, [organizationsFilter]);
}
