import React from 'react';

import Tooltip from 'common-ui-components/Tooltip';
import { getVolumeLabelByIndex } from 'global/lists/volumeLabels';

import style from 'screens/platform/cross-platform-components/Interactions/components/InteractionsTable/components/metricBadge.module.scss';

type Props = {
  volume: number;
  relativeVolume: number;
};

export default function VolumeBadge({ volume, relativeVolume }: Props) {
  return (
    <Tooltip content={volume.toLocaleString()}>
      <div className={style.metricBadge}>{getVolumeLabelByIndex(relativeVolume)}</div>
    </Tooltip>
  );
}
