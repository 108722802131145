import { useMemo } from 'react';

import { MasterFilters } from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFilters';
import { useMetadataContext } from 'screens/platform/cross-platform-components/context/metadata/MetadataContext';
import ArrayUtils from 'utils/ArrayUtils';

export function useSelectedPeopleFromMasterFilter(
  peopleMasterFilter: MasterFilters['people'],
  showDepartmentsOnly: boolean,
): string[] {
  const { persons: { allPeopleMetadata } } = useMetadataContext();
  return useMemo(
    () => {
      if (showDepartmentsOnly) return [];
      return peopleMasterFilter.internalPeopleIds ?? Object.keys(allPeopleMetadata);
    },
    [peopleMasterFilter, allPeopleMetadata, showDepartmentsOnly],
  );
}

export function useSelectedPeopleFromMasterFilterByDepartment(
  peopleMasterFilter: MasterFilters['people'],
  department: string,
): string[] {
  const {
    persons: { departmentsToPersonsMap },
  } = useMetadataContext();
  return useMemo(() => {
    const selectedPeopleIds = peopleMasterFilter.internalPeopleIds;
    const departmentPeople = departmentsToPersonsMap[department] ?? [];
    return selectedPeopleIds === null
      ? departmentPeople
      : ArrayUtils.intersection(selectedPeopleIds, departmentPeople);
  }, [peopleMasterFilter, department, departmentsToPersonsMap]);
}
