import classNames from 'classnames';
import React from 'react';

import Button from 'es-src/components/ui/Button';

import style from 'common-ui-components/Sources/ShowAllButton/style.module.scss';

interface Props {
  onClick: () => void;
  arrowDirection: 'up' | 'down';
}

export default function ShowAllButton({ onClick, arrowDirection }: Props) {
  return (
    <Button onClick={onClick} className={style.showAll}>
      Show
      {' '}
      {arrowDirection === 'up' ? 'less' : 'all'}
      {' '}
      <span className={classNames(style.arrowIcon, arrowDirection === 'up' && style.flipped)}>↓</span>
    </Button>
  );
}
