import React, { useState } from 'react';

import Spinner from 'common-ui-components/Spinner';
import { GroupedOrganizations } from 'global/api/controller/OrganizationController';
import OrganizationsMasterFilterHierarchyGroup
  from 'screens/platform/cross-platform-components/context/MasterFiltersContext/OrganizationsMasterFilter/OrganizationsMasterFilterHierarchyGroup';
import useHierarchicalSearchInput
  from 'screens/platform/cross-platform-components/HierarchicalSearchInput/HierarchicalSearchInputHook';
import HierarchicalSearchInputWrapper
  from 'screens/platform/cross-platform-components/HierarchicalSearchInput/HierarchicalSearchInputWrapper';
import ColumnsHierarchicalSearchInputWrapper
  from 'screens/platform/cross-platform-components/MasterFiltersPanel/FilterSection/ColumnsHierarchicalSearchInputWrapper/index';
import FilterSectionUtils
  from 'screens/platform/cross-platform-components/MasterFiltersPanel/FilterSection/FilterSectionUtils';
import OrganizationsHierarchicalSearchResults
  from 'screens/platform/cross-platform-components/MasterFiltersPanel/FilterSection/OrganizationsFilterSection/components/OrganizationsHierarchicalSearchResults/index';
import useOrganizationsQuery
  from 'screens/platform/cross-platform-components/MasterFiltersPanel/FilterSection/OrganizationsFilterSection/components/OrganizationsQueryHook';

interface Props {
  selection: OrganizationsMasterFilterHierarchyGroup | null;
  onClickDone: () => void;
  searchPlaceholder?: string;
  className?: string;
  shouldClearSearchInput?: boolean;
  allowEmptySelection?: boolean;
}

export default function OrganizationsHierarchicalSearchInput({
  selection,
  searchPlaceholder,
  onClickDone,
  className,
  shouldClearSearchInput,
  allowEmptySelection = false,
}: Props) {
  const [searchQuery, setSearchQuery] = useState('');
  const [itemsByGroups, setItemsByGroups] = useState<GroupedOrganizations | null>(null);

  const {
    isDisabled, emptySelectionHandler, searchInputRef,
  } = useHierarchicalSearchInput({
    isSelectionEmpty: selection?.isNotSelected() ?? false,
    allowEmptySelection,
    shouldClearSearchInput,
    setSearchQuery,
  });

  useOrganizationsQuery(searchQuery, setItemsByGroups);

  const isLoading = itemsByGroups === null || selection === null;

  const shouldUseColumnsView = FilterSectionUtils.useShouldShowFilterColumnsView(
    selection,
    selection?.getOrganizationsGroup(),
  );

  const WrapperComponent = shouldUseColumnsView
    ? ColumnsHierarchicalSearchInputWrapper
    : HierarchicalSearchInputWrapper;

  return (
    <WrapperComponent
      onClickDone={onClickDone}
      setSearchQuery={setSearchQuery}
      searchPlaceholder={searchPlaceholder}
      disabled={isDisabled}
      className={className}
      ref={searchInputRef}
    >
      {isLoading ? <Spinner /> : (
        <OrganizationsHierarchicalSearchResults
          visibleItems={itemsByGroups}
          selection={selection}
          handleEmptySelectionChange={emptySelectionHandler}
          shouldUseColumnsView={shouldUseColumnsView}
        />
      )}
    </WrapperComponent>
  );
}
