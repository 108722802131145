/* eslint-disable no-case-declarations */
import { ActiveMetrics } from 'global/api/controller/MetricController';
import { MinimalTag } from 'global/api/controller/TagsSetController';
import App from 'global/lists/apps';
import Metric from 'global/lists/Metric';
import PersonMetadata from 'screens/platform/cross-platform-components/context/metadata/dataStructures/PersonMetadata';
import ReducersHelper from 'screens/platform/cross-platform-components/context/ReducersHelper';

export interface MetadataReducerStateType {
  connectedApps: Partial<Record<App, string>>;
  categories: Record<string, string[]>;
  tagsData: Record<string, Pick<MinimalTag, 'isTopic' | 'categories'>>;
  categoriesColors: Record<string, string>;
  categoryColorsOrdinals: Record<string, number>;
  metrics: ActiveMetrics;
  persons: {
    allPeopleMetadata: Readonly<Partial<Record<string, PersonMetadata>>>;
    departmentsToPersonsMap: Readonly<Partial<Record<string, string[]>>>;
    teamsToPeopleMap: Readonly<Partial<Record<string, string[]>>>;
  };
  appsToChannelsMap: Readonly<Partial<Record<string, string[]>>>;
}

export type MetadataReducerAction =
| { type: 'INITIALIZE'; payload: Partial<MetadataReducerStateType> }
| { type: 'TOGGLE_METRIC'; payload: { metric: Metric } }

const MetadataReducer = (state: MetadataReducerStateType, action: MetadataReducerAction) => {
  switch (action.type) {
    case 'INITIALIZE': return ReducersHelper.initialize(state, action.payload);
    case 'TOGGLE_METRIC':
      const { metric } = action.payload;
      const stateMetrics = state.metrics;
      const isCurrentlyEnabled = stateMetrics.includes(metric);
      const updatedMetrics = isCurrentlyEnabled
        ? stateMetrics.filter((stateMetric) => stateMetric !== metric)
        : [...stateMetrics, metric];

      return { ...state, metrics: updatedMetrics };
    default:
      return state;
  }
};

export default MetadataReducer;
