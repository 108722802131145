import React, { ReactNode, useEffect, useState } from 'react';

import Button from 'common-ui-components/Button';
import Caret from 'common-ui-components/Caret';
import Checkbox from 'common-ui-components/Checkbox';
import HierarchicalGroup from 'utils/HierarchicalDataStructures/HierarchicalGroup';
import { SelectionStatus } from 'utils/HierarchicalDataStructures/HierarchicalGroupInterface';

import style from 'screens/platform/cross-platform-components/HierarchicalSearchInput/style.module.scss';

interface Props {
  isOpen: boolean;
  toggleIsOpen: () => void;
  selection: HierarchicalGroup;
  label: ReactNode;
  showAmount?: boolean;
  amountParser?: (amount: number) => string;
  onToggle?: (id: string | null) => void;
  disabled?: boolean;
}

function getSelectionState(selection: HierarchicalGroup): SelectionStatus {
  if (selection.isSelected) return SelectionStatus.SELECTED;
  if (selection.isPartiallySelected) return SelectionStatus.PARTIALLY_SELECTED;
  return SelectionStatus.UNSELECTED;
}

export default function RootGroupTitleRow({
  isOpen,
  toggleIsOpen,
  selection,
  label,
  showAmount = false,
  amountParser,
  onToggle,
  disabled,
}: Props) {
  const [selectionState, setSelectionState] = useState(getSelectionState(selection));
  const [selectedItemsCount, setSelectedItemsCount] = useState(selection.status.selected);

  useEffect(() => {
    function updateSelectedItemsAmount(_, __) {
      setSelectionState(getSelectionState(selection));
      setSelectedItemsCount(selection.status.selected);
    }
    const subscriptionId = selection.subscribe(updateSelectedItemsAmount);
    return () => selection.unsubscribe(subscriptionId);
  }, [selection]);

  return (
    <div className={style.groupTitle}>
      <div>
        <Checkbox
          checked={selectionState === SelectionStatus.SELECTED}
          partiallyChecked={selectionState === SelectionStatus.PARTIALLY_SELECTED}
          onClick={() => (onToggle
            ? onToggle(null)
            : selection.toggle())}
          disabled={disabled}
        />
      </div>
      <Button className={style.titleLeft} onClick={toggleIsOpen}>
        <span className={style.groupLabel}>
          {label}
          {showAmount && (
            <span className={style.groupSize}>
              {`(${amountParser ? amountParser(selectedItemsCount) : selectedItemsCount})`}
            </span>
          )}
        </span>
        <Caret isOpen={isOpen} />
      </Button>
    </div>
  );
}
