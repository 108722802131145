import React, { useMemo } from 'react';

import { PersonMetricSortingConfig, SortablePersonMetrics } from 'global/api/controller/utils/PersonControllerUtils';
import ConfigurationKey from 'global/lists/ConfigurationKey';
import Metric, { getMetricDisplayName } from 'global/lists/Metric';
import { useConfiguration } from 'screens/platform/cross-platform-components/context/configuration/ConfigHook';
import { useMetadataContext } from 'screens/platform/cross-platform-components/context/metadata/MetadataContext';
import PersonMetricValue from 'screens/platform/directory/people/PeopleDirectoryScreen/components/PersonMetricValue';
import { PeopleDirectoryTableColumn, PeopleDirectoryTableColumnId } from 'screens/platform/directory/people/PeopleDirectoryScreen/PeopleTable/utils/PeopleDirectoryTableColumn';
import DebuggerConsole from 'utils/DebuggerConsole';

export type PeopleMetricColumnId =
  | PeopleDirectoryTableColumnId.LAST_INTERACTION
  | PeopleDirectoryTableColumnId.TIME_IN_MEETINGS_LAST_7_DAYS
  | PeopleDirectoryTableColumnId.TIME_IN_MEETINGS_LAST_28_DAYS
  | PeopleDirectoryTableColumnId.TIME_IN_MEETINGS_LAST_3_MONTHS
  | PeopleDirectoryTableColumnId.TIME_IN_MEETINGS_LAST_6_MONTHS
  | PeopleDirectoryTableColumnId.TIME_IN_MEETINGS_LAST_9_MONTHS
  | PeopleDirectoryTableColumnId.TIME_IN_MEETINGS_LAST_12_MONTHS;

const isMetricTimeInMeetingsForLongPeriods = (metric: Metric) =>
  [
    Metric.PERSON_TIME_IN_MEETINGS_LAST_28_DAYS,
    Metric.PERSON_TIME_IN_MEETINGS_LAST_3_MONTHS,
    Metric.PERSON_TIME_IN_MEETINGS_LAST_6_MONTHS,
    Metric.PERSON_TIME_IN_MEETINGS_LAST_9_MONTHS,
    Metric.PERSON_TIME_IN_MEETINGS_LAST_12_MONTHS,
  ].includes(metric);

export const peopleDirectoryColumnIdToMetric: Record<
    PeopleMetricColumnId,
    SortablePersonMetrics
  > = Object.freeze({
    [PeopleDirectoryTableColumnId.LAST_INTERACTION]:
      Metric.PERSON_LAST_INTERACTION,
    [PeopleDirectoryTableColumnId.TIME_IN_MEETINGS_LAST_7_DAYS]:
      Metric.PERSON_TIME_IN_MEETINGS_LAST_7_DAYS,
    [PeopleDirectoryTableColumnId.TIME_IN_MEETINGS_LAST_28_DAYS]:
      Metric.PERSON_TIME_IN_MEETINGS_LAST_28_DAYS,
    [PeopleDirectoryTableColumnId.TIME_IN_MEETINGS_LAST_3_MONTHS]:
      Metric.PERSON_TIME_IN_MEETINGS_LAST_3_MONTHS,
    [PeopleDirectoryTableColumnId.TIME_IN_MEETINGS_LAST_6_MONTHS]:
      Metric.PERSON_TIME_IN_MEETINGS_LAST_6_MONTHS,
    [PeopleDirectoryTableColumnId.TIME_IN_MEETINGS_LAST_9_MONTHS]:
      Metric.PERSON_TIME_IN_MEETINGS_LAST_9_MONTHS,
    [PeopleDirectoryTableColumnId.TIME_IN_MEETINGS_LAST_12_MONTHS]:
      Metric.PERSON_TIME_IN_MEETINGS_LAST_12_MONTHS,
  });

function getMetricColumnConfig(columnId: PeopleMetricColumnId):
  PeopleDirectoryTableColumn<PersonMetricSortingConfig> {
  const metric = peopleDirectoryColumnIdToMetric[columnId];
  return {
    title: getMetricDisplayName(metric),
    key: columnId,
    render: (_, { metrics }) => (
      <PersonMetricValue metricValue={metrics[metric]} metric={metric} />
    ),
    customSortingConfig: { metricType: metric },
  };
}

const peopleMetricColumns: Record<
  PeopleMetricColumnId,
  PeopleDirectoryTableColumn<PersonMetricSortingConfig>
> = Object.freeze({
  [PeopleDirectoryTableColumnId.LAST_INTERACTION]:
    getMetricColumnConfig(PeopleDirectoryTableColumnId.LAST_INTERACTION),
  [PeopleDirectoryTableColumnId.TIME_IN_MEETINGS_LAST_7_DAYS]:
    getMetricColumnConfig(PeopleDirectoryTableColumnId.TIME_IN_MEETINGS_LAST_7_DAYS),
  [PeopleDirectoryTableColumnId.TIME_IN_MEETINGS_LAST_28_DAYS]:
    getMetricColumnConfig(PeopleDirectoryTableColumnId.TIME_IN_MEETINGS_LAST_28_DAYS),
  [PeopleDirectoryTableColumnId.TIME_IN_MEETINGS_LAST_3_MONTHS]:
    getMetricColumnConfig(PeopleDirectoryTableColumnId.TIME_IN_MEETINGS_LAST_3_MONTHS),
  [PeopleDirectoryTableColumnId.TIME_IN_MEETINGS_LAST_6_MONTHS]:
    getMetricColumnConfig(PeopleDirectoryTableColumnId.TIME_IN_MEETINGS_LAST_6_MONTHS),
  [PeopleDirectoryTableColumnId.TIME_IN_MEETINGS_LAST_9_MONTHS]:
    getMetricColumnConfig(PeopleDirectoryTableColumnId.TIME_IN_MEETINGS_LAST_9_MONTHS),
  [PeopleDirectoryTableColumnId.TIME_IN_MEETINGS_LAST_12_MONTHS]:
    getMetricColumnConfig(PeopleDirectoryTableColumnId.TIME_IN_MEETINGS_LAST_12_MONTHS),
});

export default function usePeopleDirectoryMetricColumns(): PeopleDirectoryTableColumn[] {
  const { isMetricEnabled } = useMetadataContext();
  const { value: isTimeInMeetingsForLongPeriodsMetricsEnabled } = useConfiguration(
    ConfigurationKey.IS_TIME_IN_MEETINGS_FOR_LONG_PERIODS_METRICS_ENABLED,
    'boolean',
  );

  return useMemo(
    () =>
      Object.values(peopleMetricColumns).filter(
        ({ key, customSortingConfig }) => {
          const metric = customSortingConfig?.metricType;
          if (!metric) {
            DebuggerConsole.error('Metric column without metricType', key);
            return false;
          }
          if (!isMetricEnabled(metric)) {
            return false;
          }
          return (
            !isMetricTimeInMeetingsForLongPeriods(metric)
            || isTimeInMeetingsForLongPeriodsMetricsEnabled
          );
        },
      ),
    [isTimeInMeetingsForLongPeriodsMetricsEnabled, isMetricEnabled],
  );
}
