import SessionStorageUtils, {
  SessionStorageKey,
} from 'utils/SessionStorageUtils';

export default class FirebaseRedirectionIndicator {
  static wasRedirectedFromFirebase(): boolean {
    return SessionStorageUtils.getItemWithDefaultValue(SessionStorageKey.IS_FIRST_LOGIN, false);
  }

  static updateLoginAttempt() {
    SessionStorageUtils.setItem(SessionStorageKey.IS_FIRST_LOGIN, true);
  }

  static reset() {
    SessionStorageUtils.setItem(SessionStorageKey.IS_FIRST_LOGIN, false);
  }
}
