import { useCallback } from 'react';

import NullableValuesSelection from 'screens/platform/cross-platform-components/context/MasterFiltersContext/NullableValuesSelection';
import PeopleMasterFilter
  from 'screens/platform/cross-platform-components/context/MasterFiltersContext/PeopleMasterFilter/index';
import {
  useDefaultMasterFilters,
} from 'screens/platform/cross-platform-components/context/MasterFiltersContext/utils/DefaultMasterFiltersUtils';
import {
  MetadataContextType,
  useMetadataContext,
} from 'screens/platform/cross-platform-components/context/metadata/MetadataContext';
import ArrayUtils from 'utils/ArrayUtils';

function getSelectedTeams(
  selectedPeopleIds: string[],
  allPeopleMetadata: MetadataContextType['persons']['allPeopleMetadata'],
): NullableValuesSelection {
  const selectedTeamsIds = selectedPeopleIds
    .flatMap((personId) => {
      const personMetadata = allPeopleMetadata[personId];
      if (!personMetadata) return [];
      return personMetadata.getTeams() ?? [null];
    }).filter(ArrayUtils.isUnique);

  const isUndefinedTeamSelected = selectedTeamsIds.includes(null);

  return new NullableValuesSelection(
    selectedTeamsIds.filter(ArrayUtils.isDefined),
    isUndefinedTeamSelected,
  );
}

export default function usePeopleMasterFilterInitializers() {
  const {
    persons: {
      departmentsToPersonsMap,
      allPeopleMetadata,
    },
  } = useMetadataContext();
  const { people: { includeUnidentified: defaultIncludeUnidentified } } = useDefaultMasterFilters();

  const fromPeopleIds = useCallback((
    selectedPeopleIds: string[],
    includeUnidentified: boolean = defaultIncludeUnidentified,
  ): PeopleMasterFilter => {
    const selectedInternalPeopleIds = selectedPeopleIds
      .filter((personId) => Boolean(allPeopleMetadata[personId]));

    const selectedExternalPeopleIds = selectedPeopleIds
      .filter((personId) => !allPeopleMetadata[personId]);

    const selectedDepartments = selectedInternalPeopleIds
      .map((personId) => allPeopleMetadata[personId]?.department)
      .filter(ArrayUtils.isDefined)
      .filter(ArrayUtils.isUnique);

    const selectedTeams = getSelectedTeams(selectedInternalPeopleIds, allPeopleMetadata);

    return new PeopleMasterFilter(
      selectedDepartments,
      selectedTeams,
      selectedInternalPeopleIds,
      includeUnidentified,
      selectedExternalPeopleIds,
    );
  }, [allPeopleMetadata]);

  const fromDepartments = useCallback((
    selectedDepartments: string[] | null,
    includeUnidentified: boolean = defaultIncludeUnidentified,
  ): PeopleMasterFilter => {
    if (selectedDepartments === null) {
      return PeopleMasterFilter.generateAllInternalSelected(includeUnidentified);
    }

    const selectedPeopleIds = Object.entries(departmentsToPersonsMap)
      .filter(([department]) => selectedDepartments.includes(department))
      .flatMap(([, members]) => members!);

    const selectedTeams = getSelectedTeams(selectedPeopleIds, allPeopleMetadata);

    return new PeopleMasterFilter(
      selectedDepartments,
      selectedTeams,
      selectedPeopleIds,
      includeUnidentified,
    );
  }, [departmentsToPersonsMap, allPeopleMetadata]);

  interface Selection {
    selectedPeopleIds: string[] | null;
    selectedDepartments: string[] | null;
  }

  /**
   * The selected people don't have to belong to the selected departments
   */
  const fromPeopleIdsAndDepartments = useCallback((
    { selectedPeopleIds, selectedDepartments }: Selection,
    includeUnidentified: boolean = defaultIncludeUnidentified,
  ): PeopleMasterFilter => {
    if (selectedDepartments === null || selectedPeopleIds === null) {
      return PeopleMasterFilter.generateAllInternalSelected(includeUnidentified);
    }

    const uniquePeopleIds = selectedPeopleIds.filter(ArrayUtils.isUnique);
    const peopleIdsFromDepartments = selectedDepartments
      .flatMap((department) => departmentsToPersonsMap[department] ?? []);
    const peopleIds = [...uniquePeopleIds, ...peopleIdsFromDepartments];

    return fromPeopleIds(peopleIds, includeUnidentified);
  }, [fromPeopleIds, departmentsToPersonsMap]);

  return {
    fromDepartments,
    fromPeopleIds,
    fromPeopleIdsAndDepartments,
  };
}
