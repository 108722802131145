import React, { Dispatch, SetStateAction } from 'react';

import Pagination from 'common-ui-components/Pagination';

import style from 'screens/platform/cross-platform-components/InteractionsPagination/style.module.scss';

interface Props {
  atomsCount: number;
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
}

export default function InteractionsPagination(
  { atomsCount, currentPage, setCurrentPage }: Props,
) {
  return (
    <Pagination
      defaultCurrent={1}
      current={currentPage}
      total={atomsCount}
      onChange={(page) => setCurrentPage(page)}
      showSizeChanger={false}
      hasNavigationButtons
      hideOnSinglePage
      className={style.interactionsPagination}
      role="navigation"
      aria-label="pagination"
    />
  );
}
