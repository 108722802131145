import React from 'react';

import OrganizationBadge from 'common-ui-components/Badge/OrganizationBadge';
import OrganizationEvent from 'model/Events/OrganizationEvent';
import DateUtils from 'utils/DateUtils';

import style from 'screens/platform/cross-platform-components/EventsPanel/EventCell/EventTitle/style.module.scss';

interface Props {
  event: OrganizationEvent;
}

export default function LastEngagementTitle({ event }: Props) {
  const { organizationId, endDate, startDate } = event;

  const daysAgo = DateUtils.getDaysAgo(endDate || startDate);
  return (
    <div className={style.title}>
      {`It's been ${daysAgo} days since`}
      <OrganizationBadge organizationId={organizationId} />
      was engaged
    </div>
  );
}
