import classNames from 'classnames';
import React from 'react';

import Button from 'common-ui-components/Button';
import LoadingDots from 'common-ui-components/LoadingDots';
import { useEnterpriseSearchContext } from 'es-src/screens/HomeScreen/EnterpriseSearchContext/EnterpriseSearchContext';
import { QuestionSource } from 'global/api/controller/EnterpriseSearchController';

import style from 'es-src/screens/HomeScreen/components/SuggestedQuestions/SuggestedQuestion/style.module.scss';

interface Props {
  question: string;
  displayQuestion?: string;
  questionSource: QuestionSource;
  color: 'white' | 'blue';
}

export default function SuggestedQuestion({
  question,
  displayQuestion,
  questionSource,
  color,
}: Props) {
  const { handleEnterpriseSearch } = useEnterpriseSearchContext();
  return (
    <Button
      className={classNames(
        style.suggestedQuestion,
        color === 'blue' && style.blue,
      )}
      onClick={() => handleEnterpriseSearch(question, questionSource, true)}
    >
      {displayQuestion ?? question}
    </Button>
  );
}

export function SuggestedQuestionSkeleton({ color }: Pick<Props, 'color'>) {
  return (
    <div
      className={classNames(
        style.suggestedQuestion,
        color === 'blue' && style.blue,
      )}
    >
      <LoadingDots />
    </div>
  );
}
