import React, {
  CSSProperties, ReactNode, useEffect,
} from 'react';

import { useMountedState } from 'utils/hooks';

const SECONDS_TO_DISAPPEAR = 0.5;

interface FadeOutWrapperProps {
  isLoading: boolean;
  children: ReactNode;
  allScreen?: boolean;
  alignToTop?: boolean;
  transparent?: boolean;
}

export default function FadeOutWrapper({
  isLoading, children, allScreen = false, alignToTop = false, transparent = false,
}: FadeOutWrapperProps) {
  const [isVisible, setVisible] = useMountedState(true);

  useEffect(() => {
    setTimeout(() => setVisible(isLoading), SECONDS_TO_DISAPPEAR * 1000);
  }, [isLoading]);

  const allScreenStyle: CSSProperties = {
    width: '100%',
    height: '100%',
    backgroundColor: transparent ? 'transparent' : 'white',
    zIndex: 2,
    cursor: 'wait',
  };

  return (
    <div style={{
      position: allScreen ? 'absolute' : 'relative',
      display: isVisible ? 'block' : 'none',
      opacity: isLoading ? '1' : '0',
      transition: `opacity ${SECONDS_TO_DISAPPEAR}s ease`,
      ...(allScreen ? allScreenStyle : {}),
    }}
    >
      <div
        style={{
          position: 'absolute',
          left: '50%',
          ...(
            alignToTop
              ? { top: '0', transform: 'translateX(-50%)' }
              : { top: '50%', transform: 'translateX(-50%) translateY(-50%)' }
          ),
        }}
      >
        { children }
      </div>
    </div>
  );
}
