import { useHistory } from 'react-router-dom';

import Role from 'global/lists/Role';
import { useUserContext } from 'screens/platform/cross-platform-components/context/user/UserContext';
import DebuggerConsole from 'utils/DebuggerConsole';

/**
 * This is the SINGLE source of truth for the applications' URL and minimum roles
 */
const ScreensConfiguration = {
  feed: {
    path: '',
    minimumRole: Role.USER,
  },
  platform: {
    path: 'platform',
    minimumRole: Role.USER,
    commChart: {
      path: 'commchart',
      minimumRole: Role.ANALYST,
    },
    explore: {
      path: 'explore',
      minimumRole: Role.ANALYST,
    },
    analytics: {
      path: 'analytics',
      minimumRole: Role.ANALYST,
    },
    interactions: {
      path: 'interactions',
      minimumRole: Role.USER,
    },
    wizard: {
      path: 'wizard',
      minimumRole: Role.ANALYST,
    },
    reports: {
      path: 'reports',
      minimumRole: Role.ANALYST,
    },
    directory: {
      path: 'directory',
      minimumRole: Role.USER,
      organizations: {
        path: 'organizations',
        minimumRole: Role.USER,
        profile: {
          path: (organizationId: string) => organizationId,
          minimumRole: Role.USER,
        },
      },
      people: {
        path: 'people',
        minimumRole: Role.USER,
        profile: {
          path: (personId: string) => personId,
          minimumRole: Role.USER,
        },
      },
      topics: {
        path: 'topics',
        minimumRole: Role.USER,
        profile: {
          path: (topic: string) => topic,
          minimumRole: Role.USER,
        },
      },
    },
  },
  settings: {
    path: 'settings',
    minimumRole: Role.USER,
    library: {
      metrics: {
        path: 'metrics',
        minimumRole: Role.ADMIN,
      },
      events: {
        path: 'events',
        minimumRole: Role.ADMIN,
      },
      objectives: {
        path: 'objectives',
        minimumRole: Role.ADMIN,
      },
      reports: {
        path: 'reports',
        minimumRole: Role.ADMIN,
      },
    },
    account: {
      people: {
        path: 'people',
        minimumRole: Role.ADMIN,
      },
      tags: {
        path: 'tags',
        minimumRole: Role.ADMIN,
      },
      apps: {
        path: 'apps',
        minimumRole: Role.INTEGRATIONADMIN,
      },
      organizations: {
        path: 'organizations',
        minimumRole: Role.ADMIN,
      },
    },
    user: {
      slackAlerts: {
        path: 'slack-alerts',
        minimumRole: Role.USER,
      },
      follow: {
        path: 'follow',
        minimumRole: Role.USER,
      },
      slackBot: {
        path: 'slack-bot',
        minimumRole: Role.USER,
      },
      extension: {
        path: 'extension',
        minimumRole: Role.USER,
      },
    },
  },
  backoffice: {
    path: 'backoffice',
    minimumRole: Role.SUPERADMIN,
    tenant: {
      path: 'tenant',
      minimumRole: Role.SUPERADMIN,
    },
    configuration: {
      path: 'configuration',
      minimumRole: Role.SUPERADMIN,
    },
    ods: {
      path: 'ods',
      minimumRole: Role.SUPERADMIN,
    },
    'offline-scans': {
      path: 'offline-scans',
      minimumRole: Role.SUPERADMIN,
    },
    'suggested-tags': {
      path: 'suggested-tags',
      minimumRole: Role.SUPERADMIN,
    },
    nudges: {
      path: 'nudges',
      minimumRole: Role.SUPERADMIN,
    },
    'manual-emails-generation': {
      path: 'manual-emails-generation',
      minimumRole: Role.SUPERADMIN,
    },
    'akooda-gpt': {
      path: 'akooda-gpt',
      minimumRole: Role.SUPERADMIN,
    },
    'gpt-sessions': {
      path: 'gpt-sessions',
      minimumRole: Role.SUPERADMIN,
    },
    'email-app': {
      path: 'email-app',
      minimumRole: Role.SUPERADMIN,
    },
  },
} as const;

type SpecificScreenConfig = { minimumRole: Role; path: string | ((param: string) => string) }

/**
 * This monstrous type defines the string values of an object,
 * where each key is concatenated to its nested object's keys by a dot.
 * Basically it allows strings of existing nested keys in an object.
 */
type NestedKeyOf<ObjectType extends object> = {
  [Key in keyof ObjectType & (string | number)]:
    ObjectType[Key] extends SpecificScreenConfig
      ? `${Key}` | `${Key}.${NestedKeyOf<ObjectType[Key]>}`
      : (ObjectType[Key] extends object
        ? `${Key}.${NestedKeyOf<ObjectType[Key]>}`
        : never)
}[keyof ObjectType & (string | number)];

function isSpecificScreenConfig(val: any): val is SpecificScreenConfig {
  if (typeof val?.minimumRole !== 'string') {
    return false;
  }
  return typeof val?.path === 'string' || typeof val?.path === 'function';
}

const DEFAULT_SCREEN_CONFIG = {
  ...ScreensConfiguration.feed,
  accumulatedPath: '',
};

function getScreenConfigRecursivelyByNestedKey(
  screenKeyHierarchy: string[],
  parent: object = ScreensConfiguration,
  accumulatedPath: string = '',
): SpecificScreenConfig & { accumulatedPath: string } {
  if (screenKeyHierarchy.length === 0) {
    if (isSpecificScreenConfig(parent)) {
      return {
        ...parent,
        accumulatedPath,
      };
    }
    DebuggerConsole.error('Invalid screen key structure: not SpecificScreenConfig', { parent, screenKeyHierarchy });
    return DEFAULT_SCREEN_CONFIG;
  }

  const [nextKey, ...restScreenKeyHierarchy] = screenKeyHierarchy;
  if (!(nextKey in parent)) {
    DebuggerConsole.error('Invalid screen key structure: key does not exist', { parent, screenKeyHierarchy });
    return DEFAULT_SCREEN_CONFIG;
  }

  const nextAccumulatedPath = ('path' in parent) ? `${accumulatedPath}/${parent.path}` : accumulatedPath;
  return getScreenConfigRecursivelyByNestedKey(
    restScreenKeyHierarchy,
    parent[nextKey],
    nextAccumulatedPath,
  );
}

type ScreenKeyFunction<T> = <LocationState>(
  screenKey: ScreenKey,
  urlParam?: string,
  state?: LocationState,
) => T;

export type ScreenKey = NestedKeyOf<typeof ScreensConfiguration>;

export function useInternalScreenRedirect(openNewTab = false): ScreenKeyFunction<void> {
  const history = useHistory();
  const { isUserAllowed } = useUserContext();

  return <LocationState>(screenKey: ScreenKey, urlParam?: string, state?: LocationState) => {
    const screenConfig = getScreenConfig(screenKey, urlParam);
    if (isUserAllowed(screenConfig.minimumRole)) {
      if (openNewTab) {
        window.open(screenConfig.path, '_blank');
      } else {
        history.push(screenConfig.path, state);
      }
    } else {
      DebuggerConsole.error("User tried to access a page they don't have access to", screenKey);
    }
  };
}

export const getScreenPath: ScreenKeyFunction<string> = (
  screenKey,
  urlParam,
) => getScreenConfig(screenKey, urlParam).path;

const getScreenConfig: ScreenKeyFunction<{
  path: string;
  minimumRole: Role;
}> = (screenKey, urlParam) => {
  const {
    path, accumulatedPath, minimumRole,
  } = getScreenConfigRecursivelyByNestedKey(screenKey.split('.'));

  if (typeof path === 'string') {
    return {
      path: `${accumulatedPath}/${path}`,
      minimumRole,
    };
  }
  if (urlParam === undefined) {
    return {
      path: accumulatedPath,
      minimumRole,
    };
  }
  return {
    path: `${accumulatedPath}/${path(urlParam)}`,
    minimumRole,
  };
};

export default getScreenConfig;
