import { useCallback } from 'react';

import { OrganizationRisk, OrganizationSize } from 'model/Organization';
import { MasterFilters } from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFilters';
import NullableValuesSelection
  from 'screens/platform/cross-platform-components/context/MasterFiltersContext/NullableValuesSelection';
import OrganizationsMasterFilter
  from 'screens/platform/cross-platform-components/context/MasterFiltersContext/OrganizationsMasterFilter';
import OrganizationsMasterFilterHierarchyGroup
  from 'screens/platform/cross-platform-components/context/MasterFiltersContext/OrganizationsMasterFilter/OrganizationsMasterFilterHierarchyGroup';
import FilterSectionUtils
  from 'screens/platform/cross-platform-components/MasterFiltersPanel/FilterSection/FilterSectionUtils';

const { convertToNullableValuesSelection } = FilterSectionUtils;

export default function useOrganizationsSelectionUpdate(
  currentOrganizationsMasterFilter: Readonly<MasterFilters['organizations']>,
): (organizationsFilterAsGroup: OrganizationsMasterFilterHierarchyGroup)
  => OrganizationsMasterFilter {
  return useCallback((organizationsFilterAsGroup) => {
    const selectionMap = organizationsFilterAsGroup.getSelectionMap({
      includeEmptyGroupsKeys: true,
    });

    if (selectionMap === null) {
      return new OrganizationsMasterFilter({
        ...OrganizationsMasterFilter.generateAllSelected(),
        organizationsIds: new NullableValuesSelection(
          null,
          currentOrganizationsMasterFilter.organizationsIds.includeUndefined,
        ),
      });
    }

    const organizationsGroup = organizationsFilterAsGroup.getOrganizationsGroup();
    const selectedOrganizationsIds = selectionMap[organizationsGroup.id] ?? [];
    return new OrganizationsMasterFilter({
      ...currentOrganizationsMasterFilter,
      organizationsIds: new NullableValuesSelection(
        organizationsGroup.isSelected ? null : selectedOrganizationsIds,
        currentOrganizationsMasterFilter.organizationsIds.includeUndefined,
      ),
      selectedRisks: convertToNullableValuesSelection<OrganizationRisk>(
        organizationsFilterAsGroup.getRisksGroup(),
      ),
      selectedSizes: convertToNullableValuesSelection<OrganizationSize>(
        organizationsFilterAsGroup.getSizesGroup(),
      ),
      selectedSegments: convertToNullableValuesSelection<string>(
        organizationsFilterAsGroup.getSegmentsGroup(),
      ),
    });
  }, [currentOrganizationsMasterFilter]);
}
