import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import AccountRouter, { ACCOUNT_PATH_PREFIX } from 'routers/AccountRouter';
import LoggedInApp from 'routers/LoggedInApp';
import { useUserContext } from 'screens/platform/cross-platform-components/context/user/UserContext';
import LocalStorageUtils, { StorageKeyOriginalPath } from 'utils/LocalStorageUtils';

const EMPTY_URL_PATH = '/';

export default function AppRouter() {
  const { pathname, search } = useLocation();
  const { isLoggedIn } = useUserContext();

  useEffect(() => {
    if (isLoggedIn !== null) {
      const shouldSaveOriginalPath = isLoggedIn
        || !(pathname.toLowerCase().startsWith(ACCOUNT_PATH_PREFIX) || pathname === EMPTY_URL_PATH);

      if (!shouldSaveOriginalPath) {
        LocalStorageUtils.removeItem(StorageKeyOriginalPath.ORIGINAL_PATH);
      } else if (!isLoggedIn) {
        LocalStorageUtils.setItem(StorageKeyOriginalPath.ORIGINAL_PATH, `${pathname}${search}`);
      }
    }
  }, [isLoggedIn]);

  if (isLoggedIn === null) {
    return null;
  }

  return isLoggedIn
    ? <LoggedInApp />
    : <AccountRouter />;
}
