import React, { ReactNode } from 'react';

import style from 'screens/platform/cross-platform-components/BadgesList/style.module.scss';

interface Props {
  children: ReactNode;
}

export default function BadgeItem({ children }: Props) {
  return children !== null ? (
    <div className={style.badge}>
      {children}
    </div>
  ) : null;
}
