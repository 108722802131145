import EnvironmentConfig from 'global/environments/environment';

const devConfig: EnvironmentConfig = {
  firebaseConfig: {
    apiKey: 'AIzaSyAUd0IYgP75BdIRIMVwLFL9mAH0uvPmCQ4',
    authDomain: 'akooda-dev-307211.firebaseapp.com',
    databaseURL: 'https://akooda-307211.firebaseio.com',
    projectId: 'akooda-dev-307211',
    storageBucket: 'akooda-dev-307211.appspot.com',
    messagingSenderId: '471767763336',
    appId: '1:471767763336:web:538fc6ceba19ca2654523d',
    measurementId: 'G-67FECCBP1J',
  },
  apiBaseUrl: process.env.REACT_APP_API_BASE_URL as string,
  appUrl: process.env.REACT_APP_BASE_URL as string,
};

export default devConfig;
