import { useEffect } from 'react';

import { useMetadataContext } from 'screens/platform/cross-platform-components/context/metadata/MetadataContext';
import { useMountedState } from 'utils/hooks';

export default function usePersonImage(personId: string | null): string | null {
  const { persons: { getPersonImageById } } = useMetadataContext();
  const [image, setImage] = useMountedState<string | null>(null);

  useEffect(() => {
    if (personId) {
      getPersonImageById(personId)
        .then((result) => setImage(result || null));
    }
  }, [personId]);

  return image;
}
