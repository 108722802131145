import classNames from 'classnames';
import React from 'react';

import ConfigurationKey from 'global/lists/ConfigurationKey';
import { useConfiguration } from 'screens/platform/cross-platform-components/context/configuration/ConfigHook';

import style from 'common-ui-components/Badge/BetaBadge/style.module.scss';

interface BetaBadgeProps {
  disabled?: boolean;
}
export default function BetaBadge({ disabled = false }: BetaBadgeProps) {
  const { value: isSalesDemoMode, loading } = useConfiguration(ConfigurationKey.IS_SALES_DEMO_MODE, 'boolean');

  return loading || isSalesDemoMode
    ? null : (
      <span className={classNames(style.betaLabel, disabled && style.disabled)}>Beta</span>
    );
}
