import React, { useCallback, useState } from 'react';

import ActionableBadge from 'common-ui-components/ActionableBadge';
import DatePickerDropdown from 'common-ui-components/DatePickerDropdown';
import { DatesRange } from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFilters';
import { useMasterFilters } from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFiltersContext';
import FilterMenu from 'screens/platform/cross-platform-components/MasterFiltersPanel/FilterMenu';
import FilterWrapper from 'screens/platform/cross-platform-components/MasterFiltersPanel/FilterWrapper';
import { useDatesRangeLabel } from 'screens/platform/cross-platform-components/MasterFiltersPanel/masterFiltersUtils';

export default function DatesRangeFilterSection() {
  const {
    currentFilters: { datesRange },
    dispatchMasterFilters,
  } = useMasterFilters();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const setDatesRange = useCallback((datesRangeToUpdate: DatesRange) => {
    dispatchMasterFilters({
      type: 'CHANGE_DATES_RANGE',
      payload: datesRangeToUpdate,
    });
  }, [dispatchMasterFilters]);

  return (
    <FilterWrapper filter={FilterMenu.DATES_RANGE}>
      <DatePickerDropdown
        datesRange={datesRange}
        setDatesRange={setDatesRange}
        isDropdownOpen={isDropdownOpen}
        setIsDropdownOpen={setIsDropdownOpen}
        toggleButtonElement={(
          <ActionableBadge
            label={useDatesRangeLabel()}
            iconType="edit"
            onClick={() => setIsDropdownOpen((prev) => !prev)}
          />
        )}
      />
    </FilterWrapper>
  );
}
