import classNames from 'classnames';
import React, { Dispatch, SetStateAction } from 'react';

import CSSVariableDefiner from 'common-ui-components/CSSVariableDefiner';
import AtomBadge from 'common-ui-components/Sources/SourceBadge/AtomBadge';
import OrganizationBadge from 'common-ui-components/Sources/SourceBadge/OrganizationBadge';
import PersonBadge from 'common-ui-components/Sources/SourceBadge/PersonBadge';
import SalesforceRecordBadge from 'common-ui-components/Sources/SourceBadge/SalesforceBadge';
import useDynamicHeights from 'common-ui-components/Sources/SourcesList/DynamicHeightHook';
import { EnrichedSource } from 'es-src/screens/HomeScreen/EnterpriseSearchContext/EnrichedSourcesDataHook';
import { isValidSourceType } from 'global/api/controller/EnterpriseSearchController';
import BadgesList from 'screens/platform/cross-platform-components/BadgesList';
import DebuggerConsole from 'utils/DebuggerConsole';

import style from 'common-ui-components/Sources/SourcesList/style.module.scss';

type Props = { sources: EnrichedSource[] } & (
  {
    minimized: boolean;
    setShouldAllowShowMore: Dispatch<SetStateAction<boolean>>;
  } | {
    minimized?: never;
    setShouldAllowShowMore?: never;
  }
)

export default function SourcesList({
  sources,
  minimized = false,
  setShouldAllowShowMore = () => {},
}: Props) {
  const { ref, heights, shouldAnimate } = useDynamicHeights(sources, setShouldAllowShowMore);

  return (
    <CSSVariableDefiner variables={{ '--height': `${minimized ? heights.firstLine : heights.total}px` }}>
      <div
        ref={ref}
        className={classNames(style.sourcesList, shouldAnimate && style.animated)}
      >
        <BadgesList<EnrichedSource> data={sources}>
          {(source) => (isValidSourceType(source.type) ? <SourceItem source={source} /> : null)}
        </BadgesList>
      </div>
    </CSSVariableDefiner>
  );
}

function SourceItem({ source }: { source: EnrichedSource }) {
  switch (source.type) {
    case 'atom':
      return <AtomBadge source={source} />;
    case 'salesforce':
      return <SalesforceRecordBadge source={source} />;
    case 'person':
      return <PersonBadge source={source} />;
    case 'organization':
      return <OrganizationBadge source={source} />;
    default:
      DebuggerConsole.error('Unidentified Enterprise Search source type', source);
      return null;
  }
}
