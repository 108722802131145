import React from 'react';

import Feedback from 'common-ui-components/Feedback';
import FeedbackContext from 'common-ui-components/Feedback/FeedbackContext';
import { Paragraph } from 'common-ui-components/Typograhpy';

import style from 'screens/platform/cross-platform-components/Disclaimer/style.module.scss';

interface Props {
  sessionId: string;
  isSuccessfulAnswer: boolean;
  location: FeedbackContext['location'] ;
}

export default function Disclaimer({ sessionId, isSuccessfulAnswer, location }: Props) {
  return (
    <>
      <div className={style.disclaimerWrapper}>
        <Paragraph className={style.disclaimer}>
          {isSuccessfulAnswer ? 'Results are generated by AI and may require verification. Rate accuracy' : 'Helpful?'}
        </Paragraph>
        <Feedback sessionId={sessionId} location={location} />
      </div>
    </>
  );
}
