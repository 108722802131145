import { useCallback } from 'react';

import Analytics, { AnalyticsEvent } from 'global/Analytics';
import { AnalyticsWidgetConfig, GroupType, WidgetType } from 'screens/platform/contentScreens/AnalyticsScreen/widgets/widgetConfig';
import { usePlatformContext } from 'screens/platform/cross-platform-components/context/platform/PlatformContext';

type HookResponse = {
  widgetConfig: AnalyticsWidgetConfig;
  setWidgetConfig: (partialChangedConfig: Partial<AnalyticsWidgetConfig>) => void;
};

export default function useWidgetConfig(widgetType: WidgetType): HookResponse {
  const {
    analyticsScreen: { [widgetType]: widgetConfig },
    dispatchPlatformContext,
  } = usePlatformContext();
  const setWidgetConfig = useCallback((nextConfig: Partial<AnalyticsWidgetConfig>) => {
    dispatchPlatformContext({
      type: 'SET_ANALYTICS_SCREEN_WIDGETS',
      payload: { [widgetType]: nextConfig },
    });
    trackUsedBreakdownOnAnAnalyticsWidget(widgetType, nextConfig.group);
  }, [dispatchPlatformContext, widgetType]);

  return { widgetConfig, setWidgetConfig };
}

function trackUsedBreakdownOnAnAnalyticsWidget(widgetType: WidgetType, group?: GroupType | null) {
  if (group) {
    Analytics.trackEvent(
      AnalyticsEvent.USED_BREAKDOWN_ON_AN_ANALYTICS_WIDGET,
      {
        widgetType,
        breakdownType: group,
      },
    );
  }
}
