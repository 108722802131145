import { Margin } from '@nivo/core';
import { LineSvgProps, ResponsiveLine, Serie } from '@nivo/line';
import classNames from 'classnames';
import {
  addHours, format, getDay, subHours,
} from 'date-fns';
import React from 'react';

import constants from 'screens/platform/constants';
import useTimeSeriesChartLabels, {
  useTimeSeriesChartLabelFormatter,
} from 'screens/platform/contentScreens/AnalyticsScreen/utils/TimeSeriesChartLabelsHook/TimeSeriesChartLabelsHook';
import { DateRangeGranularity } from 'screens/platform/contentScreens/AnalyticsScreen/widgets/widgetConfig';
import widgetConstants from 'screens/platform/contentScreens/AnalyticsScreen/widgets/widgetConstants';
import useLeftAxisTickValues from 'screens/platform/cross-platform-components/TimeLineChart/LeftAxisTickValuesHook';
import PointHighlight from 'screens/platform/cross-platform-components/TimeLineChart/PointHighlight';
import DateUtils from 'utils/DateUtils';

import style from 'screens/platform/cross-platform-components/TimeLineChart/style.module.scss';

export type DateDatum = {
  x: string;
  y: number | null;
  date: Date;
};

interface DatesSerie extends Serie {
  data: DateDatum[];
}

interface Props extends LineSvgProps {
  data: DatesSerie[];
  granularity: DateRangeGranularity;
  maxValue: number;
  margin?: Partial<Margin>;
  className?: string;
}

export default function TimeLineChart({
  data,
  granularity,
  maxValue,
  axisLeft,
  margin,
  className,
  onClick = () => {},
  ...restProps
}: Props) {
  const arbitraryDataSet = data[0].data; // All data sets should have the same dates
  const datesAmount = arbitraryDataSet.length;
  const firstDate = arbitraryDataSet[0].date;
  const lastDate = arbitraryDataSet[datesAmount - 1].date;

  const { ref, labels } = useTimeSeriesChartLabels({
    granularity,
    datesRange: { from: firstDate, to: lastDate },
    leftMargin: margin?.left,
    weekStartIndex: granularity === DateRangeGranularity.WEEK ? getDay(lastDate) : undefined,
  });
  const labelFormatter = useTimeSeriesChartLabelFormatter(labels);

  // The padding between the left-most dot and the Y axis is dependent on the width of each column
  const leftPaddingInHours = datesAmount === 1 ? 12 : 12 + 0.3 * datesAmount;

  return (
    <div ref={ref} className={classNames(style.chartWrapper, className)}>
      <ResponsiveLine
        data={data}
        theme={widgetConstants.lineChart.theme}
        axisBottom={{
          format: labelFormatter,
          tickValues: 'every day',
        }}
        axisLeft={{
          tickValues: useLeftAxisTickValues(maxValue),
          ...axisLeft,
        }}
        xScale={{
          type: 'time',
          format: '%Y-%m-%d-%H',
          precision: 'hour',
          useUTC: false,
          min: format(
            subHours(firstDate, leftPaddingInHours),
            DateUtils.DateFormat.YEAR_MONTH_DAY_HOUR,
          ),
          max: format(
            addHours(lastDate, 12),
            DateUtils.DateFormat.YEAR_MONTH_DAY_HOUR,
          ),
        }}
        layers={[
          PointHighlight,
          'grid',
          'markers',
          'axes',
          'areas',
          'crosshair',
          'lines',
          'points',
          'slices',
          'mesh',
          'legends',
        ]}
        curve="monotoneX"
        motionConfig="stiff"
        enablePoints={datesAmount === 1}
        enableSlices="x"
        margin={{
          top: 50,
          left: constants.TIMELINE_SCREEN_CANVAS_LEFT_SECTION_WIDTH - 16,
          right: datesAmount === 1 ? -15 : 0,
          bottom: 30,
          ...margin,
        }}
        colors={{ datum: 'color' }}
        onClick={onClick}
        {...widgetConstants.lineChart.props}
        {...restProps}
      />
    </div>
  );
}
