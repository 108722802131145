import React from 'react';

import PersonProfileImage from 'common-ui-components/MultiImages/ProfileImage/PersonProfileImage';
import Size from 'global/lists/Size';
import Person from 'model/Person';

import style
  from 'es-src/screens/HomeScreen/components/SearchWithAutocomplete/AutocompleteResultCategory/PersonResult/style.module.scss';

interface Props {
  person: Person;
}

export default function PersonResult({ person }: Props) {
  if (!person) return null;

  return (
    <div className={style.organizationAutocompleteResult}>
      <div className={style.personResult}>
        <div className={style.image}>
          <PersonProfileImage person={person} size={Size.MEDIUM} />
        </div>
        <div className={style.details}>
          <span className={style.name}>{person.name}</span>
          {person.department && (
          <>
            <span className={style.bullet}>•</span>
            <span className={style.department}>
              {person.department}
            </span>
          </>
          )}
        </div>
      </div>
    </div>
  );
}
