import classNames from 'classnames';
import React, { MouseEventHandler } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useAsync } from 'react-use';

import Button from 'common-ui-components/Button';
import Caret from 'common-ui-components/Caret';
import Dropdown from 'common-ui-components/Dropdown';
import ExternalLink from 'common-ui-components/ExternalLink';
import InternalLink from 'common-ui-components/InternalLink';
import Spinner from 'common-ui-components/Spinner';
import Analytics, { AnalyticsEvent } from 'global/Analytics';
import { AllReportsResponse } from 'global/api/controller/ReportController';
import Api from 'global/api/platformApi';
import { getScreenPath } from 'global/ScreensConfiguration';
import Report from 'model/Report';
import useTenantContext from 'screens/platform/cross-platform-components/context/tenant/TenantContext';
import ScreenButtonTooltipWrapper
  from 'screens/platform/cross-platform-components/Navbar/components/ScreenButton/ScreenButtonTooltipWrapper';
import { NAVBAR_DROPDOWN_DISTANCE } from 'screens/platform/cross-platform-components/Navbar/index';
import { useNavbarContext } from 'screens/platform/cross-platform-components/Navbar/NavbarContext';
import ArrayUtils from 'utils/ArrayUtils';
import DebuggerConsole from 'utils/DebuggerConsole';
import { useToggle } from 'utils/hooks';

import { ReactComponent as ReportsIcon } from 'assets/img/icon/screens/reports-screen.svg';

import style from 'screens/platform/cross-platform-components/Navbar/components/ScreenButton/style.module.scss';

export const REPORT_PARAM_KEY = 'id';
const REPORTS_URL = 'https://akooda.co/dashboards';

export default function ReportsButton() {
  const [isDropdownOpen, toggleDropdown] = useToggle();
  const { tenant: { id: tenantId } } = useTenantContext();

  const { value: reports, loading } = useAsync(async () => {
    try {
      const res = await Api.Report.getAllReports(tenantId);
      if (res) return res.data;
    } catch (error) {
      DebuggerConsole.error('Failed to fetch reports', tenantId);
    }
    return { reports: [] };
  }, [tenantId]);

  return (
    <Dropdown
      closeDropdown={toggleDropdown}
      isDropdownOpen={isDropdownOpen}
      placement="right"
      toggleButtonElement={(
        <ReportsDropdownToggle
          isDropdownOpen={isDropdownOpen}
          toggleDropdown={toggleDropdown}
        />
      )}
      dropdownClassName={style.dropdown}
      wrapperClassName={style.dropdownWrapper}
      toggleClassName={style.toggleWrapper}
      popperOptions={{ distance: NAVBAR_DROPDOWN_DISTANCE }}
    >
      {
        reports?.reports && !ArrayUtils.isEmpty(reports.reports) && (
          <ReportsList
            reports={reports}
            loading={loading}
            closeDropdown={toggleDropdown}
          />
        )
      }
      <ReportsCatalogueLink closeDropdown={toggleDropdown} />
    </Dropdown>
  );
}

interface ReportsListProps {
  reports?: AllReportsResponse;
  loading: boolean;
  closeDropdown: MouseEventHandler;
}

function ReportsList({
  reports, loading, closeDropdown,
}: ReportsListProps) {
  return (
    <>
      {
        reports
        && (
          <>
            {reports.reports.map((r) => (
              <ReportLink
                key={r.name}
                report={r}
                closeDropdown={closeDropdown}
              />
            ))}
            <div className={style.separator} />
          </>
        )
      }
      { !reports && loading && <Spinner /> }
    </>
  );
}

interface ReportsDropdownToggleProps {
  isDropdownOpen: boolean;
  toggleDropdown: () => void;
}

function ReportsDropdownToggle({
  isDropdownOpen,
  toggleDropdown,
}: ReportsDropdownToggleProps) {
  const isSelected = useRouteMatch({
    path: getScreenPath('platform.reports'),
  });
  const { isNavbarMinimized } = useNavbarContext();

  return (
    <ScreenButtonTooltipWrapper label="Reports">
      <Button
        onClick={toggleDropdown}
        className={classNames(
          style.screenButton,
          isDropdownOpen && style.open,
          Boolean(isSelected) && style.selected,
          isNavbarMinimized && style.minimized,
        )}
        transparent
      >
        <ReportsIcon className={style.icon} />
        <div className={style.label}>
          <span className={style.spacedLabel}>
            Reports
          </span>
        </div>
        <Caret
          isOpen={isDropdownOpen}
          className={classNames(
            style.caret,
            isNavbarMinimized && style.minimized,
          )}
          color="darkGray"
        />
      </Button>
    </ScreenButtonTooltipWrapper>
  );
}

interface ReportLinkProps {
  report: Report;
  closeDropdown: MouseEventHandler;
}

function ReportLink({ report, closeDropdown }: ReportLinkProps) {
  return (
    <InternalLink
      className={style.dropdownOption}
      to="platform.reports"
      search={`${REPORT_PARAM_KEY}=${report.name}`}
      title={report.title}
      onClick={closeDropdown}
    >
      {report.title}
    </InternalLink>
  );
}

interface AllReportsLinkProps {
  closeDropdown: MouseEventHandler;
}

function ReportsCatalogueLink({ closeDropdown }: AllReportsLinkProps) {
  const trackUserClickedReportsButton = (e: any) => {
    closeDropdown(e);
    Analytics.trackEvent(AnalyticsEvent.CLICKED_THE_REPORTS_BUTTON, {});
  };

  return (
    <ExternalLink
      className={classNames(style.dropdownOption, style.externalLink)}
      to={REPORTS_URL}
      onClick={trackUserClickedReportsButton}
    >
      Reports Catalogue
    </ExternalLink>
  );
}
