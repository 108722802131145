import React, { useState } from 'react';

import Spinner from 'common-ui-components/Spinner';
import HierarchicalSearchResults
  from 'screens/platform/cross-platform-components/HierarchicalSearchInput/components/HierarchicalSearchResults';
import useHierarchicalItemsSearch
  from 'screens/platform/cross-platform-components/HierarchicalSearchInput/HierarchicalItemsSearchHook';
import {
  HierarchicalItemType,
} from 'screens/platform/cross-platform-components/HierarchicalSearchInput/HierarchicalItemTypeConfig';
import useHierarchicalSearchInput
  from 'screens/platform/cross-platform-components/HierarchicalSearchInput/HierarchicalSearchInputHook';
import HierarchicalSearchInputWrapper, {
  SearchableHierarchicalItemTypes,
} from 'screens/platform/cross-platform-components/HierarchicalSearchInput/HierarchicalSearchInputWrapper';
import HierarchicalGroup from 'utils/HierarchicalDataStructures/HierarchicalGroup';

interface Props {
  selection: HierarchicalGroup;
  type: Exclude<SearchableHierarchicalItemTypes, HierarchicalItemType.PERSON>;
  onClickDone: () => void;
  searchPlaceholder?: string;
  className?: string;
  shouldClearSearchInput?: boolean;
  allowEmptySelection?: boolean;
}

export default function GenericHierarchicalSearchInput({
  type,
  selection,
  searchPlaceholder = 'Search',
  onClickDone,
  className,
  shouldClearSearchInput,
  allowEmptySelection = false,
}: Props) {
  const [searchQuery, setSearchQuery] = useState('');
  const itemsByGroups = useHierarchicalItemsSearch(type, searchQuery);

  const {
    isDisabled, emptySelectionHandler, searchInputRef,
  } = useHierarchicalSearchInput({
    isSelectionEmpty: selection.isNotSelected(),
    allowEmptySelection,
    shouldClearSearchInput,
    setSearchQuery,
  });

  const isLoading = itemsByGroups === null;

  return (
    <HierarchicalSearchInputWrapper
      onClickDone={onClickDone}
      setSearchQuery={setSearchQuery}
      searchPlaceholder={searchPlaceholder}
      disabled={isDisabled}
      className={className}
      ref={searchInputRef}
    >
      {isLoading ? <Spinner /> : (
        <HierarchicalSearchResults
          items={itemsByGroups}
          selection={selection}
          handleEmptySelectionChange={emptySelectionHandler}
          type={type}
          showEmptyGroupsAsCollapsible={type !== HierarchicalItemType.CHANNEL}
          initiallyOpen={{
            root: true,
            children: searchQuery.length > 0,
          }}
        />
      )}
    </HierarchicalSearchInputWrapper>
  );
}
