import Tenant from 'model/Tenant';
import { MasterFilters } from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFilters';
import { FlatUrlParams } from 'screens/platform/cross-platform-components/context/UrlParams/UrlParamsTypes';
import UrlParamsUtils from 'screens/platform/cross-platform-components/context/UrlParams/UrlParamsUtils';
import ArrayUtils from 'utils/ArrayUtils';
import DebuggerConsole from 'utils/DebuggerConsole';

export function flattenMasterFiltersToParams(
  masterFilters: MasterFilters,
  allDepartments: Tenant['departments'],
): FlatUrlParams {
  function hashDepartments(departments: string[] | null): string[] | null {
    if (departments === null) return null;
    try {
      return departments
        .filter((departmentLabel) => allDepartments[departmentLabel])
        .map((departmentLabel) => allDepartments[departmentLabel])
        .filter(ArrayUtils.isDefined)
        .map(({ id }) => id);
    } catch (err) {
      // Occurs when changing tenants, because the departments in `departmentsSet`
      // don't match those in `allDepartments`
      return [];
    }
  }

  const {
    selectedGroups: selectedCategories,
    selectedItems: selectedTags,
  } = masterFilters.categories.tagsByCategories.getMinimalSelectionStatus();
  const {
    selectedGroups: selectedApps,
    selectedItems: selectedChannels,
  } = masterFilters.apps.getMinimalSelectionStatus();
  const selectedDepartments = masterFilters.people.departments;

  return {
    from: masterFilters.datesRange.from,
    to: masterFilters.datesRange.to,
    categories: selectedCategories,
    tags: selectedTags,
    selectedOrganizationsIds: masterFilters.organizations.organizationsIds.selected,
    selectAllOrganizationsIds: masterFilters.organizations.organizationsIds.selected === null,
    includeUndefinedOrganizations: masterFilters.organizations.organizationsIds.includeUndefined,

    selectedOrganizationsRisks: masterFilters.organizations.selectedRisks.selected,
    selectAllOrganizationsRisks: masterFilters.organizations.selectedRisks.selected === null,
    includeUndefinedOrganizationsRisk: masterFilters.organizations.selectedRisks.includeUndefined,
    selectedOrganizationsSizes: masterFilters.organizations.selectedSizes.selected,
    selectAllOrganizationsSizes: masterFilters.organizations.selectedSizes.selected === null,
    includeUndefinedOrganizationsSize: masterFilters.organizations.selectedSizes.includeUndefined,
    selectedOrganizationsSegments: masterFilters.organizations.selectedSegments.selected,
    selectAllOrganizationsSegments: masterFilters.organizations.selectedSegments.selected === null,
    includeUndefinedOrganizationsSegment: masterFilters.organizations.selectedSegments
      .includeUndefined,

    includeUntagged: masterFilters.categories.includeUntagged,
    departments: hashDepartments(selectedDepartments),
    selectedTeams: masterFilters.people.teams.selected,
    selectAllTeams: masterFilters.people.teams.isAllSelected(),
    includeUndefinedTeam: masterFilters.people.teams.includeUndefined,
    peopleIds: masterFilters.people.internalPeopleIds,
    includeUnidentified: masterFilters.people.includeUnidentified,
    apps: selectedApps,
    channels: selectedChannels,

    directionality: masterFilters.directionality,
  };
}

export function generateUrlParams(
  flatMasterFilters: FlatUrlParams,
  flatDefaultMasterFilters: FlatUrlParams,
  defaultDatesRange: MasterFilters['datesRange'],
): string {
  const params = Object.keys(flatMasterFilters).reduce((acc, flatUrlParamsKey) => {
    const key = flatUrlParamsKey as keyof FlatUrlParams;
    try {
      if (UrlParamsUtils.isDateFilter(key)) {
        const defaultValue = defaultDatesRange[key];
        const value = flatMasterFilters[key];
        if (value.getTime() !== defaultValue.getTime()) {
          acc[key] = UrlParamsUtils.convertDateToUrlParam(value);
        }
      } else if (
        UrlParamsUtils.isHierarchicalGroupFilter(key)
        || UrlParamsUtils.isArrayFilter(key)
      ) {
        const defaultValue = flatDefaultMasterFilters[key];
        const value = flatMasterFilters[key];
        const nextValue = UrlParamsUtils.convertArrayToUrlParam(value, defaultValue);
        if (nextValue !== null) {
          acc[key] = nextValue;
        }
      } else if (UrlParamsUtils.isBooleanFilter(key)) {
        const defaultValue = flatDefaultMasterFilters[key];
        const value = flatMasterFilters[key];
        if (defaultValue !== value) {
          acc[key] = value.toString();
        }
      } else {
        DebuggerConsole.error(`Filter with key '${key}' has unknown type`);
      }
    } catch (err) {
      DebuggerConsole.error(err);
    }
    return acc;
  }, {} as Record<string, string>);

  const paramsAsUrl = Object.entries(params).reduce<string[]>(
    (acc, [key, value]) => acc.concat([`${key}=${value}`]),
    [],
  );

  return paramsAsUrl.length > 0 ? `?${paramsAsUrl.join('&')}` : '';
}
