import {
  createContext,
  Dispatch,
  useContext,
} from 'react';

import App from 'global/lists/apps';
import Metric from 'global/lists/Metric';
import Channel from 'model/Channel';
import Organization, { EnrichedOrganization } from 'model/Organization';
import Person from 'model/Person';
import PersonMetadata from 'screens/platform/cross-platform-components/context/metadata/dataStructures/PersonMetadata';
import { TagColors } from 'screens/platform/cross-platform-components/context/metadata/hooks/TagsColorsHook';
import { MetadataReducerAction, MetadataReducerStateType } from 'screens/platform/cross-platform-components/context/metadata/MetadataReducer';
import { UnionSubset } from 'utils/TypescriptTricks';

/**
 * Due to TypeScript's lack of support for treating undefined keys, we need to
 * use a partial type to make sure we have default values.
 * @see https://github.com/microsoft/TypeScript/issues/13778
 */
type RestrictedProps = UnionSubset<
  keyof MetadataReducerStateType,
  | 'metrics'
  | 'appsToChannelsMap'
  | 'tagsData'
>

export interface MetadataContextType extends Omit<MetadataReducerStateType, RestrictedProps> {
  tagsToCategories: Readonly<Record<string, string[]>>;
  dispatchMetadataContext: Dispatch<MetadataReducerAction>;
  refreshMetadata: (shouldShowLoader?: boolean) => Promise<void>;
  isAppConnected: (appId: App) => boolean;
  persons: {
    getById: (personId: string) => Promise<Person | undefined>;
    updatePersonInCache: (personId: string, person: Person | undefined) => void;
    getPersonImageById: (personId: string) => Promise<string | undefined>;
    allPeopleMetadata: Readonly<Partial<Record<string, PersonMetadata>>>;
    departmentsToPersonsMap: Readonly<Partial<Record<string, string[]>>>;
    teamsToPeopleMap: Readonly<Partial<Record<string, string[]>>>;
  };
  organizations: {
    getById: (organizationId: string) => Promise<Organization | undefined>;
    getEnrichedById: (organizationId: string) => Promise<EnrichedOrganization | undefined>;
    getDisplayNameById: (organizationId: string) => Promise<string | undefined>;
    getByClusterId: (clusterId: string) => Organization | undefined;
    updateOrganizationInCache: (organization: Organization) => void;
    getOrganizationImageById: (organizationId: string) => Promise<string | undefined>;
    abortCacheCalls: () => Promise<void>;
  };
  channels: {
    getById: (channelId: string) => Promise<Channel | undefined>;
    appsToChannelsMap: Readonly<Partial<Record<string, string[]>>>;
  };
  getTagColors: (tag: string, category?: string) => Readonly<TagColors>;
  getCategoryColor: (category: string) => string;
  isMetricEnabled: (metric: Metric) => boolean;
  isTagTopic: (tag: string) => boolean;
}

export const useMetadataContext:
  () => MetadataContextType = () => useContext<MetadataContextType>(MetadataContext);

const MetadataContext = createContext<MetadataContextType>({} as any);
export default MetadataContext;
