import React from 'react';

import { GlobalLoadingContextProvider } from 'screens/platform/cross-platform-components/context/GlobalLoadingContextProvider';
import TenantContextProvider from 'screens/platform/cross-platform-components/context/tenant/TenantContextProvider';
import UserAuthenticator from 'screens/platform/cross-platform-components/context/user/UserAuthenticator';
import { UserContextProvider } from 'screens/platform/cross-platform-components/context/user/UserContext';

function SecuredApp({ children }) {
  return (
    <UserContextProvider>
      <GlobalLoadingContextProvider>
        <TenantContextProvider>
          <UserAuthenticator>
            { children }
          </UserAuthenticator>
        </TenantContextProvider>
      </GlobalLoadingContextProvider>
    </UserContextProvider>
  );
}

export default SecuredApp;
