import React from 'react';

import Button from 'common-ui-components/Button';

import { ReactComponent as XIcon } from 'assets/img/icon/x-icon.svg';

import style from 'screens/platform/cross-platform-components/EventsPanel/EventsHeader/style.module.scss';

const ICON_SIZE = 15;

export default function HeaderCloseButton({ onClose }: { onClose: () => void }) {
  return (
    <Button
      onClick={onClose}
      rounded
      color="gray"
      className={style.closeButton}
    >
      <XIcon
        className={style.closeIcon}
        width={ICON_SIZE}
        height={ICON_SIZE}
      />
    </Button>
  );
}
