import React, {
  createContext, Dispatch, ReactNode, useContext, useReducer,
} from 'react';

import CustomEventsFiltersReducer, { CustomEventsFiltersReducerAction, CustomEventsFiltersState } from 'screens/platform/cross-platform-components/context/CustomFilters/CustomEventsFilters/CustomEventsFiltersReducer';
import { EMPTY_CUSTOM_FILTERS } from 'screens/platform/cross-platform-components/context/CustomFilters/CustomFiltersUtils';

export interface CustomEventsFiltersContextType {
  dispatchCustomEventsFilters: Dispatch<CustomEventsFiltersReducerAction>;
  customEventsFilters: CustomEventsFiltersState;
}

export function useCustomEventsFilters(): CustomEventsFiltersContextType {
  return useContext<CustomEventsFiltersContextType>(CustomEventsFiltersContext);
}

const CustomEventsFiltersContext = createContext<CustomEventsFiltersContextType>({} as any);

export default function CustomEventsFiltersContextProvider({ children }: { children: ReactNode }) {
  const [customEventsFilters, dispatchCustomEventsFilters] = useReducer(
    CustomEventsFiltersReducer,
    EMPTY_CUSTOM_FILTERS,
  );

  return (
    <CustomEventsFiltersContext.Provider value={{
      customEventsFilters,
      dispatchCustomEventsFilters,
    }}
    >
      { children }
    </CustomEventsFiltersContext.Provider>
  );
}
