import { createContext, useContext } from 'react';

import ConfigurationKey from 'global/lists/ConfigurationKey';
import { ConfigType } from 'screens/platform/cross-platform-components/context/configuration/ConfigurationContextProvider';

export interface ConfigurationContextType {
  getConfiguration: <T extends ConfigType>(configKey: ConfigurationKey) => T | undefined;
}

const ConfigurationContext = createContext<ConfigurationContextType>({} as any);

export const useConfigurationContext: () => ConfigurationContextType = () =>
  useContext<ConfigurationContextType>(ConfigurationContext);

export default ConfigurationContext;
