import classNames from 'classnames';
import React, { useMemo } from 'react';

import { CircleIconProps } from 'common-ui-components/CircleIcon/index';
import ImageWithFallback from 'common-ui-components/ImageWithFallback';
import Size from 'global/lists/Size';
import useDomainImage from 'screens/platform/cross-platform-components/DomainImage/DomainImageHook';

import { ReactComponent as LinkIcon } from 'assets/img/icon/link.svg';

import circleIconStyle from 'common-ui-components/CircleIcon/style.module.scss';
import style from 'screens/platform/cross-platform-components/DomainImage/style.module.scss';

interface DomainImageProps {
  domain: string | null;
  size: CircleIconProps['size'];
  className?: string;
  showTitle?: boolean;
}

interface FallbackIconProps {
  className: string;
}

function FallbackIcon({ className }: FallbackIconProps) {
  return (
    <div className={className}>
      <LinkIcon />
    </div>
  );
}

export default function DomainImage({
  domain,
  size,
  showTitle = true,
  className,
}: DomainImageProps) {
  const image = useDomainImage(domain);
  const sizeClassName = useMemo(() => {
    switch (size) {
      case Size.BIG: return circleIconStyle.big;
      case Size.MEDIUM:
      default:
        return circleIconStyle.medium;
    }
  }, [size]);

  const compositeClassName = classNames(
    circleIconStyle.circleIcon,
    style.icon,
    sizeClassName,
    className,
  );
  return (
    <ImageWithFallback
      imageUrl={image}
      name={domain ?? 'Link'}
      title={showTitle && domain ? domain : undefined}
      fallbackComponent={<FallbackIcon className={compositeClassName} />}
      className={compositeClassName}
    />
  );
}
