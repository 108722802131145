import {
  ChartType,
  WidgetRecipe,
} from 'screens/platform/contentScreens/AnalyticsScreen/widgets/widgetConfig';

const metricsRecipes: WidgetRecipe[] = [
  {
    group: null,
    chart: ChartType.COMMCHART,
    granularity: null,
    Component: () => null,
  },
];

export default metricsRecipes;
