/* eslint-disable no-console */
import * as Sentry from '@sentry/react';

import { isRunningLocally } from 'global/config';
import Environment from 'global/lists/environments';
import { shouldRunSentry } from 'global/sentry';
import ArrayUtils from 'utils/ArrayUtils';

export default class DebuggerConsole {
  private static shouldLog() {
    const environmentsToDebug = [Environment.DEVELOPMENT, Environment.STAGING];
    return isRunningLocally()
      || environmentsToDebug.some((env) => process.env.CLIENT_ENV === env);
  }

  private static getEnvMsg() {
    return [`%c[${process.env.CLIENT_ENV} env log]`, 'color: orange; font-size:9px'];
  }

  static log(...params) {
    if (DebuggerConsole.shouldLog()) {
      console.log(...DebuggerConsole.getEnvMsg(), ...params);
    }
  }

  static warn(...params) {
    if (DebuggerConsole.shouldLog()) {
      console.warn(...DebuggerConsole.getEnvMsg(), ...params);
    }
  }

  static error(...params) {
    if (DebuggerConsole.shouldLog()) {
      console.error(...DebuggerConsole.getEnvMsg(), ...params);
    }
    if (shouldRunSentry()) {
      const [errorMsg, ...errorContext] = params;
      Sentry.captureMessage(errorMsg, {
        level: 'error',
        contexts: {
          exceptionContext: ArrayUtils.convertToObject(errorContext),
        },
      });
    }
  }
}
