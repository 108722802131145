import { useEffect, useMemo, useState } from 'react';

import { EventsSummary } from 'global/api/controller/EventController';
import useEventsFetcher from 'screens/platform/contentScreens/AnalyticsScreen/utils/EventsFetcherHook';
import useWidgetConfig from 'screens/platform/contentScreens/AnalyticsScreen/utils/WidgetConfigHook';
import { filterEventsSummary } from 'screens/platform/contentScreens/AnalyticsScreen/widgets/EventsChart/EventChartUtils';
import { WidgetType } from 'screens/platform/contentScreens/AnalyticsScreen/widgets/widgetConfig';
import { useMasterFilters } from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFiltersContext';

export default function useFilteredEventSummary(): EventsSummary | null {
  const [eventsSummary, setEventsSummary] = useState<EventsSummary | null>(null);
  const { fetchEventsSummary } = useEventsFetcher();
  const { widgetConfig } = useWidgetConfig(WidgetType.EVENT);
  const { currentFilters } = useMasterFilters();

  useEffect(() => {
    async function init() {
      setEventsSummary(null);
      const nextEventsSummary = await fetchEventsSummary(widgetConfig.group);
      setEventsSummary(nextEventsSummary);
    }

    init();
  }, [widgetConfig.group, currentFilters.version]);

  const filteredEventSummary = useMemo(() => (
    eventsSummary ? filterEventsSummary(eventsSummary, widgetConfig) : null
  ), [eventsSummary, widgetConfig.settings.significanceThreshold]);

  return filteredEventSummary;
}
