import { useMemo } from 'react';

import Metric from 'global/lists/Metric';
import {
  PeopleDirectoryTableColumn,
  PeopleDirectoryTableColumnId,
} from 'screens/platform/directory/people/PeopleDirectoryScreen/PeopleTable/utils/PeopleDirectoryTableColumn';
import {
  peopleDirectoryColumnIdToMetric,
} from 'screens/platform/directory/people/PeopleDirectoryScreen/PeopleTable/utils/PeopleDirectoryTableMetricColumnsHook';
import RequestedField, { FieldType } from 'screens/platform/directory/utils/RequestedField';

export enum PersonFieldKey {
  NAME = 'NAME',
  DEPARTMENT = 'DEPARTMENT',
  AREAS_OF_FOCUS = 'AREAS_OF_FOCUS',
  TEAMS = 'TEAMS',
  MANAGER = 'MANAGER',
  ENGAGED_ORGANIZATIONS = 'ENGAGED_ORGANIZATIONS',
  INTERNAL_PEOPLE = 'INTERNAL_PEOPLE',
  EMAIL = 'EMAIL',
  ORGANIZATION = 'ORGANIZATION',
}

type PersonField = RequestedField<PersonFieldKey | Metric>;

const personFieldKeyToFieldType: Record<PersonFieldKey, FieldType> = {
  [PersonFieldKey.NAME]: FieldType.ORGANIC,
  [PersonFieldKey.DEPARTMENT]: FieldType.ORGANIC,
  [PersonFieldKey.TEAMS]: FieldType.ORGANIC,
  [PersonFieldKey.MANAGER]: FieldType.ORGANIC,
  [PersonFieldKey.EMAIL]: FieldType.ORGANIC,
  [PersonFieldKey.ORGANIZATION]: FieldType.ORGANIC,
  [PersonFieldKey.AREAS_OF_FOCUS]: FieldType.ADDITIONAL,
  [PersonFieldKey.ENGAGED_ORGANIZATIONS]: FieldType.ADDITIONAL,
  [PersonFieldKey.INTERNAL_PEOPLE]: FieldType.ADDITIONAL,
};

function getRequestedFields(
  selectedColumns: (
    | PeopleDirectoryTableColumnId
    | string
  )[],
): PersonField[] {
  const peopleTableColumnIdToPersonFieldKey: Record<
    PeopleDirectoryTableColumnId,
    PersonFieldKey | Metric
  > = {
    [PeopleDirectoryTableColumnId.NAME]: PersonFieldKey.NAME,
    [PeopleDirectoryTableColumnId.DEPARTMENT]: PersonFieldKey.DEPARTMENT,
    [PeopleDirectoryTableColumnId.TEAMS]: PersonFieldKey.TEAMS,
    [PeopleDirectoryTableColumnId.MANAGER]: PersonFieldKey.MANAGER,
    [PeopleDirectoryTableColumnId.TOPICS]:
      PersonFieldKey.AREAS_OF_FOCUS,
    [PeopleDirectoryTableColumnId.ORGANIZATIONS]:
      PersonFieldKey.ENGAGED_ORGANIZATIONS,

    ...peopleDirectoryColumnIdToMetric,

  };

  return selectedColumns.map((columnId) => {
    const key = peopleTableColumnIdToPersonFieldKey[columnId] ?? columnId;
    let type = FieldType.CUSTOM_PROPERTY;
    if (key in Metric) {
      type = FieldType.METRIC;
    }
    if (key in PersonFieldKey) {
      type = personFieldKeyToFieldType[key];
    }

    return { key, type };
  });
}

export const useRequestedFields = (
  columns: PeopleDirectoryTableColumn[],
) => useMemo(() => getRequestedFields(columns.map((c) => c.key)), [columns]);

export default PersonField;
