import React from 'react';

import CustomFiltersBadges from 'common-ui-components/CustomFiltersBadges';
import SpacedBullet from 'common-ui-components/SpacedBullet';
import {
  useCustomAtomsFilters,
} from 'screens/platform/cross-platform-components/context/CustomFilters/CustomAtomsFilters/CustomAtomsFiltersContextProvider';
import { AtomFilterType, CustomAtomsFiltersState } from 'screens/platform/cross-platform-components/context/CustomFilters/CustomAtomsFilters/CustomAtomsFiltersReducer';
import InteractionsCountLabel
  from 'screens/platform/cross-platform-components/Interactions/components/InteractionsTitle/InteractionsCount/InteractionsCountLabel';
import { useInteractionsContext } from 'screens/platform/cross-platform-components/Interactions/InteractionsContext';
import ArrayUtils from 'utils/ArrayUtils';
import ObjectUtils from 'utils/ObjectUtils';

import { ReactComponent as LockIcon } from 'assets/img/icon/lock.svg';

import style
  from 'screens/platform/cross-platform-components/Interactions/components/InteractionsTitle/InteractionsCount/style.module.scss';

interface Props {
  allowedAtomsCount: number | undefined;
}

export default function InteractionsCountContent({
  allowedAtomsCount,
}: Props) {
  const {
    customAtomsFilters,
    dispatchCustomAtomsFilters,
  } = useCustomAtomsFilters();
  const {
    interactionsMode,
    pagination: { value: { pageSize } },
  } = useInteractionsContext();

  const onRemove = ((filterType: AtomFilterType, filterValue: string | null) => {
    const { people } = customAtomsFilters;
    if (filterType === 'people' && ArrayUtils.isDefined(people) && people.length > 1) {
      const nextPeopleCustomAtomsFilter = people
        .filter((personId) => filterValue !== personId);
      dispatchCustomAtomsFilters({
        type: 'ADD_FILTERS',
        payload: {
          people: nextPeopleCustomAtomsFilter,
        },
      });
    } else {
      dispatchCustomAtomsFilters({ type: 'DELETE_FILTERS', payload: { type: filterType } });
    }
  });

  if (interactionsMode.value === 'table') {
    return (
      <>
        <InteractionsCountLabel />
        {!ObjectUtils.isEmpty(customAtomsFilters) && (
        <>
          <SpacedBullet />
          <CustomFiltersBadges<CustomAtomsFiltersState>
            customFilters={customAtomsFilters}
            onRemove={onRemove}
          />
        </>
        )}
      </>
    );
  }

  if (allowedAtomsCount === undefined) return null;

  const basedOn = Math.min(pageSize, allowedAtomsCount);
  return (
    <div className={style.summaryInteractionsCount}>
      {`Based on ${basedOn} out of ${allowedAtomsCount} results`}
      {' '}
      <LockIcon className={style.lockIcon} />
    </div>
  );
}
