/* eslint-disable import/prefer-default-export */
// eslint-disable-next-line max-classes-per-file
import { Exception } from '@sentry/react';

export class UnauthorizedException implements Exception {}

export function isFirebaseIgnorableException(err: any): boolean {
  const errorMessagesToIgnore = [
    'auth/popup-closed-by-user',
    'auth/cancelled-popup-request',
  ];
  return Boolean(err.message)
    && errorMessagesToIgnore.some((msg) => err.message.includes(msg));
}
