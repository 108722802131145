import React, { SetStateAction } from 'react';

import Scroller from 'common-ui-components/Scroller';
import SettingsPopover from 'common-ui-components/SettingsPopover';
import PopoverSection from 'common-ui-components/SettingsPopover/PopoverSection';
import useWidgetConfig from 'screens/platform/contentScreens/AnalyticsScreen/utils/WidgetConfigHook';
import { WidgetType } from 'screens/platform/contentScreens/AnalyticsScreen/widgets/widgetConfig';
import { defaultAnalyticsScreenSettings } from 'screens/platform/cross-platform-components/context/platform/PlatformContextProvider';

export default function EventsDrawerSettings({ isOpen, setIsOpen }: {
    isOpen: boolean;
    setIsOpen: (val: SetStateAction<boolean>) => void;
  }) {
  const { widgetConfig: config, setWidgetConfig } = useWidgetConfig(WidgetType.EVENT);
  const { significanceThreshold } = config.settings;
  const defaultInitialValue = defaultAnalyticsScreenSettings.significanceThreshold;
  const onChange = (nextThreshold: number) => {
    const nextConfig = {
      ...config,
      settings: {
        ...config.settings,
        significanceThreshold: nextThreshold,
      },
    };
    setWidgetConfig(nextConfig);
  };

  return (
    <SettingsPopover isOpen={isOpen} setIsOpen={setIsOpen}>
      <PopoverSection title="Potential Significance">
        <Scroller
          initialValue={significanceThreshold || defaultInitialValue}
          leftLabel="Low"
          rightLabel="High"
          fireOnChangeOnlyOnMouseUp
          onChange={(val) => onChange(val)}
          colored
          ticksAmount={5}
        />
      </PopoverSection>
    </SettingsPopover>
  );
}
