import React, { useMemo } from 'react';

import { TableColumn } from 'common-ui-components/Table';
import Person from 'model/Person';
import useTenantContext from 'screens/platform/cross-platform-components/context/tenant/TenantContext';
import DebuggerConsole from 'utils/DebuggerConsole';
import { WithRequired } from 'utils/TypescriptTricks';

export type RenderableTableColumn<T> = WithRequired<TableColumn<T>, 'render' | 'title'> & { key: string }

export const CUSTOM_PERSON_PROPERTIES_PREFIX = 'properties.';

export default function useCustomPersonPropertiesColumns<T extends Person>():
  RenderableTableColumn<T>[] {
  const { tenant } = useTenantContext();

  return useMemo(() => Object.keys(
    tenant.personProperties ?? {},
  ).map((propertyKey) => ({
    title: propertyKey,
    key: `${CUSTOM_PERSON_PROPERTIES_PREFIX}${propertyKey}`,
    sorting: { byKey: true },
    customSortingConfig: {
      personProperty: `${CUSTOM_PERSON_PROPERTIES_PREFIX}${propertyKey}`,
    },
    render: (_, { properties }) => {
      if (properties?.[propertyKey] !== undefined) {
        if (!['string', 'number'].includes(typeof properties[propertyKey])) {
          const type = typeof properties[propertyKey];
          DebuggerConsole.error(
            `Tried to render person property '${propertyKey}' with unsupported value type: ${type}`,
          );
          return null;
        }
        return <span>{properties[propertyKey]}</span>;
      }
      return null;
    },
  })), [tenant.personProperties]);
}
