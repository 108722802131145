import React from 'react';

import { usePlatformContext } from 'screens/platform/cross-platform-components/context/platform/PlatformContext';

import styles from 'screens/platform/contentScreens/AnalyticsScreen/components/WidgetsDrawer/Overlay/style.module.scss';

export default function Overlay() {
  const { widgetsDrawer: { setWidgetDrawerOpen } } = usePlatformContext();

  return <div className={styles.container} onClick={() => setWidgetDrawerOpen(false)} />;
}
