import Role from 'global/lists/Role';

const roleHierarchy: Record<Role, Role[]> = {
  [Role.SUPERADMIN]: [Role.ADMIN],
  [Role.ADMIN]: [Role.INTEGRATIONADMIN, Role.ANALYST],
  [Role.INTEGRATIONADMIN]: [Role.USER],
  [Role.ANALYST]: [Role.USER],
  [Role.USER]: [],
  [Role.RESTRICTED]: [],
  [Role.NUDGER]: [],
  [Role.GPT_SESSION_VIEWER]: [],
};

export default function isUserRoleAllowed(userRoles: Role[], minimumRole: Role): boolean {
  return userRoles.some((role) => traverseRoles(role, minimumRole));
}

function traverseRoles(userRole: Role, minimumRole: Role): boolean {
  if (userRole === minimumRole) {
    return true;
  }
  return roleHierarchy[userRole]
    .some((rule) => traverseRoles(rule, minimumRole));
}
