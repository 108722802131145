import { useMemo } from 'react';

import Api from 'global/api/platformApi';
import Organization, { EnrichedOrganization } from 'model/Organization';
import useOrganizationFeatureConfig
  from 'screens/platform/cross-platform-components/context/configuration/OrganizationFeatureConfigHook';
import MetadataItemsCache from 'screens/platform/cross-platform-components/context/metadata/MetadataItemsCache';
import useTenantContext from 'screens/platform/cross-platform-components/context/tenant/TenantContext';
import { getOrganizationName } from 'utils/OrganizationUtils';

export default function useConditionalOrganizationsFetching() {
  const { tenant } = useTenantContext();

  const {
    value: isOrganizationsFeatureActivated,
    loading: isOrganizationsFeatureActivatedLoading,
  } = useOrganizationFeatureConfig();

  const organizationsCache = useMemo(
    () =>
      new MetadataItemsCache<Organization>({
        fetchItemsByIds: isOrganizationsFeatureActivated
          ? async (ids: string[], abortSignal?: AbortSignal) =>
            Api.Organization.findOrganizationsByIds(
              tenant.id,
              ids,
              abortSignal,
            ).then((res) => res?.data)
          : async () => ({}),
      }),
    [tenant, isOrganizationsFeatureActivated],
  );
  const enrichedOrganizationsCache = useMemo(
    () =>
      new MetadataItemsCache<EnrichedOrganization>({
        fetchItemsByIds: isOrganizationsFeatureActivated
          ? async (ids: string[], abortSignal?: AbortSignal) =>
            Api.Organization.findEnrichedOrganizationsByIds(
              tenant.id,
              ids,
              abortSignal,
            ).then((res) => res?.data)
          : async () => ({}),
      }),
    [tenant, isOrganizationsFeatureActivated],
  );
  const organizationsNamesCache = useMemo(
    () =>
      new MetadataItemsCache<string>({
        fetchDebounceTimeInMillis: 1000,
        fetchItemsByIds: isOrganizationsFeatureActivated
          ? async (ids: string[]) => fetchOrganizationsNamesMap(tenant.id, ids)
          : async () => ({}),
      }),
    [tenant, isOrganizationsFeatureActivated],
  );

  return {
    organizationsCache,
    organizationsNamesCache,
    enrichedOrganizationsCache,
    isOrganizationsFeatureActivated,
    isOrganizationsFeatureActivatedLoading,
  };
}

async function fetchOrganizationsNamesMap(
  tenantId: number,
  organizationsIds: string[],
): Promise<Record<string, string> | undefined> {
  const organizationsResponse = await Api.Organization.findOrganizationsByIds(
    tenantId,
    organizationsIds,
  );
  const organizationsMap = organizationsResponse?.data;
  if (organizationsMap !== undefined) {
    return Object.fromEntries(
      Object.entries(organizationsMap)
        .map(([organizationId, organization]) =>
          [organizationId, getOrganizationName(organization)]),
    );
  }
  return undefined;
}
