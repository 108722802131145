import { getRequest, HttpResponse } from 'global/api/platformApiHelpers';
import Tenant from 'model/Tenant';

const ConnectorController = {
  async getSlackWorkspaceSubdomain(tenantId: Tenant['id']): HttpResponse<string> {
    return getRequest('connector/slack-workspace-subdomain', { tenantId });
  },
};

export default ConnectorController;
