import {
  Dispatch, MutableRefObject, SetStateAction, useEffect, useRef,
} from 'react';

import { EnrichedSource } from 'es-src/screens/HomeScreen/EnterpriseSearchContext/EnrichedSourcesDataHook';
import { useMountedState } from 'utils/hooks';

interface HeightsState {
  firstLine: number;
  total: number;
}

export default function useDynamicHeights(
  sources: EnrichedSource[],
  setShouldAllowShowMore: Dispatch<SetStateAction<boolean>>,
): {
  ref: MutableRefObject<HTMLDivElement | null>;
  heights: HeightsState;
  shouldAnimate: boolean;
} {
  const [heights, setHeights] = useMountedState<HeightsState>({
    firstLine: 0,
    total: 0,
  });
  const listElementRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    let timeoutId;

    function populateHeights() {
      const badgesList = listElementRef.current?.children?.[0];
      if (badgesList) {
        const badges = badgesList.children;

        const isFullyLoaded = badges.length === sources.length;
        const firstBadge = badges[0];
        if (firstBadge && isFullyLoaded) {
          const firstChildRect = firstBadge.getBoundingClientRect();

          const firstLineHeight = firstChildRect.height;
          const totalHeight = badgesList.clientHeight;

          setHeights({
            firstLine: firstLineHeight,
            total: totalHeight,
          });
          setShouldAllowShowMore(totalHeight > firstLineHeight);

          return;
        }
      }

      timeoutId = setTimeout(populateHeights, 1);
    }

    populateHeights();
    return () => clearTimeout(timeoutId);
  }, []);

  return {
    ref: listElementRef,
    heights,

    // The initial transition (from 0 to the line height) shouldn't be noticed
    shouldAnimate: heights.firstLine > 0,
  };
}
