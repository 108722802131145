import classNames from 'classnames';
import React from 'react';

import ImageWithFallback from 'common-ui-components/ImageWithFallback';
import Size from 'global/lists/Size';
import Organization from 'model/Organization';
import useOrganizationImage from 'screens/platform/cross-platform-components/context/metadata/hooks/OrganizationImageHook';
import { getOrganizationName } from 'utils/OrganizationUtils';

import style from 'common-ui-components/MultiImages/ProfileImage/style.module.scss';

type Props = {
  organization: Organization;
  size?: Size;
};

export default function OrganizationProfileImage({
  organization, size = Size.BIG,
}: Props) {
  const imageUrl = useOrganizationImage(organization.id);
  return (
    <ImageWithFallback
      className={classNames(style.profileImage, style.lightBorders, style.external, {
        [style.small]: size === Size.SMALL,
        [style.medium]: size === Size.MEDIUM,
        [style.big]: size === Size.BIG,
      })}
      imageUrl={imageUrl}
      name={getOrganizationName(organization)}
    />
  );
}
