import React from 'react';

import Checkbox from 'common-ui-components/Checkbox';
import PopoverSection from 'common-ui-components/SettingsPopover/PopoverSection';

import style from 'common-ui-components/SettingsPopover/SettingsCheckboxesSection/style.module.scss';

export interface SettingsCheckboxOption {
  label: string;
  key: string;
  checked: boolean;
  onClick: () => void;
}

interface Props {
  title: string;
  options: SettingsCheckboxOption[];
}

export default function SettingsCheckboxesSection({ title, options }: Props) {
  return (
    <PopoverSection title={title} noPadding>
      <div className={style.checkboxesWrapper}>
        {options.map(({
          key, label, checked, onClick,
        }) => (
          <Checkbox
            key={key}
            className={style.popoverCheckbox}
            label={label}
            checked={checked}
            onClick={onClick}
          />
        ))}
      </div>
    </PopoverSection>
  );
}
