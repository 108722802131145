import React, { ReactNode } from 'react';

import BadgeItem from 'screens/platform/cross-platform-components/BadgesList/BadgeItem';

import style from 'screens/platform/cross-platform-components/BadgesList/style.module.scss';

interface Props<T> {
  children: (item: T) => ReactNode;
  data: T[];
}

export default function BadgesList<T>({ data, children }: Props<T>) {
  return (
    <div className={style.badgesList}>
      {data.map((item: T) => (
        <BadgeItem key={crypto.randomUUID()}>
          {children(item)}
        </BadgeItem>
      ))}
    </div>
  );
}
