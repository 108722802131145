import { useMemo } from 'react';

import { useEnterpriseSearchContext } from 'es-src/screens/HomeScreen/EnterpriseSearchContext/EnterpriseSearchContext';

export default function useHasSearchResults() {
  const { searchResultsResponse } = useEnterpriseSearchContext();

  return useMemo(() => {
    if (!searchResultsResponse || !searchResultsResponse.value) {
      return false;
    }

    const { value: responseValue } = searchResultsResponse;
    if (responseValue === null) return false;
    const {
      atoms, organizations, persons, topics,
    } = responseValue;
    return (
      atoms.results?.length > 0
      || organizations?.results?.length > 0
      || persons?.results?.length > 0
      || topics?.results?.length > 0
    );
  }, [searchResultsResponse]);
}
