import React, { FC } from 'react';

import SimpleSettingsPopover from 'common-ui-components/SettingsPopover/SimpleSettingsPopover';
import ClusteringOptions from 'screens/platform/cross-platform-components/CommChartGraph/components/CommChartActions/components/CommChartSettings/ClusteringOptions';
import CommChartFilters from 'screens/platform/cross-platform-components/CommChartGraph/components/CommChartActions/components/CommChartSettings/CommChartFilters';
import useOrganizationFeatureConfig from 'screens/platform/cross-platform-components/context/configuration/OrganizationFeatureConfigHook';
import GraphFeaturesCheckboxes, {
  GraphFeaturesDisplayOption,
} from 'screens/platform/cross-platform-components/RightPanel/GraphFeaturesCheckboxes';

const showExternalNodesOption: GraphFeaturesDisplayOption = {
  label: 'Show External Nodes',
  key: 'showExternalTeamNodes',
};

const CommChartSettings: FC = () => {
  const { value: isOrganizationsFeatureEnabled } = useOrganizationFeatureConfig();

  const options: GraphFeaturesDisplayOption[] = [
    { label: 'Show Names', key: 'showNames' },
    { label: 'Show Images', key: 'showImages' },
    { label: 'Show Cluster Overlays', key: 'isClusterGraphEnabled' },
    ...(isOrganizationsFeatureEnabled ? [showExternalNodesOption] : []),
    { label: 'Show Siloed Nodes', key: 'showDetachedNodes' },
  ];

  return (
    <SimpleSettingsPopover>
      <GraphFeaturesCheckboxes options={options} />
      <CommChartFilters />
      <ClusteringOptions />
    </SimpleSettingsPopover>
  );
};

export default CommChartSettings;
