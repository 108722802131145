import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import Button from 'common-ui-components/Button/index';
import Modal from 'common-ui-components/Modal/index';

import { ReactComponent as WarningIcon } from 'assets/img/icon/warning.svg';

import style from 'common-ui-components/NoAccessRouteModal/style.module.scss';

interface Props {
  redirectUrl: string;
}

export default function NoAccessRouteModal({ redirectUrl }: Props) {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const history = useHistory();

  function onModalClose() {
    setIsModalOpen(!isModalOpen);
    history.push(redirectUrl);
  }

  return (
    <Modal
      className={style.modalWrapper}
      isOpen={isModalOpen}
      onClose={onModalClose}
    >
      <div className={style.warningIcon}>
        <WarningIcon />
      </div>
      <div className={style.warningText}>
        <h2>Whoops!</h2>
        <h3>
          It looks like you don&apos;t have access to this page.
          No worries, reach out to your admin for assistance.
        </h3>
      </div>
      <div className={style.warningBtn}>
        <Button padded color="blue" onClick={onModalClose}>OK</Button>
      </div>
    </Modal>
  );
}
