import { TableColumnType } from 'antd';
import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo } from 'react';

import { TableColumn } from 'common-ui-components/Table/index';
import { SortingConfig } from 'common-ui-components/Table/sortingHook';
import TableFilterDropdown from 'common-ui-components/Table/TableFilterDropdown';
import TitleWithTooltip from 'common-ui-components/TitleWithTooltip';
import FilterConfig, {
  FilterValue,
  FilterValues,
} from 'screens/platform/directory/components/GenericDirectoryScreen/FiltersList/FilterConfig';
import { useMountedState, useResizeListener } from 'utils/hooks';

import { ReactComponent as ArrowDownIcon } from 'assets/img/icon/arrow-down.svg';
import { ReactComponent as ArrowUpIcon } from 'assets/img/icon/arrow-up.svg';
import { ReactComponent as FilterIcon } from 'assets/img/icon/filter-dashes.svg';

function getSorter<T>(column: TableColumn<T>): TableColumnType<T>['sorter'] {
  const { sorting } = column;
  if (!sorting) return undefined;

  if ('byKey' in sorting) {
    return sorting.byKey;
  }
  if ('byComparator' in sorting) {
    return sorting.byComparator;
  }

  return undefined;
}

const TITLE_CHAR_WIDTH = 7;
const ACTION_BUTTON_WIDTH = 20;
const INFO_TOOLTIP_WIDTH = 20;
const REACT_ELEMENT_WIDTH = 40;
const EXTRA_SPACE = 40;

function getColumnTitleMinWidth(
  columnTitle: string | React.ReactElement,
  hasTooltip: boolean,
  isSortable: boolean,
  isFilterable: boolean,
) {
  const columnTitleLength = typeof columnTitle === 'string' ? columnTitle.length : REACT_ELEMENT_WIDTH;
  const baseWidth = columnTitleLength * TITLE_CHAR_WIDTH;
  const infoTooltipWidth = hasTooltip ? INFO_TOOLTIP_WIDTH : 0;
  let actionButtonsWidth = 0;
  if (isSortable) {
    actionButtonsWidth += ACTION_BUTTON_WIDTH;
  }
  if (isFilterable) {
    actionButtonsWidth += ACTION_BUTTON_WIDTH;
  }

  return baseWidth + infoTooltipWidth + actionButtonsWidth + EXTRA_SPACE;
}

export default function useColumnsPropsPopulator<
  RecordType,
  CustomSortingConfig,
>(
  columns: TableColumn<RecordType>[],
  addColumnIndexes: boolean,
  sorting: SortingConfig<CustomSortingConfig> | undefined,
  filtersValues: FilterValues | undefined,
) {
  const columnsWidthSum = useMemo(
    () =>
      columns.reduce(
        (sum, col) =>
          sum
          + (typeof col.width === 'number' ? col.width : col.minWidth || 250),
        0,
      ),
    [columns],
  );
  const [isTooWide, setIsTooWide] = useMountedState(false);
  const [currentTableWidth, setCurrentTableWidth] = useMountedState(0);

  const onResize = useCallback(
    (tableWidth: number | undefined) => {
      const next = tableWidth ? tableWidth < columnsWidthSum : false;
      setIsTooWide(next);
      setCurrentTableWidth(tableWidth ?? 0);
    },
    [setIsTooWide, columnsWidthSum],
  );
  const { ref: tableRef } = useResizeListener({
    handleHeight: false,
    onResize,
    refreshOptions: {
      trailing: true,
    },
  });

  useEffect(() => {
    onResize(currentTableWidth);
  }, [columnsWidthSum]);

  const columnsWithPopulatedProps = useMemo(
    () =>
      columns.map((column, index): TableColumnType<RecordType> => {
        const columnFields = { ...column };
        delete columnFields.sorting;
        const columnSorter = getSorter(column);
        const columnTitleMinWidth = getColumnTitleMinWidth(
          column.title,
          Boolean(column.infoTooltip),
          Boolean(columnSorter),
          Boolean(column.filterConfig),
        );
        const columnMinWidth = column.minWidth || column.width;
        const title = column.infoTooltip ? (
          <TitleWithTooltip
            title={column.title}
            description={column.infoTooltip}
          />
        ) : (
          column.title
        );

        return {
          ...columnFields,
          title,
          width: isTooWide
            ? columnMinWidth || columnTitleMinWidth
            : column.width,
          onHeaderCell: () => ({
            // This is used to allow changing the order of columns
            ...(column.onHeaderCell ? column.onHeaderCell(column) : {}),
            index,
          }),
          sortOrder:
            sorting && sorting.columnKey === column.key
              ? sorting.order
              : undefined,
          sorter: columnSorter,
          sortIcon({ sortOrder }) {
            if (!sortOrder) return <ArrowDownIcon className="actionIcon" />;
            return sortOrder === 'descend' ? (
              <ArrowUpIcon className={classNames('actionIcon', 'active')} />
            ) : (
              <ArrowDownIcon className={classNames('actionIcon', 'active')} />
            );
          },
          onCell: () => ({
            style: {
              width: isTooWide ? columnMinWidth : column.width,
              maxWidth: isTooWide ? columnMinWidth : column.width,
            },
          }),
          ...getAntdColumnFilterFields(
            column.filterConfig,
            filtersValues?.[column.key] ?? null,
          ),
        };
      }),
    [columns, isTooWide, addColumnIndexes, sorting, filtersValues],
  );

  return {
    columnsWithPopulatedProps,
    areColumnsTooWide: isTooWide,
    tableRef,
  };
}

function getAntdColumnFilterFields<RecordType>(
  filterConfig: FilterConfig<FilterValue> | undefined,
  filteredValue: FilterValue | FilterValue[] | null,
): Partial<TableColumnType<RecordType>> {
  if (!filterConfig) return {};
  return {
    filters: filterConfig.options?.getCurrentOptions()?.map(({ value, label }) => ({
      value,
      text: label,
    })),
    filterIcon: (filtered) => (
      <FilterIcon className={classNames('actionIcon', filtered && 'active')} />
    ),
    filterDropdown: (props) => (
      <TableFilterDropdown filterConfig={filterConfig} {...props} />
    ),
    filterMultiple: filterConfig.multiSelect,
    filteredValue:
      filteredValue === null || Array.isArray(filteredValue)
        ? filteredValue
        : [filteredValue],
  };
}
