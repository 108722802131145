import React, { ReactElement } from 'react';

import Tooltip from 'common-ui-components/Tooltip';
import { useNavbarContext } from 'screens/platform/cross-platform-components/Navbar/NavbarContext';

interface Props {
  children: ReactElement;
  disabled?: boolean;
  label: string;
}

export default function ScreenButtonTooltipWrapper({ children, disabled = false, label }: Props) {
  const { isNavbarMinimized } = useNavbarContext();

  return (
    <Tooltip content={label} placement="right" arrow={false} disabled={disabled || !isNavbarMinimized}>
      {children}
    </Tooltip>
  );
}
