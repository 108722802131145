import React from 'react';

import CustomFiltersBadges from 'common-ui-components/CustomFiltersBadges';
import SpacedBullet from 'common-ui-components/SpacedBullet';
import SingularPluralNouns, { getAmountLabel } from 'global/lists/SingularPluralNouns';
import { useCustomEventsFilters } from 'screens/platform/cross-platform-components/context/CustomFilters/CustomEventsFilters/CustomEventsFiltersContextProvider';
import { CustomEventsFiltersState } from 'screens/platform/cross-platform-components/context/CustomFilters/CustomEventsFilters/CustomEventsFiltersReducer';
import ObjectUtils from 'utils/ObjectUtils';

import style from 'screens/platform/cross-platform-components/EventsPanel/EventsSummary/style.module.scss';

export default function EventsSummary({ filteredEventsAmount }: { filteredEventsAmount: number }) {
  const { customEventsFilters, dispatchCustomEventsFilters } = useCustomEventsFilters();

  const eventsLabel = getAmountLabel(
    filteredEventsAmount,
    SingularPluralNouns.result.toLowerCase(),
  );

  function CustomBadges() {
    if (ObjectUtils.isEmpty(customEventsFilters)) {
      return null;
    }

    const onRemove = ((filterType, filterValue) => {
      const { eventsCategories } = customEventsFilters;
      if (filterType === 'eventsCategories' && eventsCategories && eventsCategories.length > 1) {
        const nextEventsCategories = eventsCategories
          .filter((category) => filterValue !== category);
        dispatchCustomEventsFilters({
          type: 'ADD_FILTERS',
          payload: {
            eventsCategories: nextEventsCategories,
          },
        });
      } else {
        dispatchCustomEventsFilters({ type: 'DELETE_FILTERS', payload: { type: filterType } });
      }
    });

    return (
      <>
        <SpacedBullet />
        <div className={style.badges}>
          <CustomFiltersBadges<CustomEventsFiltersState>
            customFilters={customEventsFilters}
            onRemove={onRemove}
          />
        </div>
      </>
    );
  }
  return (
    <span className={style.eventsSummary}>
      <span className={style.label}>{eventsLabel}</span>
      <CustomBadges />
    </span>
  );
}
