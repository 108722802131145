type BooleanGraphFeatures = {
  showNames: boolean;
  showImages: boolean;
  showClustersGraph: boolean;
  isClusterGraphEnabled: boolean;
  showDetachedNodes: boolean;
  showExternalTeamNodes: boolean;
};

export enum ClusterConnections {
  INSIDE,
  BETWEEN,
  BOTH,
}

export type GraphFeaturesKey = keyof BooleanGraphFeatures;

export type GraphFeaturesState = BooleanGraphFeatures & {
  volumeThreshold: number;
  significanceThreshold: number;
  clusterConnections: ClusterConnections;
  clusteringType: string;
};

export type GraphFeaturesReducerAction =
  | { type: 'SET_MULTIPLE_GRAPH_FEATURES'; payload: Partial<GraphFeaturesState> }
  | { type: 'SET_VOLUME_THRESHOLD'; payload: { newVolumeThreshold: number } }
  | {
      type: 'SET_SIGNIFICANCE_THRESHOLD';
      payload: { newSignificanceThreshold: number };
    }
  | { type: 'SET_CLUSTER_CONNECTIONS'; payload: { nextClusterConnections: ClusterConnections } }
  | { type: 'TOGGLE'; payload: { key: GraphFeaturesKey } }
  | { type: 'CHANGE_CLUSTERING_TYPE'; payload: { newType: string } };

const GraphFeaturesReducer = (
  state: GraphFeaturesState,
  action: GraphFeaturesReducerAction,
) => {
  switch (action.type) {
    case 'SET_MULTIPLE_GRAPH_FEATURES':
      return { ...state, ...action.payload };
    case 'SET_VOLUME_THRESHOLD':
      return { ...state, volumeThreshold: action.payload.newVolumeThreshold };
    case 'SET_SIGNIFICANCE_THRESHOLD':
      return {
        ...state,
        significanceThreshold: action.payload.newSignificanceThreshold,
      };
    case 'TOGGLE':
      return { ...state, [action.payload.key]: !state[action.payload.key] };
    case 'CHANGE_CLUSTERING_TYPE':
      return { ...state, clusteringType: action.payload.newType };
    case 'SET_CLUSTER_CONNECTIONS':
      return { ...state, clusterConnections: action.payload.nextClusterConnections };
    default:
      return state;
  }
};

export default GraphFeaturesReducer;
