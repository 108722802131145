import React, { useCallback } from 'react';

import {
  useMasterFilters,
} from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFiltersContext';
import NullableValuesSelection
  from 'screens/platform/cross-platform-components/context/MasterFiltersContext/NullableValuesSelection';
import OrganizationsMasterFilter
  from 'screens/platform/cross-platform-components/context/MasterFiltersContext/OrganizationsMasterFilter/index';
import FilterMenu from 'screens/platform/cross-platform-components/MasterFiltersPanel/FilterMenu';
import FilterCheckbox
  from 'screens/platform/cross-platform-components/MasterFiltersPanel/FilterSection/FilterCheckbox/index';
import OrganizationsHierarchicalSearchInputDropdown
  from 'screens/platform/cross-platform-components/MasterFiltersPanel/FilterSection/OrganizationsFilterSection/components/OrganizationsHierarchicalSearchInputDropdown';
import FilterWrapper from 'screens/platform/cross-platform-components/MasterFiltersPanel/FilterWrapper/index';

export default function OrganizationsFilterSection() {
  const { currentFilters: { organizations }, dispatchMasterFilters } = useMasterFilters();

  const toggleIncludeUndefinedOrganizations = useCallback(() => {
    const prevOrganizationsIds = organizations.organizationsIds;
    const nextOrganizationsFilter = new OrganizationsMasterFilter({
      ...organizations.clone(),
      organizationsIds: new NullableValuesSelection(
        prevOrganizationsIds.selected,
        !prevOrganizationsIds.includeUndefined,
      ),
    });
    dispatchMasterFilters({
      type: 'CHANGE_ORGANIZATIONS',
      payload: { nextOrganizations: nextOrganizationsFilter },
    });
  }, [organizations, dispatchMasterFilters]);

  return (
    <FilterWrapper filter={FilterMenu.ORGANIZATIONS}>
      <OrganizationsHierarchicalSearchInputDropdown
        initialFilter={organizations.clone()}
        onUpdate={(nextOrganizationsFilter) =>
          dispatchMasterFilters({ type: 'CHANGE_ORGANIZATIONS', payload: { nextOrganizations: nextOrganizationsFilter } })}
        allowEmptySelection
      />
      <FilterCheckbox
        checked={organizations.organizationsIds.includeUndefined}
        label="Include Unknown / No Organizations"
        hoverText="Show interactions without organizations, or with organizations that are unknown (free email providers)"
        onClick={toggleIncludeUndefinedOrganizations}
      />
    </FilterWrapper>
  );
}
