import React, { useEffect, useState } from 'react';

import Directionality from 'screens/platform/cross-platform-components/context/MasterFiltersContext/Directionality';
import HierarchicalSearchResults
  from 'screens/platform/cross-platform-components/HierarchicalSearchInput/components/HierarchicalSearchResults';
import {
  HierarchicalItemType,
} from 'screens/platform/cross-platform-components/HierarchicalSearchInput/HierarchicalItemTypeConfig';
import HierarchicalSearchInputWrapper
  from 'screens/platform/cross-platform-components/HierarchicalSearchInput/HierarchicalSearchInputWrapper';
import HierarchicalGroup from 'utils/HierarchicalDataStructures/HierarchicalGroup';
import { SelectionStatus } from 'utils/HierarchicalDataStructures/HierarchicalGroupInterface';

interface Props {
  selection: HierarchicalGroup;
  onClickDone: () => void;
  searchPlaceholder?: string;
  className?: string;
}

const GROUP_ID = 'ALL_INTERACTIONS_ID';

export default function DirectionalityHierarchicalSelection({
  selection,
  searchPlaceholder = 'Search',
  onClickDone,
  className,
}: Props) {
  const [disabled, setDisabled] = useState(false);
  const itemsByGroups = [{
    group: GROUP_ID,
    items: [Directionality.INTERNAL, Directionality.EXTERNAL],
  }];

  useEffect(() => {
    const subscriptionId = selection.subscribe((id, selectionStatus) => {
      setDisabled(selectionStatus === SelectionStatus.UNSELECTED);
    });
    return () => selection.unsubscribe(subscriptionId);
  }, [selection]);

  return (
    <HierarchicalSearchInputWrapper
      onClickDone={onClickDone}
      setSearchQuery={() => null}
      searchPlaceholder={searchPlaceholder}
      className={className}
      disabled={disabled}
      hideSearchInput
    >
      <HierarchicalSearchResults
        items={itemsByGroups}
        selection={selection}
        type={HierarchicalItemType.DIRECTIONALITY}
        initiallyOpen
      />
    </HierarchicalSearchInputWrapper>
  );
}
