import React from 'react';

import DepartmentBadge from 'common-ui-components/Badge/DepartmentBadge';
import SingularPluralNouns, { getAmountLabel } from 'global/lists/SingularPluralNouns';
import { EventType } from 'model/Events/Event';
import OrgStructureEvent from 'model/Events/OrgStructureEvent';

import style from 'screens/platform/cross-platform-components/EventsPanel/EventCell/EventTitle/style.module.scss';

interface Props {
  event: OrgStructureEvent;
}

export default function OrgStructureTitle({ event }: Props) {
  const { department, personsIds } = event;

  function getEventText() {
    switch (event.eventType) {
      case EventType.PERSON_OFFBOARDED:
        return 'offboarded';
      case EventType.PERSON_ONBOARDED:
        return 'onboarded';
      default:
        throw new Error(`Invalid org structure event type: ${event.eventType}`);
    }
  }

  return (
    <div className={style.title}>
      {`${getAmountLabel(personsIds.length, SingularPluralNouns.person.toLowerCase())} from the`}
      <DepartmentBadge department={department} />
      {`department have ${getEventText()} this month`}
    </div>
  );
}
