import classNames from 'classnames';
import React from 'react';

import Button from 'common-ui-components/Button';
import CSSVariableDefiner from 'common-ui-components/CSSVariableDefiner';
import ImageWithFallback from 'common-ui-components/ImageWithFallback';
import ImageTooltip from 'common-ui-components/MultiImages/ImageTooltip';
import Tooltip from 'common-ui-components/Tooltip';
import Size from 'global/lists/Size';
import { useGraphFeatures } from 'screens/platform/cross-platform-components/context/graphFeatures/GraphFeaturesContext';

import style from 'common-ui-components/MultiImages/NodeImage/style.module.scss';

export enum ImageShape {
  ROUND,
  SQUARE,
}

const nodeImageWrapperSizeClass: Record<Size.SMALL | Size.MEDIUM | Size.BIG, string> = {
  [Size.SMALL]: style.small,
  [Size.MEDIUM]: style.medium,
  [Size.BIG]: style.big,
};

export function getNodeImageWrapperClass(size: Size) {
  return classNames(style.nodeImageWrapper, nodeImageWrapperSizeClass[size]);
}

export interface NodeImageProps {
  imageUrl: string | null;
  subtitle: string;
  name: string;
  borderColor: string;
  shape?: ImageShape;
  transparent?: boolean;
  size: Size.SMALL | Size.MEDIUM | Size.BIG;
  hoverable?: boolean;
  onClick?: () => void;
  order?: number;
}

export default function NodeImage({
  imageUrl,
  subtitle,
  name,
  shape = ImageShape.ROUND,
  transparent = false,
  size,
  hoverable,
  onClick,
  borderColor,
  order = 0,
}: NodeImageProps) {
  const { showImages } = useGraphFeatures();

  const buttonClasses = classNames(
    !imageUrl && style.noImage,
    shape === ImageShape.ROUND ? style.round : style.square,
    style.button,
  );

  const OptionalButton = ({ children }) => (
    <Tooltip
      disabled={!hoverable}
      content={(
        <ImageTooltip
          name={name}
          subtitle={subtitle}
          highlightColor={borderColor}
          imageUrl={imageUrl}
          imageShape={shape}
        />
      )}
      interactive
    >
      { onClick ? (
        <Button
          className={classNames(buttonClasses)}
          onClick={onClick}
          style={{ zIndex: orderToZIndex(order) }}
        >
          { children }
        </Button>
      ) : (
        <div className={buttonClasses}>{ children }</div>
      ) }
    </Tooltip>
  );

  const imageClassNames = classNames(
    style.image,
    shape === ImageShape.ROUND ? style.roundImage : style.squareImage,
    size === Size.BIG && style.largeImage,
  );

  return (
    <CSSVariableDefiner variables={{ '--department-color': borderColor }}>
      <div className={getNodeImageWrapperClass(size)}>
        <OptionalButton>
          <div className={style.imageContainer}>
            {
              showImages && (
                <ImageWithFallback
                  className={imageClassNames}
                  imageUrl={imageUrl}
                  name={name}
                />
              )
            }
            {
              transparent && <div className={style.transparentOverlay} />
            }
          </div>
        </OptionalButton>
      </div>
    </CSSVariableDefiner>
  );
}

function orderToZIndex(order: number) {
  return Math.abs(order - 3);
}
