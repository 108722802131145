export default {
  take: <T> (arr: T[], n: number): T[] => (arr.length > n ? arr.slice(0, n) : arr),

  sortByFunc: <T, S> (mapFunc?: (item: T) => S) => (_a: T, _b: T) => {
    const [a, b] = [_a, _b].map((x) => (mapFunc ?? ((item) => item))(x));
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  },

  isEqual: (arr1: unknown[], arr2: unknown[]) => {
    if (arr1.length !== arr2.length) return false;
    return arr1.every((value) => arr2.includes(value));
  },

  getIntegers: (ticksAmount: number) => [...Array(ticksAmount).keys()],

  groupBy: <T extends Object>(arr: T[], getKey: (item: T) => string) =>
    arr.reduce((acc, x) => {
      (acc[getKey(x)] = acc[getKey(x)] || []).push(x);
      return acc;
    }, {} as Record<string, T[]>),

  isDefined: <T> (val: T | undefined | null): val is T => val !== undefined && val !== null,

  convertToObject: (arr: unknown[]) =>
    arr.reduce<Record<string, unknown>>((acc, element, index) => ({
      ...acc,
      [index]: element,
    }), {}),

  intersection<T>(arr1: T[], arr2: T[]): T[] {
    return Array.from(new Set(arr1.filter((value) => arr2.includes(value))));
  },

  isUnique<T>(item: T, index: number, arr: T[]): boolean {
    return arr.indexOf(item) === index;
  },

  isEmpty(array: unknown[]): boolean {
    return array.length === 0;
  },
};
