import React, { createContext, FC, useContext } from 'react';

import LoadingModal from 'common-ui-components/LoadingModal';
import { useMountedState } from 'utils/hooks';

interface GlobalLoadingContextType {
  isLoading: boolean;
  // eslint-disable-next-line no-unused-vars
  setFullScreenLoading: (boolean, string?) => void;
}
const GlobalLoadingContext = createContext<GlobalLoadingContextType>({} as any);

export const useGlobalLoading:
  () => GlobalLoadingContextType = () => useContext<GlobalLoadingContextType>(GlobalLoadingContext);

export const GlobalLoadingContextProvider: FC = ({ children }) => {
  const [isLoading, setIsGlobalLoading] = useMountedState(true);
  const [loadingMsg, setLoadingMsg] = useMountedState<string | undefined>(undefined);

  function setFullScreenLoading(nextIsLoading: boolean, nextLoadingMsg?: string) {
    setIsGlobalLoading(nextIsLoading);
    setLoadingMsg(nextLoadingMsg);
  }

  return (
    <GlobalLoadingContext.Provider value={{
      isLoading,
      setFullScreenLoading,
    }}
    >
      <LoadingModal isLoading={isLoading} text={loadingMsg} isFullScreen />
      { children }
    </GlobalLoadingContext.Provider>
  );
};
