import React from 'react';

import PrivacyButton from 'screens/platform/cross-platform-components/Navbar/components/BottomActions/PrivacyButton';
import UserProfile from 'screens/platform/cross-platform-components/Navbar/components/BottomActions/UserProfile';

import style from 'screens/platform/cross-platform-components/Navbar/components/BottomActions/style.module.scss';

export default function BottomActions() {
  return (
    <div className={style.bottomActions}>
      <PrivacyButton />
      <UserProfile />
    </div>
  );
}
