import React from 'react';

import Button from 'common-ui-components/Button';
import {
  useMasterFilters,
} from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFiltersContext';
import TotalInteractionsLabel
  from 'screens/platform/cross-platform-components/MasterFiltersPanel/MasterFiltersPanelHeader/TotalInteractionsLabel';
import SubscriptionButton from 'screens/platform/cross-platform-components/MasterFiltersPanel/SubscriptionButton';

import { ReactComponent as ClearFiltersIcon } from 'assets/img/icon/masterFilters/clear-filters.svg';

import style from 'screens/platform/cross-platform-components/MasterFiltersPanel/MasterFiltersPanelHeader/style.module.scss';

export default function MasterFiltersPanelHeader() {
  const { dispatchMasterFilters } = useMasterFilters();

  function clearAllFilters() {
    dispatchMasterFilters({ type: 'RESET_FILTERS' });
  }

  return (
    <div className={style.masterFiltersPanelHeader}>
      <div className={style.firstRow}>
        <h4>Filters</h4>
        <div className={style.actions}>
          <SubscriptionButton />
          <Button
            color="gray"
            onClick={clearAllFilters}
            title="Clear all filters"
          >
            <ClearFiltersIcon width={14} height={14} />
            Clear
          </Button>
        </div>
      </div>
      <TotalInteractionsLabel />
    </div>
  );
}
