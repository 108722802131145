import { OrganizationRisk, OrganizationSize } from 'model/Organization';

const UNSET = '-';

function valueToDisplayString<T extends string>(value: T | undefined, mapping: Record<T, string>) {
  return (value ? mapping[value] : undefined) ?? UNSET;
}

export function organizationSizeToDisplayString(size?: OrganizationSize) {
  return valueToDisplayString(size, {
    [OrganizationSize.VERY_SMALL]: 'Very Small',
    [OrganizationSize.SMALL]: 'Small',
    [OrganizationSize.MEDIUM]: 'Medium',
    [OrganizationSize.LARGE]: 'Large',
  });
}

export function organizationRiskToDisplayString(risk?: OrganizationRisk) {
  return valueToDisplayString(risk, {
    [OrganizationRisk.LOW]: 'Low',
    [OrganizationRisk.MEDIUM]: 'Medium',
    [OrganizationRisk.HIGH]: 'High',
  });
}
