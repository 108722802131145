import classNames from 'classnames';
import React from 'react';

import ConditionalButtonWrapper from 'common-ui-components/Checkbox/ConditionalButtonWrapper';

import style from 'common-ui-components/Checkbox/style.module.scss';

interface CheckboxProps {
  checked: boolean;
  label?: string;
  onClick?: (e: React.MouseEvent) => void;
  partiallyChecked?: boolean;
  className?: string;
  squareClassName?: string;
  disabled?: boolean;
  whiteBackground?: boolean;
  showTitle?: boolean;
  dataTestId?: string;
}

export default function Checkbox({
  label,
  onClick,
  checked,
  partiallyChecked = false,
  className,
  squareClassName,
  disabled = false,
  whiteBackground = false,
  showTitle = false,
  dataTestId,
}: CheckboxProps) {
  const onClickFunction = (e: React.MouseEvent<Element, MouseEvent>) => {
    if (!disabled && onClick) {
      onClick(e);
    }
  };
  return (
    <ConditionalButtonWrapper
      isButton={!disabled && Boolean(onClick)}
      onClick={onClickFunction}
      className={classNames(
        style.checkbox,
        className,
        disabled && style.disabled,
        partiallyChecked && style.partialCheck,
        whiteBackground && style.checkboxWhite,
      )}
    >
      <div className={style.checkboxWrapper}>
        <input
          type="checkbox"
          data-testid={`checkbox-${label ?? dataTestId}`}
          disabled={disabled}
          checked={checked}
          readOnly
        />
        <div className={classNames(style.square, squareClassName)} />
      </div>
      {label && <span title={showTitle ? label : undefined}>{label}</span>}
    </ConditionalButtonWrapper>
  );
}
