import React, { useMemo } from 'react';

import ExternalLink from 'common-ui-components/ExternalLink';
import ExternalLinkDescription from 'common-ui-components/ExternalLinkDescription';
import ExpandableCell, { EXTENDED_COLLAPSED_HEIGHT } from 'common-ui-components/Table/ExpandableCell';
import Tooltip from 'common-ui-components/Tooltip';
import Size from 'global/lists/Size';
import DomainImage from 'screens/platform/cross-platform-components/DomainImage';
import UrlUtils from 'utils/UrlUtils';

import style
  from 'screens/platform/cross-platform-components/Interactions/components/InteractionsTable/components/AdditionalLinks/style.module.scss';

export interface AdditionalLinksProps {
  links: string[];
}

export default function AdditionalLinks({
  links,
}: AdditionalLinksProps) {
  const domains = useMemo(() => links.map(UrlUtils.getDomain), [links]);

  return links.length === 0
    ? null
    : (
      <ExpandableCell collapsedHeight={EXTENDED_COLLAPSED_HEIGHT}>
        <div className={style.iconsContainer}>
          {
            links.map((link, index) => (
              <Tooltip
                key={link}
                content={(
                  <ExternalLinkDescription
                    url={link}
                    domain={domains[index]}
                  />
                )}
                interactive
                className={style.tooltip}
              >
                <ExternalLink
                  to={link}
                >
                  <DomainImage
                    domain={domains[index]}
                    className={style.image}
                    showTitle={false}
                    size={Size.MEDIUM}
                  />
                </ExternalLink>
              </Tooltip>
            ))
          }
        </div>
      </ExpandableCell>
    );
}
