enum Role {
  SUPERADMIN = 'ROLE_SUPERADMIN',
  INTEGRATIONADMIN = 'ROLE_INTEGRATIONADMIN',
  ADMIN = 'ROLE_ADMIN',
  ANALYST = 'ROLE_ANALYST',
  USER = 'ROLE_USER',
  RESTRICTED = 'ROLE_RESTRICTED',
  NUDGER = 'ROLE_NOTIFIER',
  GPT_SESSION_VIEWER = 'ROLE_GPT_SESSION_VIEWER',
}

export function roleFullLabelToRoleName(role: Role): string {
  return role.replace('ROLE_', '');
}

export default Role;
