import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import SecuredApp from 'global/authentication/SecuredApp';
import AppRouter from 'routers/AppRouter';
import ConnectorSuccessPage from 'screens/public/ConnectorSuccessPage';

export const CONNECTOR_SUCCESS_PAGE_PATH = '/public/connector/:appId/success';

/**
 * Handles pages that are accessible for both logged-in and logged-out users
 */
export default function GlobalRouter() {
  return (
    <BrowserRouter>
      <Switch>
        <Route
          exact
          path={CONNECTOR_SUCCESS_PAGE_PATH}
          render={() => <ConnectorSuccessPage />}
        />
        <SecuredApp>
          <AppRouter />
        </SecuredApp>
      </Switch>
    </BrowserRouter>
  );
}
