import { IconType } from 'antd/lib/notification/interface';
import FeedbackContext from 'common-ui-components/Feedback/FeedbackContext';
import { FeedbackType } from 'common-ui-components/Feedback/SingleFeedbackButton';
import Analytics, { AnalyticsEvent } from 'global/Analytics';
import FeedbackController from 'global/api/controller/FeedbackController';
import useTenantContext from 'screens/platform/cross-platform-components/context/tenant/TenantContext';
import DebuggerConsole from 'utils/DebuggerConsole';
import { useMountedState } from 'utils/hooks';
import ToastManager, { ToastKey } from 'utils/ToastManager';

function showToast(
  content: string,
  type: IconType,
) {
  ToastManager.show(content, { key: ToastKey.FEEDBACK, type });
}

export default function useFeedbackSender(feedbackContext: FeedbackContext) {
  const { tenant } = useTenantContext();

  const [loading, setLoading] = useMountedState(false);
  const [selected, setSelected] = useMountedState<FeedbackType | undefined>(undefined);

  async function sendFeedback(
    feedbackType: FeedbackType,
  ) {
    setLoading(true);
    setSelected(undefined);
    try {
      await FeedbackController.sendFeedback(tenant.id, feedbackContext.sessionId, feedbackType, '');
      setSelected(feedbackType);
      Analytics.trackEvent(
        feedbackType === 'LIKE' ? AnalyticsEvent.POSITIVE_FEEDBACK_CLICK : AnalyticsEvent.NEGATIVE_FEEDBACK_CLICK,
        feedbackContext,
      );
      showToast('Thank you for your feedback!', 'success');
    } catch (err) {
      showToast('Something went wrong. Please try again', 'error');
      DebuggerConsole.error(err);
    } finally {
      setLoading(false);
    }
  }

  return { loading, selected, sendFeedback };
}
