import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import SearchInput from 'es-src/screens/HomeScreen/components/EnterpriseSearch/SearchInput';
import SearchWithAutocomplete from 'es-src/screens/HomeScreen/components/SearchWithAutocomplete';
import EnterpriseSearchContextProvider
  from 'es-src/screens/HomeScreen/EnterpriseSearchContext/EnterpriseSearchContextProvider';
import ConfigurationKey from 'global/lists/ConfigurationKey';
import { getScreenPath } from 'global/ScreensConfiguration';
import { useConfiguration } from 'screens/platform/cross-platform-components/context/configuration/ConfigHook';
import {
  ENTERPRISE_SEARCH_QUESTION_KEY,
} from 'screens/platform/cross-platform-components/context/UrlParams/UrlParamsGenerator';

import style from 'screens/platform/PlatformScreen/PlatformSearch/style.module.scss';

export default function PlatformSearch() {
  const [searchQuery, setSearchQuery] = useState('');
  const history = useHistory();
  const { value: isAutocompleteEnabled } = useConfiguration(
    ConfigurationKey.IS_AUTOCOMPLETE_ENABLED,
    'boolean',
  );

  const updateUrlParams = () => {
    const urlParams = new URLSearchParams();
    urlParams.set(ENTERPRISE_SEARCH_QUESTION_KEY, searchQuery);
    history.push({
      pathname: getScreenPath('feed'),
      search: urlParams.toString(),
    });
  };

  return (
    <EnterpriseSearchContextProvider>
      <div className={style.platformSearch}>
        {isAutocompleteEnabled
          ? (
            <SearchWithAutocomplete
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              customSearchHandler={updateUrlParams}
              compact
            />
          )
          : (
            <SearchInput
              customSearchHandler={updateUrlParams}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              placeholder="Ask anything"
              compact
            />
          )}
      </div>
    </EnterpriseSearchContextProvider>
  );
}
