import React, {
  createContext, RefObject, useContext, useEffect, useRef, useState,
} from 'react';

const elementsKeys = [
  'commChartElement',
  'commChartWrapperElement',
] as const;
const initialDOMElements = elementsKeys.reduce((acc, key) => ({
  ...acc, [key]: { current: null },
}), {} as Record<typeof elementsKeys[number], RefObject<HTMLDivElement>>);

type DOMElements = typeof initialDOMElements;

interface DOMElementsContextType extends DOMElements {
  setElement: (refObject: Partial<DOMElements>) => void;
}

const DOMElementsContext = createContext<DOMElementsContextType>({} as any);
export function useDOMElements(): DOMElementsContextType {
  return useContext<DOMElementsContextType>(DOMElementsContext);
}

export function useGlobalElementSetter(
  refKey: typeof elementsKeys[number],
): RefObject<HTMLDivElement> {
  const ref = useRef(null);
  const { setElement } = useDOMElements();
  useEffect(() => setElement({ [refKey]: ref }), []);
  return ref;
}

export default function DOMElementsContextProvider({ children }) {
  const [DOMElements, setDOMElements] = useState<DOMElements>(initialDOMElements);

  function setElement(refObject: Partial<DOMElements>) {
    setDOMElements((prev) => ({ ...prev, ...refObject }));
  }

  return (
    <DOMElementsContext.Provider value={{ ...DOMElements, setElement }}>
      { children }
    </DOMElementsContext.Provider>
  );
}
