import Metric from 'global/lists/Metric';
import useMetricData
  from 'screens/platform/contentScreens/AnalyticsScreen/widgets/MetricsWidget/MetricCards/MetricCard/MetricDataHook';
import { useMasterFilters } from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFiltersContext';

export default function useTotalInteractionsCount(): number | null {
  const { currentFilters } = useMasterFilters();
  const totalInteractionsResponse = useMetricData(Metric.TOTAL_INTERACTIONS, currentFilters);
  const totalInteractions = totalInteractionsResponse.response?.value;
  return totalInteractions || null;
}
