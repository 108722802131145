import React from 'react';

import useAsync from 'react-use/lib/useAsync';
import { useMetadataContext } from 'screens/platform/cross-platform-components/context/metadata/MetadataContext';
import { getOrganizationName } from 'utils/OrganizationUtils';

type Props = {
  id: string;
};

export default function ParticipantName({ id }: Props) {
  const { persons, organizations } = useMetadataContext();

  const { value: name } = useAsync(async () => {
    const person = await persons.getById(id);
    if (person) {
      return person.name;
    }
    const organization = await organizations.getById(id);
    if (organization) {
      return getOrganizationName(organization);
    }
    return id;
  }, [id]);

  return name ? <>{name}</> : null;
}
