import React from 'react';

import Badge from 'common-ui-components/Badge';
import appsColors from 'global/lists/appsColors';
import getAppDisplayName from 'global/lists/appsNames';

import style from 'screens/platform/cross-platform-components/EventsPanel/EventCell/EventAppBadge/style.module.scss';

interface Props {
  appId: number;
}

export default function EventAppBadge({ appId }: Props) {
  return (
    <Badge
      text={getAppDisplayName(appId)}
      color={appsColors[appId]}
      className={style.appBadge}
      shouldFixLongText={false}
    />
  );
}
