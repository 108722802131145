import React from 'react';

import AppIcon from 'common-ui-components/AppIcon';
import App from 'global/lists/apps';
import Size from 'global/lists/Size';
import { SalesforceRecord } from 'model/Salesforce';

import style from 'common-ui-components/Sources/SourcePopoverContent/style.module.scss';

interface Props {
  salesforceRecord: SalesforceRecord;
}
export default function SalesforcePopoverContent({ salesforceRecord }: Props) {
  const {
    name, recordType,
  } = salesforceRecord;

  return (
    <div className={style.sourcePopoverContent}>
      <AppIcon
        appId={App.SALESFORCE}
        isPublic={false}
        hasBorder={false}
        size={Size.SMALL}
        mimeType={undefined}
      />
      <div className={style.sourceDetails}>
        <span className={style.title}>{name}</span>
        <span className={style.subheader}>{recordType}</span>
      </div>
    </div>
  );
}
