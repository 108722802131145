import { BarTooltipProps } from '@nivo/bar/dist/types/types';
import classNames from 'classnames';
import React, { useMemo } from 'react';

import ColorSquare from 'common-ui-components/ColorSquare';
import { useTooltipTitleByDate } from 'screens/platform/contentScreens/AnalyticsScreen/utils/TooltipTitle/TooltipTitleHooks';
import { DateRangeGranularity } from 'screens/platform/contentScreens/AnalyticsScreen/widgets/widgetConfig';
import { convertUserTimezoneToUTC } from 'utils/DateUtils';

import style from 'screens/platform/contentScreens/AnalyticsScreen/widgets/chartTooltipStyle.module.scss';

interface RowData {
  label: string;
  value: number;
  color: string;
}

interface Props extends BarTooltipProps<any> {
  tooltipsData: Record<string, RowData[]>;
  granularity: DateRangeGranularity;
}

export default function BriefBarChartTooltip({
  id, label, tooltipsData, granularity,
}: Props) {
  const getTitle = useTooltipTitleByDate(granularity);
  const dateAsString = useMemo(() => label.replace(`${id} - `, ''), [id, label]);
  const dailyData = tooltipsData[dateAsString];
  const date = convertUserTimezoneToUTC(new Date(Number(dateAsString)));
  const title = getTitle(date);

  function getValueLabel(rowData: RowData): string {
    return `${Math.round(rowData.value * 1000) / 10}%`;
  }

  return (
    <div className={classNames(style.tooltip, style.fixedTooltip)}>
      <div className={classNames(style.row, style.title)}>{title}</div>
      {
          dailyData.map((rowData) => {
            const isCurrentlyHovered = rowData.label === id;
            const className = classNames(style.label, isCurrentlyHovered && style.hovered);
            return (
              <div className={style.row} key={rowData.label}>
                <div className={style.left}>
                  <ColorSquare color={rowData.color} />
                  <span className={className}>
                    {rowData.label}
                  </span>
                </div>
                <div>
                  <span className={style.value}>{getValueLabel(rowData)}</span>
                </div>
              </div>
            );
          })
        }
    </div>
  );
}
