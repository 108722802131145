import React from 'react';

import PersonProfileImage from 'common-ui-components/MultiImages/ProfileImage/PersonProfileImage';
import Size from 'global/lists/Size';
import Person from 'model/Person';

import style from 'common-ui-components/Sources/SourcePopoverContent/style.module.scss';

interface Props {
  person: Person;
}
export default function PersonPopoverContent({ person }: Props) {
  const {
    name, department, isActive,
  } = person;

  return (
    <div className={style.sourcePopoverContent}>
      <PersonProfileImage person={person} size={Size.SMALL} />
      <div className={style.sourceDetails}>
        <div className={style.header}>
          <span className={style.title}>{name}</span>
          {!isActive && <span className={style.inactive}>inactive</span>}
        </div>
        <span className={style.subheader}>{department}</span>
      </div>
    </div>
  );
}
