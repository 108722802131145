import React from 'react';

import TagBadge from 'common-ui-components/Badge/TagBadge';
import HiddenItemsTooltip from 'common-ui-components/HiddenItemsTooltip';
import { ImageShape } from 'common-ui-components/MultiImages/NodeImage';
import useDynamicTagsWidth from 'common-ui-components/MultiTagsBadges/DynamicTagsWidthHook';
import SingularPluralNouns from 'global/lists/SingularPluralNouns';
import { useMetadataContext } from 'screens/platform/cross-platform-components/context/metadata/MetadataContext';

import style from 'common-ui-components/MultiTagsBadges/style.module.scss';

interface Props {
  tags: string[];
  singleRow?: boolean;
}

export default function MultiTagsBadges({ tags, singleRow = false }: Props) {
  const { getTagColors } = useMetadataContext();
  const { tagsWrapperRef, visibleTags, hiddenTags } = useDynamicTagsWidth(tags, singleRow);

  if (tags.length === 0) return null;

  return (
    <div className={style.tags} ref={singleRow ? tagsWrapperRef : undefined}>
      {visibleTags.map((tag) => <TagBadge key={tag} tag={tag} />)}
      {hiddenTags.length > 0 && (
        <HiddenItemsTooltip
          items={hiddenTags}
          colorMapper={(tag) => getTagColors(tag).uniqueColor}
          noun={SingularPluralNouns.tag}
          shape={ImageShape.SQUARE}
        />
      )}
    </div>
  );
}
