import React from 'react';

import Button from 'common-ui-components/Button';
import ExternalLink from 'common-ui-components/ExternalLink';

import style from 'screens/public/LoginPage/GetStartedHeader/style.module.scss';

export default function GetStartedHeader() {
  return (
    <div className={style.getStartedHeader}>
      Don&apos;t have an account?
      <Button color="gray" rounded className={style.getStartedBtn}>
        <ExternalLink to="https://akooda.co/learn-more">
          Get Started
        </ExternalLink>
      </Button>
    </div>
  );
}
