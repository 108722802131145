import React, { useMemo } from 'react';
import { Placement } from 'tippy.js';

import Dropdown, { DropdownProps } from 'common-ui-components/Dropdown';
import OrganizationsMasterFilterHierarchyGroup
  from 'screens/platform/cross-platform-components/context/MasterFiltersContext/OrganizationsMasterFilter/OrganizationsMasterFilterHierarchyGroup';
import PeopleMasterFilterHierarchyGroup
  from 'screens/platform/cross-platform-components/context/MasterFiltersContext/PeopleMasterFilter/PeopleMasterFilterHierarchyGroup';
import GenericHierarchicalSearchInput
  from 'screens/platform/cross-platform-components/HierarchicalSearchInput/GenericHierarchicalSearchInput';
import {
  HierarchicalItemType,
} from 'screens/platform/cross-platform-components/HierarchicalSearchInput/HierarchicalItemTypeConfig';
import {
  SearchableHierarchicalItemTypes,
} from 'screens/platform/cross-platform-components/HierarchicalSearchInput/HierarchicalSearchInputWrapper';
import DirectionalityHierarchicalSelection
  from 'screens/platform/cross-platform-components/MasterFiltersPanel/FilterSection/DirectionalityHierarchicalSelection';
import OrganizationsHierarchicalSearchInput from 'screens/platform/cross-platform-components/MasterFiltersPanel/FilterSection/OrganizationsFilterSection/components/OrganizationsHierarchicalSearchInput';
import PeopleHierarchicalSearchInput
  from 'screens/platform/cross-platform-components/MasterFiltersPanel/FilterSection/PeopleFilterSection/components/PeopleHierarchicalSearchInput';
import TagsHierarchicalSelection from 'screens/platform/cross-platform-components/MasterFiltersPanel/FilterSection/TagsHierarchicalSelection';
import HierarchicalGroup from 'utils/HierarchicalDataStructures/HierarchicalGroup';

import style
  from 'screens/platform/cross-platform-components/MasterFiltersPanel/FilterSection/HierarchicalSearchInputDropdown/style.module.scss';

type SelectionConfig = {
  type: Exclude<
    SearchableHierarchicalItemTypes,
    HierarchicalItemType.PERSON | HierarchicalItemType.ORGANIZATION
  >;
  selection: HierarchicalGroup;
} | {
  type: HierarchicalItemType.PERSON;
  selection: PeopleMasterFilterHierarchyGroup;
} | {
  type: HierarchicalItemType.ORGANIZATION;
  selection: OrganizationsMasterFilterHierarchyGroup | null;
}
export type HierarchicalSearchInputDropdownProps = {
  isDropdownOpen: boolean;
  setIsDropdownOpen: (value: boolean) => void;
  toggleButton: DropdownProps['toggleButtonElement'];
  searchPlaceholder?: string;
  allowEmptySelection?: boolean;
  placement?: Placement;
  showDepartmentsOnly?: boolean;
  enableFlipping?: boolean;
} & SelectionConfig;

export default function HierarchicalSearchInputDropdown({
  selection,
  type,
  isDropdownOpen,
  setIsDropdownOpen,
  toggleButton,
  searchPlaceholder = 'Search',
  allowEmptySelection = false,
  placement = 'right-start',
  showDepartmentsOnly = false,
  enableFlipping = false,
}: HierarchicalSearchInputDropdownProps) {
  const commonProps = {
    shouldClearSearchInput: !isDropdownOpen,
    searchPlaceholder,
    onClickDone: () => setIsDropdownOpen(false),
    allowEmptySelection,
  };

  const content = useMemo(() => {
    switch (type) {
      case HierarchicalItemType.PERSON:
        return (
          <PeopleHierarchicalSearchInput
            showDepartmentsOnly={showDepartmentsOnly}
            {...commonProps}
            selection={selection}
          />
        );
      case HierarchicalItemType.ORGANIZATION:
        return (
          <OrganizationsHierarchicalSearchInput
            {...commonProps}
            selection={selection}
          />
        );
      case HierarchicalItemType.DIRECTIONALITY:
        return (
          <DirectionalityHierarchicalSelection
            {...commonProps}
            selection={selection}
          />
        );
      case HierarchicalItemType.TAG:
        return (
          <TagsHierarchicalSelection
            {...commonProps}
            selection={selection}
          />
        );
      default:
        return (
          <GenericHierarchicalSearchInput
            {...commonProps}
            selection={selection}
            type={type}
          />
        );
    }
  }, [type, commonProps, showDepartmentsOnly]);

  return (
    <Dropdown
      dropdownClassName={style.dropdownContainer}
      toggleButtonElement={toggleButton}
      placement={placement}
      popperOptions={{ offset: -45, enableFlipping }}
      isDropdownOpen={isDropdownOpen}
      closeDropdown={() => setIsDropdownOpen(false)}
    >
      {content}
    </Dropdown>
  );
}
