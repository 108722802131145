import React from 'react';

import OrganizationProfileImage from 'common-ui-components/MultiImages/ProfileImage/OrganizationProfileImage';
import SourceBadge from 'common-ui-components/Sources/SourceBadge/index';
import OrganizationPopoverContent from 'common-ui-components/Sources/SourcePopoverContent/OrganizationPopoverContent';
import { EnrichedOrganizationSource } from 'es-src/screens/HomeScreen/EnterpriseSearchContext/EnrichedSourcesDataHook';
import Analytics, { AnalyticsEvent } from 'global/Analytics';
import Size from 'global/lists/Size';
import { useInternalScreenRedirect } from 'global/ScreensConfiguration';

interface Props {
  source: EnrichedOrganizationSource;
}

const OrganizationBadge = ({ source }: Props) => {
  const { type, data: organization } = source;
  const redirectToInternalScreen = useInternalScreenRedirect(true);

  if (!organization) return null;

  const analyticsPayload = {
    sourceType: type,
    sourceId: organization.id,
  };

  function handlePopoverHover() {
    Analytics.trackEvent(
      AnalyticsEvent.ES_SOURCE_TOOLTIP_USED,
      analyticsPayload,
    );
  }

  function handleSourceBadgeClick() {
    Analytics.trackEvent(
      AnalyticsEvent.ES_SOURCE_BADGE_CLICK,
      analyticsPayload,
    );
  }

  function handleClick() {
    redirectToInternalScreen(
      'platform.directory.organizations.profile',
      organization?.id,
    );
    handleSourceBadgeClick();
  }

  return (
    <SourceBadge
      type={type}
      redirect={handleClick}
      image={<OrganizationProfileImage organization={organization} size={Size.SMALL} />}
      title={organization.name ?? 'Unknown Organization'}
      popoverContent={<OrganizationPopoverContent organization={organization} />}
      handleSourceClick={handleSourceBadgeClick}
      handlePopoverHover={handlePopoverHover}
    />
  );
};

export default OrganizationBadge;
