import React from 'react';

import Button from 'common-ui-components/Button';
import Dropdown from 'common-ui-components/Dropdown';
import { useToggle } from 'utils/hooks';

import { ReactComponent as WarningIcon } from 'assets/img/icon/warning.svg';

import style from 'screens/settings/AppsScreen/components/ConnectorRow/components/InfoPopover/style.module.scss';

type Props = {
  title: string;
  content: string;
}

export default function InfoPopover({ title, content }: Props) {
  const [isOpen, toggle] = useToggle(false);

  return (
    <Dropdown
      toggleButtonElement={(
        <Button onClick={toggle} title="See warning" className={style.popoverButton}>
          <WarningIcon className={style.warningIcon} />
        </Button>
      )}
      isDropdownOpen={isOpen}
      placement="right"
      closeDropdown={toggle}
      dropdownClassName={style.dropdown}
      popperOptions={{ distance: 10 }}
    >
      <div className={style.infoPopover}>
        <section>
          <WarningIcon className={style.warningIcon} />
          <h3>
            {title}
          </h3>
          <span className={style.content}>
            {content}
          </span>
        </section>
        <footer>
          <Button color="blue" rounded onClick={toggle}>Close</Button>
        </footer>
      </div>
    </Dropdown>
  );
}
