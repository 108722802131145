import React from 'react';

import FilterConfig, {
  FilterValue,
} from 'screens/platform/directory/components/GenericDirectoryScreen/FiltersList/FilterConfig';
import GenericFilter from 'screens/platform/directory/components/ProfileTabs/components/Filters/GenericFilter';
import useNameFilterConfig
  from 'screens/platform/directory/people/PeopleDirectoryScreen/PeopleTable/utils/PeopleFilters/useNameFilterConfig';

interface Props {
  disabled?: boolean;
}

export default function PeopleFilter({ disabled }: Props) {
  const filterConfig = useNameFilterConfig() as FilterConfig<FilterValue>;
  return (
    <GenericFilter
      filterType="people"
      filterConfig={filterConfig}
      disabled={disabled}
    />
  );
}
