import Api from 'global/api/platformApi';
import { ListOptions } from 'global/ListOptions';
import useAsync from 'react-use/lib/useAsync';
import useTenantContext from 'screens/platform/cross-platform-components/context/tenant/TenantContext';
import { undefinedOption } from 'screens/platform/directory/components/GenericDirectoryScreen/FiltersList/filtersStateUtils';
import DebuggerConsole from 'utils/DebuggerConsole';

const useOrganizationFilterOptions = (): {
  stageFilterOptions: ListOptions<string> | null;
  segmentsFilterOptions: ListOptions<string> | null;
} => {
  const {
    tenant: { id: tenantId },
  } = useTenantContext();

  const loadingOptions = {
    stageFilterOptions: null,
    segmentsFilterOptions: null,
  };

  const convertToListOptions = (stringValues: string[]) =>
    [...stringValues.map((value) => ({ value, label: value })), undefinedOption];

  const { value: options } = useAsync(async () => {
    try {
      const response = await Api.Organization.findOrganizationFilterOptions(
        tenantId,
      );
      if (response) {
        return {
          stageFilterOptions: convertToListOptions(response.data.stages),
          segmentsFilterOptions: convertToListOptions(response.data.segments),
        };
      }
    } catch (error) {
      DebuggerConsole.error('Failed to fetch filterOptions', tenantId);
    }
    return loadingOptions;
  }, [tenantId]);

  return options ?? loadingOptions;
};

export default {
  useOrganizationFilterOptions,
};
