// eslint-disable-next-line import/no-extraneous-dependencies
import * as FullStory from '@fullstory/browser';
import { useEffect } from 'react';

import Role from 'global/lists/Role';
import useTenantContext from 'screens/platform/cross-platform-components/context/tenant/TenantContext';
import { useUserContext } from 'screens/platform/cross-platform-components/context/user/UserContext';
import DebuggerConsole from 'utils/DebuggerConsole';

function parseRoles(roles: Role[] | undefined | null): string {
  return roles
    ? roles.join(', ')
    : '';
}

const FULL_STORY_ORG_ID = '17W7V6';

// For Google Tag Manager monitoring purposes
export default function MonitoringDataPopulator() {
  const {
    name, roles, firebaseId,
  } = useUserContext();
  const { tenant } = useTenantContext();

  useEffect(() => {
    const data = {
      profile: {
        tenantId: tenant.id.toString(),
        tenantName: tenant.name,
        tenantSetupState: tenant.setupState,
        personId: undefined as string | undefined,
        personName: name,
        personRole: parseRoles(roles),
      },
    };

    if (firebaseId) {
      data.profile.personId = firebaseId;
      (document as any).akooda = data;
      (window as any).dataLayer.push({ event: 'profileDataIsPopulated' });

      FullStory.init(
        { orgId: FULL_STORY_ORG_ID },
        ({ sessionUrl }) => {
          DebuggerConsole.log(`Started session: ${sessionUrl}`);

          if (data.profile.personId) {
            FullStory.identify(data.profile.personId, {
              displayName: name ?? undefined,
              personName: name,
              personId: firebaseId,
              tenantId: tenant.id.toString(),
              tenantName: tenant.name,
              role: parseRoles(roles),
              tenantSetupState: tenant.setupState,
            });
          }
        },
      );
    }
  }, [tenant, name, roles, firebaseId]);

  return null;
}
