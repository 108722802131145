import Person from 'model/Person';
import useAsync, { AsyncState } from 'react-use/lib/useAsync';
import { useMetadataContext } from 'screens/platform/cross-platform-components/context/metadata/MetadataContext';
import ArrayUtils from 'utils/ArrayUtils';

export default function usePersonsMetadata(
  ids: string[] | undefined | null,
): AsyncState<Partial<Record<string, Person>> | undefined> {
  const { persons } = useMetadataContext();
  return useAsync(
    async () => {
      if (!ids) return undefined;

      const personsResponses = await Promise.all(ids.map(persons.getById));
      return personsResponses
        .filter(ArrayUtils.isDefined)
        .reduce((acc, person) => ({
          ...acc,
          [person.id]: person,
        }), {});
    },
    [JSON.stringify(ids ? [...ids].sort() : undefined)],
  );
}
