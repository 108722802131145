import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';

import ActionableBadge from 'common-ui-components/ActionableBadge';
import { MasterFilters } from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFilters';
import usePeopleFilterToHierarchyGroupConverter
  from 'screens/platform/cross-platform-components/context/MasterFiltersContext/PeopleMasterFilter/PeopleMasterFilterConversionUtils';
import usePeopleMasterFilterInitializers
  from 'screens/platform/cross-platform-components/context/MasterFiltersContext/PeopleMasterFilter/PeopleMasterFilterInitializersHook';
import {
  HierarchicalItemType,
} from 'screens/platform/cross-platform-components/HierarchicalSearchInput/HierarchicalItemTypeConfig';
import HierarchicalSearchInputDropdown
, {
  HierarchicalSearchInputDropdownProps,
} from 'screens/platform/cross-platform-components/MasterFiltersPanel/FilterSection/HierarchicalSearchInputDropdown';
import usePeopleHierarchyGroupToFilterConverter
  from 'screens/platform/cross-platform-components/MasterFiltersPanel/FilterSection/PeopleFilterSection/PeopleHierarchyGroupToFilterConverterHook';
import usePeopleLabel
  from 'screens/platform/cross-platform-components/MasterFiltersPanel/masterFiltersUtils/PeopleLabelHook';
import ObjectUtils from 'utils/ObjectUtils';

interface Props extends Partial<Omit<HierarchicalSearchInputDropdownProps, 'type'>> {
  initialFilter: Readonly<MasterFilters['people']>;
  onUpdate: (nextPeopleFilter: MasterFilters['people']) => void;
  selectedDepartments?: string[] | null;
  showDepartmentsOnly?: boolean;
}

export default function PeopleHierarchicalSearchInputDropdown(props: Props) {
  const {
    selectedDepartments: overriddenSelectedDepartments = undefined,
    showDepartmentsOnly = false,
    initialFilter,
    onUpdate,
  } = props;
  const { fromDepartments } = usePeopleMasterFilterInitializers();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const isFirstRender = useRef(true);

  const peopleFilter = useMemo(() => (showDepartmentsOnly ? fromDepartments(
    overriddenSelectedDepartments === undefined
      ? initialFilter.departments
      : overriddenSelectedDepartments,
  ) : initialFilter.clone()), [initialFilter, overriddenSelectedDepartments]);
  const label = usePeopleLabel(peopleFilter, showDepartmentsOnly);

  const peopleFilterAsGroup = usePeopleFilterToHierarchyGroupConverter(peopleFilter);

  const getPeopleSelection = usePeopleHierarchyGroupToFilterConverter();
  useEffect(() => { // Update people master filter when the dropdown is closed
    if (!isDropdownOpen && !isFirstRender.current) {
      const nextPeopleFilter = getPeopleSelection(peopleFilterAsGroup);
      if (!ObjectUtils.equals(nextPeopleFilter, peopleFilter)) {
        onUpdate(nextPeopleFilter);
      }
    }
    isFirstRender.current = false;
  }, [isDropdownOpen]);

  return (
    <HierarchicalSearchInputDropdown
      isDropdownOpen={isDropdownOpen}
      setIsDropdownOpen={setIsDropdownOpen}
      type={HierarchicalItemType.PERSON}
      toggleButton={(
        <ActionableBadge
          label={label}
          iconType="edit"
          onClick={() => setIsDropdownOpen((prev) => !prev)}
        />
      )}
      searchPlaceholder="Search for departments, teams or people"
      showDepartmentsOnly={showDepartmentsOnly}
      {...props}
      selection={peopleFilterAsGroup}
    />
  );
}
