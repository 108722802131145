import React, { useMemo } from 'react';

import Badge from 'common-ui-components/Badge';
import Spinner from 'common-ui-components/Spinner';
import ExpandableCell
  from 'common-ui-components/Table/ExpandableCell';
import Size from 'global/lists/Size';
import { Atom } from 'model/Atom';
import usePersonsMetadata from 'screens/platform/cross-platform-components/context/metadata/hooks/personsMetadataHook';
import useTenantContext from 'screens/platform/cross-platform-components/context/tenant/TenantContext';
import DebuggerConsole from 'utils/DebuggerConsole';

type Props = {
  participants: Atom['participants'];
};

export default function InteractionDepartments({ participants }: Props) {
  const { getDepartmentColor } = useTenantContext();

  const { value: persons, loading } = usePersonsMetadata(
    useMemo(
      () => Object.keys(participants),
      [participants],
    ),
  );

  const departments = useMemo(() => {
    if (loading) return [];

    const departmentsSet = Object.keys(participants).reduce((acc, participantId) => {
      const person = persons?.[participantId];
      if (person) {
        acc.add(person.department);
      } else {
        DebuggerConsole.error("Person ID doesn't exist", { participantId });
      }
      return acc;
    }, new Set<string>());
    return [...departmentsSet];
  }, [persons, loading]);

  if (loading) return <Spinner size={Size.MEDIUM} />;

  return departments.length > 0 ? (
    <ExpandableCell>
      {departments.map((departmentLabel) => (
        <Badge
          key={departmentLabel}
          text={departmentLabel}
          color={getDepartmentColor(departmentLabel)}
        />
      ))}
    </ExpandableCell>
  ) : null;
}
