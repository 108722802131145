enum Directionality {
  INTERNAL = 'INTERNAL',
  EXTERNAL = 'EXTERNAL'
}

export const directionalityBadgeLabelMapper: Record<Directionality, string> = {
  INTERNAL: 'Mentions',
  EXTERNAL: 'Engagements',
};

export default Directionality;
