/* eslint-disable react/jsx-filename-extension */

import React from 'react';
import ReactDOM from 'react-dom';

import Analytics from 'global/Analytics';
import Authentication from 'global/authentication/Authentication';
import initializeSentry from 'global/sentry';

import App from './App';

initializeSentry();
Authentication.initializeService();
Analytics.init();

ReactDOM.render(
  React.createElement(App),
  document.getElementById('root'),
);
