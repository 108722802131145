import classNames from 'classnames';
import React, {
  ChangeEvent, useEffect, useMemo, useRef, useState,
} from 'react';

import CSSVariableDefiner from 'common-ui-components/CSSVariableDefiner';
import { SignificanceColor, significanceColorsData } from 'global/lists/significanceColors';
import ArrayUtils from 'utils/ArrayUtils';

import style from 'common-ui-components/Scroller/sliderStyle.module.scss';

type InputElement = { min: number; max: number; value: number } & HTMLInputElement;

export enum Direction { LTR, RTL }

interface ScrollerProps {
  initialValue: number;
  onChange: Function;
  leftLabel: string;
  rightLabel: string;
  fireOnChangeOnlyOnMouseUp?: boolean;
  colored?: boolean;
  ticksAmount?: number;
  minValue?: number;
  maxValue?: number;
  direction?: Direction;
}

export default function Scroller({
  initialValue,
  onChange,
  colored,
  ticksAmount,
  leftLabel,
  rightLabel,
  fireOnChangeOnlyOnMouseUp = false,
  minValue = 5,
  maxValue = 100,
  direction = Direction.RTL,
}: ScrollerProps) {
  const isRTL = direction === Direction.RTL;
  const calcInitialValue = () => {
    const nextValue = Math.min(
      maxValue,
      Math.max(initialValue, minValue),
    );
    return (isRTL ? maxValue - nextValue : nextValue);
  };

  const [value, setValue] = useState(calcInitialValue());

  useEffect(() => setValue(calcInitialValue()), [maxValue, initialValue]);

  const ref = useRef<InputElement>(null);

  const onValueChange = (v: number) => {
    const nextValue = isRTL ? maxValue - v : v;
    onChange(Math.min(
      maxValue,
      Math.max(nextValue, minValue),
    ));
  };
  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    const v = Number(e.target.value);
    setValue(v);
    if (!fireOnChangeOnlyOnMouseUp) {
      onValueChange(v);
    }
  }

  const onMouseUp = () => {
    if (fireOnChangeOnlyOnMouseUp) {
      onValueChange(value);
    }
  };

  const thumbColor = useMemo(() => {
    if (colored && ticksAmount) {
      const colorIndex = (value * (ticksAmount - 1)) / maxValue;
      const currentColor = significanceColorsData[colorIndex]?.color;
      if (currentColor) return currentColor;
    }
    return 'var(--global-color-gray-2)';
  }, [value, colored, ticksAmount]);

  const scrollPercentage = useMemo(
    () =>
      (isRTL ? (value / maxValue) * 100 : 100 - (value / maxValue) * 100),
    [isRTL, value, maxValue],
  );
  return (
    <CSSVariableDefiner variables={{
      '--slider-color-1': significanceColorsData[SignificanceColor.LOWEST].color,
      '--slider-color-2': significanceColorsData[SignificanceColor.LOW].color,
      '--slider-color-3': significanceColorsData[SignificanceColor.MEDIUM].color,
      '--slider-color-4': significanceColorsData[SignificanceColor.HIGH].color,
      '--slider-color-5': significanceColorsData[SignificanceColor.HIGHEST].color,
      '--slider-thumb-color': thumbColor,
    }}
    >
      <div>
        <div className={classNames(style.slider, { [style.colored]: colored })}>
          <input
            type="range"
            value={value}
            min={0}
            max={maxValue}
            step={ticksAmount ? (100 / (ticksAmount - 1)) : undefined}
            onChange={handleChange}
            onMouseUp={onMouseUp}
            ref={ref}
            style={{ background: `linear-gradient(to ${isRTL ? 'right' : 'left'}, var(--global-color-gray-4) 0%, var(--global-color-gray-4) ${scrollPercentage}%, transparent ${scrollPercentage}%, transparent 100%)` }}
          />
          {
          ticksAmount && (
            <div className={style.tickMarksWrapper}>
              {ArrayUtils.getIntegers(ticksAmount).map((i) => (
                <div key={i} className={style.tickMark} />
              ))}
            </div>
          )
        }
        </div>
        <div className={style.legendLabels}>
          <span className={style.label}>{leftLabel}</span>
          <span className={style.label}>{rightLabel}</span>
        </div>
      </div>
    </CSSVariableDefiner>
  );
}
