import { useCallback } from 'react';

import { MetricValueResponse } from 'global/api/controller/MetricController';
import App, { appTypeToApps } from 'global/lists/apps';
import Metric, { getMetricConfig, getMetricDirectionality } from 'global/lists/Metric';
import { useInternalScreenRedirect } from 'global/ScreensConfiguration';
import Organization from 'model/Organization';
import {
  CustomAtomsFiltersRedirectState,
} from 'screens/platform/cross-platform-components/context/CustomFilters/CustomAtomsFilters/CustomAtomsFiltersContextProvider';
import Directionality from 'screens/platform/cross-platform-components/context/MasterFiltersContext/Directionality';
import { useMetadataContext } from 'screens/platform/cross-platform-components/context/metadata/MetadataContext';
import {
  OrganizationMetric,
} from 'screens/platform/directory/organizations/OrganizationsDirectoryScreen/OrganizationsTable/utils/OrganizationWithMetrics';

const metricsWithClickHandler: OrganizationMetric[] = [
  Metric.ORGANIZATION_TOTAL_ENGAGEMENTS,
  Metric.ORGANIZATION_TOTAL_MENTIONS,
  Metric.ORGANIZATION_TOTAL_CONVERSATIONS,
  Metric.ORGANIZATION_TOTAL_MEETINGS,
  Metric.ORGANIZATION_TIME_IN_MEETINGS_LAST_THREE_MONTHS,
  Metric.ORGANIZATION_TIME_IN_MEETINGS_LAST_SIX_MONTHS,
  Metric.ORGANIZATION_TIME_IN_MEETINGS_LAST_NINE_MONTHS,
  Metric.ORGANIZATION_TIME_IN_MEETINGS_LAST_TWELVE_MONTHS,
];

export default function useOrganizationMetricClickHandler(
  organizationId: Organization['id'],
  metric: OrganizationMetric,
  metricValue?: MetricValueResponse,
): (() => void) | null {
  const { isAppConnected } = useMetadataContext();
  const redirectToInternalScreen = useInternalScreenRedirect();

  const getAppsIds = (organizationMetric: OrganizationMetric): App[] => {
    const { appType } = getMetricConfig(organizationMetric);
    const appIds = appType ? appTypeToApps[appType] : [];
    return appIds.filter(isAppConnected);
  };

  const clickHandler = useCallback(() => {
    const directionality = getMetricDirectionality(metric);
    redirectToInternalScreen<CustomAtomsFiltersRedirectState>(
      'platform.directory.organizations.profile',
      organizationId,
      {
        customAtomsFilters: {
          directionality: (directionality === null)
            ? [Directionality.EXTERNAL, Directionality.INTERNAL]
            : [directionality],
          apps: getAppsIds(metric),
        },
      },
    );
  }, [metric, metricValue]);

  return metricsWithClickHandler.includes(metric) ? clickHandler : null;
}
