import React from 'react';

import { FilterDropdownProps } from 'antd/lib/table/interface';
import MultiSelectionFilter from 'common-ui-components/Table/TableFilterDropdown/MultiSelectionFilter';
import SingleSelectionFilter from 'common-ui-components/Table/TableFilterDropdown/SingleSelectionFilter';
import FilterConfig, {
  FilterValue,
} from 'screens/platform/directory/components/GenericDirectoryScreen/FiltersList/FilterConfig';

export type TableFilterDropdownProps = FilterDropdownProps & {
  filterConfig: FilterConfig<FilterValue>;
  hasDoneButton?: boolean;
};

export default function TableFilterDropdown(props: TableFilterDropdownProps) {
  const { multiSelect } = props.filterConfig;

  return multiSelect ? (
    <MultiSelectionFilter {...props} />
  ) : (
    <SingleSelectionFilter {...props} />
  );
}
