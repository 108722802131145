import { SliceTooltipProps } from '@nivo/line';
import { ReactNode, useMemo } from 'react';

import { useTooltipTitleByDate } from 'screens/platform/contentScreens/AnalyticsScreen/utils/TooltipTitle/TooltipTitleHooks';
import { DateRangeGranularity } from 'screens/platform/contentScreens/AnalyticsScreen/widgets/widgetConfig';
import ArrayUtils from 'utils/ArrayUtils';

const MAX_TOOLTIP_ROWS_AMOUNT = 10;

interface PointData {
  id: string;
  color: string;
  data: {
    y: number;
    date: Date;
    isAllInteractions?: boolean;
  };
}

interface LineChartTooltipData {
  title: ReactNode;
  points: PointData[];
  removedPoints: {
    amount: number;
    maxValue: number;
  } | null;
}

function selectTopValues(sortedPoints: PointData[], title: ReactNode): LineChartTooltipData {
  const maxRemovedValue = sortedPoints[MAX_TOOLTIP_ROWS_AMOUNT].data.y;
  const maxRemovedValuePointIndex = sortedPoints.findIndex((x) => x.data.y === maxRemovedValue);
  const areAllValuesRemoved = maxRemovedValuePointIndex === 0;
  const pointsAmount = areAllValuesRemoved ? MAX_TOOLTIP_ROWS_AMOUNT : maxRemovedValuePointIndex;
  return {
    title,
    points: ArrayUtils.take(
      sortedPoints,
      pointsAmount,
    ),
    removedPoints: {
      amount: sortedPoints.length - pointsAmount,
      maxValue: maxRemovedValue,
    },
  };
}

export default function useLineChartTooltipData(
  slice: SliceTooltipProps['slice'],
  granularity: DateRangeGranularity | undefined,
): LineChartTooltipData | null {
  const points = slice.points as unknown as PointData[];
  const date = points[0]?.data.date;
  const getTitle = useTooltipTitleByDate(granularity);
  const title = date ? getTitle(date) : null;

  return useMemo(
    () => {
      if (title === null) return null;

      const sortedPoints = points
        .sort((a, b) => {
          // We want All Interactions always be on top
          if (b.data.isAllInteractions) return 1;
          if (a.data.isAllInteractions) return -1;
          return b.data.y - a.data.y;
        });

      if (sortedPoints.length <= MAX_TOOLTIP_ROWS_AMOUNT) {
        return { title, points: sortedPoints, removedPoints: null };
      }

      return selectTopValues(sortedPoints, title);
    },
    [slice.points, granularity],
  );
}
