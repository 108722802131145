import { useMemo } from 'react';

import { SortingConfig } from 'common-ui-components/Table/sortingHook';
import { TableColumnsOrder } from 'common-ui-components/Table/TableTypes';
import PersonFilterableProperty from 'global/api/controller/utils/filtering/PersonFilterableProperty';
import { PersonCustomSortingConfig } from 'global/api/controller/utils/PersonControllerUtils';
import Metric from 'global/lists/Metric';
import { InternalDirectoryPerson } from 'model/Person';
import useOrganizationFeatureConfig
  from 'screens/platform/cross-platform-components/context/configuration/OrganizationFeatureConfigHook';
import { DirectoryTableColumn } from 'screens/platform/directory/directoryTableColumnsHook';
import { FilterConditionsMap } from 'screens/platform/directory/hooks/filterConditionsHook';

export enum PeopleDirectoryTableColumnId {
  NAME = 'Name',
  DEPARTMENT = 'Department',
  TEAMS = 'Teams',
  MANAGER = 'Manager',
  TOPICS = 'Topics',
  ORGANIZATIONS = 'Organizations',
  LAST_INTERACTION = 'Last Interaction',
  TIME_IN_MEETINGS_LAST_7_DAYS = 'Total time in meetings (last 7 days)',
  TIME_IN_MEETINGS_LAST_28_DAYS = 'Total time in meetings (last 28 days)',
  TIME_IN_MEETINGS_LAST_3_MONTHS = 'Total time in meetings (last 3 months)',
  TIME_IN_MEETINGS_LAST_6_MONTHS = 'Total time in meetings (last 6 months)',
  TIME_IN_MEETINGS_LAST_9_MONTHS = 'Total time in meetings (last 9 months)',
  TIME_IN_MEETINGS_LAST_12_MONTHS = 'Total time in meetings (last 12 months)',
}

export const sortablePeopleDirectoryColumns = [
  PeopleDirectoryTableColumnId.NAME,
  PeopleDirectoryTableColumnId.DEPARTMENT,
  PeopleDirectoryTableColumnId.TIME_IN_MEETINGS_LAST_7_DAYS,
  PeopleDirectoryTableColumnId.TIME_IN_MEETINGS_LAST_28_DAYS,
  PeopleDirectoryTableColumnId.TIME_IN_MEETINGS_LAST_3_MONTHS,
  PeopleDirectoryTableColumnId.TIME_IN_MEETINGS_LAST_6_MONTHS,
  PeopleDirectoryTableColumnId.TIME_IN_MEETINGS_LAST_9_MONTHS,
  PeopleDirectoryTableColumnId.TIME_IN_MEETINGS_LAST_12_MONTHS,
  PeopleDirectoryTableColumnId.LAST_INTERACTION,
];

export type PeopleDirectoryTableColumn<
  SortingConfig extends PersonCustomSortingConfig = PersonCustomSortingConfig,
> = DirectoryTableColumn<
  InternalDirectoryPerson,
  PeopleDirectoryTableColumnId | string,
  SortingConfig
>;

export type PeopleDirectoryTableColumnsOrder = TableColumnsOrder<
  PeopleDirectoryTableColumnId | string
>;

const defaultPersonTableSorting: SortingConfig<PersonCustomSortingConfig> = {
  columnKey: PeopleDirectoryTableColumnId.LAST_INTERACTION,
  order: 'descend',
  customConfig: {
    metricType: Metric.PERSON_LAST_INTERACTION,
  },
};

export function useDefaultPeopleTableColumnsOrder() {
  const { value: isOrganizationsFeatureActivated } = useOrganizationFeatureConfig();

  return useMemo<PeopleDirectoryTableColumnsOrder>(() => {
    const baseColumnsOrder: PeopleDirectoryTableColumnsOrder = {
      [PeopleDirectoryTableColumnId.NAME]: 0,
      [PeopleDirectoryTableColumnId.DEPARTMENT]: 1,
      [PeopleDirectoryTableColumnId.TOPICS]: 2,
      [PeopleDirectoryTableColumnId.LAST_INTERACTION]: 3,
    };
    if (isOrganizationsFeatureActivated) {
      baseColumnsOrder[PeopleDirectoryTableColumnId.ORGANIZATIONS] = 3;
      baseColumnsOrder[PeopleDirectoryTableColumnId.LAST_INTERACTION] = 4;
    }
    return baseColumnsOrder;
  }, [isOrganizationsFeatureActivated]);
}

export const peopleDirectoryDefaultValues: {
  sorting: SortingConfig<PersonCustomSortingConfig>;
  filterConditions: FilterConditionsMap<PersonFilterableProperty>;
} = {
  sorting: defaultPersonTableSorting,
  filterConditions: {},
};
