import classNames from 'classnames';
import React from 'react';

import { hasChannels } from 'global/lists/apps';
import { Atom } from 'model/Atom';
import useChannelDisplayName from 'screens/platform/cross-platform-components/Hook/ChannelDisplayNameHook';
import { formatDateRelativelyToNow } from 'utils/DateUtils';

import style from 'common-ui-components/InteractionTitle/InteractionSubtitle/style.module.scss';

interface Props {
  item: Atom;
  showUpdatedAt?: boolean;
}

const BULLET = '\u2022';

export default function InteractionSubtitle({
  item,
  showUpdatedAt = false,
}: Props) {
  const {
    id, channelName, isPublic, appId, end,
  } = item;

  const shouldShowSubtitle = hasChannels(appId) || showUpdatedAt;
  const shouldRenderSeparator = hasChannels(appId) && Boolean(channelName) && showUpdatedAt;
  const channel = useChannelDisplayName(appId, channelName, isPublic, id);

  const updatedDate = `Updated ${formatDateRelativelyToNow(end)}`;

  if (!shouldShowSubtitle) return null;

  return (
    <div className={style.subtitle}>
      {channel && (
        <div className={classNames(style.truncatedText, style.channel)}>
          {channel}
        </div>
      )}
      {shouldRenderSeparator && BULLET}
      {showUpdatedAt && (
        <div className={classNames(style.truncatedText, style.date)}>
          {updatedDate}
        </div>
      )}
    </div>
  );
}
