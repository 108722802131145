import {
  FeedbackType,
} from 'common-ui-components/Feedback/SingleFeedbackButton';
import { HttpResponse, postRequest } from 'global/api/platformApiHelpers';
import Tenant from 'model/Tenant';

const FeedbackController = {
  async sendFeedback(
    tenantId: Tenant['id'],
    sessionId: string,
    type: FeedbackType,
    content: string,
  ): HttpResponse<void> {
    return postRequest('feedback', {
      sessionId, type, content,
    }, { params: { tenantId } });
  },
};

export default FeedbackController;
