import { useMemo } from 'react';
import { useAsync } from 'react-use';

import { SortingConfig } from 'common-ui-components/Table/sortingHook';
import FilterCondition, { FilterFieldType } from 'global/api/controller/utils/filtering/FilterCondition';
import { IdFilterOperator } from 'global/api/controller/utils/filtering/IdFilterCondition';
import PersonFilterableProperty from 'global/api/controller/utils/filtering/PersonFilterableProperty';
import { StringFilterOperator } from 'global/api/controller/utils/filtering/StringFilterCondition';
import { PersonCustomSortingConfig } from 'global/api/controller/utils/PersonControllerUtils';
import PersonSortableProperty from 'global/api/controller/utils/PersonSortableProperty';
import Api from 'global/api/platformApi';
import { ListOptions } from 'global/ListOptions';
import { useMetadataContext } from 'screens/platform/cross-platform-components/context/metadata/MetadataContext';
import useTenantContext from 'screens/platform/cross-platform-components/context/tenant/TenantContext';
import FilterConfig, { DynamicListOptions } from 'screens/platform/directory/components/GenericDirectoryScreen/FiltersList/FilterConfig';
import { PersonFieldKey } from 'screens/platform/directory/utils/PersonField';
import { FieldType } from 'screens/platform/directory/utils/RequestedField';

const MAX_PEOPLE_NAMES = 1000;

const useNameFilterConfig = (): FilterConfig<string> => {
  const peopleIdNamePairs = usePeopleIdNamePairs(MAX_PEOPLE_NAMES);
  const { tenant } = useTenantContext();

  const nameOptions: ListOptions<string> = useMemo(
    () =>
      Object.entries(peopleIdNamePairs).map(([id, name]) => ({
        value: id,
        label: name,
      })),
    [peopleIdNamePairs],
  );

  return useMemo(
    () => ({
      label: 'Name',
      options: createDynamicFilterOptions(nameOptions, tenant.id, MAX_PEOPLE_NAMES),
      multiSelect: true,
      removable: true,
      defaultValue: [],
      toFilterCondition: (value) => ({
        fieldName: PersonFilterableProperty.ID,
        fieldType: FilterFieldType.ID,
        operator: IdFilterOperator.IS_ANY_OF,
        value,
      }),
    }),
    [nameOptions],
  );
};

const usePeopleIdNamePairs = (limit: number) => {
  const { persons: { allPeopleMetadata, getById } } = useMetadataContext();

  const { value: peopleIdNamePairs } = useAsync(async () => {
    const ids = Object.keys(allPeopleMetadata).slice(0, limit);
    const people = await Promise.all(ids.map(async (id) => getById(id)));
    const nameIds: Record<string, string> = {};
    for (const person of people) {
      if (person) {
        nameIds[person.id] = person.name;
      }
    }
    return nameIds;
  });

  return peopleIdNamePairs || {};
};

const createDynamicFilterOptions = (
  nameOptions: ListOptions<string>,
  tenantId: number,
  limit: number,
) => new DynamicListOptions(
  nameOptions,
  async (searchQuery: string) => {
    const sortingConfig: SortingConfig<PersonCustomSortingConfig> = {
      columnKey: PersonSortableProperty.NAME,
      order: 'ascend',
      customConfig: {
        personProperty: PersonSortableProperty.NAME,
      },
    };
    const pagingConfig = {
      skip: 0,
      limit,
    };
    const filterConditions: FilterCondition<PersonFilterableProperty>[] = [{
      fieldName: PersonFilterableProperty.NAME,
      fieldType: FilterFieldType.STRING,
      operator: StringFilterOperator.CONTAINS,
      value: searchQuery,
    }];
    const requestedFields = [
      { key: PersonFieldKey.NAME, type: FieldType.ORGANIC },
    ];
    const result = await Api.Person.getInternalPeople(tenantId, searchQuery, {
      sortingConfig,
      pagingConfig,
      filterConditions,
      requestedFields,
    });
    if (result?.data) {
      return result.data.people.map((person) => ({
        value: person.id,
        label: person.name,
      }));
    }
    return [];
  },
);

export default useNameFilterConfig;
