import differenceInDays from 'date-fns/differenceInDays';
import isSameDay from 'date-fns/isSameDay';
import App, { hasChannels } from 'global/lists/apps';
import HierarchicalMasterFilter
  from 'screens/platform/cross-platform-components/context/MasterFiltersContext/HierarchicalMasterFilter';
import {
  DatesRange,
  MasterFilters,
  MasterFiltersApiRequest,
  TimePeriod,
} from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFilters';
import {
  getDefaultDirectionality,
} from 'screens/platform/cross-platform-components/context/MasterFiltersContext/utils/DefaultMasterFiltersUtils';
import {
  isAppIdentified,
} from 'screens/platform/cross-platform-components/MasterFiltersPanel/FilterSection/AppsFilterSection/AppsFilterSectionUtils';
import {
  isGithubSubDivision,
} from 'screens/platform/cross-platform-components/MasterFiltersPanel/masterFiltersUtils/GithubChannelsUtils';
import {
  isSlackSubDivision, SlackSubDivisionId,
} from 'screens/platform/cross-platform-components/MasterFiltersPanel/masterFiltersUtils/SlackChannelsUtils';
import { convertUTCToUserTimezone } from 'utils/DateUtils';
import DebuggerConsole from 'utils/DebuggerConsole';

export function getTimePeriodInDays({ from, to }: DatesRange): TimePeriod<'days'> {
  return {
    duration: differenceInDays(to, from),
    endDate: isSameDay(new Date(), convertUTCToUserTimezone(to))
      ? null
      : to.getTime(),
    resolution: 'days',
  };
}

function convertDirectionalityFilter(directionalityAsArray: MasterFilters['directionality']): MasterFiltersApiRequest['directionality'] {
  switch (directionalityAsArray.length) {
    case 1: return directionalityAsArray[0];
    case 2: return null;
    default: break;
  }

  DebuggerConsole.error('Directionality array has invalid elements count', directionalityAsArray);
  return getDefaultDirectionality()[0];
}

function checkIsSlackChannelSelected(appsMasterFilter: HierarchicalMasterFilter): boolean {
  return Object.values(SlackSubDivisionId)
    .some((slackSubDivisionId) => {
      const slackChannelsGroup = appsMasterFilter.getChild(slackSubDivisionId);
      if (slackChannelsGroup?.isParent()) {
        return slackChannelsGroup.isPartiallySelected;
      }
      return false;
    });
}

function convertAppsFilter(appsMasterFilter: HierarchicalMasterFilter): MasterFiltersApiRequest['channelsByApps'] {
  const appsIgnoringBots = appsMasterFilter.clone();
  const isSlackChannelSelected = checkIsSlackChannelSelected(appsMasterFilter);
  if (isSlackChannelSelected) {
    appsIgnoringBots.getChild(SlackSubDivisionId.SLACK_BOT_CHANNELS)?.select();
  }

  const appsMap = appsIgnoringBots.getSelectionMap();
  if (appsMap === null) return appsMap;

  type DefinedMap = Exclude<MasterFiltersApiRequest['channelsByApps'], null>;
  return Object.entries(appsMap).reduce<DefinedMap>((acc, [appId, items]) => {
    function addCustomChannelsItemsToAppKey(_appId: App) {
      const previousItems = acc[_appId] ?? [];
      acc[_appId] = [...previousItems, ...items];
    }

    if (isSlackSubDivision(appId)) {
      addCustomChannelsItemsToAppKey(App.SLACK);
    } else if (isGithubSubDivision(appId)) {
      addCustomChannelsItemsToAppKey(App.GITHUB);
    } else {
      acc[appId] = !isAppIdentified(appId) || hasChannels(appId)
        ? items
        : [appId];

      if (!hasChannels(appId) || appsIgnoringBots.getChild(appId)?.isSelected) {
        acc[appId] = null; // App has no channels, or all channels are selected
      }
    }

    return acc;
  }, {});
}

export default function convertMasterFilters(
  masterFilters: MasterFilters,
  includeCommunicationOutsideFilteredGroup: boolean,
): MasterFiltersApiRequest {
  const {
    datesRange, categories, people, apps, id, name, tenantId, creatorPersonId, organizations,
  } = masterFilters;
  const timePeriod = getTimePeriodInDays(datesRange);

  const categoriesMap = categories.tagsByCategories.getSelectionMap();
  const appsMap = convertAppsFilter(apps);
  const directionality = convertDirectionalityFilter(masterFilters.directionality);

  return {
    id: id || '',
    name: name || '',
    timePeriod,
    categories: {
      tagsByCategories: categoriesMap,
      includeUntagged: categories.includeUntagged,
    },
    channelsByApps: appsMap,
    people,
    organizations: {
      selectedOrganizationsIds: organizations.organizationsIds,
      selectedOrganizationsRisks: organizations.selectedRisks,
      selectedOrganizationsSizes: organizations.selectedSizes,
      selectedOrganizationsSegments: organizations.selectedSegments,
    },
    directionality,
    includeCommunicationOutsideFilteredGroup,

    _tid: tenantId,
    creatorPersonId,
  };
}
