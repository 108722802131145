import { useCallback } from 'react';

import format from 'date-fns/format';
import { TimelineResponseDatum } from 'global/api/controller/TimelineController';
import colors from 'global/colors';
import { ALL_INTERACTIONS } from 'global/labels';
import appsColors from 'global/lists/appsColors';
import getAppDisplayName from 'global/lists/appsNames';
import { Orientation } from 'model/Atom';
import getChannelColor from 'screens/platform/contentScreens/AnalyticsScreen/utils/ChannelUtils';
import {
  VolumeTimelineChartDataType,
} from 'screens/platform/contentScreens/AnalyticsScreen/widgets/VolumeTimelineWidget/dataFetching/VolumeTimelineChartDataFetcherHook';
import { GroupType } from 'screens/platform/contentScreens/AnalyticsScreen/widgets/widgetConfig';
import { useMetadataContext } from 'screens/platform/cross-platform-components/context/metadata/MetadataContext';
import useTenantContext from 'screens/platform/cross-platform-components/context/tenant/TenantContext';
import ColorUtils from 'utils/ColorUtils';
import DateUtils, { convertUserTimezoneToUTC } from 'utils/DateUtils';

const orientationColors: Record<Orientation, string> = {
  [Orientation.INTERNAL]: '#B15AF2',
  [Orientation.INBOUND]: '#AFE135',
  [Orientation.OUTBOUND]: '#27D2B1',
};

enum OrganizationDirectionality {
  MENTIONS = 'MENTIONS',
  ENGAGEMENTS = 'ENGAGEMENTS'
}

const directionalityColors: Record<OrganizationDirectionality, string> = {
  [OrganizationDirectionality.MENTIONS]: orientationColors.INTERNAL,
  [OrganizationDirectionality.ENGAGEMENTS]: orientationColors.OUTBOUND,
};

function useDataSetColorCalculator(grouping: GroupType | null) {
  const { getDepartmentColor } = useTenantContext();
  const { getCategoryColor, getTagColors, channels } = useMetadataContext();

  return useCallback(async (dataSet: TimelineResponseDatum) => {
    if (dataSet.id === null) return colors.DARK_BLUE;

    switch (grouping) {
      case GroupType.CATEGORIES: return getCategoryColor(dataSet.id);
      case GroupType.DEPARTMENTS: return getDepartmentColor(dataSet.id);
      case GroupType.TAGS: return getTagColors(dataSet.id).uniqueColor;
      case GroupType.APPS: return appsColors[dataSet.id] || colors.DARK_GRAY;
      case GroupType.CHANNELS: return getChannelColor(await channels.getById(dataSet.id));
      case GroupType.ORGANIZATIONS: return ColorUtils.convertStringToColor(dataSet.id);
      case GroupType.ORIENTATION: return orientationColors[dataSet.id];
      case GroupType.ORGANIZATION_ACTIVITY: return directionalityColors[dataSet.id];
      default: return colors.DARK_GRAY;
    }
  }, [grouping]);
}

function useResponseDataParser(
  grouping: GroupType | null,
): (data: TimelineResponseDatum[]) => Promise<VolumeTimelineChartDataType[]> {
  const getDataSetColor = useDataSetColorCalculator(grouping);
  const { channels } = useMetadataContext();

  const getId = useCallback(async (dataSet: TimelineResponseDatum) => {
    if (!dataSet.id) return ALL_INTERACTIONS;

    if (grouping === GroupType.APPS) {
      return getAppDisplayName(dataSet.id);
    }

    const channel = await channels.getById(dataSet.id);
    if (grouping === GroupType.CHANNELS && channel) {
      const channelName = channel.name;
      const appName = getAppDisplayName(dataSet.id);
      return channelName + (appName ? ` (${appName})` : '');
    }

    return dataSet.id;
  }, [grouping]);

  return useCallback((data: TimelineResponseDatum[]) => Promise.all(data.map(async (dataSet) => ({
    id: await getId(dataSet),
    color: await getDataSetColor(dataSet),
    data: dataSet.data.map((datum) => {
      const date = convertUserTimezoneToUTC(new Date(datum.date));
      return {
        x: format(date, DateUtils.DateFormat.YEAR_MONTH_DAY_HOUR),
        y: datum.volume,
        date,
        isAllInteractions: !dataSet.id,
      };
    }),
  }))), [grouping]);
}

function trimLeftSideOfChartData(
  data: VolumeTimelineChartDataType[],
): VolumeTimelineChartDataType[] {
  let firstNonEmptyDayIndex: null | number = null;
  data.forEach((dataSet) => {
    for (let i = 0; i < dataSet.data.length; i += 1) {
      if (dataSet.data[i].y > 0) {
        firstNonEmptyDayIndex = firstNonEmptyDayIndex === null
          ? i
          : Math.min(firstNonEmptyDayIndex, i);
        break;
      }
    }
  });

  if (firstNonEmptyDayIndex === null) return data;

  return data.map((dataSet) => ({
    ...dataSet,
    data: dataSet.data.slice(
      firstNonEmptyDayIndex! >= 1 ? firstNonEmptyDayIndex! - 1 : 0,
    ),
  }));
}

const VolumeTimelineChartDataFetcherUtils = {
  useResponseDataParser,
  trimLeftSideOfChartData,
};

export default VolumeTimelineChartDataFetcherUtils;
