enum VolumeLabel {
  LOWEST = 'Very Low',
  LOW = 'Low',
  MEDIUM = 'Medium',
  HIGH = 'High',
  HIGHEST = 'Very High',
}

export const getVolumeLabelByPercentage = (volumePercentage: number): string => {
  // volumePercentage is between 0 and 100
  const {
    LOWEST, LOW, MEDIUM, HIGH, HIGHEST,
  } = VolumeLabel;
  const labelArray = [LOWEST, LOW, MEDIUM, HIGH, HIGHEST];
  const index = Math.min(4, Math.floor(volumePercentage / 20));
  return labelArray[index] || LOWEST;
};

export const getVolumeLabelByIndex = (volumeIndex: number): string =>
  Object.values(VolumeLabel)[volumeIndex];
