/* eslint-disable import/prefer-default-export */
import {
  Dispatch,
  MutableRefObject,
  RefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { useLocation } from 'react-router-dom';

import MouseEvents from 'global/lists/MouseEvents';
import { Props as ResizeDetectorProps } from 'react-resize-detector/build/ResizeDetector';
import DebuggerConsole from 'utils/DebuggerConsole';

export function useOutsideAlerter(
  ref: RefObject<any>,
  callback: Function,
  additionalRef?: RefObject<any>,
) {
  useEffect(() => {
    detectIframeClick(callback);

    function handleClickOutside(event) {
      const isClickOutsideRef = ref.current && !ref.current.contains(event.target);
      const isClickInsideAdditionalRef = additionalRef?.current
        && additionalRef.current.contains(event.target);

      if (isClickOutsideRef && !isClickInsideAdditionalRef) {
        callback();
      }
    }

    document.addEventListener(MouseEvents.DOWN, handleClickOutside);
    return () => {
      document.removeEventListener(MouseEvents.DOWN, handleClickOutside);
    };
  }, [ref, callback]);
}

function detectIframeClick(callback: Function) {
  window.focus();

  window.addEventListener('blur', () => {
    setTimeout(() => {
      if (document.activeElement && document.activeElement.tagName === 'IFRAME') {
        callback();
      }
    });
  }, { once: true });
}

export function useKeystrokesListener(
  keysCallbacks: Record<string, (event?: KeyboardEvent) => any>,
  inputRef?: RefObject<HTMLInputElement | HTMLTextAreaElement>,
) {
  useEffect(() => {
    function handleKeyboardPress(event: KeyboardEvent) {
      const callback = keysCallbacks[event.key];
      const eventTargetTagName = (event.target as Element).tagName.toLowerCase();

      const isTextElementFocused = ['input', 'textarea'].includes(eventTargetTagName);
      const isRefMatching = inputRef !== undefined || !isTextElementFocused;

      if (callback !== undefined && isRefMatching) {
        DebuggerConsole.log('Listening to keyboard event', { key: event.key });
        callback(event);
      }
    }

    const subscriber = inputRef === undefined ? window : inputRef.current;
    subscriber?.addEventListener('keydown', handleKeyboardPress as any);
    return () => {
      subscriber?.removeEventListener('keydown', handleKeyboardPress as any);
    };
  }, [keysCallbacks]);
}

export function useToggle(initialValue: boolean = false): [boolean, () => void] {
  const [value, setValue] = useState<boolean>(initialValue);
  const toggle = () => setValue((prev) => !prev);
  return [value, toggle];
}

/**
 * The returned `isMounted` object can indicate whether the component is mounted or not.
 * Remember to use `isMounted.current`, as this is a RefObject.
 * @return {{ current: boolean }} Reference containing the mounting state
 */
export function useMountedStatus(): MutableRefObject<boolean> {
  const isMounted = useRef(true);
  function onUnmount() {
    isMounted.current = false;
  }
  useEffect(() => onUnmount, []);
  return isMounted;
}

/**
 * Intended to be used in search bars, this hook prevents race conditions
 * and makes sure the most updated result is displayed.
 * @param searchQuery {string} The query that's being searched
 * @param setData {Function} The setter that updates the fetched data
 * @param asyncFetch {Function} The data fetching function
 * @param {Array} [dependencies=[]] - Additional dependencies to trigger the effect.
 */
export function useAsyncFetchingByQuery<T>(
  searchQuery: string,
  setData: Dispatch<SetStateAction<T | null>>,
  asyncFetch: (_query: string) => Promise<{ data: T } | null>,
  dependencies: any[] = [],
) {
  const debouncedSearchQuery = useDebounce(searchQuery);
  const currSearchQuery = useRef(debouncedSearchQuery);
  const isMounted = useMountedStatus();

  function setDataIfMounted(newData: T | null) {
    if (isMounted.current) setData(newData);
  }

  useEffect(() => {
    async function asyncFetchThenSet() {
      try {
        const recentSearchQuery = currSearchQuery.current;
        const result = await asyncFetch(currSearchQuery.current);

        if (recentSearchQuery !== currSearchQuery.current) {
          await asyncFetchThenSet();
        } else if (result?.data) {
          setDataIfMounted(result.data);
        }
      } catch (err) {
        setDataIfMounted(null);
      }
    }

    currSearchQuery.current = debouncedSearchQuery;
    setDataIfMounted(null);
    asyncFetchThenSet();
  }, [debouncedSearchQuery, ...dependencies]);
}

/**
 * A hook that generates and validates everything that's necessary in order to create a reducer.
 * It wraps the weirdly-typed useReducer, to provide a more readable interface
 * @param reducer
 * @param initialState
 */
export function useCustomReducer<State, Action extends { type: string; payload?: any }>(
  reducer: (state: State, action: Action) => State,
  initialState: State,
): [State, Dispatch<Action>] {
  return useReducer(reducer, initialState);
}

export function useDebounce<T>(value: T, delay = 500): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
}

export function useDebouncedString(
  defaultValue?: string,
): [string, Dispatch<SetStateAction<string>>] {
  const [value, setValue] = useState(defaultValue ?? '');
  const debouncedValue = useDebounce(value);
  return [debouncedValue, setValue];
}

/**
 * A wrapper to React's useState that only updates the state if the component is still mounted,
 * in order to prevent potential memory leaks.
 *
 * @param initialState
 */
export function useMountedState<T>(initialState: T): [T, (newValue: T | ((prev: T) => T)) => void] {
  const [value, setValue] = useState(initialState);
  const isMounted = useMountedStatus();

  const setter = useCallback<(newValue: T | ((prev: T) => T)) => void>((newValue) => {
    if (isMounted.current) setValue(newValue);
  }, [setValue]);

  return [value, setter];
}

/**
 * As useResizeDetector triggers "ResizeObserver loop limit exceeded" errors,
 * this wrapper hook is a workaround to prevent those errors.
 * @see https://github.com/maslianok/react-resize-detector/issues/45
 */
export function useResizeListener(props?: ResizeDetectorProps) {
  return useResizeDetector({
    refreshMode: 'debounce',
    refreshRate: 0,
    ...props,
  });
}

export function useDebouncedAsyncCall<Args extends any[], Result>(
  asyncCall: (...args: Args) => Promise<Result>,
  debounceTime = 1000,
): (...args: Args) => Promise<Result> {
  const timeoutId = useRef<null | ReturnType<typeof setTimeout>>(null);

  useEffect(() => {
    function cleanup() {
      if (timeoutId.current !== null) {
        clearTimeout(timeoutId.current);
      }
    }
    return cleanup;
  }, []);

  return useMemo(() => (...args: Args) => new Promise((res) => {
    if (timeoutId.current !== null) {
      clearTimeout(timeoutId.current);
    }

    timeoutId.current = setTimeout(async () => {
      const response = await asyncCall(...args);
      timeoutId.current = null;
      res(response);
    }, debounceTime);
  }), [asyncCall]);
}

export function useIsTabFocused() {
  const [isTabFoucused, setTabFocused] = useMountedState(true);

  useEffect(() => {
    const focus = () => setTabFocused(true);
    const blur = () => setTabFocused(false);
    window.addEventListener('focus', focus);
    window.addEventListener('blur', blur);
    return () => {
      window.removeEventListener('focus', focus);
      window.removeEventListener('blur', blur);
    };
  }, []);

  return isTabFoucused;
}

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}
