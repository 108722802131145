import classNames from 'classnames';
import React from 'react';

import Button from 'common-ui-components/Button';

import { ReactComponent as LikeIcon } from 'assets/img/icon/like-hand.svg';

import style from 'common-ui-components/Feedback/SingleFeedbackButton/style.module.scss';

export type FeedbackType = 'LIKE' | 'DISLIKE';

interface Props {
  type: FeedbackType;
  selected: boolean;
  onClick: () => void;
  disabled?: boolean;
}

export default function SingleFeedbackButton({
  type, onClick, selected, disabled = false,
}: Props) {
  const className = classNames({
    [style.button]: true,
    [style.disabled]: disabled,
    [style.gray]: disabled && !selected,
  });

  const iconClassName = classNames({
    [style.icon]: true,
    [style.upsideDown]: type === 'DISLIKE',
    [style.selected]: selected,
  });

  return (
    <Button
      transparent
      className={className}
      title={type === 'LIKE' ? 'Yes' : 'No'}
      aria-label={type === 'LIKE' ? 'Like' : 'Dislike'}
      onClick={disabled ? undefined : onClick}
    >
      <LikeIcon className={iconClassName} />
    </Button>
  );
}
