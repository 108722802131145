import React, { ReactNode, useEffect, useRef } from 'react';

import Button from 'common-ui-components/Button';
import CSSVariableDefiner from 'common-ui-components/CSSVariableDefiner';
import ExternalLink from 'common-ui-components/ExternalLink';
import Tooltip from 'common-ui-components/Tooltip';
import { Source } from 'global/api/controller/EnterpriseSearchController';
import { useMountedState } from 'utils/hooks';

import { ReactComponent as ExternalLinkIcon } from 'assets/img/icon/external-link.svg';

import style from 'common-ui-components/Sources/SourceBadge/style.module.scss';

const TOOLTIP_MAX_WIDTH = '400px';
const CARD_MAX_WIDTH = 210;

interface CommonProps {
  type: Source['type'];
  image: ReactNode;
  title: string;
  popoverContent: ReactNode;
  handlePopoverHover: () => void;
  handleSourceClick: () => void;
}

interface InteractionBadgeProps extends CommonProps {
  type: 'atom' | 'salesforce';
  redirect: string;
}

interface EntityBadgeProps extends CommonProps {
  type: 'person' | 'organization';
  redirect: () => void;
}

type Props = InteractionBadgeProps | EntityBadgeProps;

function useDynamicSourceBadgeWidth() {
  const [width, setWidth] = useMountedState(CARD_MAX_WIDTH);
  const ref = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current !== null) {
      const dynamicWidth = ref.current.getBoundingClientRect().width;
      setWidth(Math.min(CARD_MAX_WIDTH, dynamicWidth));
    }
  }, [ref.current]);

  return {
    width, ref,
  };
}

export default function SourceBadge({
  type, image, title, popoverContent, redirect, handlePopoverHover, handleSourceClick,
}: Props) {
  const { width, ref } = useDynamicSourceBadgeWidth();

  const onClick = type === 'person' || type === 'organization' ? redirect : undefined;
  const permalink = type === 'atom' || type === 'salesforce' ? redirect : undefined;

  return (
    <CSSVariableDefiner variables={{ '--source-badge-max-width': `${width}px` }}>
      <Tooltip
        animation={false}
        maxWidth={TOOLTIP_MAX_WIDTH}
        content={popoverContent}
        onShow={handlePopoverHover}
      >
        <Button
          className={style.sourceBadge}
          rounded
          onClick={onClick}
        >
          <OptionalExternalLinkWrapper permalink={permalink} handleSourceClick={handleSourceClick}>
            <div className={style.sourceLink} ref={ref}>
              {image}
              <span className={style.sourceTitle} data-testid="source-title">{title}</span>
              <ExternalLinkIcon className={style.externalLinkIcon} aria-label="external-link-icon" />
            </div>
          </OptionalExternalLinkWrapper>
        </Button>
      </Tooltip>
    </CSSVariableDefiner>
  );
}

interface WrapperProps {
  permalink: string | undefined;
  handleSourceClick: () => void;
  children: ReactNode;
}

function OptionalExternalLinkWrapper({ permalink, handleSourceClick, children }: WrapperProps) {
  return permalink
    ? (
      <ExternalLink to={permalink} onClick={handleSourceClick}>
        {children}
      </ExternalLink>
    )
    : (
      <>
        { children }
      </>
    );
}
