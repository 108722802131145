import classNames from 'classnames';
import React from 'react';
import { Link, LinkProps } from 'react-router-dom';

import getScreenConfig, { ScreenKey } from 'global/ScreensConfiguration';
import { useUserContext } from 'screens/platform/cross-platform-components/context/user/UserContext';
import DebuggerConsole from 'utils/DebuggerConsole';

import style from 'common-ui-components/InternalLink/style.module.scss';

interface Props extends Omit<LinkProps, 'to'> {
  to: ScreenKey;
  className?: string;
  urlParam?: string;
  search?: string;
}

export default function InternalLink({
  to, urlParam, className, search, children, ...props
}: Props) {
  const { isUserAllowed } = useUserContext();
  const { path, minimumRole } = getScreenConfig(to, urlParam);
  const isAllowed = isUserAllowed(minimumRole);

  if (isAllowed) {
    return (
      <Link
        {...props}
        to={{ pathname: path, search }}
        className={classNames(style.internalLink, className)}
      >
        {children}
      </Link>
    );
  }

  DebuggerConsole.error("User can see a link they don't have access to", to);
  return (
    <div className={className}>
      {children}
    </div>
  );
}
