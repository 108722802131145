import classNames from 'classnames';
import React from 'react';
import { useToggle } from 'react-use';

import Button from 'common-ui-components/Button';
import ScreenButtonTooltipWrapper
  from 'screens/platform/cross-platform-components/Navbar/components/ScreenButton/ScreenButtonTooltipWrapper';
import { useNavbarContext } from 'screens/platform/cross-platform-components/Navbar/NavbarContext';
import InfoModal from 'screens/platform/cross-platform-components/Panels/BottomPanel/InfoPanel/InfoModal';

import { ReactComponent as ShieldIcon } from 'assets/img/icon/screens/privacy-screen.svg';

import style from 'screens/platform/cross-platform-components/Navbar/components/ScreenButton/style.module.scss';

export default function PrivacyButton() {
  const [isModalOpen, toggleModal] = useToggle(false);
  const { isNavbarMinimized } = useNavbarContext();

  return (
    <>
      <InfoModal isOpen={isModalOpen} handleClose={toggleModal} />
      <ScreenButtonTooltipWrapper label="Privacy">
        <Button
          className={classNames(
            style.screenButton,
            isModalOpen && style.selected,
            isNavbarMinimized && style.minimized,
          )}
          transparent
          onClick={toggleModal}
        >
          <ShieldIcon className={style.icon} />
          <div className={style.label}>
            <span className={style.spacedLabel}>
              Privacy
            </span>
          </div>
        </Button>
      </ScreenButtonTooltipWrapper>
    </>
  );
}
