/* global chrome */
import { JwtToken } from 'global/authentication/Authentication';
import { isChrome } from 'utils/BrowserUtils';
import DebuggerConsole from 'utils/DebuggerConsole';

const EXTENSION_ID = 'coamegmgbgpemiaelimmihgknaeonbbc';

type DOMMessage =
  | { event: 'USER_LOGGED_IN'; payload: { accessToken: string; refreshToken: string } }
  | { event: 'USER_LOGGED_OUT' }
  | { event: 'CHECK_NEW_VERSION' }

export function sendMessageToExtension(
  message: DOMMessage,
  responseHandler: (response: any) => void,
) {
  if (isChrome && global.chrome !== undefined) {
    DebuggerConsole.log('Sending message to extension', message);
    chrome?.runtime?.sendMessage(EXTENSION_ID, message, responseHandler);
  } else {
    DebuggerConsole.log('Unable to send message to extension', message);
  }
}

function getPersistentFirebaseRefreshToken(): Promise<string> {
  const readingRequest = window.indexedDB.open('firebaseLocalStorageDb', 1);
  return new Promise((resolve, reject) => {
    readingRequest.onsuccess = (e: any) => {
      try {
        const db = e.target.result;
        const transaction = db.transaction(['firebaseLocalStorage'], 'readonly');
        const objectStore = transaction.objectStore('firebaseLocalStorage');
        objectStore.openCursor().onsuccess = (event: any) => {
          const cursor = event.target.result;
          if (cursor) {
            cursor.continue();
            const jwtJSON = cursor.value;
            const { refreshToken } = jwtJSON.value.stsTokenManager;
            resolve(refreshToken);
          }
        };
      } catch (err) {
        reject(err);
      }
    };
  });
}

export async function sendTokenToChromeExtension(accessToken: JwtToken) {
  const refreshToken = await getPersistentFirebaseRefreshToken();
  sendMessageToExtension(
    { event: 'USER_LOGGED_IN', payload: { accessToken, refreshToken } },
    (response: any) => {
      akoodaChromeExtension.isExtensionInstalled = response === 'ack';
    },
  );
}

export function sendLoggedOutMessageToChromeExtension() {
  sendMessageToExtension(
    { event: 'USER_LOGGED_OUT' },
    () => {},
  );
}

class AkoodaChromeExtension {
  isExtensionInstalled = false;
  isInstalled(): boolean {
    return this.isExtensionInstalled;
  }
}
const akoodaChromeExtension = new AkoodaChromeExtension();
export function isAkoodaChromeExtensionInstalled(): boolean {
  return akoodaChromeExtension.isInstalled();
}
