import DebuggerConsole from 'utils/DebuggerConsole';

/**
 * Extracts the text before the last period.
 * @param rawId An id generated by nivo. Has the following format: <LABEL>.<INDEX>
 */

const extractTagLabel = (rawId: string): string => {
  const matchingGroups = rawId.match(/(.+?)\.\d+/);
  if (matchingGroups && matchingGroups[1]) {
    return matchingGroups[1];
  }
  DebuggerConsole.error(`Couldn't parse nivo's ID of format LABEL.INDEX: ${rawId}`);
  return rawId;
};

export default extractTagLabel;
