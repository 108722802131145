import React from 'react';

import LazyLoad from 'common-ui-components/LazyLoad';
import {
  CollapsibleGroupProps,
} from 'screens/platform/cross-platform-components/HierarchicalSearchInput/components/HierarchicalSearchResults/CollapsibleGroup/index';
import TypedItemListing
  from 'screens/platform/cross-platform-components/HierarchicalSearchInput/components/HierarchicalSearchResults/CollapsibleGroup/TypedItemListing';
import ItemListing, { ITEM_LISTING_HEIGHT_BIG, ITEM_LISTING_HEIGHT_SMALL, ITEM_LISTING_MARGIN } from 'screens/platform/cross-platform-components/HierarchicalSearchInput/components/ItemListing';
import {
  HierarchicalItemType,
} from 'screens/platform/cross-platform-components/HierarchicalSearchInput/HierarchicalItemTypeConfig';

import style from 'screens/platform/cross-platform-components/HierarchicalSearchInput/style.module.scss';

type Props = {
  isOpen: boolean;
  type?: HierarchicalItemType;
  onToggle?: (id: string) => void;
  getItemSizeLabel?: (itemId: string) => string;
  getItemLabel?: (itemId: string) => string;
  getItemImage?: (itemId: string) => JSX.Element;
  disabledItems?: Partial<Record<string, string>>;
} & Pick<CollapsibleGroupProps, 'visibleItemsIds' | 'group'>

export default function CollapsibleGroupItems({
  isOpen,
  visibleItemsIds,
  group,
  type,
  onToggle,
  getItemSizeLabel,
  getItemLabel = (x) => x,
  getItemImage,
}: Props) {
  const itemListingHeight = type === HierarchicalItemType.PERSON
    ? ITEM_LISTING_HEIGHT_BIG : ITEM_LISTING_HEIGHT_SMALL;
  return (
    <div
      className={style.collapsibleItemsSection}
      style={{
        maxHeight: isOpen
          ? visibleItemsIds.length * (itemListingHeight + ITEM_LISTING_MARGIN)
          : 0,
      }}
    >
      {
        visibleItemsIds.map((itemId) => {
          const item = group.getChild(itemId);
          if (!item) return null;

          const commonItemListingProps = { item, onToggle };

          return (
            <LazyLoad height={itemListingHeight} key={itemId}>
              {
                type !== undefined
                  ? (
                    <TypedItemListing
                      {...commonItemListingProps}
                      type={type}
                    />
                  ) : (
                    <ItemListing
                      {...commonItemListingProps}
                      label={getItemLabel(itemId)}
                      labelImage={getItemImage && getItemImage(itemId)}
                      sizeLabel={getItemSizeLabel && getItemSizeLabel(itemId)}
                    />
                  )
              }
            </LazyLoad>
          );
        })
      }
    </div>
  );
}
