import HierarchicalGroup from 'utils/HierarchicalDataStructures/HierarchicalGroup';
import HierarchicalInterface from 'utils/HierarchicalDataStructures/HierarchicalInterface';
import HierarchicalItem from 'utils/HierarchicalDataStructures/HierarchicalItem';

export enum SelectionStatus {
  SELECTED = 'SELECTED',
  PARTIALLY_SELECTED = 'PARTIALLY_SELECTED',
  UNSELECTED = 'UNSELECTED',
}

/**
 * This class acts just like a Java interface, or like an C header file (god forbid).
 * The only difference is obviously that it includes HierarchicalGroup's constructor implementation
 */
export default abstract class HierarchicalGroupInterface extends HierarchicalInterface {
  readonly status: { selected: number; total: number };
  protected readonly children: Record<string, HierarchicalGroup> | Record<string, HierarchicalItem>;
  isPartiallySelected: boolean;

  constructor(
    id: string,
    children:
      | string[]
      | (HierarchicalGroup | HierarchicalItem)[]
      | Partial<Record<string, string[]>>,
    isSelected: boolean,
  ) {
    super(id, isSelected);

    this.children = {};
    if (children instanceof Array && children.length > 0) {
      if (typeof children[0] === 'string') {
        this.children = (children as string[]).reduce((acc, childId) => {
          const parsedChild = new HierarchicalItem(childId, isSelected);
          parsedChild.setParent(this);
          acc[childId] = parsedChild;
          return acc;
        }, {});
      } else if (
        children[0] instanceof HierarchicalGroup
        || children[0] instanceof HierarchicalItem
      ) {
        this.children = (children as (HierarchicalGroup | HierarchicalItem)[])
          .reduce((acc, child) => {
            const parsedChild = child.clone();
            parsedChild.setParent(this);
            acc[child.id] = parsedChild;
            return acc;
          }, {});
      }
    } else {
      this.children = Object.entries(children).reduce((acc, [parentId, nestedChildren]) => {
        const parsedChild = new HierarchicalGroup(
          parentId,
          nestedChildren,
          isSelected,
        );
        parsedChild.setParent(this);
        acc[parentId] = parsedChild;
        return acc;
      }, {});
    }

    const childrenAmount = (children instanceof Array ? children : Object.keys(children)).length;
    const derivedSelectedChildrenAmount = (() => {
      if (children instanceof Array
        && children.length > 0
        && children[0] instanceof HierarchicalInterface) {
        return (children as HierarchicalInterface[])
          .reduce((sum, child) => (child.isSelected ? sum + 1 : sum), 0);
      }
      return isSelected ? childrenAmount : 0;
    })();
    this.status = {
      total: childrenAmount,
      selected: derivedSelectedChildrenAmount,
    };

    this.isPartiallySelected = this.calcIsPartiallySelected();
  }

  protected abstract selectChild(nestedChildPath: string[]): SelectionStatus | null;
  protected abstract deselectChild(nestedChildPath: string[]): SelectionStatus | null;
  abstract getChild(childId: string[]): HierarchicalGroup | HierarchicalItem | undefined;
  abstract getSelectedItems(): string[];
  abstract getSelectedGroupsAndItems(): { selectedGroups: string[]; selectedItems: string[] };
  abstract isNotSelected(): boolean;
  abstract equals(other: HierarchicalInterface | undefined): boolean;
  abstract clone(): HierarchicalGroup;

  protected calcIsPartiallySelected(): boolean {
    if (this.status.selected > 0
      && this.status.selected < this.status.total) {
      return true;
    }
    return Object.values(this.children)
      .some((child) => child.isSelected
        || (child.isParent() && child.isPartiallySelected));
  }
}
