import { useCallback, useState } from 'react';

import { Key, SorterResult } from 'antd/lib/table/interface';
import { DirectoryTableColumn } from 'screens/platform/directory/directoryTableColumnsHook';
import LocalStorageUtils, { StorageKeySorting } from 'utils/LocalStorageUtils';

export interface SortingConfig<CustomConfig = never> {
  columnKey: Key;
  order: 'ascend' | 'descend';
  customConfig?: CustomConfig;
}
function validateSortingConfig<CustomConfig = never>(
  val: any,
  customConfigValidator?: Validator<CustomConfig>,
): val is SortingConfig<CustomConfig> {
  if (!val.columnKey || !val.order) {
    return false;
  }
  return !customConfigValidator || customConfigValidator(val.customConfig);
}

type OptionalSortingConfig<T> = SortingConfig<T> | undefined;
type SortingSetter = (nextSorting: SorterResult<any>) => void;
type Validator<T> = (customVal: any) => customVal is T;

function useSorting<T>(
  defaultSorting: undefined,
  key?: StorageKeySorting,
  customConfigValidator?: Validator<T>,
): [OptionalSortingConfig<T>, SortingSetter];
function useSorting<T>(
  defaultSorting: SortingConfig<T>,
  key?: StorageKeySorting,
  customConfigValidator?: Validator<T>,
): [SortingConfig<T>, SortingSetter];
function useSorting<T = never>(
  defaultSorting?: SortingConfig<T>,
  key?: StorageKeySorting,
  customConfigValidator?: Validator<T>,
): [OptionalSortingConfig<T>, SortingSetter] {
  const validateScheme = (val: any): val is SortingConfig<T> =>
    validateSortingConfig(val, customConfigValidator);
  const persistedSorting = key ? LocalStorageUtils.getItem<OptionalSortingConfig<T>>(
    key,
    validateScheme,
  ) : null;

  // TODO: Remove when all the clients have been cleaned up. see: AKD-4841
  const oldMetricsNames = [
    'LAST_PERSON_INTERACTION',
    'LAST_MEETING',
    'LAST_CONVERSATION',
    'LAST_ENGAGEMENT',
    'TOTAL_CONVERSATIONS',
    'TOTAL_MEETINGS',
    'TOTAL_ENGAGEMENTS',
    'CONVERSATION_FREQUENCY',
    'MEETING_FREQUENCY',
    'ENGAGEMENT_FREQUENCY',
    'TOTAL_MENTIONS',
    'TOTAL_ORGANIZATION_PEOPLE',
    'TOTAL_ORGANIZATION_CONTACTS',
    'TIME_SINCE_LAST_MENTION',
    'TIME_SINCE_FIRST_MENTION',
    'TOTAL_OUTBOUND_INTERACTIONS',
    'TOTAL_INBOUND_INTERACTIONS',
    'TOTAL_ENGAGEMENTS_VOLUME',
    'TOTAL_MENTIONS_VOLUME',
  ];

  // eslint-disable-next-line dot-notation
  const isCustomConfig = persistedSorting?.customConfig?.['metricType'] && oldMetricsNames.includes(persistedSorting?.customConfig['metricType']);
  const columnKey = persistedSorting?.columnKey;
  const isColumnKeyExist = key && columnKey != null;

  if (isColumnKeyExist && (oldMetricsNames.includes(columnKey.toString()) || isCustomConfig)) {
    LocalStorageUtils.removeItem(key);
  }

  const [sortingConfig, setSortingConfig] = useState<OptionalSortingConfig<T>>(
    persistedSorting ?? defaultSorting,
  );

  const updatePersistentState = (nextSortingConfig: SortingConfig<T>) => {
    if (key) {
      LocalStorageUtils.setItem(key, nextSortingConfig);
    }
  };
  const removePersistentState = () => {
    if (key) {
      LocalStorageUtils.removeItem(key);
    }
  };

  const setSorting = useCallback(
    (nextSorting: SorterResult<any>) => {
      setSortingConfig((prevSorting) => {
        if (nextSorting.order && nextSorting.columnKey) {
          const nextSortingConfig: SortingConfig<T> = {
            columnKey: nextSorting.columnKey,
            order: nextSorting.order,
            customConfig: (
              nextSorting.column as DirectoryTableColumn<any, string, any>
            )?.customSortingConfig,
          };
          if (validateScheme(nextSortingConfig)) {
            updatePersistentState(nextSortingConfig);
            return nextSortingConfig;
          }
        }

        removePersistentState();
        if (defaultSorting
          && prevSorting?.columnKey === defaultSorting.columnKey
          && prevSorting?.order === defaultSorting.order) {
          return { ...defaultSorting, order: defaultSorting.order === 'ascend' ? 'descend' : 'ascend' };
        }
        return defaultSorting;
      });
    },
    [setSortingConfig],
  );

  return [sortingConfig, setSorting];
}

export default useSorting;
