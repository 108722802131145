import HierarchicalMasterFilter
  from 'screens/platform/cross-platform-components/context/MasterFiltersContext/HierarchicalMasterFilter';
import {
  DefaultFilterLabels,
} from 'screens/platform/cross-platform-components/context/MasterFiltersContext/utils/DefaultMasterFiltersUtils';
import { MetadataContextType } from 'screens/platform/cross-platform-components/context/metadata/MetadataContext';
import HierarchicalGroup from 'utils/HierarchicalDataStructures/HierarchicalGroup';

const PEOPLE_GROUP_KEY = 'PEOPLE_IDS_KEY';
const DEPARTMENTS_GROUP_KEY = 'DEPARTMENTS_KEY';
const TEAMS_GROUP_KEY = 'TEAMS_KEY';

interface SubGroupConfig<T> {
  mapping: T;
  isSelected: boolean;
}
type PeopleMetadataContextType = MetadataContextType['persons'];

interface Props {
  people: SubGroupConfig<string[]>;
  departments: SubGroupConfig<PeopleMetadataContextType['departmentsToPersonsMap']>;
  teams: SubGroupConfig<PeopleMetadataContextType['teamsToPeopleMap']>;
}

export default class PeopleMasterFilterHierarchyGroup extends HierarchicalMasterFilter {
  constructor(config: Props) {
    super(
      DefaultFilterLabels.ALL_COMPANY,
      [
        new HierarchicalGroup(
          PEOPLE_GROUP_KEY,
          config.people.mapping,
          config.people.isSelected,
        ),
        new HierarchicalGroup(
          DEPARTMENTS_GROUP_KEY,
          config.departments.mapping,
          config.departments.isSelected,
        ),
        new HierarchicalGroup(
          TEAMS_GROUP_KEY,
          config.teams.mapping,
          config.teams.isSelected,
        ),
      ],
      false,
    );
  }

  getPeopleGroup(): HierarchicalGroup {
    return this.getChild(PEOPLE_GROUP_KEY) as HierarchicalGroup;
  }

  getDepartmentsGroup(): HierarchicalGroup {
    return this.getChild(DEPARTMENTS_GROUP_KEY) as HierarchicalGroup;
  }

  getTeamsGroup(): HierarchicalGroup {
    return this.getChild(TEAMS_GROUP_KEY) as HierarchicalGroup;
  }
}
