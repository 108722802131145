import React, { ReactNode } from 'react';

import style from 'screens/platform/cross-platform-components/Interactions/components/InteractionsSummary/InteractionsSummaryMetadata/style.module.scss';

interface Props {
  title: string;
  children: ReactNode;
}

export default function InteractionsSummaryMetadataSection({ title, children }: Props) {
  return (
    <div className={style.section}>
      <h4>
        {title}
      </h4>
      <div className={style.content}>
        {children}
      </div>
    </div>
  );
}
