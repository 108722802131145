import { useEffect } from 'react';

import { MetricValueResponse } from 'global/api/controller/MetricController';
import Api from 'global/api/platformApi';
import Metric from 'global/lists/Metric';
import useLatestDataQuery from 'screens/platform/contentScreens/AnalyticsScreen/utils/LatestDataQueryHook';
import { MasterFilters } from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFilters';
import DebuggerConsole from 'utils/DebuggerConsole';
import { useMountedState } from 'utils/hooks';

export default function useMetricData(id: Metric, masterFilters: MasterFilters | null) {
  const [response, setResponse] = useMountedState<MetricValueResponse | null>(null);
  const [error, setError] = useMountedState(false);

  const isLatestQuery = useLatestDataQuery([masterFilters?.version]);

  useEffect(() => {
    async function fetchWithUpdatedFilters() {
      if (!masterFilters) return;
      setError(false);
      setResponse(null);
      try {
        const result = await Api.Metric.getMetricValue(id, masterFilters);
        if (isLatestQuery([masterFilters.version])) {
          if (result?.data) {
            setResponse(result.data);
          } else {
            setError(true);
          }
        }
      } catch (err) {
        DebuggerConsole.error(err);
        setError(true);
      }
    }

    fetchWithUpdatedFilters();
  }, [masterFilters?.version]);

  return { response, error };
}
