import React from 'react';

import SkeletonShimmer from 'es-src/components/ui/Skeleton/SkeletonShimmer';

import style from 'es-src/components/BadgeSkeleton/style.module.scss';

const BadgeSkeleton = () => (
  <div className={style.skeletonWrapper} data-testid="badge-skeleton">
    <div className={style.badgeSkeleton} />
    <SkeletonShimmer />
  </div>
);

export default BadgeSkeleton;
