import {
  getRequest,
  HttpResponse,
} from 'global/api/platformApiHelpers';

const ImageController = {
  async getImage(imageUrl: string): HttpResponse<Blob> {
    return getRequest('image', { imageUrl }, { responseType: 'blob' });
  },

  async getLogo(tenantId: number): HttpResponse<Blob> {
    return getRequest('image/logo', { tenantId }, { responseType: 'blob' });
  },

  async getOrganizationImage(tenantId: number, orgId: string): HttpResponse<Blob> {
    return getRequest('image/organization', { tenantId, orgId }, { responseType: 'blob' });
  },

  async getDomainImage(domain: string): HttpResponse<Blob> {
    return getRequest('image/domain', { domain }, { responseType: 'blob' });
  },
};

export default ImageController;
