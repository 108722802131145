import React from 'react';

import FilterConfig, {
  FilterValue,
} from 'screens/platform/directory/components/GenericDirectoryScreen/FiltersList/FilterConfig';
import GenericFilter from 'screens/platform/directory/components/ProfileTabs/components/Filters/GenericFilter';
import {
  useNameFilterConfig,
} from 'screens/platform/directory/organizations/OrganizationsDirectoryScreen/OrganizationsTable/utils/OrganizationFiltersConfig';

interface Props {
  requiredOrganization: string | undefined;
  disabled?: boolean;
}

export default function OrganizationsFilter({ requiredOrganization, disabled }: Props) {
  const filterConfig = useNameFilterConfig(requiredOrganization) as FilterConfig<FilterValue>;
  return (
    <GenericFilter
      filterType="organizations"
      filterConfig={filterConfig}
      disabled={disabled}
    />
  );
}
