import { MinimalTag } from 'global/api/controller/TagsSetController';
import { UNASSOCIATED_CATEGORY } from 'model/Tenant';

// eslint-disable-next-line import/prefer-default-export
export function convertTagsToTagsByCategoriesMap(
  data: MinimalTag[] | null | undefined,
): Record<string, string[]> {
  return data
    ? data.reduce((acc, tag) => {
      const categories = tag.categories.length === 0
        ? [UNASSOCIATED_CATEGORY]
        : tag.categories;
      categories.forEach((category) => {
        if (!acc[category]) {
          acc[category] = [];
        }
        acc[category].push(tag.value);
        acc[category].sort();
      });
      return acc;
    }, {} as Record<string, string[]>)
    : {};
}
