import { useCallback } from 'react';

import Api from 'global/api/platformApi';
import Tenant from 'model/Tenant';
import { useUserContext } from 'screens/platform/cross-platform-components/context/user/UserContext';
import DebuggerConsole from 'utils/DebuggerConsole';
import LocalStorageUtils, { StorageKeyGeneral } from 'utils/LocalStorageUtils';

export default function useTenantDataFetching(): () => Promise<Tenant | null> {
  const { isSuperAdmin, endUserSession, setAuthError } = useUserContext();

  return useCallback(async () => {
    const tenantId = LocalStorageUtils.getItem<number>(StorageKeyGeneral.TENANT_ID) || undefined;
    try {
      const tenantResponse = await Api.Tenant.getTenant(
        isSuperAdmin ? tenantId : undefined,
      );
      if (tenantResponse?.data) {
        return tenantResponse.data;
      }
    } catch (err) {
      DebuggerConsole.warn('Tenant request failed', tenantId);
    }

    LocalStorageUtils.removeItem(StorageKeyGeneral.TENANT_ID);
    return null;
  }, [isSuperAdmin, endUserSession, setAuthError]);
}
