import React, { ReactElement } from 'react';

import getAppDisplayName from 'global/lists/appsNames';
import ChangeInAttentionEvent from 'model/Events/ChangeInAttentionEvent';
import CommunicationFlowEvent from 'model/Events/CommunicationFlowEvent';
import EventResponse, { EventType } from 'model/Events/Event';
import OrganizationEvent from 'model/Events/OrganizationEvent';
import OrgStructureEvent from 'model/Events/OrgStructureEvent';
import TrendingTopicEvent from 'model/Events/TrendingTopicEvent';
import useAsync from 'react-use/lib/useAsync';
import { useMetadataContext } from 'screens/platform/cross-platform-components/context/metadata/MetadataContext';
import ChangeInAttentionTitle from 'screens/platform/cross-platform-components/EventsPanel/EventCell/EventTitle/ChangeInAttentionTitle';
import CommunicationFlowTitle from 'screens/platform/cross-platform-components/EventsPanel/EventCell/EventTitle/CommunicationFlowTitle';
import FirstEngagementTitle from 'screens/platform/cross-platform-components/EventsPanel/EventCell/EventTitle/FirstEngagementTitle';
import FirstExecutiveEngagementTitle from 'screens/platform/cross-platform-components/EventsPanel/EventCell/EventTitle/FirstExecutiveEngagementTitle';
import LastEngagementTitle from 'screens/platform/cross-platform-components/EventsPanel/EventCell/EventTitle/LastEngagementTitle';
import OrgStrucureEventTitle from 'screens/platform/cross-platform-components/EventsPanel/EventCell/EventTitle/OrgStrucureEventTitle';
import TrendingTopicTitle from 'screens/platform/cross-platform-components/EventsPanel/EventCell/EventTitle/TrendingTopicTitle';
import DateUtils from 'utils/DateUtils';

const eventTypesToLabels: Record<EventType, string> = Object.freeze({
  [EventType.CHANGE_IN_ATTENTION]: 'CHANGE IN ATTENTION',
  [EventType.TRENDING_TOPIC]: 'TRENDING TOPICS',
  [EventType.COMMUNICATION_FLOW]: 'COMMUNICATION FLOW',
  [EventType.ORGANIZATION_FIRST_ENGAGEMENT]: 'FIRST ENGAGEMENT',
  [EventType.ORGANIZATION_LAST_ENGAGEMENT]: 'DISENGAGED ORGANIZATION',
  [EventType.ORGANIZATION_FIRST_EXECUTIVE_ENGAGEMENT]: 'EXECUTIVE LEVERAGE',
  [EventType.PERSON_ONBOARDED]: 'People Onboarded',
  [EventType.PERSON_OFFBOARDED]: 'People Offboarded',
});

interface EventDescription {
  type: string;
  title: ReactElement;
  subTitle: string;
  significance: number;
}

export default function useEventsDescription(event: EventResponse): EventDescription | null {
  const { persons, connectedApps } = useMetadataContext();

  const { value: eventDescription } = useAsync(async () => {
    switch (event.eventType) {
      case EventType.CHANGE_IN_ATTENTION:
        return buildChangeOfAttention(event);
      case EventType.COMMUNICATION_FLOW:
        return buildCommunicationFlow(event);
      case EventType.TRENDING_TOPIC:
        return buildTrendingTopic(event);
      case EventType.ORGANIZATION_FIRST_ENGAGEMENT:
        return buildFirstEngagement(event, (await persons.getById(event.initiatorId))?.name);
      case EventType.ORGANIZATION_FIRST_EXECUTIVE_ENGAGEMENT:
        return buildFirstExecutiveEngagement(
          event,
          (await persons.getById(event.initiatorId))?.name,
        );
      case EventType.ORGANIZATION_LAST_ENGAGEMENT:
        return buildLastEngagement(
          event,
          (await persons.getById(event.initiatorId))?.department,
          getAppDisplayName(event.appId),
        );
      case EventType.PERSON_ONBOARDED:
      case EventType.PERSON_OFFBOARDED:
        return buildOrgStructure(event);
      default:
        // @ts-ignore
        throw new Error(`Invalid event type: ${event.eventType}`);
    }
  }, [event, connectedApps]);

  return eventDescription || null;
}

function formatPercentage(percentage: number) {
  const absPercentage = Math.abs(percentage);
  return Number(absPercentage.toFixed(1));
}

function buildChangeOfAttention(
  changeInAttentionEvent: ChangeInAttentionEvent,
): EventDescription {
  const { changeFromAverage, significance } = changeInAttentionEvent;
  const changeText = changeFromAverage > 0 ? 'increase' : 'decrease';
  const subTitle = `that's a ${formatPercentage(changeFromAverage)}% ${changeText} versus the previous 30-day average`;
  return {
    type: eventTypesToLabels[EventType.CHANGE_IN_ATTENTION],
    title: <ChangeInAttentionTitle event={changeInAttentionEvent} />,
    subTitle,
    significance,
  };
}

function buildFirstEngagement(
  firstEngagementEvent: OrganizationEvent,
  initiatorName: string | undefined,
): EventDescription | null {
  if (!initiatorName) return null;
  const { significance, startDate } = firstEngagementEvent;
  const subTitle = `which was mainly handled by ${initiatorName} over ${DateUtils.getDaysAgo(startDate)} days ago`;

  return {
    type: eventTypesToLabels[EventType.ORGANIZATION_FIRST_ENGAGEMENT],
    title: <FirstEngagementTitle event={firstEngagementEvent} />,
    subTitle,
    significance,
  };
}

function buildOrgStructure(event: OrgStructureEvent): EventDescription {
  const {
    significance, personsIds, eventType,
  } = event;
  const personsCount = personsIds.length;
  const departmentSize = event.departmentSize
  + (eventType === EventType.PERSON_OFFBOARDED ? personsCount : 0);
  const countPercentage = Math.round((personsCount / departmentSize) * 100);
  const subTitle = `which is ${countPercentage}% of the department size as it is today`;

  return {
    type: eventTypesToLabels[eventType],
    title: <OrgStrucureEventTitle event={event} />,
    subTitle,
    significance,
  };
}

function buildFirstExecutiveEngagement(
  firstExecutiveEngagementEvent: OrganizationEvent,
  initiatorName: string | undefined,
): EventDescription | null {
  if (!initiatorName) return null;

  const { significance } = firstExecutiveEngagementEvent;
  const subTitle = `${initiatorName} was the latest addition to the team working this account`;

  return {
    type: eventTypesToLabels[EventType.ORGANIZATION_FIRST_EXECUTIVE_ENGAGEMENT],
    title: <FirstExecutiveEngagementTitle event={firstExecutiveEngagementEvent} />,
    subTitle,
    significance,
  };
}

function buildLastEngagement(
  lastEngagementEvent: OrganizationEvent,
  initiatorDepartment: string | undefined,
  appName: string,
): EventDescription | null {
  if (!initiatorDepartment) return null;

  const { significance } = lastEngagementEvent;
  const subTitle = `whereas the last touchpoint was on ${appName} led by the ${initiatorDepartment} department`;

  return {
    type: eventTypesToLabels[EventType.ORGANIZATION_LAST_ENGAGEMENT],
    title: <LastEngagementTitle event={lastEngagementEvent} />,
    subTitle,
    significance,
  };
}

function buildTrendingTopic(
  trendingTopicEvent: TrendingTopicEvent,
): EventDescription {
  const { tagSOAPercentage, significance } = trendingTopicEvent;

  return {
    type: eventTypesToLabels[EventType.TRENDING_TOPIC],
    title: <TrendingTopicTitle event={trendingTopicEvent} />,
    subTitle: `such that it is discussed in ${formatPercentage(tagSOAPercentage)}% of all interactions in your organization`,
    significance,
  };
}

function buildCommunicationFlow(
  communicationFlowEvent: CommunicationFlowEvent,
): EventDescription {
  return {
    type: eventTypesToLabels[EventType.COMMUNICATION_FLOW],
    title: <CommunicationFlowTitle event={communicationFlowEvent} />,
    subTitle: buildCommunicationFlowSubTitle(communicationFlowEvent),
    significance: communicationFlowEvent.significance,
  };
}

function buildCommunicationFlowSubTitle(
  communicationFlowEvent: CommunicationFlowEvent,
): string {
  const PERCENTAGE_THRESHOLD = 30;
  const {
    firstDepartment: {
      label: firstDepartmentLabel,
      weeklyVolumeChange: firstDepartmentChange,
    },
    secondDepartment: {
      label: secondDepartmentLabel,
      weeklyVolumeChange: secondDepartmentChange,
    },
  } = communicationFlowEvent;

  const firstDepartmentChangeAbs = Math.abs(firstDepartmentChange);
  const secondDepartmentChangeAbs = Math.abs(secondDepartmentChange);

  const isAnyAboveThreshold = firstDepartmentChangeAbs > PERCENTAGE_THRESHOLD
  || secondDepartmentChangeAbs > PERCENTAGE_THRESHOLD;
  const isBothAboveThreshold = firstDepartmentChangeAbs > PERCENTAGE_THRESHOLD
  && secondDepartmentChangeAbs > PERCENTAGE_THRESHOLD;

  if (isBothAboveThreshold) {
    const isBothPositive = firstDepartmentChange > 0 && secondDepartmentChange > 0;
    const isBothNegative = firstDepartmentChange < 0 && secondDepartmentChange < 0;
    if (isBothPositive || isBothNegative) {
      const changeText = isBothPositive ? 'more' : 'less';
      const totalChange = formatPercentage(firstDepartmentChange + secondDepartmentChange);
      return `while both departments are relatively ${totalChange}% ${changeText} active than others compared to last week`;
    }
    const minChangePercentage = Math.min(
      formatPercentage(firstDepartmentChangeAbs),
      formatPercentage(secondDepartmentChangeAbs),
    );
    return `while their activity changed by more than ${minChangePercentage}% in comparison to last week`;
  }

  if (isAnyAboveThreshold) {
    const { department, change } = (() => (
      firstDepartmentChangeAbs > secondDepartmentChangeAbs
        ? {
          department: firstDepartmentLabel,
          change: firstDepartmentChange,
        }
        : {
          department: secondDepartmentLabel,
          change: secondDepartmentChange,
        }))();
    const changeText = change > 0 ? 'more' : 'less';
    return `while ${department} is relatively ${changeText} active than last week by ${formatPercentage(change)}%`;
  }
  const maxChangePercentage = Math.max(
    formatPercentage(firstDepartmentChangeAbs),
    formatPercentage(secondDepartmentChangeAbs),
  );
  return `while their activity level is about the same as last week, with less than ${maxChangePercentage}% overall change`;
}
