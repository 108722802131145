import StorageUtils from 'utils/StorageUtils';

export enum SessionStorageKey {
  WAS_BROWSER_WARNING_SHOWN = 'isBrowserWarningShown',
  IS_FIRST_LOGIN = 'wasRedirectedFromFirebase',
  IS_SEARCH_HINT_SHOWN = 'isSearchHintShown',
}

export enum SessionKeyFilters {
  FEED_ANNOUNCEMENTS_FILTERS = 'feed.notifications.announcement.filters'
}

export type SessionKey = SessionStorageKey | SessionKeyFilters

export default new StorageUtils<SessionKey>(sessionStorage);
