import classNames from 'classnames';
import React from 'react';

import Feedback from 'common-ui-components/Feedback';
import Tooltip from 'common-ui-components/Tooltip';
import getHighlightText from 'es-src/screens/HomeScreen/components/Highlights/utils/HighlightTextUtils';
import Analytics, { AnalyticsEvent } from 'global/Analytics';
import { AtomHighlight, HighlightType } from 'global/api/controller/AtomController';
import getAppDisplayName from 'global/lists/appsNames';
import InteractionDetails, {
  InteractionDetailsSkeleton,
} from 'screens/platform/cross-platform-components/InteractionDetails';

import style from 'es-src/screens/HomeScreen/components/Highlights/HighlightCard/style.module.scss';

interface Props {
  atomHighlightsData: AtomHighlight;
}

interface AnalyticsPayload extends Record<string, unknown> {
  highlightType: HighlightType;
  atomId: string;
  dataSource: string;
}

export default function HighlightCard({ atomHighlightsData }: Props) {
  const { atom, highlight } = atomHighlightsData;
  const {
    id: atomId,
    appId,
  } = atom;

  const highlightText = getHighlightText(highlight);
  if (!highlightText) return null;
  const { title, popoverText } = highlightText;

  const highlightAnalyticsPayload: AnalyticsPayload = {
    highlightType: highlight,
    atomId,
    dataSource: getAppDisplayName(appId),
  };

  function handlePopoverHover() {
    Analytics.trackEvent(
      AnalyticsEvent.HIGHLIGHT_EXPLANATION_TOOLTIP_USED,
      highlightAnalyticsPayload,
    );
  }

  function handleInteractionClick() {
    Analytics.trackEvent(
      AnalyticsEvent.HIGHLIGHT_INTERACTION_CLICK,
      highlightAnalyticsPayload,
    );
  }

  return (
    <div className={style.atomHighlightCard} aria-label="highlight-card">
      <div className={style.highlightHeader}>
        <Tooltip
          placement="left"
          content={popoverText}
          onShow={handlePopoverHover}
        >
          <div className={style.atomHighlightType}>
            {title}
          </div>
        </Tooltip>
        <Feedback
          sessionId={atomId}
          location="Highlights"
          feedbackPayload={highlightAnalyticsPayload}
        />
      </div>
      <InteractionDetails
        atom={atom}
        onClick={handleInteractionClick}
      />
    </div>
  );
}

export function HighlightCardSkeleton() {
  return (
    <div className={style.atomHighlightCard}>
      <div className={classNames(style.highlightHeader, style.skeleton)}>
        <div className={style.atomHighlightType} />
      </div>
      <InteractionDetailsSkeleton />
    </div>
  );
}
