type RequestedField<KeyType> = { type: FieldType; key: KeyType };

export enum FieldType {
  ORGANIC = 'ORGANIC', // Part of the DB model
  METRIC = 'METRIC', // A metric that is calculated per entity
  ADDITIONAL = 'ADDITIONAL', // Calculated per entity, but not a metric
  CUSTOM_PROPERTY = 'CUSTOM_PROPERTY', // custom property for example: Person.properties key
}

export default RequestedField;
