import { Placement, PositioningStrategy } from '@popperjs/core';
import classnames from 'classnames';
import React, {
  FC,
  ReactNode,
  RefObject,
  useEffect,
  useRef,
  useState,
} from 'react';
import { usePopper } from 'react-popper';

import usePopperModifiers, { PopperOptions } from 'common-ui-components/Dropdown/popperModifiersHook';
import { useOutsideAlerter, useResizeListener } from 'utils/hooks';

import style from 'common-ui-components/Dropdown/style.module.scss';

// The component must have either toggleButtonElement or externalElementRef prop
export type MandatoryDropdownProps =
  | { toggleButtonElement: ReactNode; externalElementRef?: never }
  | { externalElementRef: RefObject<HTMLElement>; toggleButtonElement?: never };

export type DropdownProps = MandatoryDropdownProps & {
  isDropdownOpen: boolean;
  placement: Placement;
  closeDropdown: () => void;
  onDropdownClose?: () => void;
  wrapperClassName?: string;
  toggleClassName?: string;
  dropdownClassName?: string;
  updateTrigger?: any;
  popperOptions?: PopperOptions;
  strategy?: PositioningStrategy;
};

const Dropdown: FC<DropdownProps> = ({
  children,
  isDropdownOpen,
  toggleButtonElement,
  externalElementRef,
  placement,
  wrapperClassName,
  toggleClassName,
  dropdownClassName,
  closeDropdown,
  onDropdownClose,
  updateTrigger,
  popperOptions,
  strategy = 'absolute',
}) => {
  const [toggleElement, setToggleElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const popperElementRef = useRef<HTMLDivElement | null>(null);
  const { width, height, ref: resizeObservedRef } = useResizeListener();

  const modifiers = usePopperModifiers(popperOptions);

  const { forceUpdate, styles, attributes } = usePopper(
    externalElementRef?.current || toggleElement,
    popperElement,
    {
      placement,
      modifiers,
      strategy,
    },
  );

  useEffect(() => {
    if (forceUpdate) {
      forceUpdate();
    }
  }, [updateTrigger, width, height]);

  const handleCloseDropdown = () => {
    if (isDropdownOpen) {
      closeDropdown();
    }
  };

  useOutsideAlerter(wrapperRef, handleCloseDropdown, externalElementRef);

  useEffect(() => {
    if (!isDropdownOpen) {
      if (onDropdownClose) onDropdownClose();
    }
  }, [isDropdownOpen]);

  return (
    <div ref={wrapperRef} className={wrapperClassName}>
      {toggleButtonElement && (
        <div ref={setToggleElement} className={toggleClassName}>
          {toggleButtonElement}
        </div>
      )}
      {isDropdownOpen && (
        <div
          ref={(ref) => {
            setPopperElement(ref);
            popperElementRef.current = ref;
            resizeObservedRef.current = ref;
          }}
          style={styles.popper}
          className={classnames(style.dropdownMenu, dropdownClassName)}
          {...attributes.popper}
        >
          {children}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
