import { useEffect } from 'react';

import ConfigurationKey from 'global/lists/ConfigurationKey';
import { useConfiguration } from 'screens/platform/cross-platform-components/context/configuration/ConfigHook';
import { useMountedState } from 'utils/hooks';

export default function useMaxAtomsCount(): number | undefined {
  const [maxAtomsCount, setMaxAtomsCount] = useMountedState<number | undefined>(undefined);
  const maxAtomsConfig = useConfiguration(
    ConfigurationKey.MAX_ATOMS_COUNT,
    'number',
  );

  useEffect(() => {
    if (!maxAtomsConfig.loading) {
      setMaxAtomsCount(maxAtomsConfig.value);
    }
  }, [maxAtomsConfig.loading]);

  return maxAtomsCount;
}
