import React, { useEffect, useReducer } from 'react';

import GraphFeaturesContext, { ClusteringTypeKey } from 'screens/platform/cross-platform-components/context/graphFeatures/GraphFeaturesContext';
import GraphFeaturesReducer, { ClusterConnections, GraphFeaturesState } from 'screens/platform/cross-platform-components/context/graphFeatures/GraphFeaturesReducer';
import LocalStorageUtils, { StorageKeyFeatures } from 'utils/LocalStorageUtils';

const PERSISTED_GRAPH_FEATURES_KEYS: (keyof GraphFeaturesState)[] = [
  'showNames',
  'showImages',
  'clusterConnections',
  'isClusterGraphEnabled',
  'volumeThreshold',
  'significanceThreshold',
  'showDetachedNodes',
  'showExternalTeamNodes',
];

const defaultState: GraphFeaturesState = {
  showNames: true,
  showImages: true,
  volumeThreshold: 100,
  significanceThreshold: 100,
  clusteringType: ClusteringTypeKey.DEPARTMENT,
  clusterConnections: ClusterConnections.BOTH,
  showClustersGraph: false,
  isClusterGraphEnabled: false,
  showDetachedNodes: false,
  showExternalTeamNodes: false,
};

function GraphFeaturesContextProvider({ children }) {
  const persistedState = LocalStorageUtils.getItemWithDefaultValue(
    StorageKeyFeatures.GRAPH_FEATURES,
    defaultState,
  );
  const [graphFeaturesValues, dispatchGraphFeatures] = useReducer(
    GraphFeaturesReducer,
    persistedState,
  );

  useEffect(() => {
    LocalStorageUtils.setItem<Partial<GraphFeaturesState>>(
      StorageKeyFeatures.GRAPH_FEATURES,
      PERSISTED_GRAPH_FEATURES_KEYS.reduce((acc, key) => {
        acc[key] = graphFeaturesValues[key];
        return acc;
      }, {}),
    );
  }, [graphFeaturesValues]);

  return (
    <GraphFeaturesContext.Provider value={{
      ...graphFeaturesValues,
      dispatchGraphFeatures,
    }}
    >
      { children }
    </GraphFeaturesContext.Provider>
  );
}

export default GraphFeaturesContextProvider;
