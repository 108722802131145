import { OrganizationRisk, OrganizationSize } from 'model/Organization';
import {
  NullableValuesSelectionConstants,
} from 'screens/platform/cross-platform-components/context/MasterFiltersContext/NullableValuesSelection';
import {
  OrganizationDimension,
} from 'screens/platform/cross-platform-components/MasterFiltersPanel/FilterSection/OrganizationsFilterSection/components/OrganizationsHierarchicalSearchResults/OrganizationsHierarchicalSearchResultsReducerActions';
import DebuggerConsole from 'utils/DebuggerConsole';

const {
  UNDEFINED_PROPERTY,
  UNDEFINED_PROPERTY_LABEL,
} = NullableValuesSelectionConstants;

const keysToLabels: Record<OrganizationRisk | OrganizationSize, string> = {
  [OrganizationRisk.HIGH]: 'High',
  [OrganizationRisk.MEDIUM]: 'Medium',
  [OrganizationRisk.LOW]: 'Low',

  [OrganizationSize.LARGE]: 'Large',
  [OrganizationSize.MEDIUM]: 'Medium',
  [OrganizationSize.SMALL]: 'Small',
  [OrganizationSize.VERY_SMALL]: 'Very Small',
};

export default function getOrganizationFilterLabel(
  itemId: string,
  dimension: OrganizationDimension,
): string {
  if (itemId === UNDEFINED_PROPERTY) return UNDEFINED_PROPERTY_LABEL;

  if (dimension === OrganizationDimension.SEGMENTS) {
    return itemId;
  }

  const label = keysToLabels[itemId];
  if (label !== undefined) return label;

  DebuggerConsole.error('Invalid itemId on organization filter', { itemId });
  return '-';
}
