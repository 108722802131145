import { ModifierPhases } from '@popperjs/core';
import { useMemo } from 'react';

export type PopperOptions = {
  offset?: number;
  distance?: number;
  enableFlipping?: boolean;
  enablePreventOverflowing?: boolean;
  enableSameWidth?: boolean;
};

const defaultPopperOptions: PopperOptions = {
  offset: 0,
  distance: 5,
  enableFlipping: true,
  enablePreventOverflowing: true,
  enableSameWidth: false,
};

export default function usePopperModifiers(options?: PopperOptions) {
  const {
    offset,
    distance,
    enableFlipping,
    enablePreventOverflowing,
    enableSameWidth,
  } = { ...defaultPopperOptions, ...options };

  return useMemo(
    () => [
      {
        name: 'offset',
        options: {
          offset: [offset, distance],
        },
      },
      {
        name: 'flip',
        enabled: enableFlipping,
        options: {
          flipVariations: false, // true by default
        },
      },
      {
        name: 'preventOverflow',
        enabled: enablePreventOverflowing,
      },
      {
        name: 'sameWidth',
        enabled: enableSameWidth,
        phase: 'beforeWrite' as ModifierPhases,
        requires: ['computeStyles'],
        fn({ state }) {
          // eslint-disable-next-line no-param-reassign
          state.styles.popper.minWidth = `${state.rects.reference.width}px`;
        },
        effect({ state }) {
          // eslint-disable-next-line no-param-reassign
          state.elements.popper.style.minWidth = `${state.elements.reference.offsetWidth}px`;
        },
      },
    ],
    [enableFlipping, enablePreventOverflowing, enableSameWidth],
  );
}
