import classNames from 'classnames';
import React, { forwardRef, ReactNode } from 'react';

import { ReactComponent as EditIcon } from 'assets/img/icon/edit-pencil.svg';

import style from 'common-ui-components/ActionableBadge/style.module.scss';

interface ActionableBadgeProps {
  label: string | ReactNode;
  iconType: 'edit' | 'remove';
  onClick: (event) => void;
  title?: string;
  disabled?: boolean;
  className?: string;
  dataTestId?: string;
}

const ActionableBadge = forwardRef<HTMLDivElement, ActionableBadgeProps>(({
  label, iconType, onClick, title, disabled = false, className, dataTestId,
}, ref) => (
  <div
    className={classNames(style.actionableBadge, disabled && style.disabled, className)}
    onClick={onClick}
    ref={ref}
    title={title}
    data-testid={dataTestId}
  >
    <span className={style.badgeLabel}>{label}</span>
    {!disabled && (
      iconType === 'edit' ? <EditIcon /> : <span className={style.removeIcon}>&times;</span>)}
  </div>
));

export default ActionableBadge;
