import Organization, { OrganizationRisk, OrganizationSize } from 'model/Organization';
import {
  NullableValuesSelectionConstants,
} from 'screens/platform/cross-platform-components/context/MasterFiltersContext/NullableValuesSelection';
import FilterSectionUtils
  from 'screens/platform/cross-platform-components/MasterFiltersPanel/FilterSection/FilterSectionUtils';
import {
  OrganizationsHierarchicalSearchResultsState, ToggleActionPayload,
} from 'screens/platform/cross-platform-components/MasterFiltersPanel/FilterSection/OrganizationsFilterSection/components/OrganizationsHierarchicalSearchResults/OrganizationsHierarchicalSearchResultsReducer';
import HierarchicalInterface from 'utils/HierarchicalDataStructures/HierarchicalInterface';

const { UNDEFINED_PROPERTY } = NullableValuesSelectionConstants;

export enum OrganizationDimension {
  RISK = 'RISK',
  SIZE = 'SIZE',
  SEGMENTS = 'SEGMENTS'
}

const { toggleMultipleHierarchicalEntities, handleGroupToggle } = FilterSectionUtils;

function getOrganizationChangeHandler(
  organizationsMetadata: ToggleActionPayload<Organization['id']>['organizationsMetadata'],
  state: OrganizationsHierarchicalSearchResultsState,
  shouldUpdateOrganization = true,
  toggledDimension: OrganizationDimension | null,
) {
  const dimensionsToUpdate = Object.values(OrganizationDimension)
    .filter((dimension) => dimension !== toggledDimension);

  return (organizationId: string, isSelected: boolean) => {
    if (shouldUpdateOrganization) {
      toggleMultipleHierarchicalEntities(
        isSelected,
        [state.organizationsSelection.getChild(organizationId)],
      );
    }

    const organizationMetadata = organizationsMetadata.allOrganizationsMetadata[organizationId];
    if (organizationMetadata) {
      const { risk, size } = organizationMetadata;
      const segments = organizationMetadata.getSegments();
      const groupsToUpdate: (HierarchicalInterface | undefined)[] = [];

      if (dimensionsToUpdate.includes(OrganizationDimension.RISK)) {
        groupsToUpdate.push(
          state.riskSelection.getChild([risk ?? UNDEFINED_PROPERTY, organizationId]),
        );
      }
      if (dimensionsToUpdate.includes(OrganizationDimension.SIZE)) {
        groupsToUpdate.push(
          state.sizeSelection.getChild([size ?? UNDEFINED_PROPERTY, organizationId]),
        );
      }
      if (dimensionsToUpdate.includes(OrganizationDimension.SEGMENTS)) {
        if (segments === null) {
          groupsToUpdate.push(
            state.segmentSelection.getChild([UNDEFINED_PROPERTY, organizationId]),
          );
        } else {
          segments.forEach((segment) => {
            groupsToUpdate.push(
              state.segmentSelection.getChild([segment, organizationId]),
            );
          });
        }
      }

      toggleMultipleHierarchicalEntities(isSelected, groupsToUpdate);
    }
  };
}

const OrganizationsHierarchicalSearchResultsReducerActions = {
  handleOrganizationToggle(
    state: OrganizationsHierarchicalSearchResultsState,
    { id, organizationsMetadata }: ToggleActionPayload<Organization['id']>,
  ): OrganizationsHierarchicalSearchResultsState {
    if (id === null) {
      const isSelected = state.organizationsSelection.toggle();
      toggleMultipleHierarchicalEntities(isSelected, [
        state.riskSelection, state.sizeSelection, state.segmentSelection,
      ]);
    } else {
      const organizationSelection = state.organizationsSelection.getChild(id);
      if (!organizationSelection) return state;

      const isSelected = organizationSelection.toggle();
      const handleOrganizationUpdate = getOrganizationChangeHandler(
        organizationsMetadata,
        state,
        false,
        null,
      );
      handleOrganizationUpdate(id, isSelected);
    }

    return state;
  },

  handleRiskToggle(
    state: OrganizationsHierarchicalSearchResultsState,
    { id, organizationsMetadata }: ToggleActionPayload<OrganizationRisk | 'UNDEFINED_PROPERTY'>,
  ): OrganizationsHierarchicalSearchResultsState {
    const handleOrganizationUpdate = getOrganizationChangeHandler(
      organizationsMetadata,
      state,
      true,
      OrganizationDimension.RISK,
    );

    handleGroupToggle(
      id,
      state.riskSelection,
      state.organizationsSelection,
      organizationsMetadata.risksToOrganizationsMap,
      handleOrganizationUpdate,
    );

    return state;
  },

  handleSizeToggle(
    state: OrganizationsHierarchicalSearchResultsState,
    { id, organizationsMetadata }: ToggleActionPayload<OrganizationSize | 'UNDEFINED_PROPERTY'>,
  ): OrganizationsHierarchicalSearchResultsState {
    const handleOrganizationUpdate = getOrganizationChangeHandler(
      organizationsMetadata,
      state,
      true,
      OrganizationDimension.SIZE,
    );

    handleGroupToggle(
      id,
      state.sizeSelection,
      state.organizationsSelection,
      organizationsMetadata.sizesToOrganizationsMap,
      handleOrganizationUpdate,
    );
    return state;
  },

  handleSegmentToggle(
    state: OrganizationsHierarchicalSearchResultsState,
    { id, organizationsMetadata }: ToggleActionPayload<string | 'UNDEFINED_PROPERTY'>,
  ): OrganizationsHierarchicalSearchResultsState {
    const handleOrganizationUpdate = getOrganizationChangeHandler(
      organizationsMetadata,
      state,
      true,
      OrganizationDimension.SEGMENTS,
    );

    handleGroupToggle(
      id,
      state.segmentSelection,
      state.organizationsSelection,
      organizationsMetadata.segmentsToOrganizationsMap,
      handleOrganizationUpdate,
    );
    return state;
  },
};

export default OrganizationsHierarchicalSearchResultsReducerActions;
