import React, { forwardRef, ReactNode } from 'react';

import style from 'screens/platform/contentScreens/AnalyticsScreen/components/AnalyticsScreenSection/style.module.scss';
import chartStyle from 'screens/platform/contentScreens/AnalyticsScreen/widgets/chartStyle.module.scss';

interface Props {
  children: ReactNode;
}

const AnalyticsScreenSection = forwardRef<HTMLElement, Props>(({ children }, ref) => (
  <section ref={ref} className={style.analyticsScreen}>
    <div className={chartStyle.chartSection}>
      { children }
    </div>
  </section>
));

export default AnalyticsScreenSection;
