import classNames from 'classnames';
import React from 'react';

import MultiDepartmentsBadges from 'common-ui-components/MultiDepartmentsBadges';
import MultiOrganizationsImages from 'common-ui-components/MultiImages/MultiOrganizationsImages';
import MultiPersonsImages from 'common-ui-components/MultiImages/MultiPersonsImages';

import { ReactComponent as RoundedBadgeSkeleton } from 'assets/img/interactionsTable/rounded-badge-skeleton.svg';

import style
  from 'screens/platform/cross-platform-components/Interactions/components/InteractionsSummary/InteractionsSummaryMetadata/style.module.scss';

interface Props {
  peopleIds?: string[];
  departments?: string[];
  organizationsIds?: string[];
  loading: boolean;
}

export default function ParticipantsSection({
  peopleIds = [],
  departments = [],
  organizationsIds = [],
  loading,
}: Props) {
  if (loading) {
    return (
      <div className={classNames(style.participantsSection, style.skeleton)}>
        <RoundedBadgeSkeleton />
        <RoundedBadgeSkeleton />
        <RoundedBadgeSkeleton />
      </div>
    );
  }

  return (
    <div className={style.participantsSection}>
      <span className={style.images}>
        {organizationsIds.length > 0 ? (<MultiOrganizationsImages ids={organizationsIds} />) : null}
        {peopleIds.length > 0 ? (<MultiPersonsImages ids={peopleIds} />) : null}
      </span>
      {departments.length > 0 ? <MultiDepartmentsBadges departments={departments} /> : null}
    </div>
  );
}
