import { useMemo } from 'react';

import Api from 'global/api/platformApi';
import { HttpResponseResult } from 'global/api/platformApiHelpers';
import useTenantContext from 'screens/platform/cross-platform-components/context/tenant/TenantContext';
import CachedLoadingQueue from 'utils/CachedLoadingQueue';

function handleResponse(response: HttpResponseResult<Blob>): string | undefined {
  if (response?.data) {
    return URL.createObjectURL(response.data);
  }
  return undefined;
}

export function usePeopleImagesLoader() {
  return useMemo(() => new CachedLoadingQueue({
    asyncLoadingFunction: async (url: string) =>
      Api.Image.getImage(url)
        .then(handleResponse),
  }), []);
}

export function useOrganizationsImagesLoader() {
  const { tenant } = useTenantContext();

  return useMemo(() => new CachedLoadingQueue({
    asyncLoadingFunction: async (orgId: string) =>
      Api.Image.getOrganizationImage(tenant.id, orgId)
        .then(handleResponse),
  }), []);
}

export function useDomainImagesLoader() {
  return useMemo(() => new CachedLoadingQueue({
    asyncLoadingFunction: async (domain: string) =>
      Api.Image.getDomainImage(domain)
        .then(handleResponse),
  }), []);
}
