import { useMemo } from 'react';

import ItemsByGroup from 'global/ItemsByGroup';
import { ColumnConfiguratorHierarchicalItemType } from 'screens/platform/cross-platform-components/ColumnsConfigurator';
import { useHierarchicalItemTypeImplementations } from 'screens/platform/cross-platform-components/HierarchicalSearchInput/HierarchicalItemTypeConfig';
import HierarchicalGroup from 'utils/HierarchicalDataStructures/HierarchicalGroup';

export const getColumnsSelection = (
  allColumnsByGroups: Partial<Record<string, string[]>>,
  selectedColumns: string[],
) => {
  const selection = new HierarchicalGroup(
    'All Attributes',
    allColumnsByGroups,
    false,
  );
  Object.entries(allColumnsByGroups).forEach(([group, items]) => {
    items!.forEach((column) => {
      if (selectedColumns.includes(column)) {
        selection.getChild([group, column])?.select();
      }
    });
  });
  return selection;
};

export default function useColumnsConfiguratorItemsByGroups(
  allColumnsByGroups: Partial<Record<string, string[]>>,
  searchQuery: string,
  type: ColumnConfiguratorHierarchicalItemType,
) {
  const { getItemLabel } = useHierarchicalItemTypeImplementations(type);
  return useMemo(() => {
    const result = Object.entries(allColumnsByGroups)
      .map(([group, items]) => ({
        group,
        items,
      })) as ItemsByGroup<string>[];

    if (searchQuery.length > 0) {
      const searchQueryLower = searchQuery.toLowerCase();
      return result.reduce((acc, { group, items }) => {
        if (group.toLowerCase().includes(searchQueryLower)) {
          acc.push({ group, items });
          return acc;
        }
        const filteredItems = items.filter((item) => {
          const itemLabel = getItemLabel(item) as string;
          return itemLabel.toLowerCase().includes(searchQueryLower);
        });
        if (filteredItems.length > 0) {
          acc.push({ group, items: filteredItems });
        }
        return acc;
      }, [] as ItemsByGroup[]);
    }
    return result;
  }, [searchQuery]);
}
