import React from 'react';

import { Heading3 } from 'common-ui-components/Typograhpy';
import BadgesList from 'screens/platform/cross-platform-components/BadgesList';

import style from 'es-src/screens/HomeScreen/components/ResultSection/RegularSearchResults/style.module.scss';

interface SearchResultsSectionProps<T> {
  title: string;
  data: T[];
  renderBadge: (item: T) => JSX.Element;
}

export default function SearchResultsSection<T>({
  title,
  data,
  renderBadge,
}: SearchResultsSectionProps<T>) {
  if (data.length === 0) return null;

  return (
    <div className={style.sectionContainer}>
      <Heading3>{title}</Heading3>
      <BadgesList<T> data={data}>
        {renderBadge}
      </BadgesList>
    </div>
  );
}
