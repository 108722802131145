import DebuggerConsole from 'utils/DebuggerConsole';

class InteractionsCountValidator {
  private expectedInteractionsCount: number | null = null;

  public setExpectedInteractionsCount(nextValue: number | null) {
    if (nextValue !== null) {
      this.expectedInteractionsCount = nextValue;
    }
  }

  public validate(value: number) {
    if (value !== this.expectedInteractionsCount) {
      DebuggerConsole.error('Interactions count mismatches expected value', { value, expected: this.expectedInteractionsCount });
    }
    this.clear();
  }

  private clear() {
    this.expectedInteractionsCount = null;
  }
}

export default new InteractionsCountValidator();
