import { getRequest, HttpResponse, putRequest } from 'global/api/platformApiHelpers';
import App from 'global/lists/apps';
import Tenant from 'model/Tenant';

const TenantController = {
  async getTenant(tenantId?: Tenant['id']): HttpResponse<Tenant> {
    return getRequest('tenant', { tenantId: tenantId || null });
  },

  async getAllTenants(): HttpResponse<Tenant[]> {
    return getRequest('tenant/findAll');
  },

  async getAppsIds(tenantId: Tenant['id']): HttpResponse<Record<App, string>> {
    return getRequest('tenant/app', { tenantId });
  },

  AkoodaiApproval: {
    async getAkoodaiApprovalStatus(tenantId: Tenant['id']): HttpResponse<boolean> {
      return getRequest('tenant/integration/slack-bot', { tenantId });
    },

    async approveAkoodaiIntegration(tenantId: Tenant['id']): HttpResponse<void> {
      return putRequest('tenant/integration/slack-bot', {}, { params: { tenantId } });
    },
  },
};

export default TenantController;
