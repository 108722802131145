import React, { FC, useMemo } from 'react';

import SettingsCheckboxesSection, { SettingsCheckboxOption } from 'common-ui-components/SettingsPopover/SettingsCheckboxesSection';
import { useGraphFeatures } from 'screens/platform/cross-platform-components/context/graphFeatures/GraphFeaturesContext';
import { GraphFeaturesKey } from 'screens/platform/cross-platform-components/context/graphFeatures/GraphFeaturesReducer';

export interface GraphFeaturesDisplayOption {
  label: string;
  key: GraphFeaturesKey;
}

interface Props {
  options: GraphFeaturesDisplayOption[];
}

const GraphFeaturesCheckboxes: FC<Props> = ({ options }) => {
  const graphFeatures = useGraphFeatures();

  const toggleGraphFeature = (key: GraphFeaturesKey) =>
    graphFeatures.dispatchGraphFeatures({ type: 'TOGGLE', payload: { key } });

  const parsedOptions = useMemo<SettingsCheckboxOption[]>(
    () =>
      options.map((option) => ({
        ...option,
        checked: graphFeatures[option.key],
        onClick: () => toggleGraphFeature(option.key),
      })),
    [options, graphFeatures],
  );

  return (
    <SettingsCheckboxesSection title="Display" options={parsedOptions} />
  );
};

export default GraphFeaturesCheckboxes;
