import React from 'react';

import DepartmentBadge from 'common-ui-components/Badge/DepartmentBadge';
import TagBadge from 'common-ui-components/Badge/TagBadge';
import ChangeInAttentionEvent from 'model/Events/ChangeInAttentionEvent';

import style from 'screens/platform/cross-platform-components/EventsPanel/EventCell/EventTitle/style.module.scss';

interface Props {
  event: ChangeInAttentionEvent;
}

export default function ChangeInAttentionTitle({ event }: Props) {
  const { department, tag, relativeSOAPercentage } = event;
  const SOAPercentage = Math.abs(Math.ceil(relativeSOAPercentage));
  return (
    <div className={style.title}>
      {`${SOAPercentage}% of`}
      <DepartmentBadge department={department} />
      interactions are about
      <TagBadge tag={tag} />
    </div>
  );
}
