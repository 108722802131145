import React, { FC, useEffect } from 'react';

import { useGlobalLoading } from 'screens/platform/cross-platform-components/context/GlobalLoadingContextProvider';
import useTenantContext from 'screens/platform/cross-platform-components/context/tenant/TenantContext';

const TenantLoader: FC = ({ children }) => {
  const { tenant } = useTenantContext();
  const { setFullScreenLoading } = useGlobalLoading();

  const isTenantLoaded = tenant.name;

  useEffect(() => {
    if (!isTenantLoaded) {
      setFullScreenLoading(true);
    }
  }, [tenant.id]);

  return isTenantLoaded ? <>{children}</> : null;
};

export default TenantLoader;
