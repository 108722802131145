import { FC, useMemo } from 'react';

import { ScreenKey } from 'global/ScreensConfiguration';
import useOrganizationFeatureConfig from 'screens/platform/cross-platform-components/context/configuration/OrganizationFeatureConfigHook';
import lazyWithRetry from 'utils/lazyWithRetry';

import { ReactComponent as peopleIcon } from 'assets/img/icon/people.svg';
import { ReactComponent as organizationsIcon } from 'assets/img/icon/screens/organizations-screen.svg';
import { ReactComponent as tagsIcon } from 'assets/img/icon/tags.svg';

const OrganizationsRouter = lazyWithRetry(() => import('routers/DirectoryRouter/OrganizationsRouter'));
const PeopleRouter = lazyWithRetry(() => import('routers/DirectoryRouter/PeopleRouter'));
const TagsRouter = lazyWithRetry(() => import('routers/DirectoryRouter/TagsRouter'));

export type DirectoryScreen = 'organizations' | 'people' | 'topics';

export interface DirectoryScreenConfig {
  label: string;
  screenKey: ScreenKey;
  Icon: FC;
  Component: FC;
}

export type DirectoryScreensConfigMap = Record<DirectoryScreen, DirectoryScreenConfig>;

const DirectoryScreensConfig: DirectoryScreensConfigMap = Object.freeze({
  organizations: {
    label: 'Organizations',
    screenKey: 'platform.directory.organizations',
    Icon: organizationsIcon,
    Component: OrganizationsRouter,
  },
  people: {
    label: 'People',
    screenKey: 'platform.directory.people',
    Icon: peopleIcon,
    Component: PeopleRouter,
  },
  topics: {
    label: 'Topics',
    screenKey: 'platform.directory.topics',
    Icon: tagsIcon,
    Component: TagsRouter,
  },
});

export default function useFilteredDirectoryScreensConfig() {
  const {
    value: isOrganizationsFeatureActivated,
    loading: loadingOrganizationsConfig,
  } = useOrganizationFeatureConfig();

  function filterDirectoryScreens(entry: [string, DirectoryScreenConfig]) {
    const key = entry[0] as DirectoryScreen;
    return (key !== 'organizations' || isOrganizationsFeatureActivated);
  }

  const filteredScreens = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(DirectoryScreensConfig).filter(filterDirectoryScreens),
      ) as Partial<DirectoryScreensConfigMap>,
    [isOrganizationsFeatureActivated],
  );
  return {
    filteredScreens,
    loading: loadingOrganizationsConfig,
  };
}
