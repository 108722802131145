import FilterableProperty from 'global/api/controller/utils/filtering/FilterableProperty';
import { BaseFilterCondition, FilterFieldType } from 'global/api/controller/utils/filtering/FilterCondition';

interface DateFilterCondition<FieldNameType extends FilterableProperty>
  extends BaseFilterCondition<FieldNameType, Date> {
  fieldType: FilterFieldType.DATE;
  operator: DateFilterOperator;
}

export enum DateFilterOperator {
  IS_AFTER = 'IS_AFTER',
  IS_BEFORE = 'IS_BEFORE',
}

const operatorLabels: Record<DateFilterOperator, string> = {
  [DateFilterOperator.IS_AFTER]: 'is after',
  [DateFilterOperator.IS_BEFORE]: 'is before',
};
export function getDateFilterOperatorLabel(operator: DateFilterOperator) {
  return operatorLabels[operator];
}

export default DateFilterCondition;
