import React, { ReactNode } from 'react';
import LazyLoadComponent from 'react-lazy-load';
import LazyLoadComponentWorkingWithApproximation from 'react-lazyload';

type Props = {
  children: ReactNode;
  /** How many items should be rendered outside the visible window as a buffer  */
  renderedBufferItemsCount?: number;
} & (
  | {
      height: number;
      approximateHeight?: never;
    }
  | {
      height?: never;
      /** Use if the components don't have exact
       * height because of dynamic content - less performant */
      // TODO - remove after we hava pagination for events drawer in analytics screen
      approximateHeight: number;
    }
);

/**
 * Component that renders its child (accepts only a single node) only when it is in
 * the visible part of an overflown parent element.
 * Use this component to wrap list items that are too many or heavy to render
 */
export default function LazyLoad({
  children,
  height,
  approximateHeight,
  renderedBufferItemsCount = 10,
}: Props) {
  return approximateHeight !== undefined ? (
    <LazyLoadComponentWorkingWithApproximation
      once
      height={approximateHeight}
      offset={renderedBufferItemsCount * approximateHeight}
      overflow
      unmountIfInvisible
    >
      {children}
    </LazyLoadComponentWorkingWithApproximation>
  ) : (
    <LazyLoadComponent
      height={height}
      offset={renderedBufferItemsCount * height}
    >
      {children}
    </LazyLoadComponent>
  );
}
