import classNames from 'classnames';
import React from 'react';

import AIBadge from 'common-ui-components/Badge/AIBadge';
import InteractionsCount from 'screens/platform/cross-platform-components/Interactions/components/InteractionsTitle/InteractionsCount';
import { useInteractionsContext } from 'screens/platform/cross-platform-components/Interactions/InteractionsContext';

import style from 'screens/platform/cross-platform-components/Interactions/components/InteractionsTitle/style.module.scss';

interface Props {
  extended: boolean;
}

export default function InteractionsTitle({ extended }: Props) {
  const { interactionsMode } = useInteractionsContext();
  const isSummaryMode = interactionsMode.value === 'summary';

  return (
    <div className={style.headerLeftSection}>
      <div className={style.title}>
        <h2 data-testid="interactions-title">Interactions</h2>
        <AIBadge className={classNames(style.badge, isSummaryMode && style.visible)} />
      </div>
      <div className={style.subtitle}>
        <InteractionsCount visible={extended} />
      </div>
    </div>
  );
}
