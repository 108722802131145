import classNames from 'classnames';
import React from 'react';

import Button from 'common-ui-components/Button';
import InternalLink from 'common-ui-components/InternalLink';
import { useInternalScreenRedirect } from 'global/ScreensConfiguration';
import { useUserContext } from 'screens/platform/cross-platform-components/context/user/UserContext';
import useSwitchTenantModal from 'screens/platform/cross-platform-components/Navbar/components/BottomActions/SwitchTenantModal/useSwitchTenantModal';

import style from 'screens/platform/cross-platform-components/Navbar/components/ScreenButton/style.module.scss';

interface Props {
  isDropdownOpen: boolean;
  toggleDropdown: () => void;
}

export default function UserDropdownContent({ isDropdownOpen, toggleDropdown }: Props) {
  const { personId } = useUserContext();
  const { endUserSession, isSuperAdmin } = useUserContext();
  const openSwitchTenantModal = useSwitchTenantModal();
  const redirectToInternalScreen = useInternalScreenRedirect();

  const handleProfileClick = () => {
    if (!personId) return;
    redirectToInternalScreen(
      'platform.directory.people.profile',
      personId,
    );
  };

  if (!isDropdownOpen) return null;

  return (
    <>
      {isSuperAdmin && (
        <>
          <Button
            className={style.dropdownOption}
            onClick={() => {
              toggleDropdown();
              openSwitchTenantModal();
            }}
          >
            Switch Tenant
          </Button>
          <InternalLink
            to="backoffice.configuration"
            className={classNames(
              style.internalLink,
              style.dropdownOption,
            )}
            onClick={toggleDropdown}
          >
            Backoffice
          </InternalLink>
        </>
      )}
      <Button
        className={style.dropdownOption}
        onClick={() => {
          toggleDropdown();
          handleProfileClick();
        }}
      >
        Your Profile
      </Button>
      <InternalLink
        to="settings"
        className={classNames(
          style.internalLink,
          style.dropdownOption,
        )}
        onClick={toggleDropdown}
      >
        Settings
      </InternalLink>
      <div className={style.separator} />
      <Button
        className={classNames(style.dropdownOption, style.externalLink)}
        onClick={endUserSession}
      >
        Logout
      </Button>
    </>
  );
}
