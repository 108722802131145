import classNames from 'classnames';
import React, { useMemo } from 'react';

import AppIcon from 'common-ui-components/AppIcon';
import ExternalLink from 'common-ui-components/ExternalLink';
import emojiParser from 'common-ui-components/InteractionTitle/parsers/emojiParser';
import AtomSearchMetadata
  from 'es-src/screens/HomeScreen/components/ResultSection/RegularSearchResults/AtomSearchResult/AtomSearchMetadata';
import Size from 'global/lists/Size';
import { Atom } from 'model/Atom';
import InteractionDetailsFooter
  from 'screens/platform/cross-platform-components/InteractionDetails/InteractionDetailsFooter';

import { ReactComponent as ExternalLinkIcon } from 'assets/img/icon/external-link.svg';

import style
  from 'es-src/screens/HomeScreen/components/ResultSection/RegularSearchResults/AtomSearchResult/style.module.scss';

interface Props {
  atom: Atom;
  transparent?: boolean;
}

export default function AtomSearchResult({ atom, transparent = false }: Props) {
  const {
    appId,
    title,
    permalink,
    isPublic,
    mimeType,
    mentionedOrganizations: organizations,
    tags,
    participants,
  } = atom;

  const parsedTitleWithEmojis = useMemo(() => emojiParser(title), [atom]);

  return (
    <ExternalLink
      to={permalink}
      className={classNames(style.atomSearchResult, transparent && style.transparent)}
      aria-label="interaction result"
    >
      <AppIcon
        appId={appId}
        isPublic={isPublic}
        hasBorder={false}
        size={Size.MEDIUM}
        mimeType={mimeType}
      />
      <div className={style.content}>
        <AtomSearchMetadata atom={atom} />
        <div className={style.atomTitle}>{parsedTitleWithEmojis}</div>
        <InteractionDetailsFooter
          tags={Object.keys(tags)}
          organizations={organizations}
          participantsIds={Object.keys(participants)}
        />
      </div>
      <ExternalLinkIcon className={style.externalLinkIcon} />
    </ExternalLink>
  );
}
