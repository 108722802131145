import { useCallback } from 'react';

import ToastManager, { ToastKey } from 'utils/ToastManager';

const MIN_WIDTH = 800;

type HookResponse = {
  isMobile: boolean;
  showMobileWarning: () => void;
};

export default function useMobileWarning(): HookResponse {
  const isMobile = document.body.clientWidth < MIN_WIDTH;

  const showMobileWarning = useCallback(() => {
    ToastManager.show('Try to log in from your desktop device', {
      type: 'warning',
      key: ToastKey.MOBILE_WARNING,
    });
  }, []);

  return {
    isMobile,
    showMobileWarning,
  };
}
