import NullableValuesSelection from 'screens/platform/cross-platform-components/context/MasterFiltersContext/NullableValuesSelection';
import UnionBasedMasterFilter
  from 'screens/platform/cross-platform-components/context/MasterFiltersContext/UnionBasedMasterFilter';

export default class PeopleMasterFilter extends UnionBasedMasterFilter<PeopleMasterFilter> {
  readonly departments: string[] | null;
  readonly teams: NullableValuesSelection<string>;
  readonly internalPeopleIds: string[] | null;
  readonly externalPeopleIds: string[] = [];
  readonly includeUnidentified: boolean;

  /**
   * This constructor isn't developer-friendly BY DESIGN.<br />
   * It should not be used unless all properties are well-defined.<br />
   * If you want to initialize based on specific properties,
   * please use `PeopleMasterFilterInitializersHook`.
   * @see usePeopleMasterFilterInitializers.
   *
   * @param {string[] | null} departments
   * @param {NullableValuesSelection<string>} teams
   * @param {string[] | null} internalPeopleIds
   * @param {boolean} includeUnidentified
   * @param externalPeopleIds
   */
  constructor(
    departments: string[] | null,
    teams: NullableValuesSelection<string>,
    internalPeopleIds: string[] | null,
    includeUnidentified: boolean,
    externalPeopleIds = [] as string[],
  ) {
    super();
    this.departments = departments;
    this.teams = teams;
    this.internalPeopleIds = internalPeopleIds;
    this.externalPeopleIds = externalPeopleIds;
    this.includeUnidentified = includeUnidentified;
  }

  clone() {
    return new PeopleMasterFilter(
      this.departments,
      this.teams,
      this.internalPeopleIds,
      this.includeUnidentified,
      this.externalPeopleIds,
    );
  }

  equalsIgnoreFlags(other: any): boolean {
    if (!super.isSameType(other)) return false;

    return PeopleMasterFilter.areArraysNullOrEquals(this.departments, other.departments)
        && this.teams.equals(other.teams)
        && PeopleMasterFilter.areArraysNullOrEquals(
          this.internalPeopleIds,
          other.internalPeopleIds,
        );
  }

  static generateAllInternalSelected(includeUnidentified: boolean): PeopleMasterFilter {
    return new PeopleMasterFilter(
      null,
      NullableValuesSelection.getAllSelected(),
      null,
      includeUnidentified,
    );
  }

  static generateEmpty(): PeopleMasterFilter {
    return new PeopleMasterFilter(
      [],
      NullableValuesSelection.getEmpty(),
      [],
      false,
    );
  }

  isAllSelected(): boolean {
    return this.departments === null
        && this.teams.isAllSelected()
        && this.internalPeopleIds === null;
  }

  isEmpty(): boolean {
    return this.departments?.length === 0
        || this.teams.isEmpty()
        || this.internalPeopleIds?.length === 0;
  }
}
