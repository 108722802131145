import React from 'react';

import OrganizationBadge from 'common-ui-components/Badge/OrganizationBadge';
import OrganizationEvent from 'model/Events/OrganizationEvent';

import style from 'screens/platform/cross-platform-components/EventsPanel/EventCell/EventTitle/style.module.scss';

interface Props {
  event: OrganizationEvent;
}

export default function FirstExecutiveEngagementTitle({ event }: Props) {
  const { organizationId } = event;

  return (
    <div className={style.title}>
      Someone from leadership stepped in managing
      <OrganizationBadge organizationId={organizationId} />
    </div>
  );
}
