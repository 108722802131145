import classNames from 'classnames';
import React, { ReactNode } from 'react';

import style from 'common-ui-components/SettingsPopover/PopoverSection/style.module.scss';

type Props = {
  title: string;
  noPadding?: boolean;
  children: ReactNode;
};

export default function PopoverSection({
  title,
  noPadding,
  children,
}: Props) {
  return (
    <div className={style.popoverSection}>
      <div className={style.headerWrapper}>
        <h3>{title}</h3>
      </div>
      <div
        className={classNames(style.sectionInnerWrapper, noPadding && style.noPadding)}
      >
        {children}
      </div>
    </div>
  );
}
