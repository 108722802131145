import {
  Table as TableAnt, TableColumnType, TablePaginationConfig, TableProps,
} from 'antd';
import classNames from 'classnames';
import React, { ReactNode } from 'react';
import { DndProvider } from 'react-dnd';

import { CompareFn, GetRowKey, SorterResult } from 'antd/lib/table/interface';
import Spinner from 'common-ui-components/Spinner';
import useColumnsPropsPopulator from 'common-ui-components/Table/columnsPropsPopulatorHook';
import DraggableColumnHeaderCell from 'common-ui-components/Table/DraggableColumnHeaderCell';
import { SortingConfig } from 'common-ui-components/Table/sortingHook';
import FilterConfig, { FilterValue, FilterValues } from 'screens/platform/directory/components/GenericDirectoryScreen/FiltersList/FilterConfig';
import { PickRequired } from 'utils/TypescriptTricks';

import 'common-ui-components/Table/style.scss';
import { HTML5Backend } from 'react-dnd-html5-backend';

type TableSortingConfig<Item> = { byKey: true } | { byComparator: CompareFn<Item> }
export type TableColumn<Item, ColumnId = string> = PickRequired<TableColumnType<Item>, 'render'>
  & Pick<TableColumnType<Item>, 'width' | 'ellipsis' | 'onHeaderCell'>
  & {
  key: ColumnId;
  title: string | React.ReactElement;
  infoTooltip?: string;
  minWidth?: number;
  sorting?: TableSortingConfig<Item>;
  filterConfig?: FilterConfig<FilterValue>;
}

interface Props<RecordType, CustomSortingConfig> extends Omit<TableProps<RecordType>, 'onChange'> {
  rowKey: string | GetRowKey<RecordType>;
  columns: TableColumn<RecordType>[];
  emptyDataMsg?: ReactNode | string;
  loading?: boolean;
  sorting?: SortingConfig<CustomSortingConfig>;
  onSortingChange?: (sorter: SorterResult<RecordType>) => void;
  filtersValues?: FilterValues;
  onFiltersChange?: (filters: Record<string, FilterValue[]>) => void;
  onPaginationChange?: (pagination: TablePaginationConfig) => void;
  onColumnMoved?: (fromIndex: number, toIndex: number) => void;
  stickyHeaderOffset?: number;
}

export default function Table<RecordType extends object, CustomSortingConfig = never>({
  className,
  emptyDataMsg,
  columns,
  loading = false,
  sorting,
  onSortingChange,
  filtersValues,
  onFiltersChange,
  onPaginationChange,
  onColumnMoved,
  // in most of our places the table is rendered within a scrollable container with 16px padding
  // this offset here fixes a problem where table items are visible in this padding
  // above the sticky header
  stickyHeaderOffset = -16,
  pagination,
  ...restProps
}: Props<RecordType, CustomSortingConfig>) {
  const {
    columnsWithPopulatedProps,
    areColumnsTooWide,
    tableRef,
  } = useColumnsPropsPopulator<RecordType, CustomSortingConfig>(
    columns,
    Boolean(onColumnMoved),
    sorting,
    filtersValues,
  );

  const TableHeaderCell = ({ index, ...restCellProps }) =>
    (onColumnMoved ? (
      <DraggableColumnHeaderCell
        index={index}
        onDrop={onColumnMoved}
        {...restCellProps}
      />
    ) : (
      <th {...restCellProps} />
    ));

  const onTableChange: TableProps<RecordType>['onChange'] = (
    nextPagination,
    nextFilters,
    nextSorter,
    { action },
  ) => {
    switch (action) {
      case 'sort':
        onSortingChange?.(nextSorter as SorterResult<RecordType>);
        break;
      case 'filter': {
        const filters = Object.entries(nextFilters).reduce((acc, [key, filter]) => {
          if (filter !== null && typeof filter[0] !== 'boolean') {
            acc[key] = filter as FilterValue[];
          }
          return acc;
        }, {} as Record<string, FilterValue[]>);
        onFiltersChange?.(filters);
        break;
      }
      case 'paginate':
        onPaginationChange?.(nextPagination);
        break;
      default:
        break;
    }
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <TableAnt<RecordType>
        ref={(ref) => { tableRef.current = ref?.nativeElement; }}
        components={{
          header: {
            cell: TableHeaderCell,
          },
        }}
        columns={columnsWithPopulatedProps}
        locale={{ emptyText: loading ? ' ' : emptyDataMsg }}
        className={classNames('tableWrapper', className)}
        size="middle"
        showSorterTooltip={false}
        loading={{
          spinning: loading,
          indicator: (
            <div className="spinnerWrapper">
              <Spinner />
            </div>
          ),
        }}
        scroll={{ x: areColumnsTooWide ? 'max-content' : undefined }}
        pagination={pagination ? { ...pagination, position: ['bottomCenter'] } : false}
        sticky={{
          offsetHeader: stickyHeaderOffset,
        }}
        onChange={onTableChange}
        {...restProps}
      />
    </DndProvider>
  );
}
