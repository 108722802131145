import { notification } from 'antd';
import classNames from 'classnames';
import React, { ReactNode } from 'react';

import { ArgsProps } from 'antd/lib/notification';

import { ReactComponent as XIcon } from 'assets/img/icon/x-icon.svg';

import 'common-ui-components/Toast/style.scss';

export enum ToastKey {
  BROWSER_WARNING = 'BROWSER_WARNING',
  MOBILE_WARNING = 'MOBILE_WARNING',
  SEARCH_HINT = 'SEARCH_HINT',
  ORGANIZATIONS_TABLE_ERROR = 'ORGANIZATIONS_TABLE_ERROR',
  DIRECTORY_PROFILE_SUMMARY_ERROR = 'DIRECTORY_PROFILE_SUMMARY_ERROR',
  ORGANIZATIONS_TABLE_SUCCESS = 'ORGANIZATIONS_TABLE_SUCCESS',
  EXPORT_DIRECTORY_TABLE_ERROR = 'EXPORT_DIRECTORY_TABLE_ERROR',
  EXPORT_DIRECTORY_TABLE_SUCCESS = 'EXPORT_DIRECTORY_TABLE_SUCCESS',
  ORG_CHART_UPLOAD_SUCCESS = 'ORG_CHART_UPLOAD_SUCCESS',
  LIMITED_ACCESS_TO_TENANT = 'LIMITED_ACCESS_TO_TENANT',
  METRIC_UPDATE_ERROR = 'METRIC_UPDATE_ERROR',
  OBJECTIVE_UPDATE_ERROR = 'OBJECTIVE_UPDATE_ERROR',
  OBJECTIVE_UPDATE_SUCCESS = 'OBJECTIVE_UPDATE_SUCCESS',
  REFINE_SEARCH_TERM = 'REFINE_SEARCH_TERM',
  MISSING_PROFILE_PAGE = 'MISSING_PROFILE_PAGE',
  MISSING_SLACK_INTEGRATION = 'MISSING_SLACK_INTEGRATION',
  FEEDBACK = 'FEEDBACK',
  INTERACTIONS_SUMMARY = 'INTERACTIONS_SUMMARY',
  ADD_SUGGESTED_TAGS_ERROR = 'ADD_SUGGESTED_TAGS_ERROR',
  ADD_SUGGESTED_TAGS_SUCCESS = 'ADD_SUGGESTED_TAGS_SUCCESS',
  REQUEST_TAG = 'REQUEST_TAG',
  TAG_SETTINGS_API_ERROR = 'TAG_SETTINGS_API_ERROR',
  EXTENSION_SETTINGS_CHANGED = 'EXTENSION_SETTING_CHANGED'
}

const AUTO_HIDE_DURATION = 3;
const NAVBAR_HEIGHT = 64;
const SINGLE_SPACE = 8;

type ToastConfig = {
  type?: ArgsProps['type'];
  autoHide?: boolean;
  key: string;
  className?: string;
  duration?: number;
};

notification.config({
  top: NAVBAR_HEIGHT + SINGLE_SPACE,
  closeIcon: <XIcon width={16} height={16} />,
});

export default class ToastManager {
  static show(content: ReactNode, {
    key, type, duration, className, autoHide = true,
  }: ToastConfig) {
    notification.open({
      message: content,
      duration: duration ?? (autoHide ? AUTO_HIDE_DURATION : 0),
      type,
      key,
      className: classNames('toast', className),
    });
  }
}
