import classNames from 'classnames';
import React from 'react';
import { useLocation } from 'react-router-dom';

import Button from 'common-ui-components/Button';
import { useCustomAtomsFilters } from 'screens/platform/cross-platform-components/context/CustomFilters/CustomAtomsFilters/CustomAtomsFiltersContextProvider';
import useTagRequest from 'screens/platform/cross-platform-components/Hook/TagRequestHook';

import { ReactComponent as CheckIcon } from 'assets/img/icon/check.svg';
import { ReactComponent as TagIcon } from 'assets/img/icon/tag.svg';

import style from 'screens/platform/cross-platform-components/Interactions/components/InteractionsTable/components/EmptyDataMessage/style.module.scss';

function extractSearchValue(searchString: string): string {
  const searchParams = new URLSearchParams(searchString);
  return searchParams.get('search') ?? '';
}

export default function EmptyDataMessage() {
  const { filteredAtomsCount } = useCustomAtomsFilters();
  const { search } = useLocation();
  const { handleTagRequest, isButtonDisabled } = useTagRequest();

  const searchValue = extractSearchValue(search);

  const onTagRequestClick = () => {
    handleTagRequest(searchValue, 'Interactions Table');
  };

  if (!searchValue) {
    return <div>No interactions match current filters</div>;
  }

  if (filteredAtomsCount?.restrictedAtoms.exists) {
    return <div>The matching interactions are private</div>;
  }

  return (
    <div className={style.interactionTableEmptyDataMessage}>
      <TagIcon className={style.icon} />
      <h2 className={style.title}>Request to add a tag</h2>
      <p className={style.subtitle}>Submit a request to add this as a tag.</p>
      <Button
        onClick={onTagRequestClick}
        className={classNames(style.button, isButtonDisabled && style.disabled)}
        color="blue"
        padded
        rounded
        disabled={isButtonDisabled}
      >
        {isButtonDisabled ? <CheckIcon width={16} height={16} /> : null}
        <span>
          {isButtonDisabled ? 'Request was submitted' : 'Submit a request'}
        </span>
      </Button>
    </div>
  );
}
