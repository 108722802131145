import { useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useMasterFilters } from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFiltersContext';
import useMasterFiltersFromUrlParams from 'screens/platform/cross-platform-components/context/UrlParams/convertUrlParamsToMasterFilters';
import useMasterFiltersUrlParamsGenerator from 'screens/platform/cross-platform-components/context/UrlParams/masterFiltersUrlParamsGeneratorHook';
import UrlParamsUtils from 'screens/platform/cross-platform-components/context/UrlParams/UrlParamsUtils';
import DebuggerConsole from 'utils/DebuggerConsole';

export const SEARCH_URL_PARAM_KEY = 'search';
export const ENTERPRISE_SEARCH_QUESTION_KEY = 's';
export const EXTENSION_SETTINGS_EVENT_KEY = 'event';

export default function UrlParamsGenerator() {
  const {
    currentFilters, shouldPushParamsToUrl, dispatchMasterFilters,
  } = useMasterFilters();
  const history = useHistory();
  const { search, pathname } = useLocation();
  const masterFiltersFromUrl = useMasterFiltersFromUrlParams();
  const skipUpdatingMasterFiltersFromUrl = useRef(true);
  const generateUrlParams = useMasterFiltersUrlParamsGenerator();

  useEffect(() => {
    if (!skipUpdatingMasterFiltersFromUrl.current) {
      dispatchMasterFilters({
        type: 'APPLY_URL_FILTERS',
        payload: {
          currentFilters: masterFiltersFromUrl,
        },
      });
    }
    skipUpdatingMasterFiltersFromUrl.current = false;
  }, [masterFiltersFromUrl]);

  useEffect(() => {
    if (shouldPushParamsToUrl) {
      const currentUrlParams = new URLSearchParams(search);
      const nextUrlParams = new URLSearchParams(generateUrlParams(currentFilters));

      const nonMasterFiltersParams = UrlParamsUtils.getNonMasterFiltersParams(
        pathname,
        currentUrlParams,
      );
      nonMasterFiltersParams.forEach((value, key) => nextUrlParams.append(key, value));

      if (
        nextUrlParams.size === 0
        && currentUrlParams.size === 1
        && (
          currentUrlParams.get(ENTERPRISE_SEARCH_QUESTION_KEY)
          || currentUrlParams.get(EXTENSION_SETTINGS_EVENT_KEY)
        )
      ) {
        DebuggerConsole.log('Has URL param for enterprise search or extension settings, ignoring overriding master filters');
      } else if (nextUrlParams.toString() !== currentUrlParams.toString()) {
        const nextSearch = UrlParamsUtils.getAsUrlEncodedLocationSearch(nextUrlParams);
        history.replace({ search: nextSearch });
        skipUpdatingMasterFiltersFromUrl.current = true;
      }
      dispatchMasterFilters({ type: 'UPDATED_URL_PARAMS_ACK' });
    }
  }, [shouldPushParamsToUrl, search]);

  return null;
}
