import { useEffect } from 'react';

import { TRANSITION_ANIMATION_LENGTH } from 'global/constants';
import { usePlatformContext } from 'screens/platform/cross-platform-components/context/platform/PlatformContext';
import {
  InteractionsTableState,
} from 'screens/platform/cross-platform-components/Panels/BottomPanel/CollapsibleInteractionsDrawer';
import { useMountedState } from 'utils/hooks';

export default function useInteractionsTableRenderStatus(isCollapsible: boolean) {
  const [
    shouldRenderInteractionsTable,
    setShouldRenderInteractionsTable,
  ] = useMountedState(!isCollapsible);
  const [isDuringOpeningTransition, setIsDuringOpeningTransition] = useMountedState(false);

  const { interactionsTable } = usePlatformContext();

  useEffect(() => {
    if (!isCollapsible) {
      return () => null;
    }

    let timeoutId;

    if (interactionsTable.openState !== InteractionsTableState.CLOSED) {
      if (interactionsTable.openState !== InteractionsTableState.OPENED) {
        setIsDuringOpeningTransition(true);
      }

      timeoutId = setTimeout(() => {
        const isStillOpen = interactionsTable.openState !== InteractionsTableState.CLOSED;
        if (isStillOpen) {
          setShouldRenderInteractionsTable(true);
          setIsDuringOpeningTransition(false);
        }
      }, TRANSITION_ANIMATION_LENGTH);
    } else {
      timeoutId = setTimeout(() => {
        const isStillClosed = interactionsTable.openState === InteractionsTableState.CLOSED;
        if (isStillClosed) {
          setShouldRenderInteractionsTable(false);
        }
      }, TRANSITION_ANIMATION_LENGTH);
    }

    return () => clearTimeout(timeoutId);
  }, [isCollapsible, interactionsTable.openState]);

  return {
    shouldRenderInteractionsTable,
    isDuringOpeningTransition,
  };
}
