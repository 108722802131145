import classNames from 'classnames';
import React from 'react';

import Button from 'common-ui-components/Button';

import { ReactComponent as SettingsIcon } from 'assets/img/icon/settings.svg';

import style from 'common-ui-components/SettingsPopover/SettingsButton/style.module.scss';

interface Props {
  onClick: () => void;
  className?: string;
}

export default function SettingsButton({ onClick, className }: Props) {
  return (
    <Button
      className={classNames(style.settingsButton, className)}
      onClick={onClick}
      color="gray"
      rounded
    >
      <SettingsIcon />
    </Button>
  );
}
