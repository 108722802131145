import classNames from 'classnames';
import React from 'react';

import Button from 'common-ui-components/Button';
import { ListOptions } from 'global/ListOptions';

import style from 'common-ui-components/OptionsPicker/style.module.scss';

interface OptionsPickerProps<T> {
  selectedValue: null | T;
  options: ListOptions<T>;
  setValue: (nextValue: T) => void;
  disabled?: boolean;
}

export default function OptionsPicker<T>({
  options, setValue, selectedValue, disabled = false,
}: OptionsPickerProps<T>) {
  return (
    <div className={style.optionsPicker}>
      {
        options.map((option) => (
          <Button
            key={`${option.value}`}
            className={classNames({ [style.selected]: selectedValue === option.value })}
            onClick={() => setValue(option.value)}
            disabled={disabled}
          >
            { option.label }
          </Button>
        ))
      }
    </div>
  );
}
