import React from 'react';

import Button from 'common-ui-components/Button';
import Dropdown from 'common-ui-components/Dropdown';
import RestrictedViewPopoverContent from 'screens/platform/cross-platform-components/Interactions/components/ExtendedInteractionsTableHeader/RestrictedViewButton/RestrictedViewPopoverContent';
import { useToggle } from 'utils/hooks';

import { ReactComponent as LockIcon } from 'assets/img/icon/lock.svg';

import style from 'screens/platform/cross-platform-components/Interactions/components/ExtendedInteractionsTableHeader/RestrictedViewButton/style.module.scss';

export default function RestrictedViewButton() {
  const [isPopoverOpen, togglePopover] = useToggle(false);

  return (
    <Dropdown
      isDropdownOpen={isPopoverOpen}
      closeDropdown={togglePopover}
      placement="left-start"
      popperOptions={{ enableFlipping: false }}
      toggleButtonElement={(
        <Button
          onClick={togglePopover}
          className={style.restrictedViewBtn}
          padded
          rounded
          color="gray"
        >
          <LockIcon className={style.lockIcon} />
          Personalized View
        </Button>
      )}
    >
      <RestrictedViewPopoverContent closePopover={togglePopover} />
    </Dropdown>
  );
}
