import React from 'react';

import { Orientation } from 'model/Atom';
import StringUtils from 'utils/StringUtils';

import style from 'screens/platform/cross-platform-components/Interactions/components/InteractionsTable/components/metricBadge.module.scss';

type Props = {
  orientation?: Orientation;
};

export default function AtomOrientationBadge({ orientation }: Props) {
  return orientation ? (
    <div className={style.metricBadge}>{StringUtils.capitalize(orientation)}</div>
  ) : null;
}
