import React from 'react';

import colors from 'global/colors';

export default function PointHighlight(props) {
  const { currentPoint, borderWidth, borderColor } = props;
  if (currentPoint) {
    return (
      <g>
        <circle
          fill={colors.BLUE}
          r={12}
          strokeWidth={borderWidth}
          stroke={borderColor}
          fillOpacity={0.2}
          cx={currentPoint.x}
          cy={currentPoint.y}
        />
      </g>
    );
  }
  return null;
}
