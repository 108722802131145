import { useMemo } from 'react';

import isMonday from 'date-fns/isMonday';
import { AKOODA_TENANT_ID } from 'global/constants';
import useTenantContext from 'screens/platform/cross-platform-components/context/tenant/TenantContext';
import { useUserContext } from 'screens/platform/cross-platform-components/context/user/UserContext';

type WelcomeGreeting = 'Happy Monday' | 'Good morning' | 'Good afternoon' | 'Good evening' | 'Good night'

export function useWelcomeGreeting(): WelcomeGreeting {
  return useMemo(() => {
    const now = new Date();

    if (isMonday(now)) {
      return 'Happy Monday';
    }

    const hours = now.getHours();
    if (hours >= 6 && hours < 12) {
      return 'Good morning';
    }
    if (hours >= 12 && hours < 16) {
      return 'Good afternoon';
    }
    if (hours >= 16 && hours < 21) {
      return 'Good evening';
    }
    return 'Good night';
  }, []);
}

export function useFirstName(): string {
  const { name, firstName } = useUserContext();

  const getFirstName = () => {
    if (firstName) return firstName;
    return name ? name.trim().split(' ')[0] : '';
  };

  return useMemo(getFirstName, [name, firstName]);
}

export default function useWelcomeMessage(): string {
  const firstName = useFirstName();
  const { tenantId: userTenantId } = useUserContext();
  const welcomeGreeting = useWelcomeGreeting();
  const { tenant } = useTenantContext();

  const plainWelcomeMessage = `${welcomeGreeting}, ${firstName}`;

  if (userTenantId !== AKOODA_TENANT_ID) {
    return plainWelcomeMessage;
  }

  if (userTenantId === AKOODA_TENANT_ID && tenant.id === AKOODA_TENANT_ID) {
    return plainWelcomeMessage;
  }

  return `${plainWelcomeMessage} (this is ${tenant.name})`;
}
