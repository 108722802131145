import { useCallback, useMemo } from 'react';

import { TableColumn } from 'common-ui-components/Table';
import { Atom } from 'model/Atom';
import { usePlatformContext } from 'screens/platform/cross-platform-components/context/platform/PlatformContext';
import useColumnsConfig, {
  InteractionsTableColumnsOrder,
  useDefaultInteractionsTableColumns,
} from 'screens/platform/cross-platform-components/Interactions/components/InteractionsTable/columnsConfigUtils';
import InteractionsTableColumn
  from 'screens/platform/cross-platform-components/Interactions/components/InteractionsTable/InteractionsTableColumn';

export interface InteractionsTableColumnProps extends TableColumn<Atom> {
  id: InteractionsTableColumn;
}

export default function useInteractionsTableColumns(): {
  columns: InteractionsTableColumnProps[];
  onColumnMoved: (fromIndex: number, toIndex: number) => void;
  } {
  const { interactionsTableColumns, dispatchPlatformContext } = usePlatformContext();
  const defaultInteractionsTableColumns = useDefaultInteractionsTableColumns();

  const setInteractionsTableColumns = useCallback((nextColumns: InteractionsTableColumnsOrder) => {
    dispatchPlatformContext({
      type: 'SET_INTERACTIONS_TABLE_COLUMNS',
      payload: { nextColumns },
    });
  }, [dispatchPlatformContext]);

  let persistentColumns = Object.entries(interactionsTableColumns);

  const allColumns = useColumnsConfig();
  const existingColumnsLabels = Object.keys(allColumns);
  const areAllColumnsDefined = persistentColumns.every(([columnLabel]) =>
    existingColumnsLabels.includes(columnLabel));

  if (!areAllColumnsDefined) {
    setInteractionsTableColumns(defaultInteractionsTableColumns);
    persistentColumns = Object.entries(defaultInteractionsTableColumns);
  }

  const columns = useMemo(
    () =>
      persistentColumns
        .sort(([, indexA], [, indexB]) => indexA! - indexB!)
        .map(([columnId]) => ({ id: columnId, ...allColumns[columnId] })),
    [interactionsTableColumns, allColumns],
  );

  const onColumnMoved = useCallback((fromIndex: number, toIndex: number) => {
    const nextColumnsArray = [...columns];
    const item = nextColumnsArray.splice(fromIndex, 1)[0];
    nextColumnsArray.splice(toIndex, 0, item);
    const nextColumnsConfig = nextColumnsArray.reduce((acc, c, index) => ({
      ...acc,
      [c.id]: index,
    }), {});
    setInteractionsTableColumns(nextColumnsConfig);
  }, [setInteractionsTableColumns, columns]);

  return { columns, onColumnMoved };
}
