import ArrayUtils from 'utils/ArrayUtils';

export default {
  caseInsensitiveComparator(mapFunc = (x) => x) {
    return (_a, _b) => {
      const [a, b] = [_a, _b].map((x) => mapFunc(x).toLowerCase());
      if (a < b) return -1;
      if (a > b) return 1;
      return 0;
    };
  },

  alphabeticalDescendingSorter(a: string, b: string) {
    return a.localeCompare(b, 'en', { numeric: true, caseFirst: 'upper' });
  },

  isValidEmailDomain(emailDomain: string): boolean {
    // regex was taken from http://emailregex.com
    return /^[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)$/.test(emailDomain);
  },

  capitalize(str: string) {
    return str.split(' ').map((word) => word[0].toUpperCase() + word.substring(1).toLowerCase()).join(' ');
  },

  isEmailValid(email: string): boolean {
    const regexEmail = /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regexEmail.test(email);
  },

  equalsIgnoreCase(a: string, b: string) {
    return a.toLowerCase() === b.toLowerCase();
  },

  /**
   * Given a string, this function deterministically calculates a number between 0 and 1
   */
  hashStringToNumber(str: string): number {
    let sum = 0;
    ArrayUtils.getIntegers(str.length).forEach((i) => {
      const hash = str.codePointAt(i) || 1;
      // eslint-disable-next-line no-bitwise
      sum += ((i + 1) * hash) / (1 << 8);
    });
    return ((sum * 100) % 80) / 100;
  },
};
