import { useEffect } from 'react';

import SessionStorageUtils, { SessionStorageKey } from 'utils/SessionStorageUtils';
import ToastManager, { ToastKey } from 'utils/ToastManager';

export const isChrome = navigator.userAgent.includes('Chrome');
const isMicrosoftEdge = navigator.userAgent.includes('Edg');
const isChromium = isChrome || isMicrosoftEdge;

export default function useBrowserWarning() {
  useEffect(() => {
    if (isChromium) return;

    const isBrowserWarningShown = SessionStorageUtils.getItem<boolean>(
      SessionStorageKey.WAS_BROWSER_WARNING_SHOWN,
    );
    if (!isBrowserWarningShown) {
      ToastManager.show('Akooda works best with Chrome or Edge. Switch browsers?', {
        type: 'warning',
        key: ToastKey.BROWSER_WARNING,
      });
      SessionStorageUtils.setItem(
        SessionStorageKey.WAS_BROWSER_WARNING_SHOWN,
        true,
      );
    }
  }, []);
}
