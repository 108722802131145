import React from 'react';

import Tooltip from 'common-ui-components/Tooltip';
import { significanceColorsData } from 'global/lists/significanceColors';

import style from 'screens/platform/cross-platform-components/Interactions/components/InteractionsTable/components/metricBadge.module.scss';

type Props = {
  significance: number;
  relativeSignificance: number;
};

export default function SignificanceBadge({
  significance,
  relativeSignificance,
}: Props) {
  const { color, label } = significanceColorsData[relativeSignificance];
  return (
    <Tooltip content={significance.toLocaleString()}>
      <div className={style.metricBadge}>
        <div className={style.circle} style={{ backgroundColor: color }} />
        <span>{label}</span>
      </div>
    </Tooltip>
  );
}
