import { RequestSorting } from 'global/api/controller/utils/commonControllerUtils';
import OrganizationsTablePropertyColumnId from 'screens/platform/directory/organizations/OrganizationsDirectoryScreen/OrganizationsTable/utils/OrganizationsTablePropertyColumnId';
import {
  OrganizationMetric,
} from 'screens/platform/directory/organizations/OrganizationsDirectoryScreen/OrganizationsTable/utils/OrganizationWithMetrics';

export const sortableOrganizationProperties = [
  OrganizationsTablePropertyColumnId.NAME,
  OrganizationsTablePropertyColumnId.DOMAIN,
  OrganizationsTablePropertyColumnId.SIZE,
  OrganizationsTablePropertyColumnId.RISK,
  OrganizationsTablePropertyColumnId.LAST_ENGAGEMENT,
  OrganizationsTablePropertyColumnId.LAST_MENTION,
] as const;
export type OrganizationSortableProperty = typeof sortableOrganizationProperties[number]
  | MetricSortingConfig;

export function isOrganizationSortableProperty(val: any): val is OrganizationSortableProperty {
  return sortableOrganizationProperties.includes(val);
}

export interface MetricSortingConfig {
  metricType: OrganizationMetric;
}
export interface OrganizationPropertySorting {
  organizationProperty: OrganizationSortableProperty;
}

export type OrganizationCustomSortingConfig = MetricSortingConfig | OrganizationPropertySorting;
export function isOrganizationCustomSortingConfig(val: any):
  val is OrganizationCustomSortingConfig {
  try {
    const keys = Object.keys(val);
    return keys.includes('organizationProperty') || keys.includes('metricType');
  } catch (e) {
    return false;
  }
}

export function isOrganizationPropertySortingConfig(val: any):
  val is OrganizationPropertySorting {
  try {
    return Object.keys(val).includes('organizationProperty');
  } catch (e) {
    return false;
  }
}

export type OrganizationRequestSorting = Pick<RequestSorting, 'sortOrder'> &
  OrganizationCustomSortingConfig;
