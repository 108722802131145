// eslint-disable-next-line max-classes-per-file
import { Mutex, MutexInterface } from 'async-mutex';

import ArrayUtils from 'utils/ArrayUtils';

type Key = string;
type DownloadedResource<ResourceType> = ResourceType | undefined;
type UrlToLoad<T = string> = T;
export type RequestCallback<ResourceType> = (
  key: Key,
  result: DownloadedResource<ResourceType>
) => void;

export interface Request<ResourceType> {
  key: Key;
  url: UrlToLoad;
  callback: RequestCallback<ResourceType>;
}

export interface Task<ResourceType> {
  key: Key;
  urlToLoad: UrlToLoad;
  callbacks: RequestCallback<ResourceType>[];
}

export interface CachedLoadingQueueConfig<ResourceType> {
  asyncLoadingFunction: (
    url: string,
  ) => Promise<DownloadedResource<ResourceType> | undefined>;
  workersAmount?: number;
  cacheSize?: number;
}

class ConcurrentCallbacksMap<ResourceType> {
  private queue: Record<Key, RequestCallback<ResourceType>[]> = {};
  private lock = new Mutex();

  initKey(key: Key) {
    this.queue[key] = [];
  }

  async addIfIncludedOrElse(
    key: Key,
    callback: RequestCallback<ResourceType>,
    elseCallback: () => void,
  ) {
    await this.performWhileLocked(() => {
      if (this.queue[key]) {
        this.queue[key].push(callback);
      } else {
        elseCallback();
      }
    });
  }

  async run(key: Key, newResource: DownloadedResource<ResourceType>) {
    await this.performWhileLocked(() => {
      this.queue[key]?.forEach((callback) => callback(key, newResource));
      delete this.queue[key];
    });
  }

  async performWhileLocked(callback: Function): Promise<void> {
    let releaseLock: MutexInterface.Releaser | undefined;
    try {
      releaseLock = await this.lock.acquire();
      callback();
    } finally {
      if (releaseLock) releaseLock();
    }
  }
}

export default class CachedLoadingQueue<ResourceType> {
  protected readonly asyncLoadingFunction: CachedLoadingQueueConfig<ResourceType>['asyncLoadingFunction'];
  private readonly workersAmount: number;
  private readonly cacheSize: number;

  protected queue: Task<ResourceType>[] = [];
  protected currentlyHandledTasks = new ConcurrentCallbacksMap<ResourceType>();
  protected cache: Record<Key, DownloadedResource<ResourceType>> = {};
  private itemsAccessOrder: Key[] = [];

  constructor(config: CachedLoadingQueueConfig<ResourceType>) {
    const {
      asyncLoadingFunction,
      workersAmount = 5,
      cacheSize = 1000,
    } = config;
    this.asyncLoadingFunction = asyncLoadingFunction;
    this.workersAmount = workersAmount;
    this.cacheSize = cacheSize;
  }

  async get(key: Key, url: string): Promise<DownloadedResource<ResourceType>> {
    const cachedItem = this.getCached(key);
    if (cachedItem !== undefined) return cachedItem;

    return new Promise((resolve) => {
      const callback = (_: Key, result: DownloadedResource<ResourceType>) => resolve(result);

      this.currentlyHandledTasks.addIfIncludedOrElse(key, callback, () => {
        this.addTask({ key, url, callback });
      });
    });
  }

  private addTask(request: Request<ResourceType>) {
    const { key, url, callback } = request;

    const taskIndex = this.queue.findIndex((task) => task.key === key);

    if (taskIndex > -1) {
      this.queue[taskIndex].callbacks.push(callback);
    } else {
      this.queue.push({ key, urlToLoad: url, callbacks: [callback] });
    }

    if (this.queue.length > 0) {
      this.wakeUp();
    }
  }

  protected wakeUp() {
    const queueSize = this.queue.length;
    const tasksToRun = Math.min(queueSize, this.workersAmount);
    const workers = ArrayUtils.getIntegers(tasksToRun);
    workers.forEach(() => this.consumeTask());
  }

  private async consumeTask() {
    let nextTask: Task<ResourceType> | undefined;
    await this.currentlyHandledTasks.performWhileLocked(() => {
      nextTask = this.queue.pop();
      if (nextTask) {
        this.currentlyHandledTasks.initKey(nextTask.key);
      }
    });

    if (!nextTask) return;
    const { key, urlToLoad, callbacks } = nextTask;

    const existingResource = this.getCached(key);
    const newResource = existingResource || await this.asyncLoadingFunction(urlToLoad);
    if (newResource) {
      if (!existingResource) {
        this.updateCache(key, newResource);
      }

      callbacks.forEach((callback) => callback(key, newResource));
      await this.currentlyHandledTasks.run(key, newResource);
    }

    this.consumeTask();
  }

  protected getCached(key: Key): DownloadedResource<ResourceType> | undefined {
    const cachedItem = this.cache[key];
    if (cachedItem) {
      const index = this.itemsAccessOrder.findIndex((k) => k === key);
      this.itemsAccessOrder.splice(index, 1);
      this.itemsAccessOrder.push(key);

      return cachedItem;
    }
    return undefined;
  }

  protected updateCache(key: Key, value: DownloadedResource<ResourceType>) {
    if (Object.keys(this.cache).length >= this.cacheSize) {
      const elementToRemove = this.itemsAccessOrder.shift();
      delete this.cache[elementToRemove!];
    }

    this.cache[key] = value;
    this.itemsAccessOrder.push(key);
  }

  protected abort() {
    this.queue = [];
    this.currentlyHandledTasks = new ConcurrentCallbacksMap<ResourceType>();
  }
}
