import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

import Authentication from 'global/authentication/Authentication';
import config from 'global/config';
import HttpStatus from 'global/lists/HttpStatus';
import { AuthError } from 'screens/platform/cross-platform-components/context/user/UserContext';
import DebuggerConsole from 'utils/DebuggerConsole';

type ImprovedRequestWithoutBody = (
  url: string,
  params?: { [key: string]: any },
  config?: AxiosRequestConfig,
) => Promise<any>;

type ImprovedAxiosInstance = AxiosInstance & {
  get: ImprovedRequestWithoutBody;
  delete: ImprovedRequestWithoutBody;
};

const AUTHORIZATION_HEADER = 'X-Authorization-Firebase';
const IMPERSONATION_HEADER = 'X-Impersonation-Person-Id';
let impersonationInterceptorId: number | null;

const axiosConfig: AxiosRequestConfig = {
  baseURL: config.apiBaseUrl,
  headers: {
    'Content-Type': 'application/json',
  },
};

const instance = axios.create(axiosConfig);

const originalRequests = {
  get: instance.get,
  delete: instance.delete,
};

function generateImprovedRequestWithoutBody(
  methodName: 'get' | 'delete',
): ImprovedRequestWithoutBody {
  return (url, params?, requestConfig = {}) =>
    originalRequests[methodName](url, { params, ...requestConfig });
}

instance.get = generateImprovedRequestWithoutBody('get');
instance.delete = generateImprovedRequestWithoutBody('delete');

let unauthorizedInterceptorId: number | null;

export function attachApiUnauthorizedInterceptor(
  setAuthError: (message: AuthError) => void,
  endUserSession: () => void,
) {
  unauthorizedInterceptorId = instance.interceptors.response.use(
    undefined,
    (error) => {
      if (error.response?.status === HttpStatus.UNAUTHORIZED) {
        endUserSession();
        // This interceptor is only attached after successful login,
        // so if we get to here the token has probably expired
        setAuthError(AuthError.SESSION_EXPIRED);
        return null;
      }
      return Promise.reject(error);
    },
  );
}

instance.interceptors.request.use(
  async (axiosRequestConfig) => {
    const nextConfig = { ...axiosRequestConfig };
    const user = await Authentication.getAuthenticatedUser();
    if (user) {
      nextConfig.headers[AUTHORIZATION_HEADER] = await user.getIdToken();
    }
    return nextConfig;
  },
);

export function ejectApiUnauthorizedInterceptor() {
  if (unauthorizedInterceptorId) {
    instance.interceptors.response.eject(unauthorizedInterceptorId);
    unauthorizedInterceptorId = null;
  }
}

export async function addImpersonationHeader(impersonationPersonId: string, tenantId: number) {
  removeImpersonationHeader();

  impersonationInterceptorId = instance.interceptors.request.use(
    (axiosRequestConfig) => {
      const nextConfig = { ...axiosRequestConfig };
      nextConfig.headers[IMPERSONATION_HEADER] = impersonationPersonId;
      return nextConfig;
    },
    (error) => {
      DebuggerConsole.error('Unable the impersonate user', { impersonationPersonId, tenantId });
      Promise.reject(error);
    },
  );
}

export async function removeImpersonationHeader() {
  if (impersonationInterceptorId !== null) {
    instance.interceptors.request.eject(impersonationInterceptorId);
    impersonationInterceptorId = null;
  }
}

export default instance as ImprovedAxiosInstance;
