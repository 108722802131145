import axios from 'axios';

import firebase from 'firebase/compat/app';
import PublicApi from 'global/api/publicApi';
import { UnauthorizedException } from 'global/authentication/AuthenticationException';
import FirebaseRedirectionIndicator from 'global/authentication/FirebaseRedirectionIndicator';
import { sendLoggedOutMessageToChromeExtension } from 'global/ChromeExtensionUtils';
import config from 'global/config';
import HttpStatus from 'global/lists/HttpStatus';
import { AuthError, PopulatedUserState } from 'screens/platform/cross-platform-components/context/user/UserContext';
import DebuggerConsole from 'utils/DebuggerConsole';

import 'firebase/compat/auth';

const { firebaseConfig } = config;

interface FirebaseUser extends firebase.User {}
export type JwtToken = string;

export default class Authentication {
  static initializeService() {
    try {
      firebase.initializeApp(firebaseConfig);
      DebuggerConsole.log('Initialized Firebase');
    } catch (err) {
      DebuggerConsole.error('Firebase failed to initialize', err);
    }
  }

  static logoutFromFirebase() {
    sendLoggedOutMessageToChromeExtension();
    FirebaseRedirectionIndicator.reset();
    firebase.auth().signOut();
  }

  static endSessionWithoutRedirect() {
    Authentication.logoutFromFirebase();
  }

  static async openSignInPopup(provider: 'google' | 'microsoft'): Promise<firebase.auth.UserCredential> {
    const authProvider = provider === 'google'
      ? new firebase.auth.GoogleAuthProvider()
      : new firebase.auth.OAuthProvider('microsoft.com');
    authProvider.setCustomParameters({
      prompt: 'select_account', // Force choosing Google account on every login
    });

    FirebaseRedirectionIndicator.updateLoginAttempt();
    return firebase.auth().signInWithPopup(authProvider);
  }

  static async getAuthenticatedUser(): Promise<FirebaseUser | null> {
    return new Promise((res, rej) => {
      firebase.auth().onAuthStateChanged(res, rej);
    });
  }

  // eslint-disable-next-line consistent-return
  static async authorizeUserByToken(
    authenticationToken: JwtToken | undefined,
    // @ts-ignore
  ): Promise<PopulatedUserState> {
    if (authenticationToken) {
      try {
        const currentUserData = await PublicApi.validateExistingUserByToken();
        if (currentUserData) {
          return currentUserData;
        }
      } catch (err) {
        DebuggerConsole.error(err);
        Authentication.handleError(err);
      }
    } else {
      throw new Error(AuthError.INVALID_TOKEN);
    }
  }

  private static handleError(err: unknown) {
    if (!axios.isAxiosError(err)) return;

    if (err.message === 'Network Error') {
      throw new Error(AuthError.NETWORK_ERROR);
    }
    if (err.response !== undefined) {
      if (err.response.status >= 500 && err.response.status < 600) {
        throw new Error(AuthError.SERVER_ERROR);
      }
      if (
        err.response.status === HttpStatus.UNAUTHORIZED
        || err.response.status === HttpStatus.FORBIDDEN
      ) {
        throw new UnauthorizedException();
      }
    }
    throw new Error(AuthError.GENERAL_ERROR);
  }

  static async fetchUserFromFirebase() {
    try {
      const userFromFirebase = await Authentication.getAuthenticatedUser();
      if (userFromFirebase) {
        const authenticationToken: JwtToken = await userFromFirebase.getIdToken();
        return Authentication.authorizeUserByToken(authenticationToken);
      }
    } catch (err) {
      Authentication.logoutFromFirebase();
      DebuggerConsole.error(err);
    }
    return null;
  }
}
