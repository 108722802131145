import React, { useMemo } from 'react';

import CircleIcon from 'common-ui-components/CircleIcon';
import App, { ConnectorApp, ParentApp } from 'global/lists/apps';
import getAppDisplayName from 'global/lists/appsNames';
import Size from 'global/lists/Size';

import lockBadge from 'assets/img/icon/closed-lock.svg';
import airtableLogo from 'assets/img/logo/airtable.svg';
import akoodaLogo from 'assets/img/logo/akooda.svg';
import asanaLogo from 'assets/img/logo/asana.svg';
import atlassianLogo from 'assets/img/logo/atlassian.svg';
import bitbucketLogo from 'assets/img/logo/bitbucket.svg';
import boxLogo from 'assets/img/logo/box.svg';
import confluenceLogo from 'assets/img/logo/confluence.svg';
import dropboxLogo from 'assets/img/logo/dropbox.svg';
import figmaLogo from 'assets/img/logo/figma.svg';
import frontLogo from 'assets/img/logo/front.svg';
import githubLogo from 'assets/img/logo/github.svg';
import googleCalendarLogo from 'assets/img/logo/google-calendar.svg';
import googleDriveLogo from 'assets/img/logo/google-drive.svg';
import googleDriveDocument from 'assets/img/logo/google-drive-document.svg';
import googleDrivePresentation from 'assets/img/logo/google-drive-presentation.svg';
import googleDriveSpreadsheet from 'assets/img/logo/google-drive-spreadsheet.svg';
import googleGmailLogo from 'assets/img/logo/google-gmail.svg';
import googleMeetLogo from 'assets/img/logo/google-meet.svg';
import hubspotLogo from 'assets/img/logo/hubspot.svg';
import intercomLogo from 'assets/img/logo/intercom.svg';
import jiraLogo from 'assets/img/logo/jira.svg';
import linearLogo from 'assets/img/logo/linear.svg';
import m365exchangeLogo from 'assets/img/logo/m365exchange.svg';
import miroLogo from 'assets/img/logo/miro.svg';
import mondayLogo from 'assets/img/logo/monday.svg';
import notionLogo from 'assets/img/logo/notion.svg';
import onedriveLogo from 'assets/img/logo/onedrive.svg';
import phabricatorLogo from 'assets/img/logo/phabricator.svg';
import salesforceLogo from 'assets/img/logo/salesforce.svg';
import sharepointLogo from 'assets/img/logo/sharepoint.svg';
import slackLogo from 'assets/img/logo/slack.svg';
import zendeskLogo from 'assets/img/logo/zendesk.svg';
import zoomLogo from 'assets/img/logo/zoom.svg';

const appsLogos: Record<Exclude<App, ParentApp> & App.ATLASSIAN, string> = {
  [App.AKOODA]: akoodaLogo,
  [App.SLACK]: slackLogo,
  [App.GOOGLE_CALENDAR]: googleCalendarLogo,
  [App.GOOGLE_DRIVE]: googleDriveLogo,
  [App.GOOGLE_MEET]: googleMeetLogo,
  [App.DROPBOX]: dropboxLogo,
  [App.ATLASSIAN]: atlassianLogo,
  [App.ATLASSIAN_JIRA]: jiraLogo,
  [App.ATLASSIAN_CONFLUENCE]: confluenceLogo,
  [App.GITHUB]: githubLogo,
  [App.MONDAY]: mondayLogo,
  [App.FIGMA]: figmaLogo,
  [App.NOTION]: notionLogo,
  [App.GOOGLE_GMAIL]: googleGmailLogo,
  [App.INTERCOM]: intercomLogo,
  [App.PHABRICATOR_BRANCHES]: phabricatorLogo,
  [App.PHABRICATOR_TICKETS]: phabricatorLogo,
  [App.BITBUCKET]: bitbucketLogo,
  [App.ZOOM]: zoomLogo,
  [App.SALESFORCE]: salesforceLogo,
  [App.ZENDESK]: zendeskLogo,
  [App.BOX]: boxLogo,
  [App.M365_SHAREPOINT]: sharepointLogo,
  [App.M365_ONEDRIVE]: onedriveLogo,
  [App.ASANA]: asanaLogo,
  [App.LINEAR]: linearLogo,
  [App.HUBSPOT]: hubspotLogo,
  [App.FRONT]: frontLogo,
  [App.AIRTABLE]: airtableLogo,
  [App.MIRO]: miroLogo,
  [App.M365_EXCHANGE]: m365exchangeLogo,
};

export const getImgSrc = (appId: ConnectorApp, mimeType?: string) => {
  if (appId === App.GOOGLE_DRIVE) {
    switch (mimeType) {
      case 'application/vnd.google-apps.document':
        return googleDriveDocument;
      case 'application/vnd.google-apps.spreadsheet':
        return googleDriveSpreadsheet;
      case 'application/vnd.google-apps.presentation':
        return googleDrivePresentation;
      default:
        break;
    }
  }
  return appsLogos[appId];
};

type Props = {
  appId: ConnectorApp;
  mimeType: string | undefined;
  isPublic?: boolean;
  showTitle?: boolean;
  className?: string;
  hasBorder?: boolean;
  size?: Size;
};

export default function AppIcon({
  appId,
  mimeType,
  isPublic = true,
  showTitle = true,
  hasBorder = true,
  className,
  size = Size.MEDIUM,
}: Props) {
  const source = useMemo(() => getImgSrc(appId, mimeType), [appId, mimeType]);

  const privacyBadge = useMemo(() => {
    if (!isPublic) return undefined; // This is for product review purpose only
    return {
      path: lockBadge,
      alt: 'Private Interaction',
    };
  }, [isPublic]);

  return (
    <CircleIcon
      source={source}
      className={className}
      title={getAppDisplayName(appId)}
      showTitle={showTitle}
      secondaryBadge={privacyBadge}
      size={size}
      hasBorder={hasBorder}
    />
  );
}
