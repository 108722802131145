import axios from 'global/api/axiosInstance';
import { PopulatedUserState } from 'screens/platform/cross-platform-components/context/user/UserContext';

const PublicApi = {
  validateExistingUserByToken: async (): Promise<PopulatedUserState> => {
    const response = await axios.post('/login?source=web');
    return response.data;
  },
};

export default PublicApi;
