import classNames from 'classnames';
import React, { ReactElement } from 'react';

import Tooltip from 'common-ui-components/Tooltip';

import style from 'common-ui-components/ChangedFilterDot/style.module.scss';

type Props = {
  hoverText?: string;
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
};

export default function ChangedFilterDot({
  hoverText,
  onClick,
  className,
  disabled,
}: Props) {
  const Wrapper = ({ children }: { children: ReactElement }) =>
    (hoverText && !disabled ? (
      <Tooltip content={hoverText}>{children}</Tooltip>
    ) : (
      <>{children}</>
    ));

  return (
    <Wrapper>
      <div
        className={classNames(
          style.changedFilterDot,
          onClick && !disabled && style.clickable,
          className,
        )}
        onClick={disabled ? undefined : onClick}
      />
    </Wrapper>
  );
}
