import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import MonitoringDataPopulator from 'global/MonitoringDataPopulator';
import BackofficeEmailAppScreen from 'screens/backoffice/screens/emailApp/BackOfficeEmailAppScreen';
import ConfigurationContextProvider
  from 'screens/platform/cross-platform-components/context/configuration/ConfigurationContextProvider';
import { useGlobalLoading } from 'screens/platform/cross-platform-components/context/GlobalLoadingContextProvider';
import MetadataContextProvider
  from 'screens/platform/cross-platform-components/context/metadata/MetadataContextProvider';
import TenantLoader from 'screens/platform/cross-platform-components/context/tenant/TenantLoader';
import { useUserContext } from 'screens/platform/cross-platform-components/context/user/UserContext';
import PlatformScreen from 'screens/platform/PlatformScreen';
import useBrowserWarning from 'utils/BrowserUtils';
import LocalStorageUtils, { StorageKeyOriginalPath } from 'utils/LocalStorageUtils';

/**
 * Deprecate this as soon as we get approved by Google
 * @ref https://akoodaco.atlassian.net/browse/AKD-4702
 */
function useGoogleReviewersRestriction() {
  const { email } = useUserContext();
  const { setFullScreenLoading } = useGlobalLoading();

  const shouldShowEmailApp = email === 'google.approver@akooda.co';
  useEffect(() => {
    if (shouldShowEmailApp) {
      setFullScreenLoading(false);
    }
  }, []);

  return shouldShowEmailApp;
}

function useOriginalPath() {
  const history = useHistory();

  useEffect(() => {
    const originalPath = LocalStorageUtils.getItem<string>(StorageKeyOriginalPath.ORIGINAL_PATH);
    if (originalPath) {
      LocalStorageUtils.removeItem(StorageKeyOriginalPath.ORIGINAL_PATH);
      history.push(originalPath);
    }
  }, []);
}

export default function LoggedInApp() {
  useOriginalPath();
  useBrowserWarning();

  const shouldShowEmailApp = useGoogleReviewersRestriction();

  return shouldShowEmailApp
    ? (
      <div style={{ padding: 20 }}>
        <BackofficeEmailAppScreen />
      </div>
    ) : (
      <TenantLoader>
        <MonitoringDataPopulator />
        <ConfigurationContextProvider>
          <MetadataContextProvider>
            <PlatformScreen />
          </MetadataContextProvider>
        </ConfigurationContextProvider>
      </TenantLoader>
    );
}
