import classnames from 'classnames';
import React from 'react';

import Button from 'common-ui-components/Button';
import Dropdown from 'common-ui-components/Dropdown';
import ColumnsConfiguratorPopoverContent from 'screens/platform/cross-platform-components/ColumnsConfigurator/ColumnsConfiguratorPopoverContent';
import { HierarchicalItemType } from 'screens/platform/cross-platform-components/HierarchicalSearchInput/HierarchicalItemTypeConfig';
import { ColumnsOrder, ColumnsOrderWrapper } from 'screens/platform/directory/components/GenericDirectoryScreen/context/DirectoryContext';
import { useToggle } from 'utils/hooks';

import { ReactComponent as SettingsIcon } from 'assets/img/icon/columns.svg';

import style from 'screens/platform/cross-platform-components/ColumnsConfigurator/style.module.scss';

export type ColumnConfiguratorHierarchicalItemType = Extract<
  HierarchicalItemType,
  | HierarchicalItemType.INTERACTIONS_TABLE_COLUMN
  | HierarchicalItemType.ORGANIZATIONS_TABLE_COLUMN
  | HierarchicalItemType.PEOPLE_DIRECTORY_TABLE_COLUMN
  | HierarchicalItemType.TOPICS_DIRECTORY_TABLE_COLUMN
>;

type Props<T extends ColumnsOrder> = {
  allColumnsByGroups: Partial<Record<string, string[]>>;
  type: ColumnConfiguratorHierarchicalItemType;
  columnsOrder: ColumnsOrderWrapper<T>;
  darkBorder?: boolean;
};

export default function ColumnsConfigurator<T extends ColumnsOrder>(props: Props<T>) {
  const [isPopoverOpen, togglePopover] = useToggle(false);
  const { columnsOrder, darkBorder = true } = props;

  return (
    <Dropdown
      isDropdownOpen={isPopoverOpen}
      closeDropdown={togglePopover}
      placement="left-start"
      popperOptions={{ enableFlipping: false }}
      toggleButtonElement={(
        <Button
          onClick={(e) => {
            e.stopPropagation();
            togglePopover();
          }}
          className={classnames(style.toggleBtn, {
            [style.darkBorder]: darkBorder,
          })}
          rounded
          padded
          color="gray"
        >
          <SettingsIcon />
          Columns
        </Button>
      )}
    >
      <ColumnsConfiguratorPopoverContent
        {...props}
        selectedColumns={Object.keys(columnsOrder.get())}
        onNextColumnsConfig={columnsOrder.set}
        closePopover={togglePopover}
      />
    </Dropdown>
  );
}
