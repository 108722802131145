import classNames from 'classnames';
import React from 'react';

import AppIcon from 'common-ui-components/AppIcon';
import Button from 'common-ui-components/Button';
import useInteractionCardData from 'common-ui-components/InteractionCard/hooks/InteractionCardDataHook';
import InteractionTitle from 'common-ui-components/InteractionTitle';
import MultiOrganizationsImages from 'common-ui-components/MultiImages/MultiOrganizationsImages';
import MultiPersonsImages from 'common-ui-components/MultiImages/MultiPersonsImages';
import MultiTagsBadges from 'common-ui-components/MultiTagsBadges';
import { Atom } from 'model/Atom';

import style from 'common-ui-components/InteractionCard/style.module.scss';

type InteractionCardProps = {
  atom: Atom;
  singleRow?: boolean;
  hasBorder?: boolean;
  sticker?: string;
  onClick?: () => void;
}

export default function InteractionCard({
  atom,
  singleRow = false,
  hasBorder = false,
  sticker,
  onClick,
}: InteractionCardProps) {
  const { externalIds, internalIds, tagsArr } = useInteractionCardData(atom);
  const {
    appId, isPublic, participatingExternalOrganizations: organizations, mimeType,
  } = atom;

  return (
    <div className={classNames(
      style.interactionCardContainer,
      hasBorder && style.withBorder,
    )}
    >
      {sticker && (
      <Button
        color="gray"
        rounded
        padded
        className={style.sticker}
      >
        {sticker}
      </Button>
      )}
      <AppIcon appId={appId} isPublic={isPublic} mimeType={mimeType} />
      <div className={style.content}>
        <InteractionTitle
          atom={atom}
          options={{ showUpdatedAt: true, clickable: true, singleLine: true }}
          onClick={onClick}
        />
        {tagsArr.length > 0 ? <MultiTagsBadges singleRow={singleRow} tags={tagsArr} /> : null}
        {externalIds.length > 0 && organizations.length === 1
          ? <MultiPersonsImages ids={externalIds} externalOrganizationId={organizations[0]} />
          : null}
        {internalIds.length > 0 ? <MultiPersonsImages ids={internalIds} /> : null}
        {organizations.length > 1 ? <MultiOrganizationsImages ids={organizations} /> : null}
      </div>
    </div>
  );
}
