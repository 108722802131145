import { OrganizationRisk, OrganizationSize } from 'model/Organization';

export default class OrganizationMetadata {
  readonly risk: OrganizationRisk | null;
  readonly size: OrganizationSize | null;
  private readonly segments: string[] | null;

  constructor(initializer: { risk: OrganizationMetadata['risk']; size: OrganizationMetadata['size']; segments: OrganizationMetadata['segments'] }) {
    this.risk = initializer.risk;
    this.size = initializer.size;
    this.segments = initializer.segments;
  }

  getSegments(): string[] | null {
    return this.segments === null || this.segments.length === 0
      ? null
      : this.segments;
  }
}
