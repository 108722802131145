import FilterableProperty from 'global/api/controller/utils/filtering/FilterableProperty';
import { BaseFilterCondition, FilterFieldType } from 'global/api/controller/utils/filtering/FilterCondition';

interface NumberFilterCondition<FieldNameType extends FilterableProperty>
  extends BaseFilterCondition<FieldNameType, number> {
  fieldType: FilterFieldType.NUMBER;
  operator: NumberFilterOperator;
}

export enum NumberFilterOperator {
  EQUAL = 'EQUAL',
  MORE_THAN = 'MORE_THAN',
  LESS_THAN = 'LESS_THAN',
}

const operatorLabels: Record<NumberFilterOperator, string> = {
  [NumberFilterOperator.EQUAL]: 'equals',
  [NumberFilterOperator.MORE_THAN]: 'equals',
  [NumberFilterOperator.LESS_THAN]: 'equals',
};
export function getNumberFilterOperatorLabel(operator: NumberFilterOperator) {
  return operatorLabels[operator];
}

export default NumberFilterCondition;
