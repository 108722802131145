import { useMemo } from 'react';

import { ListOptions } from 'global/ListOptions';
import Directionality from 'screens/platform/cross-platform-components/context/MasterFiltersContext/Directionality';
import FilterConfig, {
  DynamicListOptions,
} from 'screens/platform/directory/components/GenericDirectoryScreen/FiltersList/FilterConfig';

const useDirectionalityFilterConfig = (): FilterConfig<string> => useMemo(() => {
  const directionalityOptions: ListOptions<string> = [
    {
      value: Directionality.EXTERNAL,
      label: 'External',
    },
    {
      value: Directionality.INTERNAL,
      label: 'Internal',
    },
  ];

  return {
    label: 'Directionality',
    options: new DynamicListOptions(directionalityOptions),
    multiSelect: true,
    removable: false,
    defaultValue: [Directionality.EXTERNAL, Directionality.INTERNAL],
  };
}, []);

export default useDirectionalityFilterConfig;
