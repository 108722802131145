import React, {
  Key, useEffect, useMemo, useRef, useState,
} from 'react';

import Button from 'common-ui-components/Button';
import Checkbox from 'common-ui-components/Checkbox';
import SearchInput from 'common-ui-components/SearchInput';
import { TableFilterDropdownProps } from 'common-ui-components/Table/TableFilterDropdown';
import { ListOptions } from 'global/ListOptions';
import CheckboxTooltip from 'screens/platform/cross-platform-components/HierarchicalSearchInput/components/ItemListing/CheckboxTooltip';
import { FilterValue } from 'screens/platform/directory/components/GenericDirectoryScreen/FiltersList/FilterConfig';
import { useDebounce } from 'utils/hooks';

import style from 'common-ui-components/Table/TableFilterDropdown/style.module.scss';

type SelectionState = 'All' | 'Partial' | 'None';

export default function MultiSelectionFilter({
  filterConfig,
  confirm,
  selectedKeys,
  setSelectedKeys,
  visible,
  hasDoneButton = true,
}: TableFilterDropdownProps) {
  const [searchQuery, setSearchQuery] = useState('');
  const searchInputRef = useRef<HTMLInputElement>(null);
  const {
    disabledOptions, options, noOptionsMessage,
  } = filterConfig;
  const toggleMultiSelectValue = (clickedValue: Key) => {
    const nextMultiselectValues = selectedKeys.includes(clickedValue)
      ? selectedKeys.filter((k) => k !== clickedValue)
      : [...selectedKeys, clickedValue];

    setSelectedKeys(nextMultiselectValues);
  };

  useEffect(() => {
    if (visible) {
      searchInputRef.current?.focus();
    }
  }, [searchInputRef, visible]);

  const onSelectAllClick = () => {
    const currentOptions = options?.getCurrentOptions();
    if (determineSelectAll(selectedKeys, currentOptions) === 'None') {
      setSelectedKeys(currentOptions?.map((option) => option.value) || []);
    } else {
      setSelectedKeys([]);
    }
  };

  const emptyFilterOptionsMessage = useMemo(() => {
    const defaultMessage = 'No filter options found';
    if (options && options.getCurrentOptions().length > 0) return defaultMessage;
    return noOptionsMessage ?? defaultMessage;
  }, [noOptionsMessage]);

  const [filteredOptions, setFilteredOptions] = useState<ListOptions<FilterValue>>(
    options?.getCurrentOptions() || [],
  );

  const debouncedSearchQuery = useDebounce(searchQuery, 100);

  useEffect(() => {
    const updateFilteredOptions = async () => {
      if (options) {
        await options.updateOptionsWithFilter(debouncedSearchQuery);
        setFilteredOptions(options.getCurrentOptions());
      }
    };

    updateFilteredOptions();
  }, [debouncedSearchQuery, options]);

  // Workaround for ResizeObserver loop limit exceeded error
  const hardcodedDimensionsStyle = filterConfig.label === 'Name'
    ? { width: '400px' }
    : {};

  return (
    <div className={style.container} style={hardcodedDimensionsStyle}>
      {options?.hasAny && (
        <>
          <div className={style.topMenu}>
            <SearchInput
              ref={searchInputRef}
              setValue={setSearchQuery}
              placeholder="Search"
              transparent
              className={style.searchInput}
            />
            <Checkbox
              label="Select all"
              checked={determineSelectAll(selectedKeys, options?.getCurrentOptions()) === 'All'}
              partiallyChecked={
              determineSelectAll(selectedKeys, options?.getCurrentOptions()) === 'Partial'
            }
              className={style.optionItem}
              onClick={onSelectAllClick}
            />
          </div>
          <div className={style.separator} />
        </>
      )}
      <div className={style.scrollableOptionsList}>
        {filteredOptions?.length === 0 && (
          <div className={style.emptyFiltersMessage}>
            {emptyFilterOptionsMessage}
          </div>
        )}
        {filteredOptions?.map((option) => {
          const disabledReason = disabledOptions?.[option.value];
          const checkbox = (
            <Checkbox
              key={option.value}
              label={option.label}
              checked={!disabledReason && selectedKeys.includes(option.value)}
              className={style.optionItem}
              disabled={Boolean(disabledReason)}
              onClick={() => toggleMultiSelectValue(option.value)}
            />
          );
          return disabledReason ? (
            <CheckboxTooltip key={option.value} content={disabledReason}>
              {checkbox}
            </CheckboxTooltip>
          ) : (
            checkbox
          );
        })}
      </div>
      {hasDoneButton && (
        <>
          <div className={style.separator} />
          <div className={style.bottomMenu}>
            <Button
              color="blue"
              rounded
              onClick={() => confirm({ closeDropdown: true })}
            >
              Done
            </Button>
          </div>
        </>
      )}
    </div>
  );
}

function determineSelectAll<T extends FilterValue = FilterValue>(
  multiSelectValues: T[],
  options?: ListOptions<T> | null,
): SelectionState {
  if (multiSelectValues.length === 0 || !options) {
    return 'None';
  }
  return multiSelectValues.length === options.length ? 'All' : 'Partial';
}
