import {
  clientPageToRequestSkip,
  Pagination,
} from 'common-ui-components/Table/paginationHook';
import { PagedAtomsRequest } from 'global/api/controller/AtomController';
import Tenant from 'model/Tenant';
import {
  useCustomAtomsFilters,
} from 'screens/platform/cross-platform-components/context/CustomFilters/CustomAtomsFilters/CustomAtomsFiltersContextProvider';
import { usePlatformContext } from 'screens/platform/cross-platform-components/context/platform/PlatformContext';
import useTenantContext from 'screens/platform/cross-platform-components/context/tenant/TenantContext';
import useFiltersWithCustomAtomsFilters
  from 'screens/platform/cross-platform-components/Interactions/components/InteractionsTable/dataFetchingHook/masterFiltersWithCustomAtomsFiltersHook';

type ApiFunction<T> = (tenantId: Tenant['id'], pagedAtomsRequest: PagedAtomsRequest) => Promise<T>;
type FetchDataFunction<T> = (
  pagination: Pagination,
  shouldGetPagesCounts: boolean,
) => Promise<T>;

export default function useInteractionsFetching<T>(
  apiFunction: ApiFunction<T>,
): FetchDataFunction<T> {
  const { tenant } = useTenantContext();
  const { customAtomsFilters } = useCustomAtomsFilters();
  const getFiltersWithCustomAtomsFilters = useFiltersWithCustomAtomsFilters();
  const { interactionsTable: { sorting, limit } } = usePlatformContext();

  async function fetchData(
    pagination: Pagination,
    shouldGetPagesCount: boolean,
  ) {
    const {
      currentFiltersWithCustomAtomsFilters,
      secondaryGroupFilter,
    } = await getFiltersWithCustomAtomsFilters();

    const pageSize = limit ?? pagination.pageSize;
    const skip = limit === null
      ? clientPageToRequestSkip(pagination.current, pageSize)
      : 0;

    return apiFunction(
      tenant.id,
      {
        paging: { skip, limit: pageSize },
        currentFilters: currentFiltersWithCustomAtomsFilters,
        sorting,
        shouldGetPagesCount,
        secondaryGroupFilter,
        searchQuery: customAtomsFilters.searchQuery,
      },
    );
  }

  return fetchData;
}
