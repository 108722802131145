import classNames from 'classnames';
import React from 'react';

import OrganizationProfileImage from 'common-ui-components/MultiImages/ProfileImage/OrganizationProfileImage';
import BadgeSkeleton from 'es-src/components/BadgeSkeleton';
import Button from 'es-src/components/ui/Button';
import Size from 'global/lists/Size';
import { useInternalScreenRedirect } from 'global/ScreensConfiguration';
import Organization from 'model/Organization';
import useAsync from 'react-use/lib/useAsync';
import { useMetadataContext } from 'screens/platform/cross-platform-components/context/metadata/MetadataContext';

import style from 'es-src/components/OrganizationBadge/style.module.scss';

interface Props {
  organizationId: Organization['id'];
  hasBorder?: boolean;
  size?: Size;
  className?: string;
  openNewTab?: boolean;
}

const OrganizationBadge = ({
  organizationId, className, size = Size.MEDIUM, hasBorder = true, openNewTab = false,
}: Props) => {
  const { organizations } = useMetadataContext();
  const redirectToInternalScreen = useInternalScreenRedirect(openNewTab);

  const { value: organization, loading } = useAsync(
    () => organizations.getById(organizationId),
    [organizationId],
  );

  function handleClick() {
    redirectToInternalScreen(
      'platform.directory.organizations.profile',
      organizationId,
    );
  }

  if (loading) return <BadgeSkeleton />;

  if (!organization) return null;

  return (
    <Button
      className={classNames(style.organizationBadge, className)}
      onClick={handleClick}
      color="gray"
      padding="medium"
      rounded={hasBorder}
      outlined={hasBorder}
      title="Go to organization's page"
    >
      <OrganizationProfileImage organization={organization} size={size} />
      <div className={style.details}>
        {organization.name}
      </div>
    </Button>
  );
};

export default OrganizationBadge;
