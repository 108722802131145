import classNames from 'classnames';
import React, { ReactNode } from 'react';
import ReactModal from 'react-modal';

import style from 'common-ui-components/Modal/style.module.scss';

if (process.env.NODE_ENV !== 'test') {
  ReactModal.setAppElement('#root');
}

export interface ModalProps {
  className?: string;
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  isFullScreen?: boolean;
}

export default function Modal({
  className, isOpen, onClose, children, isFullScreen,
}: ModalProps) {
  return (
    <ReactModal
      className={classNames(style.modalContent, isFullScreen && style.fullScreen, className)}
      isOpen={isOpen}
      onRequestClose={onClose}
      overlayClassName={style.overlay}
    >
      {children}
    </ReactModal>
  );
}
