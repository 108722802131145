import classNames from 'classnames';
import React, { forwardRef } from 'react';

import style from 'es-src/components/ui/Button/style.module.scss';

type HTMLButtonElementProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;
export type ButtonProps = {
  transparent?: boolean;
  color?: 'gray' | 'blue' | 'red' | 'yellow';
  padding?: 'small' | 'medium';
  rounded?: boolean;
  type?: 'button' | 'submit' | 'reset';
  loading?: boolean;
  outlined?: boolean;
} & Pick<HTMLButtonElementProps,
  | 'id'
  | 'children'
  | 'className'
  | 'onClick'
  | 'onMouseDown'
  | 'onMouseUp'
  | 'onMouseEnter'
  | 'onMouseLeave'
  | 'aria-label'
  | 'title'
  | 'disabled'
  | 'style'
>

const Button = forwardRef<HTMLButtonElement, ButtonProps>((props: ButtonProps, ref) => {
  const {
    className,
    transparent,
    color,
    type = 'button',
    loading,
    padding,
    rounded,
    outlined,
    disabled,
    children,
    ...htmlButtonElementProps
  } = props;

  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      className={classNames(style.button, className, {
        [style.blue]: color === 'blue',
        [style.gray]: color === 'gray',
        [style.red]: color === 'red',
        [style.yellow]: color === 'yellow',
        [style.transparent]: transparent,
        [style.disabled]: disabled,
        [style.paddedSmall]: padding === 'small',
        [style.paddedMedium]: padding === 'medium',
        [style.rounded]: rounded,
        [style.outlined]: outlined,
      })}
      ref={ref}
      disabled={Boolean(disabled) || loading}
      {...htmlButtonElementProps}
    >
      {children}
    </button>
  );
});

export default Button;
