import classNames from 'classnames';
import React, { ReactNode } from 'react';

import style from 'common-ui-components/CardContainer/style.module.scss';

interface CardContainerProps {
  children: ReactNode;
  className?: string;
}

export default function CardContainer({ children, className }: CardContainerProps) {
  return (
    <div className={classNames(style.cardContainer, className)}>
      {children}
    </div>
  );
}
