import { useEffect, useRef } from 'react';

import { FIRST_PAGE_INDEX } from 'common-ui-components/Table/paginationHook';
import Api from 'global/api/platformApi';
import { Atom } from 'model/Atom';
import {
  useCustomAtomsFilters,
} from 'screens/platform/cross-platform-components/context/CustomFilters/CustomAtomsFilters/CustomAtomsFiltersContextProvider';
import {
  useMasterFilters,
} from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFiltersContext';
import { usePlatformContext } from 'screens/platform/cross-platform-components/context/platform/PlatformContext';
import useInteractionsFetching
  from 'screens/platform/cross-platform-components/Interactions/hooks/InteractionsFetchingHook';
import { useInteractionsContext } from 'screens/platform/cross-platform-components/Interactions/InteractionsContext';
import DebuggerConsole from 'utils/DebuggerConsole';
import { useDebouncedAsyncCall, useMountedState } from 'utils/hooks';
import ObjectUtils from 'utils/ObjectUtils';

export default function useInteractionsDataFetcher(): Atom[] | null {
  const { currentFilters, setTotalAtomsCount } = useMasterFilters();
  const { customAtomsFilters, setFilteredAtomsCount } = useCustomAtomsFilters();
  const { interactionsTable: { sorting, limit } } = usePlatformContext();
  const {
    pagination: { value: pagination, setCurrentPage },
    loading,
    noInteractions,
  } = useInteractionsContext();

  const fetchInteractions = useInteractionsFetching(Api.Atom.getAtoms);

  const [interactionsPage, setInteractionsPage] = useMountedState<Atom[] | null>(null);

  const latestApiCallIdRef = useRef(0);
  const isNewlyFilteredData = useRef(true);

  async function fetchData(): Promise<void> {
    // eslint-disable-next-line no-multi-assign
    const currentApiCallId = (latestApiCallIdRef.current += 1);

    try {
      loading.set(true);
      const fetchedAtoms = await fetchInteractions(pagination, isNewlyFilteredData.current);

      if (fetchedAtoms?.data
        && currentApiCallId === latestApiCallIdRef.current) {
        const {
          atoms, allowedAtomsCount, totalAtomsCount, restrictedAtoms,
        } = fetchedAtoms.data;
        setInteractionsPage(atoms);

        if (isNewlyFilteredData.current) {
          setCurrentPage(FIRST_PAGE_INDEX);
          if (ObjectUtils.isEmpty(customAtomsFilters)) {
            setTotalAtomsCount({
              allowedAtomsCount: limit ? Math.min(limit, allowedAtomsCount) : allowedAtomsCount,
              totalAtomsCount,
              restrictedAtoms,
            });
          } else {
            setFilteredAtomsCount({ allowedAtomsCount, totalAtomsCount, restrictedAtoms });
          }
        }
      }
    } catch (err) {
      DebuggerConsole.error(err);
    } finally {
      if (currentApiCallId === latestApiCallIdRef.current) {
        loading.set(false);
      }
    }
  }

  // TODO: This is a temporary mitigation, however we shouldn't call this multiple times in a row
  const debouncedDataFetching = useDebouncedAsyncCall(fetchData);

  useEffect(() => {
    if (!isNewlyFilteredData.current || pagination.current > FIRST_PAGE_INDEX) {
      isNewlyFilteredData.current = false;
      debouncedDataFetching();
    }
  }, [pagination.current]);

  useEffect(() => {
    isNewlyFilteredData.current = true;
    debouncedDataFetching();
  }, [
    currentFilters.version,
    customAtomsFilters,
    sorting?.columnKey,
    sorting?.order,
    limit,
    pagination.pageSize,
  ]);

  useEffect(
    () => noInteractions.set(interactionsPage === null || interactionsPage.length === 0),
    [interactionsPage],
  );

  return interactionsPage;
}
