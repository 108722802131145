import * as Sentry from '@sentry/react';
import React, {
  createContext, FC, useContext, useEffect,
} from 'react';

import { attachApiUnauthorizedInterceptor, ejectApiUnauthorizedInterceptor } from 'global/api/axiosInstance';
import PersonaType from 'global/lists/PersonaType';
import Role from 'global/lists/Role';
import isUserRoleAllowed from 'global/PermissionsByRoles';
import Person from 'model/Person';
import getUserActions, { UserActionsType } from 'screens/platform/cross-platform-components/context/user/UserActions';
import reducer from 'screens/platform/cross-platform-components/context/user/UserReducer';
import { useCustomReducer } from 'utils/hooks';
import ToastManager from 'utils/ToastManager';

export enum AuthError {
  NETWORK_ERROR = "Couldn't connect to the server. Try again?",
  SERVER_ERROR = 'Our server has experienced an error. Contact support@akooda.co for assistance.',
  UNAUTHORIZED = 'Email does not have access to an Akooda account. Contact your admin to get access.',
  GENERAL_ERROR = 'Something went wrong. Try again?',
  INVALID_TOKEN = 'Invalid authentication token. Sign in again to get access.',
  TENANT_INITIALIZATION_ERROR = "Failed to initialize user's organization.",
  SESSION_EXPIRED = 'User session has expired. Log in again to get access.',
  DISABLED_COOKIES = 'Enable cookies and try again.',
}

export type EmptyUserState = {
  isLoggedIn: null;
  firstName: null;
  lastName: null;
  name: null;
  email: null;
  roles: null;
  personId: null;
  firebaseId: null;
  tenantId: null;
  personaType: null;
};

export type PopulatedUserState = {
  isLoggedIn: boolean;
  firstName: string;
  lastName: string;
  name: string;
  email: string;
  roles: Role[];
  personId: Person['id'];
  firebaseId: string;
  tenantId: number;
  personaType: PersonaType;
};

export type UserState = PopulatedUserState | EmptyUserState;

export const initialState: EmptyUserState = {
  isLoggedIn: null,
  firstName: null,
  lastName: null,
  name: null,
  email: null,
  roles: null,
  personId: null,
  firebaseId: null,
  tenantId: null,
  personaType: null,
};

export type UserContextType = UserState &
  UserActionsType & {
    isSuperAdmin: boolean;
    isAdmin: boolean;
    setAuthError: (error: AuthError) => void;
    isUserAllowed: (minimumRole: Role) => boolean;
  };

export const UserContext = createContext<UserContextType>({} as any);

export const useUserContext: () => UserContextType = () =>
  useContext<UserContextType>(UserContext);

export const UserContextProvider: FC = ({ children }) => {
  const [state, dispatch] = useCustomReducer(reducer, initialState);
  const actions = getUserActions(dispatch);

  function isUserAllowed(minimumRole: Role) {
    return isUserRoleAllowed(state.roles || [], minimumRole);
  }

  const setAuthError = (error: AuthError) => {
    ToastManager.show(error, { type: 'error', key: error });
  };

  useEffect(() => {
    Sentry.setUser({
      id: state.personId || undefined,
      roles: state.roles?.join(', ') || undefined,
      tenantId: state.tenantId || undefined,
    });
    if (state.personId) {
      attachApiUnauthorizedInterceptor(setAuthError, actions.endUserSession);
    }
    return ejectApiUnauthorizedInterceptor;
  }, [state]);

  return (
    <UserContext.Provider
      value={{
        ...state,
        ...actions,
        isSuperAdmin: isUserAllowed(Role.SUPERADMIN) && !!state.email?.endsWith('@akooda.co'),
        isAdmin: isUserAllowed(Role.ADMIN),
        setAuthError,
        isUserAllowed,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
