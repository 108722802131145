import React, { Key, ReactNode, useState } from 'react';

import SettingsPopover from 'common-ui-components/SettingsPopover';
import SettingsCheckboxesSection, { SettingsCheckboxOption } from 'common-ui-components/SettingsPopover/SettingsCheckboxesSection';
import SignificanceScroller from 'screens/platform/contentScreens/AnalyticsScreen/components/WidgetHeader/SignificanceScroller';
import { AnalyticsWidgetConfig, WidgetSettings } from 'screens/platform/contentScreens/AnalyticsScreen/widgets/widgetConfig';

interface SettingsElement {
  key: Key;
  element: ReactNode;
}

export type SetSettingFunction = (changed: Partial<WidgetSettings>, closePopover: boolean) => void;

interface Props {
  settings: Partial<WidgetSettings>;
  setWidgetConfig: (changed: Partial<AnalyticsWidgetConfig>) => void;
}

export default function HeaderSettingsPopover({ settings, setWidgetConfig }: Props) {
  const { includeZeros, includeTotal, significanceThreshold } = settings;
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const setSettings: SetSettingFunction = (updatedSettings, closePopover) => {
    setWidgetConfig({
      settings: {
        ...settings,
        ...updatedSettings,
      },
    });
    if (closePopover) {
      setIsPopoverOpen(false);
    }
  };

  const settingsElements: SettingsElement[] = [];

  const settingsCheckboxOptions: SettingsCheckboxOption[] = [];

  if (includeZeros !== undefined) {
    settingsCheckboxOptions.push({
      label: 'Show Zero Values',
      key: 'includeZeros',
      checked: includeZeros,
      onClick: () => setSettings({ includeZeros: !includeZeros }, true),
    });
  }

  if (includeTotal !== undefined) {
    settingsCheckboxOptions.push({
      label: 'Show Total',
      key: 'includeTotal',
      checked: includeTotal,
      onClick: () => setSettings({ includeTotal: !includeTotal }, true),
    });
  }

  if (settingsCheckboxOptions.length > 0) {
    settingsElements.push({
      key: 'settingsCheckboxesSection',
      element: <SettingsCheckboxesSection
        title="Display"
        options={settingsCheckboxOptions}
      />,
    });
  }

  if (significanceThreshold !== undefined) {
    settingsElements.push({
      key: 'significanceThreshold',
      element: <SignificanceScroller
        value={significanceThreshold}
        setSettings={setSettings}
      />,
    });
  }

  return settingsElements.length > 0 ? (
    <SettingsPopover isOpen={isPopoverOpen} setIsOpen={setIsPopoverOpen}>
      {settingsElements
        .map(({ element, key }) => (
          <span key={key}>
            {element}
          </span>
        ))}
    </SettingsPopover>
  ) : null;
}
