import HierarchicalInterface from 'utils/HierarchicalDataStructures/HierarchicalInterface';

export default class HierarchicalItem extends HierarchicalInterface {
  constructor(
    id: string,
    isSelected = false,
  ) {
    super(id, isSelected);
  }

  propagateSelectionUpwards() {
    if (this.parent?.isParent()) {
      this.parent.propagateSelectionUpwards([this.id]);
    }
  }

  propagateDeselectionUpwards() {
    if (this.parent?.isParent()) {
      this.parent.propagateDeselectionUpwards([this.id]);
    }
  }

  clone(): HierarchicalItem {
    return new HierarchicalItem(this.id, this.isSelected);
  }

  equals(other: HierarchicalInterface | undefined): boolean {
    if (!other || this.isParent() !== other.isParent()) return false;
    return this.id === other.id && this.isSelected === other.isSelected;
  }
}
