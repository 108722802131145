import { useEffect, useMemo } from 'react';

import {
  useCustomAtomsFilters,
} from 'screens/platform/cross-platform-components/context/CustomFilters/CustomAtomsFilters/CustomAtomsFiltersContextProvider';
import { usePlatformContext } from 'screens/platform/cross-platform-components/context/platform/PlatformContext';
import InteractionsCountValidator
  from 'screens/platform/cross-platform-components/Interactions/InteractionsCountValidator';
import {
  InteractionsTableState,
} from 'screens/platform/cross-platform-components/Panels/BottomPanel/CollapsibleInteractionsDrawer';

export default function useInteractionsCount(): {
  restrictedAtomsCount: number | undefined;
  totalAtomsCount: number | undefined;
  } {
  const { interactionsTable: { openState, maxAtomsThreshold, limit } } = usePlatformContext();
  const { filteredAtomsCount } = useCustomAtomsFilters();

  const {
    restrictedAtomsCount,
    totalAtomsCount,
  } = useMemo<ReturnType<typeof useInteractionsCount>>(() => {
    if (filteredAtomsCount === null) {
      return {
        restrictedAtomsCount: undefined,
        totalAtomsCount: undefined,
      };
    }

    const { allowedAtomsCount, restrictedAtoms } = filteredAtomsCount;
    const _restrictedAtomsCount = restrictedAtoms.count ?? 0;
    return {
      restrictedAtomsCount: _restrictedAtomsCount,
      totalAtomsCount: allowedAtomsCount + (restrictedAtoms.exists ? _restrictedAtomsCount : 0),
    };
  }, [filteredAtomsCount]);

  useEffect(() => {
    if (totalAtomsCount !== undefined && openState !== InteractionsTableState.CLOSED) {
      const presentedValue = Math.min(
        maxAtomsThreshold ?? totalAtomsCount,
        limit ?? totalAtomsCount, // If limit is undefined - we can ignore it
        totalAtomsCount,
      );
      InteractionsCountValidator.validate(presentedValue);
    }
  }, [totalAtomsCount]);

  return {
    restrictedAtomsCount,
    totalAtomsCount,
  };
}
