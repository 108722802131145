import { useMemo } from 'react';

import { NullableValuesSelectionConstants } from 'screens/platform/cross-platform-components/context/MasterFiltersContext/NullableValuesSelection';
import OrganizationsMasterFilter from 'screens/platform/cross-platform-components/context/MasterFiltersContext/OrganizationsMasterFilter';
import OrganizationsMasterFilterHierarchyGroup from 'screens/platform/cross-platform-components/context/MasterFiltersContext/OrganizationsMasterFilter/OrganizationsMasterFilterHierarchyGroup';
import { OrganizationsMetadata, useOrganizationsMetadataContext } from 'screens/platform/cross-platform-components/context/OrganizationsMetadata/OrganizationsMetadataContext';

const { UNDEFINED_PROPERTY } = NullableValuesSelectionConstants;

function selectOrganization(
  selection: OrganizationsMasterFilterHierarchyGroup,
  organizationId: string,
  allOrganizationsMetadata: OrganizationsMetadata['allOrganizationsMetadata'],
) {
  selection.getOrganizationsGroup().getChild(organizationId)?.select();

  const organizationMetadata = allOrganizationsMetadata[organizationId];
  if (organizationMetadata) {
    const { risk, size } = organizationMetadata;
    const segments = organizationMetadata.getSegments();
    selection.getRisksGroup().getChild([risk ?? UNDEFINED_PROPERTY, organizationId])?.select();
    selection.getSizesGroup().getChild([size ?? UNDEFINED_PROPERTY, organizationId])?.select();

    const segmentsGroup = selection.getSegmentsGroup();
    if (segments === null) {
      segmentsGroup.getChild([UNDEFINED_PROPERTY, organizationId])?.select();
    } else {
      segments.forEach((segment) => segmentsGroup.getChild([segment, organizationId])?.select());
    }
  }
}

export default function useOrganizationsFilterToHierarchyGroupConverter(
  filterToConvert: OrganizationsMasterFilter,
): OrganizationsMasterFilterHierarchyGroup | null {
  const {
    loading,
    allOrganizationsMetadata,
    risksToOrganizationsMap,
    sizesToOrganizationsMap,
    segmentsToOrganizationsMap,
  } = useOrganizationsMetadataContext();

  return useMemo(() => {
    if (loading) return null;

    const allOrganizationsIds = Object.keys(allOrganizationsMetadata);

    const ans = new OrganizationsMasterFilterHierarchyGroup({
      organizations: {
        mapping: allOrganizationsIds,
        isSelected: filterToConvert.organizationsIds.isAllSelected(),
      },
      risks: {
        mapping: risksToOrganizationsMap,
        isSelected: filterToConvert.selectedRisks.isAllSelected(),
      },
      sizes: {
        mapping: sizesToOrganizationsMap,
        isSelected: filterToConvert.selectedSizes.isAllSelected(),
      },
      segments: {
        mapping: segmentsToOrganizationsMap,
        isSelected: filterToConvert.selectedSegments.isAllSelected(),
      },
    });

    filterToConvert.organizationsIds.selected?.forEach((organizationId) => {
      selectOrganization(ans, organizationId, allOrganizationsMetadata);
    });

    return ans;
  }, [
    filterToConvert,
    allOrganizationsMetadata,
    risksToOrganizationsMap,
    sizesToOrganizationsMap,
    segmentsToOrganizationsMap,
    loading,
  ]);
}
