export enum PersonFilterableProperty {
  ID = 'ID',
  NAME = 'NAME',
  DEPARTMENT = 'DEPARTMENT',
  TEAMS = 'TEAMS',
  MANAGER = 'MANAGER',
  LAST_INTERACTION = 'LAST_INTERACTION',
  ENGAGED_PEOPLE = 'ENGAGED_PEOPLE',
}

export const personFilterablePropertyLabels: Record<
  PersonFilterableProperty,
  string
> = {
  [PersonFilterableProperty.ID]: 'Person',
  [PersonFilterableProperty.NAME]: 'Name',
  [PersonFilterableProperty.DEPARTMENT]: 'Department',
  [PersonFilterableProperty.LAST_INTERACTION]: 'Last interaction',
  [PersonFilterableProperty.TEAMS]: 'Teams',
  [PersonFilterableProperty.MANAGER]: 'Manager',
  [PersonFilterableProperty.ENGAGED_PEOPLE]: 'Engaged with',
};

export type PersonFilterablePropertyOfTypeID =
  | PersonFilterableProperty.ID
  | PersonFilterableProperty.DEPARTMENT;

export default PersonFilterableProperty;
