import { useCallback } from 'react';

import Size from 'global/lists/Size';
import { usePlatformContext } from 'screens/platform/cross-platform-components/context/platform/PlatformContext';
import {
  InteractionsTableState,
} from 'screens/platform/cross-platform-components/Panels/BottomPanel/CollapsibleInteractionsDrawer';

interface ImagesIds {
  visibleIds: string[];
  hiddenIds: string[];
}

export interface MultiImagesSpecificIdentityProps {
  ids: string[];
  size?: Size.SMALL | Size.MEDIUM | Size.BIG;
  hoverable?: boolean;
  onHiddenEntitiesClick?: () => void;
  externalOrganizationId?: string;
  maxImagesThreshold?: number;
}

export enum ImageType {
  ORGANIZATIONS = 'Organizations',
  PEOPLE = 'People',
  CONTACTS = 'Contacts'
}

export function getImageTitleAttributeText(imageType: ImageType): string {
  const titleAttributeTextMapper: Record<ImageType, string> = {
    [ImageType.ORGANIZATIONS]: 'organization',
    [ImageType.PEOPLE]: 'person',
    [ImageType.CONTACTS]: 'contact',
  };
  return `Go to ${titleAttributeTextMapper[imageType]}'s profile`;
}

export function splitToVisibleAndHidden(ids: string[], maxImagesThreshold = 4): ImagesIds {
  let visibleIds = ids;
  let hiddenIds: string[] = [];

  if (ids.length > maxImagesThreshold) {
    hiddenIds = [...ids];
    visibleIds = hiddenIds.splice(0, maxImagesThreshold - 1);
  }
  return { visibleIds, hiddenIds };
}

export function useImageClickHandler(): () => void {
  const { interactionsTable } = usePlatformContext();

  return useCallback(() => {
    if (interactionsTable.openState !== InteractionsTableState.CLOSED) {
      interactionsTable.setOpenState(InteractionsTableState.CLOSED, null);
    }
  }, [interactionsTable]);
}
