import React from 'react';

import ColumnsConfigurator from 'screens/platform/cross-platform-components/ColumnsConfigurator/index';
import { usePlatformContext } from 'screens/platform/cross-platform-components/context/platform/PlatformContext';
import {
  HierarchicalItemType,
} from 'screens/platform/cross-platform-components/HierarchicalSearchInput/HierarchicalItemTypeConfig';
import InteractionsSummaryToggle
  from 'screens/platform/cross-platform-components/Interactions/components/ExtendedInteractionsTableHeader/InteractionsSummaryToggle';
import RestrictedViewButton
  from 'screens/platform/cross-platform-components/Interactions/components/ExtendedInteractionsTableHeader/RestrictedViewButton/index';
import {
  useColumnsByGroups,
} from 'screens/platform/cross-platform-components/Interactions/components/InteractionsTable/columnsConfigUtils';

import style from 'screens/platform/cross-platform-components/Interactions/components/ExtendedInteractionsTableHeader/style.module.scss';

export default function ExtendedInteractionsTableHeader() {
  const {
    interactionsTableColumns,
    dispatchPlatformContext,
  } = usePlatformContext();
  const columnsByGroups = useColumnsByGroups();

  const columnsOrder = {
    get: () => interactionsTableColumns,
    set: (nextColumns) => {
      dispatchPlatformContext({
        type: 'SET_INTERACTIONS_TABLE_COLUMNS',
        payload: { nextColumns },
      });
    },
  };

  return (
    <div className={style.extendedInteractionsTableHeader} onClick={(e) => e.stopPropagation()}>
      <InteractionsSummaryToggle />
      <RestrictedViewButton />
      <ColumnsConfigurator
        allColumnsByGroups={columnsByGroups}
        type={HierarchicalItemType.INTERACTIONS_TABLE_COLUMN}
        columnsOrder={columnsOrder}
        darkBorder={false}
      />
    </div>
  );
}
