import { useMemo } from 'react';

import App from 'global/lists/apps';
import Channel from 'model/Channel';
import Person from 'model/Person';
import useAsync from 'react-use/lib/useAsync';
import {
  HierarchicalItemType,
  useHierarchicalItemTypeImplementations,
} from 'screens/platform/cross-platform-components/HierarchicalSearchInput/HierarchicalItemTypeConfig';

export default function useItemData(itemId: string, type: HierarchicalItemType) {
  const { getItem } = useHierarchicalItemTypeImplementations(type);

  const isPerson = type === HierarchicalItemType.PERSON;
  const isChannel = type === HierarchicalItemType.CHANNEL;

  const { value: itemData } = useAsync(async () => getItem(itemId), [itemId]);

  const isActivePerson = useMemo(() => {
    if (isPerson && itemData) {
      const personData = (itemData as Person);
      return personData.isActive;
    }
    return true;
  }, [isPerson, itemData]);

  const isPrivateChannel = useMemo(() => {
    if (isChannel && itemData) {
      switch ((itemData as Channel).appId) {
        case App.SLACK:
          return (itemData as Channel).isPrivate;
        default:
          return false;
      }
    }
    return false;
  }, [isChannel, itemData]);

  const isBotChannel = useMemo(() => {
    if (isChannel && itemData) {
      return (itemData as Channel).isBot;
    }
    return false;
  }, [isChannel, itemData]);

  return {
    isActivePerson,
    isPrivateChannel,
    isBotChannel,
  };
}
