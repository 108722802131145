import Analytics, { AnalyticsEvent, MasterFilterType } from 'global/Analytics';
import HierarchicalMasterFilter
  from 'screens/platform/cross-platform-components/context/MasterFiltersContext/HierarchicalMasterFilter';
import {
  DatesRange,
  MasterFilters,
} from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFilters';
import OrganizationsMasterFilter
  from 'screens/platform/cross-platform-components/context/MasterFiltersContext/OrganizationsMasterFilter';
import PeopleMasterFilter
  from 'screens/platform/cross-platform-components/context/MasterFiltersContext/PeopleMasterFilter';
import PersonMetadata from 'screens/platform/cross-platform-components/context/metadata/dataStructures/PersonMetadata';
import { MetadataContextType } from 'screens/platform/cross-platform-components/context/metadata/MetadataContext';
import {
  SlackSubDivisionId,
} from 'screens/platform/cross-platform-components/MasterFiltersPanel/masterFiltersUtils/SlackChannelsUtils';
import ArrayUtils from 'utils/ArrayUtils';
import { endOfUTCDay, startOfUTCDay } from 'utils/DateUtils';
import DebuggerConsole from 'utils/DebuggerConsole';
import ObjectUtils from 'utils/ObjectUtils';
import { DeepReadonly } from 'utils/TypescriptTricks';

interface ApplyUrlFiltersPayload {
  currentFilters: Partial<MasterFiltersState['currentFilters']>;
}
interface ChangeCategoriesPayload { nextCategories: HierarchicalMasterFilter }
interface ChangePeoplePayload { nextPeople: MasterFilters['people'] }
interface ChangeDirectionalityPayload { nextDirectionality: MasterFilters['directionality'] }
type ChangeOrganizationsPayload = { nextOrganizations: MasterFilters['organizations'] }
interface ChangeAppsPayload { nextApps: HierarchicalMasterFilter }
interface HidePersonPayload {
  personId: string;
  person: DeepReadonly<PersonMetadata>;
  departmentsToPersonsMap: MetadataContextType['persons']['departmentsToPersonsMap'];
  allTeams: string[];
}
interface OverrideFiltersPayload {
  overriddenFilters: Partial<MasterFilters>;
  shouldPushParamsToUrl: boolean;
}
type ResetOverriddenFiltersPayload = Pick<OverrideFiltersPayload, 'shouldPushParamsToUrl'>
type ChangeDatesRangePayload = Partial<DatesRange>
type MultipleActionsPayload = MasterFiltersReducerAction[]
interface ChangeDefaultFiltersPayload { nextDefaultFilters: MasterFilters }

export type MasterFiltersReducerAction =
  | { type: 'RESET_FILTERS'; payload?: MasterFilters }
  | { type: 'OVERRIDE_FILTERS'; payload: OverrideFiltersPayload }
  | { type: 'RESET_OVERRIDDEN_FILTERS'; payload: ResetOverriddenFiltersPayload }
  | { type: 'CHANGE_CATEGORIES'; payload: ChangeCategoriesPayload }
  | { type: 'TOGGLE_INCLUDE_UNTAGGED' }
  | { type: 'TOGGLE_INCLUDE_UNIDENTIFIED' }
  | { type: 'CHANGE_DATES_RANGE'; payload: ChangeDatesRangePayload }
  | { type: 'CHANGE_PEOPLE'; payload: ChangePeoplePayload }
  | { type: 'CHANGE_DIRECTIONALITY'; payload: ChangeDirectionalityPayload }
  | { type: 'CHANGE_ORGANIZATIONS'; payload: ChangeOrganizationsPayload }
  | { type: 'CHANGE_APPS'; payload: ChangeAppsPayload }
  | { type: 'UPDATED_URL_PARAMS_ACK' }
  | { type: 'APPLY_URL_FILTERS'; payload: ApplyUrlFiltersPayload }
  | { type: 'HIDE_PERSON'; payload: HidePersonPayload }
  | { type: 'COMMCHART_SKIPPED_UPDATE_ACK' }
  | { type: 'CHANGE_MULTIPLE_FILTERS'; payload: MultipleActionsPayload }
  | { type: 'CHANGE_DEFAULT_FILTERS'; payload: ChangeDefaultFiltersPayload }

export interface MasterFiltersState {
  currentFilters: MasterFilters;
  defaultState: MasterFilters;
  shouldPushParamsToUrl: boolean;
  shouldCommChartIgnoreChange: boolean;
  overriddenFiltersOriginalValues: Partial<MasterFilters> | null;
}

export default function MasterFiltersReducer(
  state: MasterFiltersState,
  action: MasterFiltersReducerAction,
): MasterFiltersState {
  switch (action.type) {
    case 'CHANGE_MULTIPLE_FILTERS': return handleMultipleActions(state, action.payload);
    default: return handleSingleAction(state, action);
  }
}

function handleMultipleActions(
  state: MasterFiltersState,
  actions: MultipleActionsPayload,
): MasterFiltersState {
  const initialVersion = getVersion(state);
  let nextState = state;
  actions.forEach((action) => {
    nextState = handleSingleAction(nextState, action);
  });
  if (getVersion(nextState) !== initialVersion) {
    nextState.currentFilters.version = initialVersion + 1;
  }
  return nextState;
}

function getVersion(state: MasterFiltersState) {
  return state.currentFilters.version;
}

function getNextVersion(state: MasterFiltersState) {
  return getVersion(state) + 1;
}

function handleSingleAction(
  state: MasterFiltersState,
  action: MasterFiltersReducerAction,
): MasterFiltersState {
  switch (action.type) {
    case 'RESET_FILTERS': return handleResetFilters(state, action.payload);
    case 'OVERRIDE_FILTERS': return handleOverrideFilters(state, action.payload);
    case 'RESET_OVERRIDDEN_FILTERS': return handleResetOverriddenFilters(state, action.payload);
    case 'CHANGE_CATEGORIES': return handleChangeCategories(state, action.payload);
    case 'CHANGE_DATES_RANGE': return handleChangeDatesRange(state, action.payload);
    case 'CHANGE_PEOPLE': return handleChangePersons(state, action.payload);
    case 'CHANGE_DIRECTIONALITY': return handleChangeDirectionality(state, action.payload);
    case 'CHANGE_ORGANIZATIONS': return handleChangeOrganizations(state, action.payload);
    case 'CHANGE_APPS': return handleChangeApps(state, action.payload);
    case 'UPDATED_URL_PARAMS_ACK': return handleUpdateUrlParams(state);
    case 'APPLY_URL_FILTERS': return handleApplyUrlFilters(state, action.payload);
    case 'HIDE_PERSON': return handleHidePerson(state, action.payload);
    case 'COMMCHART_SKIPPED_UPDATE_ACK': return handleCommChartSkippedUpdateACK(state);
    case 'TOGGLE_INCLUDE_UNTAGGED': return handleToggleIncludeUntagged(state);
    case 'TOGGLE_INCLUDE_UNIDENTIFIED': return handleToggleIncludeUnidentified(state);
    case 'CHANGE_DEFAULT_FILTERS': return handleChangeDefaultFilters(state, action.payload);
    default: return state;
  }
}

function getDefaultCurrentFilters(state: MasterFiltersState) {
  return ObjectUtils.deepClone(state.defaultState);
}

function handleChangeDefaultFilters(
  currentState: MasterFiltersState,
  { nextDefaultFilters }: ChangeDefaultFiltersPayload,
): MasterFiltersState {
  return {
    ...currentState,
    currentFilters: nextDefaultFilters,
    defaultState: nextDefaultFilters,
  };
}

function handleCommChartSkippedUpdateACK(
  currentState: MasterFiltersState,
): MasterFiltersState {
  return {
    ...currentState,
    shouldCommChartIgnoreChange: false,
  };
}

function handleResetFilters(
  currentState: MasterFiltersState,
  payload: MasterFilters | undefined,
): MasterFiltersState {
  const nextFilters = payload ? {
    ...payload,
    version: currentState.currentFilters.version + 1,
  } : getDefaultCurrentFilters(currentState);
  return {
    ...currentState,
    currentFilters: nextFilters,
    shouldPushParamsToUrl: true,
  };
}

function handleOverrideFilters(
  currentState: MasterFiltersState,
  payload: OverrideFiltersPayload,
): MasterFiltersState {
  DebuggerConsole.log('MasterFiltersReducer: OVERRIDE_FILTERS', payload);
  const { currentFilters } = currentState;
  const { overriddenFilters, shouldPushParamsToUrl } = payload;
  return {
    ...currentState,
    overriddenFiltersOriginalValues: Object.keys(overriddenFilters).reduce(
      (acc, key) => ({ ...acc, [key]: currentFilters[key] }),
      {},
    ),
    currentFilters: {
      ...currentFilters,
      ...overriddenFilters,
      version: getNextVersion(currentState),
    },
    shouldPushParamsToUrl,
  };
}

function handleResetOverriddenFilters(
  currentState: MasterFiltersState,
  { shouldPushParamsToUrl }: ResetOverriddenFiltersPayload,
): MasterFiltersState {
  DebuggerConsole.log('MasterFiltersReducer: RESET_OVERRIDDEN_FILTERS');
  return {
    ...currentState,
    currentFilters: {
      ...currentState.currentFilters,
      ...currentState.overriddenFiltersOriginalValues,
      version: getNextVersion(currentState),
    },
    overriddenFiltersOriginalValues: null,
    shouldPushParamsToUrl,
  };
}

function handleChangeCategories(
  currentState: MasterFiltersState,
  payload: ChangeCategoriesPayload,
): MasterFiltersState {
  DebuggerConsole.log('MasterFiltersReducer: CHANGE_CATEGORIES', payload);

  Analytics.trackEvent(
    AnalyticsEvent.USED_FILTERS_ON_ANALYTICS_PAGE,
    { filter: MasterFilterType.CATEGORIES_FILTER },
  );

  const { nextCategories } = payload;
  const { categories } = currentState.currentFilters;

  const hasChange = !categories.tagsByCategories.equals(nextCategories);
  const nextVersion = hasChange ? getNextVersion(currentState) : getVersion(currentState);
  const nextIncludeUntagged = nextCategories.isNotSelected()
    ? true
    : currentState.currentFilters.categories.includeUntagged;

  return {
    ...currentState,
    currentFilters: {
      ...currentState.currentFilters,
      categories: {
        ...currentState.currentFilters.categories,
        tagsByCategories: nextCategories.clone(),
        includeUntagged: nextIncludeUntagged,
      },
      version: nextVersion,
    },
    shouldPushParamsToUrl: true,
  };
}

function handleChangeDatesRange(
  currentState: MasterFiltersState,
  payload: ChangeDatesRangePayload,
): MasterFiltersState {
  DebuggerConsole.log('MasterFiltersReducer: CHANGE_DATES_RANGE', payload);

  Analytics.trackEvent(
    AnalyticsEvent.USED_FILTERS_ON_ANALYTICS_PAGE,
    { filter: MasterFilterType.DATES_RANGE_FILTER },
  );

  const { from, to } = payload;
  const derivedFrom = from ? startOfUTCDay(from) : currentState.currentFilters.datesRange.from;
  const derivedTo = to ? endOfUTCDay(to) : currentState.currentFilters.datesRange.to;
  return {
    ...currentState,
    currentFilters: {
      ...currentState.currentFilters,
      datesRange: { from: derivedFrom, to: derivedTo },
      version: getNextVersion(currentState),
    },
    shouldPushParamsToUrl: true,
  };
}

function handleChangePersons(
  currentState: MasterFiltersState,
  payload: ChangePeoplePayload,
): MasterFiltersState {
  DebuggerConsole.log('MasterFiltersReducer: CHANGE_PEOPLE', payload);

  Analytics.trackEvent(
    AnalyticsEvent.USED_FILTERS_ON_ANALYTICS_PAGE,
    { filter: MasterFilterType.PEOPLE_FILTER },
  );

  const { people } = currentState.currentFilters;
  const { nextPeople } = payload;

  const hasChange = !people.equalsIgnoreFlags(nextPeople);
  const prevVersion = currentState.currentFilters.version;
  const nextVersion = hasChange ? prevVersion + 1 : prevVersion;

  return {
    ...currentState,
    currentFilters: {
      ...currentState.currentFilters,
      people: new PeopleMasterFilter(
        nextPeople.departments ?? people.departments,
        nextPeople.teams ?? people.teams,
        nextPeople.internalPeopleIds ?? people.internalPeopleIds,
        people.includeUnidentified,
      ),
      version: nextVersion,
    },
    shouldPushParamsToUrl: true,
  };
}

function handleChangeDirectionality(
  currentState: MasterFiltersState,
  payload: ChangeDirectionalityPayload,
): MasterFiltersState {
  DebuggerConsole.log('MasterFiltersReducer: DIRECTIONALITY', payload);

  Analytics.trackEvent(
    AnalyticsEvent.USED_FILTERS_ON_ANALYTICS_PAGE,
    { filter: MasterFilterType.DIRECTIONALITY_FILTER },
  );

  const { directionality } = currentState.currentFilters;
  const { nextDirectionality } = payload;

  const hasChange = !ArrayUtils.isEqual(nextDirectionality, directionality);

  const prevVersion = currentState.currentFilters.version;
  const nextVersion = hasChange ? prevVersion + 1 : prevVersion;

  return {
    ...currentState,
    currentFilters: {
      ...currentState.currentFilters,
      version: nextVersion,
      directionality: nextDirectionality,
    },
    shouldPushParamsToUrl: true,
  };
}

function handleChangeOrganizations(
  currentState: MasterFiltersState,
  payload: ChangeOrganizationsPayload,
): MasterFiltersState {
  DebuggerConsole.log('MasterFiltersReducer: CHANGE_ORGANIZATIONS', payload);

  Analytics.trackEvent(
    AnalyticsEvent.USED_FILTERS_ON_ANALYTICS_PAGE,
    { filter: MasterFilterType.ORGANIZATIONS_FILTER },
  );

  const { organizations } = currentState.currentFilters;
  const { nextOrganizations } = payload;

  const hasChange = !organizations.equalsIgnoreFlags(nextOrganizations)
    || organizations.organizationsIds.includeUndefined
      !== nextOrganizations.organizationsIds.includeUndefined;

  const prevVersion = currentState.currentFilters.version;
  const nextVersion = hasChange ? prevVersion + 1 : prevVersion;

  return {
    ...currentState,
    currentFilters: {
      ...currentState.currentFilters,
      version: nextVersion,
      organizations: new OrganizationsMasterFilter({
        ...organizations,
        ...nextOrganizations,
      }),
    },
    shouldPushParamsToUrl: true,
  };
}

function handleChangeApps(
  currentState: MasterFiltersState,
  payload: ChangeAppsPayload,
): MasterFiltersState {
  DebuggerConsole.log('MasterFiltersReducer: CHANGE_APPS', payload);

  Analytics.trackEvent(
    AnalyticsEvent.USED_FILTERS_ON_ANALYTICS_PAGE,
    { filter: MasterFilterType.APPS_FILTER },
  );

  const { nextApps } = payload;
  nextApps.getChild(SlackSubDivisionId.SLACK_BOT_CHANNELS)?.deselect();
  const { apps } = currentState.currentFilters;

  const hasChange = !apps.equals(nextApps);
  const prevVersion = currentState.currentFilters.version;
  const nextVersion = hasChange ? prevVersion + 1 : prevVersion;

  return {
    ...currentState,
    currentFilters: {
      ...currentState.currentFilters,
      apps: nextApps.clone(),
      version: nextVersion,
    },
    shouldPushParamsToUrl: true,
  };
}

function handleApplyUrlFilters(
  currentState: MasterFiltersState,
  payload: ApplyUrlFiltersPayload,
): MasterFiltersState {
  DebuggerConsole.log('MasterFiltersReducer: APPLY_URL_FILTERS', payload);

  return {
    ...currentState,
    currentFilters: {
      ...getDefaultCurrentFilters(currentState),
      ...payload.currentFilters,
      version: getNextVersion(currentState),
    },
  };
}

function handleHidePerson(
  currentState: MasterFiltersState,
  payload: HidePersonPayload,
): MasterFiltersState {
  DebuggerConsole.log('MasterFiltersReducer: HIDE_PERSON', { payload: { personId: payload.personId, person: payload.person } });
  const {
    personId,
    departmentsToPersonsMap,
  } = payload;
  const allPeopleIds = Object.values(departmentsToPersonsMap).flat();

  const { internalPeopleIds } = currentState.currentFilters.people;
  const nextPeopleIds = (internalPeopleIds || allPeopleIds)
    .filter((p) => p !== personId)
    .filter(ArrayUtils.isDefined);

  const currentPeopleFilter = currentState.currentFilters.people;
  return {
    ...currentState,
    currentFilters: {
      ...currentState.currentFilters,
      people: new PeopleMasterFilter(
        currentPeopleFilter.departments,
        currentPeopleFilter.teams,
        nextPeopleIds,
        currentPeopleFilter.includeUnidentified,
      ),
      version: getNextVersion(currentState),
    },
    shouldCommChartIgnoreChange: true,
    shouldPushParamsToUrl: true,
  };
}

function handleUpdateUrlParams(
  currentState: MasterFiltersState,
): MasterFiltersState { return { ...currentState, shouldPushParamsToUrl: false }; }

function handleToggleIncludeUntagged(
  currentState: MasterFiltersState,
): MasterFiltersState {
  const { includeUntagged } = currentState.currentFilters.categories;
  return {
    ...currentState,
    currentFilters: {
      ...currentState.currentFilters,
      categories: {
        ...currentState.currentFilters.categories,
        includeUntagged: !includeUntagged,
      },
      version: getNextVersion(currentState),
    },
    shouldPushParamsToUrl: true,
  };
}

function handleToggleIncludeUnidentified(
  currentState: MasterFiltersState,
): MasterFiltersState {
  const currentPeopleFilter = currentState.currentFilters.people;
  return {
    ...currentState,
    currentFilters: {
      ...currentState.currentFilters,
      people: new PeopleMasterFilter(
        currentPeopleFilter.departments,
        currentPeopleFilter.teams,
        currentPeopleFilter.internalPeopleIds,
        !currentPeopleFilter.includeUnidentified,
      ),
      version: getNextVersion(currentState),
    },
    shouldPushParamsToUrl: true,
  };
}
