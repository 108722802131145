/* eslint-disable no-case-declarations */
import ChangeInAttentionEvent from 'model/Events/ChangeInAttentionEvent';
import CommunicationFlowEvent from 'model/Events/CommunicationFlowEvent';
import OrganizationEvent from 'model/Events/OrganizationEvent';
import OrgStructureEvent from 'model/Events/OrgStructureEvent';
import TrendingTopicEvent from 'model/Events/TrendingTopicEvent';
import TypeUtils from 'utils/TypeUtils';

export enum EventType {
  TRENDING_TOPIC = 'TRENDING_TOPIC',
  COMMUNICATION_FLOW = 'COMMUNICATION_FLOW',
  CHANGE_IN_ATTENTION = 'CHANGE_IN_ATTENTION',
  ORGANIZATION_FIRST_ENGAGEMENT = 'ORGANIZATION_FIRST_ENGAGEMENT',
  ORGANIZATION_LAST_ENGAGEMENT = 'ORGANIZATION_LAST_ENGAGEMENT',
  ORGANIZATION_FIRST_EXECUTIVE_ENGAGEMENT = 'ORGANIZATION_FIRST_EXECUTIVE_ENGAGEMENT',
  PERSON_ONBOARDED = 'PERSON_ONBOARDED',
  PERSON_OFFBOARDED = 'PERSON_OFFBOARDED',
}

export interface EventProps {
  id: string;
  startDate: Date;
  endDate: Date | null;
  significance: number;
  eventType: EventType;
}

type EventResponse =
  | ChangeInAttentionEvent
  | CommunicationFlowEvent
  | OrganizationEvent
  | TrendingTopicEvent
  | OrgStructureEvent;

export const EventParsingUtils = {
  getEventDepartments(event: EventResponse): string[] | null {
    switch (event.eventType) {
      case EventType.TRENDING_TOPIC:
        return [
          event.firstDepartment,
          event.secondDepartment,
        ].filter(TypeUtils.isString);
      case EventType.COMMUNICATION_FLOW:
        return [
          event.firstDepartment.label,
          event.secondDepartment.label,
        ];
      case EventType.CHANGE_IN_ATTENTION:
        return [event.department];
      default:
        return null;
    }
  },
  getEventTag(event: EventResponse): string | null {
    switch (event.eventType) {
      case EventType.TRENDING_TOPIC:
      case EventType.CHANGE_IN_ATTENTION:
        return event.tag;
      default:
        return null;
    }
  },
  getEventOrganization(event: EventResponse): string | null {
    switch (event.eventType) {
      case EventType.ORGANIZATION_FIRST_ENGAGEMENT:
      case EventType.ORGANIZATION_LAST_ENGAGEMENT:
      case EventType.ORGANIZATION_FIRST_EXECUTIVE_ENGAGEMENT:
        return event.organizationId;
      default:
        return null;
    }
  },
  getEventAppId(event: EventResponse): number | null {
    switch (event.eventType) {
      case EventType.ORGANIZATION_FIRST_ENGAGEMENT:
      case EventType.ORGANIZATION_LAST_ENGAGEMENT:
      case EventType.ORGANIZATION_FIRST_EXECUTIVE_ENGAGEMENT:
        return event.appId;
      default:
        return null;
    }
  },
  getEventDepartmentsChange(event: EventResponse): number[] | null {
    switch (event.eventType) {
      case EventType.COMMUNICATION_FLOW:
        return [
          event.firstDepartment.weeklyVolumeChange,
          event.secondDepartment.weeklyVolumeChange,
        ];
      default:
        return null;
    }
  },
};

export default EventResponse;
