import React from 'react';

import style from 'es-src/components/ui/Skeleton/style.module.scss';

const SkeletonShimmer: React.FC = () => (
  <div className={style.shimmerWrapper}>
    <div className={style.shimmer} />
  </div>
);

export default SkeletonShimmer;
