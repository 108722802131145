import { useEffect, useRef } from 'react';

export default function useLatestDataQuery(dependencies: any[]) {
  const hashDependencies = (currentDependencies?: any[]) =>
    JSON.stringify(currentDependencies || dependencies);

  const currentQueryConfig = useRef(hashDependencies());

  useEffect(() => {
    currentQueryConfig.current = hashDependencies();
  }, dependencies);

  return (currentDependencies: any[]) =>
    currentQueryConfig.current === hashDependencies(currentDependencies);
}
