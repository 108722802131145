import React from 'react';

import Checkbox from 'common-ui-components/Checkbox';
import SupportEmailLink from 'common-ui-components/SupportEmailLink/index';
import CheckboxTooltip from 'screens/platform/cross-platform-components/HierarchicalSearchInput/components/ItemListing/CheckboxTooltip';
import { HierarchicalItemType } from 'screens/platform/cross-platform-components/HierarchicalSearchInput/HierarchicalItemTypeConfig';
import {
  SlackSubDivisionId,
} from 'screens/platform/cross-platform-components/MasterFiltersPanel/masterFiltersUtils/SlackChannelsUtils';

const SLACK_BOT_CHANNEL_ERROR = (
  <span>
    Integration and bot notification channels are not processed,
    {' '}
    <SupportEmailLink onMouseDown={(e) => e.stopPropagation()}>
      contact support
    </SupportEmailLink>
    {' '}
    to learn more
  </span>
);

interface Props {
    type: HierarchicalItemType;
    selectionStatus: {
        isSelected: boolean;
        isPartiallySelected: boolean;
    };
    groupId: string;
    onClick: () => void;
}

export default function GroupCheckbox({
  type,
  selectionStatus: { isSelected, isPartiallySelected },
  groupId,
  onClick,
}: Props) {
  function getCheckboxElement(
    isChecked: boolean,
    partiallyChecked: boolean,
    isDisabled: boolean,
  ) {
    return (
      <Checkbox
        checked={isChecked}
        partiallyChecked={partiallyChecked}
        onClick={onClick}
        disabled={isDisabled}
      />
    );
  }

  const isSlackBotChannelsGroup = type === HierarchicalItemType.CHANNEL
      && groupId === SlackSubDivisionId.SLACK_BOT_CHANNELS;

  if (isSlackBotChannelsGroup) {
    return (
      <CheckboxTooltip content={SLACK_BOT_CHANNEL_ERROR} disabled interactive>
        {getCheckboxElement(false, false, true)}
      </CheckboxTooltip>
    );
  }

  return getCheckboxElement(isSelected, isPartiallySelected, false);
}
