import { HighlightType } from 'global/api/controller/AtomController';
import DebuggerConsole from 'utils/DebuggerConsole';

type HighlightInfo = {
  title: string;
  popoverText: string;
};

const highlightDetails: Record<HighlightType, HighlightInfo> = {
  PendingReviewHighlight: {
    title: 'Pending review 🔍',
    popoverText: 'Someone is waiting for you to review this.',
  },
  BugHighlight: {
    title: 'Bug 🐞',
    popoverText: 'This is a bug that may require your attention.',
  },
  LotsOfActionHighlight: {
    title: 'Lots of action 🔥',
    popoverText: 'This interaction is getting a lot of replies.',
  },
  FomoHighlight: {
    title: 'FOMO 😬',
    popoverText: 'This has to do with one of your focus topics.',
  },
  ImportantFYIHighlight: {
    title: 'Important FYI 🗣️',
    popoverText: 'This is referencing another conversation you\'ve been a part of.',
  },
  AllHandsOnDeckHighlight: {
    title: 'All hands on deck 🚢',
    popoverText: 'This is getting attention from several different teams.',
  },
  LongTimeNoSeeHighlight: {
    title: 'Long time no see 👋',
    popoverText: 'You have an upcoming meeting after a long time you didn\'t meet',
  },
  MegaMeetingHighlight: {
    title: 'Mega meeting 📅',
    popoverText: 'You have an upcoming meeting with many participants',
  },
};

export default function getHighlightText(key: HighlightType): HighlightInfo | null {
  const highlight = highlightDetails[key];
  if (!highlight) {
    DebuggerConsole.error('Highlight not found for key', key);
    return null;
  }
  return highlight;
}
