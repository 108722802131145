import HierarchicalGroupInterface, {
  SelectionStatus,
} from 'utils/HierarchicalDataStructures/HierarchicalGroupInterface';

type SelectionUpdateCallback = (hierarchyId: string[], selectionStatus: SelectionStatus) => void;

export default abstract class HierarchicalInterface {
  readonly id: string;
  protected parent: HierarchicalGroupInterface | null;
  protected readonly callbacks: Record<string, SelectionUpdateCallback> = {};
  private maxCallbackIndex = 0;
  isSelected: boolean;

  protected constructor(
    id: string,
    isSelected = false,
  ) {
    this.id = id;
    this.parent = null;
    this.isSelected = isSelected;
  }

  abstract propagateSelectionUpwards(nestedChildPath: string[]);
  abstract propagateDeselectionUpwards(nestedChildPath: string[]);
  abstract equals(other: HierarchicalInterface): boolean;

  toggle(): boolean {
    if (this.isSelected) {
      this.deselect(true);
      return false;
    }
    this.select(true);
    return true;
  }

  select(shouldPropagateUpwards = true) {
    if (!this.isSelected) {
      this.isSelected = true;
      this.triggerCallbacks(this.getIdWithAncestors());

      if (shouldPropagateUpwards) {
        this.propagateSelectionUpwards([]);
      }
    }
  }

  deselect(shouldPropagateUpwards = true) {
    if (this.isSelected) {
      this.isSelected = false;
      this.triggerCallbacks(this.getIdWithAncestors());

      if (shouldPropagateUpwards) {
        this.propagateDeselectionUpwards([]);
      }
    }
  }

  isParent(): this is HierarchicalGroupInterface {
    return this instanceof HierarchicalGroupInterface;
  }

  getParent() {
    return this.parent;
  }

  setParent(parent: HierarchicalGroupInterface) {
    this.parent = parent;
  }

  subscribe(callback: (hierarchyId: string[], selectionStatus: SelectionStatus) => void): string {
    const newSubscriptionId = this.generateSubscriptionId();
    this.callbacks[newSubscriptionId] = callback;
    return newSubscriptionId;
  }

  unsubscribe(callbackId: string) {
    delete this.callbacks[callbackId];
  }

  getIdWithAncestors(): string[] {
    return this.parent ? [...this.parent.getIdWithAncestors(), this.id] : [this.id];
  }

  getSelectionStatus(): SelectionStatus {
    if (this.isSelected) return SelectionStatus.SELECTED;
    if (this.isParent() && this.isPartiallySelected) return SelectionStatus.PARTIALLY_SELECTED;
    return SelectionStatus.UNSELECTED;
  }

  protected triggerCallbacks(calledId: string[]) {
    Object.values(this.callbacks)
      .forEach((callback) => callback(calledId, this.getSelectionStatus()));
  }

  private generateSubscriptionId(): string {
    const newCallbackId = this.maxCallbackIndex.toString();
    this.maxCallbackIndex += 1;
    return newCallbackId;
  }
}
