import React, { useMemo } from 'react';

import MultiImages from 'common-ui-components/MultiImages';
import { ImageType, useImageClickHandler } from 'common-ui-components/MultiImages/MultiImagesUtils';
import MultiPersonsImagesUtils from 'common-ui-components/MultiImages/MultiPersonsImages/MultiPersonsImagesUtils';
import { ImageShape } from 'common-ui-components/MultiImages/NodeImage';
import Size from 'global/lists/Size';
import Organization from 'model/Organization';
import usePersonsMetadata from 'screens/platform/cross-platform-components/context/metadata/hooks/personsMetadataHook';
import useTenantContext from 'screens/platform/cross-platform-components/context/tenant/TenantContext';
import ArrayUtils from 'utils/ArrayUtils';

import multiImagesStyle from 'common-ui-components/MultiImages/style.module.scss';

interface Props {
  organization: Organization;
}

export default function OrganizationOwnersImages({ organization }: Props) {
  const { getDepartmentColor } = useTenantContext();
  const handleImageClick = useImageClickHandler();

  const ownersPersonIdToDescription = organization.properties?.owners ?? {};
  const ownersIds = useMemo(() => Object.keys(ownersPersonIdToDescription), [organization]);
  const { value: persons, loading } = usePersonsMetadata(ownersIds);

  function getVisibleImages(visibleIds: string[]) {
    return visibleIds
      .map((id) => persons?.[id])
      .filter(ArrayUtils.isDefined)
      .map((person, idx) => (
        <MultiPersonsImagesUtils.PersonImage
          key={person.id}
          person={person}
          name={person.name}
          shape={ImageShape.ROUND}
          size={Size.BIG}
          hoverable
          onClick={handleImageClick}
          subtitle={ownersPersonIdToDescription[person.id]}
          borderColor={getDepartmentColor(person.department)}
          order={idx}
        />
      ));
  }

  function getHiddenEntitiesLabels(hiddenIds: string[]): string[] {
    return hiddenIds
      .map((personId) => persons?.[personId]?.name)
      .filter(ArrayUtils.isDefined);
  }

  if (ownersIds.length === 0) {
    return <>-</>;
  }

  return (
    <MultiImages
      ids={ownersIds}
      getVisibleImages={getVisibleImages}
      getHiddenEntitiesLabels={getHiddenEntitiesLabels}
      size={Size.BIG}
      imageType={ImageType.PEOPLE}
      hiddenEntitiesNodeClassName={multiImagesStyle.personImageWrapperHidden}
      loading={loading}
    />
  );
}
