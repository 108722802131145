import React from 'react';

import DepartmentBadge from 'common-ui-components/Badge/DepartmentBadge';
import HiddenItemsTooltip from 'common-ui-components/HiddenItemsTooltip';
import SingularPluralNouns from 'global/lists/SingularPluralNouns';
import useTenantContext from 'screens/platform/cross-platform-components/context/tenant/TenantContext';

import style from 'common-ui-components/MultiDepartmentsBadges/style.module.scss';

interface Props {
  departments: string[];
}

const MAX_VISIBLE_DEPARTMENTS = 3;

export default function MultiDepartmentsBadges({
  departments,
}: Props) {
  const { getDepartmentColor } = useTenantContext();
  if (departments.length === 0) return null;

  const visibleDepartments = departments.slice(0, MAX_VISIBLE_DEPARTMENTS);
  const hiddenDepartments = departments.slice(MAX_VISIBLE_DEPARTMENTS);

  return (
    <div className={style.departments}>
      {visibleDepartments.map((department) =>
        <DepartmentBadge key={department} department={department} />)}
      <HiddenItemsTooltip
        items={hiddenDepartments}
        colorMapper={getDepartmentColor}
        noun={SingularPluralNouns.department}
      />
    </div>
  );
}
