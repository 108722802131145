import React, { FC, useEffect } from 'react';

import ExternalLink from 'common-ui-components/ExternalLink';
import Intercom from 'global/Intercom';
import { APPLICATION_STATUS_URL, PRODUCT_UPDATES_URL, TERMS_AND_CONDITIONS_URL } from 'global/links';
import { useGlobalLoading } from 'screens/platform/cross-platform-components/context/GlobalLoadingContextProvider';
import GetStartedHeader from 'screens/public/LoginPage/GetStartedHeader';
import LoginMainSlide from 'screens/public/LoginPage/LoginMainSlide';

import style from 'screens/public/LoginPage/style.module.scss';

const LoginPage: FC = () => {
  const { setFullScreenLoading } = useGlobalLoading();

  useEffect(() => {
    setFullScreenLoading(false);
    Intercom.startAnonymousSession();
  }, []);

  return (
    <div className={style.container}>
      <div className={style.illustration} />
      <div className={style.content}>
        <GetStartedHeader />
        <div className={style.slidesWrapper}>
          <LoginMainSlide />
        </div>
        <footer>
          <ExternalLink to={TERMS_AND_CONDITIONS_URL}>
            Terms and Conditions
          </ExternalLink>
          <a href="mailto:info@akooda.co">Contact Us</a>
          <ExternalLink to={APPLICATION_STATUS_URL}>
            Application Status
          </ExternalLink>
          <ExternalLink to={PRODUCT_UPDATES_URL}>
            Product Updates
          </ExternalLink>
        </footer>
      </div>
    </div>
  );
};

export default LoginPage;
