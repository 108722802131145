import { useCallback } from 'react';

import { EXTERNAL_ORGANIZATIONS_DEPARTMENT, UNASSIGNED_DEPARTMENT } from 'model/Tenant';
import { useMetadataContext } from 'screens/platform/cross-platform-components/context/metadata/MetadataContext';
import useTenantContext from 'screens/platform/cross-platform-components/context/tenant/TenantContext';
import ArrayUtils from 'utils/ArrayUtils';

type PeopleIdsByType = {
  internalPeopleIds: string[];
  externalPeopleIds: string[];
  organizationsIds: string[];
  departmentsIds: string[];
}

interface InteractionsDataFetcherUtilsResponse {
  getTagCategories: (tag: string) => string[];
  getDepartment: (id: string) => Promise<string>;
  splitIdsByTypes: (ids: string[]) => Promise<PeopleIdsByType>;
}

export default function useInteractionsDataFetcherUtils(): InteractionsDataFetcherUtilsResponse {
  const { tenant } = useTenantContext();
  const { persons, organizations, categories } = useMetadataContext();

  const getTagCategories = useCallback(
    (tag: string) => Object.entries(categories)
      .filter(([_, tags]) => tags.includes(tag))
      .map(([category, _]) => category),
    [categories],
  );

  const getDepartment = useCallback(
    async (id: string) => {
      const person = await persons.getById(id);
      const organization = await organizations.getById(id);
      if (person) return person.department;
      if (organization) return EXTERNAL_ORGANIZATIONS_DEPARTMENT;
      return UNASSIGNED_DEPARTMENT;
    },
    [],
  );

  const splitIdsByTypes = async (ids: string[]) => {
    const departmentsLabels = Object.values(tenant.departments)
      .filter(ArrayUtils.isDefined)
      .map(({ label }) => label);
    const isInternalPersonId = (id: string) => Boolean(persons.allPeopleMetadata[id]);
    const organizationIds = (await Promise.all(ids.map(organizations.getById)))
      .filter(ArrayUtils.isDefined)
      .map((o) => o.id);
    return ids.reduce<PeopleIdsByType>((acc, id) => {
      if (organizationIds.includes(id)) {
        acc.organizationsIds = [...acc.organizationsIds, id];
      } else if (departmentsLabels.includes(id)) {
        acc.departmentsIds = [...acc.departmentsIds, id];
      } else if (isInternalPersonId(id)) {
        acc.internalPeopleIds = [...acc.internalPeopleIds, id];
      } else {
        acc.externalPeopleIds = [...acc.externalPeopleIds, id];
      }
      return acc;
    }, {
      internalPeopleIds: [],
      externalPeopleIds: [],
      organizationsIds: [],
      departmentsIds: [],
    });
  };

  return {
    getTagCategories, getDepartment, splitIdsByTypes,
  };
}
