import { AllPeopleMetadata, AllPeopleMetadataApiResponse } from 'global/api/controller/PersonController';
import {
  NullableValuesSelectionConstants,
} from 'screens/platform/cross-platform-components/context/MasterFiltersContext/NullableValuesSelection';
import PersonMetadata from 'screens/platform/cross-platform-components/context/metadata/dataStructures/PersonMetadata';
import ObjectUtils from 'utils/ObjectUtils';

const { UNDEFINED_PROPERTY } = NullableValuesSelectionConstants;

export default function parsePeopleResponse(
  nextPeople: AllPeopleMetadataApiResponse,
): Pick<AllPeopleMetadata, 'allPeopleMetadata'> & {
  departmentsToPersonsMap: AllPeopleMetadata['departmentToPeople'] & Partial<{ UNDEFINED_PROPERTY: string[] }>;
  teamsToPeopleMap: AllPeopleMetadata['teamToPeople'] & Partial<{ UNDEFINED_PROPERTY: string[] }>;
} {
  const {
    allPeopleMetadata,
    departmentToPeople,
    teamToPeople,
  } = nextPeople;

  const parsedAllPeopleMetadata = ObjectUtils.mapEntries(
    allPeopleMetadata,
    ([personId, personMetadata]) => [
      personId,
      new PersonMetadata(personMetadata!.department, personMetadata!.teams),
    ],
  );

  function addUndefinedCase(
    metadataField: 'department' | 'teams',
    defaultObject: Partial<Record<string, string[]>>,
  ) {
    const peopleWithUndefinedField = Object.entries(parsedAllPeopleMetadata)
      .reduce<string[]>((acc, [orgId, personMetadata]) =>
        (personMetadata.getTeams() === null
          ? acc.concat([orgId])
          : acc), []);
    const additionalObject = peopleWithUndefinedField.length > 0
      ? { [UNDEFINED_PROPERTY]: peopleWithUndefinedField }
      : {};

    return { ...defaultObject, ...additionalObject };
  }

  const teamsToPeopleMap = addUndefinedCase('teams', teamToPeople);

  return {
    allPeopleMetadata: parsedAllPeopleMetadata,
    departmentsToPersonsMap: departmentToPeople,
    teamsToPeopleMap,
  };
}
