import FilterableProperty from 'global/api/controller/utils/filtering/FilterableProperty';
import { ListOption } from 'global/ListOptions';
import { NullableValuesSelectionConstants } from 'screens/platform/cross-platform-components/context/MasterFiltersContext/NullableValuesSelection';
import { FiltersConfig, FilterValues } from 'screens/platform/directory/components/GenericDirectoryScreen/FiltersList/FilterConfig';
import { FilterConditionsMap } from 'screens/platform/directory/hooks/filterConditionsHook';
import LocalStorageUtils, { StorageKey } from 'utils/LocalStorageUtils';
import SessionStorageUtils, { SessionKey, SessionKeyFilters } from 'utils/SessionStorageUtils';
import StorageUtils from 'utils/StorageUtils';

export function getStorage(isSessionStorage: boolean): StorageUtils<SessionKey | StorageKey> {
  return isSessionStorage ? SessionStorageUtils : LocalStorageUtils;
}

function getPersistedFilters<
  FilterKeyType extends string,
  FilterValuesType extends FilterValues<FilterKeyType>,
>(
  filtersConfig: FiltersConfig<FilterKeyType>,
  storageKey: SessionKeyFilters,
  isSessionStorage: boolean,
) {
  const validateScheme = (
    persistedFilters: any,
  ): persistedFilters is FilterValuesType => {
    if (typeof persistedFilters !== 'object') return false;
    return Object.entries(persistedFilters).every(([filterKey, value]) => {
      const filterConfig = filtersConfig[filterKey];
      return filterConfig && typeof filterConfig.defaultValue === typeof value;
    });
  };
  const storage = getStorage(isSessionStorage);
  return storage.getItem(storageKey, validateScheme);
}

export function getDefaultFiltersValues<
  FilterKeyType extends string,
  FilterValuesType extends FilterValues<FilterKeyType>,
>(
  filtersConfig: FiltersConfig<FilterKeyType>,
  storageKey: SessionKeyFilters | undefined,
  defaultFiltersKeys: FilterKeyType[],
  injectedFilterValues: Partial<FilterValuesType>,
  isSessionStorage: boolean,
): FilterValuesType {
  if (storageKey) {
    const persistedFilters = getPersistedFilters<FilterKeyType, FilterValuesType>(
      filtersConfig,
      storageKey,
      isSessionStorage,
    );
    if (persistedFilters) {
      return { ...persistedFilters, ...injectedFilterValues };
    }
  }
  return defaultFiltersKeys.reduce(
    (acc, filterKey) => ({
      ...acc,
      [filterKey]: filtersConfig[filterKey].defaultValue,
      ...injectedFilterValues,
    }),
    {} as FilterValuesType,
  );
}

const { UNDEFINED_PROPERTY, UNDEFINED_PROPERTY_LABEL } = NullableValuesSelectionConstants;
export const undefinedOption: ListOption<typeof UNDEFINED_PROPERTY> = {
  value: UNDEFINED_PROPERTY,
  label: UNDEFINED_PROPERTY_LABEL,
};

export function convertFilterValuesToFilterConditions<
  FilterKeyType extends string,
  FilterablePropertyType extends FilterableProperty,
>(
  filterValues: FilterValues<FilterKeyType>,
  filtersConfig: FiltersConfig<FilterKeyType>,
): FilterConditionsMap<FilterablePropertyType> {
  return Object.entries(filterValues).reduce((acc, [filterKey, values]) => {
    const filterConfig = filtersConfig[filterKey as FilterKeyType];
    if (filterConfig.toFilterCondition) {
      const valuesWithConvertedUndefinedProperty = Array.isArray(values) ? values.map((v) =>
        (v === UNDEFINED_PROPERTY ? null : v)) : values as any;
      const filterCondition = filterConfig.toFilterCondition(
        valuesWithConvertedUndefinedProperty,
      );
      acc[filterCondition.fieldName] = filterCondition;
    }
    return acc;
  }, {});
}
