import React, { ReactNode } from 'react';

import Role from 'global/lists/Role';
import { useUserContext } from 'screens/platform/cross-platform-components/context/user/UserContext';

interface Props {
  minimumRole: Role;
  children: ReactNode;
}

export default function AuthorizedWrapper({ minimumRole, children }: Props) {
  const { isUserAllowed } = useUserContext();

  return isUserAllowed(minimumRole) ? <>{children}</> : null;
}
