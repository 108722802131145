import classNames from 'classnames';
import React from 'react';

import Button from 'common-ui-components/Button';
import Tooltip from 'common-ui-components/Tooltip';

import { ReactComponent as InfoIcon } from 'assets/img/icon/info.svg';

import style from 'common-ui-components/TitleWithTooltip/style.module.scss';

interface Props {
  title: string | React.ReactElement;
  description: string;
  bold?: boolean;
}

export default function TitleWithTooltip({ title, description, bold = false }: Props) {
  return (
    <div className={style.titleWithTooltipContainer}>
      <div className={classNames(
        style.title,
        bold && style.bold,
      )}
      >
        {title}
      </div>
      <Tooltip content={description}>
        <Button transparent className={style.infoButton} onClick={(e) => e.stopPropagation()}>
          <InfoIcon className={style.icon} />
        </Button>
      </Tooltip>
    </div>
  );
}
