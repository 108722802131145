import { FC } from 'react';

export enum DateRangeGranularity { DAY = 'DAY', WEEK = 'WEEK' }

export enum WidgetType {
  METRICS = 'METRICS',
  RELATIVE_VOLUME = 'RELATIVE_VOLUME',
  VOLUME = 'VOLUME',
  EVENT = 'EVENT',
  SIGNIFICANCE = 'SIGNIFICANCE',
  PARTICIPANTS = 'PARTICIPANTS'
}

export enum GroupType {
  CATEGORIES = 'CATEGORIES',
  TAGS = 'TAGS',
  DEPARTMENTS = 'DEPARTMENTS',
  APPS = 'APPS',
  CHANNELS = 'CHANNELS',
  ORGANIZATIONS = 'ORGANIZATIONS',
  ORIENTATION = 'ORIENTATION',
  ORGANIZATION_ACTIVITY = 'ORGANIZATION_ACTIVITY',
}

export function isGroupType(val: any): val is GroupType {
  return Object.values(GroupType).includes(val);
}

export enum ChartType {
  BAR = 'BAR',
  STACKED_BAR = 'STACKED_BAR',
  HEATMAP = 'HEATMAP',
  LINE = 'LINE',
  BUBBLE = 'BUBBLE',
  STATISTICS = 'STATISTICS',
  COMMCHART = 'COMMCHART',
}

export interface WidgetSettings {
  includeZeros: boolean;
  includeTotal: boolean;
  significanceThreshold: number;
}

export interface WidgetConfig {
  group: GroupType | null;
  chart: ChartType;
  granularity: DateRangeGranularity | null;
  settings: Partial<WidgetSettings>;
}

export interface AnalyticsWidgetConfig extends WidgetConfig {
  isVisible: boolean;
}

export interface WidgetRecipe extends Partial<AnalyticsWidgetConfig> {
  Component: FC<any>;
}

// The Analytics screen currently excludes these widgets from display
// TODO: Remove unnecessary widgets if they are not in use.
export const disabledWidgets = [WidgetType.METRICS];
