import { useEffect } from 'react';

import { ShareOfAttentionTableDataResponse } from 'global/api/controller/TimelineController';
import Api from 'global/api/platformApi';
import ShareOfAttentionTableUtils, {
  ShareOfAttentionTableColumn,
} from 'screens/platform/contentScreens/AnalyticsScreen/widgets/ShareOfAttentionWidget/visualizations/ShareOfAttentionTable/ShareOfAttentionTableUtils';
import { GroupType } from 'screens/platform/contentScreens/AnalyticsScreen/widgets/widgetConfig';
import { MasterFilters } from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFilters';
import useTenantContext from 'screens/platform/cross-platform-components/context/tenant/TenantContext';
import DebuggerConsole from 'utils/DebuggerConsole';
import { useMountedState } from 'utils/hooks';

function isInvalidQuery(
  currentFilters: MasterFilters,
  group: GroupType,
): boolean {
  const {
    categories: { tagsByCategories, includeUntagged },
    people,
    organizations,
  } = currentFilters;

  switch (group) {
    case GroupType.TAGS:
      return tagsByCategories.isNotSelected() && !includeUntagged;

    case GroupType.DEPARTMENTS:
      return people.isEmpty() && !people.includeUnidentified;

    case GroupType.ORGANIZATIONS:
      return organizations.isEmpty();

    default: {
      DebuggerConsole.error(`Invalid Group Type: '${group}'`);
      return true;
    }
  }
}

export default function useShareOfAttentionDataFetcher(
  group: GroupType,
  includeZeros: boolean,
  masterFilters: MasterFilters | null,
  limit?: number,
) {
  const [
    shareOfAttentionData,
    setShareOfAttentionData,
  ] = useMountedState<ShareOfAttentionTableColumn[] | null>(null);
  const [isNetworkError, setError] = useMountedState<boolean>(false);

  const { tenant } = useTenantContext();

  useEffect(() => {
    async function fetchShareOfAttentionData(): Promise<ShareOfAttentionTableDataResponse> {
      try {
        setError(false);
        if (masterFilters) {
          const res = await Api.Timeline.getShareOfAttentionTableData(
            tenant.id,
            masterFilters,
            group,
            includeZeros,
            limit,
          );
          return res?.data || [];
        }
        return [];
      } catch (err) {
        DebuggerConsole.error(err);
        setError(true);
        return [];
      }
    }

    if (masterFilters === null || isInvalidQuery(masterFilters, group)) {
      setShareOfAttentionData([]);
      return;
    }

    setShareOfAttentionData(null);
    fetchShareOfAttentionData()
      .then((fetchedData) => {
        const parsedData = ShareOfAttentionTableUtils.parseShareOfAttentionData(fetchedData);
        setShareOfAttentionData(parsedData);
      });
  }, [
    masterFilters,
    group,
    includeZeros,
  ]);

  return { shareOfAttentionData, isNetworkError };
}
