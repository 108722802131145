import { useMemo } from 'react';

import { BriefResponse, SummaryCategory, SummaryTag } from 'global/api/controller/BriefController';
import { useMetadataContext } from 'screens/platform/cross-platform-components/context/metadata/MetadataContext';
import { startOfUTCDay } from 'utils/DateUtils';

type DailyData = Record<string, number>;
type FormattedDate = string;

type GraphData = {
  date: FormattedDate;
  data: DailyData;
}[]

interface TooltipDailyCategoryData { label: string; value: number; color: string }
type TooltipsData = Record<FormattedDate, TooltipDailyCategoryData[]>;

export interface PieChartData {
  categories: {
    id: string;
    label: string;
    value: number;
    color: string;
  }[];
  tagsByCategories: Record<string, SummaryTag[]>;
}

type DailyBarChartData = Record<string, string | number> & { date: string };
export type BarChartData = DailyBarChartData[];

const MIN_TAG_PERCENTAGE_THRESHOLD = 0.002;

function generateTooltipsData(
  graphData: GraphData,
  totalsByDates: Record<string, number>,
  keys: string[],
  getCategoryColor: (category: string) => string,
): TooltipsData {
  return graphData.reduce((allDatesData, dailyDatum) => {
    const dailyTooltipData = Object.entries(dailyDatum.data)
      .reduce((acc, [category, value]) => ({
        ...acc,
        [category]: { label: category, value, color: getCategoryColor(category) },
      }), {} as Record<string, TooltipDailyCategoryData>);

    const dailyOrderedCategoriesData = keys.reduce((acc, category) => {
      if (dailyTooltipData[category]) {
        const dailyTotal = totalsByDates[dailyDatum.date];
        const proportionalValue = dailyTotal
          ? dailyTooltipData[category].value / dailyTotal
          : 0;
        return [
          ...acc, {
            ...dailyTooltipData[category],
            value: proportionalValue,
          },
        ];
      }
      return acc;
    }, [] as TooltipDailyCategoryData[]).reverse();

    return {
      ...allDatesData,
      [dailyDatum.date]: dailyOrderedCategoriesData,
    };
  }, {} as TooltipsData);
}

function generateBarChartData(
  graphData: GraphData,
  totalsByDates: Record<string, number>,
  getCategoryColor: (category: string) => string,
): BarChartData {
  return graphData.reduce((acc, dailyDatum) => {
    const getProportionalValue = (value: number) => {
      const dailyTotal = totalsByDates[dailyDatum.date];
      return dailyTotal ? (value / dailyTotal) * 100 : 0;
    };
    const getCategoryColorKey = (category: string) => `${category.toLowerCase()}Color`;

    const dailyBarChartData = Object.entries(dailyDatum.data)
      .reduce((dailyAcc, [category, value]) => ({
        ...dailyAcc,
        [category]: getProportionalValue(value),
        [getCategoryColorKey(category)]: getCategoryColor(category),
      }), { date: dailyDatum.date } as DailyBarChartData);

    acc.push(dailyBarChartData);
    return acc;
  }, [] as BarChartData);
}

export interface ParsedBriefChartData {
  barChartData: BarChartData;
  pieChartData: PieChartData | null;
  tooltipsData: TooltipsData;
  keys: string[];
}

export default function useBriefBarChartDataParser(
  rawData: BriefResponse | null,
): ParsedBriefChartData {
  const { getCategoryColor, categoryColorsOrdinals } = useMetadataContext();

  const graphData = useMemo<GraphData | null>(
    () => rawData && Object.entries(rawData.categoriesByDays || {})
      .reduce<GraphData>((acc, [date, dailyCategories]) => {
        if (date === startOfUTCDay(new Date()).getTime().toString()
          && dailyCategories.totalDailyScore === 0) {
          return acc;
        }

        const newDataItem = dailyCategories.categoriesHistograms
          .reduce((categories, categoryData) => ({
            ...categories,
            [categoryData.value]: categoryData.score,
          }), {});

        return [...acc, { date, data: newDataItem }];
      }, []),
    [rawData],
  );

  const totalsByDates = useMemo(
    () => Object.entries(rawData?.categoriesByDays || {})
      .reduce(
        (acc, [date, dailyCategories]) => ({
          ...acc,
          [date]: dailyCategories.totalDailyScore,
        }),
    {} as Record<string, number>,
      ),
    [rawData],
  );

  const keys = useMemo(
    () =>
      rawData?.summary
        .map((s) => s.label)
        .sort((c1, c2) => {
          const color1 = getCategoryColor(c1);
          const color2 = getCategoryColor(c2);
          if (!color1 || !color2) return 0;
          const color1Ordinal = categoryColorsOrdinals[color1];
          const color2Ordinal = categoryColorsOrdinals[color2];
          if (!color1Ordinal || !color2Ordinal) return 0;
          return color1Ordinal - color2Ordinal;
        }).reverse() || [],
    [rawData, getCategoryColor],
  );

  const tooltipsData = useMemo(() => generateTooltipsData(
    graphData || [],
    totalsByDates,
    keys,
    getCategoryColor,
  ), [graphData, keys]);

  const barChartData = useMemo(() => generateBarChartData(
    graphData || [],
    totalsByDates,
    getCategoryColor,
  ), [graphData]);

  const pieChartData: PieChartData | null = rawData && {
    categories: rawData.summary
      .map((categoryData) => {
        const categoryLabel = categoryData.label;
        return {
          id: categoryLabel,
          label: categoryLabel,
          value: categoryData.percentage,
          color: getCategoryColor(categoryLabel),
        };
      }),

    tagsByCategories: rawData.summary.reduce((acc, categoryData: SummaryCategory) => {
      let filteredTags = categoryData.tags
        .filter((tagData) => tagData.percentage > MIN_TAG_PERCENTAGE_THRESHOLD);

      if (filteredTags.length === 0) {
        filteredTags = categoryData.tags.slice(0, 3);
      }

      return {
        ...acc,
        [categoryData.label]: filteredTags,
      };
    }, {} as Record<string, SummaryTag[]>),
  };

  return {
    barChartData,
    pieChartData,
    tooltipsData,
    keys,
  };
}
