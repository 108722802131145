import classNames from 'classnames';
import React from 'react';

import { findEventCategory } from 'global/lists/EventCategory';
import Size from 'global/lists/Size';
import { EventType } from 'model/Events/Event';
import { BubbleShape } from 'screens/platform/contentScreens/AnalyticsScreen/widgets/EventsChart/BubbleChart';
import {
  eventCategoriesToShapes,
} from 'screens/platform/contentScreens/AnalyticsScreen/widgets/EventsChart/EventChartUtils';
import { EventsByDate } from 'screens/platform/contentScreens/AnalyticsScreen/widgets/EventsChart/EventsChartDataHook';
import TooltipShape from 'screens/platform/contentScreens/AnalyticsScreen/widgets/EventsChart/TooltipShape';
import { DateRangeGranularity } from 'screens/platform/contentScreens/AnalyticsScreen/widgets/widgetConfig';
import { formatDateByGranularity } from 'utils/DateUtils';

import style from 'screens/platform/contentScreens/AnalyticsScreen/widgets/chartTooltipStyle.module.scss';

interface Props {
  datum: { datum: EventsByDate };
}

const EVENT_TYPE_TO_TITLE: Record<EventType, string> = Object.freeze({
  [EventType.TRENDING_TOPIC]: 'Trending Topics',
  [EventType.CHANGE_IN_ATTENTION]: 'Change in Attention',
  [EventType.COMMUNICATION_FLOW]: 'Communication Flow',
  [EventType.ORGANIZATION_FIRST_ENGAGEMENT]: 'First Engagement',
  [EventType.ORGANIZATION_LAST_ENGAGEMENT]: 'Disengaged Organization',
  [EventType.ORGANIZATION_FIRST_EXECUTIVE_ENGAGEMENT]: 'Executive Leverage',
  [EventType.PERSON_ONBOARDED]: 'People Onboarded',
  [EventType.PERSON_OFFBOARDED]: 'People Offboarded',
});

export default function EventsChartTooltip({ datum }: Props) {
  const { datum: { date, eventsMap } } = datum;
  const formattedDate = formatDateByGranularity(date, DateRangeGranularity.WEEK);
  const totalEvents = Object.values(eventsMap).reduce((acc, group) => acc + group.count, 0);
  const title = `${formattedDate} (${totalEvents} events)`;

  function getEventShape(type: EventType): BubbleShape {
    const category = findEventCategory(type);
    return eventCategoriesToShapes[category];
  }

  return (
    <div className={style.tooltip}>
      <div className={classNames(style.row, style.title)}>
        {title}
      </div>
      {
        Object.entries(eventsMap)
          .map(([eventType, group]) => (
            <div className={style.row} key={eventType}>
              <div className={style.left}>
                <TooltipShape
                  color={group.color}
                  shape={getEventShape(eventType as EventType)}
                  size={Size.MEDIUM}
                />
                <span className={style.label}>
                  {EVENT_TYPE_TO_TITLE[eventType]}
                </span>
              </div>
              <div>
                <span className={style.value}>
                  {`${group.count} events`}
                </span>
              </div>
            </div>
          ))
      }
    </div>
  );
}
