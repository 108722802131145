import React from 'react';

import PersonProfileImage from 'common-ui-components/MultiImages/ProfileImage/PersonProfileImage';
import SourceBadge from 'common-ui-components/Sources/SourceBadge/index';
import PersonPopoverContent from 'common-ui-components/Sources/SourcePopoverContent/PersonPopoverContent';
import { EnrichedPersonSource } from 'es-src/screens/HomeScreen/EnterpriseSearchContext/EnrichedSourcesDataHook';
import Analytics, { AnalyticsEvent } from 'global/Analytics';
import Size from 'global/lists/Size';
import { useInternalScreenRedirect } from 'global/ScreensConfiguration';

interface Props {
  source: EnrichedPersonSource;
}
export default function PersonBadge({ source }: Props) {
  const { data: person, type } = source;
  const redirectToInternalScreen = useInternalScreenRedirect(true);

  if (!person) return null;

  const analyticsPayload = {
    sourceType: type,
    sourceId: person.id,
  };

  function handlePopoverHover() {
    Analytics.trackEvent(
      AnalyticsEvent.ES_SOURCE_TOOLTIP_USED,
      analyticsPayload,
    );
  }

  function handleSourceBadgeClick() {
    Analytics.trackEvent(
      AnalyticsEvent.ES_SOURCE_BADGE_CLICK,
      analyticsPayload,
    );
  }

  function redirectToPersonPage() {
    if (!person || person?.isExternal) return;

    redirectToInternalScreen(
      'platform.directory.people.profile',
      person.id,
    );

    handleSourceBadgeClick();
  }

  return (
    <SourceBadge
      type={type}
      redirect={redirectToPersonPage}
      image={<PersonProfileImage person={person} size={Size.SMALL} />}
      title={person.name}
      popoverContent={<PersonPopoverContent person={person} />}
      handlePopoverHover={handlePopoverHover}
      handleSourceClick={handleSourceBadgeClick}
    />
  );
}
