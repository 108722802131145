import format from 'date-fns/format';
import parse from 'date-fns/parse';
import { getScreenPath } from 'global/ScreensConfiguration';
import { OrganizationRisk, OrganizationSize } from 'model/Organization';
import { GPT_SESSION_URL_PARAMS } from 'screens/backoffice/screens/GPTSessions';
import {
  ANALYTICS_WIDGETS_SEARCH_PARAMS,
} from 'screens/platform/contentScreens/AnalyticsScreen/utils/UrlParams';
import Directionality from 'screens/platform/cross-platform-components/context/MasterFiltersContext/Directionality';
import { MasterFilters } from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFilters';
import NullableValuesSelection
  from 'screens/platform/cross-platform-components/context/MasterFiltersContext/NullableValuesSelection';
import OrganizationsMasterFilter
  from 'screens/platform/cross-platform-components/context/MasterFiltersContext/OrganizationsMasterFilter';
import PeopleMasterFilter
  from 'screens/platform/cross-platform-components/context/MasterFiltersContext/PeopleMasterFilter';
import {
  SEARCH_URL_PARAM_KEY,
} from 'screens/platform/cross-platform-components/context/UrlParams/UrlParamsGenerator';
import { FlatUrlParams } from 'screens/platform/cross-platform-components/context/UrlParams/UrlParamsTypes';
import { REPORT_PARAM_KEY } from 'screens/platform/cross-platform-components/Navbar/components/ScreenButton/ReportsButton';
import { convertUTCToUserTimezone } from 'utils/DateUtils';

const DATE_FORMAT = 'yyyy-MM-dd';

const keysByTypes = {
  date: ['from', 'to'] as const,
  hierarchicalGroup: ['categories', 'tags', 'apps', 'channels'] as const,
  array: [
    'peopleIds',
    'departments',
    'selectedTeams',
    'selectedOrganizationsIds',
    'selectedOrganizationsRisks',
    'selectedOrganizationsSizes',
    'selectedOrganizationsSegments',
    'directionality',
  ] as const,
  boolean: [
    'includeUntagged',
    'includeUnidentified',
    'includeUndefinedOrganizations',
    'selectAllTeams',
    'includeUndefinedTeam',
    'selectAllOrganizationsRisks',
    'selectAllOrganizationsSizes',
    'selectAllOrganizationsSegments',
    'selectAllOrganizationsIds',
    'includeUndefinedOrganizationsRisk',
    'includeUndefinedOrganizationsSize',
    'includeUndefinedOrganizationsSegment',
  ] as const,
};

// This variable makes sure that only FlatUrlParams keys are used in 'keysByTypes'
const flatUrlParamsKeys: Record<string, Readonly<(keyof FlatUrlParams)[]>> = keysByTypes;

type DateFilterKey = typeof keysByTypes.date[number];
type HierarchicalGroupFilterKey = typeof keysByTypes.hierarchicalGroup[number];
type ArrayFilterKey = typeof keysByTypes.array[number];
type BooleanFilterKey = typeof keysByTypes.boolean[number];

export default {
  isDateFilter(key: keyof FlatUrlParams): key is DateFilterKey {
    return flatUrlParamsKeys.date.includes(key);
  },

  isHierarchicalGroupFilter(key: keyof FlatUrlParams): key is HierarchicalGroupFilterKey {
    return flatUrlParamsKeys.hierarchicalGroup.includes(key);
  },

  isArrayFilter(key: keyof FlatUrlParams): key is ArrayFilterKey {
    return flatUrlParamsKeys.array.includes(key);
  },

  isBooleanFilter(key: keyof FlatUrlParams): key is BooleanFilterKey {
    return flatUrlParamsKeys.boolean.includes(key);
  },

  convertArrayToUrlParam(
    currentFilterValue: string[] | null,
    defaultFilterValue: string[] | null,
  ): string | null {
    if (currentFilterValue === null) return null;

    const haveSameSize = defaultFilterValue
      && currentFilterValue.length === defaultFilterValue.length;
    const isFilterChanged = !haveSameSize
      || defaultFilterValue!.some((element) => !currentFilterValue.includes(element))
      || currentFilterValue.some((element) => !defaultFilterValue!.includes(element));

    if (isFilterChanged) return encodeURIComponent([...currentFilterValue].join(','));
    return null;
  },

  convertDateToUrlParam(date: Date) {
    return format(convertUTCToUserTimezone(date), DATE_FORMAT);
  },

  convertUrlParamToDate(dateString: string) {
    return parse(dateString, DATE_FORMAT, new Date());
  },

  /**
   * Given "pathname" and a set of URLSearchParams, this function returns the
   * URL params that aren't included in "currentUrlParams".<br />
   * This is used, for example, to store the global search query
   */
  getNonMasterFiltersParams(
    pathname: string,
    currentUrlParams: URLSearchParams,
  ): URLSearchParams {
    const pathnameToUrlParams = {
      [getScreenPath('backoffice.gpt-sessions')]: GPT_SESSION_URL_PARAMS,
      [getScreenPath('platform.interactions')]: [SEARCH_URL_PARAM_KEY],
      [getScreenPath('platform.analytics')]: ANALYTICS_WIDGETS_SEARCH_PARAMS,
      [getScreenPath('platform.reports')]: [REPORT_PARAM_KEY],
    };

    const nonMasterFiltersUrlParams = new URLSearchParams();
    const paramKeys = pathnameToUrlParams[pathname];
    paramKeys?.forEach((paramKey) => {
      if (currentUrlParams.has(paramKey)) {
        nonMasterFiltersUrlParams.append(paramKey, currentUrlParams.get(paramKey)!);
      }
    });
    return nonMasterFiltersUrlParams;
  },

  /**
   * URLSearchParams.toString() encodes spaced as "+", which is not decoded correctly
   * when used as part of a URL. Thus, this function parses the string correctly.
   * @param {URLSearchParams} urlParams
   * @returns {string} location.search
   */
  getAsUrlEncodedLocationSearch(urlParams: URLSearchParams): string {
    return [...urlParams.entries()]
      .reduce<string[]>((acc, [key, value]) => [...acc, `${key}=${encodeURIComponent(value)}`], [])
      .join('&');
  },

  parseTeamsIdsFilter(
    flatParams: Partial<FlatUrlParams>,
    defaultTeamsIds: PeopleMasterFilter['teams'],
  ): NullableValuesSelection<string> {
    const { selectAllTeams } = flatParams;
    let selectedItems = selectAllTeams
      ? null
      : defaultTeamsIds.selected;
    if (!selectAllTeams && flatParams.selectedTeams !== undefined) {
      selectedItems = flatParams.selectedTeams;
    }
    const includeUndefined = flatParams.includeUndefinedTeam === undefined
      ? defaultTeamsIds.includeUndefined
      : flatParams.includeUndefinedTeam;
    return new NullableValuesSelection(selectedItems, includeUndefined);
  },

  parseDirectionalityFilter(
    flatParams: Partial<FlatUrlParams>,
    defaultDirectionalityFilter: MasterFilters['directionality'],
  ): MasterFilters['directionality'] | undefined {
    const { directionality: directionalityInput } = flatParams;

    if (!Array.isArray(directionalityInput)) {
      return defaultDirectionalityFilter;
    }

    const directionalityValidValues = directionalityInput
      ?.filter((x) => Object.values(Directionality).includes(x));

    return directionalityValidValues.length === 0 // Some directionality must be selected
      ? defaultDirectionalityFilter
      : directionalityValidValues;
  },

  parseOrganizationsIdsFilter(
    flatParams: Partial<FlatUrlParams>,
    defaultOrganizations: OrganizationsMasterFilter,
  ): NullableValuesSelection<string> {
    const { selectAllOrganizationsIds } = flatParams;
    let selectedItems = selectAllOrganizationsIds
      ? null
      : defaultOrganizations.organizationsIds.selected;
    if (!selectAllOrganizationsIds && flatParams.selectedOrganizationsIds !== undefined) {
      selectedItems = flatParams.selectedOrganizationsIds;
    }
    const includeUndefined = flatParams.includeUndefinedOrganizations === undefined
      ? defaultOrganizations.organizationsIds.includeUndefined
      : flatParams.includeUndefinedOrganizations;
    return new NullableValuesSelection(selectedItems, includeUndefined);
  },

  parseOrganizationsRiskFilter(
    flatParams: Partial<FlatUrlParams>,
    defaultOrganizations: OrganizationsMasterFilter,
  ): NullableValuesSelection<OrganizationRisk> {
    const { selectAllOrganizationsRisks } = flatParams;
    let selectedItems = selectAllOrganizationsRisks
      ? null
      : defaultOrganizations.selectedRisks.selected;
    if (!selectAllOrganizationsRisks && flatParams.selectedOrganizationsRisks !== undefined) {
      selectedItems = flatParams.selectedOrganizationsRisks;
    }
    const includeUndefined = flatParams.includeUndefinedOrganizationsRisk === undefined
      ? defaultOrganizations.selectedRisks.includeUndefined
      : flatParams.includeUndefinedOrganizationsRisk;
    return new NullableValuesSelection(selectedItems, includeUndefined);
  },

  parseOrganizationsSizeFilter(
    flatParams: Partial<FlatUrlParams>,
    defaultOrganizations: OrganizationsMasterFilter,
  ): NullableValuesSelection<OrganizationSize> {
    const { selectAllOrganizationsSizes } = flatParams;
    let selectedItems = selectAllOrganizationsSizes
      ? null
      : defaultOrganizations.selectedSizes.selected;
    if (!selectAllOrganizationsSizes && flatParams.selectedOrganizationsSizes !== undefined) {
      selectedItems = flatParams.selectedOrganizationsSizes;
    }
    const includeUndefined = flatParams.includeUndefinedOrganizationsSize === undefined
      ? defaultOrganizations.selectedSizes.includeUndefined
      : flatParams.includeUndefinedOrganizationsSize;
    return new NullableValuesSelection(selectedItems, includeUndefined);
  },

  parseOrganizationsSegmentFilter(
    flatParams: Partial<FlatUrlParams>,
    defaultOrganizations: OrganizationsMasterFilter,
  ): NullableValuesSelection<string> {
    const { selectAllOrganizationsSegments } = flatParams;
    let selectedItems = selectAllOrganizationsSegments
      ? null
      : defaultOrganizations.selectedSegments.selected;
    if (!selectAllOrganizationsSegments && flatParams.selectedOrganizationsSegments !== undefined) {
      selectedItems = flatParams.selectedOrganizationsSegments;
    }
    const includeUndefined = flatParams.includeUndefinedOrganizationsSegment === undefined
      ? defaultOrganizations.selectedSegments.includeUndefined
      : flatParams.includeUndefinedOrganizationsSegment;
    return new NullableValuesSelection(selectedItems, includeUndefined);
  },
};
