import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';

import Button from 'common-ui-components/Button';
import SearchInput from 'common-ui-components/SearchInput';
import Tenant from 'model/Tenant';
import useTenantContext from 'screens/platform/cross-platform-components/context/tenant/TenantContext';

import style from 'screens/platform/cross-platform-components/Navbar/components/BottomActions/SwitchTenantModal/style.module.scss';

function useFilteredTenants(allTenants: Tenant[]) {
  const [searchTerm, setSearchTerm] = useState('');

  const filteredTenants = useMemo(
    () => allTenants
      .filter((tenant) => tenant.name.toLowerCase().includes(searchTerm.toLowerCase())
        || tenant.id.toString().startsWith(searchTerm)),
    [allTenants, searchTerm],
  );

  return {
    setSearchTerm,
    filteredTenants,
  };
}

export default function SwitchTenantModalContent({ allTenants }: { allTenants: Tenant[] }) {
  const { switchTenant } = useTenantContext();
  const { setSearchTerm, filteredTenants } = useFilteredTenants(allTenants);
  useEffect(() => inputRef.current?.focus(), []);

  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <div className={style.switchTenantModalContent}>
      <SearchInput
        setValue={setSearchTerm}
        placeholder="Search for tenants"
        className={style.searchInput}
        ref={inputRef}
      />
      <div className={style.tenantsList}>
        {
          filteredTenants.map((tenant) => (
            <Button
              padded
              color="gray"
              key={tenant.id}
              onClick={() => switchTenant(tenant.id)}
              className={style.tenantButton}
            >
              <span>
                {tenant.name}
              </span>
              <span className={style.tenantId}>
                {`(${tenant.id})`}
              </span>
            </Button>
          ))
        }
      </div>
    </div>
  );
}
