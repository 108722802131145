import React from 'react';

import MultiPersonsImages from 'common-ui-components/MultiImages/MultiPersonsImages';
import OrganizationProfileImage from 'common-ui-components/MultiImages/ProfileImage/OrganizationProfileImage';
import Size from 'global/lists/Size';
import { EnrichedOrganization } from 'model/Organization';
import { formatDateRelativelyToNow } from 'utils/DateUtils';

import style from 'common-ui-components/Sources/SourcePopoverContent/style.module.scss';

interface Props {
  organization: EnrichedOrganization;
}
export default function OrganizationPopoverContent({ organization }: Props) {
  const {
    name, topInternalPeople,
  } = organization;
  const lastEngagementDate = organization.lastEngagement
    && formatDateRelativelyToNow(organization.lastEngagement);

  return (
    <div className={style.sourcePopoverContent}>
      <OrganizationProfileImage organization={organization} size={Size.SMALL} />
      <div className={style.sourceDetails}>
        <span className={style.title}>{name}</span>
        {lastEngagementDate && (
          <span className={style.subheader}>{`Last engaged ${lastEngagementDate}`}</span>
        )}
        <MultiPersonsImages ids={topInternalPeople} />
      </div>
    </div>
  );
}
