import React from 'react';
import { Route, RouteProps } from 'react-router-dom';

import NoAccessRouteModal from 'common-ui-components/NoAccessRouteModal/index';
import Role from 'global/lists/Role';
import { DEFAULT_PLATFORM_LANDING_PAGE } from 'routers/PlatformRouter/platformRoutesConfig';
import { useUserContext } from 'screens/platform/cross-platform-components/context/user/UserContext';

interface Props extends RouteProps {
  minimumRole: Role;
  redirectUrl?: string;
}

export default function ProtectedRoute({
  minimumRole, redirectUrl = DEFAULT_PLATFORM_LANDING_PAGE, children, ...rest
}: Props) {
  const { isUserAllowed } = useUserContext();
  const isAllowed = isUserAllowed(minimumRole);

  return isAllowed
    ? (<Route {...rest}>{children}</Route>)
    : (<NoAccessRouteModal redirectUrl={redirectUrl} />);
}
