import App from 'global/lists/apps';

export type Permalink = string;

export interface Atom {
  title: string;
  participants: Record<string, number>;
  conversationStarter: string;
  start: string;
  end: string;
  channelId: string;
  tags: Record<string, Permalink>;
  _tid: number;
  appId: App;
  id: string;
  channelName: string;
  permalink: Permalink;
  isPublic: boolean;
  significance: number;
  relativeSignificance: number;
  volume: number;
  relativeVolume: number;
  mimeType?: string;

  engagedExternalOrganizations: string[]; // externalOrganizationsIds + viewerOrganizationsIds
  participatingExternalOrganizations: string[]; // externalOrganizationsIds
  mentionedOrganizations: string[]; // sorted DESC by mentions count

  initiatorPersonId?: string;
  orientation?: Orientation;

  references: AtomLinks;
  backlinks: AtomLinks;
  unmappedLinks: string[];
}

export interface AtomLinks {
  links: Atom[];
  countIncludingNotAllowed: number;
}

export enum Orientation {
  INBOUND = 'INBOUND',
  OUTBOUND = 'OUTBOUND',
  INTERNAL = 'INTERNAL',
}
