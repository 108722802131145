import DebuggerConsole from 'utils/DebuggerConsole';
import HierarchicalGroupInterface, {
  SelectionStatus,
} from 'utils/HierarchicalDataStructures/HierarchicalGroupInterface';
import HierarchicalInterface from 'utils/HierarchicalDataStructures/HierarchicalInterface';
import HierarchicalItem from 'utils/HierarchicalDataStructures/HierarchicalItem';

interface SelectionMapConfig {
  alwaysReturnMap: boolean;
  includeEmptyGroupsKeys: boolean;
}

export default class HierarchicalGroup extends HierarchicalGroupInterface {
  select(shouldPropagateUpwards = true) {
    this.isPartiallySelected = false;
    this.status.selected = this.status.total;

    Object.values(this.children).forEach((child) => {
      child.select(false);
    });
    super.select(shouldPropagateUpwards);
  }

  deselect(shouldPropagateUpwards = true) {
    this.isPartiallySelected = false;
    this.status.selected = 0;

    Object.values(this.children).forEach((child) => {
      child.deselect(false);
    });
    super.deselect(shouldPropagateUpwards);
  }

  protected selectChild(
    nestedChildPath: string[],
  ): SelectionStatus | null {
    if (nestedChildPath.length === 0) return SelectionStatus.SELECTED;

    const [directChildId, ...nextChildPath] = nestedChildPath;

    const child = this.children[directChildId];
    if (!child) {
      const errorMsg = 'Tried to select a non-existent child';
      DebuggerConsole.error(errorMsg, { parentId: this.id, directChildId });
      return null;
    }

    const childSelectionStatus = (() => {
      if (nestedChildPath.length === 1) return SelectionStatus.SELECTED;
      if (nestedChildPath.length > 1 && child.isParent()) return child.selectChild(nextChildPath);
      return null;
    })();
    if (childSelectionStatus === null) return null;

    const somehowSelected = [
      SelectionStatus.SELECTED,
      SelectionStatus.PARTIALLY_SELECTED,
    ].includes(childSelectionStatus);
    if (somehowSelected) {
      if (childSelectionStatus === SelectionStatus.SELECTED) {
        this.status.selected = Math.min(this.status.total, this.status.selected + 1);
      }
      this.updateSelectionFlags();
    }

    if (childSelectionStatus !== SelectionStatus.UNSELECTED) {
      if (this.isSelected) return SelectionStatus.SELECTED;
      if (this.isPartiallySelected) return SelectionStatus.PARTIALLY_SELECTED;
    }
    return SelectionStatus.UNSELECTED;
  }

  protected deselectChild(
    nestedChildPath: string[],
  ): SelectionStatus | null {
    if (nestedChildPath.length === 0) return SelectionStatus.UNSELECTED;

    const [directChildId, ...nextChildPath] = nestedChildPath;

    const child = this.children[directChildId];
    if (!child) {
      const errorMsg = 'Tried to deselect a non-existent child';
      DebuggerConsole.error(errorMsg, { parentId: this.id, directChildId });
      return null;
    }

    const childSelectionStatus = (() => {
      if (nestedChildPath.length === 1) return SelectionStatus.UNSELECTED;
      if (nestedChildPath.length > 1 && child.isParent()) return child.deselectChild(nextChildPath);
      return null;
    })();
    if (childSelectionStatus === null) return null;

    if (childSelectionStatus !== SelectionStatus.SELECTED) {
      this.status.selected = Math.max(0, this.status.selected - 1);
      this.updateSelectionFlags();
    }

    if (this.isSelected) return SelectionStatus.SELECTED;
    if (this.isPartiallySelected) return SelectionStatus.PARTIALLY_SELECTED;
    return SelectionStatus.UNSELECTED;
  }

  propagateSelectionUpwards(nestedChildPath: string[]) {
    const callerId = [this.id, ...nestedChildPath];

    if (this.parent?.isParent()) {
      this.parent.propagateSelectionUpwards(callerId);
    } else {
      this.selectChild(nestedChildPath);
    }
    super.triggerCallbacks(callerId);
  }

  propagateDeselectionUpwards(nestedChildPath: string[]) {
    const callerId = [this.id, ...nestedChildPath];

    if (this.parent?.isParent()) {
      this.parent.propagateDeselectionUpwards(callerId);
    } else {
      this.deselectChild(nestedChildPath);
    }
    super.triggerCallbacks(callerId);
  }

  getChild(childId: string[] | string): HierarchicalGroup | HierarchicalItem | undefined {
    const childrenPathArray = typeof childId === 'string' ? [childId] : childId;
    const [currId, ...nestedIds] = childrenPathArray;

    const child = this.children[currId];
    if (child) {
      if (nestedIds.length === 0) return child;
      if (child.isParent()) {
        return child.getChild(nestedIds);
      }
    }
    return undefined;
  }

  getSelectedItems(handleDirectChildrenOnly = false): string[] {
    return Object.entries(this.children)
      .reduce<string[]>((acc, entry) => {
        const child = entry[1] as HierarchicalInterface;
        if (!handleDirectChildrenOnly && child.isParent()) {
          return [...acc, ...child.getSelectedItems()];
        }
        return child.isSelected ? [...acc, child.id] : acc;
      }, []);
  }

  getSelectedGroupsAndItems(): { selectedGroups: string[]; selectedItems: string[] } {
    return Object.entries(this.children)
      .reduce((acc, [categoryId, category]) => {
        if (category.isSelected) {
          return {
            ...acc,
            selectedGroups: [...acc.selectedGroups, categoryId],
          };
        }

        const categoryAsGroup = category as HierarchicalGroup;
        const selectedChildrenTags = Object.entries(categoryAsGroup.children)
          .reduce((tagsAcc, [tagId, tag]) => (
            tag.isSelected ? [...tagsAcc, tagId] : tagsAcc), [] as string[]);

        return {
          ...acc,
          selectedItems: [...acc.selectedItems, ...selectedChildrenTags],
        };
      }, {
        selectedGroups: [],
        selectedItems: [],
      } as { selectedGroups: string[]; selectedItems: string[] });
  }

  /**
   * Returns a map with groups as keys and their selected items as values.
   * If all groups are selected, return `null`
   */
  getSelectionMap(config?: Partial<SelectionMapConfig>):
    Record<string, string[]> | null {
    if (!config?.alwaysReturnMap && this.isSelected) return null;

    const allGroups = this.children as Record<string, HierarchicalGroup>;
    return Object.entries(allGroups)
      .reduce<Record<string, string[]>>((acc, [groupId, group]) => {
        const selectedItems = Object.entries(group.children)
          .reduce((selectedItemsAcc, [itemId, item]) => {
            if (item.isSelected) return [...selectedItemsAcc, itemId];
            return selectedItemsAcc;
          }, [] as string[]);

        if (group.isSelected || selectedItems.length > 0 || config?.includeEmptyGroupsKeys) {
          return {
            ...acc,
            [groupId]: selectedItems,
          };
        }
        return acc;
      }, {});
  }

  equals(other: HierarchicalInterface | undefined): boolean {
    if (!other
      || this.isParent() !== other.isParent()
      || this.id !== other.id
      || this.isSelected !== other.isSelected) {
      return false;
    }
    const otherGroup = other as HierarchicalGroup;

    return Object.entries(this.children).every(([childId, child]) => {
      const otherChild = otherGroup.getChild(childId);
      return otherChild && child.equals(otherChild);
    });
  }

  // The weird types allow inherited classes to use this method
  clone(): this {
    return new (this.constructor as any)(
      this.id,
      Object.keys(this.children).length === 0 ? {}
        : Object.values(this.children).map((child) => child.clone()),
      this.isSelected,
      this.status.selected,
    );
  }

  /**
   * Returns true if the group is neither selected nor partially selected.
   */
  isNotSelected() {
    return !this.isSelected && !this.isPartiallySelected;
  }

  private updateSelectionFlags() {
    this.isSelected = this.status.selected === this.status.total;
    this.isPartiallySelected = !this.isSelected && this.calcIsPartiallySelected();
  }

  getChildren(): (HierarchicalGroup | HierarchicalItem)[] {
    return Object.values(this.children);
  }

  getChildrenEntries(): [string, HierarchicalGroup | HierarchicalItem][] {
    return Object.entries(this.children);
  }
}
