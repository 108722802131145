import classNames from 'classnames';
import React from 'react';

import Size from 'global/lists/Size';

import style from 'common-ui-components/ColorSquare/style.module.scss';

interface Props {
  color: string | undefined;
  size?: Size.MEDIUM | Size.SMALL;
  shouldAddBorder?: boolean;
}

export default function ColorSquare({ color, size = Size.MEDIUM, shouldAddBorder }: Props) {
  return (
    <div
      className={classNames(
        style.colorSquare,
        !color && style.noColor,
        shouldAddBorder && style.bordered,
        {
          [style.medium]: size === Size.MEDIUM,
          [style.small]: size === Size.SMALL,
        },
      )}
      style={{ backgroundColor: color }}
    />
  );
}
