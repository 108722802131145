import React from 'react';

import AnalyticsScreenSection from 'screens/platform/contentScreens/AnalyticsScreen/components/AnalyticsScreenSection';
import WidgetHeader from 'screens/platform/contentScreens/AnalyticsScreen/components/WidgetHeader';
import useWidgetConfig from 'screens/platform/contentScreens/AnalyticsScreen/utils/WidgetConfigHook';
import useVolumeTimelineChartDataFetcher
  from 'screens/platform/contentScreens/AnalyticsScreen/widgets/VolumeTimelineWidget/dataFetching/VolumeTimelineChartDataFetcherHook';
import volumeRecipes from 'screens/platform/contentScreens/AnalyticsScreen/widgets/VolumeTimelineWidget/volumeRecipes';
import {
  DateRangeGranularity,
  GroupType,
  WidgetType,
} from 'screens/platform/contentScreens/AnalyticsScreen/widgets/widgetConfig';
import ChartStatesHandler from 'screens/platform/cross-platform-components/ChartStatesHandler';
import Directionality from 'screens/platform/cross-platform-components/context/MasterFiltersContext/Directionality';
import {
  useMasterFilters,
} from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFiltersContext';
import VolumeTimeLineChart from 'screens/platform/cross-platform-components/VolumeTimeLineChart';

import chartStyle from 'screens/platform/contentScreens/AnalyticsScreen/widgets/chartStyle.module.scss';

const defaultGranularity = DateRangeGranularity.DAY;

export default function VolumeTimelineWidget() {
  const { currentFilters } = useMasterFilters();
  const { widgetConfig } = useWidgetConfig(WidgetType.VOLUME);
  const granularity = widgetConfig.granularity || defaultGranularity;

  const { chartData, isLoading, isNetworkError } = useVolumeTimelineChartDataFetcher(
    currentFilters,
    granularity,
    widgetConfig,
  );

  const relevantVolumeRecipes = volumeRecipes.filter((recipe) => {
    if (
      recipe.group !== GroupType.ORGANIZATIONS
      && recipe.group !== GroupType.ORIENTATION
    ) return true;
    return currentFilters.directionality.includes(Directionality.EXTERNAL);
  });

  const isEmptyData = chartData.length === 0 || chartData[0].data.length === 0;

  return (
    <AnalyticsScreenSection>
      <WidgetHeader
        type={WidgetType.VOLUME}
        recipes={relevantVolumeRecipes}
      />
      <div className={chartStyle.chartWrapper}>
        <ChartStatesHandler
          loading={isLoading}
          emptyData={isEmptyData}
          isNetworkError={isNetworkError}
        >
          <VolumeTimeLineChart
            chartData={chartData}
            group={widgetConfig.group}
            granularity={granularity}
          />
        </ChartStatesHandler>
      </div>
    </AnalyticsScreenSection>
  );
}
