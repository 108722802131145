import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';

import { useCurrentAppScreen } from 'global/lists/AppScreen';
import { useCustomEventsFilters } from 'screens/platform/cross-platform-components/context/CustomFilters/CustomEventsFilters/CustomEventsFiltersContextProvider';
import { usePlatformContext } from 'screens/platform/cross-platform-components/context/platform/PlatformContext';
import EventsPanel from 'screens/platform/cross-platform-components/EventsPanel';
import { useOutsideAlerter } from 'utils/hooks';
import ObjectUtils from 'utils/ObjectUtils';

import styles from 'screens/platform/cross-platform-components/Panels/RightPanel/CollapsibleEventsDrawer/style.module.scss';

export default function CollapsibleEventsDrawer() {
  const { customEventsFilters, dispatchCustomEventsFilters } = useCustomEventsFilters();
  const { isAnalyticsScreen } = useCurrentAppScreen();
  const { eventsDrawer: { isEventsDrawerOpen, setEventsDrawerOpen } } = usePlatformContext();
  const drawerRef = useRef(null);

  useEffect(() => {
    if (!ObjectUtils.isEmpty(customEventsFilters)) {
      setEventsDrawerOpen(true);
    }
  }, [customEventsFilters]);

  useEffect(() => {
    if (!isAnalyticsScreen && isEventsDrawerOpen) {
      setEventsDrawerOpen(false);
    }
  }, [isAnalyticsScreen]);

  useEffect(() => {
    if (!isEventsDrawerOpen) {
      dispatchCustomEventsFilters({
        type: 'RESET_ALL',
      });
    }
  }, [isEventsDrawerOpen]);

  useOutsideAlerter(drawerRef, () => setEventsDrawerOpen(false));

  return (
    <div
      ref={drawerRef}
      className={classNames(
        styles.drawContainer,
        isEventsDrawerOpen ? styles.open : styles.closed,
      )}
    >
      {isEventsDrawerOpen && <EventsPanel />}
    </div>
  );
}
