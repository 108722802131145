import React, { useMemo } from 'react';

import TimelineEvent from 'model/Events/Event';
import useTenantContext from 'screens/platform/cross-platform-components/context/tenant/TenantContext';
import findMatchingActionText from 'screens/platform/cross-platform-components/EventsPanel/EventCell/EventActionText/ActionTextMatcher';
import EventActionLink from 'screens/platform/cross-platform-components/EventsPanel/EventCell/EventActionText/EventActionLink';
import { parseActionText } from 'screens/platform/cross-platform-components/EventsPanel/EventCell/EventActionText/EventActionTextUtils';
import ArrayUtils from 'utils/ArrayUtils';

import style from 'screens/platform/cross-platform-components/EventsPanel/EventCell/EventActionText/style.module.scss';

export default function EventActionText({ event }: { event: TimelineEvent }) {
  const { tenant: { departments } } = useTenantContext();

  const action = useMemo(
    () =>
      findMatchingActionText(event, Object.values(departments)
        .filter(ArrayUtils.isDefined)),
    [event, departments],
  );

  if (!action) {
    return null;
  }

  const { prefixText, suffixText, linkText } = parseActionText(action.text, event);

  return (
    <span className={style.container}>
      {prefixText}
      <EventActionLink event={event} linkText={linkText} link={action.link} />
      {suffixText}
    </span>
  );
}
