import React from 'react';

import { useGlobalModal } from 'screens/platform/cross-platform-components/context/globalModal/GlobalModalContext';
import useTenantContext from 'screens/platform/cross-platform-components/context/tenant/TenantContext';
import SwitchTenantModalContent from 'screens/platform/cross-platform-components/Navbar/components/BottomActions/SwitchTenantModal/SwitchTenantModalContent';

export default function useSwitchTenantModal() {
  const { openGlobalModal } = useGlobalModal();
  const { allTenants } = useTenantContext();

  return () => {
    openGlobalModal({
      title: 'Switch Tenant',
      content: allTenants && <SwitchTenantModalContent allTenants={allTenants} />,
    });
  };
}
