import classNames from 'classnames';
import React, { ReactNode } from 'react';

import style from 'common-ui-components/SupportEmailLink/style.module.scss';

interface Props {
  subject?: string;
  onMouseDown?: (e: React.MouseEvent) => void;
  className?: string;
  children: ReactNode;
}

export default function SupportEmailLink({
  subject, onMouseDown, className, children,
}: Props) {
  const suffix = subject ? `?subject=${subject}` : '';
  const href = `mailto:support@akooda.co${suffix}`;
  return (
    <a href={href} onMouseDown={onMouseDown} className={classNames(style.emailLink, className)}>
      {children}
    </a>
  );
}
