import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';

import { AtomsCount } from 'global/api/controller/AtomController';
import CustomAtomsFiltersReducer, {
  CustomAtomsFiltersReducerAction,
  CustomAtomsFiltersState,
} from 'screens/platform/cross-platform-components/context/CustomFilters/CustomAtomsFilters/CustomAtomsFiltersReducer';
import {
  EMPTY_CUSTOM_FILTERS,
} from 'screens/platform/cross-platform-components/context/CustomFilters/CustomFiltersUtils';
import {
  useMasterFilters,
} from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFiltersContext';
import ObjectUtils from 'utils/ObjectUtils';

export interface CustomAtomsFiltersContextType {
  dispatchCustomAtomsFilters: Dispatch<CustomAtomsFiltersReducerAction>;
  customAtomsFilters: CustomAtomsFiltersState;
  filteredAtomsCount: AtomsCount | null;
  setFilteredAtomsCount: Dispatch<SetStateAction<AtomsCount | null>>;
}

export interface CustomAtomsFiltersRedirectState {
  customAtomsFilters?: CustomAtomsFiltersState;
}

export function useCustomAtomsFilters(): CustomAtomsFiltersContextType {
  return useContext<CustomAtomsFiltersContextType>(CustomAtomsFiltersContext);
}

export const CustomAtomsFiltersContext = createContext<CustomAtomsFiltersContextType>({} as any);

export default function CustomAtomsFiltersContextProvider({ children }: { children: ReactNode }) {
  const { totalAtomsCount } = useMasterFilters();
  const [filteredAtomsCount, setFilteredAtomsCount] = useState<AtomsCount | null>(null);
  const { state } = useLocation<CustomAtomsFiltersRedirectState>();

  const [customAtomsFilters, dispatchCustomAtomsFilters] = useReducer(
    CustomAtomsFiltersReducer,
    EMPTY_CUSTOM_FILTERS,
  );

  useEffect(() => {
    if (ObjectUtils.isEmpty(customAtomsFilters)) {
      setFilteredAtomsCount(totalAtomsCount);
    }
  }, [totalAtomsCount, customAtomsFilters]);

  useEffect(() => {
    if (!ObjectUtils.isEmpty(customAtomsFilters)) {
      setFilteredAtomsCount(null);
    }
  }, [customAtomsFilters]);

  useEffect(() => {
    if (state && state.customAtomsFilters) {
      dispatchCustomAtomsFilters({
        type: 'SET_FILTERS',
        payload: state.customAtomsFilters,
      });
    }
  }, [state?.customAtomsFilters]);

  return (
    <CustomAtomsFiltersContext.Provider value={{
      customAtomsFilters,
      dispatchCustomAtomsFilters,
      filteredAtomsCount,
      setFilteredAtomsCount,
    }}
    >
      { children }
    </CustomAtomsFiltersContext.Provider>
  );
}
