import { useCallback } from 'react';

import {
  useMasterFilters,
} from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFiltersContext';
import PeopleMasterFilter
  from 'screens/platform/cross-platform-components/context/MasterFiltersContext/PeopleMasterFilter';
import PeopleMasterFilterHierarchyGroup
  from 'screens/platform/cross-platform-components/context/MasterFiltersContext/PeopleMasterFilter/PeopleMasterFilterHierarchyGroup';
import { useMetadataContext } from 'screens/platform/cross-platform-components/context/metadata/MetadataContext';
import FilterSectionUtils
  from 'screens/platform/cross-platform-components/MasterFiltersPanel/FilterSection/FilterSectionUtils';
import HierarchicalGroup from 'utils/HierarchicalDataStructures/HierarchicalGroup';

const { convertToNullableValuesSelection } = FilterSectionUtils;

/**
 * Include departments that are either fully or partially selected
 */
function getDepartmentsToSelect(departmentsSelection: HierarchicalGroup) {
  return departmentsSelection.isSelected
    ? null
    : departmentsSelection.getChildrenEntries()
      .filter(([, group]) => group.isSelected
        || (group.isParent() && group.isPartiallySelected))
      .map(([department]) => department);
}

export default function usePeopleHierarchyGroupToFilterConverter():
  (peopleFilterAsGroup: PeopleMasterFilterHierarchyGroup) => PeopleMasterFilter {
  const { currentFilters: { people: currentPeopleMasterFilter } } = useMasterFilters();
  const { persons: { allPeopleMetadata } } = useMetadataContext();

  return useCallback((peopleFilterAsGroup) => {
    const selectionMap = peopleFilterAsGroup.getSelectionMap({ includeEmptyGroupsKeys: true });

    if (selectionMap === null) {
      return PeopleMasterFilter.generateAllInternalSelected(
        currentPeopleMasterFilter.includeUnidentified,
      );
    }

    const departmentsSelection = peopleFilterAsGroup.getDepartmentsGroup();
    const departmentsToSelect = getDepartmentsToSelect(departmentsSelection);

    const peopleGroup = peopleFilterAsGroup.getPeopleGroup();
    const teamsGroup = peopleFilterAsGroup.getTeamsGroup();

    return new PeopleMasterFilter(
      departmentsToSelect,
      convertToNullableValuesSelection(teamsGroup),
      peopleFilterAsGroup.getPeopleGroup().isSelected
        ? null
        : selectionMap[peopleGroup.id] ?? [],
      currentPeopleMasterFilter.includeUnidentified,
    );
  }, [currentPeopleMasterFilter, allPeopleMetadata]);
}
