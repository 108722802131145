import { colord, extend } from 'colord';

import mixPlugin from 'colord/plugins/mix';
import namesPlugin from 'colord/plugins/names';
import DebuggerConsole from 'utils/DebuggerConsole';
import StringUtils from 'utils/StringUtils';

extend([namesPlugin, mixPlugin]);

export default {
  colorWithOpacity(color: string, opacity: number): string {
    if (opacity > 1 || opacity < 0) {
      DebuggerConsole.error(`Opacity must be in the range [0, 1]. Current: ${opacity}`);
    }

    let opacityString = (Math.round(opacity * 255)).toString(16);
    if (opacityString.length === 1) {
      opacityString = `0${opacityString}`;
    }

    return `${color}${opacityString}`;
  },

  /**
   * @param color {string}  The color to get the shade of
   * @param step {number}   A number between 0 and 1
   */
  getColorShade(color: string, step: number): string {
    const colordInstance = colord(color);
    const brightness = colordInstance.brightness();
    const changeFactor = Math.abs(brightness - 0.5) + 0.4;

    const targetColor = brightness > 0.5
      ? colordInstance.darken(changeFactor / 3).lighten(step * 0.83)
      : colordInstance.lighten(changeFactor / 1.9).darken(step);

    if (targetColor.brightness() > 0.85 || targetColor.brightness() < 0.15) {
      return this.getColorShade(color, step * 0.9);
    }

    return targetColor.toHex();
  },

  darken(color: string, darkenFactor: number, minBrightnessThreshold = 0.2): string {
    const colordInstance = colord(color);
    let darkerColor = colordInstance.darken(darkenFactor);
    let factor = 0;
    while (darkerColor.brightness() < minBrightnessThreshold) {
      factor += 0.05;
      darkerColor = colordInstance.darken(darkenFactor - factor);
    }
    return darkerColor.toHex();
  },

  lighten(color: string, lightenFactor: number, maxBrightnessThreshold = 0.95): string {
    const colordInstance = colord(color);
    let lighterColor = colordInstance.lighten(lightenFactor);
    let factor = 0;
    while (lighterColor.brightness() > maxBrightnessThreshold) {
      factor += 0.05;
      lighterColor = colordInstance.lighten(lightenFactor - factor);
    }
    return lighterColor.toHex();
  },

  convertStringToColor(str: string): string {
    const MIN_BASE_COLOR_VALUE = 50; // To ensure no blacks
    const MAX_BASE_COLOR_VALUE = 255 - MIN_BASE_COLOR_VALUE; // To ensure no whites
    const ORGANIZATION_BASE_MIX = 'rgb(67, 148, 195)';

    function getBaseColorValue(rotations: number): number {
      function rotateString(stringToRotate: string, iterations: number): string {
        if (stringToRotate.length === 0 || iterations === 0) return stringToRotate;
        const initialLetter = stringToRotate.charAt(0);
        const rotated = stringToRotate.substring(1) + initialLetter;
        return rotateString(rotated, iterations - 1);
      }

      const color = StringUtils.hashStringToNumber(rotateString(str, rotations));
      return MIN_BASE_COLOR_VALUE + (color * (MAX_BASE_COLOR_VALUE - MIN_BASE_COLOR_VALUE));
    }

    const r = getBaseColorValue(0);
    const g = getBaseColorValue(1);
    const b = getBaseColorValue(2);

    return colord(`rgb(${r}, ${g}, ${b})`)
      .mix(ORGANIZATION_BASE_MIX, 0.5)
      .saturate(0.8)
      .lighten(0.1)
      .toHex();
  },
};
