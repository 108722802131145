import { subMonths } from 'date-fns';

import { SortingConfig } from 'common-ui-components/Table/sortingHook';
import { convertCustomSortingConfig, RequestPaging } from 'global/api/controller/utils/commonControllerUtils';
import FilterCondition from 'global/api/controller/utils/filtering/FilterCondition';
import PersonFilterableProperty from 'global/api/controller/utils/filtering/PersonFilterableProperty';
import {
  PersonCustomSortingConfig, PersonRequestSorting,
} from 'global/api/controller/utils/PersonControllerUtils';
import {
  getRequest, HttpResponse, postRequest, putRequest,
} from 'global/api/platformApiHelpers';
import PersonaType from 'global/lists/PersonaType';
import Role from 'global/lists/Role';
import Person, { ExternalDirectoryPerson, InternalDirectoryPerson } from 'model/Person';
import Tenant from 'model/Tenant';
import {
  MasterFilters,
  TimePeriod,
} from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFilters';
import convertMasterFilters from 'screens/platform/cross-platform-components/context/MasterFiltersContext/utils/MasterFiltersApiConversionUtils';
import PersonMetadata from 'screens/platform/cross-platform-components/context/metadata/dataStructures/PersonMetadata';
import PersonField from 'screens/platform/directory/utils/PersonField';

export type PersonSettingsData = {
  email: string;
  role: string;
  isVisibleInUi: boolean;
};

export type PersonWithSettingsData = Person & PersonSettingsData;

export type PersonContactData = Partial<{
  email: string;
  slack: {
    username: string;
    subdomain: string;
  };
}>;

export type PersonsAreasOfFocusMap = Partial<Record<string, string[]>>;

interface PagedSortedPeopleRequest {
  pagingConfig: RequestPaging;
  sortingConfig: PersonRequestSorting;
  filterConditions: FilterCondition<PersonFilterableProperty>[];
  requestedFields?: PersonField[];
}
type RawRequestBody = Omit<PagedSortedPeopleRequest, 'sortingConfig'> & { sortingConfig: SortingConfig<PersonCustomSortingConfig> };

interface PagedPeopleResponse<PersonType> {
  people: PersonType[];
  totalPeopleCount: number;
}

interface UpdatePersonPayload {
  nextRole?: Role;
  nextDepartment?: string;
  nextPersonaType?: PersonaType;
  nextIsVisible?: boolean;
}

export interface GroupedPersons { allPeople: string[]; departments: string[]; teams: string[] }

export interface AllPeopleMetadata<CustomPersonMetadata = PersonMetadata> {
  allPeopleMetadata: Partial<Record<string, CustomPersonMetadata>>;
  departmentToPeople: Partial<Record<string, string[]>>;
  teamToPeople: Partial<Record<string, string[]>>;
}

export type AllPeopleMetadataApiResponse = AllPeopleMetadata<{
  department: string;
  teams: string[] | null;
}>;

const PersonController = {
  async getAllPeopleMetadata(tenantId: Tenant['id']): HttpResponse<AllPeopleMetadataApiResponse> {
    return getRequest('person', { tenantId });
  },

  async getPeopleByIds(
    tenantId: Tenant['id'],
    ids: string[],
  ): HttpResponse<Record<string, Person>> {
    return postRequest('person', ids, { params: { tenantId } });
  },

  async getInternalPeople(
    tenantId: Tenant['id'],
    query: string | null,
    rawRequestBody: RawRequestBody,
  ): HttpResponse<PagedPeopleResponse<InternalDirectoryPerson>> {
    const requestBody: PagedSortedPeopleRequest = {
      ...rawRequestBody,
      sortingConfig: convertCustomSortingConfig(rawRequestBody.sortingConfig),
    };
    return postRequest('person/internal', requestBody, {
      params: { tenantId, query },
    });
  },

  async getExternalPeople(
    tenantId: Tenant['id'],
    query: string | null,
    rawRequestBody: RawRequestBody,
  ): HttpResponse<PagedPeopleResponse<ExternalDirectoryPerson>> {
    const requestBody: PagedSortedPeopleRequest = {
      ...rawRequestBody,
      sortingConfig: convertCustomSortingConfig(rawRequestBody.sortingConfig),
    };
    return postRequest('person/external', requestBody, {
      params: { tenantId, query },
    });
  },

  async getExternalPerson(
    tenantId: Tenant['id'],
    personId: string,
  ): HttpResponse<ExternalDirectoryPerson> {
    return getRequest(`person/${personId}`, { tenantId });
  },

  async getPersonTeam(
    tenantId: Tenant['id'],
    personId: string,
  ): HttpResponse<string[]> {
    return getRequest('person/team', { tenantId, personId });
  },

  async getPersonTeamMembersIds(
    tenantId: Tenant['id'],
    personId: string,
  ): HttpResponse<string[]> {
    return getRequest(`person/${personId}/team/members`, { tenantId });
  },

  async getPersonsSettingsData(
    tenantId: Tenant['id'],
  ): HttpResponse<Record<string, PersonWithSettingsData>> {
    return getRequest('person/settings', { tenantId });
  },

  async getPersonContactData(
    personId: Person['id'],
    tenantId: Tenant['id'],
  ): HttpResponse<PersonContactData> {
    return getRequest('person/contact', { tenantId, personId });
  },

  async getPersonsAreasOfFocus(
    tenantId: Tenant['id'],
    filters?: MasterFilters,
  ): HttpResponse<PersonsAreasOfFocusMap> {
    return postRequest(
      'person/areasOfFocus',
      filters ? convertMasterFilters(filters, true) : null,
      { params: { tenantId } },
    );
  },

  async getPeopleOrganizations(
    tenantId: Tenant['id'],
    peopleIds: string[],
  ): HttpResponse<Record<string, string[]>> {
    return postRequest('person/organizations', peopleIds, { params: { tenantId } });
  },

  async getTopPeopleForPeople(
    tenantId: Tenant['id'],
    peopleIds: string[],
  ): HttpResponse<Record<string, string[]>> {
    return postRequest('person/top-people', peopleIds, { params: { tenantId } });
  },

  async getPeopleByQuery(
    tenantId: Tenant['id'],
    config: { query?: string; showDeletedLongTimeAgo?: boolean },
  ): HttpResponse<GroupedPersons> {
    const threeMonthsAgo = subMonths(new Date(), 3);
    const body = {
      deletedAfter: config?.showDeletedLongTimeAgo ? threeMonthsAgo.getTime() : undefined,
    };

    return postRequest(
      'person/query',
      body,
      {
        params: {
          tenantId,
          query: config?.query,
        },
      },
    );
  },

  async setPerson(
    tenantId: Tenant['id'],
    personId: string,
    payload: UpdatePersonPayload,
  ): HttpResponse<string> {
    return putRequest('person', {
      personId, ...payload,
    }, { params: { tenantId } });
  },

  async downloadOrgChart(
    tenantId: Tenant['id'],
  ): HttpResponse<Blob> {
    return getRequest('person/export', { tenantId }, { responseType: 'blob' });
  },

  async exportPeopleDirectoryTable(
    tenantId: Tenant['id'],
    requestBody: {
      timePeriod: TimePeriod<'days'>;
      sortingConfig: PersonRequestSorting;
      filterConditions: FilterCondition<PersonFilterableProperty>[];
    },
  ): HttpResponse<void> {
    return postRequest('export/directory/person', { ...requestBody, requestedFields: null }, {
      params: { tenantId },
    });
  },
};

export default PersonController;
