import React from 'react';

import GlobalRouter from 'routers/GlobalRouter';

import 'global/globalStyle.scss';

const App = () => (
  <GlobalRouter />
);

export default App;
