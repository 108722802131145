import classNames from 'classnames';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import Button from 'common-ui-components/Button';

import style from 'screens/public/LoginPage/RoundIconButton/style.module.scss';

type Props = {
  label: string;
  Icon: FC;
  onClick?: () => void;
  to?: string;
  className?: string;
};

const RoundIconButton: FC<Props> = ({
  onClick, to, label, Icon, className,
}) => {
  const buttonContent = (
    <>
      <div className={style.iconWrapper}>
        <Icon />
      </div>
      <span>{label}</span>
    </>
  );

  return (
    <Button onClick={onClick} className={classNames(style.button, className)}>
      {to ? (
        <Link className={style.link} to={to}>
          {buttonContent}
        </Link>
      ) : (
        buttonContent
      )}
    </Button>
  );
};

export default RoundIconButton;
