import React from 'react';

import addDays from 'date-fns/addDays';
import ShareOfAttentionBarChart
  from 'screens/platform/contentScreens/AnalyticsScreen/components/ShareOfAttentionBarChart';
import useTimeSeriesChartLabels, { useTimeSeriesChartLabelFormatter } from 'screens/platform/contentScreens/AnalyticsScreen/utils/TimeSeriesChartLabelsHook/TimeSeriesChartLabelsHook';
import { ParsedBriefChartData } from 'screens/platform/contentScreens/AnalyticsScreen/widgets/ShareOfAttentionWidget/visualizations/BriefChart/BriefBarChartDataParsingHook';
import BriefBarChartTooltip
  from 'screens/platform/contentScreens/AnalyticsScreen/widgets/ShareOfAttentionWidget/visualizations/BriefChart/BriefBarChartTooltip';
import { DateRangeGranularity } from 'screens/platform/contentScreens/AnalyticsScreen/widgets/widgetConfig';
import {
  useCustomAtomsFilters,
} from 'screens/platform/cross-platform-components/context/CustomFilters/CustomAtomsFilters/CustomAtomsFiltersContextProvider';
import { useMetadataContext } from 'screens/platform/cross-platform-components/context/metadata/MetadataContext';
import { convertUserTimezoneToUTC } from 'utils/DateUtils';

import style
  from 'screens/platform/contentScreens/AnalyticsScreen/widgets/ShareOfAttentionWidget/visualizations/BriefChart/style.module.scss';

interface Props {
  data: Pick<ParsedBriefChartData, 'barChartData' | 'keys' | 'tooltipsData'>;
  granularity: DateRangeGranularity;
  disableClicking?: boolean;
}

export default function BriefBarChart({ data, granularity, disableClicking = false }: Props) {
  const { barChartData, keys, tooltipsData } = data;
  const { dispatchCustomAtomsFilters } = useCustomAtomsFilters();
  const { getCategoryColor } = useMetadataContext();
  const { ref, labels } = useTimeSeriesChartLabels({ granularity });

  function onClick(cell) {
    const { data: { date } } = cell;
    const from = convertUserTimezoneToUTC(new Date(Number(date)));
    const to = granularity === DateRangeGranularity.WEEK ? addDays(from, 7) : from;

    dispatchCustomAtomsFilters({
      type: 'SET_FILTERS',
      payload: { datesRange: { from, to } },
    });
  }

  const labelFormatter = useTimeSeriesChartLabelFormatter(labels);

  return (
    <div ref={ref} className={style.barChartWrapper}>
      <ShareOfAttentionBarChart
        data={barChartData}
        indexBy="date"
        keys={keys}
        onBarClick={disableClicking ? undefined : onClick}
        axisBottom={{
          tickValues: 'every day',
          format: labelFormatter,
        }}
        tooltip={(props) => (
          <BriefBarChartTooltip
            {...props}
            tooltipsData={tooltipsData}
            granularity={granularity}
          />
        )}
        colors={({ id }) => getCategoryColor(id.toString())}
        maxValue={100}
        minValue={1}
        enableLabel={false}
      />
    </div>
  );
}
