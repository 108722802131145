import appsColors from 'global/lists/appsColors';
import Channel from 'model/Channel';
import ColorUtils from 'utils/ColorUtils';
import StringUtils from 'utils/StringUtils';

export default function getChannelColor(channel: Channel | undefined): string {
  if (!channel) return '';
  const { appId } = channel;
  const appColor = appsColors[appId];
  return ColorUtils.getColorShade(appColor, StringUtils.hashStringToNumber(channel.id));
}
