import { ResponsiveScatterPlotCanvas, ScatterPlotNodeData } from '@nivo/scatterplot';
import React, { ReactElement, useMemo } from 'react';

import CSSVariableDefiner from 'common-ui-components/CSSVariableDefiner';
import makeCursorPointer from 'screens/platform/contentScreens/AnalyticsScreen/utils/pointerCursorUtils';
import useTimeSeriesChartLabels, {
  useTimeSeriesChartLabelFormatter,
} from 'screens/platform/contentScreens/AnalyticsScreen/utils/TimeSeriesChartLabelsHook/TimeSeriesChartLabelsHook';
import {
  buildChartData,
  buildNodesShapesOffsetMap,
  calcMiddleDates,
  drawCircle,
  drawDiamond,
  drawSquare,
  drawTriangle,
  getAllDatesSorted,
  getFixedNodeSize,
  getMinAndMaxDates,
} from 'screens/platform/contentScreens/AnalyticsScreen/widgets/EventsChart/BubbleChart/BubbleChartUtils';
import { DateRangeGranularity } from 'screens/platform/contentScreens/AnalyticsScreen/widgets/widgetConfig';
import widgetConstants from 'screens/platform/contentScreens/AnalyticsScreen/widgets/widgetConstants';

import style from 'screens/platform/contentScreens/AnalyticsScreen/widgets/EventsChart/BubbleChart/style.module.scss';

export enum BubbleShape {
  CIRCLE,
  DIAMOND,
  SQUARE,
  TRIANGLE,
}
export interface BubbleDatum<T> {
  date: Date;
  nodes: {
    size: number;
    color: string;
    shape: BubbleShape;
  }[];
  datum: T;
}

export interface BubbleChartGroup<T> {
  id: string;
  groupData: BubbleDatum<T>[];
  title: string;
}

interface Props<T> {
  groups: BubbleChartGroup<T>[];
  onBubbleClick: (datum: any) => void;
  tooltip: ({ datum, color }: { datum: any; color: string }) => ReactElement;
  maxBubbleSize: number;
  minBubbleSize: number;
}

export default function BubbleChart<T>({
  groups, onBubbleClick, tooltip, maxBubbleSize, minBubbleSize,
}: Props<T>) {
  const chartData = useMemo(() => buildChartData(groups, minBubbleSize, maxBubbleSize), [groups]);
  const sortedDates = useMemo(() => getAllDatesSorted(groups), [groups]);
  const middleDates = calcMiddleDates(sortedDates);
  const { min, max } = getMinAndMaxDates(sortedDates, middleDates);
  const { labels, ref } = useTimeSeriesChartLabels({ granularity: DateRangeGranularity.WEEK });

  const {
    theme, margin, lineHeight,
  } = widgetConstants.bubbleChart;
  const height = chartData.length * lineHeight + margin.bottom + margin.top;

  const renderNodes = (
    ctx: CanvasRenderingContext2D,
    node: ScatterPlotNodeData<any>,
  ) => {
    const { x, y, data: { nodes } } = node;
    const shapesOffsets = buildNodesShapesOffsetMap(nodes, maxBubbleSize);
    nodes.forEach(({ size, color, shape }) => {
      const nodeSize = getFixedNodeSize(size, maxBubbleSize);
      const offsetX = x + (shapesOffsets[shape] || 0) + nodeSize / 2;
      switch (shape) {
        case BubbleShape.CIRCLE:
          drawCircle(ctx, offsetX, y, nodeSize, color);
          break;
        case BubbleShape.DIAMOND:
          drawDiamond(ctx, offsetX, y, nodeSize, color);
          break;
        case BubbleShape.SQUARE:
          drawSquare(ctx, offsetX, y, nodeSize, color);
          break;
        case BubbleShape.TRIANGLE:
          drawTriangle(ctx, offsetX, y, nodeSize, color);
          break;
        default:
      }
    });
  };

  const labelFormatter = useTimeSeriesChartLabelFormatter(labels);

  const groupTitles = [...new Set(chartData.map(({ title }) => title))];
  return (
    <CSSVariableDefiner className={style.wrapper} variables={{ '--bubble-chart-height': `${height}px` }}>
      <div ref={ref} className={style.container}>
        <ResponsiveScatterPlotCanvas
          data={chartData}
          enableGridX={false}
          theme={theme}
          margin={margin}
          renderNode={renderNodes}
          onMouseEnter={makeCursorPointer}
          xScale={{
            useUTC: false,
            type: 'time',
            format: '%Y-%m-%d',
            precision: 'hour',
            min,
            max,
          }}
          onClick={({ data: datum }) => onBubbleClick(datum)}
          nodeSize={{ key: 'data.size', values: [0, maxBubbleSize], sizes: [0, 50] }}
          tooltip={({ node: { data: datum, color } }) => (
            <div className={style.tooltip}>
              {tooltip({ datum, color })}
            </div>
          )}
          axisTop={{
            tickValues: 'every day',
            tickPadding: 10,
            format: labelFormatter,
          }}
          yScale={{
            type: 'point',
          }}
          axisBottom={null}
          axisLeft={{
            ...widgetConstants.bubbleChart.axisLeft,
            tickValues: groupTitles.map((_, index) => index),
            format: (groupIndex) => groupTitles[groupIndex],
          }}
        />
      </div>
    </CSSVariableDefiner>
  );
}
