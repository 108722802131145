import { createContext, Dispatch, useContext } from 'react';

import Tenant from 'model/Tenant';
import { TenantReducerAction } from 'screens/platform/cross-platform-components/context/tenant/TenantReducer';

export interface TenantContextType {
  tenant: Tenant;
  dispatchTenant: Dispatch<TenantReducerAction>;
  switchTenant: (newTenantId: Tenant['id']) => Promise<void>;
  getDepartmentColor: (departmentLabel: string) => string;
  allTenants?: Tenant[];
  tenantLogo: string | null;
  isImpersonationMode: boolean;
}
export const TenantContext = createContext<TenantContextType>({} as any);

export default function useTenantContext(): TenantContextType {
  return useContext<TenantContextType>(TenantContext);
}
