import { FC, SVGProps } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';

import { getScreenPath, ScreenKey } from 'global/ScreensConfiguration';
import useFilteredDirectoryScreensConfig from 'routers/DirectoryRouter/DirectoryScreensConfig';
import { useUserContext } from 'screens/platform/cross-platform-components/context/user/UserContext';

import { ReactComponent as AnalyticsIcon } from 'assets/img/icon/screens/analytics-screen.svg';
import { ReactComponent as DirectoryIcon } from 'assets/img/icon/screens/directory-screen.svg';
import { ReactComponent as FeedIcon } from 'assets/img/icon/screens/feed-screen.svg';
import { ReactComponent as OrganizationsIcon } from 'assets/img/icon/screens/organizations-screen.svg';
import { ReactComponent as PeopleIcon } from 'assets/img/icon/screens/people-screen.svg';
import { ReactComponent as TopicsIcon } from 'assets/img/icon/screens/topics-screen.svg';

export enum AppScreen {
  FEED,
  ANALYTICS,
  DIRECTORY,
  ORGANIZATIONS,
  PEOPLE,
  TOPICS,
  INTERACTIONS,
  // eslint-disable-next-line max-len
  SLACK_ALERTS, // This is redundant, since it's defined in the settings router. However, its purpose is to support the events' actionText component
}

export type NavbarScreen = AppScreen.FEED |
  AppScreen.DIRECTORY |
  AppScreen.ANALYTICS |
  AppScreen.ORGANIZATIONS |
  AppScreen.PEOPLE |
  AppScreen.TOPICS

export interface AppScreenConfig {
  key: string;
  url: string;
}

export interface NavbarAppScreenConfig extends AppScreenConfig {
  Icon: FC<SVGProps<SVGSVGElement>>;
  label: string;
  isBetaFeature?: boolean;
}

export const NavbarScreensConfig:
  Record<NavbarScreen, NavbarAppScreenConfig> = Object.freeze({
    [AppScreen.FEED]: {
      key: 'feed',
      label: 'Home',
      url: getScreenPath('feed'),
      Icon: FeedIcon,
    },
    [AppScreen.DIRECTORY]: {
      key: 'directory',
      label: 'Directory',
      url: getScreenPath('platform.directory'),
      Icon: DirectoryIcon,
    },
    [AppScreen.ORGANIZATIONS]: {
      key: 'organizations',
      label: 'Organizations',
      url: getScreenPath('platform.directory.organizations'),
      Icon: OrganizationsIcon,
    },
    [AppScreen.PEOPLE]: {
      key: 'people',
      label: 'People',
      url: getScreenPath('platform.directory.people'),
      Icon: PeopleIcon,
    },
    [AppScreen.TOPICS]: {
      key: 'topics',
      label: 'Topics',
      url: getScreenPath('platform.directory.topics'),
      Icon: TopicsIcon,
    },
    [AppScreen.ANALYTICS]: {
      key: 'analytics',
      label: 'Analytics',
      url: getScreenPath('platform.analytics'),
      Icon: AnalyticsIcon,
    },
  });

export const AppScreensConfig:
  Record<AppScreen, AppScreenConfig | NavbarAppScreenConfig> = Object.freeze({
    ...NavbarScreensConfig,
    [AppScreen.INTERACTIONS]: {
      key: 'interactions',
      url: getScreenPath('platform.interactions'),
    },
    [AppScreen.SLACK_ALERTS]: {
      key: 'slackAlerts',
      url: getScreenPath('settings.user.slackAlerts'),
    },
  });

export function useCurrentAppScreen() {
  const location = useLocation();
  const { filteredScreens } = useFilteredDirectoryScreensConfig();
  const { personId } = useUserContext();

  function isCurrentPath(screenKey: ScreenKey, urlParam?: string | null) {
    if (urlParam === null) return false;
    const path = getScreenPath(screenKey, urlParam);
    return location?.pathname === path;
  }

  return {
    isFeedScreen: isCurrentPath('feed'),
    isWizardScreen: isCurrentPath('platform.wizard'),
    isAnalyticsScreen: isCurrentPath('platform.analytics'),
    isInteractionsScreen: isCurrentPath('platform.interactions'),
    isReportsScreen: isCurrentPath('platform.reports'),
    isDirectoryRootScreen: Object.values(filteredScreens)
      .some(({ screenKey }) => isCurrentPath(screenKey)),
    isDirectoryScreen: Boolean(useRouteMatch(getScreenPath('platform.directory'))),
    isTopicProfileScreen: Boolean(useRouteMatch(getScreenPath('platform.directory.topics.profile'))),
    isOrganizationProfileScreen: Boolean(useRouteMatch(getScreenPath('platform.directory.organizations.profile'))),
    isPersonProfileScreen: Boolean(useRouteMatch(getScreenPath('platform.directory.people.profile'))),
    isUserProfileScreen: isCurrentPath('platform.directory.people.profile', personId),
    isSettingsPageScreen: Boolean(useRouteMatch(getScreenPath('settings'))),
    isBackofficeScreen: Boolean(useRouteMatch(getScreenPath('backoffice'))),
  };
}
