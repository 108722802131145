import classNames from 'classnames';
import React, { forwardRef, ReactNode } from 'react';

import Button from 'common-ui-components/Button';
import { usePlatformContext } from 'screens/platform/cross-platform-components/context/platform/PlatformContext';
import ExtendedInteractionsTableHeader
  from 'screens/platform/cross-platform-components/Interactions/components/ExtendedInteractionsTableHeader';
import InteractionsContent from 'screens/platform/cross-platform-components/Interactions/components/InteractionsContent';
import InteractionsTitle from 'screens/platform/cross-platform-components/Interactions/components/InteractionsTitle';
import InteractionsContextProvider from 'screens/platform/cross-platform-components/Interactions/InteractionsContext';
import {
  InteractionsTableState,
} from 'screens/platform/cross-platform-components/Panels/BottomPanel/CollapsibleInteractionsDrawer';

import style from 'screens/platform/cross-platform-components/Interactions/style.module.scss';

interface Props {
  isCollapsible?: boolean;
  onHeaderClick?: () => void;
  className?: string;
  bottomSectionClassName?: string;
  toggleButtonIcon: ReactNode;
  onToggleButtonClick?: () => void;
}

const Interactions = forwardRef<HTMLDivElement, Props>(
  (
    {
      isCollapsible = false,
      onHeaderClick,
      className,
      bottomSectionClassName,
      toggleButtonIcon,
      onToggleButtonClick,
    },
    ref,
  ) => {
    const { interactionsTable } = usePlatformContext();
    const isInteractionsTableClosed = interactionsTable.openState === InteractionsTableState.CLOSED;
    const showHeaderDetails = !isCollapsible || !isInteractionsTableClosed;

    return (
      <InteractionsContextProvider isCollapsible={isCollapsible}>
        <div ref={ref} className={classNames(style.interactions, className)}>
          <div
            className={classNames(style.topSection, {
              [style.opened]: showHeaderDetails,
              [style.clickable]: Boolean(onHeaderClick),
            })}
            onClick={onHeaderClick}
          >
            <InteractionsTitle extended={showHeaderDetails} />
            {showHeaderDetails && <ExtendedInteractionsTableHeader />}
            <Button
              onClick={onToggleButtonClick}
              rounded
              color="gray"
              className={style.caretBtn}
            >
              {toggleButtonIcon}
            </Button>
          </div>
          <div
            className={classNames(style.bottomSection, bottomSectionClassName)}
          >
            <InteractionsContent />
          </div>
        </div>
      </InteractionsContextProvider>
    );
  },
);

export default Interactions;
