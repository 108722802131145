import {
  getRequest,
  HttpResponse,
  postRequest,
} from 'global/api/platformApiHelpers';
import Metric from 'global/lists/Metric';
import { Atom } from 'model/Atom';
import Tenant from 'model/Tenant';
import { MasterFilters } from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFilters';
import convertMasterFilters from 'screens/platform/cross-platform-components/context/MasterFiltersContext/utils/MasterFiltersApiConversionUtils';

export type ActiveMetrics = Metric[];
export type MetricValueByOrganization = Record<string, MetricValueResponse>;

export type MetricValueResponse =
  | {
      value: number;
      previousPeriodValue: number;
      interaction: null;
    }
  | {
      value: null;
      previousPeriodValue: null;
      interaction: Atom;
    };

const MetricController = {
  async getActiveMetrics(tenantId: Tenant['id']): HttpResponse<ActiveMetrics> {
    return getRequest('metric', { tenantId });
  },

  async getMetricValue(
    metricId: Metric,
    filters: MasterFilters,
  ): HttpResponse<MetricValueResponse> {
    return postRequest('metric', {
      metricId,
      filters: convertMasterFilters(filters, true),
    });
  },

  async getMetricValuesByOrganizations(
    tenantId: Tenant['id'],
    metricId: Metric,
    organizationsIds: string[],
  ): HttpResponse<MetricValueByOrganization> {
    return postRequest(
      'metric/organizations',
      { metricId, organizationsIds },
      { params: { tenantId } },
    );
  },

  async toggleMetric(
    tenantId: Tenant['id'],
    metricType: Metric,
    isEnabled: boolean,
  ): HttpResponse<void> {
    return postRequest(
      'metric/toggle',
      { metricType, isEnabled },
      { params: { tenantId } },
    );
  },
};

export default MetricController;
