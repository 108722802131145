import React from 'react';

import Metric from 'global/lists/Metric';
import MetricCard from 'screens/platform/contentScreens/AnalyticsScreen/widgets/MetricsWidget/MetricCards/MetricCard';
import { MasterFilters } from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFilters';
import MetricsContainer from 'screens/platform/cross-platform-components/MetricsContainer';

type Props = {
  metrics: Metric[];
  masterFilters: MasterFilters | null;
  disableClicking?: boolean;
};

export default function MetricCards({
  metrics,
  masterFilters,
  disableClicking = false,
}: Props) {
  return (
    <MetricsContainer horizontallyScrollable>
      {metrics.map((metric) => (
        <MetricCard
          key={metric}
          type={metric}
          masterFilters={masterFilters}
          disableClicking={disableClicking}
        />
      ))}
    </MetricsContainer>
  );
}
