import { useCallback, useMemo } from 'react';

import FilterCondition, {
  FilterFieldType,
} from 'global/api/controller/utils/filtering/FilterCondition';
import { IdFilterOperator } from 'global/api/controller/utils/filtering/IdFilterCondition';
import OrganizationFilterableProperty from 'global/api/controller/utils/filtering/OrganizationFilterableProperty';
import PersonFilterableProperty from 'global/api/controller/utils/filtering/PersonFilterableProperty';
import { ListOptions } from 'global/ListOptions';
import { useUserContext } from 'screens/platform/cross-platform-components/context/user/UserContext';
import FilterConfig, { DynamicListOptions } from 'screens/platform/directory/components/GenericDirectoryScreen/FiltersList/FilterConfig';
import useLoggedInPersonTeam from 'screens/platform/directory/utils/loggedInPersonTeamHook';

export enum RelationsFilterOption {
  BY_ME = 'BY_ME',
  BY_MY_TEAM = 'BY_MY_TEAM',
  BY_ANYONE = 'BY_ANYONE',
}

type RelationsFilterableField =
  | PersonFilterableProperty.MANAGER
  | PersonFilterableProperty.ENGAGED_PEOPLE
  | OrganizationFilterableProperty.OWNERS;

export default function useRelationsFilterConfig(
  relationsOptions: ListOptions<RelationsFilterOption>,
  filterableField: RelationsFilterableField,
): FilterConfig<RelationsFilterOption> {
  const { personId: loggedInPersonId } = useUserContext();
  const teamPersonIds = useLoggedInPersonTeam();

  const disabledOptions = useMemo(() => {
    if (!teamPersonIds || teamPersonIds.length > 0) return undefined;
    return {
      [RelationsFilterOption.BY_MY_TEAM]: 'Enabled for team managers only',
    };
  }, [teamPersonIds]);

  const toFilterCondition = useCallback(
    (value: RelationsFilterOption[]): FilterCondition => {
      const operator = value.includes(RelationsFilterOption.BY_ANYONE)
        ? IdFilterOperator.EXISTS
        : IdFilterOperator.IS_ANY_OF;

      const filteredIds: string[] = [];

      if (value.includes(RelationsFilterOption.BY_ME) && loggedInPersonId) {
        filteredIds.push(loggedInPersonId);
      }

      if (value.includes(RelationsFilterOption.BY_MY_TEAM) && teamPersonIds) {
        filteredIds.push(...teamPersonIds);
      }

      return {
        fieldName: filterableField,
        fieldType: FilterFieldType.ID,
        operator,
        value: filteredIds,
      };
    },
    [teamPersonIds, loggedInPersonId],
  );

  return useMemo(
    () => ({
      label: 'Relations',
      options: new DynamicListOptions(relationsOptions),
      multiSelect: true,
      disabledOptions,
      removable: true,
      defaultValue: [],
      toFilterCondition,
    }),
    [disabledOptions, toFilterCondition],
  );
}
