import StorageUtils from 'utils/StorageUtils';

export enum StorageKeyGeneral {
  TENANT_ID = 'general.tenantId',
}

export enum StorageKeySorting {
  INTERACTION_TABLE_SORTING = 'sorting.interactionTable',
  SETTINGS_ORGANIZATIONS_TABLE_SORTING = 'sorting.settings.organizationsTable',
}

export enum StorageKeyPageSize {
  INTERACTION_TABLE_PAGE_SIZE = 'pageSize.interactionTable',
  ORGANIZATIONS_TABLE_PAGE_SIZE = 'pageSize.organizationsTable',
  PEOPLE_SETTINGS_TABLE_PAGE_SIZE = 'pageSize.settings.peopleTable',
  TAGS_SETTINGS_TABLE_PAGE_SIZE = 'pageSize.settings.tagsTable',
}

export enum StorageKeyColumnsConfig {
  INTERACTION_TABLE_COLUMNS = 'columnsConfig.interactionTable',
  ORGANIZATIONS_TABLE_COLUMNS = 'columnsConfig.directory.organizationsTable',
  PEOPLE_DIRECTORY_TABLE_COLUMNS = 'columnsConfig.directory.peopleTable',
  TAGS_DIRECTORY_TABLE_COLUMNS = 'columnsConfig.directory.tagsTable',
  CONTACTS_DIRECTORY_TABLE_COLUMNS = 'columnsConfig.directory.contactsTable',
}

export enum StorageKeyFeatures {
  GRAPH_FEATURES = 'feature.graphFeatures',
  REAL_TIME = 'feature.realTime',
  ANALYTICS_SCREEN = 'feature.analyticsScreen',
  RD_MANAGER_FEED_TAGS_SELECTION = 'feature.feed.tagsSelection',
  DIRECTORY_SCREEN_TOGGLE_SIDEBAR = 'feature.directoryScreen.toggleSidebar'
}

export enum StorageKeyOriginalPath {
  ORIGINAL_PATH = 'originalPath',
}

export enum InternalFeatureStorageKey {
  SHOULD_ANONYMIZE_ORGANIZATIONS = 'shouldAnonymizeOrganizations',
  VIEW_ENTERPRISE_SEARCH_PROMPT = 'viewEnterpriseSearchPrompt',
}

export type StorageKey = StorageKeyGeneral
  | StorageKeyFeatures
  | StorageKeyOriginalPath
  | StorageKeyColumnsConfig
  | StorageKeySorting
  | StorageKeyPageSize
  | InternalFeatureStorageKey

export default new StorageUtils<StorageKey>(localStorage);
