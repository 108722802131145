import BubbleChart from 'screens/platform/contentScreens/AnalyticsScreen/widgets/EventsChart/BubbleChart';
import {
  ChartType, DateRangeGranularity,
  GroupType,
  WidgetRecipe,
} from 'screens/platform/contentScreens/AnalyticsScreen/widgets/widgetConfig';

const eventRecipes: WidgetRecipe[] = [
  {
    group: null,
    chart: ChartType.BUBBLE,
    granularity: DateRangeGranularity.WEEK,
    Component: BubbleChart,
  },
  {
    group: GroupType.TAGS,
    chart: ChartType.BUBBLE,
    granularity: DateRangeGranularity.WEEK,
    Component: BubbleChart,
  },
  {
    group: GroupType.DEPARTMENTS,
    chart: ChartType.BUBBLE,
    granularity: DateRangeGranularity.WEEK,
    Component: BubbleChart,
  },
];

export default eventRecipes;
