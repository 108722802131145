import classNames from 'classnames';
import React, { ReactNode } from 'react';

import Button from 'common-ui-components/Button';

import style from 'common-ui-components/TrendCard/style.module.scss';

interface Props {
    title: String;
    value: ReactNode | string;
    additionalInfo?: ReactNode | string;
    onClick?: () => void;
    className?: string;
  }

export default function TrendCard({
  title, value, additionalInfo, onClick, className,
}: Props) {
  return (
    <Button
      className={classNames(style.card, className)}
      onClick={onClick}
      disabled={Boolean(!onClick)}
    >
      <div className={style.title}>
        {title}
      </div>
      <div className={style.value}>
        {value}
      </div>
      {additionalInfo && (
        <div className={style.additionalInfo}>
          {additionalInfo}
        </div>
      )}
    </Button>
  );
}
