import React from 'react';

import EnterpriseSearch from 'es-src/screens/HomeScreen/components/EnterpriseSearch';
import ResultSection from 'es-src/screens/HomeScreen/components/ResultSection';
import WelcomeMessage from 'es-src/screens/HomeScreen/components/WelcomeMessage';
import EnterpriseSearchContextProvider
  from 'es-src/screens/HomeScreen/EnterpriseSearchContext/EnterpriseSearchContextProvider';

import style from 'es-src/screens/HomeScreen/style.module.scss';

export default function HomeScreen() {
  return (
    <div className={style.homeScreenContainer}>
      <div className={style.content}>
        <EnterpriseSearchContextProvider>
          <WelcomeMessage />
          <EnterpriseSearch />
          <ResultSection />
        </EnterpriseSearchContextProvider>
      </div>
    </div>
  );
}
