import classNames from 'classnames';
import React, {
  ReactNode, useEffect, useRef, useState,
} from 'react';

import { useResizeListener } from 'utils/hooks';

import style from 'common-ui-components/ShadowedScrollableDiv/style.module.scss';

type Props = {
  scrollDirection: 'horizontal' | 'vertical';
  children: ReactNode;
  className?: string;
};

export default function ShadowedScrollableDiv({
  scrollDirection,
  children,
  className,
}: Props) {
  const [showStart, setShowStart] = useState(false);
  const [showEnd, setShowEnd] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const { width, height, ref: resizableRef } = useResizeListener();

  const isVertical = scrollDirection === 'vertical';

  useEffect(() => {
    if (!ref.current) return () => {};

    const onScroll = () => {
      const {
        scrollHeight = 0,
        scrollWidth = 0,
        scrollTop = 0,
        scrollLeft = 0,
        offsetHeight = 0,
        offsetWidth = 0,
      } = ref.current || {};
      setShowStart(isVertical ? scrollTop > 0 : scrollLeft > 0);
      setShowEnd(
        isVertical
          ? scrollTop + offsetHeight < scrollHeight
          : scrollLeft + offsetWidth < scrollWidth,
      );
    };

    onScroll();
    const node = ref.current;
    node.addEventListener('scroll', onScroll);
    return () => {
      node.removeEventListener('scroll', onScroll);
    };
  }, [width, height, isVertical]);

  return (
    <div ref={resizableRef} className={style.container}>
      <div ref={ref} className={classNames(style.content, className)}>
        {children}
      </div>
      {showStart && (
        <div className={isVertical ? style.shadowTop : style.shadowLeft} />
      )}
      {showEnd && (
        <div className={isVertical ? style.shadowBottom : style.shadowRight} />
      )}
    </div>
  );
}
