import { getRequest, HttpResponse } from 'global/api/platformApiHelpers';
import Report from 'model/Report';
import Tenant from 'model/Tenant';

export interface AllReportsResponse {
  reports: Report[];
}

const ReportController = {
  async getAllReports(tenantId: Tenant['id']): HttpResponse<AllReportsResponse> {
    return getRequest('report', { tenantId });
  },
};

export default ReportController;
