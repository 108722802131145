import classNames from 'classnames';
import React, { ReactNode } from 'react';

import style from 'common-ui-components/ScreenContentCard/style.module.scss';

type Props = {
  fullScreen?: boolean;
  className?: string;
  children: ReactNode;
};

export default function ScreenContentCard({
  fullScreen = false,
  className,
  children,
}: Props) {
  return (
    <div className={classNames(style.screenContentCard, className, fullScreen && style.fullScreen)}>
      {children}
    </div>
  );
}
