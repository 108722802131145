import React, { FC } from 'react';

import Button from 'common-ui-components/Button';
import ExternalLink from 'common-ui-components/ExternalLink';
import { AKOODA_PRIVACY_DOCS_URL } from 'global/links';

import { ReactComponent as LockIcon } from 'assets/img/icon/lock.svg';

import style from 'screens/platform/cross-platform-components/Interactions/components/ExtendedInteractionsTableHeader/RestrictedViewButton/style.module.scss';

type Props = {
  closePopover: () => void;
};

const RestrictedViewPopoverContent: FC<Props> = ({ closePopover }) => (
  <div
    // e.stopPropagation() is needed because this popover is rendered inside a button
    onClick={(e) => e.stopPropagation()}
    className={style.restrictedViewPopoverContent}
  >
    <LockIcon className={style.lockIcon} />
    <h3 className={style.title}>Personalized View</h3>
    <span className={style.content}>
      This view contains details only on interactions that are either public to
      everyone, or private such that you specifically can access them.
    </span>
    <div className={style.actionsContainer}>
      <Button color="gray" padded rounded className={style.transparent} onClick={closePopover}>
        Close
      </Button>
      <Button color="blue" padded rounded>
        <ExternalLink to={AKOODA_PRIVACY_DOCS_URL} className={style.learnMore}>
          Learn More
        </ExternalLink>
      </Button>
    </div>
  </div>
);

export default RestrictedViewPopoverContent;
