import React from 'react';

import { useCurrentAppScreen } from 'global/lists/AppScreen';
import AppsFilter from 'screens/platform/directory/components/ProfileTabs/components/Filters/AppsFilter';
import DatesRangeFilter from 'screens/platform/directory/components/ProfileTabs/components/Filters/DatesRangeFilter';
import DirectionalityFilter
  from 'screens/platform/directory/components/ProfileTabs/components/Filters/DirectionalityFilter';
import ClearFiltersButton
  from 'screens/platform/directory/components/ProfileTabs/components/Filters/FilterButton/ClearFiltersButton';
import OrganizationsFilter
  from 'screens/platform/directory/components/ProfileTabs/components/Filters/OrganizationsFilter';
import PeopleFilter from 'screens/platform/directory/components/ProfileTabs/components/Filters/PeopleFilter';
import TopicsFilter from 'screens/platform/directory/components/ProfileTabs/components/Filters/TopicsFilter';

import style from 'screens/platform/directory/components/ProfileTabs/components/Filters/style.module.scss';

export interface FiltersProps {
  requiredEntity?: 'organizations' | 'topics' | 'people';
  entityValue?: string;
  disabled?: boolean;
}

export default function Filters({
  requiredEntity,
  entityValue,
  disabled,
}: FiltersProps) {
  const { isOrganizationProfileScreen, isTopicProfileScreen } = useCurrentAppScreen();

  return (
    <div className={style.filters}>
      <DatesRangeFilter disabled={disabled} />
      {isOrganizationProfileScreen ? <DirectionalityFilter disabled={disabled} /> : null}
      <OrganizationsFilter
        requiredOrganization={isOrganizationProfileScreen ? entityValue : undefined}
        disabled={disabled}
      />
      <PeopleFilter disabled={disabled} />
      <TopicsFilter
        requiredTopic={isTopicProfileScreen ? entityValue : undefined}
        disabled={disabled}
      />
      <AppsFilter disabled={disabled} />
      <ClearFiltersButton
        requiredEntity={requiredEntity}
        entityValue={entityValue}
        disabled={disabled}
      />
    </div>
  );
}
