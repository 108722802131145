import { MetricValueResponse } from 'global/api/controller/MetricController';
import Metric from 'global/lists/Metric';
import { EnrichedOrganization } from 'model/Organization';

export const organizationMetrics = [
  Metric.ORGANIZATION_LAST_MEETING,
  Metric.ORGANIZATION_MEETING_FREQUENCY,
  Metric.ORGANIZATION_TOTAL_ENGAGEMENTS,
  Metric.ORGANIZATION_ENGAGEMENT_FREQUENCY,
  Metric.ORGANIZATION_TOTAL_PEOPLE,
  Metric.ORGANIZATION_TOTAL_CONTACTS,
  Metric.ORGANIZATION_TOTAL_MENTIONS,
  Metric.ORGANIZATION_TIME_SINCE_FIRST_MENTION,
  Metric.ORGANIZATION_TOTAL_OUTBOUND_INTERACTIONS,
  Metric.ORGANIZATION_TOTAL_INBOUND_INTERACTIONS,
  Metric.ORGANIZATION_TOTAL_ENGAGEMENTS_VOLUME,
  Metric.ORGANIZATION_TOTAL_MENTIONS_VOLUME,
  Metric.ORGANIZATION_TOTAL_CONVERSATIONS,
  Metric.ORGANIZATION_TOTAL_MEETINGS,
  Metric.ORGANIZATION_TIME_IN_MEETINGS_LAST_THREE_MONTHS,
  Metric.ORGANIZATION_TIME_IN_MEETINGS_LAST_SIX_MONTHS,
  Metric.ORGANIZATION_TIME_IN_MEETINGS_LAST_NINE_MONTHS,
  Metric.ORGANIZATION_TIME_IN_MEETINGS_LAST_TWELVE_MONTHS,
] as const;

export type OrganizationMetric = (typeof organizationMetrics)[number];

export type MetricValues = Partial<Record<OrganizationMetric, MetricValueResponse>>;

interface OrganizationWithMetrics extends EnrichedOrganization {
  metricValues?: MetricValues;
}

export default OrganizationWithMetrics;
