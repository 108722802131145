export type OrganizationProperties = {
  displayName?: string;
  nicknames?: string[];
  owners?: Record<string, string>;
  segments?: string[];
  stage?: string;
  location?: string;
  closedAt?: string; // Date
  modifiedAt?: string; // Date
  renewedAt?: string; // Date
  objectId?: string;
  objectType?: string;
  objectSource?: string;
  risk?: OrganizationRisk;
  size?: OrganizationSize;
  linkedinUrl?: string;
  twitterUrl?: string;
  websiteUrl?: string;
  phone?: string;
};

export enum OrganizationSize {
  VERY_SMALL = 'VERY_SMALL',
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}

export enum OrganizationRisk {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
}

interface Organization {
  id: string;
  domain: string;
  isExternal: boolean;
  clusterId: string;
  isEnabled: boolean;
  name?: string;
  lastEngagement?: string;
  lastMention?: string;
  properties?: OrganizationProperties;
}

export type EnrichedOrganization = Organization & {
  contacts: string[];
  topInternalPeople: string[];
}

export default Organization;
