import classNames from 'classnames';
import React from 'react';

import MultiImages from 'common-ui-components/MultiImages/index';
import {
  ImageType,
  MultiImagesSpecificIdentityProps,
  useImageClickHandler,
} from 'common-ui-components/MultiImages/MultiImagesUtils';
import MultiOrganizationsImagesUtils
  from 'common-ui-components/MultiImages/MultiOrganizationsImages/MultiOrganizationsImagesUtils';
import { FilterFieldType } from 'global/api/controller/utils/filtering/FilterCondition';
import { IdFilterOperator } from 'global/api/controller/utils/filtering/IdFilterCondition';
import OrganizationFilterableProperty from 'global/api/controller/utils/filtering/OrganizationFilterableProperty';
import Size from 'global/lists/Size';
import Organization from 'model/Organization';
import useOrganizationsMetadata
  from 'screens/platform/cross-platform-components/context/metadata/hooks/organizationsMetadataHook';
import { usePlatformContext } from 'screens/platform/cross-platform-components/context/platform/PlatformContext';
import {
  InteractionsTableState,
} from 'screens/platform/cross-platform-components/Panels/BottomPanel/CollapsibleInteractionsDrawer';
import useRedirectToFilteredDirectory from 'screens/platform/directory/hooks/redirectToFilteredDirectoryHook';
import { getOrganizationName } from 'utils/OrganizationUtils';

import multiImagesStyle from 'common-ui-components/MultiImages/style.module.scss';

const { OrganizationImage } = MultiOrganizationsImagesUtils;

export default function MultiOrganizationsImages({
  ids,
  size = Size.MEDIUM,
  hoverable = true,
  onHiddenEntitiesClick,
}: MultiImagesSpecificIdentityProps) {
  const handleImageClick = useImageClickHandler();
  const redirectToFilteredDirectory = useRedirectToFilteredDirectory();
  const { interactionsTable } = usePlatformContext();

  const { value: organizations, loading } = useOrganizationsMetadata(ids);

  function getVisibleImages(visibleIds: string[]) {
    function isOrganizationDefined(value: Readonly<Organization> | undefined):
      value is Readonly<Organization> {
      return value !== undefined;
    }

    return (
      visibleIds
        .map((id) => organizations?.[id])
        .filter(isOrganizationDefined)
        .map((organization, idx) => {
          const { id } = organization;
          return (
            <OrganizationImage
              key={id}
              organizationId={id}
              name={getOrganizationName(organization)}
              size={size}
              hoverable={hoverable}
              onClick={handleImageClick}
              order={idx}
            />
          );
        })
    );
  }

  function getHiddenEntitiesLabels(hiddenIds: string[]): string[] {
    return hiddenIds.map((orgId) => getOrganizationName(organizations?.[orgId], orgId));
  }

  const hiddenEntitiesDefaultClickHandler = () => {
    redirectToFilteredDirectory({
      screen: 'organizations',
      filterConditions: {
        [OrganizationFilterableProperty.ID]: {
          fieldType: FilterFieldType.ID,
          fieldName: OrganizationFilterableProperty.ID,
          operator: IdFilterOperator.IS_ANY_OF,
          value: ids,
        },
      },
    });
    if (interactionsTable.openState !== InteractionsTableState.CLOSED) {
      interactionsTable.setOpenState(InteractionsTableState.CLOSED, null);
    }
  };

  return (
    <MultiImages
      ids={ids}
      getVisibleImages={getVisibleImages}
      getHiddenEntitiesLabels={getHiddenEntitiesLabels}
      onHiddenEntitiesClick={onHiddenEntitiesClick || hiddenEntitiesDefaultClickHandler}
      size={size}
      imageType={ImageType.ORGANIZATIONS}
      hiddenEntitiesNodeClassName={
        classNames({
          [multiImagesStyle.organizationImageWrapperHidden]: size === Size.BIG,
          [multiImagesStyle.organizationImageWrapperHiddenMd]: size === Size.MEDIUM,
          [multiImagesStyle.organizationImageWrapperHiddenSm]: size === Size.SMALL,
        })
      }
      loading={loading}
    />
  );
}
