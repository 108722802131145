import { parseEventDates } from 'global/api/controller/EventController';
import { RequestPaging, RequestSorting } from 'global/api/controller/utils/commonControllerUtils';
import { getRequest, HttpResponseResult, postRequest } from 'global/api/platformApiHelpers';
import Announcement from 'model/Announcement';
import EventResponse from 'model/Events/Event';
import Objective from 'model/Objectives/Objective';
import Tenant from 'model/Tenant';
import DebuggerConsole from 'utils/DebuggerConsole';

export interface AnnouncementRequestBody {
  pagingConfig: RequestPaging;
  announceTsSortOrder: RequestSorting['sortOrder'];
  readFilter: {
    includeRead: boolean;
    includeUnread: boolean;
  };
}

export interface AnnouncementResponse {
  announcements: Announcement[];
  totalAnnouncementCount: number;
}

const FeedController = {

  Objectives: {
    async getFeedObjectives(tenantId: Tenant['id']): Promise<Objective[]> {
      try {
        const response: HttpResponseResult<Objective[]> = await getRequest(
          'feed/objectives',
          { tenantId },
        );
        if (response?.data) {
          return response.data;
        }
      } catch (err) {
        DebuggerConsole.error('Failed fetching feed objectives', err, { tenantId });
      }
      return [];
    },
  },

  Notifications: {
    async getEvents(tenantId: Tenant['id']): Promise<EventResponse[]> {
      try {
        const response: HttpResponseResult<EventResponse[]> = await getRequest(
          'feed/events',
          { tenantId },
        );
        if (response?.data) {
          return response.data.map(parseEventDates);
        }
      } catch (err) {
        DebuggerConsole.error('Failed fetching feed events', err, { tenantId });
      }
      return [];
    },

    async getAnnouncements(tenantId: Tenant['id'], requestBody: AnnouncementRequestBody): Promise<AnnouncementResponse> {
      try {
        const response: HttpResponseResult<AnnouncementResponse> = await postRequest(
          'announcement/feed',
          requestBody,
          { params: { tenantId } },
        );
        if (response?.data) {
          return response.data;
        }
      } catch (err) {
        DebuggerConsole.error('Failed fetching announcements events', err, { tenantId });
      }
      return {
        announcements: [],
        totalAnnouncementCount: 0,
      };
    },

    async markAnnouncementAsRead(tenantId: Tenant['id'], messageId: Announcement['messageId'], isRead: Announcement['isRead']): Promise<string | undefined> {
      try {
        const response: HttpResponseResult<string> = await postRequest(
          'announcement/markRead',
          null,
          { params: { tenantId, messageId, isRead } },
        );
        if (response?.data) {
          return response.data;
        }
      } catch (err) {
        DebuggerConsole.error('Failed marking message as read', err, { tenantId }, { messageId });
      }
      return undefined;
    },
  },
};

export default FeedController;
