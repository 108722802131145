import classNames from 'classnames';
import React from 'react';

import colors from 'global/colors';
import Size from 'global/lists/Size';

import style from 'common-ui-components/Caret/style.module.scss';

const COLORS_MAP: Record<string, string> = {
  blue: colors.BLUE,
  darkGray: colors.DARK_GRAY,
  default: 'currentColor',
};

const caretSizesMap = {
  [Size.SMALL]: 10,
  [Size.MEDIUM]: 15,
  [Size.BIG]: 20,
};

interface Props {
  isOpen: boolean;
  size?: Size;
  color?: keyof typeof COLORS_MAP;
  className?: string;
}

export default function Caret({
  isOpen, size = Size.MEDIUM, color = 'default', className,
}: Props) {
  return (
    <div className={classNames(style.caret, isOpen && style.open, className)}>
      <svg
        height={caretSizesMap[size]}
        width={caretSizesMap[size]}
        viewBox="0 0 12 12"
        aria-hidden="true"
        focusable="false"
      >
        <path stroke={COLORS_MAP[color]} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" d="M2 4L6 8L10 4" fill="none" />
      </svg>
    </div>
  );
}
