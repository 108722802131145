import React from 'react';

import LazyLoad from 'common-ui-components/LazyLoad';
import OptionalButton from 'common-ui-components/OptionalButton';
import TimelineEvent from 'model/Events/Event';
import { usePlatformContext } from 'screens/platform/cross-platform-components/context/platform/PlatformContext';
import EventActionText from 'screens/platform/cross-platform-components/EventsPanel/EventCell/EventActionText';
import useEventsDescription from 'screens/platform/cross-platform-components/EventsPanel/EventCell/EventCellUtils';
import useEventClickHandler from 'screens/platform/cross-platform-components/EventsPanel/EventCell/EventClickHandlerHook';

import style from 'screens/platform/cross-platform-components/EventsPanel/EventCell/style.module.scss';

interface Props {
  event: TimelineEvent;
  titleAttributeText?: string;
}

// We don't need the exact height. Lazy loading works fine with approximation.
const EVENT_CELL_APPROXIMATE_HEIGHT = 170;

export default function EventCell({ event, titleAttributeText }: Props) {
  const { eventsDrawer: { setEventsDrawerOpen } } = usePlatformContext();
  const eventsDescription = useEventsDescription(event);
  const handleEventClick = useEventClickHandler(event);
  const onEventClick = handleEventClick !== null
    ? () => {
      setEventsDrawerOpen(false);
      handleEventClick();
    }
    : undefined;
  if (!eventsDescription) return null;

  const { type, title, subTitle } = eventsDescription;
  return (
    <LazyLoad approximateHeight={EVENT_CELL_APPROXIMATE_HEIGHT}>
      <div className={style.container}>
        <div className={style.card}>
          <div className={style.type}>{type}</div>
          <OptionalButton
            className={style.title}
            onClick={onEventClick}
            title={titleAttributeText}
          >
            {title}
          </OptionalButton>
          <div className={style.subTitle}>{subTitle}</div>
        </div>
        <div className={style.footer}>
          <EventActionText event={event} />
        </div>
      </div>
    </LazyLoad>
  );
}
