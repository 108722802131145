import React from 'react';

import style from 'screens/platform/directory/components/metricValue/MetricDateDisplay/style.module.scss';

interface Props {
  timeAgoLabel: string;
  dateLabel: string;
}

export default function MetricDateDisplay({ timeAgoLabel, dateLabel }: Props) {
  return (
    <div>
      <div>{timeAgoLabel}</div>
      <div className={style.dateSubtitle}>{dateLabel}</div>
    </div>
  );
}
