import classNames from 'classnames';
import React, {
  FC, forwardRef, useEffect, useState,
} from 'react';

import style from 'common-ui-components/Input/style.module.scss';

export interface InputProps {
  value?: string;
  setValue: (value: string) => void;
  placeholder: string;
  onClickEnter?: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  pattern?: RegExp;
  transparent?: boolean;
  className?: string;
  leftIcon?: FC<{ isFocused: boolean }>;
  maxLength?: number;
  type?: 'text' | 'number';
}

const Input = forwardRef<HTMLInputElement, InputProps>(({
  value: injectedValue,
  setValue: setInjectedValue,
  placeholder,
  onClickEnter,
  onFocus,
  onBlur,
  pattern,
  transparent,
  className,
  leftIcon: LeftIcon,
  maxLength,
  type = 'text',
}: InputProps, ref) => {
  const [value, setValue] = useState(injectedValue || '');
  const [isFocused, setFocused] = useState(false);

  useEffect(() => {
    if (injectedValue !== undefined) setValue(injectedValue);
  }, [injectedValue]);

  function handleChange(e) {
    const currValue = e.target.value;
    if (pattern === undefined || pattern.test(currValue)) {
      setValue(currValue);
      setInjectedValue(currValue);
    }
  }

  function onKeyDown(e) {
    if (onClickEnter && e.key === 'Enter') {
      onClickEnter(value);
    }
  }

  function handleFocus() {
    setFocused(true);
    if (onFocus) onFocus();
  }

  function handleBlur() {
    setFocused(false);
    if (onBlur) {
      onBlur();
    }
    if (injectedValue) {
      setValue(injectedValue);
    }
  }

  return (
    <div className={classNames(style.inputWrapper, className)}>
      {LeftIcon && <LeftIcon isFocused={isFocused} />}
      <input
        type={type}
        value={value}
        className={classNames({
          [style.transparent]: transparent,
          [style.paddedLeft]: Boolean(LeftIcon),
        })}
        placeholder={placeholder}
        onChange={handleChange}
        onKeyDown={onKeyDown}
        onFocus={handleFocus}
        onBlur={handleBlur}
        maxLength={maxLength}
        ref={ref}
        spellCheck={false}
      />
    </div>
  );
});

export default Input;
