import React, { useEffect, useRef } from 'react';

import Size from 'global/lists/Size';
import { BubbleShape } from 'screens/platform/contentScreens/AnalyticsScreen/widgets/EventsChart/BubbleChart';
import {
  drawCircle, drawDiamond, drawSquare, drawTriangle,
} from 'screens/platform/contentScreens/AnalyticsScreen/widgets/EventsChart/BubbleChart/BubbleChartUtils';

interface Props {
    shape: BubbleShape;
    size: Size;
    color: string;
}

const shapeSizes = {
  [Size.SMALL]: 16,
  [Size.MEDIUM]: 20,
  [Size.BIG]: 24,
};

export default function TooltipShape({ size, shape, color }: Props) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const shapeSize = shapeSizes[size];

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      canvas.width = shapeSize;
      canvas.height = shapeSize;
    }
  }, [size]);

  useEffect(() => {
    function draw(canvas: HTMLCanvasElement) {
      const context = canvas.getContext('2d');
      const centerCoordinate = canvas.width / 2;
      if (!context) return;
      switch (shape) {
        case BubbleShape.CIRCLE:
          drawCircle(context, centerCoordinate, centerCoordinate, shapeSize, color);
          break;
        case BubbleShape.DIAMOND:
          drawDiamond(context, centerCoordinate, centerCoordinate, shapeSize, color);
          break;
        case BubbleShape.SQUARE:
          drawSquare(context, centerCoordinate, centerCoordinate, shapeSize, color);
          break;
        case BubbleShape.TRIANGLE:
          drawTriangle(context, centerCoordinate, centerCoordinate, shapeSize, color);
          break;
        default:
          break;
      }
    }

    const canvas = canvasRef.current;
    if (canvas) {
      draw(canvas);
    }
  }, [color]);

  return <canvas width={shapeSize} height={shapeSize} ref={canvasRef} />;
}
