import { useEffect, useMemo } from 'react';

import EventCategory, { categoryToEventTypes } from 'global/lists/EventCategory';
import EventResponse, { EventType } from 'model/Events/Event';
import useEventsFetcher from 'screens/platform/contentScreens/AnalyticsScreen/utils/EventsFetcherHook';
import useLatestDataQuery from 'screens/platform/contentScreens/AnalyticsScreen/utils/LatestDataQueryHook';
import useWidgetConfig from 'screens/platform/contentScreens/AnalyticsScreen/utils/WidgetConfigHook';
import { WidgetConfig, WidgetType } from 'screens/platform/contentScreens/AnalyticsScreen/widgets/widgetConfig';
import {
  useCustomEventsFilters,
} from 'screens/platform/cross-platform-components/context/CustomFilters/CustomEventsFilters/CustomEventsFiltersContextProvider';
import {
  useMasterFilters,
} from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFiltersContext';
import {
  defaultAnalyticsScreenSettings,
} from 'screens/platform/cross-platform-components/context/platform/PlatformContextProvider';
import useEventsCustomMasterFilters from 'screens/platform/cross-platform-components/EventsPanel/EventsCustomMasterFiltersHook';
import { useMountedState } from 'utils/hooks';

export default function useFilteredEvents(): EventResponse[] | null {
  const [events, setEvents] = useMountedState<EventResponse[] | null>(null);
  const { fetchEvents } = useEventsFetcher();
  const { customEventsFilters } = useCustomEventsFilters();
  const isLatestCustomEventsFilters = useLatestDataQuery([customEventsFilters]);
  const { currentFilters } = useMasterFilters();
  const buildCustomMasterFilters = useEventsCustomMasterFilters(currentFilters);
  const { widgetConfig } = useWidgetConfig(WidgetType.EVENT);

  useEffect(() => {
    async function initEvents() {
      setEvents(null);
      const currentCustomEventsFilters = { ...customEventsFilters };
      const customMasterFilters = buildCustomMasterFilters(customEventsFilters);
      const timelineEventTypes = getEventsTypes(customEventsFilters.eventsCategories);
      const nextEvents = await fetchEvents(timelineEventTypes, customMasterFilters);
      if (isLatestCustomEventsFilters([currentCustomEventsFilters])) {
        setEvents(nextEvents);
      }
    }
    initEvents();
  }, [customEventsFilters, currentFilters.version]);

  return useMemo(
    () => (events ? filterEvents(events, widgetConfig) : null),
    [events, widgetConfig.settings.significanceThreshold],
  );
}

function filterEvents(
  events: EventResponse[],
  widgetConfig: WidgetConfig,
): EventResponse[] {
  const { settings: { significanceThreshold } } = widgetConfig;
  const defaultThreshold = defaultAnalyticsScreenSettings.significanceThreshold;
  const threshold = 100 - (significanceThreshold || defaultThreshold);
  return events.filter(({ significance }) => significance >= threshold);
}

function getEventsTypes(eventsCategories: EventCategory[] | undefined): EventType[] | null {
  if (!eventsCategories) {
    return null;
  }
  const timelineEventTypes = eventsCategories
    .map((category) => categoryToEventTypes[category])
    .flat();
  return [...new Set(timelineEventTypes)];
}
