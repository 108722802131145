import {
  deleteRequest, getRequest, HttpResponseResult, postRequest,
} from 'global/api/platformApiHelpers';
import FollowedInterest, { Interest } from 'model/FollowedInterest';
import Tenant from 'model/Tenant';
import DebuggerConsole from 'utils/DebuggerConsole';

export interface PagedResponse {
  items: FollowedInterest[];
  totalCount: number;
}

const followedInterestController = {
  async getFollowedInterest(tenantId: Tenant['id'], skip: number, limit: number, type: Interest['type']): Promise<PagedResponse> {
    try {
      const response: HttpResponseResult<PagedResponse> = await getRequest('person/follow', {
        tenantId, limit, skip, type,
      });
      if (response?.data) {
        return response.data;
      }
    } catch (err) {
      DebuggerConsole.error('Failed fetching followed interest', err, { tenantId });
    }
    return {
      items: [],
      totalCount: 0,
    };
  },
  async follow(tenantId: Tenant['id'], interest: Interest): Promise<FollowedInterest | null> {
    try {
      const response: HttpResponseResult<FollowedInterest> = await postRequest('person/follow', { interest }, { params: { tenantId } });
      if (response?.data) {
        return response.data;
      }
    } catch (err) {
      DebuggerConsole.error('Failed follow', err, { tenantId, interest });
    }
    return null;
  },
  async unfollow(tenantId: Tenant['id'], interestId: String): Promise<FollowedInterest | null> {
    try {
      const response: HttpResponseResult<FollowedInterest> = await deleteRequest(`person/unfollow/${interestId}`, { tenantId });
      if (response?.data) {
        return response.data;
      }
    } catch (err) {
      DebuggerConsole.error('Failed unfollow', err, { tenantId, interestId });
    }
    return null;
  },
};

export default followedInterestController;
