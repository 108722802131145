import Channel from 'model/Channel';
import Organization from 'model/Organization';
import Person from 'model/Person';
import {
  useMetadataContext,
} from 'screens/platform/cross-platform-components/context/metadata/MetadataContext';
import { interactionsTableColumnLabels } from 'screens/platform/cross-platform-components/Interactions/components/InteractionsTable/columnsConfigUtils';
import {
  DirectionalityLabels,
} from 'screens/platform/cross-platform-components/MasterFiltersPanel/FilterSection/DirectionalityFilterSection';
import { getOrganizationsTableColumnLabel } from 'screens/platform/directory/organizations/OrganizationsDirectoryScreen/OrganizationsTable/utils/organizationsTableColumnsConfig';
import {
  CUSTOM_PERSON_PROPERTIES_PREFIX,
} from 'screens/platform/directory/people/PeopleDirectoryScreen/PeopleTable/utils/CustomPersonPropertiesColumnsHook';

export enum HierarchicalItemType {
  PERSON = 'PERSON',
  CHANNEL = 'CHANNEL',
  TAG = 'TAG',
  ORGANIZATION = 'ORGANIZATION',
  DIRECTIONALITY = 'DIRECTIONALITY',
  INTERACTIONS_TABLE_COLUMN = 'INTERACTIONS_TABLE_COLUMN',
  ORGANIZATIONS_TABLE_COLUMN = 'ORGANIZATIONS_TABLE_COLUMN',
  PEOPLE_DIRECTORY_TABLE_COLUMN = 'PEOPLE_DIRECTORY_TABLE_COLUMN',
  TOPICS_DIRECTORY_TABLE_COLUMN = 'TOPICS_DIRECTORY_TABLE_COLUMN',
}

interface HierarchicalItemTypeConfig<T = Channel | Person | Organization | string> {
  getItemLabel: (itemId: string) => Promise<string> | string;
  getItem: (itemId: string) => Promise<T | undefined> | T;
}

export function useHierarchicalItemTypeImplementations(
  type: HierarchicalItemType,
): HierarchicalItemTypeConfig {
  const { persons, organizations, channels } = useMetadataContext();
  const hierarchicalItemTypeImplementations: Record<
    HierarchicalItemType,
    HierarchicalItemTypeConfig
  > = {
    [HierarchicalItemType.PERSON]: {
      getItemLabel: async (personId) => {
        const { name, isActive } = (await persons.getById(personId)) || { name: '-', isActive: false };
        return `${name}${isActive ? '' : ' (Inactive)'}`;
      },
      getItem: (personId) => persons.getById(personId),
    },
    [HierarchicalItemType.DIRECTIONALITY]: {
      getItemLabel: (directionalityEnumValue) => DirectionalityLabels[directionalityEnumValue],
      getItem: identity,
    },
    [HierarchicalItemType.ORGANIZATION]: {
      getItemLabel: async (orgId) => (await organizations.getDisplayNameById(orgId)) ?? orgId,
      getItem: (orgId) => organizations.getById(orgId),
    },
    [HierarchicalItemType.CHANNEL]: {
      getItemLabel: async (channelId) => (await channels.getById(channelId))?.name ?? channelId,
      getItem: (channelId) => channels.getById(channelId),
    },
    [HierarchicalItemType.TAG]: {
      getItemLabel: identity,
      getItem: identity,
    },
    [HierarchicalItemType.INTERACTIONS_TABLE_COLUMN]: {
      getItemLabel: (column) => interactionsTableColumnLabels[column],
      getItem: identity,
    },
    [HierarchicalItemType.ORGANIZATIONS_TABLE_COLUMN]: {
      getItemLabel: (columnId) => getOrganizationsTableColumnLabel(columnId),
      getItem: identity,
    },
    [HierarchicalItemType.PEOPLE_DIRECTORY_TABLE_COLUMN]: {
      getItemLabel: (columnId) => columnId.replace(CUSTOM_PERSON_PROPERTIES_PREFIX, ''),
      getItem: identity,
    },
    [HierarchicalItemType.TOPICS_DIRECTORY_TABLE_COLUMN]: {
      getItemLabel: identity,
      getItem: identity,
    },
  };

  return hierarchicalItemTypeImplementations[type];
}

function identity(toReturn: any) {
  return toReturn;
}
