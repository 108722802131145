import React from 'react';

import ExternalLink from 'common-ui-components/ExternalLink';
import Organization from 'model/Organization';

import style from 'common-ui-components/OrganizationDomain/style.module.scss';

type Props = {
  organization: Organization;
};

export default function OrganizationDomain({ organization }: Props) {
  return (
    <ExternalLink
      className={style.organizationDomain}
      to={`https://${organization.domain}`}
    >
      {organization.domain}
    </ExternalLink>
  );
}
