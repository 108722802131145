import { createContext, useContext } from 'react';

import { OrganizationRisk, OrganizationSize } from 'model/Organization';
import OrganizationMetadata from 'screens/platform/cross-platform-components/context/OrganizationsMetadata/dataStructures/OrganizationMetadata';

export type OrganizationsMetadata = {
  allOrganizationsMetadata: Readonly<
    Partial<Record<string, OrganizationMetadata>>
  >;
  risksToOrganizationsMap: Readonly<
    Partial<
      Record<OrganizationRisk, string[]> & { UNDEFINED_PROPERTY: string[] }
    >
  >;
  sizesToOrganizationsMap: Readonly<
    Partial<
      Record<OrganizationSize, string[]> & { UNDEFINED_PROPERTY: string[] }
    >
  >;
  segmentsToOrganizationsMap: Readonly<
    Partial<Record<string, string[]> & { UNDEFINED_PROPERTY: string[] }>
  >;
};

type OrganizationsMetadataContextType = OrganizationsMetadata & {
  loading: boolean;
};

export const useOrganizationsMetadataContext: () => OrganizationsMetadataContextType = () =>
  useContext<OrganizationsMetadataContextType>(OrganizationsMetadataContext);

const OrganizationsMetadataContext = createContext<OrganizationsMetadataContextType>({} as any);
export default OrganizationsMetadataContext;
