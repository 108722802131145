import { differenceInHours, formatDistanceStrict } from 'date-fns';
import React, { useMemo } from 'react';

import AppIcon from 'common-ui-components/AppIcon';
import InteractionTitle from 'common-ui-components/InteractionTitle';
import MultiOrganizationsImages from 'common-ui-components/MultiImages/MultiOrganizationsImages';
import MultiPersonsImages from 'common-ui-components/MultiImages/MultiPersonsImages';
import { TableColumn } from 'common-ui-components/Table';
import { SortingConfig } from 'common-ui-components/Table/sortingHook';
import { TableColumnsOrder } from 'common-ui-components/Table/TableTypes';
import App from 'global/lists/apps';
import Metric, { getMetricDisplayName } from 'global/lists/Metric';
import Size from 'global/lists/Size';
import { Atom } from 'model/Atom';
import useOrganizationFeatureConfig
  from 'screens/platform/cross-platform-components/context/configuration/OrganizationFeatureConfigHook';
import { useMetadataContext } from 'screens/platform/cross-platform-components/context/metadata/MetadataContext';
import AdditionalLinks
  from 'screens/platform/cross-platform-components/Interactions/components/InteractionsTable/components/AdditionalLinks';
import AtomOrientationBadge
  from 'screens/platform/cross-platform-components/Interactions/components/InteractionsTable/components/AtomOrientationBadge';
import InteractionCategories
  from 'screens/platform/cross-platform-components/Interactions/components/InteractionsTable/components/InteractionCategories';
import InteractionDepartments
  from 'screens/platform/cross-platform-components/Interactions/components/InteractionsTable/components/InteractionDepartments';
import InteractionPersonsImages, {
  PersonType,
} from 'screens/platform/cross-platform-components/Interactions/components/InteractionsTable/components/InteractionPersonsImages';
import InteractionTags
  from 'screens/platform/cross-platform-components/Interactions/components/InteractionsTable/components/InteractionTags';
import LinkedInteractions
  from 'screens/platform/cross-platform-components/Interactions/components/InteractionsTable/components/LinkedInteractions';
import SignificanceBadge
  from 'screens/platform/cross-platform-components/Interactions/components/InteractionsTable/components/SignificanceBadge';
import VolumeBadge
  from 'screens/platform/cross-platform-components/Interactions/components/InteractionsTable/components/VolumeBadge';
import InteractionsTableColumn
  from 'screens/platform/cross-platform-components/Interactions/components/InteractionsTable/InteractionsTableColumn';
import { getFormattedDate } from 'utils/DateUtils';

const metricColumnsToMetric: Partial<Record<InteractionsTableColumn, Metric>> = {
  [InteractionsTableColumn.SIGNIFICANCE]: Metric.POTENTIAL_SIGNIFICANCE,
  [InteractionsTableColumn.VOLUME]: Metric.RELATIVE_VOLUME,
  [InteractionsTableColumn.LIFETIME]: Metric.LIFETIME,
  [InteractionsTableColumn.TOTAL_PARTICIPANTS]: Metric.TOTAL_PARTICIPANTS,
};

function interactionsTableColumnsFilter(
  column: InteractionsTableColumn,
  isOrganizationsFeatureEnabled: boolean,
  isMetricEnabled: (metric: Metric, appId: App) => boolean,
) {
  return (column !== InteractionsTableColumn.ORGANIZATIONS
    || isOrganizationsFeatureEnabled)
  && (!metricColumnsToMetric[column]
    || isMetricEnabled(metricColumnsToMetric[column]!, App.AKOODA));
}

export const interactionsTableColumnLabels: Record<InteractionsTableColumn, string> = {
  [InteractionsTableColumn.APP]: 'App',
  [InteractionsTableColumn.TITLE]: 'Title',
  [InteractionsTableColumn.STARTED_AT]: 'Started At',
  [InteractionsTableColumn.UPDATED_AT]: 'Updated At',
  [InteractionsTableColumn.TOPICS]: 'Topics',
  [InteractionsTableColumn.TAGS]: 'Tags',
  [InteractionsTableColumn.CATEGORIES]: 'Categories',
  [InteractionsTableColumn.PEOPLE]: 'People',
  [InteractionsTableColumn.ORGANIZATIONS]: 'Organizations',
  [InteractionsTableColumn.CONTACTS]: 'Contacts',
  [InteractionsTableColumn.INITIATOR]: 'Initiator',
  [InteractionsTableColumn.DIRECTIONALITY]: 'Directionality',
  [InteractionsTableColumn.DEPARTMENTS]: 'Departments',
  [InteractionsTableColumn.MENTIONS]: 'Mentions',
  [InteractionsTableColumn.SIGNIFICANCE]: getMetricDisplayName(Metric.POTENTIAL_SIGNIFICANCE),
  [InteractionsTableColumn.VOLUME]: getMetricDisplayName(Metric.RELATIVE_VOLUME),
  [InteractionsTableColumn.LIFETIME]: getMetricDisplayName(Metric.LIFETIME),
  [InteractionsTableColumn.TOTAL_PARTICIPANTS]: getMetricDisplayName(Metric.TOTAL_PARTICIPANTS),
  [InteractionsTableColumn.REFERENCES]: 'References',
  [InteractionsTableColumn.BACKLINKS]: 'Backlinks',
  [InteractionsTableColumn.ADDITIONAL_LINKS]: 'Additional Links',
};

export const columnToElasticAtomFieldName: Partial<Record<InteractionsTableColumn, string>> = {
  // [InteractionsTableColumn.TITLE]: 'data.title.keyword',
  [InteractionsTableColumn.STARTED_AT]: 'data.start',
  [InteractionsTableColumn.UPDATED_AT]: 'data.end',
  [InteractionsTableColumn.SIGNIFICANCE]: 'data.significance.score',
  [InteractionsTableColumn.VOLUME]: 'data.volume',
  [InteractionsTableColumn.TOTAL_PARTICIPANTS]: 'participants',
};

export type InteractionsTableColumnsOrder = TableColumnsOrder<InteractionsTableColumn>;

const defaultInteractionsTableColumns: InteractionsTableColumn[] = [
  InteractionsTableColumn.APP,
  InteractionsTableColumn.TITLE,
  InteractionsTableColumn.UPDATED_AT,
  InteractionsTableColumn.DIRECTIONALITY,
  InteractionsTableColumn.TOPICS,
  InteractionsTableColumn.DEPARTMENTS,
  InteractionsTableColumn.PEOPLE,
  InteractionsTableColumn.ORGANIZATIONS,
  InteractionsTableColumn.CONTACTS,
  InteractionsTableColumn.TOTAL_PARTICIPANTS,
];

export function useDefaultInteractionsTableColumns(): InteractionsTableColumnsOrder {
  const { isMetricEnabled } = useMetadataContext();
  const { value: isOrganizationsFeatureEnabled } = useOrganizationFeatureConfig();
  return useMemo(() => Object.fromEntries(
    defaultInteractionsTableColumns
      .filter((column) =>
        interactionsTableColumnsFilter(
          column,
          isOrganizationsFeatureEnabled,
          isMetricEnabled,
        ))
      .map((column, index) => [column, index]),
  ), [isMetricEnabled, isOrganizationsFeatureEnabled]);
}

export function useColumnsByGroups(): Record<string, InteractionsTableColumn[]> {
  const { isMetricEnabled } = useMetadataContext();
  const { value: isOrganizationsFeatureEnabled } = useOrganizationFeatureConfig();

  const metricColumns: InteractionsTableColumn[] = Object.entries(metricColumnsToMetric)
    .filter(([_, metric]) => isMetricEnabled(metric))
    .map(([column]) => column as InteractionsTableColumn);

  return useMemo(() => ({
    Interactions: [
      InteractionsTableColumn.APP,
      InteractionsTableColumn.TITLE,
      InteractionsTableColumn.STARTED_AT,
      InteractionsTableColumn.UPDATED_AT,
      InteractionsTableColumn.DIRECTIONALITY,
    ],
    Context: [
      InteractionsTableColumn.TOPICS,
      InteractionsTableColumn.TAGS,
      InteractionsTableColumn.CATEGORIES,
      InteractionsTableColumn.MENTIONS,
    ],
    Participants: [
      InteractionsTableColumn.PEOPLE,
      ...(isOrganizationsFeatureEnabled ? [InteractionsTableColumn.ORGANIZATIONS] : []),
      InteractionsTableColumn.DEPARTMENTS,
      InteractionsTableColumn.CONTACTS,
      InteractionsTableColumn.INITIATOR,
    ],
    Links: [
      InteractionsTableColumn.REFERENCES,
      InteractionsTableColumn.BACKLINKS,
      InteractionsTableColumn.ADDITIONAL_LINKS,
    ],
    ...(metricColumns.length > 0 ? { Metrics: metricColumns } : {}),
  }), [isOrganizationsFeatureEnabled, isMetricEnabled]);
}

export const defaultSorting: SortingConfig<string> = {
  columnKey: columnToElasticAtomFieldName[InteractionsTableColumn.UPDATED_AT]!,
  order: 'descend',
};

const allColumns: Record<InteractionsTableColumn, TableColumn<Atom, InteractionsTableColumn>> = {
  [InteractionsTableColumn.APP]: {
    title: interactionsTableColumnLabels[InteractionsTableColumn.APP],
    key: InteractionsTableColumn.APP,
    width: 60,
    render: (_, { appId, isPublic, mimeType }) => (
      <AppIcon appId={appId} isPublic={isPublic} mimeType={mimeType} />
    ),
  },
  [InteractionsTableColumn.TITLE]: {
    title: interactionsTableColumnLabels[InteractionsTableColumn.TITLE],
    key: InteractionsTableColumn.TITLE,
    minWidth: 340,
    ellipsis: true,
    // TODO: this will be added in future PR after a fix for the backend
    // key: columnToElasticAtomFiledName[InteractionsTableColumn.TITLE],
    // sorter: true,
    // sortDirections: ['ascend', 'descend'],
    render: (_, atom) => (
      <InteractionTitle atom={atom} options={{ clickable: true }} />
    ),
  },
  [InteractionsTableColumn.STARTED_AT]: {
    title: interactionsTableColumnLabels[InteractionsTableColumn.STARTED_AT],
    key: InteractionsTableColumn.STARTED_AT,
    width: 150,
    sorting: { byKey: true },
    render: (_, { start }) => getFormattedDate(start),
  },
  [InteractionsTableColumn.UPDATED_AT]: {
    title: interactionsTableColumnLabels[InteractionsTableColumn.UPDATED_AT],
    key: InteractionsTableColumn.UPDATED_AT,
    width: 150,
    sorting: { byKey: true },
    render: (_, { end }) => getFormattedDate(end),
  },
  [InteractionsTableColumn.LIFETIME]: {
    title: interactionsTableColumnLabels[InteractionsTableColumn.LIFETIME],
    key: InteractionsTableColumn.LIFETIME,
    width: 140,
    render: (_, { start, end }) => {
      const startDate = new Date(start);
      const endDate = new Date(end);
      if (differenceInHours(endDate, startDate) < 24) {
        return '1 day';
      }
      return formatDistanceStrict(endDate, startDate);
    },
  },
  [InteractionsTableColumn.TOPICS]: {
    title: interactionsTableColumnLabels[InteractionsTableColumn.TOPICS],
    infoTooltip: 'Topics are selected tags that represent the main share of attention of the company and enabling the delivery of insights on crucial matters.',
    key: InteractionsTableColumn.TOPICS,
    width: 270,
    render: (_, atom) => <InteractionTags atom={atom} topicsOnly />,
  },
  [InteractionsTableColumn.TAGS]: {
    title: interactionsTableColumnLabels[InteractionsTableColumn.TAGS],
    infoTooltip: 'Tags are specific keywords or labels that provide a detailed, organized breakdown, making it easier to navigate and understand the various aspects of your company\'s operations.',
    key: InteractionsTableColumn.TAGS,
    width: 270,
    render: (_, atom) => <InteractionTags atom={atom} />,
  },
  [InteractionsTableColumn.CATEGORIES]: {
    title: interactionsTableColumnLabels[InteractionsTableColumn.CATEGORIES],
    infoTooltip: 'Tags can be associated with categories, allowing for data analysis on specific criteria and around common themes.',
    key: InteractionsTableColumn.CATEGORIES,
    width: 270,
    render: (_, { tags }) => <InteractionCategories tags={tags} />,
  },
  [InteractionsTableColumn.PEOPLE]: {
    title: interactionsTableColumnLabels[InteractionsTableColumn.PEOPLE],
    key: InteractionsTableColumn.PEOPLE,
    width: 220,
    render: (_, atom) => <InteractionPersonsImages type={PersonType.INTERNAL} atom={atom} />,
  },
  [InteractionsTableColumn.ORGANIZATIONS]: {
    title: interactionsTableColumnLabels[InteractionsTableColumn.ORGANIZATIONS],
    key: InteractionsTableColumn.ORGANIZATIONS,
    width: 220,
    render: (_, { engagedExternalOrganizations }) => (
      <MultiOrganizationsImages ids={engagedExternalOrganizations} size={Size.BIG} />
    ),
  },
  [InteractionsTableColumn.CONTACTS]: {
    title: interactionsTableColumnLabels[InteractionsTableColumn.CONTACTS],
    key: InteractionsTableColumn.CONTACTS,
    width: 220,
    render: (_, atom) => <InteractionPersonsImages type={PersonType.EXTERNAL} atom={atom} />,
  },
  [InteractionsTableColumn.DEPARTMENTS]: {
    title: interactionsTableColumnLabels[InteractionsTableColumn.DEPARTMENTS],
    key: InteractionsTableColumn.DEPARTMENTS,
    width: 270,
    render: (_, { participants }) => (
      <InteractionDepartments participants={participants} />
    ),
  },
  [InteractionsTableColumn.SIGNIFICANCE]: {
    title: interactionsTableColumnLabels[InteractionsTableColumn.SIGNIFICANCE],
    key: InteractionsTableColumn.SIGNIFICANCE,
    width: 195,
    sorting: { byKey: true },
    render: (_, { significance, relativeSignificance }) => (
      <SignificanceBadge
        significance={significance}
        relativeSignificance={relativeSignificance}
      />
    ),
  },
  [InteractionsTableColumn.VOLUME]: {
    title: interactionsTableColumnLabels[InteractionsTableColumn.VOLUME],
    key: InteractionsTableColumn.VOLUME,
    width: 195,
    sorting: { byKey: true },
    render: (_, { volume, relativeVolume }) => (
      <VolumeBadge volume={volume} relativeVolume={relativeVolume} />
    ),
  },
  [InteractionsTableColumn.TOTAL_PARTICIPANTS]: {
    title: interactionsTableColumnLabels[InteractionsTableColumn.TOTAL_PARTICIPANTS],
    key: InteractionsTableColumn.TOTAL_PARTICIPANTS,
    width: 180,
    sorting: { byKey: true },
    render: (_, { participants }) => Object.keys(participants).length,
  },
  [InteractionsTableColumn.MENTIONS]: {
    title: interactionsTableColumnLabels[InteractionsTableColumn.MENTIONS],
    key: InteractionsTableColumn.MENTIONS,
    width: 195,
    render: (_, { mentionedOrganizations }) => (
      <MultiOrganizationsImages ids={mentionedOrganizations} size={Size.BIG} />
    ),
  },
  [InteractionsTableColumn.REFERENCES]: {
    title: interactionsTableColumnLabels[InteractionsTableColumn.REFERENCES],
    key: InteractionsTableColumn.REFERENCES,
    width: 220,
    render: (_, { references }) => (
      <LinkedInteractions
        interactions={references.links}
        countIncludingNotAllowed={references.countIncludingNotAllowed}
        description="references in the interaction"
      />
    ),
  },
  [InteractionsTableColumn.BACKLINKS]: {
    title: interactionsTableColumnLabels[InteractionsTableColumn.BACKLINKS],
    key: InteractionsTableColumn.BACKLINKS,
    width: 220,
    render: (_, { backlinks }) => (
      <LinkedInteractions
        interactions={backlinks.links}
        countIncludingNotAllowed={backlinks.countIncludingNotAllowed}
        description="backlinks to the interaction"
      />
    ),
  },
  [InteractionsTableColumn.ADDITIONAL_LINKS]: {
    title: interactionsTableColumnLabels[InteractionsTableColumn.ADDITIONAL_LINKS],
    key: InteractionsTableColumn.ADDITIONAL_LINKS,
    width: 220,
    render: (_, { unmappedLinks }) => (
      <AdditionalLinks links={unmappedLinks} />
    ),
  },
  [InteractionsTableColumn.INITIATOR]: {
    title: interactionsTableColumnLabels[InteractionsTableColumn.INITIATOR],
    key: InteractionsTableColumn.INITIATOR,
    width: 100,
    render: (_, { initiatorPersonId }) =>
      (initiatorPersonId ? (
        <MultiPersonsImages ids={[initiatorPersonId]} size={Size.BIG} />
      ) : null),
  },
  [InteractionsTableColumn.DIRECTIONALITY]: {
    title: interactionsTableColumnLabels[InteractionsTableColumn.DIRECTIONALITY],
    key: InteractionsTableColumn.DIRECTIONALITY,
    width: 195,
    render: (_, { orientation }) => (
      <AtomOrientationBadge orientation={orientation} />
    ),
  },
};

export default function useColumnsConfig():
  Partial<Record<InteractionsTableColumn, TableColumn<Atom>>> {
  const { isMetricEnabled } = useMetadataContext();
  const { value: isOrganizationsFeatureEnabled } = useOrganizationFeatureConfig();

  return useMemo(() => {
    const filteredColumnsEntries = Object.entries(allColumns).filter(
      ([column]) =>
        interactionsTableColumnsFilter(
          column as InteractionsTableColumn,
          isOrganizationsFeatureEnabled,
          isMetricEnabled,
        ),
    ).map(([key, column]) => [key, {
      ...column,
      key: columnToElasticAtomFieldName[column.key] ?? column.key,
    }]);
    return Object.fromEntries(filteredColumnsEntries);
  }, [allColumns, isOrganizationsFeatureEnabled, isMetricEnabled]);
}
