import React from 'react';

import InteractionsSummary
  from 'screens/platform/cross-platform-components/Interactions/components/InteractionsSummary';
import InteractionsTable from 'screens/platform/cross-platform-components/Interactions/components/InteractionsTable';
import { useInteractionsContext } from 'screens/platform/cross-platform-components/Interactions/InteractionsContext';

export default function InteractionsContent() {
  const { interactionsMode } = useInteractionsContext();

  return (
    interactionsMode.isSummaryModeAvailable
    && interactionsMode.value === 'summary'
      ? <InteractionsSummary />
      : <InteractionsTable />
  );
}
