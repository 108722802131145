import App, { hasChannels } from 'global/lists/apps';
import Channel from 'model/Channel';
import HierarchicalMasterFilter
  from 'screens/platform/cross-platform-components/context/MasterFiltersContext/HierarchicalMasterFilter';
import {
  isAppIdentified,
  UNIDENTIFIED_APPS_LABEL,
} from 'screens/platform/cross-platform-components/MasterFiltersPanel/FilterSection/AppsFilterSection/AppsFilterSectionUtils';
import {
  isGithubSubDivision,
} from 'screens/platform/cross-platform-components/MasterFiltersPanel/masterFiltersUtils/GithubChannelsUtils';
import {
  isSlackSubDivision,
  SlackSubDivisionId,
} from 'screens/platform/cross-platform-components/MasterFiltersPanel/masterFiltersUtils/SlackChannelsUtils';
import HierarchicalGroup from 'utils/HierarchicalDataStructures/HierarchicalGroup';

const appsWithChannelPoundSignPrefix: App[] = [App.SLACK];

function getChannelLabel(channels: Partial<Record<string, Channel>>, id: string) {
  const channel = channels[id];
  if (channel === undefined) return id;

  const { name } = channel;
  return appsWithChannelPoundSignPrefix.includes(channel.appId)
    ? `#${name}`
    : name;
}

function getSelectedApps(apps: HierarchicalMasterFilter) {
  const { selectedGroups } = apps.getSelectedGroupsAndItems();

  const selectedApps = selectedGroups.reduce<string[]>(
    (acc, appId) => {
      const isSubDivision = isGithubSubDivision(appId) || isSlackSubDivision(appId);
      return isSubDivision ? acc : [...acc, appId];
    },
    [],
  );

  const allSlackSelectedExceptBots = apps.getChildren()
    .filter((child) => isSlackSubDivision(child.id))
    .every((child) => child.id === SlackSubDivisionId.SLACK_BOT_CHANNELS || child.isSelected);

  if (allSlackSelectedExceptBots) {
    selectedApps.push(App.SLACK.toString());
  }

  const githubApps = apps.getChildren()
    .filter((child) => isGithubSubDivision(child.id));
  const allGithubSelected = githubApps.length > 0 && githubApps.every((child) => child.isSelected);

  if (allGithubSelected) {
    selectedApps.push(App.GITHUB.toString());
  }

  return selectedApps;
}

function getSelectedChannelsIds(apps: HierarchicalMasterFilter, selectedApps: string[]): string[] {
  return apps.getChildren()
    .reduce<string[]>((acc, appGroup) => {
      const appId = appGroup.id;
      const shouldIgnoreSlackChannels = selectedApps.includes(App.SLACK.toString())
        && isSlackSubDivision(appId);
      const shouldIgnoreGithubChannels = selectedApps.includes(App.GITHUB.toString())
        && isGithubSubDivision(appId);

      if (!selectedApps.includes(appId)
        && !shouldIgnoreSlackChannels
        && !shouldIgnoreGithubChannels) {
        const appHasChannels = hasChannels(appId)
          || isSlackSubDivision(appId)
          || isGithubSubDivision(appId);

        if (appHasChannels && appGroup.isParent() && !appGroup.isNotSelected()) {
          const channels = appGroup as HierarchicalGroup;
          const selectedChannelsIds = channels.getChildren()
            .filter((channel) => channel.isSelected)
            .map((selectedChannel) => selectedChannel.id);
          return [...acc, ...selectedChannelsIds];
        }
      }

      return acc;
    }, []);
}

function getSelectedEntitiesIncludingUnidentified(
  apps: HierarchicalMasterFilter,
): {
  selectedAppsIds: string[];
  selectedChannelsIds: string[];
} {
  const selectedAppsIds = getSelectedApps(apps);
  const identifiedSelectedApps = selectedAppsIds.filter(isAppIdentified);

  const selectedAppsIdsIncludingUnidentified = identifiedSelectedApps;
  const includeUnidentifiedApps = identifiedSelectedApps.length < selectedAppsIds.length;
  if (includeUnidentifiedApps) {
    selectedAppsIdsIncludingUnidentified.push(UNIDENTIFIED_APPS_LABEL);
  }

  return {
    selectedAppsIds: selectedAppsIdsIncludingUnidentified,
    selectedChannelsIds: getSelectedChannelsIds(apps, selectedAppsIds),
  };
}

const AppsLabelHookUtils = {
  getChannelLabel,
  getSelectedEntitiesIncludingUnidentified,
};

export default AppsLabelHookUtils;
