import classNames from 'classnames';
import React, {
  useEffect, useRef, useState,
} from 'react';

import Button from 'common-ui-components/Button';
import CSSVariableDefiner from 'common-ui-components/CSSVariableDefiner';
import { useResizeListener, useToggle } from 'utils/hooks';

import style from 'common-ui-components/Table/ExpandableCell/style.module.scss';

const DEFAULT_COLLAPSED_HEIGHT = 26;
export const EXTENDED_COLLAPSED_HEIGHT = 28;

type Props = {
  children: React.ReactNode;
  className?: string;
  collapsedHeight?: number;
}

export default function ExpandableCell({
  collapsedHeight = DEFAULT_COLLAPSED_HEIGHT,
  className,
  children,
}: Props) {
  const [isExpanded, toggleExpanded] = useToggle(false);
  const [isOverflowing, setOverflowing] = useState(false);
  const tagsWrapperElementRef = useRef<HTMLDivElement>(null);
  const { ref: resizableRef, width } = useResizeListener();

  useEffect(() => {
    if (tagsWrapperElementRef.current) {
      const { clientHeight, scrollHeight } = tagsWrapperElementRef.current;
      if (clientHeight < scrollHeight) {
        setOverflowing(true);
      } else {
        setOverflowing(false);
      }
    }
  }, [tagsWrapperElementRef, width]);

  const expandedMaxHeight = tagsWrapperElementRef.current?.scrollHeight || collapsedHeight;

  return (
    <div ref={resizableRef} className={classNames(style.expandableWrapper, className)}>
      <div
        ref={tagsWrapperElementRef}
        className={style.expandable}
        style={{
          maxHeight: isExpanded ? expandedMaxHeight : collapsedHeight,
        }}
      >
        {children}
      </div>
      {isOverflowing && (
        <CSSVariableDefiner variables={{ '--collapsed-height': `${collapsedHeight}px` }}>
          <Button
            className={style.ellipsisToggle}
            onClick={toggleExpanded}
          >
            {isExpanded ? 'Show less' : 'Show more'}
          </Button>
        </CSSVariableDefiner>
      )}
    </div>
  );
}
