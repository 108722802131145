import { useMemo } from 'react';

import { ListOptions } from 'global/ListOptions';
import { useMetadataContext } from 'screens/platform/cross-platform-components/context/metadata/MetadataContext';
import FilterConfig, {
  DynamicListOptions,
} from 'screens/platform/directory/components/GenericDirectoryScreen/FiltersList/FilterConfig';

const useAppsFilterConfig = (): FilterConfig<string> => {
  const { connectedApps } = useMetadataContext();

  return useMemo(() => {
    const appsOptions: ListOptions<string> = Object.entries(connectedApps).map(([id, name]) => ({
      value: id,
      label: name,
    }));

    return {
      label: 'Apps',
      options: new DynamicListOptions(appsOptions),
      multiSelect: true,
      removable: false,
      defaultValue: [],
    };
  }, [connectedApps]);
};

export default useAppsFilterConfig;
