import React from 'react';

import MultiPersonsImagesUtils from 'common-ui-components/MultiImages/MultiPersonsImages/MultiPersonsImagesUtils';
import { ImageShape } from 'common-ui-components/MultiImages/NodeImage';
import colors from 'global/colors';
import Size from 'global/lists/Size';
import Person from 'model/Person';
import { EXTERNAL_ORGANIZATIONS_DEPARTMENT } from 'model/Tenant';

const { PersonImage } = MultiPersonsImagesUtils;
type Props = {
  organizationId: string;
};

export default function InteractionUnknownExternalPersonImage({ organizationId }: Props) {
  const unknownExternalPerson: Person = {
    id: `${organizationId}-unknwon-contact-id`,
    name: 'Unknown contact',
    department: EXTERNAL_ORGANIZATIONS_DEPARTMENT,
    teams: [],
    image: '',
    isActive: false,
    organizationId,
    isExternal: true,
  };

  return (
    <PersonImage
      key={unknownExternalPerson.id}
      person={unknownExternalPerson}
      name={unknownExternalPerson.name}
      borderColor={colors.DARK_GRAY}
      shape={ImageShape.SQUARE}
      size={Size.BIG}
      hoverable
    />
  );
}
