import devConfig from 'global/environments/development';
import EnvironmentConfig from 'global/environments/environment';
import stagingConfig from 'global/environments/staging';

const localConfig: EnvironmentConfig = {
  firebaseConfig: devConfig.firebaseConfig,
  apiBaseUrl: 'http://localhost:8081/api/v1',
  appUrl: 'http://localhost:3000',
  segmentWriteKey: stagingConfig.segmentWriteKey,
};

export default localConfig;
