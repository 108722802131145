import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';

import ActionableBadge from 'common-ui-components/ActionableBadge';
import { ALL_INTERACTIONS } from 'global/labels';
import Directionality from 'screens/platform/cross-platform-components/context/MasterFiltersContext/Directionality';
import {
  useMasterFilters,
} from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFiltersContext';
import {
  HierarchicalItemType,
} from 'screens/platform/cross-platform-components/HierarchicalSearchInput/HierarchicalItemTypeConfig';
import FilterMenu from 'screens/platform/cross-platform-components/MasterFiltersPanel/FilterMenu';
import HierarchicalSearchInputDropdown
  from 'screens/platform/cross-platform-components/MasterFiltersPanel/FilterSection/HierarchicalSearchInputDropdown';
import FilterWrapper from 'screens/platform/cross-platform-components/MasterFiltersPanel/FilterWrapper';
import HierarchicalGroup from 'utils/HierarchicalDataStructures/HierarchicalGroup';
import HierarchicalItem from 'utils/HierarchicalDataStructures/HierarchicalItem';
import ObjectUtils from 'utils/ObjectUtils';

export const DirectionalityLabels: Readonly<Record<Directionality, string>> = {
  [Directionality.INTERNAL]: 'Internal',
  [Directionality.EXTERNAL]: 'External',
};

export default function DirectionalityFilterSection() {
  const { currentFilters, dispatchMasterFilters } = useMasterFilters();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const isFirstRender = useRef(true);

  const { directionalityFilter, label } = useMemo(() => {
    const childrenIds = Object.values(Directionality);
    const children = childrenIds.map((id) => new HierarchicalItem(
      id,
      currentFilters.directionality.includes(id),
    ));

    const directionalityFilterLabel = currentFilters.directionality.length === 2
      ? 'Both'
      : DirectionalityLabels[currentFilters.directionality[0]];
    const selection = new HierarchicalGroup(ALL_INTERACTIONS, children, false);

    return {
      directionalityFilter: selection,
      label: directionalityFilterLabel,
    };
  }, [currentFilters.directionality]);

  useEffect(() => {
    if (
      !isDropdownOpen
      && !isFirstRender.current
    ) {
      const nextDirectionality = directionalityFilter.getSelectedItems(true)
        .map((id) => ObjectUtils.parseEnum(Directionality, id, Directionality.INTERNAL));
      dispatchMasterFilters({
        type: 'CHANGE_DIRECTIONALITY',
        payload: { nextDirectionality },
      });
    }
    isFirstRender.current = false;
  }, [isDropdownOpen]);

  return (
    <FilterWrapper filter={FilterMenu.DIRECTIONALITY}>
      <HierarchicalSearchInputDropdown
        selection={directionalityFilter}
        isDropdownOpen={isDropdownOpen}
        setIsDropdownOpen={setIsDropdownOpen}
        type={HierarchicalItemType.DIRECTIONALITY}
        toggleButton={(
          <ActionableBadge
            label={label}
            iconType="edit"
            onClick={() => setIsDropdownOpen((prev) => !prev)}
          />
        )}
      />
    </FilterWrapper>
  );
}
