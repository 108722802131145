import classNames from 'classnames';
import React from 'react';

import { ALL_INTERACTIONS } from 'global/labels';
import { significanceColorsData } from 'global/lists/significanceColors';
import AnalyticsScreenSection from 'screens/platform/contentScreens/AnalyticsScreen/components/AnalyticsScreenSection';
import HeatMapChart from 'screens/platform/contentScreens/AnalyticsScreen/components/HeatMapChart';
import WidgetHeader from 'screens/platform/contentScreens/AnalyticsScreen/components/WidgetHeader';
import useTimeSeriesChartLabels from 'screens/platform/contentScreens/AnalyticsScreen/utils/TimeSeriesChartLabelsHook/TimeSeriesChartLabelsHook';
import useWidgetConfig from 'screens/platform/contentScreens/AnalyticsScreen/utils/WidgetConfigHook';
import useSignificanceTableDataFetcher
  from 'screens/platform/contentScreens/AnalyticsScreen/widgets/TimelineSignificanceTable/DataFetchingHook';
import significanceRecipes
  from 'screens/platform/contentScreens/AnalyticsScreen/widgets/TimelineSignificanceTable/significanceRecipes';
import { DateRangeGranularity, WidgetType } from 'screens/platform/contentScreens/AnalyticsScreen/widgets/widgetConfig';
import ChartStatesHandler from 'screens/platform/cross-platform-components/ChartStatesHandler';
import { useCustomAtomsFilters } from 'screens/platform/cross-platform-components/context/CustomFilters/CustomAtomsFilters/CustomAtomsFiltersContextProvider';
import StringUtils from 'utils/StringUtils';

import style from 'screens/platform/contentScreens/AnalyticsScreen/widgets/TimelineSignificanceTable/style.module.scss';

const defaultGranularity = DateRangeGranularity.DAY;

export default function TimelineSignificanceTable() {
  const { widgetConfig } = useWidgetConfig(WidgetType.SIGNIFICANCE);
  const granularity = widgetConfig.granularity || defaultGranularity;

  const { data, isLoading, isNetworkError } = useSignificanceTableDataFetcher(granularity);
  const { dispatchCustomAtomsFilters } = useCustomAtomsFilters();

  const {
    ref: significanceTableWrapper,
    labels,
  } = useTimeSeriesChartLabels({ granularity, isTableChart: true });

  const showEmptyState = data[0]?.data.length === 0;

  const onCellClick = (cell) => {
    const { data: { datesRange: { from, to } } } = cell;
    dispatchCustomAtomsFilters({
      type: 'SET_FILTERS',
      payload: { datesRange: { from, to } },
    });
  };

  return (
    <AnalyticsScreenSection>
      <WidgetHeader
        type={WidgetType.SIGNIFICANCE}
        recipes={significanceRecipes}
      />
      <div
        ref={significanceTableWrapper}
        className={classNames(style.significanceTableWrapper, showEmptyState && style.emptyChart)}
      >
        <ChartStatesHandler
          loading={isLoading}
          emptyData={showEmptyState}
          isNetworkError={isNetworkError}
        >
          <HeatMapChart
            data={data}
            granularity={granularity}
            onCellClick={onCellClick}
            valueFormat={() => ''}
            colors={(cellData) => significanceColorsData[cellData.data.y].color}
            getLabel={() => ALL_INTERACTIONS}
            getValueLabel={(valueLabel) => {
              const significanceLabel = significanceColorsData[valueLabel.cell.value].label;
              return `${StringUtils.capitalize(significanceLabel)}`;
            }}
            labels={labels}
          />
        </ChartStatesHandler>
      </div>
    </AnalyticsScreenSection>
  );
}
