import React, { useEffect } from 'react';

import EmptyResultsButton from 'common-ui-components/EmptyResultsButton';
import Spinner from 'common-ui-components/Spinner';
import Analytics, { AnalyticsEvent } from 'global/Analytics';
import HierarchicalSearchResults from 'screens/platform/cross-platform-components/HierarchicalSearchInput/components/HierarchicalSearchResults';
import useHierarchicalItemsSearch from 'screens/platform/cross-platform-components/HierarchicalSearchInput/HierarchicalItemsSearchHook';
import { HierarchicalItemType } from 'screens/platform/cross-platform-components/HierarchicalSearchInput/HierarchicalItemTypeConfig';
import useHierarchicalSearchInput from 'screens/platform/cross-platform-components/HierarchicalSearchInput/HierarchicalSearchInputHook';
import HierarchicalSearchInputWrapper from 'screens/platform/cross-platform-components/HierarchicalSearchInput/HierarchicalSearchInputWrapper';
import useTagRequest from 'screens/platform/cross-platform-components/Hook/TagRequestHook';
import ArrayUtils from 'utils/ArrayUtils';
import HierarchicalGroup from 'utils/HierarchicalDataStructures/HierarchicalGroup';
import { useDebouncedString } from 'utils/hooks';

import { ReactComponent as TagIcon } from 'assets/img/icon/tag.svg';

import style from 'screens/platform/cross-platform-components/MasterFiltersPanel/FilterSection/TagsHierarchicalSelection/style.module.scss';

interface Props {
  selection: HierarchicalGroup;
  onClickDone: () => void;
  searchPlaceholder?: string;
  className?: string;
  shouldClearSearchInput?: boolean;
  allowEmptySelection?: boolean;
}

export default function TagsHierarchicalSelection({
  selection,
  searchPlaceholder = 'Search',
  onClickDone,
  className,
  shouldClearSearchInput,
  allowEmptySelection = false,
}: Props) {
  const [searchQuery, setSearchQuery] = useDebouncedString('');
  const itemsByGroups = useHierarchicalItemsSearch(HierarchicalItemType.TAG, searchQuery);
  const { handleTagRequest } = useTagRequest();

  const onTagRequestClick = () => {
    handleTagRequest(searchQuery, 'Tags Master Filter Search');
    onClickDone();
  };

  const {
    isDisabled, emptySelectionHandler, searchInputRef,
  } = useHierarchicalSearchInput({
    isSelectionEmpty: selection.isNotSelected(),
    allowEmptySelection,
    shouldClearSearchInput,
    setSearchQuery,
  });

  const isLoading = itemsByGroups === null;

  const isEmpty = Boolean(itemsByGroups && ArrayUtils.isEmpty(itemsByGroups));

  useEffect(() => {
    if (isEmpty) {
      Analytics.trackEvent(AnalyticsEvent.TAG_SEARCH_NO_RESULTS, { keyword: searchQuery, location: 'Tags Master Filter Search' });
    }
  }, [itemsByGroups]);

  return (
    <HierarchicalSearchInputWrapper
      onClickDone={onClickDone}
      setSearchQuery={setSearchQuery}
      searchPlaceholder={searchPlaceholder}
      disabled={isDisabled}
      className={className}
      ref={searchInputRef}
      customAction={isEmpty
        ? (
          <div className={style.button}>
            <EmptyResultsButton
              onClick={onTagRequestClick}
              title="Add as a tag"
              subtitle="Request admins to tag keywords"
              icon={TagIcon}
            />
          </div>
        ) : null}
    >
      {isLoading ? <Spinner /> : (
        <HierarchicalSearchResults
          items={itemsByGroups}
          selection={selection}
          handleEmptySelectionChange={emptySelectionHandler}
          type={HierarchicalItemType.TAG}
          showEmptyGroupsAsCollapsible
          initiallyOpen={{
            root: true,
            children: searchQuery.length > 0,
          }}
        />
      )}
    </HierarchicalSearchInputWrapper>
  );
}
