import React from 'react';

import AppIcon from 'common-ui-components/AppIcon';
import ExternalLink from 'common-ui-components/ExternalLink';
import InteractionCard from 'common-ui-components/InteractionCard';
import ExpandableCell, { EXTENDED_COLLAPSED_HEIGHT } from 'common-ui-components/Table/ExpandableCell';
import Tooltip from 'common-ui-components/Tooltip';
import { Atom } from 'model/Atom';
import AdditionalPrivateInteractions from 'screens/platform/cross-platform-components/Interactions/components/InteractionsTable/components/LinkedInteractions/AdditionalPrivateInteractions';

import style from 'screens/platform/cross-platform-components/Interactions/components/InteractionsTable/components/LinkedInteractions/style.module.scss';

export interface LinkedInteractionsProps {
  interactions: Atom[];
  countIncludingNotAllowed: number;
  description: string;
}

export default function LinkedInteractions({
  interactions,
  countIncludingNotAllowed,
  description,
}: LinkedInteractionsProps) {
  const notAllowedCount = countIncludingNotAllowed - interactions.length;
  return interactions.length === 0
    ? null
    : (
      <ExpandableCell collapsedHeight={EXTENDED_COLLAPSED_HEIGHT}>
        <div className={style.iconsContainer}>
          {
            interactions.map((interaction) => (
              <Tooltip
                key={`${interaction.appId}_${interaction.id}`}
                content={(<InteractionCard atom={interaction} />)}
                interactive
                className={style.tooltip}
              >
                <ExternalLink
                  to={interaction.permalink}
                >
                  <AppIcon
                    appId={interaction.appId}
                    isPublic={interaction.isPublic}
                    showTitle={false}
                    mimeType={interaction.mimeType}
                  />
                </ExternalLink>
              </Tooltip>
            ))
          }
          <AdditionalPrivateInteractions
            notAllowedCount={notAllowedCount}
            description={description}
          />
        </div>
      </ExpandableCell>
    );
}
