import classNames from 'classnames';
import React from 'react';

import Button from 'common-ui-components/Button';

import { ReactComponent as ChevronIcon } from 'assets/img/icon/chevron.svg';

import style from 'common-ui-components/Carousel/style.module.scss';

interface Props {
  activeIndex: number;
  itemsLength: number;
  itemsToShow: number;
  goToPreviousSlide: () => void;
  goToNextSlide: () => void;
}

export default function CarouselNavigation({
  activeIndex,
  itemsLength,
  itemsToShow,
  goToPreviousSlide,
  goToNextSlide,
}: Props) {
  return (
    <div className={style.navigation}>
      <Button
        transparent
        disabled={activeIndex === 0}
        onClick={goToPreviousSlide}
        className={style.carouselButton}
        aria-label="Previous"
      >
        <ChevronIcon className={style.chevronIcon} />
      </Button>
      <Button
        transparent
        disabled={activeIndex >= itemsLength - itemsToShow}
        onClick={goToNextSlide}
        className={style.carouselButton}
        aria-label="Next"
      >
        <ChevronIcon className={classNames(style.chevronIcon, style.next)} />
      </Button>
    </div>
  );
}
