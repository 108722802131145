import { RefObject, useEffect, useRef } from 'react';

import {
  useDefaultMasterFilters,
} from 'screens/platform/cross-platform-components/context/MasterFiltersContext/utils/DefaultMasterFiltersUtils';

export default function useMasterFiltersVersion(
  masterFiltersVersion: number,
): RefObject<number> {
  const defaultCurrentFilters = useDefaultMasterFilters();
  const currentVersion = useRef(defaultCurrentFilters.version);

  useEffect(() => {
    currentVersion.current = masterFiltersVersion;
  }, [masterFiltersVersion]);

  return currentVersion;
}
