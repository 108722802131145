import React from 'react';

import DepartmentBadge from 'common-ui-components/Badge/DepartmentBadge';
import CommunicationFlowEvent from 'model/Events/CommunicationFlowEvent';

import style from 'screens/platform/cross-platform-components/EventsPanel/EventCell/EventTitle/style.module.scss';

interface Props {
  event: CommunicationFlowEvent;
}

export default function CommunicationFlowTitle({ event }: Props) {
  const { firstDepartment, secondDepartment, changeFromAverage } = event;
  const changePercentage = Math.abs(Math.ceil(changeFromAverage));
  const changeText = changeFromAverage > 0 ? 'increased' : 'dropped';

  return (
    <div className={style.title}>
      <span>Collaboration between</span>
      <DepartmentBadge department={firstDepartment.label} />
      and
      <DepartmentBadge department={secondDepartment.label} />
      <span>{`has ${changeText} by ${changePercentage}%`}</span>
    </div>
  );
}
