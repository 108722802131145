import { useEffect } from 'react';

import Api from 'global/api/platformApi';
import { SETUP_STATE } from 'model/Tenant';
import useLatestDataQuery from 'screens/platform/contentScreens/AnalyticsScreen/utils/LatestDataQueryHook';
import { MasterFilters } from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFilters';
import useTenantContext from 'screens/platform/cross-platform-components/context/tenant/TenantContext';
import DebuggerConsole from 'utils/DebuggerConsole';
import { useDebouncedAsyncCall, useMountedState } from 'utils/hooks';
import ObjectUtils from 'utils/ObjectUtils';

export interface AtomsCounts {
  byDays: Record<string, number>;
  byWeeks: Record<string, number>;
  byCategories: Record<string, number>;
  byTags: Record<string, Record<string, number>>;
  byDepartments: Record<string, Record<string, number>>;
  byOrganizations: Record<string, Record<string, number>>;
}

export default function useTotalAtomsCounts(
  masterFilters: MasterFilters | null,
): AtomsCounts | null {
  const [totalAtomsCounts, setTotalAtomsCounts] = useMountedState<AtomsCounts | null>(null);
  const { tenant } = useTenantContext();
  const isLatestQuery = useLatestDataQuery([masterFilters?.version]);

  function updateTotalAtomsCounts(response: AtomsCounts) {
    function formatFullDateEntry<T>([dateAsString, value]: [string, T]): [string, T] {
      const shortDateFormat = '2022-05-02';
      return [dateAsString.substring(0, shortDateFormat.length), value];
    }

    setTotalAtomsCounts({
      ...response,
      byDays: ObjectUtils.mapEntries(response.byDays, formatFullDateEntry),
      byWeeks: ObjectUtils.mapEntries(response.byWeeks, formatFullDateEntry),
      byTags: ObjectUtils.mapEntries(response.byTags, formatFullDateEntry),
      byDepartments: ObjectUtils.mapEntries(response.byDepartments, formatFullDateEntry),
      byOrganizations: ObjectUtils.mapEntries(response.byOrganizations, formatFullDateEntry),
    });
  }

  async function fetchTotalAtomsCounts() {
    if (!masterFilters) return;
    setTotalAtomsCounts(null);
    try {
      const result = await Api.Atom.getAtomsCounts(tenant.id, masterFilters);
      if (isLatestQuery([masterFilters.version])
            && result?.data) {
        updateTotalAtomsCounts(result.data);
      }
    } catch (err) {
      DebuggerConsole.error(err);
    }
  }

  // TODO: This is a temporary mitigation, however we shouldn't call this multiple times in a row
  const debouncedFetchTotalAtomsCounts = useDebouncedAsyncCall(fetchTotalAtomsCounts);

  useEffect(() => {
    if (tenant.setupState === SETUP_STATE.READY) {
      debouncedFetchTotalAtomsCounts();
    }
  }, [masterFilters]);

  return totalAtomsCounts;
}
