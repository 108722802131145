import React, { useMemo } from 'react';

import AppIcon from 'common-ui-components/AppIcon';
import Button from 'common-ui-components/Button';
import Intercom from 'global/Intercom';
import App from 'global/lists/apps';
import getAppDisplayName from 'global/lists/appsNames';
import SingularPluralNouns from 'global/lists/SingularPluralNouns';
import {
  getAppChannelLabel,
} from 'screens/platform/cross-platform-components/HierarchicalSearchInput/components/groupLabelUtils';
import DebuggerConsole from 'utils/DebuggerConsole';

import 'screens/platform/cross-platform-components/Panels/BottomPanel/InfoPanel/InfoModal/InfoModalPlatform/style.scss';

export interface InfoModalPlatformProps {
  description: string | undefined;
  appId: App;
  isPublic?: boolean;
  intercomArticleId?: number;
}

export default function InfoModalPlatform({
  description, isPublic = true, appId, intercomArticleId,
}: InfoModalPlatformProps) {
  const appName = getAppDisplayName(appId);
  const parsedDescription = useMemo(() => {
    if (description !== undefined) {
      return description;
    }
    const nouns = getAppChannelLabel(appId.toString()) ?? SingularPluralNouns.interaction;
    return nouns.plural.toLowerCase();
  }, [description, appId]);

  function loadIntercomArticle(articleID: number, _appId: App) {
    if (!articleID) {
      DebuggerConsole.error('intercomArticleId is undefined', { articleID, _appId });
      return;
    }
    Intercom.showArticle(articleID);
  }

  return (
    <div className="info-modal-platform">
      <AppIcon
        appId={appId}
        mimeType={undefined}
        isPublic={isPublic}
      />
      <div className="content">
        <h3>{appName}</h3>
        <div className="description">
          {parsedDescription}
          {intercomArticleId && (
            <Button
              transparent
              className="guideLink"
              onClick={() => loadIntercomArticle(intercomArticleId, appId)}
            >
              (see guide)
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
