import React from 'react';

import useTotalInteractionsCount
  from 'screens/platform/cross-platform-components/MasterFiltersPanel/MasterFiltersPanelHeader/TotalInteractionsLabel/TotalInteractionsCountHook';

import style from 'screens/platform/cross-platform-components/MasterFiltersPanel/MasterFiltersPanelHeader/TotalInteractionsLabel/style.module.scss';

export default function TotalInteractionsLabel() {
  const totalAtomsCount = useTotalInteractionsCount();
  return (
    <div className={style.interactionsCount}>
      {
        totalAtomsCount ? (
          <span>
            {`${totalAtomsCount} Interactions`}
          </span>
        ) : null
      }
    </div>
  );
}
