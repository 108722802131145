import React, { useMemo } from 'react';

import InternalLink from 'common-ui-components/InternalLink/index';
import { getImageTitleAttributeText, ImageType } from 'common-ui-components/MultiImages/MultiImagesUtils';
import MultiOrganizationsImages from 'common-ui-components/MultiImages/MultiOrganizationsImages/index';
import Spinner from 'common-ui-components/Spinner';
import Size from 'global/lists/Size';
import Organization from 'model/Organization';
import useOrganizationsMetadata
  from 'screens/platform/cross-platform-components/context/metadata/hooks/organizationsMetadataHook';
import { getOrganizationName } from 'utils/OrganizationUtils';

import style from 'screens/platform/directory/organizations/OrganizationsDirectoryScreen/OrganizationsTable/components/OrganizationName/style.module.scss';

type Props =
  | {
      organization: Organization;
      organizationId?: never;
    }
  | {
      organizationId: string;
      organization?: never;
    };

export default function OrganizationName({
  organization,
  organizationId,
}: Props) {
  const orgIdArray = useMemo(
    () => (organizationId ? [organizationId] : null),
    [organizationId],
  );
  const { value: organizations } = useOrganizationsMetadata(orgIdArray);

  const loadedOrganization = organization ?? organizations?.[organizationId];

  if (!loadedOrganization) {
    return <Spinner />;
  }

  return (
    <div className={style.organizationNameContainer}>
      <MultiOrganizationsImages
        ids={[loadedOrganization.id]}
        size={Size.BIG}
        hoverable
      />
      <InternalLink
        to="platform.directory.organizations.profile"
        urlParam={loadedOrganization.id}
        title={getImageTitleAttributeText(ImageType.ORGANIZATIONS)}
      >
        <span>{getOrganizationName(loadedOrganization)}</span>
      </InternalLink>
    </div>
  );
}
