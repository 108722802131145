import React, { useState } from 'react';

import Analytics, { AnalyticsEvent, RequestTagLocation } from 'global/Analytics';
import ToastManager, { ToastKey } from 'utils/ToastManager';

export default function useTagRequest() {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const handleTagRequest = (keyword: string, location: RequestTagLocation) => {
    Analytics.trackEvent(AnalyticsEvent.TAG_REQUESTED, { keyword, location });
    setIsButtonDisabled(true);
    ToastManager.show(
      (
        <span>
          A request to add
          {' '}
          <b>{keyword}</b>
          {' '}
          as a tag was sent to your admin
        </span>
      ), {
        type: 'success',
        key: ToastKey.REQUEST_TAG,
      },
    );
  };

  return { isButtonDisabled, handleTagRequest };
}
