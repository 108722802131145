import React from 'react';

import { ReactComponent as SparklesIcon } from 'assets/img/icon/sparkles-icon.svg';

import style from 'es-src/screens/HomeScreen/components/SearchWithAutocomplete/HintMessage/style.module.scss';

interface Props {
  isVisible: boolean;
}

export default function HintMessage({ isVisible }: Props) {
  if (!isVisible) return null;

  return (
    <div className={style.hintMessage}>
      <SparklesIcon className={style.icon} />
      <span className={style.text}>Hit Enter for AI and search results</span>
    </div>
  );
}
