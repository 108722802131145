import classNames from 'classnames';
import React, { FC, useEffect, useState } from 'react';

import Button from 'common-ui-components/Button';
import Checkbox from 'common-ui-components/Checkbox';
import { SelectionStatus } from 'utils/HierarchicalDataStructures/HierarchicalGroupInterface';
import HierarchicalInterface from 'utils/HierarchicalDataStructures/HierarchicalInterface';

import style
  from 'screens/platform/cross-platform-components/HierarchicalSearchInput/components/ItemListing/style.module.scss';

export const ITEM_LISTING_HEIGHT_SMALL = 18;
export const ITEM_LISTING_HEIGHT_BIG = 24;
export const ITEM_LISTING_MARGIN = 8;

interface ItemListingProps {
  item: HierarchicalInterface;
  label: string;
  labelImage?: JSX.Element;
  sizeLabel?: string;
  checkboxWrapper?: FC;
  disabled?: boolean; // Not clickable
  inactive?: boolean; // Grayed out
  onToggle?: (id: string) => void;
}

export default function ItemListing({
  item,
  label,
  labelImage,
  sizeLabel,
  checkboxWrapper = ({ children }) => <>{children}</>,
  disabled = false,
  inactive = false,
  onToggle,
}: ItemListingProps) {
  const [selectionState, setSelectionState] = useState(item.getSelectionStatus());
  const CheckboxWrapper = checkboxWrapper;

  useEffect(() => {
    const id = item.subscribe((_, nextSelectionStatus) => setSelectionState(nextSelectionStatus));
    return () => item.unsubscribe(id);
  }, [item]);

  function handleClick() {
    if (onToggle) {
      onToggle(item.id);
    } else {
      item.toggle();
    }
  }

  return (
    <Button
      onClick={handleClick}
      className={style.itemListing}
      disabled={disabled}
    >
      <CheckboxWrapper>
        <Checkbox
          checked={selectionState === SelectionStatus.SELECTED}
          partiallyChecked={selectionState === SelectionStatus.PARTIALLY_SELECTED}
          disabled={disabled}
          dataTestId={item.id}
        />
      </CheckboxWrapper>
      <div className={classNames(style.labelContainer, inactive && style.inactive)}>
        {labelImage && <span className={style.itemImage}>{labelImage}</span>}
        <span className={style.label}>{label}</span>
        {sizeLabel && <span className={style.itemSize}>{sizeLabel}</span>}
      </div>
    </Button>
  );
}
