import React from 'react';

import Badge from 'common-ui-components/Badge';
import useAsync from 'react-use/lib/useAsync';
import { useMetadataContext } from 'screens/platform/cross-platform-components/context/metadata/MetadataContext';
import { getOrganizationName } from 'utils/OrganizationUtils';

import style from 'common-ui-components/Badge/commonStyle.module.scss';

interface Props {
  organizationId: string;
}

export default function OrganizationBadge({ organizationId }: Props) {
  const { getTagColors } = useMetadataContext();
  const { organizations } = useMetadataContext();
  const { value: organizationName } = useAsync(
    async () => {
      const organization = await organizations.getById(organizationId);
      return getOrganizationName(organization, organizationId);
    },
    [organizationId],
  );
  const colors = getTagColors(organizationName ?? organizationId);
  return (
    <Badge
      text={organizationName ?? organizationId}
      color={colors.color}
      className={style.badge}
      shouldFixLongText={false}
    />
  );
}
