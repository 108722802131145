import { ChartType, GroupType, WidgetType } from 'screens/platform/contentScreens/AnalyticsScreen/widgets/widgetConfig';

const widgetTypesTooltips: Record<WidgetType, string> = {
  [WidgetType.METRICS]: 'Multiple metrics of interest as made available from the library',
  [WidgetType.EVENT]: 'Events related to trending topics and shift of attention within and across teams',
  [WidgetType.VOLUME]: 'Overall activity per interactions (edits, messages, etc.)',
  [WidgetType.SIGNIFICANCE]: 'Perceived professional substance (importance, urgency etc.)',
  [WidgetType.RELATIVE_VOLUME]: 'Ratio of activity per interactions (edits, messages, etc.) versus another group',
  [WidgetType.PARTICIPANTS]: 'People or Contacts that took active part in an interaction',
};

const groupTooltips: Record<GroupType, string> = {
  [GroupType.TAGS]: 'Tags that were automatically detected, according to the filters criteria',
  [GroupType.CATEGORIES]: 'Groups of related topics that were automatically detected, according to the filters criteria',
  [GroupType.DEPARTMENTS]: 'Assignment of individuals to business divisions, according to the filters criteria',
  [GroupType.APPS]: 'Connected workplace apps, according to the filtered criteria',
  [GroupType.CHANNELS]: 'Collections of interactions as defined in the source app, according to the filtered criteria',
  [GroupType.ORGANIZATIONS]: 'External companies, teams or products, according to the filtered criteria',
  [GroupType.ORIENTATION]: 'Inbound, outbound or internal communication',
  [GroupType.ORGANIZATION_ACTIVITY]: 'Mentions or engagements communication',
};

const chartTooltips: Record<ChartType, string> = {
  [ChartType.BUBBLE]: 'Time and two other interrelated dimensions (such as number and impact)',
  [ChartType.BAR]: 'Distinct comparison of breakdown groups on chosen metric',
  [ChartType.STACKED_BAR]: 'Part-to-whole breakdown group relationships on chosen metric',
  [ChartType.LINE]: 'Continuous change over time for chosen metric',
  [ChartType.HEATMAP]: 'Relationship between time variable, chosen metric and breakdown groups',
  [ChartType.STATISTICS]: 'Aggregated value for chosen metrics (compared to past, where applicable)',
  [ChartType.COMMCHART]: 'A structured way to visualize relationships and communication flow (ONA)',
};

const widgetTooltips = {
  widgetType: widgetTypesTooltips,
  group: groupTooltips,
  chart: chartTooltips,
  defaults: {
    group: 'The organization\'s interactions (docs, conversations etc.), according to the filters criteria',
    filter: 'All interactions (docs, conversations etc.) in the organization',
  },
};

export default widgetTooltips;
