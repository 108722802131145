import classNames from 'classnames';
import React, { forwardRef } from 'react';

import Spinner from 'common-ui-components/Spinner';

import style from 'common-ui-components/Button/style.module.scss';

type HTMLButtonElementProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;
export type ButtonProps = {
  transparent?: boolean;
  color?: 'gray' | 'blue' | 'red' | 'yellow';
  padded?: boolean;
  rounded?: boolean;
  type?: 'button' | 'submit' | 'reset';
  loading?: boolean;
  testId?: string;
} & Pick<HTMLButtonElementProps,
  | 'id'
  | 'children'
  | 'className'
  | 'onClick'
  | 'onMouseDown'
  | 'onMouseUp'
  | 'onMouseEnter'
  | 'onMouseLeave'
  | 'aria-label'
  | 'title'
  | 'disabled'
  | 'style'
  | 'role'
>

const Button = forwardRef<HTMLButtonElement, ButtonProps>((props: ButtonProps, ref) => {
  const {
    className,
    transparent,
    color,
    type = 'button',
    loading,
    padded,
    rounded,
    disabled,
    testId,
    children,
    ...htmlButtonElementProps
  } = props;

  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      className={classNames(style.button, className, {
        [style.blue]: color === 'blue',
        [style.gray]: color === 'gray',
        [style.red]: color === 'red',
        [style.yellow]: color === 'yellow',
        [style.transparent]: transparent,
        [style.disabled]: disabled,
        [style.padded]: padded,
        [style.rounded]: rounded,
      })}
      ref={ref}
      disabled={Boolean(disabled) || loading}
      data-testid={testId}
      {...htmlButtonElementProps}
    >
      {
        loading ? (
          <div className={style.loadingSpinner}>
            <Spinner color="white" />
          </div>
        ) : children
      }
    </button>
  );
});

export default Button;
