import classNames from 'classnames';
import React from 'react';

import { Heading2, Heading4 } from 'common-ui-components/Typograhpy';

import style from 'screens/platform/cross-platform-components/ErrorMessage/style.module.scss';

interface Props {
  heading: string;
  subtitle?: string;
  className?: string;
  emoji?: string;
}

export default function ErrorMessage({
  heading,
  subtitle,
  className,
  emoji = '🧐',
}: Props) {
  return (
    <div className={classNames(style.errorMessage, className)}>
      <div className={style.emoji}>
        {emoji}
      </div>
      <Heading2>{heading}</Heading2>
      {subtitle && (
        <Heading4 className={style.subtitle}>{subtitle}</Heading4>
      )}
    </div>
  );
}
