import { useAsync } from 'react-use';

import getScreenConfig from 'global/ScreensConfiguration';
import Person from 'model/Person';
import { useMetadataContext } from 'screens/platform/cross-platform-components/context/metadata/MetadataContext';

export function getNonFriendlyPersonDirectoryProfilePath(personHash: string) {
  const { path } = getScreenConfig('platform.directory.people.profile', personHash);
  return path;
}

type PersonDirectoryProfileFullPathResponse = {
  personDirectoryProfileFullPath: string;
  loading: false;
} | {
  personDirectoryProfileFullPath: undefined;
  loading: true;
}

export function encodePersonDirectoryHash(person: Person | undefined, personId: string): string {
  const name = (person?.name ?? '').replace(' ', '-');
  return `${encodeURIComponent(name)}-${personId}`;
}
export function decodePersonDirectoryHash(hash: string): string {
  const parts = hash.split('-');
  return parts[parts.length - 1];
}

export function usePersonDirectoryProfileFullPathGetter(): (personId: string) => Promise<string> {
  const { persons } = useMetadataContext();

  return async (personId) => persons.getById(personId)
    .then((person) => {
      const hash = encodePersonDirectoryHash(person, personId);
      return getNonFriendlyPersonDirectoryProfilePath(hash);
    });
}

export function usePersonDirectoryProfileFullPath(personId: string):
  PersonDirectoryProfileFullPathResponse {
  const getPersonDirectoryProfileFullPath = usePersonDirectoryProfileFullPathGetter();

  const { value, loading } = useAsync(
    async () => getPersonDirectoryProfileFullPath(personId),
    [personId],
  );

  return {
    personDirectoryProfileFullPath: value,
    loading,
  } as PersonDirectoryProfileFullPathResponse;
}
