import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import AuthorizedWrapper from 'common-ui-components/AuthorizedWrapper/index';
import Button from 'common-ui-components/Button';
import Tooltip from 'common-ui-components/Tooltip';
import getScreenConfig from 'global/ScreensConfiguration';
import {
  useMasterFilters,
} from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFiltersContext';
import {
  useDefaultMasterFilters,
} from 'screens/platform/cross-platform-components/context/MasterFiltersContext/utils/DefaultMasterFiltersUtils';
import ArrayUtils from 'utils/ArrayUtils';

import { ReactComponent as BellIcon } from 'assets/img/icon/masterFilters/bell.svg';

import style from 'screens/platform/cross-platform-components/MasterFiltersPanel/masterFiltersPanelStyles.module.scss';

export default function SubscriptionButton() {
  const history = useHistory();
  const { currentFilters } = useMasterFilters();
  const defaultCurrentFilters = useDefaultMasterFilters();

  const disabled = useMemo(() => {
    const { categories, people: { departments } } = currentFilters;
    const {
      categories: defaultCategories,
      people: { departments: defaultDepartments },
    } = defaultCurrentFilters;

    const areDefaultCategoriesSelected = categories.tagsByCategories
      .equals(defaultCategories.tagsByCategories);
    const areDefaultDepartmentsSelected = (departments === null && defaultDepartments === null)
      || (departments !== null
        && defaultDepartments !== null
        && ArrayUtils.isEqual(departments, defaultDepartments));

    // Should subscribe to at least one department
    const isDepartmentsSelectionValid = departments === null || departments.length > 0;

    return areDefaultCategoriesSelected
      && (areDefaultDepartmentsSelected || !isDepartmentsSelectionValid);
  }, [currentFilters.version]);

  const slackAlertsScreenConfig = getScreenConfig('settings.user.slackAlerts');

  function redirectToSettings() {
    const slackAlertsSettingsScreenUrl = slackAlertsScreenConfig.path;
    history.push(
      slackAlertsSettingsScreenUrl,
      { masterFilters: currentFilters },
    );
  }

  return disabled
    ? (
      <Tooltip
        placement="right"
        content={(
          <div className={style.disabledSubscriptionTooltip}>
            Slack alerts cannot be applied
            to the current filters selected
          </div>
        )}
      >
        <div>
          <Button disabled>
            <BellIcon width={14} height={14} />
          </Button>
        </div>
      </Tooltip>
    ) : (
      <AuthorizedWrapper minimumRole={slackAlertsScreenConfig.minimumRole}>
        <Button
          color="gray"
          title="Create Slack Alert"
          onClick={redirectToSettings}
        >
          <BellIcon width={14} height={14} />
        </Button>
      </AuthorizedWrapper>
    );
}
