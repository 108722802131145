import classNames from 'classnames';
import React, { ReactElement, ReactNode, useMemo } from 'react';

import ExternalLink from 'common-ui-components/ExternalLink';
import InteractionSubtitle from 'common-ui-components/InteractionTitle/InteractionSubtitle';
import emojiParser from 'common-ui-components/InteractionTitle/parsers/emojiParser';
import MultiPersonsImages from 'common-ui-components/MultiImages/MultiPersonsImages';
import Tooltip from 'common-ui-components/Tooltip';
import Size from 'global/lists/Size';
import { Atom } from 'model/Atom';
import InteractionDetails from 'screens/platform/cross-platform-components/InteractionDetails';
import AtomUtils from 'utils/AtomUtils';

import style from 'common-ui-components/InteractionTitle/style.module.scss';

const TOOLTIP_MAX_WIDTH = 300;

interface Props {
  atom: Atom;
  onClick?: () => void;
  options?: {
    clickable?: boolean;
    singleLine?: boolean;
    showUpdatedAt?: boolean;
    shouldShowSubtitle?: boolean;
    shouldShowParticipants?: boolean;
    hasTooltip?: boolean;
    enterpriseSearchDesign?: boolean;
  };
}

interface OptionalExternalLinkProps {
  children: ReactNode;
  clickable: boolean;
  onClick?: () => void;
  atom: Atom;
}

interface OptionalTooltipWrapperProps {
  children: ReactElement;
  hasTooltip: boolean;
  atom: Atom;
}

export default function InteractionTitle({
  atom,
  options = {},
  onClick,
}: Props) {
  const {
    clickable = false,
    singleLine = true,
    showUpdatedAt = false,
    shouldShowSubtitle = true,
    shouldShowParticipants = false,
    hasTooltip = false,
    enterpriseSearchDesign = false,
  } = options;

  const parsedTitleWithEmojis = useMemo(() => emojiParser(atom.title), [atom]);
  const sortedParticipants = shouldShowParticipants
    ? AtomUtils.sortParticipantsIdsByPercentage(atom)
    : [];

  return (
    <div
      className={classNames(
        style.sourceTitle,
        enterpriseSearchDesign && style.enterpriseSearchDesign,
      )}
    >
      <OptionalExternalLink clickable={clickable} atom={atom} onClick={onClick}>
        <div className={classNames(style.title, singleLine ? style.singleLine : style.threeLines)}>
          <OptionalTooltipWrapper hasTooltip={hasTooltip} atom={atom}>
            <span>{parsedTitleWithEmojis}</span>
          </OptionalTooltipWrapper>
        </div>
        <div className={style.subtitle}>
          {shouldShowSubtitle
        && <InteractionSubtitle item={atom} showUpdatedAt={showUpdatedAt} />}
          {shouldShowParticipants
        && <MultiPersonsImages ids={sortedParticipants} size={Size.SMALL} />}
        </div>
      </OptionalExternalLink>
    </div>
  );
}

function OptionalExternalLink({
  clickable, onClick, atom, children,
}: OptionalExternalLinkProps) {
  return clickable ? (
    <ExternalLink onClick={onClick} to={atom.permalink} title="Go to start of interaction">
      {children}
    </ExternalLink>
  ) : <>{children}</>;
}

function OptionalTooltipWrapper({ hasTooltip, atom, children }: OptionalTooltipWrapperProps) {
  return hasTooltip ? (
    <Tooltip
      animation={false}
      arrow={false}
      placement="bottom-start"
      content={<InteractionDetails atom={atom} />}
      maxWidth={TOOLTIP_MAX_WIDTH}
    >
      {children}
    </Tooltip>
  ) : <>{children}</>;
}
