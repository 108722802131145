import React, { ReactNode } from 'react';

import Button from 'common-ui-components/Button';
import ToastManager, { ToastKey } from 'utils/ToastManager';

import style from 'common-ui-components/MultiImages/MultiOrganizationsImages/MissingOrganizationProfileWrapper/style.module.scss';

interface Props {
  children: ReactNode;
}

export default function MissingOrganizationProfileWrapper({ children }: Props) {
  const showToast = () => ToastManager.show('There is no profile page for unknown organizations', {
    key: ToastKey.MISSING_PROFILE_PAGE,
    type: 'info',
    className: style.toastContainer,
  });

  return <Button onClick={showToast}>{children}</Button>;
}
