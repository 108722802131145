import classNames from 'classnames';
import React, { ReactNode } from 'react';

import style from 'common-ui-components/Typograhpy/style.module.scss';

interface TypographyProps {
  children: ReactNode;
  className?: string;
}

// eslint-disable-next-line import/prefer-default-export

export const Heading1 = ({ children, className }: TypographyProps) => (
  <h1 className={classNames(style.h1, className)}>{children}</h1>
);

export const Heading2 = ({ children, className }: TypographyProps) => (
  <h2 className={classNames(style.h2, className)}>{children}</h2>
);
export const Heading3 = ({ children, className }: TypographyProps) => (
  <h3 className={classNames(style.h3, className)}>{children}</h3>
);

export const Heading4 = ({ children, className }: TypographyProps) => (
  <h4 className={classNames(style.h4, className)}>{children}</h4>
);

export const Paragraph = ({ children, className }: TypographyProps) => (
  <p className={classNames(style.paragraph, className)}>{children}</p>
);
