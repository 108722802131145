import { useState } from 'react';

import HierarchicalGroup from 'utils/HierarchicalDataStructures/HierarchicalGroup';

type VoidFunction = () => void;

export interface CollapsibleGroupSelectionStatus {
  selectionStatus: {
    isSelected: boolean;
    isPartiallySelected: boolean;
  };
  updateSelectionStatus: VoidFunction;
  toggleAll: VoidFunction;
}

interface Props {
  group: HierarchicalGroup;
  postUpdateCallback?: VoidFunction;
  customToggleAll?: VoidFunction;
}

export default function useCollapsibleGroupSelectionStatus({
  group,
  postUpdateCallback = () => null,
  customToggleAll,
}: Props): CollapsibleGroupSelectionStatus {
  const getSelectionStatus = () => ({
    isSelected: group.isSelected,
    isPartiallySelected: group.isPartiallySelected,
  });
  const [selectionStatus, setSelectionStatus] = useState(getSelectionStatus);

  function updateSelectionStatus() {
    setSelectionStatus(getSelectionStatus);
    postUpdateCallback();
  }

  function toggleAll() {
    if (customToggleAll) {
      customToggleAll();
    } else if (group.isSelected) {
      group.deselect();
    } else {
      group.select();
    }
    updateSelectionStatus();
  }

  return {
    selectionStatus,
    updateSelectionStatus,
    toggleAll,
  };
}
