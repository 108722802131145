import React, { useEffect, useReducer, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { addImpersonationHeader, removeImpersonationHeader } from 'global/api/axiosInstance';
import Api from 'global/api/platformApi';
import colors from 'global/colors';
import { AKOODA_TENANT_ID } from 'global/constants';
import Intercom from 'global/Intercom';
import Tenant, { SETUP_STATE } from 'model/Tenant';
import {
  TenantContext,
  TenantContextType,
} from 'screens/platform/cross-platform-components/context/tenant/TenantContext';
import useTenantDataFetching from 'screens/platform/cross-platform-components/context/tenant/TenantDataFetchingHook';
import TenantReducer from 'screens/platform/cross-platform-components/context/tenant/TenantReducer';
import { AuthError, useUserContext } from 'screens/platform/cross-platform-components/context/user/UserContext';
import DebuggerConsole from 'utils/DebuggerConsole';
import { useMountedState } from 'utils/hooks';
import LocalStorageUtils, { StorageKeyGeneral } from 'utils/LocalStorageUtils';
import ToastManager, { ToastKey } from 'utils/ToastManager';

const defaultTenant = {
  id: -1,
  name: '',
  domain: '',
  departments: {},
  setupState: SETUP_STATE.SETUP,
  personProperties: {},
};

export const defaultDepartmentColor = colors.DARK_GRAY;

export default function TenantContextProvider({ children }) {
  const {
    isLoggedIn, isSuperAdmin, endUserSession, setAuthError, personId,
  } = useUserContext();

  const [tenantState, dispatchTenant] = useReducer(TenantReducer, {
    currTenant: defaultTenant,
    tenantLogo: null,
  });
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const history = useHistory();
  const [isImpersonationMode, setIsImpersonationMode] = useMountedState<boolean>(false);
  const location = useLocation();

  const fetchTenantData = useTenantDataFetching();

  useEffect(() => {
    if (isLoggedIn) {
      fetchTenantData().then((tenant) => {
        if (tenant !== null) {
          Intercom.updateTenantMode(tenant.setupState);
          dispatchTenant({ type: 'INITIALIZE', payload: { newTenant: tenant } });
        } else {
          setAuthError(AuthError.TENANT_INITIALIZATION_ERROR);
          endUserSession();
        }
      });
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (isSuperAdmin && !tenantState.allTenants) {
      Api.Tenant.getAllTenants()
        .then((allTenantsFromDb) => {
          if (allTenantsFromDb?.data) {
            dispatchTenant({ type: 'SET_ALL_TENANTS', payload: { allTenants: allTenantsFromDb.data } });
          }
        });
    }
  }, [isSuperAdmin]);

  useEffect(() => {
    const initTenantLogo = async (tenantId: Tenant['id']) => {
      try {
        const response = await Api.Image.getLogo(tenantId);
        if (response?.data) {
          const url = response.data.size > 0 ? URL.createObjectURL(response.data) : null;
          dispatchTenant({ type: 'SET_LOGO', payload: { url } });
        }
      } catch (error) {
        DebuggerConsole.error('Couldn\'t load tenant logo', error);
      }
    };
    const { id } = tenantState.currTenant;
    if (id !== defaultTenant.id) {
      initTenantLogo(id);
    }
  }, [tenantState.currTenant]);

  useEffect(() => {
    if (shouldRefresh) setShouldRefresh(false);
  }, [shouldRefresh]);

  async function switchTenant(newTenantId: Tenant['id']) {
    function updateNewTenant(data: Tenant) {
      history.push({ search: '' }); // Reset search query
      LocalStorageUtils.setItem<number>(StorageKeyGeneral.TENANT_ID, newTenantId);
      dispatchTenant({ type: 'INITIALIZE', payload: { newTenant: data } });
      setShouldRefresh(true);
    }

    removeImpersonationHeader();
    setIsImpersonationMode(false);

    if (isSuperAdmin) {
      try {
        const newTenantResponse = await Api.Tenant.getTenant(newTenantId);
        if (newTenantResponse) {
          const { data } = newTenantResponse;
          if (data) {
            updateNewTenant(data);
          } else {
            ToastManager.show('Access to this tenant is limited', {
              key: ToastKey.LIMITED_ACCESS_TO_TENANT,
              type: 'warning',
            });
          }
        }
      } catch (error) {
        DebuggerConsole.error('An error has occurred while switching tenants', { error, newTenantId, personId });
      }
    }
  }

  useEffect(() => {
    const { id: currentTenantId } = tenantState.currTenant;
    const isDefaultTenantId = currentTenantId === defaultTenant.id;
    const isAkoodaTenant = currentTenantId === AKOODA_TENANT_ID;

    if (isAkoodaTenant || isDefaultTenantId) {
      setIsImpersonationMode(false);
      return;
    }

    const searchParams = new URLSearchParams(location.search);
    const impersonationPersonId = searchParams.get('impersonationPersonId');

    if (impersonationPersonId !== null && isSuperAdmin) {
      setIsImpersonationMode(true);
      addImpersonationHeader(impersonationPersonId, currentTenantId);
    }
  }, [tenantState.currTenant.id, isSuperAdmin]);

  function getDepartmentColor(departmentLabel: string): string {
    return tenantState.currTenant.departments[departmentLabel]?.color || defaultDepartmentColor;
  }

  const contextValue: TenantContextType = {
    tenant: tenantState.currTenant,
    allTenants: tenantState.allTenants,
    tenantLogo: tenantState.tenantLogo,
    switchTenant,
    getDepartmentColor,
    dispatchTenant,
    isImpersonationMode,
  };

  return (
    <TenantContext.Provider value={contextValue}>
      { !shouldRefresh && children }
    </TenantContext.Provider>
  );
}
