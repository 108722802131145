import { useEffect, useState } from 'react';

import { useCurrentAppScreen } from 'global/lists/AppScreen';
import { ErrorType } from 'screens/platform/cross-platform-components/ChartStatesHandler/DataRetrievalErrorMessage';
import { useIsAnyFilterChanged } from 'screens/platform/cross-platform-components/MasterFiltersPanel/masterFiltersUtils/FilterChangeHook';

export default function useErrorType(isNetworkError: boolean): ErrorType {
  const [errorType, setErrorType] = useState<ErrorType>('NO_ACTIVITY_FOUND');
  const { isAnalyticsScreen } = useCurrentAppScreen();

  const isAnyFilterChanged = useIsAnyFilterChanged();

  useEffect(() => {
    if (isNetworkError) {
      setErrorType('NETWORK_ERROR');
    } else if (isAnyFilterChanged && isAnalyticsScreen) {
      setErrorType('INSUFFICIENT_DATA');
    } else {
      setErrorType('NO_ACTIVITY_FOUND');
    }
  }, [isNetworkError, isAnyFilterChanged]);

  return errorType;
}
