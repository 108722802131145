import { useCallback } from 'react';

import {
  CustomEventsFiltersState,
} from 'screens/platform/cross-platform-components/context/CustomFilters/CustomEventsFilters/CustomEventsFiltersReducer';
import { MasterFilters } from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFilters';
import NullableValuesSelection
  from 'screens/platform/cross-platform-components/context/MasterFiltersContext/NullableValuesSelection';
import OrganizationsMasterFilter
  from 'screens/platform/cross-platform-components/context/MasterFiltersContext/OrganizationsMasterFilter';
import usePeopleMasterFilterInitializers
  from 'screens/platform/cross-platform-components/context/MasterFiltersContext/PeopleMasterFilter/PeopleMasterFilterInitializersHook';
import { useMetadataContext } from 'screens/platform/cross-platform-components/context/metadata/MetadataContext';
import ObjectUtils from 'utils/ObjectUtils';

export default function useEventsCustomMasterFilters(masterFilters: Readonly<MasterFilters>): (
  customFilters: Readonly<CustomEventsFiltersState>,
) => MasterFilters {
  const { categories } = useMetadataContext();
  const { fromDepartments } = usePeopleMasterFilterInitializers();

  return useCallback((customFilters) => {
    const {
      datesRange, departments, tag, organizations,
    } = customFilters;
    const customMasterFilters: MasterFilters = {
      ...ObjectUtils.deepClone(masterFilters),
      datesRange: datesRange || masterFilters.datesRange,
    };

    if (tag) {
      const updatedTagsByCategories = masterFilters.categories.tagsByCategories.clone();
      updatedTagsByCategories.deselect();
      const tagsCategories = Object.entries(categories)
        .filter(([_, tags]) => tags.includes(tag))
        .map(([category, _]) => category);

      tagsCategories.forEach((tagCategory) => {
        updatedTagsByCategories.getChild([tagCategory, tag])?.select();
      });
      customMasterFilters.categories.tagsByCategories = updatedTagsByCategories;
    }

    if (departments) {
      customMasterFilters.people = fromDepartments(departments);
    }

    if (organizations) {
      customMasterFilters.organizations = new OrganizationsMasterFilter({
        ...masterFilters.organizations,
        organizationsIds: new NullableValuesSelection(organizations, false),
      });
    }

    return customMasterFilters;
  }, [categories, fromDepartments]);
}
