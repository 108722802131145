import EventResponse, { EventParsingUtils } from 'model/Events/Event';
import { CustomEventsFiltersState } from 'screens/platform/cross-platform-components/context/CustomFilters/CustomEventsFilters/CustomEventsFiltersReducer';
import { InternalLink } from 'screens/platform/cross-platform-components/EventsPanel/EventCell/EventActionText/actionText';
import DebuggerConsole from 'utils/DebuggerConsole';

const TEXT_REGEX = {
  LINK_TEXT: /@{(.*)\}/,
  VARIABLE: /%{(.*)\}/,
};

export function parseActionText(text: string, event: EventResponse): {
  prefixText: string;
  suffixText: string;
  linkText: string;
} {
  const matchResult = text.split(TEXT_REGEX.LINK_TEXT);
  if (matchResult.length !== 3) {
    DebuggerConsole.error('Failed to parse event action link');
    return {
      prefixText: '',
      suffixText: '',
      linkText: '',
    };
  }
  return {
    prefixText: replaceTextVariables(matchResult[0], event),
    linkText: replaceTextVariables(matchResult[1], event),
    suffixText: replaceTextVariables(matchResult[2], event),
  };
}

function replaceTextVariables(text: string, event: EventResponse): string {
  const tag = EventParsingUtils.getEventTag(event);
  const departments = EventParsingUtils.getEventDepartments(event);

  return text
    .split(TEXT_REGEX.VARIABLE)
    .map((result) => {
      switch (result.toLowerCase()) {
        case 'tag':
          return tag || result;
        case 'departments':
          if (departments && departments.length > 0) {
            return departments.join(' and ');
          }
          return result;
        default:
          return result;
      }
    }).join('');
}

export function buildCustomFilters(event: EventResponse, filters: InternalLink['filters']): CustomEventsFiltersState {
  return filters.reduce((acc, filter) => {
    const tag = EventParsingUtils.getEventTag(event);
    const departments = EventParsingUtils.getEventDepartments(event);
    const organization = EventParsingUtils.getEventOrganization(event);

    switch (filter) {
      case 'datesRange':
        acc[filter] = {
          from: event.startDate,
          to: event.endDate || new Date(),
        };
        break;
      case 'tag':
        if (tag !== null) {
          acc[filter] = tag;
        }
        break;
      case 'departments':
        if (departments && departments.length > 0) {
          acc[filter] = departments;
        }
        break;
      case 'organizations':
        if (organization) {
          acc[filter] = [organization];
        }
        break;
      default:
        break;
    }
    return acc;
  }, {});
}
