import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import Tenant from 'model/Tenant';
import { MasterFilters } from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFilters';
import OrganizationsMasterFilter
  from 'screens/platform/cross-platform-components/context/MasterFiltersContext/OrganizationsMasterFilter';
import PeopleMasterFilter
  from 'screens/platform/cross-platform-components/context/MasterFiltersContext/PeopleMasterFilter';
import {
  useDefaultMasterFilters,
} from 'screens/platform/cross-platform-components/context/MasterFiltersContext/utils/DefaultMasterFiltersUtils';
import useTenantContext from 'screens/platform/cross-platform-components/context/tenant/TenantContext';
import {
  FlatUrlParams,
  isFlatURLParamsKey,
} from 'screens/platform/cross-platform-components/context/UrlParams/UrlParamsTypes';
import UrlParamsUtils from 'screens/platform/cross-platform-components/context/UrlParams/UrlParamsUtils';
import ArrayUtils from 'utils/ArrayUtils';
import { endOfUTCDay, startOfUTCDay } from 'utils/DateUtils';
import HierarchicalGroup from 'utils/HierarchicalDataStructures/HierarchicalGroup';
import ObjectUtils from 'utils/ObjectUtils';

export function convertUrlParamsToMasterFilters(
  flatParams: Partial<FlatUrlParams>,
  allDepartments: Tenant['departments'],
  defaultMasterFilters: MasterFilters,
): Partial<MasterFilters> {
  const flatParamsConverted = {
    ...flatParams,
    departments: convertDepartmentsIdsToLabels(flatParams.departments),
  };

  function removeUndefinedValues<T extends Partial<MasterFilters>>(obj: T): Partial<T> {
    return Object.keys(obj).reduce((acc, key) => {
      if (obj[key] !== undefined) {
        acc[key] = obj[key];
      }
      return acc;
    }, {});
  }

  function convertDepartmentsIdsToLabels(
    departmentsIds: string[] | null | undefined,
  ): string[] | null | undefined {
    if (departmentsIds === null) return null;
    if (departmentsIds !== undefined) {
      return departmentsIds.length === 0
        ? []
        : departmentsIds.reduce<string[]>((acc, departmentId) => {
          const department = Object.values(allDepartments)
            .filter(ArrayUtils.isDefined)
            .find(({ id }) => id === departmentId);
          return department ? [...acc, department.label] : acc;
        }, []);
    }
    return undefined;
  }

  const {
    datesRange: defaultDatesRange,
    categories: defaultCategories,
    apps: defaultApps,
    people: defaultPeople,
    organizations: defaultOrganizations,
    directionality: defaultDirectionality,
  } = defaultMasterFilters;

  function adjustGroupSelections(
    groupsParent: HierarchicalGroup | undefined,
    flatParamsGroupKey: keyof FlatUrlParams,
    flatParamsItemKey: keyof FlatUrlParams,
  ): void {
    if (groupsParent) {
      groupsParent.deselect();
      const groups = flatParamsConverted[flatParamsGroupKey] as
        | string[]
        | null
        | undefined;
      groups?.forEach((category) => groupsParent.getChild(category)?.select());

      const items = flatParamsConverted[flatParamsItemKey] as
        | string[]
        | null
        | undefined;
      if (items) {
        items.forEach((itemToSelectId) => {
          groupsParent.getChildrenEntries().forEach(([groupId, group]) => {
            if (
              !flatParamsConverted.categories
              || !flatParamsConverted.categories.includes(groupId)
            ) {
              const tagToSelect = group.isParent() && group.getChild(itemToSelectId);
              if (tagToSelect) tagToSelect.select();
            }
          });
        });
      }
    }
  }
  function parseBoolean(key: keyof FlatUrlParams, defaultValue: boolean): boolean {
    const value = flatParams[key];
    return value !== undefined ? value as boolean : defaultValue;
  }

  const nextCategories = (flatParams.categories
    || flatParams.tags
    || flatParams.includeUntagged !== undefined)
    ? {
      tagsByCategories: defaultCategories.tagsByCategories.clone(),
      includeUntagged: parseBoolean('includeUntagged', defaultCategories.includeUntagged),
    }
    : undefined;

  if (flatParams.categories || flatParams.tags) {
    adjustGroupSelections(nextCategories!!.tagsByCategories, 'categories', 'tags');
  }

  const nextApps = (flatParams.apps || flatParams.channels)
    ? defaultApps.clone()
    : undefined;
  adjustGroupSelections(nextApps, 'apps', 'channels');

  const departmentsLabels = convertDepartmentsIdsToLabels(flatParams.departments);
  const nextPeople: MasterFilters['people'] = new PeopleMasterFilter(
    departmentsLabels === undefined ? defaultPeople.departments : departmentsLabels,
    UrlParamsUtils.parseTeamsIdsFilter(flatParams, defaultPeople.teams),
    flatParams.peopleIds === undefined ? defaultPeople.internalPeopleIds : flatParams.peopleIds,
    parseBoolean('includeUnidentified', defaultPeople.includeUnidentified),
  );

  const nextOrganizations: MasterFilters['organizations'] = new OrganizationsMasterFilter({
    organizationsIds: UrlParamsUtils.parseOrganizationsIdsFilter(flatParams, defaultOrganizations),
    selectedRisks: UrlParamsUtils.parseOrganizationsRiskFilter(flatParams, defaultOrganizations),
    selectedSizes: UrlParamsUtils.parseOrganizationsSizeFilter(flatParams, defaultOrganizations),
    selectedSegments: UrlParamsUtils.parseOrganizationsSegmentFilter(
      flatParams,
      defaultOrganizations,
    ),
  });

  const nextDirectionality = UrlParamsUtils.parseDirectionalityFilter(
    flatParams,
    defaultDirectionality,
  );

  return removeUndefinedValues({
    datesRange: {
      from: flatParams.from || defaultDatesRange.from,
      to: flatParams.to || defaultDatesRange.to,
    },
    categories: nextCategories,
    people: nextPeople,
    organizations: nextOrganizations,
    apps: nextApps,
    directionality: nextDirectionality,
  });
}

export function extractParamsFromUrl(searchQuery: string): Partial<FlatUrlParams> {
  if (searchQuery.length === 0) return {};

  const ENCODED_COMMA = encodeURIComponent(',');
  return searchQuery.substring(1).split('&').reduce<Partial<FlatUrlParams>>((acc, param) => {
    const [key, value] = param.split('=');

    if (isFlatURLParamsKey(key)) {
      const keyAsString = key as string;

      if (UrlParamsUtils.isDateFilter(key)) {
        const utcDate = UrlParamsUtils.convertUrlParamToDate(value);
        acc[keyAsString] = (key === 'from' ? startOfUTCDay : endOfUTCDay)(utcDate);
      } else if (
        UrlParamsUtils.isHierarchicalGroupFilter(key)
        || UrlParamsUtils.isArrayFilter(key)
      ) {
        acc[keyAsString] = value.length === 0
          ? []
          : value.split(ENCODED_COMMA).map((element) => decodeURIComponent(element));
      } else if (UrlParamsUtils.isBooleanFilter(key)) {
        if (value === 'true') {
          acc[keyAsString] = true;
        } else if (value === 'false') {
          acc[keyAsString] = false;
        }
      } else {
        acc[keyAsString] = new Set(
          value.length === 0
            ? []
            : value.split(ENCODED_COMMA).map((element) => decodeURIComponent(element)),
        );
      }
    }

    return acc;
  }, {});
}

const getOnlyMasterFiltersUrlParamsFromSearch = (search: string): URLSearchParams => {
  const masterFilterUrlParams = new URLSearchParams(search);
  const nonMasterFiltersParamKeys: string[] = [];
  masterFilterUrlParams.forEach((_, key) => {
    if (!isFlatURLParamsKey(key)) {
      nonMasterFiltersParamKeys.push(key);
    }
  });
  nonMasterFiltersParamKeys.forEach((key) => {
    masterFilterUrlParams.delete(key);
  });
  return masterFilterUrlParams;
};

export default function useMasterFiltersFromUrlParams(): Partial<MasterFilters> {
  const { tenant } = useTenantContext();
  const defaultCurrentFilters = useDefaultMasterFilters();
  const { search } = useLocation();
  const masterFilterUrlParams = getOnlyMasterFiltersUrlParamsFromSearch(search);

  return useMemo(() => {
    const params = extractParamsFromUrl(search);
    const masterFiltersFromUrl = convertUrlParamsToMasterFilters(
      params,
      tenant.departments,
      defaultCurrentFilters,
    );

    masterFiltersFromUrl.version = ObjectUtils.isEmpty(masterFiltersFromUrl)
      ? defaultCurrentFilters.version
      : defaultCurrentFilters.version + 1;

    return masterFiltersFromUrl;
  }, [masterFilterUrlParams.toString()]);
}
