import React from 'react';

import OrganizationBadge from 'common-ui-components/Badge/OrganizationBadge';
import App from 'global/lists/apps';
import OrganizationEvent from 'model/Events/OrganizationEvent';
import EventAppBadge from 'screens/platform/cross-platform-components/EventsPanel/EventCell/EventAppBadge';

import style from 'screens/platform/cross-platform-components/EventsPanel/EventCell/EventTitle/style.module.scss';

interface Props {
  event: OrganizationEvent;
}

export default function FirstEngagementTitle({ event }: Props) {
  const { organizationId, appId } = event;

  function getEngagementLabel(app: App): string {
    switch (app) {
      case App.SLACK: return 'conversation';
      case App.GOOGLE_GMAIL: return 'correspondence';
      case App.ATLASSIAN: return 'ticket';
      case App.GOOGLE_CALENDAR: return 'meeting';
      case App.DROPBOX:
      case App.GOOGLE_DRIVE:
        return 'document';
      default: return 'interaction';
    }
  }

  return (
    <div className={style.title}>
      {`First ${getEngagementLabel(appId)} with`}
      <OrganizationBadge organizationId={organizationId} />
      in
      <EventAppBadge appId={appId} />
    </div>
  );
}
