import React from 'react';

import ShowAllButton from 'common-ui-components/Sources/ShowAllButton';
import SourcesList from 'common-ui-components/Sources/SourcesList';
import { Heading3 } from 'common-ui-components/Typograhpy';
import { EnrichedSource } from 'es-src/screens/HomeScreen/EnterpriseSearchContext/EnrichedSourcesDataHook';
import { useMountedState, useToggle } from 'utils/hooks';

import style from 'common-ui-components/Sources/style.module.scss';

interface Props {
  sources: EnrichedSource[];
}

export default function Sources({ sources }: Props) {
  const [shouldShowAllSources, toggleSourcesView] = useToggle(false);
  const [shouldAllowShowMore, setShouldAllowShowMore] = useMountedState(false);

  if (sources.length === 0) return null;

  return (
    <div className={style.sources}>
      <Heading3>{`Sources (${sources.length})`}</Heading3>
      <div className={style.sourcesList}>
        <SourcesList
          sources={sources}
          minimized={!shouldShowAllSources}
          setShouldAllowShowMore={setShouldAllowShowMore}
        />
        {shouldAllowShowMore && (
          <ShowAllButton onClick={toggleSourcesView} arrowDirection={shouldShowAllSources ? 'up' : 'down'} />
        )}
      </div>
    </div>
  );
}
