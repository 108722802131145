import { useMemo } from 'react';

import ArrayUtils from 'utils/ArrayUtils';

const TICKS_AMOUNT = 5;

export default function useLeftAxisTickValues(maxVolume: number) {
  return useMemo(() => {
    const ticksDiff = maxVolume / TICKS_AMOUNT;
    const ticksDiffRoundedTo10 = Math.ceil(ticksDiff / 10) * 10;
    return ArrayUtils.getIntegers(TICKS_AMOUNT + 1).map((_, index) => index * ticksDiffRoundedTo10);
  }, [maxVolume]);
}
