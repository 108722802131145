import { useEnterpriseSearchContext } from 'es-src/screens/HomeScreen/EnterpriseSearchContext/EnterpriseSearchContext';
import useHasAnswer from 'es-src/screens/HomeScreen/hooks/HasAnswerHook';
import useHasSearchResults from 'es-src/screens/HomeScreen/hooks/HasSearchResultsHook';

export default function useCouldNotFindAnyResults() {
  const { hasError } = useEnterpriseSearchContext();

  const hasSearchResults = useHasSearchResults();
  const hasAnswer = useHasAnswer();

  return hasError === null && !hasSearchResults && !hasAnswer;
}
