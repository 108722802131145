import {
  getRequest,
  HttpResponse,
  postRequest,
} from 'global/api/platformApiHelpers';
import ItemsByGroup from 'global/ItemsByGroup';
import Channel from 'model/Channel';
import Tenant from 'model/Tenant';

const ChannelController = {
  async getChannelsByAppsByQuery(tenantId: Tenant['id'], query?: string):
    HttpResponse<ItemsByGroup[]> {
    return getRequest('channel/query', {
      tenantId,
      query,
    });
  },

  async findChannelsByIds(
    tenantId: Tenant['id'],
    ids: string[],
  ): HttpResponse<Record<string, Channel>> {
    return postRequest('channel', ids, { params: { tenantId } });
  },
};

export default ChannelController;
