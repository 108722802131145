import React from 'react';

import MultiTagsBadges from 'common-ui-components/MultiTagsBadges';
import InteractionsSummaryMetadataSection
  from 'screens/platform/cross-platform-components/Interactions/components/InteractionsSummary/InteractionsSummaryMetadata/InteractionsSummaryMetadataSection';

import { ReactComponent as TagsSectionSkeleton } from 'assets/img/interactionsTable/tags-section-skeleton.svg';

import style
  from 'screens/platform/cross-platform-components/Interactions/components/InteractionsSummary/InteractionsSummaryMetadata/style.module.scss';

interface Props {
  loading: boolean;
  tags: string[] | undefined;
}

export default function InteractionsSummaryMetadataTagsSection({
  loading: _loading, tags,
}: Props) {
  const loading = _loading || tags === undefined;

  if (!loading && tags.length === 0) {
    return null;
  }

  return (
    <InteractionsSummaryMetadataSection title="Tags">
      {loading
        ? <TagsSectionSkeleton className={style.skeleton} />
        : <MultiTagsBadges tags={tags} />}
    </InteractionsSummaryMetadataSection>
  );
}
