import classNames from 'classnames';
import React from 'react';
import { useHistory } from 'react-router-dom';

import Button from 'common-ui-components/Button';
import ScreenContentCard from 'common-ui-components/ScreenContentCard';
import { INTERCOM_CONTACT_US_ID } from 'global/Intercom';
import { DEFAULT_PLATFORM_LANDING_PAGE } from 'routers/PlatformRouter/platformRoutesConfig';

import style from 'routers/NotFoundPage/style.module.scss';

interface Props {
  padded?: boolean;
}

export default function NotFoundPage({ padded = false }: Props) {
  const history = useHistory();

  return (
    <div className={classNames(style.notFoundPageContainer, !padded && style.noPadding)}>
      <ScreenContentCard fullScreen className={style.card}>
        <h1 className={style.header}>404</h1>
        <div className={style.content}>
          <h2 className={style.title}>Page Not Found</h2>
          <p className={style.subtitle}>
            We are sorry, but we couldn&apos;t find the page you were looking for.
          </p>
        </div>
        <div className={style.actions}>
          <Button
            onClick={() => history.push(DEFAULT_PLATFORM_LANDING_PAGE)}
            rounded
            padded
            color="blue"
          >
            Take me home
          </Button>
          <Button
            id={INTERCOM_CONTACT_US_ID}
            rounded
            padded
            color="gray"
            className={style.contactUs}
          >
            Contact Us
          </Button>
        </div>
      </ScreenContentCard>
    </div>
  );
}
