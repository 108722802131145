import { SliceTooltipProps } from '@nivo/line';
import classNames from 'classnames';
import React from 'react';

import ColorSquare from 'common-ui-components/ColorSquare';
import { getSignificanceColorByPercentage } from 'global/lists/significanceColors';
import { getVolumeLabelByPercentage } from 'global/lists/volumeLabels';
import { DateRangeGranularity, GroupType } from 'screens/platform/contentScreens/AnalyticsScreen/widgets/widgetConfig';
import useLineChartTooltipData
  from 'screens/platform/cross-platform-components/VolumeTimeLineChart/hooks/LineChartTooltipDataHook';
import extractTagLabel from 'screens/platform/cross-platform-components/VolumeTimeLineChart/utils';

import style from 'screens/platform/contentScreens/AnalyticsScreen/widgets/chartTooltipStyle.module.scss';

interface Props {
  slice: SliceTooltipProps['slice'];
  group: GroupType | null;
  granularity?: DateRangeGranularity;
  maxVolume: number;
}

export default function LineChartTooltip({
  slice, maxVolume, group, granularity,
}: Props) {
  const tooltipData = useLineChartTooltipData(slice, granularity);
  if (!tooltipData) return null;

  const { points, removedPoints, title } = tooltipData;

  return (
    <div className={style.tooltip}>
      <div className={classNames(style.row, style.title)}>
        {title}
      </div>
      {
        points
          .map((rowData) => {
            const tagLabel = extractTagLabel(rowData.id);
            const volume = rowData.data.y;
            const volumePercentage = 100 * (volume / maxVolume);
            const valueLabel = getVolumeLabelByPercentage(volumePercentage);
            const color = group
              ? rowData.color
              : getSignificanceColorByPercentage(volumePercentage);
            return (
              <div className={style.row} key={tagLabel}>
                <div className={style.left}>
                  <ColorSquare color={color} />
                  <span className={style.label}>{tagLabel}</span>
                </div>
                <div>
                  <span className={style.value}>{`${volume.toFixed(1)} (${valueLabel})`}</span>
                </div>
              </div>
            );
          })
      }
      {
        removedPoints && (
          <div className={classNames(style.row, style.hiddenRowsMsg)}>
            {`...${removedPoints.amount} more, with a maximum value of ${removedPoints.maxValue.toFixed(1)}`}
          </div>
        )
      }
    </div>
  );
}
