import { TablePaginationConfig } from 'antd';
import {
  Dispatch, SetStateAction, useEffect, useState,
} from 'react';

import LocalStorageUtils, { StorageKeyPageSize } from 'utils/LocalStorageUtils';

const DEFAULT_PAGE_SIZE_OPTIONS = [10, 20, 50];
export const FIRST_PAGE_INDEX = 1;

export const clientPageToRequestSkip = (page: number, pageSize: number) => (page - 1) * pageSize;

export interface Pagination extends TablePaginationConfig {
  current: number;
  pageSize: number;
}

function getInitialPageSize(
  key: StorageKeyPageSize | null,
  defaultPageSize: number,
  pageSizeOptions: number[],
): number {
  if (key === null) {
    return defaultPageSize;
  }

  const persistedValue = LocalStorageUtils.getItemWithDefaultValue<number>(
    key,
    defaultPageSize,
  );

  return pageSizeOptions.includes(persistedValue)
    ? persistedValue
    : defaultPageSize;
}

export default function usePagination(
  key: StorageKeyPageSize | null,
  total: number | undefined,
  _pageSizeOptions?: number[],
): [Pagination, Dispatch<SetStateAction<number>>] {
  const pageSizeOptions = _pageSizeOptions?.[0] !== undefined
    ? _pageSizeOptions
    : DEFAULT_PAGE_SIZE_OPTIONS;
  const defaultPageSize = pageSizeOptions[0];

  const initialPageSize = getInitialPageSize(key, defaultPageSize, pageSizeOptions);

  const [pageSize, setPageSize] = useState(initialPageSize);
  const [currentPage, setCurrentPage] = useState(FIRST_PAGE_INDEX);

  useEffect(() => {
    if (key !== null) {
      LocalStorageUtils.setItem<number>(key, pageSize);
    }
  }, [pageSize]);

  return [
    {
      pageSizeOptions,
      defaultPageSize,
      pageSize,
      current: currentPage,
      total,
      showSizeChanger: pageSizeOptions.length > 1,
      onChange: (nextPage, nextPageSize) => {
        setCurrentPage(nextPage);
        setPageSize(nextPageSize);
      },
    },
    setCurrentPage,
  ];
}
