import React, { FC } from 'react';

import Button from 'common-ui-components/Button';
import { useMasterFilters } from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFiltersContext';

import { ReactComponent as AlertTriangleIcon } from 'assets/img/icon/alert-triangle.svg';
import { ReactComponent as DatabaseIcon } from 'assets/img/icon/database.svg';
import { ReactComponent as MagnifyingGlassIcon } from 'assets/img/icon/magnifying-glass-error.svg';

import styles from 'screens/platform/cross-platform-components/ChartStatesHandler/DataRetrievalErrorMessage/style.module.scss';

export type ErrorType =
  | 'INSUFFICIENT_DATA'
  | 'NETWORK_ERROR'
  | 'NO_ACTIVITY_FOUND';

interface NoDataErrorProps {
  icon: FC;
  title: string;
  subtitle: string;
}

function getErrorData(errorType: ErrorType) {
  const errorComponentMap: Record<ErrorType, NoDataErrorProps> = {
    INSUFFICIENT_DATA: {
      icon: DatabaseIcon,
      title: 'Insufficient data',
      subtitle: "We couldn't find enough data",
    },
    NETWORK_ERROR: {
      icon: AlertTriangleIcon,
      title: 'Something went wrong',
      subtitle: 'Please try again in a few minutes',
    },
    NO_ACTIVITY_FOUND: {
      icon: MagnifyingGlassIcon,
      title: 'No activity was found',
      subtitle: "We tried searching for your activity, but couldn't find any",
    },
  };
  return errorComponentMap[errorType];
}

interface Props {
  errorType: ErrorType;
}

export default function DataRetrievalErrorMessage({ errorType }: Props) {
  const { dispatchMasterFilters } = useMasterFilters();
  const resetMasterFilters = () => dispatchMasterFilters({ type: 'RESET_FILTERS' });

  const { icon: ErrorIcon, title, subtitle } = getErrorData(errorType);

  return (
    <div className={styles.message}>
      <ErrorIcon />
      <h2>
        {title}
      </h2>
      <span>
        {subtitle}
        <br />
        {errorType === 'INSUFFICIENT_DATA' && (
          <Button onClick={resetMasterFilters}>
            Clear filters
          </Button>
        )}
      </span>
    </div>
  );
}
