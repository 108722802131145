import classNames from 'classnames';
import React, { ReactElement, ReactNode, useState } from 'react';

import CarouselNavigation from 'common-ui-components/Carousel/CarouselNavigation';
import CSSVariableDefiner from 'common-ui-components/CSSVariableDefiner';
import { Heading4 } from 'common-ui-components/Typograhpy';
import SkeletonElement from 'es-src/components/ui/Skeleton/SkeletonElement';
import SkeletonShimmer from 'es-src/components/ui/Skeleton/SkeletonShimmer';
import ArrayUtils from 'utils/ArrayUtils';

import style from 'common-ui-components/Carousel/style.module.scss';

const ITEMS_TO_SHOW = 2;
const ITEMS_TO_SKIP = 1;

interface Props<T> {
  items: T[] | undefined;
  renderChild: (item: T) => ReactNode;
  title: string;
  skeletonItem: ReactNode;
  loading: boolean;
  error: boolean;
  errorMessage: ReactElement;
}

export default function Carousel<T extends { id: string }>({
  items, title, renderChild, skeletonItem, loading, error, errorMessage,
}: Props<T>) {
  const [activeIndex, setActiveIndex] = useState(0);

  const noData = (!items || ArrayUtils.isEmpty(items)) && !error;

  if (loading) return <CarouselSkeleton skeletonItem={skeletonItem} />;

  if (noData) return null;

  const itemsLength = items?.length ?? 0;

  const goToNextSlide = () => {
    setActiveIndex((prevIndex) => Math.min(itemsLength, prevIndex + ITEMS_TO_SKIP));
  };

  const goToPreviousSlide = () => {
    setActiveIndex((prevIndex) => Math.max(0, prevIndex - ITEMS_TO_SKIP));
  };

  const translateX = `${(-activeIndex * 100)}%`;

  return (
    <div className={classNames(style.carousel, error && style.error)}>
      <div className={style.header}>
        <Heading4>{title}</Heading4>
        <CarouselNavigation
          activeIndex={activeIndex}
          itemsLength={itemsLength}
          itemsToShow={ITEMS_TO_SHOW}
          goToPreviousSlide={goToPreviousSlide}
          goToNextSlide={goToNextSlide}
        />
      </div>
      { error
        ? errorMessage
        : (
          <CSSVariableDefiner variables={{ '--transform-value': translateX }}>
            <ul className={style.carouselContent}>
              {items?.map((item) => (
                <CarouselItem key={item.id}>
                  {renderChild(item)}
                </CarouselItem>
              ))}
            </ul>
          </CSSVariableDefiner>
        )}
    </div>
  );
}

function CarouselItem({ children }: { children: ReactNode }) {
  return children !== null ? (
    <li className={style.carouselItem}>
      {children}
    </li>
  ) : null;
}

function CarouselSkeleton({ skeletonItem }: { skeletonItem: ReactNode }) {
  return (
    <div className={classNames(style.carousel, style.skeleton)} role="progressbar">
      <div className={style.header}>
        <SkeletonElement type="title" />
        <div className={style.navigation}>
          <div className={style.carouselButton}>
            <SkeletonElement type="avatar" />
          </div>
          <div className={style.carouselButton}>
            <SkeletonElement type="avatar" />
          </div>
        </div>
      </div>
      <ul className={style.carouselContent}>
        {[{ id: '1' }, { id: '2' }].map((item) => (
          <CarouselItem key={item.id}>
            {skeletonItem}
          </CarouselItem>
        ))}
      </ul>
      <SkeletonShimmer />
    </div>
  );
}
