import React from 'react';

import EnterpriseSearchResult from 'es-src/screens/HomeScreen/components/ResultSection/EnterpriseSearchResult';
import RegularSearchResults from 'es-src/screens/HomeScreen/components/ResultSection/RegularSearchResults';
import SuggestedQuestions from 'es-src/screens/HomeScreen/components/SuggestedQuestions';
import { useEnterpriseSearchContext } from 'es-src/screens/HomeScreen/EnterpriseSearchContext/EnterpriseSearchContext';
import useCouldNotFindAnyResults from 'es-src/screens/HomeScreen/hooks/CouldNotFindAnyResultsHook';

export default function ResultSection() {
  const {
    hasError, loading, isResultCardOpen,
  } = useEnterpriseSearchContext();
  const couldNotFindAnyResults = useCouldNotFindAnyResults();

  const shouldShowSuggestedQuestions = !loading && isResultCardOpen && (couldNotFindAnyResults || hasError === 'network');

  return (
    <>
      <EnterpriseSearchResult />
      <RegularSearchResults />
      { shouldShowSuggestedQuestions && <SuggestedQuestions /> }
    </>
  );
}
