import React from 'react';

import Button from 'common-ui-components/Button';

export default function OptionalButton({
  children, onClick, className, title, ...props
}) {
  return onClick ? (
    <Button onClick={onClick} className={className} title={title} {...props}>
      {children}
    </Button>
  ) : (
    <div className={className} data-testid={props.testId}>{children}</div>
  );
}
