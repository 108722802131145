import { Atom } from 'model/Atom';
import usePersonsMetadata from 'screens/platform/cross-platform-components/context/metadata/hooks/personsMetadataHook';
import { useMetadataContext } from 'screens/platform/cross-platform-components/context/metadata/MetadataContext';
import AtomUtils from 'utils/AtomUtils';

export default function useInteractionCardData(atom: Atom) {
  const { isTagTopic } = useMetadataContext();
  const { tags } = atom;

  const sortedParticipants = AtomUtils.sortParticipantsIdsByPercentage(atom);

  const { value: persons } = usePersonsMetadata(sortedParticipants);

  const internalIds = sortedParticipants.filter((id) => !persons?.[id]?.isExternal);
  const externalIds = sortedParticipants.filter((id) => persons?.[id]?.isExternal);

  const tagsArr = Object.keys(tags).filter(isTagTopic);

  return {
    tagsArr,
    externalIds,
    internalIds,
    persons,
  };
}
