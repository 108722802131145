import developmentConfig from 'global/environments/development';
import EnvironmentConfig from 'global/environments/environment';
import localConfig from 'global/environments/local';
import productionConfig from 'global/environments/production';
import stagingConfig from 'global/environments/staging';
import Environment from 'global/lists/environments';

function getBaseConfig() {
  switch (process.env.CLIENT_ENV) {
    case Environment.PRODUCTION: return productionConfig;
    case Environment.STAGING: return stagingConfig;
    case Environment.LOCAL: return localConfig;
    default: return developmentConfig;
  }
}

function getApiBaseUrl(): string {
  if (process.env.IS_API_RUNNING_LOCALLY === 'true') {
    return localConfig.apiBaseUrl;
  }

  switch (process.env.API_ENV) {
    case Environment.PRODUCTION: return productionConfig.apiBaseUrl;
    case Environment.STAGING: return stagingConfig.apiBaseUrl;
    case Environment.DEVELOPMENT: return developmentConfig.apiBaseUrl;
    case Environment.LOCAL: return localConfig.apiBaseUrl;
    default: return getBaseConfig().apiBaseUrl;
  }
}

function getFirebaseConfig(): EnvironmentConfig['firebaseConfig'] {
  switch (process.env.API_ENV) {
    case Environment.PRODUCTION: return productionConfig.firebaseConfig;
    case Environment.STAGING: return stagingConfig.firebaseConfig;
    case Environment.DEVELOPMENT: return developmentConfig.firebaseConfig;
    case Environment.LOCAL: return localConfig.firebaseConfig;
    default: return getBaseConfig().firebaseConfig;
  }
}

function getConfigByCurrentEnvironment() {
  const config = getBaseConfig();

  config.apiBaseUrl = getApiBaseUrl();
  config.firebaseConfig = getFirebaseConfig();

  return config;
}

export function isRunningLocally() {
  return typeof window !== 'undefined'
    && window.location.href.indexOf('http://localhost') > -1;
}

// Returns a singleton
export default getConfigByCurrentEnvironment();
