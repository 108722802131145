import {
  Dispatch, SetStateAction, useEffect, useRef, useState,
} from 'react';

interface Props {
  isSelectionEmpty: boolean;
  allowEmptySelection: boolean;
  shouldClearSearchInput?: boolean;
  setSearchQuery: Dispatch<SetStateAction<string>>;
}

export default function useHierarchicalSearchInput({
  isSelectionEmpty,
  allowEmptySelection,
  shouldClearSearchInput,
  setSearchQuery,
}: Props) {
  const [isDisabled, setIsDisabled] = useState(!allowEmptySelection && isSelectionEmpty);
  const searchInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (shouldClearSearchInput) {
      setSearchQuery('');
      searchInputRef.current?.blur();
    }
  }, [shouldClearSearchInput]);

  function emptySelectionHandler(isEmpty: boolean) {
    if (!allowEmptySelection) {
      setIsDisabled(isEmpty);
    }
  }

  return {
    isDisabled, emptySelectionHandler, searchInputRef,
  };
}
