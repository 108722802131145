import React, { ReactNode } from 'react';

import Button from 'common-ui-components/Button';
import Tooltip from 'common-ui-components/Tooltip';
import Intercom from 'global/Intercom';

import { ReactComponent as ExternalLinkIcon } from 'assets/img/icon/external-link.svg';
import { ReactComponent as InfoIcon } from 'assets/img/icon/info.svg';

import style from 'common-ui-components/IntercomArticleButton/style.module.scss';
import internalLinkStyle from 'common-ui-components/InternalLink/style.module.scss';

export type ArticleConfig = {
  articleID: number;
  articleTooltip: string;
}

type ArticleType = string;

export const articleConfig: Record<ArticleType, ArticleConfig> = {
  Metrics: {
    articleID: 8374667,
    articleTooltip: 'Tracking metrics is essential for being data-driven and making informed decisions about your work, projects, customers, teams, and other aspects of your operation.',
  },
  Objectives: {
    articleID: 8312811,
    articleTooltip: 'Objectives are indications you want to track over time, and in relation to your goals.',
  },
  Events: {
    articleID: 8374672,
    articleTooltip: 'Akooda can automatically detect and surface events, allowing you to seamlessly scroll through all events at the click of a button, turning data into meaningful narratives.',
  },
  People: {
    articleID: 8312788,
    articleTooltip: 'Control which people we see on the platform, including editing attributes and user roles.',
  },
  TagsAndCategories: {
    articleID: 8312768,
    articleTooltip: 'Tags help us understand what topics are top of mind for you and the company so we can deliver insights on what matters most.',
  },
  AppIntegrations: {
    articleID: 8110156,
    articleTooltip: 'The Akooda system handles sensitive organizational data where privacy and security are paramount. Here are the key principles that guide each of our app integrations.',
  },
  Organizations: {
    articleID: 8312796,
    articleTooltip: 'Control which organizations will be seen on the platform, and edit their attributes.',
  },
  NotificationsSubscriptions: {
    articleID: 8312779,
    articleTooltip: 'Akooda notifications keep you informed and up to date on everything happening in your organization using the Slack bot integration.',
  },
  ConnectorSlack: {
    articleID: 8110157,
    articleTooltip: 'This connection essentially provides Akooda with access to all public channels in your entire organization, as well as private ones to which the Akooda bot was manually added by participants.',
  },
  ConnectorDropbox: {
    articleID: 8306454,
    articleTooltip: 'This connection essentially provides Akooda with access to private folders/documents that are manually shared with Akooda.',
  },
  ConnectorGoogleWorkspace: {
    articleID: 8110159,
    articleTooltip: 'This connection essentially provides Akooda with access to public Calendar events and private Drive folders/documents that are shared explicitly with Akooda.',
  },
  ConnectorGoogleGmail: {
    articleID: 8306450,
    articleTooltip: 'This connection essentially provides Akooda with access to emails that were sent to a specific destination inbox under your organization\'s Google Workspace account.',
  },
  ConnectorAtlassian: {
    articleID: 8306484,
    articleTooltip: 'This connection essentially provides Akooda with access to Jira tickets (including Jira Service Management) and Confluence folders/documents that are public to your entire organization.',
  },
  ConnectorGitHub: {
    articleID: 8306467,
    articleTooltip: 'This connection essentially provides Akooda with access to either all or selected repositories and their respective branch interactions in your entire organization.',
  },
  ConnectorMonday: {
    articleID: 8306496,
    articleTooltip: 'This connection essentially provides Akooda with access to workspaces and their respective item interactions in your entire organization.',
  },
  ConnectorFigma: {
    articleID: 8306501,
    articleTooltip: 'This connection essentially provides Akooda with access to teams and their respective file interactions in your entire organization.',
  },
  ConnectorNotion: {
    articleID: 8306490,
    articleTooltip: 'This connection essentially provides Akooda with access to selected databases in your workspace (excluding private ones).',
  },
  ConnectorIntercom: {
    articleID: 8311780,
    articleTooltip: 'This connection essentially provides Akooda with access to all chats.',
  },
  ConnectorPhabricator: {
    articleID: 8374685,
    articleTooltip: 'This connection essentially provides Akooda with access to Phabricator.',
  },
  ConnectorBitBucket: {
    articleID: 8311783,
    articleTooltip: 'This connection essentially provides Akooda with access to all repositories and their respective branch interactions in your entire organization.',
  },
  ConnectorSalesforce: {
    articleID: 8311787,
    articleTooltip: 'This connection provides Akooda with access to accounts, their respective opportunities, and Salesforce reports in your organization.',
  },
  ConnectorZendesk: {
    articleID: 8312696,
    articleTooltip: 'This connection provides Akooda with access to your support tickets and related information.',
  },
  ConnectorAsana: {
    articleID: 8312704,
    articleTooltip: 'This connection essentially provides Akooda with access to teams\' projects in your organization and their respective tasks.',
  },
  ConnectorAkoodAI: {
    articleID: 8191720,
    articleTooltip: 'Get ready to embark on an extraordinary journey into the future of Ops Intelligence with our groundbreaking GenAI capabilities!',
  },
  ConnectorM365: {
    articleID: 8432433,
    articleTooltip: 'This connection essentially provides Akooda with access to SharePoint and OneDrive folders/documents that are shared explicitly with Akooda.',
  },
  ConnectorBox: {
    articleID: 9214853,
    articleTooltip: 'This connection essentially provides Akooda with access to documents that are shared explicitly with Akooda.',
  },
  ConnectorAirtable: {
    articleID: 9208148,
    articleTooltip: 'This connection provides Akooda with access to all selected workspaces.',
  },
  ConnectorM365Exchange: {
    articleID: 9650799,
    articleTooltip: 'This connection essentially provides Akooda with access to emails that were sent to a specific destination inbox under your organization\'s Microsoft Exchange.',
  },
} as const;

interface Props {
  articleConfig: ArticleConfig;
  displayAsGuideLink?: boolean;
  displayAsLink?: boolean;
  children?: ReactNode;
}

export default function IntercomArticleButton({
  articleConfig: { articleID, articleTooltip },
  displayAsGuideLink = false,
  displayAsLink = false,
  children,
}: Props) {
  if (displayAsLink) {
    return <TextLink articleID={articleID}>{children}</TextLink>;
  }

  return displayAsGuideLink
    ? <GuideLink articleID={articleID} />
    : (
      <InfoTooltip articleConfig={{ articleID, articleTooltip }}>
        {children}
      </InfoTooltip>
    );
}

function loadIntercomArticle(articleID) {
  Intercom.showArticle(articleID);
}

function GuideLink({ articleID }: { articleID: number }) {
  return (
    <Button className={style.guideLink} onClick={() => loadIntercomArticle(articleID)}>
      <ExternalLinkIcon className={style.icon} />
      Guide
    </Button>
  );
}

function TextLink({ articleID, children }: { articleID: number; children: ReactNode }) {
  return (
    <Button className={style.textLink} onClick={() => loadIntercomArticle(articleID)}>
      {children}
    </Button>
  );
}

type InfoTooltipProps = Pick<Props, 'articleConfig' | 'children'>

function InfoTooltip({
  articleConfig: { articleID, articleTooltip },
  children,
}: InfoTooltipProps) {
  return (
    <div className={style.titleWithInfo}>
      {children}
      <Tooltip
        theme="dark"
        className="badge-tippy"
        interactive
        content={(
          <p>
            {articleTooltip}
            {' '}
            <a href="#" onClick={() => loadIntercomArticle(articleID)} className={internalLinkStyle.internalLink}>Learn more</a>
          </p>
        )}
      >
        <Button
          className={style.infoButton}
          onClick={loadIntercomArticle}
        >
          <InfoIcon height={16} width={16} />
        </Button>
      </Tooltip>
    </div>
  );
}
