import EnvironmentConfig from 'global/environments/environment';

const productionConfig: EnvironmentConfig = {
  firebaseConfig: {
    apiKey: process.env.REACT_APP_FIREBASE_PROD_API_KEY as string,
    authDomain: 'akooda-de4bc.firebaseapp.com',
    databaseURL: 'https://akooda-de4bc.firebaseio.com',
    projectId: 'akooda-de4bc',
    storageBucket: 'akooda-de4bc.appspot.com',
    messagingSenderId: '1064312932524',
    appId: '1:1064312932524:web:56a5181a85a337a7f3f709',
    measurementId: 'G-KL3XXKBZ73',
  },
  apiBaseUrl: 'https://api.akooda.co/api/v1',
  appUrl: 'https://app.akooda.co',
  segmentWriteKey: process.env.SEGMENT_PROD_WRITE_KEY as string,
};

export default productionConfig;
