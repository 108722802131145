import React, { useMemo } from 'react';

import Spinner from 'common-ui-components/Spinner';
import { Atom } from 'model/Atom';
import { usePlatformContext } from 'screens/platform/cross-platform-components/context/platform/PlatformContext';
import useInteractionsTableColumns from 'screens/platform/cross-platform-components/Interactions/components/InteractionsTable/columnsHook';
import AtomsTable from 'screens/platform/cross-platform-components/Interactions/components/InteractionsTable/components/AtomsTable';
import EmptyDataMessage from 'screens/platform/cross-platform-components/Interactions/components/InteractionsTable/components/EmptyDataMessage';
import useInteractionsDataFetcher from 'screens/platform/cross-platform-components/Interactions/components/InteractionsTable/dataFetchingHook/interactionsDataFetchingHook';
import useInteractionsTableRenderStatus from 'screens/platform/cross-platform-components/Interactions/components/InteractionsTable/InteractionsTableRenderStatusHook';
import { useInteractionsContext } from 'screens/platform/cross-platform-components/Interactions/InteractionsContext';

export default function InteractionsTable() {
  const { interactionsTable: { sorting, setSorting } } = usePlatformContext();
  const { isCollapsible, pagination, loading: { value: isLoading } } = useInteractionsContext();

  const interactionsPage = useInteractionsDataFetcher();
  const { columns, onColumnMoved } = useInteractionsTableColumns();
  const {
    isDuringOpeningTransition,
    shouldRenderInteractionsTable,
  } = useInteractionsTableRenderStatus(isCollapsible);

  const dataSource = useMemo<Atom[] | null>(() => {
    if (shouldRenderInteractionsTable) {
      return interactionsPage;
    }

    return null;
  }, [shouldRenderInteractionsTable, interactionsPage]);

  return dataSource === null ? <Spinner /> : (
    <AtomsTable
      dataSource={dataSource ?? undefined}
      loading={isLoading || isDuringOpeningTransition}
      emptyDataMsg={<EmptyDataMessage />}
      columns={columns}
      pagination={pagination.value}
      sorting={sorting}
      onSortingChange={setSorting}
      onColumnMoved={onColumnMoved}
    />
  );
}
