import App from 'global/lists/apps';
import {
  GithubSubDivisionId,
} from 'screens/platform/cross-platform-components/MasterFiltersPanel/masterFiltersUtils/GithubChannelsUtils';
import {
  SlackSubDivisionId,
} from 'screens/platform/cross-platform-components/MasterFiltersPanel/masterFiltersUtils/SlackChannelsUtils';

// eslint-disable-next-line import/prefer-default-export
export function getAppIdOrSubDivisionsIds(appId: App | string): string[] {
  switch (Number(appId)) {
    case App.SLACK:
      return Object.values(SlackSubDivisionId);
    case App.GITHUB:
      return Object.values(GithubSubDivisionId);
    default:
      return [appId.toString()];
  }
}
