import {
  EventsGroupSummary, EventSignificance, EventsSummary, EventsSummaryByDate, EventsSummaryMap,
} from 'global/api/controller/EventController';
import { ALL_INTERACTIONS } from 'global/labels';
import EventCategory, { categoryToEventTypes, findEventCategory } from 'global/lists/EventCategory';
import { getSignificanceColorByPercentage } from 'global/lists/significanceColors';
import { EventType } from 'model/Events/Event';
import { BubbleChartGroup, BubbleShape } from 'screens/platform/contentScreens/AnalyticsScreen/widgets/EventsChart/BubbleChart';
import { EventsChartDatum, EventsMap } from 'screens/platform/contentScreens/AnalyticsScreen/widgets/EventsChart/EventsChartDataHook';
import { AnalyticsWidgetConfig, GroupType } from 'screens/platform/contentScreens/AnalyticsScreen/widgets/widgetConfig';
import { defaultAnalyticsScreenSettings } from 'screens/platform/cross-platform-components/context/platform/PlatformContextProvider';

export const eventCategoriesToShapes: Record<EventCategory, BubbleShape> = Object.freeze({
  [EventCategory.WORK_EFFORTS]: BubbleShape.CIRCLE,
  [EventCategory.COLLABORATION_PATTERNS]: BubbleShape.DIAMOND,
  [EventCategory.ACCOUNT_MANAGEMENT]: BubbleShape.SQUARE,
  [EventCategory.ORG_STRUCTURE]: BubbleShape.TRIANGLE,
});

const totalGroupIsLastComparator = (eventsGroup: EventsGroupSummary) =>
  (eventsGroup.groupId === null ? 0 : -1);

export function buildChartGroups(
  eventsSummary: EventsGroupSummary[],
  widgetConfig: AnalyticsWidgetConfig,
): BubbleChartGroup<EventsChartDatum>[] {
  const defaultGroupId = getDefaultGroupId(widgetConfig);

  return eventsSummary
    .sort(totalGroupIsLastComparator) // Total event group (e.g. "all tags") should be last
    .map(({ groupId, eventsSummaryByDate }) => ({
      id: groupId ?? defaultGroupId,
      title: groupId ?? defaultGroupId,
      groupData: eventsSummaryByDate
        .map((({ date, eventsSummaryMap }) => {
          const datum = {
            date,
            eventsMap: buildEventsMap(eventsSummaryMap),
            groupId,
          };
          const nodes = splitToNodes(eventsSummaryMap);
          return {
            date, nodes, datum,
          };
        })),
    }));
}

function getDefaultGroupId(widgetConfig: AnalyticsWidgetConfig): string {
  switch (widgetConfig.group) {
    case GroupType.DEPARTMENTS:
      return 'All Departments';
    case GroupType.TAGS:
      return 'All Tags';
    default:
      return ALL_INTERACTIONS;
  }
}

function buildEventsMap(eventsSummaryByDate: EventsSummaryMap): EventsMap {
  return Object
    .entries(eventsSummaryByDate)
    .reduce((acc, [key, events]) => {
      const significance = calculateWeightedSignificanceAverage(events);
      acc[key] = {
        count: events.length,
        color: getSignificanceColorByPercentage(significance),
      };
      return acc;
    }, {} as EventsMap);
}

function calculateWeightedSignificanceAverage(
  significanceArray: EventSignificance[],
): number {
  const HIGH_SIGNIFICANCE_WEIGHT = 1.5;
  const HIGH_SIGNIFICANCE_THRESHOLD = 50;

  if (significanceArray.length === 0) return 0;
  const significanceWeightedSum = significanceArray
    .reduce((acc, significance) => {
      if (significance > HIGH_SIGNIFICANCE_THRESHOLD) {
        return acc + significance * HIGH_SIGNIFICANCE_WEIGHT;
      }
      return acc + significance;
    }, 0);
  return significanceWeightedSum / significanceArray.length;
}

function filterByTypes(
  map: EventsSummaryMap,
  types: EventType[],
): EventsSummaryMap {
  const filteredEvents = Object.entries(map)
    .filter(([type, _]) => types.includes(type as EventType));
  return Object.fromEntries(filteredEvents) as EventsSummaryMap;
}

function calculateColor(map: EventsSummaryMap): string {
  const allEvents = Object.values(map).flat();
  const significance = calculateWeightedSignificanceAverage(allEvents);
  return getSignificanceColorByPercentage(significance);
}

function splitToNodes(summary: EventsSummaryMap) {
  return Object.entries(eventCategoriesToShapes)
    .map(([category, shape]) => ({
      shape: Number(shape) as BubbleShape,
      types: categoryToEventTypes[category as EventCategory],
    })).map(({ types, shape }) => {
      const shapeSummary = filterByTypes(summary, types);
      const color = calculateColor(shapeSummary);
      const size = Object.values(shapeSummary)
        .reduce((acc, events) => acc + events.length, 0);
      return { size, color, shape };
    }).filter((node) => node.size > 0);
}

export function getEventsCategories(
  chartGroups: BubbleChartGroup<EventsChartDatum>[],
): EventCategory[] {
  const allEventsTypes = chartGroups
    .flatMap((group) => group.groupData)
    .map((data) => data.datum.eventsMap)
    .flatMap((eventsMap) => Object.keys(eventsMap) as EventType[]);

  const eventsTypes = [...new Set(allEventsTypes)];
  const eventsCategories = eventsTypes.map((type) => findEventCategory(type));
  return [...new Set(eventsCategories)];
}

export function filterEventsSummary(
  { eventsSummary, maximumWeeklyCount }: EventsSummary,
  widgetConfig: AnalyticsWidgetConfig,
): EventsSummary {
  const filteredEventsSummary = eventsSummary.map((groupSummary) => {
    const { eventsSummaryByDate } = groupSummary;
    return {
      ...groupSummary,
      eventsSummaryByDate: eventsSummaryByDate.map((s) => filterSummaryMap(s, widgetConfig)),
    };
  });

  return {
    maximumWeeklyCount,
    eventsSummary: filteredEventsSummary,
  };
}

function filterSummaryMap(
  summary: EventsSummaryByDate,
  widgetConfig: AnalyticsWidgetConfig,
): EventsSummaryByDate {
  const { settings: { significanceThreshold } } = widgetConfig;
  const defaultThreshold = defaultAnalyticsScreenSettings.significanceThreshold;
  const threshold = 100 - (significanceThreshold || defaultThreshold);
  const filteredSummaryMap = Object.entries(summary.eventsSummaryMap)
    .map(([key, value]) => {
      const filtered = value.filter((significance) => significance >= threshold);
      return [key, filtered];
    })
    .filter(([_, value]) => value.length > 0);

  return {
    ...summary,
    eventsSummaryMap: Object.fromEntries(filteredSummaryMap),
  };
}
