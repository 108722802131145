import React from 'react';

import { AtomsSummary } from 'global/api/controller/AtomController';
import InteractionsSummaryMetadataSection from 'screens/platform/cross-platform-components/Interactions/components/InteractionsSummary/InteractionsSummaryMetadata/InteractionsSummaryMetadataSection';
import InteractionsSummaryMetadataTagsSection from 'screens/platform/cross-platform-components/Interactions/components/InteractionsSummary/InteractionsSummaryMetadata/InteractionsSummaryMetadataTagsSection';
import ParticipantsSection from 'screens/platform/cross-platform-components/Interactions/components/InteractionsSummary/InteractionsSummaryMetadata/ParticipantsSection';

import style from 'screens/platform/cross-platform-components/Interactions/components/InteractionsSummary/InteractionsSummaryMetadata/style.module.scss';

export default function InteractionsSummaryMetadata({
  data, loading: _loading,
}: { data: AtomsSummary | undefined; loading: boolean }) {
  const loading = _loading || data === undefined;

  return (
    <div className={style.metadata}>
      <h3>
        Details
      </h3>
      <InteractionsSummaryMetadataSection title="Engaged">
        <ParticipantsSection
          loading={loading}
          peopleIds={data?.participants.peopleIds}
          departments={data?.participants.departments}
          organizationsIds={data?.participants.organizationsIds}
        />
      </InteractionsSummaryMetadataSection>
      <InteractionsSummaryMetadataTagsSection loading={loading} tags={data?.tags} />
    </div>
  );
}
