import { useHistory } from 'react-router-dom';

import OrganizationFilterableProperty from 'global/api/controller/utils/filtering/OrganizationFilterableProperty';
import PersonFilterableProperty from 'global/api/controller/utils/filtering/PersonFilterableProperty';
import RedirectionData from 'global/RedirectionData';
import { getScreenPath } from 'global/ScreensConfiguration';
import useFilteredDirectoryScreensConfig, { DirectoryScreensConfigMap } from 'routers/DirectoryRouter/DirectoryScreensConfig';
import { FilterConditionsMap } from 'screens/platform/directory/hooks/filterConditionsHook';
import { OrganizationFilterValues } from 'screens/platform/directory/organizations/OrganizationsDirectoryScreen/OrganizationsTable/utils/OrganizationFiltersConfig';
import { PeopleFilterValues } from 'screens/platform/directory/people/PeopleDirectoryScreen/PeopleTable/utils/PeopleFilters/Config';

export type DirectoryRedirectConfig = (
  | {
      screen: 'people';
      filterConditions?: FilterConditionsMap<PersonFilterableProperty>;
      filterValues?: Partial<PeopleFilterValues>;
    }
  | {
      screen: 'organizations';
      filterConditions?: FilterConditionsMap<OrganizationFilterableProperty>;
      filterValues?: Partial<OrganizationFilterValues>;
    }
);

export function getFilteredDirectoryRedirectionData(
  directoryScreensConfig: Partial<DirectoryScreensConfigMap>,
  {
    screen,
    filterConditions,
    filterValues,
  }: DirectoryRedirectConfig,
): RedirectionData<Omit<DirectoryRedirectConfig, 'screen'>> | null {
  const screenConfig = directoryScreensConfig[screen];
  if (!screenConfig) return null;
  return {
    location: getScreenPath(screenConfig.screenKey),
    state: {
      filterConditions,
      filterValues,
    },
  };
}

export default function useRedirectToFilteredDirectory():
  (directoryRedirectConfig: DirectoryRedirectConfig) => void {
  const history = useHistory<Omit<DirectoryRedirectConfig, 'screen'>>();
  const { filteredScreens } = useFilteredDirectoryScreensConfig();

  return (directoryRedirectConfig: DirectoryRedirectConfig) => {
    const redirectionData = getFilteredDirectoryRedirectionData(
      filteredScreens,
      directoryRedirectConfig,
    );
    if (!redirectionData) return;
    history.push(redirectionData.location, redirectionData.state);
  };
}
