import React from 'react';

import FilterConfig, {
  FilterValue,
} from 'screens/platform/directory/components/GenericDirectoryScreen/FiltersList/FilterConfig';
import GenericFilter from 'screens/platform/directory/components/ProfileTabs/components/Filters/GenericFilter';
import useDirectionalityFilterConfig
  from 'screens/platform/directory/components/ProfileTabs/components/Filters/hooks/DirectionalityFilterConfigHook';

interface Props {
  disabled?: boolean;
}

export default function DirectionalityFilter({ disabled }: Props) {
  const filterConfig = useDirectionalityFilterConfig() as FilterConfig<FilterValue>;

  return (
    <GenericFilter
      filterType="directionality"
      filterConfig={filterConfig}
      disabled={disabled}
    />
  );
}
