import React, { ReactNode, useEffect, useRef } from 'react';

interface CSSVariableDefinerProps {
  variables: Record<string, string>;
  children: ReactNode;
  className?: string;
}

export default function CSSVariableDefiner({
  variables,
  children,
  className,
}: CSSVariableDefinerProps) {
  const currentRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (currentRef.current) {
      Object.entries(variables).forEach(([name, value]) => {
        currentRef.current?.style.setProperty(name, value);
      });
    }
  }, [currentRef, variables]);

  return (
    <span ref={currentRef} className={className}>
      { children }
    </span>
  );
}
