import { getRequest, HttpResponse } from 'global/api/platformApiHelpers';
import ConfigurationKey from 'global/lists/ConfigurationKey';
import Tenant from 'model/Tenant';
import { ConfigType } from 'screens/platform/cross-platform-components/context/configuration/ConfigurationContextProvider';

const ConfigurationController = {
  async getConfiguration(
    configKey: ConfigurationKey,
    tenantId: Tenant['id'],
  ): HttpResponse<ConfigType> {
    const encodedId = encodeURIComponent(configKey);
    return getRequest(`configuration/${encodedId}`, { tenantId });
  },
};

export default ConfigurationController;
