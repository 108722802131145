import React from 'react';

import ImportedLottie from 'react-lottie-player/dist/LottiePlayerLight';

interface LottieProps {
  file: any;
  width?: number;
  height?: number;
}

function getDimension(dimension: string | number | undefined) {
  if (typeof dimension === 'number') {
    return `${dimension}px`;
  }
  return dimension;
}

export default function Lottie({
  file, width, height,
}: LottieProps) {
  if (process.env.NODE_ENV === 'test') return null;

  const style = {
    width: getDimension(width),
    height: getDimension(height),
    margin: 'auto',
  };

  return (
    <ImportedLottie
      className="animation"
      style={style}
      loop
      play
      animationData={file}
      rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
    />
  );
}
