import React from 'react';

import { getImageTitleAttributeText, ImageType } from 'common-ui-components/MultiImages/MultiImagesUtils';
import MissingOrganizationProfileWrapper
  from 'common-ui-components/MultiImages/MultiOrganizationsImages/MissingOrganizationProfileWrapper';
import NodeImage, { ImageShape, NodeImageProps } from 'common-ui-components/MultiImages/NodeImage';
import OptionalDirectoryLinkWrapper from 'common-ui-components/MultiImages/OptionalDirectoryLinkWrapper';
import colors from 'global/colors';
import { EXTERNAL_ORGANIZATIONS_DEPARTMENT, UNKNOWN_ORGANIZATION } from 'model/Tenant';
import useOrganizationImage
  from 'screens/platform/cross-platform-components/context/metadata/hooks/OrganizationImageHook';

import style from 'common-ui-components/MultiImages/style.module.scss';

interface OrganizationImageProps extends Omit<NodeImageProps, 'imageUrl' | 'subtitle' | 'shape' | 'borderColor'> {
  organizationId: string;
  hoverable?: boolean;
}

function OrganizationImage(props: OrganizationImageProps) {
  const { organizationId, hoverable } = props;
  const image = useOrganizationImage(organizationId);

  const content = (
    <div
      className={style.organizationImageWrapper}
      title={hoverable ? getImageTitleAttributeText(ImageType.ORGANIZATIONS) : undefined}
    >
      <NodeImage
        {...props}
        subtitle={EXTERNAL_ORGANIZATIONS_DEPARTMENT}
        borderColor={colors.DARK_GRAY}
        shape={ImageShape.SQUARE}
        imageUrl={image}
      />
    </div>
  );

  return organizationId === UNKNOWN_ORGANIZATION
    ? <MissingOrganizationProfileWrapper>{content}</MissingOrganizationProfileWrapper>
    : (
      <OptionalDirectoryLinkWrapper
        to="platform.directory.organizations.profile"
        urlParam={organizationId}
        aria-label="organization badge"
      >
        {content}
      </OptionalDirectoryLinkWrapper>
    );
}

const MultiOrganizationsImagesUtils = { OrganizationImage };
export default MultiOrganizationsImagesUtils;
