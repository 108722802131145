import {
  deleteRequest,
  getRequest,
  HttpResponse,
  postRequest,
  putRequest,
} from 'global/api/platformApiHelpers';
import Subscription from 'model/Subscription';
import Tenant from 'model/Tenant';

const SubscriptionController = {
  async getSubscriptions(tenantId: Tenant['id']): HttpResponse<Subscription[]> {
    return getRequest('subscription', { tenantId });
  },
  async createSubscription(
    tenantId: Tenant['id'],
    subscription: Partial<Subscription>,
  ): HttpResponse<Subscription> {
    return postRequest('subscription', subscription, {
      params: { tenantId },
    });
  },
  async editSubscription(
    tenantId: Tenant['id'],
    subscription: Partial<Subscription>,
  ): HttpResponse<Subscription> {
    return putRequest('subscription', subscription, {
      params: { tenantId },
    });
  },
  async deleteSubscription(
    tenantId: Tenant['id'],
    subscriptionId: Subscription['id'],
  ): HttpResponse<Subscription> {
    return deleteRequest('subscription', { tenantId, subscriptionId });
  },
};

export default SubscriptionController;
