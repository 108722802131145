import { useMemo, useState } from 'react';

import Api from 'global/api/platformApi';
import { HttpResponse } from 'global/api/platformApiHelpers';
import ItemsByGroup from 'global/ItemsByGroup';
import useTenantContext from 'screens/platform/cross-platform-components/context/tenant/TenantContext';
import {
  HierarchicalItemType,
} from 'screens/platform/cross-platform-components/HierarchicalSearchInput/HierarchicalItemTypeConfig';
import {
  SearchableHierarchicalItemTypes,
} from 'screens/platform/cross-platform-components/HierarchicalSearchInput/HierarchicalSearchInputWrapper';
import {
  UNIDENTIFIED_APPS_LABEL,
  useIdentifiedAppsClassification,
} from 'screens/platform/cross-platform-components/MasterFiltersPanel/FilterSection/AppsFilterSection/AppsFilterSectionUtils';
import { useAsyncFetchingByQuery } from 'utils/hooks';
import ObjectUtils from 'utils/ObjectUtils';

export default function useHierarchicalItemsSearch(
  type: Exclude<SearchableHierarchicalItemTypes, HierarchicalItemType.PERSON>,
  searchQuery: string,
) {
  const { tenant } = useTenantContext();
  const { unidentifiedAppsIds } = useIdentifiedAppsClassification();

  const [itemsByGroups, setItemsByGroups] = useState<null | ItemsByGroup[]>(
    null,
  );

  const apiFetchFunction = useMemo<(tenantId: number, query: string) =>
    HttpResponse<ItemsByGroup[]>>(() => {
      switch (type) {
        case HierarchicalItemType.CHANNEL:
          return Api.Channel.getChannelsByAppsByQuery;
        case HierarchicalItemType.TAG:
          return Api.TagsSet.getTagsSetByCategories;
        default:
          throw new Error(`Fetching HierarchicalItemType of type '${type}' isn't supported`);
      }
    }, [type]);

  const sortAndSetItemsByGroups = ((next: null | ItemsByGroup[]) => {
    let sorted = next === null
      ? null
      : next.sort(ObjectUtils.compareByKeyAlphabetically('group'));

    if (sorted) {
      sorted = sorted.map((group) => ({ ...group, items: group.items.sort() }));
    }

    setItemsByGroups(() => {
      if (
        type === HierarchicalItemType.CHANNEL
        && sorted !== null
        && unidentifiedAppsIds.length > 0
        && UNIDENTIFIED_APPS_LABEL.toLowerCase().includes(searchQuery.toLowerCase())
      ) {
        return [...sorted, { group: UNIDENTIFIED_APPS_LABEL, items: [] }];
      }
      return sorted;
    });
  }) as typeof setItemsByGroups;

  useAsyncFetchingByQuery<ItemsByGroup[]>(
    searchQuery,
    sortAndSetItemsByGroups,
    (query) => apiFetchFunction(tenant.id, query),
  );

  return itemsByGroups;
}
