import { useMemo } from 'react';

import SingularPluralNouns, { getAmountLabel, NounsType } from 'global/lists/SingularPluralNouns';
import { useMasterFilters } from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFiltersContext';
import { DefaultFilterLabels } from 'screens/platform/cross-platform-components/context/MasterFiltersContext/utils/DefaultMasterFiltersUtils';
import joinOneOrTwoElements, {
  hasOneOrTwoElements,
} from 'screens/platform/cross-platform-components/MasterFiltersPanel/masterFiltersUtils/MasterFiltersLabelsUtils';
import { getDatesFilterLabel } from 'utils/DateUtils';
import HierarchicalGroup from 'utils/HierarchicalDataStructures/HierarchicalGroup';

type AmountAndNouns = { amount: number; nouns: NounsType }
export type ItemLabelAccessor = (itemId: string) => string;

export const EMPTY_SELECTION_LABEL = 'None';

export function getAmountsOnly(
  itemsLists: AmountAndNouns[],
): string {
  return itemsLists
    .filter((entity) => entity.amount > 0)
    .map((entity) => getAmountLabel(entity.amount, entity.nouns))
    .reduce((acc, nextEntityAmount, index) => {
      if (itemsLists.length === 1 || acc === '') {
        return nextEntityAmount;
      }
      if (index === itemsLists.length - 1) {
        return `${acc} and ${nextEntityAmount}`;
      }
      return `${acc}, ${nextEntityAmount}`;
    }, '');
}

export function getHierarchicalFilterSelectionLabel(
  selection: HierarchicalGroup,
  itemLabelAccessor: ItemLabelAccessor,
  groupLabels: NounsType,
  itemsLabels: NounsType,
) {
  const { id, isSelected } = selection;

  if (isSelected) {
    return id;
  }

  function getSelectedGroupsAndItems(): { items: string[]; groups: string[] } {
    if (id === DefaultFilterLabels.ALL_ORGANIZATIONS) {
      return {
        groups: [],
        items: selection.getSelectedItems(),
      };
    }
    const {
      selectedGroups: groups,
      selectedItems: items,
    } = selection.getSelectedGroupsAndItems();
    return { groups, items };
  }

  return getSelectionLabel(
    { id, ...getSelectedGroupsAndItems() },
    itemLabelAccessor,
    groupLabels,
    itemsLabels,
  );
}

function getSelectionLabel(
  { groups, items, id }: { groups: string[]; items: string[]; id: string },
  itemLabelAccessor: ItemLabelAccessor,
  groupLabels: NounsType,
  itemsLabels: NounsType,
) {
  if (items.length === 0 && groups.length === 0) {
    switch (id) {
      case DefaultFilterLabels.ALL_TAGS: return 'No Tags';
      default: return EMPTY_SELECTION_LABEL;
    }
  }
  if (hasOneOrTwoElements(groups) && items.length === 0) {
    return joinOneOrTwoElements(groups, groupLabels);
  }
  if (hasOneOrTwoElements(items) && groups.length === 0) {
    return joinOneOrTwoElements(items, itemsLabels, itemLabelAccessor);
  }

  const groupAmountAndNouns = { amount: groups.length, nouns: groupLabels };
  const itemsAmountAndNouns = { amount: items.length, nouns: itemsLabels };
  return getAmountsOnly([groupAmountAndNouns, itemsAmountAndNouns]);
}

export function useTagsLabel(): string {
  const { currentFilters } = useMasterFilters();
  const { categories } = currentFilters;

  return useMemo(
    () =>
      getHierarchicalFilterSelectionLabel(
        categories.tagsByCategories,
        (tag) => tag,
        SingularPluralNouns.category,
        SingularPluralNouns.tag,
      ),
    [currentFilters.version],
  );
}

export function useDatesRangeLabel(): string {
  const { currentFilters } = useMasterFilters();

  return useMemo(
    () => getDatesFilterLabel(currentFilters.datesRange),
    [currentFilters.version],
  );
}
