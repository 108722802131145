import React from 'react';
import { useAsync } from 'react-use';

import { Heading3 } from 'common-ui-components/Typograhpy';
import SuggestedQuestion, {
  SuggestedQuestionSkeleton,
} from 'es-src/screens/HomeScreen/components/SuggestedQuestions/SuggestedQuestion';
import { EnrichedEnterpriseSearchResponse } from 'es-src/screens/HomeScreen/EnterpriseSearchContext/EnrichedSourcesDataHook';
import { useEnterpriseSearchContext } from 'es-src/screens/HomeScreen/EnterpriseSearchContext/EnterpriseSearchContext';
import Api from 'global/api/platformApi';
import useTenantContext from 'screens/platform/cross-platform-components/context/tenant/TenantContext';

import style from 'es-src/screens/HomeScreen/components/ResultSection/FollowupQuestions/style.module.scss';

type Props = {
  searchResponse: EnrichedEnterpriseSearchResponse;
};

export default function FollowupQuestions({ searchResponse }: Props) {
  const {
    tenant: { id: tenantId },
  } = useTenantContext();
  const { originalQuestion } = useEnterpriseSearchContext();
  const { answer, sessionId } = searchResponse;
  const { value: followupQuestions, loading } = useAsync(async () => {
    const res = await Api.EnterpriseSearch.getFollowupQuestions(
      tenantId,
      sessionId,
      originalQuestion,
      answer,
    );
    return res?.data ?? [];
  }, [sessionId, tenantId, originalQuestion, answer]);

  if ((!followupQuestions && !loading) || followupQuestions?.length === 0) return null;

  return (
    <div className={style.container}>
      <Heading3>RELATED</Heading3>
      <div className={style.questions}>
        {loading ? (
          <SuggestedQuestionsSkeleton />
        ) : (
          followupQuestions?.map((question) => (
            <SuggestedQuestion
              key={question.followupQuestion}
              question={question.followupQuestion}
              displayQuestion={question.shortFollowupQuestion}
              questionSource="followupQuestion"
              color="blue"
            />
          ))
        )}
      </div>
    </div>
  );
}

function SuggestedQuestionsSkeleton() {
  return (
    <div className={style.container} role="followupQuestionsProgressbar">
      <SuggestedQuestionSkeleton color="blue" />
    </div>
  );
}
