import React, { useEffect } from 'react';

import WidgetDynamicTitle from 'screens/platform/contentScreens/AnalyticsScreen/components/WidgetDynamicTitle';
import HeaderSettings from 'screens/platform/contentScreens/AnalyticsScreen/components/WidgetHeader/HeaderSettingsPopover';
import useWidgetConfig from 'screens/platform/contentScreens/AnalyticsScreen/utils/WidgetConfigHook';
import { WidgetRecipe, WidgetType } from 'screens/platform/contentScreens/AnalyticsScreen/widgets/widgetConfig';
import usePlatformContextDefaultState
  from 'screens/platform/cross-platform-components/context/platform/platformContextDefaultStateHook';

import style from 'screens/platform/contentScreens/AnalyticsScreen/components/WidgetHeader/style.module.scss';

export interface WidgetHeaderProps {
  type: WidgetType;
  recipes: WidgetRecipe[];
  showComparedTo?: boolean;
}

export default function WidgetHeader(props: WidgetHeaderProps) {
  const { type, recipes } = props;
  const { widgetConfig, setWidgetConfig } = useWidgetConfig(type);
  const defaultPlatformContextState = usePlatformContextDefaultState();

  useEffect(() => {
    if (recipes.every((recipe) => recipe.group !== widgetConfig.group)) {
      const defaultWidgetConfig = defaultPlatformContextState.analyticsScreen[type];
      setWidgetConfig({ group: defaultWidgetConfig.group });
    }
  }, [recipes]);

  return (
    <header className={style.widgetTitle}>
      <WidgetDynamicTitle
        {...props}
        config={widgetConfig}
        setWidgetConfig={setWidgetConfig}
      />
      <HeaderSettings
        setWidgetConfig={setWidgetConfig}
        settings={widgetConfig.settings}
      />
    </header>
  );
}
