import App from 'global/lists/apps';
import getAppDisplayName from 'global/lists/appsNames';
import SingularPluralNouns from 'global/lists/SingularPluralNouns';
import Channel from 'model/Channel';
import useAsync from 'react-use/lib/useAsync';
import HierarchicalMasterFilter
  from 'screens/platform/cross-platform-components/context/MasterFiltersContext/HierarchicalMasterFilter';
import {
  useMasterFilters,
} from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFiltersContext';
import {
  DefaultFilterLabels,
} from 'screens/platform/cross-platform-components/context/MasterFiltersContext/utils/DefaultMasterFiltersUtils';
import { useMetadataContext } from 'screens/platform/cross-platform-components/context/metadata/MetadataContext';
import AppsLabelHookUtils
  from 'screens/platform/cross-platform-components/MasterFiltersPanel/masterFiltersUtils/AppsLabelHook/AppsLabelHookUtils';
import { getAmountsOnly } from 'screens/platform/cross-platform-components/MasterFiltersPanel/masterFiltersUtils/index';
import joinOneOrTwoElements, {
  hasOneOrTwoElements,
} from 'screens/platform/cross-platform-components/MasterFiltersPanel/masterFiltersUtils/MasterFiltersLabelsUtils';
import {
  SlackSubDivisionId,
} from 'screens/platform/cross-platform-components/MasterFiltersPanel/masterFiltersUtils/SlackChannelsUtils';
import ArrayUtils from 'utils/ArrayUtils';

const NONE_LABEL = 'None';

export async function getAppsFilterLabel(
  availableApps: Partial<Record<App, string>>,
  getChannelById: (id: string) => Promise<Channel | undefined>,
  apps: HierarchicalMasterFilter,
): Promise<string> {
  const allAppsSelectedExceptBots = apps.getChildren()
    .every((child) => child.id === SlackSubDivisionId.SLACK_BOT_CHANNELS || child.isSelected);

  if (apps.isSelected || allAppsSelectedExceptBots) {
    return DefaultFilterLabels.ALL_APPS;
  }

  const {
    selectedAppsIds, selectedChannelsIds,
  } = AppsLabelHookUtils.getSelectedEntitiesIncludingUnidentified(apps);

  if (Object.keys(availableApps).length > 0) {
    const appsAmount = selectedAppsIds.length;
    const channelsAmount = selectedChannelsIds.length;

    if (channelsAmount === 0 && appsAmount === 0) {
      return NONE_LABEL;
    }

    if (hasOneOrTwoElements(selectedAppsIds) && channelsAmount === 0) {
      const getAppLabel = (appId: App) => getAppDisplayName(appId.toString());
      return joinOneOrTwoElements(
        selectedAppsIds,
        SingularPluralNouns.app,
        getAppLabel,
      );
    }
    if (hasOneOrTwoElements(selectedChannelsIds) && appsAmount === 0) {
      const channels = (await Promise.all(selectedChannelsIds.map(getChannelById)))
        .filter(ArrayUtils.isDefined)
        .reduce((acc, c) => ({ ...acc, [c.id]: c }), {} as Partial<Record<string, Channel>>);

      return joinOneOrTwoElements(
        selectedChannelsIds,
        SingularPluralNouns.channel,
        (channelId: string) => AppsLabelHookUtils.getChannelLabel(channels, channelId),
      );
    }

    return getAmountsOnly(
      [
        { amount: appsAmount, nouns: SingularPluralNouns.app },
        { amount: channelsAmount, nouns: SingularPluralNouns.channel },
      ],
    );
  }

  return NONE_LABEL;
}

export default function useAppsLabel(): string {
  const { connectedApps, channels } = useMetadataContext();
  const { currentFilters } = useMasterFilters();
  const { apps } = currentFilters;

  const { value: label } = useAsync(
    () => getAppsFilterLabel(connectedApps, channels.getById, apps),
    [currentFilters.version],
  );

  return label ?? '';
}

export const exportedForTesting = { getAppsFilterLabel };
