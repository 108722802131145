import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';

import ActionableBadge from 'common-ui-components/ActionableBadge';
import { useMasterFilters } from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFiltersContext';
import { HierarchicalItemType } from 'screens/platform/cross-platform-components/HierarchicalSearchInput/HierarchicalItemTypeConfig';
import FilterMenu from 'screens/platform/cross-platform-components/MasterFiltersPanel/FilterMenu';
import FilterCheckbox from 'screens/platform/cross-platform-components/MasterFiltersPanel/FilterSection/FilterCheckbox';
import HierarchicalSearchInputDropdown from 'screens/platform/cross-platform-components/MasterFiltersPanel/FilterSection/HierarchicalSearchInputDropdown';
import FilterWrapper from 'screens/platform/cross-platform-components/MasterFiltersPanel/FilterWrapper';
import { useTagsLabel } from 'screens/platform/cross-platform-components/MasterFiltersPanel/masterFiltersUtils';

export default function CategoriesFilterSection() {
  const { currentFilters, dispatchMasterFilters } = useMasterFilters();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const isFirstRender = useRef(true);
  const { includeUntagged } = currentFilters.categories;
  const categoriesFilter = useMemo(
    () => currentFilters.categories.tagsByCategories.clone(),
    [currentFilters.categories],
  );

  useEffect(() => {
    if (
      !isDropdownOpen
      && !isFirstRender.current
    ) {
      dispatchMasterFilters({
        type: 'CHANGE_CATEGORIES',
        payload: { nextCategories: categoriesFilter },
      });
    }
    isFirstRender.current = false;
  }, [isDropdownOpen]);

  return (
    <FilterWrapper filter={FilterMenu.TAGS}>
      <HierarchicalSearchInputDropdown
        selection={categoriesFilter}
        isDropdownOpen={isDropdownOpen}
        setIsDropdownOpen={setIsDropdownOpen}
        type={HierarchicalItemType.TAG}
        toggleButton={(
          <ActionableBadge
            label={useTagsLabel()}
            iconType="edit"
            onClick={() => setIsDropdownOpen((prev) => !prev)}
          />
        )}
        searchPlaceholder="Search for categories or tags"
        allowEmptySelection
      />
      <FilterCheckbox
        checked={includeUntagged}
        label="Include Untagged"
        hoverText="Show interactions that are not tagged by any of the keywords configured for this organization"
        onClick={() => dispatchMasterFilters({ type: 'TOGGLE_INCLUDE_UNTAGGED' })}
        disabled={currentFilters.categories.tagsByCategories.isNotSelected()}
      />
    </FilterWrapper>
  );
}
