import { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import {
  extractParamsFromUrl,
} from 'screens/platform/cross-platform-components/context/UrlParams/convertUrlParamsToMasterFilters';
import { FlatUrlParams } from 'screens/platform/cross-platform-components/context/UrlParams/UrlParamsTypes';
import FilterMenu from 'screens/platform/cross-platform-components/MasterFiltersPanel/FilterMenu';

const flatUrlParamsToAffectedFilterMenu:
  Readonly<Record<keyof FlatUrlParams, FilterMenu>> = Object.freeze({
    apps: FilterMenu.APPS,
    categories: FilterMenu.TAGS,
    channels: FilterMenu.APPS,
    departments: FilterMenu.PEOPLE,
    from: FilterMenu.DATES_RANGE,
    to: FilterMenu.DATES_RANGE,
    includeUnidentified: FilterMenu.PEOPLE,
    includeUntagged: FilterMenu.TAGS,

    selectedOrganizationsIds: FilterMenu.ORGANIZATIONS,
    selectAllOrganizationsIds: FilterMenu.ORGANIZATIONS,
    includeUndefinedOrganizations: FilterMenu.ORGANIZATIONS,

    selectedOrganizationsRisks: FilterMenu.ORGANIZATIONS,
    selectAllOrganizationsRisks: FilterMenu.ORGANIZATIONS,
    includeUndefinedOrganizationsRisk: FilterMenu.ORGANIZATIONS,
    selectAllOrganizationsSizes: FilterMenu.ORGANIZATIONS,
    selectedOrganizationsSizes: FilterMenu.ORGANIZATIONS,
    includeUndefinedOrganizationsSize: FilterMenu.ORGANIZATIONS,
    selectAllOrganizationsSegments: FilterMenu.ORGANIZATIONS,
    selectedOrganizationsSegments: FilterMenu.ORGANIZATIONS,
    includeUndefinedOrganizationsSegment: FilterMenu.ORGANIZATIONS,

    organizationsCommunication: FilterMenu.ORGANIZATIONS,
    organizationsGroups: FilterMenu.ORGANIZATIONS,
    peopleIds: FilterMenu.PEOPLE,
    selectedTeams: FilterMenu.PEOPLE,
    selectAllTeams: FilterMenu.PEOPLE,
    includeUndefinedTeam: FilterMenu.PEOPLE,
    tags: FilterMenu.TAGS,
    directionality: FilterMenu.DIRECTIONALITY,
  });
const filterMenuToAffectingFlatUrlParams = Object.entries(flatUrlParamsToAffectedFilterMenu)
  .reduce((acc, [flatParam, filterMenu]) => {
    acc[filterMenu] = acc[filterMenu] ?? [];
    acc[filterMenu].push(flatParam as keyof FlatUrlParams);
    return acc;
  }, {} as Record<FilterMenu, (keyof FlatUrlParams)[]>);

export function useIsFilterChanged(): (filter: FilterMenu) => boolean {
  const { search } = useLocation();
  const paramKeys = useMemo(() => (
    Object.keys(extractParamsFromUrl(search))
  ), [search]);

  return useCallback((filter: FilterMenu): boolean => {
    const isSomeIncludedInParamKeys = (keys: (keyof FlatUrlParams)[]) =>
      keys.some((key) => paramKeys.includes(key));

    const affectingFlatUrlParamKeys = filterMenuToAffectingFlatUrlParams[filter];
    return isSomeIncludedInParamKeys(affectingFlatUrlParamKeys);
  }, [paramKeys, filterMenuToAffectingFlatUrlParams]);
}

export function useIsAnyFilterChanged(): boolean {
  const isFilterChanged = useIsFilterChanged();
  return useMemo(
    () =>
      Object.values(FilterMenu).some((filter) => isFilterChanged(filter)),
    [isFilterChanged],
  );
}
