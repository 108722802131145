import classNames from 'classnames';
import React, { FC, useCallback, useState } from 'react';

import AuthorizedWrapper from 'common-ui-components/AuthorizedWrapper/index';
import ChangedFilterDot from 'common-ui-components/ChangedFilterDot';
import InternalLink from 'common-ui-components/InternalLink/index';
import getScreenConfig, { ScreenKey } from 'global/ScreensConfiguration';
import {
  useMasterFilters,
} from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFiltersContext';
import {
  useDefaultMasterFilters,
} from 'screens/platform/cross-platform-components/context/MasterFiltersContext/utils/DefaultMasterFiltersUtils';
import FilterMenu from 'screens/platform/cross-platform-components/MasterFiltersPanel/FilterMenu';
import FiltersConfig, {
  FilterToMasterFilterKey,
  ManageFilterScreenKeys,
} from 'screens/platform/cross-platform-components/MasterFiltersPanel/FiltersConfigs';
import {
  useIsFilterChanged,
} from 'screens/platform/cross-platform-components/MasterFiltersPanel/masterFiltersUtils/FilterChangeHook';
import ObjectUtils from 'utils/ObjectUtils';

import style from 'screens/platform/cross-platform-components/MasterFiltersPanel/FilterWrapper/style.module.scss';

interface Props {
  filter: FilterMenu;
}

const FilterWrapper: FC<Props> = ({
  filter,
  children,
}) => {
  const config = FiltersConfig[filter];
  const isFilterChanged = useIsFilterChanged();
  const { currentFilters, dispatchMasterFilters } = useMasterFilters();
  const defaultMasterFilters = useDefaultMasterFilters();
  const { Icon } = config;

  const [isManageLinkVisible, setIsManageLinkVisible] = useState(false);

  const clearFilter = useCallback(() => {
    const nextFilters = ObjectUtils.deepClone(currentFilters);
    const defaultFilters = ObjectUtils.deepClone(defaultMasterFilters);

    const masterFiltersKey = FilterToMasterFilterKey[filter];
    nextFilters[masterFiltersKey] = defaultFilters[masterFiltersKey];

    dispatchMasterFilters({ type: 'RESET_FILTERS', payload: nextFilters });
  }, [filter, currentFilters.version, defaultMasterFilters]);

  const filterScreenKey: ScreenKey | undefined = ManageFilterScreenKeys[filter];
  const filterScreenConfig = filterScreenKey && getScreenConfig(filterScreenKey);

  return (
    <div
      className={classNames(style.filterWrapper)}
      onMouseEnter={() => setIsManageLinkVisible(true)}
      onMouseLeave={() => setIsManageLinkVisible(false)}
    >
      <div className={style.filterHeader}>
        <h3>
          <Icon />
          <span>{config.title}</span>
          {Object.values(FilterMenu).includes(filter as FilterMenu)
            && isFilterChanged(filter as FilterMenu) && (
              <ChangedFilterDot
                hoverText="Clear Filter"
                onClick={clearFilter}
              />
          )}
        </h3>
        {filterScreenConfig && (
          <AuthorizedWrapper minimumRole={filterScreenConfig.minimumRole}>
            <InternalLink
              to={filterScreenKey}
              className={classNames(
                style.manageLink,
                isManageLinkVisible && style.visible,
              )}
            >
              Manage
            </InternalLink>
          </AuthorizedWrapper>
        )}
      </div>
      <div
        // onMouseUp is needed to fix a bug where the Manage link is not hidden
        // when the dropdown is open and closed by pressing done
        onMouseUp={() => setIsManageLinkVisible(false)}
        className={style.filterContent}
      >
        {children}
      </div>
    </div>
  );
};

export default FilterWrapper;
