import classNames from 'classnames';
import React from 'react';

import Tooltip from 'common-ui-components/Tooltip';

import style from 'common-ui-components/DynamicTitle/style.module.scss';

type Props = {
  label: string;
  tooltip: string | null;
  className?: string;
};

export default function StaticConfigLabel({ label, tooltip, className }: Props) {
  return (
    <Tooltip content={tooltip}>
      <span data-testid={`dynamic-title-${label}`} className={classNames(style.config, style.static, className)}>{label}</span>
    </Tooltip>
  );
}
