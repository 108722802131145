import React, {
  createContext,
  useContext,
} from 'react';

import { OpenGlobalModalArgs } from 'screens/platform/cross-platform-components/context/globalModal/GlobalModalReducer';

export interface GlobalModalContextType {
  openGlobalModal: (args: OpenGlobalModalArgs) => void;
  closeGlobalModal: (event?: React.MouseEvent) => void;
  preventInteractionsDrawerClosing: boolean;
}

export const useGlobalModal:
  () => GlobalModalContextType = () => useContext<GlobalModalContextType>(GlobalModalContext);

const GlobalModalContext = createContext<GlobalModalContextType>({} as any);
export default GlobalModalContext;
