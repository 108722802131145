import { Dispatch, SetStateAction, useCallback } from 'react';

import { GroupedOrganizations } from 'global/api/controller/OrganizationController';
import Api from 'global/api/platformApi';
import { HttpResponse } from 'global/api/platformApiHelpers';
import {
  NullableValuesSelectionConstants,
} from 'screens/platform/cross-platform-components/context/MasterFiltersContext/NullableValuesSelection';
import useTenantContext from 'screens/platform/cross-platform-components/context/tenant/TenantContext';
import { useAsyncFetchingByQuery } from 'utils/hooks';

const { UNDEFINED_PROPERTY, UNDEFINED_PROPERTY_LABEL } = NullableValuesSelectionConstants;

export default function useOrganizationsQuery(
  searchQuery: string,
  setItemsByGroups: Dispatch<SetStateAction<GroupedOrganizations | null>>,
) {
  const { tenant } = useTenantContext();

  const organizationsQuery = useCallback(async (query): HttpResponse<GroupedOrganizations> => {
    const shouldIncludeUndefined = UNDEFINED_PROPERTY_LABEL
      .toLowerCase()
      .includes(query.toLowerCase());
    const additionalArray = shouldIncludeUndefined ? [UNDEFINED_PROPERTY] : [];

    const result = await Api.Organization.getOrganizationsByQuery(tenant.id, query);
    if (result?.data) {
      return {
        ...result,
        data: {
          ...result.data,
          risks: [...result.data.risks, ...additionalArray],
          sizes: [...result.data.sizes, ...additionalArray],
        },
      };
    }
    return result;
  }, [tenant.id]);

  useAsyncFetchingByQuery(
    searchQuery,
    setItemsByGroups,
    organizationsQuery,
  );
}
