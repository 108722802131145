import classNames from 'classnames';
import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';

import { NavbarScreen, NavbarScreensConfig } from 'global/lists/AppScreen';
import { getScreenPath } from 'global/ScreensConfiguration';
import ScreenButtonTooltipWrapper
  from 'screens/platform/cross-platform-components/Navbar/components/ScreenButton/ScreenButtonTooltipWrapper';
import { useNavbarContext } from 'screens/platform/cross-platform-components/Navbar/NavbarContext';

import style from 'screens/platform/cross-platform-components/Navbar/components/ScreenButton/style.module.scss';

type Props = {
  navbarScreen: NavbarScreen;
  onClick?: () => void;
};

export default function ScreenButton({
  navbarScreen, onClick,
}: Props) {
  const { isNavbarMinimized } = useNavbarContext();
  const { label, Icon, url } = NavbarScreensConfig[navbarScreen];
  const isSelected = useRouteMatch({
    path: url,
    exact: url === getScreenPath('feed'),
  });

  return (
    <ScreenButtonTooltipWrapper label={label}>
      <Link
        to={{ pathname: url }}
        className={classNames(
          style.screenButton,
          Boolean(isSelected) && style.selected,
          isNavbarMinimized && style.minimized,
        )}
        onClick={onClick}
      >
        <Icon className={style.icon} />
        <div className={style.label}>
          <span className={style.spacedLabel}>
            {label}
          </span>
        </div>
      </Link>
    </ScreenButtonTooltipWrapper>
  );
}
