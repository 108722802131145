import React, { useCallback, useEffect, useMemo } from 'react';

import { MinimalTag } from 'global/api/controller/TagsSetController';
import Api from 'global/api/platformApi';
import { HttpResponse } from 'global/api/platformApiHelpers';
import colors from 'global/colors';
import ItemsByGroup from 'global/ItemsByGroup';
import App from 'global/lists/apps';
import Metric from 'global/lists/Metric';
import Channel from 'model/Channel';
import Organization from 'model/Organization';
import Person from 'model/Person';
import Tenant, { ADDITIONAL_CATEGORIES_COLORS, SETUP_STATE } from 'model/Tenant';
import { useGlobalLoading } from 'screens/platform/cross-platform-components/context/GlobalLoadingContextProvider';
import {
  NullableValuesSelectionConstants,
} from 'screens/platform/cross-platform-components/context/MasterFiltersContext/NullableValuesSelection';
import useConditionalOrganizationsFetching
  from 'screens/platform/cross-platform-components/context/metadata/hooks/ConditionalOrganizationsFetchingHook';
import {
  useOrganizationsImagesLoader,
  usePeopleImagesLoader,
} from 'screens/platform/cross-platform-components/context/metadata/hooks/ImagesLoaderHook';
import useTagsColorsByCategory, {
  defaultTagColors,
} from 'screens/platform/cross-platform-components/context/metadata/hooks/TagsColorsHook';
import MetadataContext from 'screens/platform/cross-platform-components/context/metadata/MetadataContext';
import MetadataItemsCache from 'screens/platform/cross-platform-components/context/metadata/MetadataItemsCache';
import MetadataReducer, {
  MetadataReducerStateType,
} from 'screens/platform/cross-platform-components/context/metadata/MetadataReducer';
import parsePeopleResponse from 'screens/platform/cross-platform-components/context/metadata/utils/PeopleMetadataUtils';
import useTenantContext from 'screens/platform/cross-platform-components/context/tenant/TenantContext';
import DebuggerConsole from 'utils/DebuggerConsole';
import { useCustomReducer, useMountedStatus } from 'utils/hooks';
import ObjectUtils from 'utils/ObjectUtils';
import { convertTagsToTagsByCategoriesMap } from 'utils/TagUtils';

const { UNDEFINED_PROPERTY } = NullableValuesSelectionConstants;

const initialMetadataContext: MetadataReducerStateType = {
  connectedApps: {},
  categories: {},
  tagsData: {},
  categoriesColors: {},
  categoryColorsOrdinals: {},
  metrics: [],
  persons: {
    allPeopleMetadata: {},
    departmentsToPersonsMap: {},
    teamsToPeopleMap: { [UNDEFINED_PROPERTY]: [] },
  },
  appsToChannelsMap: {},
};

export default function MetadataContextProvider({ children }) {
  const { tenant } = useTenantContext();
  const [metadataContext, dispatchMetadataContext] = useCustomReducer(
    MetadataReducer,
    initialMetadataContext,
  );

  const isMounted = useMountedStatus();

  const peopleImagesLoader = usePeopleImagesLoader();
  const organizationsImagesLoader = useOrganizationsImagesLoader();

  const { setFullScreenLoading } = useGlobalLoading();

  const {
    organizationsCache,
    organizationsNamesCache,
    enrichedOrganizationsCache,
    isOrganizationsFeatureActivated,
    isOrganizationsFeatureActivatedLoading,
  } = useConditionalOrganizationsFetching();

  const personsCache = useMemo(
    () =>
      new MetadataItemsCache<Person>({
        fetchItemsByIds: async (ids: string[]) =>
          Api.Person.getPeopleByIds(tenant.id, ids).then((res) => res?.data),
      }),
    [tenant],
  );

  const channelsCache = useMemo(
    () =>
      new MetadataItemsCache<Channel>({
        fetchItemsByIds: async (ids: string[]) =>
          Api.Channel.findChannelsByIds(tenant.id, ids).then((res) => res?.data),
      }),
    [tenant],
  );

  const refreshMetadata = useCallback(async (shouldShowLoader = true) => {
    if (isOrganizationsFeatureActivatedLoading) return;

    const tenantId = tenant.id;

    async function fetchFromApi<T>(
      apiFunction: (tenantId: Tenant['id']) => HttpResponse<T>,
    ): Promise<T | null> {
      const response = await apiFunction(tenantId);
      return response?.data ?? null;
    }

    async function fetchItemsIdsByGroupsFromApi(
      apiFunction: (tenantId: Tenant['id']) => HttpResponse<ItemsByGroup[]>,
    ): Promise<Partial<Record<string, string[]>> | null> {
      const itemsByGroups = await fetchFromApi(apiFunction);
      if (!itemsByGroups) return null;
      return Object.fromEntries(
        itemsByGroups.map(({ group, items }) => [group, items]),
      );
    }

    try {
      if (shouldShowLoader) {
        setFullScreenLoading(true);
      }

      const [
        nextApps,
        nextTags,
        nextCategoriesColors,
        nextMetrics,
        nextPeople,
        nextAppsToChannelsMap,
      ] = await Promise.all([
        fetchFromApi(Api.Tenant.getAppsIds),
        fetchFromApi(Api.TagsSet.getTagsSet),
        fetchFromApi(Api.TagsSet.getCategoriesColors),
        fetchFromApi(Api.Metric.getActiveMetrics),
        fetchFromApi(Api.Person.getAllPeopleMetadata),
        fetchItemsIdsByGroupsFromApi(Api.Channel.getChannelsByAppsByQuery),
      ]);

      const payload: Partial<MetadataReducerStateType> = {};

      if (nextApps) {
        payload.connectedApps = nextApps;
        DebuggerConsole.log(`Fetched ${Object.keys(nextApps).length} apps`);
      }

      if (nextTags) {
        payload.categories = convertTagsToTagsByCategoriesMap(nextTags);
        payload.tagsData = nextTags.reduce((acc, { value, isTopic, categories }) => {
          acc[value] = { isTopic, categories };
          return acc;
        }, {} as Record<string, Pick<MinimalTag, 'isTopic' | 'categories'>>);
        DebuggerConsole.log(`Fetched ${Object.keys(nextTags).length} tags`);
      }

      if (nextCategoriesColors) {
        const { categoriesColors, categoryColorsOrdinals } = nextCategoriesColors;
        payload.categoriesColors = { ...categoriesColors, ...ADDITIONAL_CATEGORIES_COLORS };
        payload.categoryColorsOrdinals = categoryColorsOrdinals;
        DebuggerConsole.log(`Fetched ${Object.keys(nextCategoriesColors).length} category colors`);
      }

      if (nextMetrics) {
        payload.metrics = nextMetrics;
        DebuggerConsole.log(`Fetched ${Object.keys(nextMetrics).length} metrics`);
      }

      if (nextPeople) {
        const { allPeopleMetadata, departmentToPeople, teamToPeople } = nextPeople;
        payload.persons = parsePeopleResponse(nextPeople);
        DebuggerConsole.log(`Fetched people data (${Object.keys(allPeopleMetadata).length} people, ${Object.keys(departmentToPeople).length} departments, ${Object.keys(teamToPeople).length} teams)`);
      }

      if (nextAppsToChannelsMap) {
        payload.appsToChannelsMap = nextAppsToChannelsMap;
        DebuggerConsole.log('Fetched appsToChannelsMap');
      }

      if (isMounted.current) {
        dispatchMetadataContext({
          type: 'INITIALIZE',
          payload,
        });
      }
    } catch (err) {
      DebuggerConsole.error(err);
    } finally {
      if (shouldShowLoader) {
        setFullScreenLoading(false);
      }
    }
  }, [
    tenant.id,
    isOrganizationsFeatureActivated,
    isOrganizationsFeatureActivatedLoading,
  ]);

  useEffect(() => {
    if (isMounted.current) {
      dispatchMetadataContext({ type: 'INITIALIZE', payload: initialMetadataContext });
      refreshMetadata(true);
    }
  }, [tenant.id, isOrganizationsFeatureActivatedLoading]);

  const {
    metrics,
    persons,
    appsToChannelsMap,
    tagsData,
    ...restMetadataContext
  } = metadataContext;

  const isMetricEnabled = useCallback((metric: Metric) => metrics.includes(metric), [metrics]);
  const isTagTopic = (tag: string) => Boolean(tagsData[tag]?.isTopic);

  const tagsColors = useTagsColorsByCategory(
    metadataContext.categories,
    metadataContext.categoriesColors,
  );

  function findCategoryByTag(tag: string): string | undefined {
    return (
      Object.entries(metadataContext.categories)
        .filter(([_, tags]) => tags.includes(tag))
        .map(([categoryName]) => categoryName)[0]
    );
  }

  const getTagColorsByCategory = useCallback(
    (tag: string, category?: string) => {
      const tagCategory = category || findCategoryByTag(tag);
      const tagColor = tagCategory ? tagsColors[tagCategory]?.[tag] : undefined;

      if (tagColor !== undefined) return tagColor;

      DebuggerConsole.error('An error occurred in getTagColorsByCategory', { tag, category });
      return defaultTagColors;
    },
    [tagsColors],
  );

  const getCategoryColor = useCallback((category: string) => {
    const categoryColor = metadataContext.categoriesColors[category];
    if (categoryColor) return categoryColor;

    DebuggerConsole.error('Tried to access a non-existing category color', { category });
    return colors.GRAY_4;
  }, [metadataContext.categoriesColors]);

  const tagsToCategories = useMemo(
    () =>
      Object.entries(metadataContext.categories).reduce(
        (acc, [category, tags]) => {
          tags.forEach((tag) => {
            acc[tag] = [...(acc[tag] || []), category];
          });
          return acc;
        },
        {},
      ),
    [metadataContext.categories],
  );

  const getOrganizationByClusterId = (clusterId: string) =>
    organizationsCache.getAllCachedItems().find((o) => o.clusterId === clusterId);

  const updateOrganizationInCache = (org: Organization) => {
    organizationsCache.updateItemCache(org.id, org);
  };

  if (
    [
      appsToChannelsMap,
      persons.departmentsToPersonsMap,
    ].some((x) => ObjectUtils.isEmpty(x))
    && tenant.setupState === SETUP_STATE.READY
  ) {
    return null;
  }

  async function getPersonImageById(personId: string): Promise<string | undefined> {
    const urlToFetch = (await personsCache.get(personId))?.image;
    if (!urlToFetch) return undefined;

    return peopleImagesLoader.get(personId, urlToFetch);
  }

  async function getOrganizationImageById(organizationId: string): Promise<string | undefined> {
    return organizationsImagesLoader.get(organizationId, organizationId);
  }

  return (
    <MetadataContext.Provider value={{
      ...restMetadataContext,
      dispatchMetadataContext,
      refreshMetadata,
      isAppConnected: (appId: App) =>
        restMetadataContext.connectedApps[appId] !== undefined
          || appId === App.AKOODA,
      persons: {
        getById: (id) => personsCache.get(id),
        updatePersonInCache: (personId, person) => personsCache.updateItemCache(personId, person),
        getPersonImageById,
        ...persons,
      },
      organizations: {
        getById: (id) => organizationsCache.get(id),
        getEnrichedById: (id) => enrichedOrganizationsCache.get(id),
        getDisplayNameById: (id) => organizationsNamesCache.get(id),
        getByClusterId: getOrganizationByClusterId,
        updateOrganizationInCache,
        getOrganizationImageById,
        abortCacheCalls: async () => organizationsCache.abort(),
      },
      channels: {
        getById: (id) => channelsCache.get(id),
        appsToChannelsMap,
      },
      getTagColors: getTagColorsByCategory,
      getCategoryColor,
      tagsToCategories,
      isMetricEnabled,
      isTagTopic,
    }}
    >
      { children }
    </MetadataContext.Provider>
  );
}
