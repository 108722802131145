import React from 'react';

import InternalLink from 'common-ui-components/InternalLink';
import { TagColors } from 'screens/platform/cross-platform-components/context/metadata/hooks/TagsColorsHook';
import { useMetadataContext } from 'screens/platform/cross-platform-components/context/metadata/MetadataContext';

import style from 'common-ui-components/Badge/TagBadge/tagBadge.module.scss';

export interface TagBadgeProps {
  tag: string;
  colors?: TagColors;
  clickable?: boolean;
}

export default function TagBadge({
  tag,
  colors,
  clickable = true,
}: TagBadgeProps) {
  const { getTagColors, isTagTopic } = useMetadataContext();

  const tagBadge = (
    <div className={style.badgeContainer} style={colors ?? getTagColors(tag)}>
      {tag}
    </div>
  );

  return clickable && isTagTopic(tag) ? (
    <InternalLink
      to="platform.directory.topics.profile"
      urlParam={tag}
      title="Go to topic profile"
      className={style.badgeButton}
      aria-label="topic badge"
    >
      {tagBadge}
    </InternalLink>
  ) : (
    tagBadge
  );
}
