import { subDays, subMonths, subWeeks } from 'date-fns';

import { ListOptions } from 'global/ListOptions';
import { TimePeriod } from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFilters';

enum DirectoryTimePeriod {
  LAST_DAY,
  LAST_WEEK,
  LAST_MONTH,
  LAST_3_MONTHS,
}

export const dateRangeFromOptions: ListOptions<DirectoryTimePeriod> = [
  { label: 'Last day', value: DirectoryTimePeriod.LAST_DAY },
  { label: 'Last week', value: DirectoryTimePeriod.LAST_WEEK },
  { label: 'Last month', value: DirectoryTimePeriod.LAST_MONTH },
  { label: 'Last 3 months', value: DirectoryTimePeriod.LAST_3_MONTHS },
];

export const getFromDateByTimePeriod = (timePeriod: DirectoryTimePeriod): Date => {
  const today = new Date();
  const timePeriodToDate: Record<DirectoryTimePeriod, Date> = {
    [DirectoryTimePeriod.LAST_DAY]: subDays(today, 1),
    [DirectoryTimePeriod.LAST_WEEK]: subWeeks(today, 1),
    [DirectoryTimePeriod.LAST_MONTH]: subMonths(today, 1),
    [DirectoryTimePeriod.LAST_3_MONTHS]: subMonths(today, 3),
  };
  return timePeriodToDate[timePeriod];
};

export const getTimePeriodInDays = (
  timePeriodOption: DirectoryTimePeriod,
): TimePeriod<'days'> => {
  const timePeriodOptionToDays: Record<DirectoryTimePeriod, number> = {
    [DirectoryTimePeriod.LAST_DAY]: 1,
    [DirectoryTimePeriod.LAST_WEEK]: 7,
    [DirectoryTimePeriod.LAST_MONTH]: 30,
    [DirectoryTimePeriod.LAST_3_MONTHS]: 90,
  };
  const timePeriod: TimePeriod<'days'> = {
    duration: timePeriodOptionToDays[timePeriodOption],
    endDate: null,
    resolution: 'days',
  };
  return timePeriod;
};

export default DirectoryTimePeriod;
