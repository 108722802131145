import React from 'react';

import { useMasterFilters } from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFiltersContext';
import PeopleMasterFilter from 'screens/platform/cross-platform-components/context/MasterFiltersContext/PeopleMasterFilter';
import FilterMenu from 'screens/platform/cross-platform-components/MasterFiltersPanel/FilterMenu';
import FilterCheckbox from 'screens/platform/cross-platform-components/MasterFiltersPanel/FilterSection/FilterCheckbox';
import PeopleHierarchicalSearchInputDropdown
  from 'screens/platform/cross-platform-components/MasterFiltersPanel/FilterSection/PeopleFilterSection/components/PeopleHierarchicalSearchInputDropdown';
import FilterWrapper from 'screens/platform/cross-platform-components/MasterFiltersPanel/FilterWrapper';

export default function PeopleFilterSection() {
  const { currentFilters: { people }, dispatchMasterFilters } = useMasterFilters();

  const setFilter = (nextPeopleFilter: PeopleMasterFilter) => {
    dispatchMasterFilters({ type: 'CHANGE_PEOPLE', payload: { nextPeople: nextPeopleFilter } });
  };

  return (
    <FilterWrapper filter={FilterMenu.PEOPLE}>
      <PeopleHierarchicalSearchInputDropdown
        initialFilter={people.clone()}
        onUpdate={setFilter}
      />
      <FilterCheckbox
        checked={people.includeUnidentified}
        label="Include Unidentified"
        hoverText="Show interactions that don't have any identified participants"
        onClick={() => dispatchMasterFilters({ type: 'TOGGLE_INCLUDE_UNIDENTIFIED' })}
      />
    </FilterWrapper>
  );
}
