import classNames from 'classnames';
import React, {
  Dispatch, forwardRef, ReactNode, SetStateAction,
} from 'react';

import Button from 'common-ui-components/Button';
import SearchInput from 'common-ui-components/SearchInput';
import {
  HierarchicalItemType,
} from 'screens/platform/cross-platform-components/HierarchicalSearchInput/HierarchicalItemTypeConfig';

import style from 'screens/platform/cross-platform-components/HierarchicalSearchInput/style.module.scss';

export type SearchableHierarchicalItemTypes = HierarchicalItemType.CHANNEL
  | HierarchicalItemType.TAG
  | HierarchicalItemType.ORGANIZATION
  | HierarchicalItemType.PERSON
  | HierarchicalItemType.DIRECTIONALITY;

export interface HierarchicalSearchInputWrapperProps {
  onClickDone: () => void;
  setSearchQuery: Dispatch<SetStateAction<string>>;
  searchPlaceholder?: string;
  disabled: boolean;
  className?: string;
  scrollable?: boolean;
  hideSearchInput?: boolean;
  customAction?: ReactNode;
  children: ReactNode;
}

const HierarchicalSearchInputWrapper = forwardRef<
  HTMLInputElement,
  HierarchicalSearchInputWrapperProps
>(({
  searchPlaceholder = 'Search',
  setSearchQuery,
  onClickDone,
  disabled,
  className,
  scrollable = true,
  hideSearchInput = false,
  customAction,
  children,
}, searchInputRef) => (
  <div
    className={classNames(
      style.searchableHierarchicalResultsContainer,
      className,
    )}
  >
    {!hideSearchInput && (
      <div className={style.searchInputWrapper}>
        <SearchInput
          ref={searchInputRef}
          setValue={setSearchQuery}
          placeholder={searchPlaceholder}
        />
      </div>
    )}
    <div
      className={classNames(
        style.content,
        scrollable && style.scrollable,
        hideSearchInput && style.noSearchInput,
      )}
    >
      {children}
    </div>
    {customAction}
    <div className={style.bottomMenu}>
      <Button
        color="blue"
        className={style.done}
        onClick={onClickDone}
        disabled={disabled}
      >
        Done
      </Button>
    </div>
  </div>
));

export default HierarchicalSearchInputWrapper;
