import React from 'react';

import Checkbox from 'common-ui-components/Checkbox';
import Tooltip from 'common-ui-components/Tooltip';

import style from 'screens/platform/cross-platform-components/MasterFiltersPanel/FilterSection/FilterCheckbox/style.module.scss';

interface Props {
    label: string;
    onClick: () => void;
    checked: boolean;
    hoverText: string;
    disabled?: boolean;
}

export default function FilterCheckbox({
  label, onClick, checked, hoverText, disabled = false,
}: Props) {
  return (
    <Tooltip
      placement="right"
      content={hoverText}
    >
      <div className={style.container}>
        <Checkbox
          checked={checked}
          className={style.checkbox}
          label={label}
          onClick={onClick}
          disabled={disabled}
        />
      </div>
    </Tooltip>
  );
}
