enum FilterMenu {
  TAGS = 'tags',
  DATES_RANGE = 'datesRange',
  PEOPLE = 'people',
  APPS = 'apps',
  ORGANIZATIONS = 'organizations',
  DIRECTIONALITY = 'directionality',
}

export default FilterMenu;
