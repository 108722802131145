import React from 'react';
import { useAsync } from 'react-use';

import RelevantPeopleAlgorithm from 'es-src/screens/HomeScreen/components/ResultSection/RegularSearchResults/RelevantPeople/RelevantPeopleAlgorithm';
import SearchResultsSection from 'es-src/screens/HomeScreen/components/ResultSection/RegularSearchResults/SearchResultsSection';
import { EnrichedPersonSource } from 'es-src/screens/HomeScreen/EnterpriseSearchContext/EnrichedSourcesDataHook';
import { useEnterpriseSearchContext } from 'es-src/screens/HomeScreen/EnterpriseSearchContext/EnterpriseSearchContext';
import Size from 'global/lists/Size';
import { MinimalPerson } from 'model/Person';
import { useMetadataContext } from 'screens/platform/cross-platform-components/context/metadata/MetadataContext';
import PersonBadge from 'screens/platform/cross-platform-components/PersonBadge';

interface Props {
  itemsPerPage: number;
}

export default function RelevantPeople({
  itemsPerPage,
}: Props) {
  const { persons: { getById } } = useMetadataContext();
  const { data, searchResultsResponse } = useEnterpriseSearchContext();

  const { value: relevantPeople } = useAsync(async () => {
    const { atoms, persons } = searchResultsResponse?.value || {};
    const enrichedPeopleSources = data?.sources.filter((source) => source.type === 'person') as EnrichedPersonSource[] || [];
    const firstPageAtoms = atoms?.results.slice(0, itemsPerPage) || [];

    return new RelevantPeopleAlgorithm({ getById })
      .toRelevantPeople(persons?.results || [], enrichedPeopleSources, firstPageAtoms);
  }, [data?.sources, searchResultsResponse?.value]);

  return (
    <SearchResultsSection<MinimalPerson>
      title="People"
      data={relevantPeople || []}
      renderBadge={(person) => (
        <PersonBadge
          personId={person._id}
          hasDepartment
          hasBorder
          openNewTab
          size={Size.MEDIUM}
        />
      )}
    />
  );
}
