import React, { useState } from 'react';

import { usePlatformContext } from 'screens/platform/cross-platform-components/context/platform/PlatformContext';
import EventsDrawerSettings from 'screens/platform/cross-platform-components/EventsPanel/EventsHeader/EventsDrawerSettings';
import HeaderCloseButton from 'screens/platform/cross-platform-components/EventsPanel/EventsHeader/HeaderCloseButton';

import style from 'screens/platform/cross-platform-components/EventsPanel/EventsHeader/style.module.scss';

export default function EventsHeader() {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const { eventsDrawer: { setEventsDrawerOpen } } = usePlatformContext();

  return (
    <div className={style.container}>
      <div className={style.title}>
        Events
      </div>
      <div className={style.buttons}>
        <EventsDrawerSettings
          isOpen={isPopoverOpen}
          setIsOpen={setIsPopoverOpen}
        />
        <HeaderCloseButton onClose={() => setEventsDrawerOpen(false)} />
      </div>
    </div>

  );
}
