import React from 'react';

import Table from 'common-ui-components/Table';
import { Atom } from 'model/Atom';
import { ComponentProps } from 'utils/TypescriptTricks';

import style
  from 'screens/platform/cross-platform-components/Interactions/components/InteractionsTable/style.module.scss';

interface Props extends Pick<ComponentProps<typeof Table<Atom, string>>,
  | 'dataSource'
  | 'loading'
  | 'emptyDataMsg'
  | 'columns'
  | 'pagination'
  | 'sorting'
  | 'onSortingChange'
  | 'onColumnMoved'
> {}

export default function AtomsTable({
  dataSource,
  loading,
  emptyDataMsg,
  columns,
  pagination,
  sorting,
  onSortingChange,
  onColumnMoved,
}: Props) {
  return (
    <Table<Atom, string>
      className={style.interactionsTable}
      rowKey="id"
      sortDirections={['descend', 'ascend']}
      dataSource={dataSource}
      loading={loading}
      emptyDataMsg={emptyDataMsg}
      columns={columns}
      pagination={pagination}
      sorting={sorting}
      onSortingChange={onSortingChange}
      onColumnMoved={onColumnMoved}
      stickyHeaderOffset={0}
    />
  );
}
