import React, { FC, Suspense } from 'react';

import lazyWithRetry from 'utils/lazyWithRetry';

import style from 'screens/platform/PlatformScreen/ScanningScreen/style.module.scss';

const ScanningAnimation = lazyWithRetry(() => import('screens/platform/PlatformScreen/ScanningScreen/ScanningAnimation'));

const ScanningScreen: FC = () => (
  <div className={style.scanningScreen}>
    <div className={style.topShadow} />
    <div className={style.loader}>
      <Suspense fallback={null}>
        <ScanningAnimation />
      </Suspense>
    </div>
    <h2>Akooda is processing... stay tuned!</h2>
    <p>
      Your Akooda universe will be ready soon. Check your inbox for incoming updates.
    </p>
  </div>
);

export default ScanningScreen;
