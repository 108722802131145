import {
  getRequest, HttpResponse, putRequest,
} from 'global/api/platformApiHelpers';
import Tenant from 'model/Tenant';

const SettingsController = {
  async getExtensionSettings(
    tenantId: Tenant['id'],
    personId: string,
  ): HttpResponse<Record<string, any>> {
    return getRequest(`settings/person/${personId}/extension`, { tenantId });
  },

  async updateExtensionSettings(
    tenantId: Tenant['id'],
    personId: string,
    extensionSettings: Record<string, any>,
  ): HttpResponse<void> {
    return putRequest(`settings/person/${personId}/extension`, { ...extensionSettings }, { params: { tenantId } });
  },
};

export default SettingsController;
