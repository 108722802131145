import {
  createContext, Dispatch, RefObject, SetStateAction,
  useContext,
} from 'react';

import { AtomsCount } from 'global/api/controller/AtomController';
import {
  MasterFiltersReducerAction,
  MasterFiltersState,
} from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFiltersReducer';

export interface MasterFiltersContextType extends Omit<MasterFiltersState, 'defaultState'> {
  dispatchMasterFilters: Dispatch<MasterFiltersReducerAction>;
  currentVersionRef: RefObject<number>;
  totalAtomsCount: AtomsCount | null;
  setTotalAtomsCount: Dispatch<SetStateAction<AtomsCount | null>>;
}

export function useMasterFilters(): MasterFiltersContextType {
  return useContext<MasterFiltersContextType>(MasterFiltersContext);
}

const MasterFiltersContext = createContext<MasterFiltersContextType>({} as any);
export default MasterFiltersContext;
