import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';

import ActionableBadge from 'common-ui-components/ActionableBadge/index';
import { MasterFilters } from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFilters';
import useOrganizationsFilterToHierarchyGroupConverter
  from 'screens/platform/cross-platform-components/context/MasterFiltersContext/OrganizationsMasterFilter/OrganizationsMasterFilterConversionUtils';
import {
  HierarchicalItemType,
} from 'screens/platform/cross-platform-components/HierarchicalSearchInput/HierarchicalItemTypeConfig';
import HierarchicalSearchInputDropdown, {
  HierarchicalSearchInputDropdownProps,
} from 'screens/platform/cross-platform-components/MasterFiltersPanel/FilterSection/HierarchicalSearchInputDropdown/index';
import useOrganizationsSelectionUpdate
  from 'screens/platform/cross-platform-components/MasterFiltersPanel/FilterSection/OrganizationsFilterSection/OrganizationsSelectionUpdateHook';
import useOrganizationsLabel
  from 'screens/platform/cross-platform-components/MasterFiltersPanel/masterFiltersUtils/OrganizationsLabelHook';
import ObjectUtils from 'utils/ObjectUtils';

interface Props extends Partial<Omit<HierarchicalSearchInputDropdownProps, 'type' | 'selection'>> {
  initialFilter: Readonly<MasterFilters['organizations']>;
  onUpdate: (nextPeopleFilter: MasterFilters['organizations']) => void;
}

export default function OrganizationsHierarchicalSearchInputDropdown(props: Props) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const isFirstRender = useRef(true);
  const { initialFilter, onUpdate } = props;

  const organizationsFilter = useMemo(() => initialFilter.clone(), [initialFilter]);
  const label = useOrganizationsLabel(organizationsFilter);

  const organizationsFilterAsGroup = useOrganizationsFilterToHierarchyGroupConverter(
    organizationsFilter,
  );

  const getOrganizationsSelection = useOrganizationsSelectionUpdate(initialFilter);
  useEffect(() => { // Update when the dropdown is closed
    if (!isDropdownOpen && !isFirstRender.current && organizationsFilterAsGroup) {
      const nextOrganizationsFilter = getOrganizationsSelection(organizationsFilterAsGroup);
      if (!ObjectUtils.equals(nextOrganizationsFilter, organizationsFilter)) {
        onUpdate(nextOrganizationsFilter);
      }
    }
    isFirstRender.current = false;
  }, [isDropdownOpen]);

  return (
    <HierarchicalSearchInputDropdown
      isDropdownOpen={isDropdownOpen}
      setIsDropdownOpen={setIsDropdownOpen}
      type={HierarchicalItemType.ORGANIZATION}
      toggleButton={(
        <ActionableBadge
          label={label}
          iconType="edit"
          onClick={() => setIsDropdownOpen((prev) => !prev)}
          dataTestId="organizations-filter-edit-button"
        />
      )}
      searchPlaceholder="Search for organizations"
      {...props}
      selection={organizationsFilterAsGroup}
    />
  );
}
