import React, { Key, useMemo, useState } from 'react';

import Dropdown from 'common-ui-components/Dropdown';
import MultiSelectionFilter from 'common-ui-components/Table/TableFilterDropdown/MultiSelectionFilter';
import {
  useCustomAtomsFilters,
} from 'screens/platform/cross-platform-components/context/CustomFilters/CustomAtomsFilters/CustomAtomsFiltersContextProvider';
import { CustomFilters } from 'screens/platform/cross-platform-components/context/CustomFilters/CustomFiltersUtils';
import FilterConfig, {
  FilterValue,
} from 'screens/platform/directory/components/GenericDirectoryScreen/FiltersList/FilterConfig';
import FilterButton from 'screens/platform/directory/components/ProfileTabs/components/Filters/FilterButton';

interface Props {
  filterType: Extract<keyof CustomFilters, 'people' | 'organizations' | 'apps' | 'topics' | 'directionality'>;
  filterConfig: FilterConfig<FilterValue>;
  disabled?: boolean;
}

const filterTypeToTitles: Record<Props['filterType'], string> = {
  people: 'People',
  organizations: 'Organizations',
  apps: 'Apps',
  topics: 'Topics',
  directionality: 'Stakeholders',
};

export default function GenericFilter({ filterType, filterConfig, disabled }: Props) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { dispatchCustomAtomsFilters, customAtomsFilters } = useCustomAtomsFilters();
  const title = filterTypeToTitles[filterType];

  const selectedKeys = useMemo(() => {
    const selectedValues = customAtomsFilters[filterType] || [];
    // Temporary solution to hide the requiredEntity from the selected values in the UI
    const requiredValues = customAtomsFilters.requiredEntity?.values || [];

    return selectedValues.filter((value) => !requiredValues.includes(value as string));
  }, [customAtomsFilters, filterType]);

  const handleFilterUpdate = (keys: Key[]) => {
    const hasSelectedItems = keys.length > 0;
    if (hasSelectedItems) {
      dispatchCustomAtomsFilters({
        type: 'ADD_FILTERS',
        payload: {
          [filterType]: keys as string[],
        },
      });
    } else {
      dispatchCustomAtomsFilters({
        type: 'DELETE_FILTERS',
        payload: { type: filterType },
      });
    }
  };

  return (
    <Dropdown
      closeDropdown={() => setIsDropdownOpen(false)}
      isDropdownOpen={isDropdownOpen}
      placement="bottom-start"
      toggleButtonElement={(
        <FilterButton
          closeFilterDropdown={() => setIsDropdownOpen(!isDropdownOpen)}
          hasSelectedValues={selectedKeys.length > 0}
          isDropdownOpen={isDropdownOpen}
          disabled={disabled}
        >
          {selectedKeys.length > 0 ? `${title} (${selectedKeys.length})` : title}
        </FilterButton>
      )}
    >
      <MultiSelectionFilter
        filterConfig={filterConfig}
        confirm={() => {
          setIsDropdownOpen(false);
        }}
        selectedKeys={selectedKeys}
        setSelectedKeys={handleFilterUpdate}
        visible={isDropdownOpen}
        close={() => setIsDropdownOpen(!isDropdownOpen)}
        prefixCls=""
        hasDoneButton={false}
      />
    </Dropdown>
  );
}
