import { useEffect, useState } from 'react';

import { useDomainImagesLoader } from 'screens/platform/cross-platform-components/context/metadata/hooks/ImagesLoaderHook';

export default function useDomainImage(domain: string | null): string | null {
  const domainImagesLoader = useDomainImagesLoader();

  const [image, setImage] = useState<string | null>(null);

  useEffect(() => {
    if (domain) {
      domainImagesLoader.get(domain, domain).then((nextImage) => setImage(nextImage || null));
    }
  }, [domain]);

  return image;
}
