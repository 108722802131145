import classNames from 'classnames';
import CSS from 'csstype';
import React from 'react';

import CSSVariableDefiner from 'common-ui-components/CSSVariableDefiner';
import Size from 'global/lists/Size';

import 'common-ui-components/ColoredCircle/style.scss';

interface Props {
  color: string;
  gradient?: boolean;
  size?: Size;
}

const circleSizes = {
  [Size.SMALL]: 10,
  [Size.MEDIUM]: 20,
};

export default function ColoredCircle({ color, gradient = false, size = Size.MEDIUM }: Props) {
  const circleSize = `${circleSizes[size]}px`;
  const style: CSS.Properties = {
    width: circleSize,
    height: circleSize,
  };
  if (gradient) {
    style.backgroundImage = `linear-gradient(to bottom, ${color}, darken(${color}, 1.2))`;
  }

  return (
    <CSSVariableDefiner variables={{ '--iconColor': color }}>
      <div
        className={classNames(
          'colored-circle',
          gradient && 'gradient',
        )}
        style={style}
      />
    </CSSVariableDefiner>
  );
}
