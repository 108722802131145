import React from 'react';

import Button from 'common-ui-components/Button';
import ExternalLink from 'common-ui-components/ExternalLink';
import { articleConfig } from 'common-ui-components/IntercomArticleButton';
import Modal from 'common-ui-components/Modal';
import App, { AppsWithChannels, ParentApp } from 'global/lists/apps';
import useAsync from 'react-use/lib/useAsync';
import { useMetadataContext } from 'screens/platform/cross-platform-components/context/metadata/MetadataContext';
import {
  SlackSubDivisionId,
} from 'screens/platform/cross-platform-components/MasterFiltersPanel/masterFiltersUtils/SlackChannelsUtils';
import InfoModalPlatform
  from 'screens/platform/cross-platform-components/Panels/BottomPanel/InfoPanel/InfoModal/InfoModalPlatform';
import PrivateChannelsInfoModalPlatform
  from 'screens/platform/cross-platform-components/Panels/BottomPanel/InfoPanel/InfoModal/InfoModalPlatform/PrivateChannelsInfoModalPlatform';
import ArrayUtils from 'utils/ArrayUtils';

import lockIcon from 'assets/img/icon/blue-circle-open-lock.svg';
import exitIcon from 'assets/img/icon/exit.svg';

import 'screens/platform/cross-platform-components/Panels/BottomPanel/InfoPanel/InfoModal/style.scss';

const HEADER = 'Akooda only shows data to which you already have access';
const P1 = 'Akooda does not let anyone view information that they are not exposed to already.';
const P2 = 'Platforms currently integrated to your Akooda account:';
const COPYRIGHT = '© 2024 Akooda, Inc. All rights reserved. ';
const LOGOS_TEXT = 'Logos provided by';

interface ConnectorDetails {
  description: string;
  isPublic: boolean;
  intercomArticleId?: number;
}

type PlatformDetails = { [K in Exclude<App, App.AKOODA | ParentApp>]: (
  K extends AppsWithChannels
    ? ConnectorDetails | Omit<ConnectorDetails, 'description'>
    : ConnectorDetails
) }

const PLATFORM_DETAILS: PlatformDetails = {
  [App.SLACK]: {
    description: 'All public channels',
    isPublic: true,
  },
  [App.GOOGLE_DRIVE]: {
    description: 'Your permitted documents',
    isPublic: false,
  },
  [App.GOOGLE_CALENDAR]: {
    description: 'Public events',
    isPublic: true,
  },
  [App.GOOGLE_MEET]: {
    description: 'Meetings found in Google Calendar events',
    isPublic: true,
  },
  [App.GOOGLE_GMAIL]: {
    description: 'Your permitted inboxes',
    isPublic: false,
  },
  [App.DROPBOX]: {
    description: 'Your permitted files',
    isPublic: false,
  },
  [App.ATLASSIAN_JIRA]: {
    description: 'All tickets',
    isPublic: true,
  },
  [App.ATLASSIAN_CONFLUENCE]: {
    description: 'All published pages',
    isPublic: true,
  },
  [App.GITHUB]: {
    description: 'All repositories',
    isPublic: true,
  },
  [App.MONDAY]: {
    description: 'All workspaces',
    isPublic: true,
  },
  [App.FIGMA]: {
    description: 'All files',
    isPublic: true,
  },
  [App.NOTION]: {
    description: 'All pages available to anyone',
    isPublic: true,
    intercomArticleId: articleConfig.ConnectorNotion.articleID,
  },
  [App.INTERCOM]: {
    description: 'All conversations',
    isPublic: true,
  },
  [App.PHABRICATOR_BRANCHES]: {
    description: 'All branches',
    isPublic: true,
  },
  [App.PHABRICATOR_TICKETS]: {
    description: 'All tickets',
    isPublic: true,
  },
  [App.ZOOM]: {
    description: 'Meetings found in Google Calendar events',
    isPublic: true,
  },
  [App.BITBUCKET]: {
    isPublic: true,
  },
  [App.SALESFORCE]: {
    description: 'All interactions (notes, chatter, emails, tasks, cases and events)',
    isPublic: true,
  },
  [App.ZENDESK]: {
    description: 'Tickets and help center articles',
    isPublic: true,
  },
  [App.BOX]: {
    description: 'Your permitted documents',
    isPublic: false,
  },
  [App.M365_SHAREPOINT]: {
    description: 'Your permitted documents',
    isPublic: false,
  },
  [App.M365_ONEDRIVE]: {
    description: 'Your permitted documents',
    isPublic: false,
  },
  [App.ASANA]: {
    description: 'All tickets',
    isPublic: true,
  },
  [App.LINEAR]: {
    description: 'All issues',
    isPublic: true,
  },
  [App.HUBSPOT]: {
    description: 'All engagements',
    isPublic: true,
  },
  [App.FRONT]: {
    description: 'All inboxes',
    isPublic: true,
  },
  [App.AIRTABLE]: {
    description: "Your permitted bases. In case you've provided Akooda with permissions to a Workspace, Akooda will have access to all bases in that workspace",
    isPublic: true,
    intercomArticleId: articleConfig.ConnectorAirtable.articleID,
  },
  [App.MIRO]: {
    isPublic: true,
  },
  [App.M365_EXCHANGE]: {
    description: 'Your permitted inboxes',
    isPublic: false,
  },
};

export default function InfoModal({
  isOpen,
  handleClose,
}) {
  const { channels, connectedApps } = useMetadataContext();
  async function getInfoModalPlatforms() {
    const platforms = [] as React.JSX.Element[];
    await Promise.all(
      Object.keys(connectedApps).map(async (appId) => {
        platforms.push(
          <InfoModalPlatform
            key={appId}
            appId={Number(appId)}
            {...PLATFORM_DETAILS[appId]}
          />,
        );

        if (Number(appId) === App.SLACK) {
          const slackPrivateChannelsIds = channels.appsToChannelsMap[
            SlackSubDivisionId.SLACK_PRIVATE_CHANNELS
          ];

          if (slackPrivateChannelsIds) {
            const privateChannels = (await Promise.all(
              slackPrivateChannelsIds.map(channels.getById),
            )).filter(ArrayUtils.isDefined);
            platforms.push(
              <PrivateChannelsInfoModalPlatform
                key={`${appId} private`}
                description={`${privateChannels.length} Private Channels`}
                appId={Number(appId)}
                privateChannels={privateChannels}
              />,
            );
          }
        }
      }),
    );

    return platforms;
  }

  const { value: infoModalPlatforms } = useAsync(getInfoModalPlatforms, [connectedApps]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      className="info-modal"
    >
      <Button
        className="exit-button"
        onClick={handleClose}
      >
        <img src={exitIcon} alt="Close Modal" />
      </Button>
      <div className="info-modal-header">
        <img src={lockIcon} alt="" />
        {HEADER}
      </div>
      <div className="info-modal-body">
        <p>{P1}</p>
        <p>{P2}</p>
        {infoModalPlatforms}
      </div>
      <div className="info-modal-footer">
        <span>
          {LOGOS_TEXT}
          <ExternalLink to="https://clearbit.com">
            {' Clearbit'}
          </ExternalLink>
        </span>
        <span>
          {COPYRIGHT}
          <a href="mailto:info@akooda.co">Contact</a>
        </span>
      </div>
    </Modal>
  );
}
