import ArrayUtils from 'utils/ArrayUtils';

abstract class UnionBasedMasterFilter<T extends UnionBasedMasterFilter<any>> {
  abstract clone(): T;
  abstract equalsIgnoreFlags(other: any): boolean;
  abstract isAllSelected(): boolean;
  abstract isEmpty(): boolean;

  protected isSameType(other: any): other is T {
    if (!other) return false;
    return Object.keys(this).every((key) => key in other);
  }

  protected static areArraysNullOrEquals(arr1: any[] | null, arr2: any[] | null): boolean {
    if (arr1 === null || arr2 === null) {
      return (arr1 === null && arr2 === null);
    }
    return ArrayUtils.isEqual(arr1, arr2);
  }
}

export default UnionBasedMasterFilter;
