import classNames from 'classnames';
import React from 'react';

import style from 'common-ui-components/HorizontalSeparator/style.module.scss';

export default function HorizontalSeparator({ className }: { className?: string }) {
  return (
    <hr className={classNames(style.separator, className)} />
  );
}
