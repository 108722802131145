import FilterCondition from 'global/api/controller/utils/filtering/FilterCondition';
import { ListOptions } from 'global/ListOptions';

type FilterFn<T> = (searchQuery: string, options: ListOptions<T>) => Promise<ListOptions<T>>;

function defaultSearchFn<T>(
  searchQuery: string,
  initialOptions: ListOptions<T>,
) {
  if (searchQuery.length === 0) {
    return Promise.resolve(initialOptions);
  }

  return Promise.resolve(
    initialOptions.filter((option) => {
      const searchQueryLower = searchQuery.toLowerCase();
      return option.label.toLowerCase().includes(searchQueryLower);
    }),
  );
}

export class DynamicListOptions<T> {
  private currentOptions: ListOptions<T>;
  private initialOptions: ListOptions<T>;
  private filterFn: FilterFn<T>;

  constructor(
    initialOptions: ListOptions<T>,
    filterFn: FilterFn<T> = defaultSearchFn,
  ) {
    this.currentOptions = initialOptions;
    this.initialOptions = initialOptions;
    this.filterFn = filterFn;
  }

  getCurrentOptions(): ListOptions<T> {
    return this.currentOptions;
  }

  get hasAny(): boolean {
    return this.initialOptions.length > 0;
  }

  async updateOptionsWithFilter(searchQuery: string) {
    this.currentOptions = await this.filterFn(searchQuery, this.initialOptions);
    // This resolves a bug where the component starts using the options before
    // they have been initialized. For example, when we call
    // OrganizationFiltersConfig.useDynamicFilterOptions, sometimes the nameOptions there
    // are undefined. So we don't have any initialOptions set. However, the current options
    // get updated from the component. So we're in a situation where we have currentOptions
    // but no initialOptions.
    //
    // The bug is that in some rare cases, when you load the organization filter initially
    // you won't see the filter even though we have options. Just comment out this fix
    // to reproduce the behavior.
    //
    // I have no idea why this happens but I've tried to fix for about 2 hours and this
    // was the workaround I found. I suspect it might be an issue with how Ant initializes
    // the filters.
    if (this.initialOptions.length === 0) {
      this.initialOptions = this.currentOptions;
    }
  }
}

type FilterConfig<ValueType extends FilterValue> = {
  label: string;
  options: DynamicListOptions<ValueType> | null;
  removable: boolean;
  disabledOptions?: Partial<Record<ValueType, DisabledOptionReason>>;
  noOptionsMessage?: string;
} & (
  | {
      multiSelect: true;
      defaultValue: ValueType[];
      toFilterCondition?: (value: ValueType[]) => FilterCondition;
    }
  | {
      multiSelect: false;
      defaultValue: ValueType;
      toFilterCondition?: (value: ValueType) => FilterCondition;
    }
);

type DisabledOptionReason = string;
export type FilterValue = string | number;
export type FilterValues<T extends string = string> = Partial<
  Record<T, FilterValue | FilterValue[]>
>;
export type FiltersConfig<T extends string> = Record<T, FilterConfig<any>>;

export default FilterConfig;
