import React from 'react';

import FeedbackContext from 'common-ui-components/Feedback/FeedbackContext';
import useFeedbackSender from 'common-ui-components/Feedback/hooks/FeedbackSenderHook';
import SingleFeedbackButton, { FeedbackType } from 'common-ui-components/Feedback/SingleFeedbackButton';

import style from 'common-ui-components/Feedback/style.module.scss';

export default function Feedback(props: FeedbackContext) {
  const { loading, selected, sendFeedback } = useFeedbackSender(props);

  function getFeedbackButton(feedbackType: FeedbackType) {
    return (
      <SingleFeedbackButton
        type={feedbackType}
        selected={selected === feedbackType}
        onClick={() => sendFeedback(feedbackType)}
        disabled={loading}
      />
    );
  }

  return (
    <div className={style.feedback}>
      {getFeedbackButton('LIKE')}
      {getFeedbackButton('DISLIKE')}
    </div>
  );
}
