const HierarchicalSearchResultsUtils = {
  parseInitiallyOpen(initiallyOpen: boolean | { root: boolean; children: boolean }): {
    isRootInitiallyOpen: boolean;
    areChildrenInitiallyOpen: boolean;
  } {
    if (typeof initiallyOpen === 'boolean') {
      return {
        isRootInitiallyOpen: initiallyOpen,
        areChildrenInitiallyOpen: initiallyOpen,
      };
    }
    return {
      isRootInitiallyOpen: initiallyOpen.root,
      areChildrenInitiallyOpen: initiallyOpen.children,
    };
  },
};

export default HierarchicalSearchResultsUtils;
