import {
  ChartType,
  DateRangeGranularity,
  GroupType,
  WidgetRecipe,
} from 'screens/platform/contentScreens/AnalyticsScreen/widgets/widgetConfig';
import VolumeTimeLineChart from 'screens/platform/cross-platform-components/VolumeTimeLineChart';

const availableGroupTypes: (GroupType | null)[] = [
  null,
  GroupType.CATEGORIES,
  GroupType.DEPARTMENTS,
  GroupType.TAGS,
  GroupType.APPS,
  GroupType.CHANNELS,
  GroupType.ORGANIZATIONS,
  GroupType.ORIENTATION,
];

const volumeRecipes: WidgetRecipe[] = availableGroupTypes.flatMap((group) => [
  {
    group,
    chart: ChartType.LINE,
    granularity: DateRangeGranularity.DAY,
    Component: VolumeTimeLineChart,
  }, {
    group,
    chart: ChartType.LINE,
    granularity: DateRangeGranularity.WEEK,
    Component: VolumeTimeLineChart,
  },
]);

export default volumeRecipes;
