import HierarchicalGroup from 'utils/HierarchicalDataStructures/HierarchicalGroup';

interface MinimalSelectionStatus {
  selectedGroups: string[];
  selectedItems: string[];
}

export default class HierarchicalMasterFilter extends HierarchicalGroup {
  /**
   * If a group is selected, its items aren't mentioned
   */
  getMinimalSelectionStatus(): MinimalSelectionStatus {
    const allGroups = this.children as Record<string, HierarchicalGroup>;
    return Object.entries(allGroups).reduce<MinimalSelectionStatus>((acc, [groupId, group]) => {
      if (group.isSelected) {
        acc.selectedGroups.push(groupId);
      } else if (group.isPartiallySelected) {
        group.getChildrenEntries().forEach(([itemId, item]) => {
          if (item.isSelected) {
            acc.selectedItems.push(itemId);
          }
        });
      }
      return acc;
    }, { selectedGroups: [], selectedItems: [] });
  }
}
