import {
  AllOrganizationsMetadataApiResponse,
  OrganizationMetadataApiResponse,
} from 'global/api/controller/OrganizationController';
import {
  NullableValuesSelectionConstants,
} from 'screens/platform/cross-platform-components/context/MasterFiltersContext/NullableValuesSelection';
import AllOrganizationsMetadata
  from 'screens/platform/cross-platform-components/context/OrganizationsMetadata/dataStructures/AllOrganizationsMetadata';
import OrganizationMetadata
  from 'screens/platform/cross-platform-components/context/OrganizationsMetadata/dataStructures/OrganizationMetadata';
import ObjectUtils from 'utils/ObjectUtils';

const { UNDEFINED_PROPERTY } = NullableValuesSelectionConstants;

export default function parseOrganizationsResponse(
  nextOrganizations: AllOrganizationsMetadataApiResponse,
): Pick<AllOrganizationsMetadata, 'allOrganizationsMetadata'> & {
  risksToOrganizationsMap: AllOrganizationsMetadata['risksToOrganizations'] & Partial<{ UNDEFINED_PROPERTY: string[] }>;
  sizesToOrganizationsMap: AllOrganizationsMetadata['sizesToOrganizations'] & Partial<{ UNDEFINED_PROPERTY: string[] }>;
  segmentsToOrganizationsMap: AllOrganizationsMetadata['segmentsToOrganizations'] & Partial<{ UNDEFINED_PROPERTY: string[] }>;
} {
  function addUndefinedCase<T extends string>(
    metadataField: keyof OrganizationMetadataApiResponse,
    defaultObject: Record<T, string[]>,
  ) {
    const organizationsWithUndefinedField = Object.entries(allOrganizationsMetadata)
      .reduce<string[]>((acc, [orgId, orgMetadata]) => {
        const value = orgMetadata?.[metadataField];
        if (metadataField === 'segments') {
          return (value === null || value?.length === 0
            ? acc.concat([orgId])
            : acc);
        }
        return (value === null ? acc.concat([orgId]) : acc);
      }, []);
    const additionalObject = organizationsWithUndefinedField.length > 0
      ? { [UNDEFINED_PROPERTY]: organizationsWithUndefinedField }
      : {};

    return { ...defaultObject, ...additionalObject };
  }

  const {
    allOrganizationsMetadata,
    risksToOrganizations,
    sizesToOrganizations,
    segmentsToOrganizations,
  } = nextOrganizations;

  const parsedAllOrganizationsMetadata = ObjectUtils.mapEntries(
    allOrganizationsMetadata,
    ([organizationId, organizationMetadata]) => [
      organizationId,
      new OrganizationMetadata(organizationMetadata!),
    ],
  );

  const risksToOrganizationsMap = addUndefinedCase('risk', risksToOrganizations);
  const sizesToOrganizationsMap = addUndefinedCase('size', sizesToOrganizations);
  const segmentsToOrganizationsMap = addUndefinedCase('segments', segmentsToOrganizations);

  return {
    allOrganizationsMetadata: parsedAllOrganizationsMetadata,
    risksToOrganizationsMap,
    sizesToOrganizationsMap,
    segmentsToOrganizationsMap,
  };
}
