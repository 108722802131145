import { useMemo } from 'react';

import { SortingConfig } from 'common-ui-components/Table/sortingHook';
import { TableColumnsOrder } from 'common-ui-components/Table/TableTypes';
import { DirectoryTopic } from 'global/api/controller/TagsSetController';
import { TagCustomSortingConfig } from 'global/api/controller/utils/TagsControllerSortingUtils';
import Metric from 'global/lists/Metric';
import useOrganizationFeatureConfig from 'screens/platform/cross-platform-components/context/configuration/OrganizationFeatureConfigHook';
import { DirectoryTableColumn } from 'screens/platform/directory/directoryTableColumnsHook';

export enum TopicsDirectoryTableColumnId {
  TOPIC = 'Topic',
  KEYWORDS = 'Keywords',
  CATEGORIES = 'Categories',
  PEOPLE = 'People',
  DEPARTMENTS = 'Departments',
  ORGANIZATIONS = 'Organizations',
  LAST_ACTIVITY = 'Last Activity',
  TOTAL_INTERACTIONS = 'Total Interactions',
  TOTAL_CONVERSATIONS = 'Total Conversations',
  TOTAL_MEETINGS = 'Total Meetings',
  RELATIVE_VOLUME = 'Relative Volume',
}

export const sortableTopicsDirectoryColumns = [
  TopicsDirectoryTableColumnId.TOPIC,
  TopicsDirectoryTableColumnId.CATEGORIES,
  TopicsDirectoryTableColumnId.LAST_ACTIVITY,
  TopicsDirectoryTableColumnId.TOTAL_INTERACTIONS,
  TopicsDirectoryTableColumnId.TOTAL_CONVERSATIONS,
  TopicsDirectoryTableColumnId.TOTAL_MEETINGS,
  TopicsDirectoryTableColumnId.RELATIVE_VOLUME,
];

export type TopicsDirectoryTableColumn<
  SortingConfig extends TagCustomSortingConfig = TagCustomSortingConfig,
> = DirectoryTableColumn<
  DirectoryTopic,
  TopicsDirectoryTableColumnId,
  SortingConfig
>;

export type TopicsDirectoryTableColumnsOrder =
  TableColumnsOrder<TopicsDirectoryTableColumnId>;

export const defaultTagsTableSorting: SortingConfig<TagCustomSortingConfig> = {
  columnKey: TopicsDirectoryTableColumnId.TOTAL_INTERACTIONS,
  order: 'descend',
  customConfig: {
    metricType: Metric.TOTAL_TAG_INTERACTIONS,
  },
};

export function useDefaultTopicsTableColumnsOrder() {
  const { value: isOrganizationsFeatureActivated } = useOrganizationFeatureConfig();

  return useMemo<TopicsDirectoryTableColumnsOrder>(
    () =>
      Object.fromEntries(
        Object.values(TopicsDirectoryTableColumnId)
          .filter(
            (c) =>
              c !== TopicsDirectoryTableColumnId.ORGANIZATIONS
              || isOrganizationsFeatureActivated,
          )
          .map((c, index) => [c, index]),
      ),
    [isOrganizationsFeatureActivated],
  );
}
