import {
  createContext,
  Dispatch,
  useContext,
} from 'react';

import { GraphFeaturesReducerAction, GraphFeaturesState } from 'screens/platform/cross-platform-components/context/graphFeatures/GraphFeaturesReducer';

export enum ClusteringTypeKey {
  SMART = 'SMART',
  DEPARTMENT = 'DEPARTMENT',
  INTERNAL_EXTERNAL = 'INTERNAL_EXTERNAL',
}

export interface GraphFeaturesContextType extends GraphFeaturesState {
  dispatchGraphFeatures: Dispatch<GraphFeaturesReducerAction>;
}

export const useGraphFeatures:
  () => GraphFeaturesContextType = () => useContext<GraphFeaturesContextType>(GraphFeaturesContext);

const GraphFeaturesContext = createContext<GraphFeaturesContextType>({} as any);
export default GraphFeaturesContext;
