import classNames from 'classnames';
import React, { useEffect } from 'react';

import HorizontalSeparator from 'common-ui-components/HorizontalSeparator';
import CollapsibleGroupItems
  from 'screens/platform/cross-platform-components/HierarchicalSearchInput/components/HierarchicalSearchResults/CollapsibleGroup/CollapsibleGroupItems';
import RootGroupTitleRow
  from 'screens/platform/cross-platform-components/HierarchicalSearchInput/components/HierarchicalSearchResults/RootCollapsibleGroup/RootGroupTitleRow';
import {
  HierarchicalItemType,
} from 'screens/platform/cross-platform-components/HierarchicalSearchInput/HierarchicalItemTypeConfig';
import HierarchicalGroup from 'utils/HierarchicalDataStructures/HierarchicalGroup';
import { useToggle } from 'utils/hooks';

import style from 'screens/platform/cross-platform-components/HierarchicalSearchInput/style.module.scss';

interface Props {
  label?: string;
  group: HierarchicalGroup;
  initiallyOpen: boolean;
  handleEmptySelectionChange?: (isEmpty: boolean) => void;
  type?: HierarchicalItemType;
  onToggle: (id: string | null) => void;
  visibleItemsIds: string[];
  getItemSizeLabel?: (itemId: string) => string;
  getItemLabel?: (itemId: string) => string;
  getItemImage?: (itemId: string) => JSX.Element;
}

export default function RootCollapsibleGroup({
  label,
  group,
  initiallyOpen,
  handleEmptySelectionChange,
  type,
  onToggle,
  visibleItemsIds,
  getItemSizeLabel,
  getItemLabel,
  getItemImage,
}: Props) {
  const [isOpen, toggleIsOpen] = useToggle(initiallyOpen);

  useEffect(() => {
    const id = group.subscribe(() => handleEmptySelectionChange?.(group.isNotSelected()));
    return () => group.unsubscribe(id);
  }, []);

  return (
    <div className={style.collapsibleRootGroup}>
      <RootGroupTitleRow
        isOpen={isOpen}
        toggleIsOpen={toggleIsOpen}
        selection={group}
        label={label || group.id}
        showAmount
        amountParser={(amount: number) => `${amount} selected`}
        onToggle={onToggle}
      />
      <div
        className={classNames(style.collapsibleItemsSection, !isOpen && style.close)}
      >
        <CollapsibleGroupItems
          isOpen={isOpen}
          visibleItemsIds={visibleItemsIds}
          group={group}
          type={type}
          onToggle={onToggle}
          getItemSizeLabel={getItemSizeLabel}
          getItemImage={getItemImage}
          getItemLabel={getItemLabel}
        />
      </div>
      {isOpen && <HorizontalSeparator />}
    </div>
  );
}
