import classNames from 'classnames';
import React from 'react';

import HiddenEntitiesNode from 'common-ui-components/MultiImages/HiddenEntitiesNode';
import { ImageType, splitToVisibleAndHidden } from 'common-ui-components/MultiImages/MultiImagesUtils';
import { getNodeImageWrapperClass, ImageShape } from 'common-ui-components/MultiImages/NodeImage';
import Spinner from 'common-ui-components/Spinner';
import Size from 'global/lists/Size';

import style from 'common-ui-components/MultiImages/style.module.scss';

interface Props {
  ids: string[];
  getVisibleImages: (visibleIds: string[]) => JSX.Element[];
  getHiddenEntitiesLabels: (hiddenIds: string[]) => string[];
  onHiddenEntitiesClick?: () => void;
  hiddenEntitiesNodeClassName?: string;
  imageType: ImageType;
  loading: boolean;
  size?: Size;
  maxImagesThreshold?: number;
}

export default function MultiImages({
  ids,
  getVisibleImages,
  getHiddenEntitiesLabels,
  onHiddenEntitiesClick,
  hiddenEntitiesNodeClassName,
  imageType,
  loading,
  size = Size.MEDIUM,
  maxImagesThreshold,
}: Props) {
  const { visibleIds, hiddenIds } = splitToVisibleAndHidden(ids, maxImagesThreshold);

  const imageShape = imageType === ImageType.PEOPLE ? ImageShape.ROUND : ImageShape.SQUARE;

  if (ids.length === 0) {
    return null;
  }

  return loading ? (
    <div className={getNodeImageWrapperClass(size)}>
      <Spinner />
    </div>
  ) : (
    <div
      className={classNames(
        style.images,
        imageShape === ImageShape.ROUND ? style.withHalfCircleBorder : style.withSquareRoundBorder,
      )}
    >
      {getVisibleImages(visibleIds)}
      <HiddenEntitiesNode
        entities={getHiddenEntitiesLabels(hiddenIds)}
        size={size}
        shape={imageShape}
        className={hiddenEntitiesNodeClassName}
        onClick={onHiddenEntitiesClick}
        titleAttributeText={`See in ${imageType} Directory`}
      />
    </div>
  );
}
