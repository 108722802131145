import React, { ReactNode } from 'react';

import Spinner from 'common-ui-components/Spinner';
import DataRetrievalErrorMessage from 'screens/platform/cross-platform-components/ChartStatesHandler/DataRetrievalErrorMessage';
import useErrorType from 'screens/platform/cross-platform-components/Hook/ErrorTypeHook';

type Props = {
  loading: boolean;
  emptyData: boolean;
  isNetworkError: boolean;
  children: ReactNode;
  customEmptyDataComponent?: ReactNode;
};

export default function ChartStatesHandler({
  loading,
  isNetworkError,
  emptyData,
  customEmptyDataComponent,
  children,
}: Props) {
  const errorType = useErrorType(isNetworkError);

  if (loading) {
    return <Spinner />;
  }

  if (emptyData && customEmptyDataComponent && !isNetworkError) {
    return <>{customEmptyDataComponent}</>;
  }

  if (emptyData && errorType) {
    return <DataRetrievalErrorMessage errorType={errorType} />;
  }

  return <>{children}</>;
}
