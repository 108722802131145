import React from 'react';

import { Heading1 } from 'common-ui-components/Typograhpy';
import useWelcomeMessage from 'es-src/screens/HomeScreen/components/WelcomeMessage/hooks/WelcomeMessageDataHook';
import { useEnterpriseSearchContext } from 'es-src/screens/HomeScreen/EnterpriseSearchContext/EnterpriseSearchContext';

import style from 'es-src/screens/HomeScreen/components/WelcomeMessage/style.module.scss';

export default function WelcomeMessage() {
  const welcomeMessage = useWelcomeMessage();
  const { loading, isResultCardOpen } = useEnterpriseSearchContext();
  const shouldHide = loading || isResultCardOpen;

  if (shouldHide) return null;

  return (
    <Heading1 className={style.welcomeMessageHeader}>
      {welcomeMessage}
    </Heading1>
  );
}
