import Api from 'global/api/platformApi';
import useAsync from 'react-use/lib/useAsync';
import useTenantContext from 'screens/platform/cross-platform-components/context/tenant/TenantContext';
import { useUserContext } from 'screens/platform/cross-platform-components/context/user/UserContext';
import DebuggerConsole from 'utils/DebuggerConsole';

export default function useLoggedInPersonTeam() {
  const { personId } = useUserContext();
  const {
    tenant: { id: tenantId },
  } = useTenantContext();
  const { value: teamPersonIds } = useAsync(async () => {
    if (!personId) return [];
    try {
      const res = await Api.Person.getPersonTeam(tenantId, personId);
      if (res) return res.data;
    } catch (error) {
      DebuggerConsole.error(
        'Failed to fetch logged in person team',
        tenantId,
        personId,
      );
    }
    return [];
  }, [personId, tenantId]);

  return teamPersonIds;
}
