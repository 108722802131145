import React, { useState } from 'react';

import Button from 'common-ui-components/Button';
import Caret from 'common-ui-components/Caret';
import Dropdown from 'common-ui-components/Dropdown';
import Size from 'global/lists/Size';
import Channel from 'model/Channel';
import InfoModalPlatform, {
  InfoModalPlatformProps,
} from 'screens/platform/cross-platform-components/Panels/BottomPanel/InfoPanel/InfoModal/InfoModalPlatform/index';

import lockIcon from 'assets/img/icon/lock.svg';

interface PrivateChannelsInfoModalPlatformProps extends InfoModalPlatformProps {
  privateChannels: Channel[];
}

export default function PrivateChannelsInfoModalPlatform({
  description,
  appId,
  privateChannels,
}: PrivateChannelsInfoModalPlatformProps) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  return (
    <Dropdown
      toggleButtonElement={(
        <Button
          className="info-modal-wrapper-button"
          onClick={() => setIsDropdownOpen((prev) => !prev)}
        >
          <InfoModalPlatform
            description={description}
            isPublic={false}
            appId={appId}
          />
          <div className="caret">
            <Caret isOpen={isDropdownOpen} size={Size.SMALL} />
          </div>
        </Button>
    )}
      wrapperClassName="info-modal-platform-dropdown"
      dropdownClassName="dropdown-menu"
      placement="bottom-start"
      isDropdownOpen={isDropdownOpen}
      closeDropdown={() => setIsDropdownOpen(false)}
    >
      {privateChannels!.map((channel) => (
        <div key={channel.id} className="private-channel-name">
          <img src={lockIcon} alt="private channel" />
          <span>{channel.name}</span>
        </div>
      ))}
    </Dropdown>
  );
}
