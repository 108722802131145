import classNames from 'classnames';
import React from 'react';

import style from 'es-src/components/ui/Skeleton/style.module.scss';

interface Props {
  type: 'text' | 'paragraph' | 'title' | 'avatar' | 'subtitle' | 'badge';
  className?: string;
}

export default function SkeletonElement({ type, className }: Props) {
  return (
    <div className={classNames(
      style.skeleton,
      type === 'title' && style.title,
      type === 'paragraph' && style.paragraph,
      type === 'avatar' && style.avatar,
      type === 'text' && style.text,
      type === 'subtitle' && style.subtitle,
      type === 'badge' && style.badge,
      className,
    )}
    />
  );
}
