import React from 'react';

import { GroupedOrganizations } from 'global/api/controller/OrganizationController';
import OrganizationsMasterFilterHierarchyGroup
  from 'screens/platform/cross-platform-components/context/MasterFiltersContext/OrganizationsMasterFilter/OrganizationsMasterFilterHierarchyGroup';
import { useOrganizationsMetadataContext } from 'screens/platform/cross-platform-components/context/OrganizationsMetadata/OrganizationsMetadataContext';
import RootCollapsibleGroup
  from 'screens/platform/cross-platform-components/HierarchicalSearchInput/components/HierarchicalSearchResults/RootCollapsibleGroup';
import {
  HierarchicalItemType,
} from 'screens/platform/cross-platform-components/HierarchicalSearchInput/HierarchicalItemTypeConfig';
import FilterSectionUtils
  from 'screens/platform/cross-platform-components/MasterFiltersPanel/FilterSection/FilterSectionUtils';
import getOrganizationFilterLabel
  from 'screens/platform/cross-platform-components/MasterFiltersPanel/FilterSection/OrganizationsFilterSection/components/OrganizationsHierarchicalSearchResults/OrganizationPropertiesLabels';
import useOrganizationsHierarchicalSearchResultsReducer
  from 'screens/platform/cross-platform-components/MasterFiltersPanel/FilterSection/OrganizationsFilterSection/components/OrganizationsHierarchicalSearchResults/OrganizationsHierarchicalSearchResultsReducer';
import {
  OrganizationDimension,
} from 'screens/platform/cross-platform-components/MasterFiltersPanel/FilterSection/OrganizationsFilterSection/components/OrganizationsHierarchicalSearchResults/OrganizationsHierarchicalSearchResultsReducerActions';

import style
  from 'screens/platform/cross-platform-components/MasterFiltersPanel/FilterSection/ColumnsHierarchicalSearchInputWrapper/style.module.scss';

interface Props {
  selection: OrganizationsMasterFilterHierarchyGroup;
  visibleItems: GroupedOrganizations;
  shouldUseColumnsView: boolean;
  handleEmptySelectionChange?: (isEmpty: boolean) => void;
}

const { checkFilterHasDefinedValues } = FilterSectionUtils;

export default function OrganizationsHierarchicalSearchResults({
  selection,
  visibleItems,
  shouldUseColumnsView,
  handleEmptySelectionChange,
}: Props) {
  const {
    risksToOrganizationsMap, sizesToOrganizationsMap, segmentsToOrganizationsMap,
  } = useOrganizationsMetadataContext();
  const {
    state,
    getDispatchFunction,
  } = useOrganizationsHierarchicalSearchResultsReducer(selection);

  const organizationsFilter = (
    <RootCollapsibleGroup
      label="Organizations"
      group={state.organizationsSelection}
      visibleItemsIds={visibleItems.allOrganizationsIds}
      initiallyOpen
      handleEmptySelectionChange={handleEmptySelectionChange}
      onToggle={getDispatchFunction('TOGGLE_ORGANIZATION')}
      type={HierarchicalItemType.ORGANIZATION}
    />
  );

  const shouldShowRiskSection = checkFilterHasDefinedValues(selection.getRisksGroup());
  const shouldShowSizeSection = checkFilterHasDefinedValues(selection.getSizesGroup());
  const shouldShowSegmentSection = checkFilterHasDefinedValues(selection.getSegmentsGroup());

  return shouldUseColumnsView ? (
    <div className={style.filterColumns}>
      <section className={style.column}>
        {shouldShowRiskSection && (
        <RootCollapsibleGroup
          label="Risk"
          group={state.riskSelection}
          visibleItemsIds={visibleItems.risks}
          onToggle={getDispatchFunction('TOGGLE_RISK')}
          initiallyOpen
          getItemSizeLabel={(itemId) => `(${risksToOrganizationsMap[itemId]?.length || 0} Organizations)`}
          getItemLabel={(itemId) => getOrganizationFilterLabel(itemId, OrganizationDimension.RISK)}
        />
        )}
        {shouldShowSizeSection && (
        <RootCollapsibleGroup
          label="Size"
          group={state.sizeSelection}
          visibleItemsIds={visibleItems.sizes}
          onToggle={getDispatchFunction('TOGGLE_SIZE')}
          initiallyOpen
          getItemSizeLabel={(itemId) => `(${sizesToOrganizationsMap[itemId]?.length || 0} Organizations)`}
          getItemLabel={(itemId) => getOrganizationFilterLabel(itemId, OrganizationDimension.SIZE)}
        />
        )}
        {shouldShowSegmentSection && (
        <RootCollapsibleGroup
          label="Segments"
          group={state.segmentSelection}
          visibleItemsIds={visibleItems.segments}
          onToggle={getDispatchFunction('TOGGLE_SEGMENT')}
          initiallyOpen
          getItemSizeLabel={(itemId) => `(${segmentsToOrganizationsMap[itemId]?.length || 0} Organizations)`}
          getItemLabel={(itemId) =>
            getOrganizationFilterLabel(itemId, OrganizationDimension.SEGMENTS)}
        />
        )}
      </section>
      <section className={style.column}>
        {organizationsFilter}
      </section>
    </div>
  ) : organizationsFilter;
}
