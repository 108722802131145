import React, { useEffect, useRef } from 'react';

import emojiParser from 'common-ui-components/InteractionTitle/parsers/emojiParser';
import AnimatedCursor
  from 'screens/platform/cross-platform-components/Interactions/components/InteractionsSummary/TypeWriter/AnimatedCursor';
import ArrayUtils from 'utils/ArrayUtils';
import { useMountedState } from 'utils/hooks';

import style from 'screens/platform/cross-platform-components/Interactions/components/InteractionsSummary/TypeWriter/style.module.scss';

interface Props {
  text: string;
  onFinishTyping?: () => void;
}

const TYPING_SPEED_FACTOR = 25;

export default function TypeWriter({ text, onFinishTyping }: Props) {
  const [visibleText, setVisibleText] = useMountedState('');
  const timeoutId = useRef<ReturnType<typeof setTimeout>>();
  const isSentenceEnding = useRef(false);

  function addNextWord(index: number) {
    const timeout = Math.random()
      * TYPING_SPEED_FACTOR
      * (isSentenceEnding.current ? 5 : 1); // Delay when ending a sentence

    timeoutId.current = setTimeout(() => {
      if (index < text.length) {
        const addition = ArrayUtils.getIntegers(Math.floor(Math.random() * 2))
          .map((i) => text[index + i])
          .join('');

        setVisibleText((prev) => emojiParser(`${prev}${addition}`));

        isSentenceEnding.current = /\.\s*$/.test(addition);
        addNextWord(index + addition.length);
      } else {
        onFinishTyping?.();
      }
    }, timeout);
  }

  useEffect(() => {
    addNextWord(0);
    return () => clearTimeout(timeoutId.current);
  }, []);

  return (
    <div data-testid="interactions-summary-content" className={style.typeWriter}>
      {visibleText}
      {visibleText.length === text.length ? null : <AnimatedCursor />}
    </div>
  );
}
