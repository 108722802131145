import React from 'react';

import { SETUP_STATE } from 'model/Tenant';
import PlatformRouter from 'routers/PlatformRouter';
import useTenantContext from 'screens/platform/cross-platform-components/context/tenant/TenantContext';
import UrlParamsGenerator from 'screens/platform/cross-platform-components/context/UrlParams/UrlParamsGenerator';
import { useUserContext } from 'screens/platform/cross-platform-components/context/user/UserContext';
import Navbar from 'screens/platform/cross-platform-components/Navbar';
import { NavbarContextProvider } from 'screens/platform/cross-platform-components/Navbar/NavbarContext';
import SessionContexts from 'screens/platform/PlatformScreen/SessionContexts';

import 'common-ui-components/tooltipStyle.scss';
import style from 'screens/platform/PlatformScreen/style.module.scss';

export default function PlatformScreen() {
  const { tenant } = useTenantContext();
  const { isSuperAdmin } = useUserContext();
  const isTenantReady = tenant.setupState === SETUP_STATE.READY
    || (tenant.setupState === SETUP_STATE.SCANNING && isSuperAdmin);

  return (
    <div className={style.platformScreen}>
      <SessionContexts>
        <UrlParamsGenerator />
        <div className={style.contentContainerWrapper}>
          <NavbarContextProvider>
            <Navbar disabled={!isTenantReady} />
            <PlatformRouter />
          </NavbarContextProvider>
        </div>
      </SessionContexts>
    </div>
  );
}
