import { MutableRefObject, useEffect, useRef } from 'react';

import ArrayUtils from 'utils/ArrayUtils';
import { useMountedState, useResizeListener } from 'utils/hooks';

interface ParsedTags {
  visible: string[];
  hidden: string[];
}

const MAX_VISIBLE_TAGS = 6;
const SINGLE_SPACE = 8;
const SPACED_HIDDEN_TAGS_BADGE_WIDTH = 30 + SINGLE_SPACE;

function getVisibleTagsCount(
  tagsBadgesWidths: number[],
  tagsWrapperWidth: number,
): number {
  return tagsBadgesWidths.reduce((acc, tagBadgeWidth, index) => {
    acc.totalWidth += tagBadgeWidth;
    let totalWidth = acc.totalWidth + (index * SINGLE_SPACE);

    const tagsCount = tagsBadgesWidths.length;
    const includeHiddenTagsBadge = index + 1 < tagsCount;
    if (includeHiddenTagsBadge) {
      totalWidth += SPACED_HIDDEN_TAGS_BADGE_WIDTH;
    }

    if (totalWidth < tagsWrapperWidth) {
      acc.index = index;
    }
    return acc;
  }, { totalWidth: 0, index: 0 }).index + 1;
}

function useTags(allTags: string[]) {
  const getParsedTags = (visibleTagsCount: number) => ({
    visible: allTags.slice(0, visibleTagsCount),
    hidden: allTags.slice(visibleTagsCount),
  });

  const [tags, setTags] = useMountedState<ParsedTags>(getParsedTags(MAX_VISIBLE_TAGS));
  const setTagsByVisibleTagsCount = (visibleTagsCount: number) => setTags(
    getParsedTags(visibleTagsCount),
  );

  return {
    visibleTags: tags.visible,
    hiddenTags: tags.hidden,
    setTagsByVisibleTagsCount,
  };
}

export default function useDynamicTagsWidth(allTags: string[], shouldKeepSingleRow: boolean): {
  tagsWrapperRef: MutableRefObject<HTMLDivElement>;
  visibleTags: string[];
  hiddenTags: string[];
} {
  const { width, ref: tagsWrapperRef } = useResizeListener();
  const { visibleTags, hiddenTags, setTagsByVisibleTagsCount } = useTags(allTags);
  const tagsBadgesWidths = useRef<number[] | null>(null);

  function populateTagsBadgesWidths() {
    if (tagsBadgesWidths.current === null) {
      const tagsElements: HTMLElement[] | undefined = tagsWrapperRef.current?.children;
      if (
        !shouldKeepSingleRow
        || width === undefined
        || tagsElements === undefined
      ) return;

      tagsBadgesWidths.current = Array.from(tagsElements)
        .map((element) => element.getBoundingClientRect().width);
    }
  }

  function updateTagsByWidth() {
    if (
      !shouldKeepSingleRow
      || !ArrayUtils.isDefined(tagsWrapperRef.current)
      || !ArrayUtils.isDefined(tagsBadgesWidths.current)
    ) return;

    const tagsWrapperWidth = tagsWrapperRef.current.getBoundingClientRect().width;
    const visibleTagsCount = getVisibleTagsCount(tagsBadgesWidths.current, tagsWrapperWidth);

    if (visibleTags.length !== visibleTagsCount) {
      setTagsByVisibleTagsCount(visibleTagsCount);
    }
  }

  useEffect(() => {
    populateTagsBadgesWidths();
    updateTagsByWidth();
  }, [width]);

  return {
    tagsWrapperRef,
    visibleTags,
    hiddenTags,
  };
}
