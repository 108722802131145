import {
  NullableValuesSelectionConstants,
} from 'screens/platform/cross-platform-components/context/MasterFiltersContext/NullableValuesSelection';
import FilterSectionUtils
  from 'screens/platform/cross-platform-components/MasterFiltersPanel/FilterSection/FilterSectionUtils';
import {
  PeopleHierarchicalSearchResultsReducerAction,
  PeopleHierarchicalSearchResultsState,
} from 'screens/platform/cross-platform-components/MasterFiltersPanel/FilterSection/PeopleFilterSection/components/PeopleHierarchicalSearchResults/PeopleHierarchicalSearchResultsReducer';

type ToggleActionPayload = PeopleHierarchicalSearchResultsReducerAction['payload'];

const { toggleMultipleHierarchicalEntities } = FilterSectionUtils;

const { UNDEFINED_PROPERTY } = NullableValuesSelectionConstants;

function getPersonChangeHandler(
  state: PeopleHierarchicalSearchResultsState,
  shouldUpdatePerson = true,
) {
  const { peopleData } = state;
  return (personId: string, isSelected: boolean) => {
    if (shouldUpdatePerson) {
      toggleMultipleHierarchicalEntities(isSelected, [state.peopleSelection.getChild(personId)]);
    }

    const personMetadata = peopleData[personId];
    if (personMetadata) {
      const { department } = personMetadata;
      const teams = personMetadata.getTeams();

      const teamsSelections = teams === null
        ? [state.teamsSelection.getChild([UNDEFINED_PROPERTY, personId])]
        : teams.map(
          (teamId) => state.teamsSelection.getChild([teamId, personId]),
        );
      const personSelection = state.departmentsSelection.getChild([department, personId]);
      toggleMultipleHierarchicalEntities(isSelected, [
        personSelection,
        ...teamsSelections,
      ]);
    }
  };
}

const PeopleHierarchicalSearchResultsReducerActions = {
  handlePersonToggle(
    state: PeopleHierarchicalSearchResultsState,
    { id }: ToggleActionPayload,
  ): PeopleHierarchicalSearchResultsState {
    if (id === null) {
      const isSelected = state.peopleSelection.toggle();
      toggleMultipleHierarchicalEntities(
        isSelected,
        [state.departmentsSelection, state.teamsSelection],
      );
    } else {
      const personSelection = state.peopleSelection.getChild(id);
      if (!personSelection) return state;

      const isSelected = personSelection.toggle();
      const handlePersonUpdate = getPersonChangeHandler(
        state,
        false,
      );
      handlePersonUpdate(id, isSelected);
    }

    return state;
  },

  handleDepartmentToggle(
    state: PeopleHierarchicalSearchResultsState,
    { id }: ToggleActionPayload,
  ): PeopleHierarchicalSearchResultsState {
    if (id === null) {
      const isSelected = state.departmentsSelection.toggle();
      toggleMultipleHierarchicalEntities(
        isSelected,
        [state.peopleSelection, state.teamsSelection],
      );
    } else { // Specific department was toggled
      const departmentSelection = state.departmentsSelection.getChild(id);
      if (!departmentSelection) return state;

      const isSelected = departmentSelection.toggle();
      const departmentPeople = state.departmentsToPeople[id];
      if (!departmentPeople) return state;

      const handlePersonUpdate = getPersonChangeHandler(
        state,
        true,
      );
      departmentPeople.forEach((personId) => {
        handlePersonUpdate(personId, isSelected);
      });
    }

    return state;
  },

  handleTeamToggle(
    state: PeopleHierarchicalSearchResultsState,
    { id }: ToggleActionPayload,
  ): PeopleHierarchicalSearchResultsState {
    if (id === null) {
      const isSelected = state.teamsSelection.toggle();
      toggleMultipleHierarchicalEntities(
        isSelected,
        [state.peopleSelection, state.departmentsSelection],
      );
    } else { // Specific team was toggled
      const teamSelection = state.teamsSelection.getChild(id);
      if (!teamSelection) return state;

      const isSelected = teamSelection.toggle();
      const teamPeople = state.teamsToPeople[id];
      if (!teamPeople) return state;

      const handlePersonUpdate = getPersonChangeHandler(
        state,
        true,
      );
      teamPeople.forEach((personId) => {
        handlePersonUpdate(personId, isSelected);
      });
    }

    return state;
  },
};

export default PeopleHierarchicalSearchResultsReducerActions;
