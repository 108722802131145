import { getRequest, HttpResponse, postRequest } from 'global/api/platformApiHelpers';
import App from 'global/lists/apps';
import { ConnectorState, ConnectorStepLabel } from 'global/lists/ConnectorConfig';
import Tenant from 'model/Tenant';

export enum UploadStatus {
  FILE_NOT_FOUND = 'FILE_NOT_FOUND',
  UNSUPPORTED_MEDIA_TYPE = 'UNSUPPORTED_MEDIA_TYPE',
  FILE_SIZE_TOO_LARGE = 'FILE_SIZE_TOO_LARGE',
  OK = 'OK',
}

export type ConnectorStep = {
  step: ConnectorStepLabel;
  link: string;
};

export type FileMetadata = {
  directory: string;
  name: string;
  updateTime: number; // timestamp
  size: number; // size in bytes
};

export type ConnectorConfig = {
  steps: ConnectorStep[];
  state: ConnectorState | null;
}

export type UploadResponse = {
  status: UploadStatus;
  fileMetadata: FileMetadata | null;
};

const SetupController = {
  async getActiveConnectors(
    tenantId: Tenant['id'],
  ): HttpResponse<Record<App, string>> {
    return getRequest('setup/active-connectors', { tenantId });
  },

  async getConnectorConfig(
    tenantId: Tenant['id'],
    appId: number,
  ): HttpResponse<ConnectorConfig> {
    return getRequest('setup/connector-config', { tenantId, appId });
  },

  async importOrgChart(
    tenantId: Tenant['id'],
    file: File,
  ): HttpResponse<UploadResponse> {
    const formData = new FormData();
    formData.append('file', file);
    const requestConfig = {
      params: { tenantId },
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    return postRequest('setup/orgchart', formData, requestConfig);
  },

  async getUnmappedUsersSharePerApp(tenantId: Tenant['id'], appId: number): HttpResponse<number> {
    return getRequest('setup/unmapped-users', { tenantId, appId });
  },

  async getGmailConnectedEmail(tenantId: Tenant['id']): HttpResponse<string | null> {
    return getRequest('setup/gmail-connected-email', { tenantId });
  },

  async sendPhabricatorCredentials(tenantId: Tenant['id'], baseUrl: string, token: string): HttpResponse<null> {
    return postRequest('setup/phabricator', { baseUrl, token }, { params: { tenantId } });
  },
};

export default SetupController;
