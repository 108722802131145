import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import Spinner from 'common-ui-components/Spinner';

import style from 'screens/platform/cross-platform-components/TextLoader/style.module.scss';

const MESSAGE_ROTATION_INTERVAL = 5000;

const messages = [
  'We\'re analyzing the data! Just a few more moments...',
  'This is going to be worth the wait...',
  'Almost there...',
];

interface Props {
  enterpriseSearch?: boolean;
}

export default function TextLoader({ enterpriseSearch = false }: Props) {
  const [message, setMessage] = useState(messages[0]);

  useEffect(() => {
    const timer = setTimeout(() => setMessage(nextMessage()), MESSAGE_ROTATION_INTERVAL);
    return () => clearTimeout(timer);
  }, [message]);

  function nextMessage() {
    const currentMessageIdx = messages.findIndex((m) => m === message);
    const nextMessageIdx = (currentMessageIdx + 1) % messages.length;
    return messages[nextMessageIdx];
  }

  return (
    <div className={style.textLoader} data-testid="text-loader">
      <div className={classNames(style.message, enterpriseSearch && style.enterpriseSearch)}>
        {message}
      </div>
      {!enterpriseSearch && <Spinner /> }
    </div>
  );
}
