import React, { useEffect } from 'react';

import Directionality from 'screens/platform/cross-platform-components/context/MasterFiltersContext/Directionality';
import CollapsibleGroupItems
  from 'screens/platform/cross-platform-components/HierarchicalSearchInput/components/HierarchicalSearchResults/CollapsibleGroup/CollapsibleGroupItems';
import useCollapsibleGroupSelectionStatus
  from 'screens/platform/cross-platform-components/HierarchicalSearchInput/components/HierarchicalSearchResults/CollapsibleGroup/CollapsibleGroupSelectionStatusHook';
import {
  HierarchicalItemType,
} from 'screens/platform/cross-platform-components/HierarchicalSearchInput/HierarchicalItemTypeConfig';
import HierarchicalGroup from 'utils/HierarchicalDataStructures/HierarchicalGroup';

interface Props {
  selection: HierarchicalGroup;
  isOpen: boolean;
  updateParentSelectionStatus: () => void;
}

export default function DirectionalityCollapsibleItems({
  selection, isOpen, updateParentSelectionStatus,
}: Props) {
  const visibleItemsIds = [Directionality.INTERNAL, Directionality.EXTERNAL];

  const {
    selectionStatus,
    updateSelectionStatus,
  } = useCollapsibleGroupSelectionStatus({
    group: selection,
    postUpdateCallback: updateParentSelectionStatus,
  });

  useEffect(() => {
    const subscriptionId = selection.subscribe((_, __) => {
      updateSelectionStatus();
    });
    return () => selection.unsubscribe(subscriptionId);
  }, [selection]);

  useEffect(() => {
    if (selection.isSelected !== selectionStatus.isSelected) updateSelectionStatus();
  }, [selection, selectionStatus]);

  return (
    <CollapsibleGroupItems
      isOpen={isOpen}
      group={selection}
      type={HierarchicalItemType.DIRECTIONALITY}
      visibleItemsIds={visibleItemsIds}
    />
  );
}
