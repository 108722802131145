import React, { FC } from 'react';
import { useParams } from 'react-router-dom';

import App from 'global/lists/apps';
import CONNECTORS from 'screens/settings/AppsScreen/connectorsConfig';

import checkCircle from 'assets/img/setup/connectorSuccess/check-circle-big.svg';

import style from 'screens/public/ConnectorSuccessPage/style.module.scss';

type UrlParams = {
  appId: string;
};

const appGroupNameByAppId = {
  [App.ATLASSIAN]: 'Atlassian',
  [App.GOOGLE]: 'Google',
};

const ConnectorSuccessPage: FC = () => {
  const { appId } = useParams<UrlParams>();

  const connector = CONNECTORS.find((c) => c.appId?.toString() === appId);
  const appName = appGroupNameByAppId[appId] || connector?.name || null;
  const title = appName ? `${appName} connected successfully!` : 'Connected successfully!';

  return (
    <div className={style.connectorSuccessPage}>
      <img id={style.logo} src={checkCircle} alt="success" />
      <h1>{title}</h1>
      <h2>
        You can now close this tab and continue with the setup.
      </h2>
    </div>
  );
};

export default ConnectorSuccessPage;
