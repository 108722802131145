import React, { useMemo } from 'react';

import MultiPersonsImages from 'common-ui-components/MultiImages/MultiPersonsImages';
import Size from 'global/lists/Size';
import { Atom } from 'model/Atom';
import usePersonsMetadata from 'screens/platform/cross-platform-components/context/metadata/hooks/personsMetadataHook';
import InteractionUnknownExternalPersonImage from 'screens/platform/cross-platform-components/Interactions/components/InteractionsTable/components/InteractionUnknownExternalPersonImage';

export enum PersonType {
  INTERNAL,
  EXTERNAL,
}

type Props = {
  type: PersonType;
  atom: Atom;
};

export default function InteractionPersonsImages({
  type,
  atom: { participants, participatingExternalOrganizations },
}: Props) {
  const { value: persons } = usePersonsMetadata(
    useMemo(() => Object.keys(participants), [participants]),
  );
  const filteredIds = useMemo(
    () =>
      Object.entries(participants)
        .filter(([personId, _]) => {
          const person = persons?.[personId];
          return person !== undefined && type === PersonType.EXTERNAL === person.isExternal;
        })
        .sort(([_A, percentageA], [_B, percentageB]) => percentageB - percentageA)
        .map(([personId, _]) => personId),
    [persons],
  );

  if (type === PersonType.EXTERNAL
    && filteredIds.length === 0
    && participatingExternalOrganizations.length === 1) {
    return (
      <InteractionUnknownExternalPersonImage
        organizationId={participatingExternalOrganizations[0]}
      />
    );
  }
  return <MultiPersonsImages ids={filteredIds} size={Size.BIG} />;
}
