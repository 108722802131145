import React, { FC } from 'react';

import OptionsPicker from 'common-ui-components/OptionsPicker';
import Scroller from 'common-ui-components/Scroller';
import PopoverSection from 'common-ui-components/SettingsPopover/PopoverSection';
import { useGraphFeatures } from 'screens/platform/cross-platform-components/context/graphFeatures/GraphFeaturesContext';
import { ClusterConnections } from 'screens/platform/cross-platform-components/context/graphFeatures/GraphFeaturesReducer';

const ClusterConnectionsOptions = [
  { value: ClusterConnections.INSIDE, label: 'Inside' },
  { value: ClusterConnections.BETWEEN, label: 'Between' },
  { value: ClusterConnections.BOTH, label: 'Both' },
];

const CommChartFilters: FC = () => {
  const {
    volumeThreshold,
    significanceThreshold,
    clusterConnections,
    showDetachedNodes,
    dispatchGraphFeatures,
  } = useGraphFeatures();

  const setClusterConnections = (
    nextClusterConnections: ClusterConnections,
  ) => {
    dispatchGraphFeatures({
      type: 'SET_CLUSTER_CONNECTIONS',
      payload: { nextClusterConnections },
    });
  };

  return (
    <>
      <PopoverSection title="Work Volume">
        <Scroller
          initialValue={volumeThreshold}
          leftLabel="Low"
          rightLabel="High"
          fireOnChangeOnlyOnMouseUp={!showDetachedNodes}
          onChange={(newVolumeThreshold) =>
            dispatchGraphFeatures({
              type: 'SET_VOLUME_THRESHOLD',
              payload: { newVolumeThreshold },
            })}
        />
      </PopoverSection>
      <PopoverSection title="Potential Significance">
        <Scroller
          initialValue={significanceThreshold}
          leftLabel="Low"
          rightLabel="High"
          fireOnChangeOnlyOnMouseUp={!showDetachedNodes}
          onChange={(newSignificanceThreshold) =>
            dispatchGraphFeatures({
              type: 'SET_SIGNIFICANCE_THRESHOLD',
              payload: { newSignificanceThreshold },
            })}
          colored
          ticksAmount={5}
        />
      </PopoverSection>
      <PopoverSection title="Cluster Connections">
        <OptionsPicker
          options={ClusterConnectionsOptions}
          setValue={setClusterConnections}
          selectedValue={clusterConnections}
        />
      </PopoverSection>
    </>
  );
};

export default CommChartFilters;
