import UnionBasedMasterFilter
  from 'screens/platform/cross-platform-components/context/MasterFiltersContext/UnionBasedMasterFilter';

const UNDEFINED_PROPERTY = 'UNDEFINED_PROPERTY' as const; // Used as the key for items with values that aren't defined
export const NullableValuesSelectionConstants = {
  UNDEFINED_PROPERTY,
  UNDEFINED_PROPERTY_LABEL: 'Undefined',
};

export default class NullableValuesSelection<T = string>
  extends UnionBasedMasterFilter<NullableValuesSelection<T>> {
  readonly selected: T[] | null;
  readonly includeUndefined: boolean;

  constructor(selected: T[] | null, includeUndefined: boolean) {
    super();
    this.selected = selected;
    this.includeUndefined = includeUndefined;
  }

  static getAllSelected<T>(): NullableValuesSelection<T> {
    return new NullableValuesSelection(null, true);
  }

  static getEmpty<T>(): NullableValuesSelection<T> {
    return new NullableValuesSelection([], false);
  }

  clone(): NullableValuesSelection<T> {
    return new NullableValuesSelection(this.selected, this.includeUndefined);
  }

  equalsIgnoreFlags(other: any): boolean {
    if (super.isSameType(other)) {
      return UnionBasedMasterFilter.areArraysNullOrEquals(this.selected, other.selected);
    }
    return false;
  }

  equals(other: any): boolean {
    return this.equalsIgnoreFlags(other)
      && this.includeUndefined === other.includeUndefined;
  }

  isAllSelected(): boolean {
    return this.selected === null && this.includeUndefined;
  }

  isEmpty(): boolean {
    return this.selected?.length === 0 && !this.includeUndefined;
  }

  isItemSelected(itemId: string): boolean {
    return this.isAllSelected()
      || this.selected?.includes(itemId as unknown as T)
      || (this.includeUndefined && itemId === UNDEFINED_PROPERTY);
  }
}
