import { HttpResponse, postRequest } from 'global/api/platformApiHelpers';
import App from 'global/lists/apps';
import OdsDataType from 'screens/backoffice/screens/ods/OdsDataType';

function getAllDataTypes(config: Record<App, OdsDataType[]>): OdsDataType[] {
  return [...new Set(Object.values(config).flat())];
}

type OdsRequest = () => HttpResponse<void>;

function getOdsRequest(
  dataType: OdsDataType,
  totalTimeToScanInSeconds: number | null,
  taskContext?: { appId: string; tenantId: string | null },
): OdsRequest {
  return () => postRequest('ods', {
    dataType,
    taskContext: taskContext || null,
    totalTimeToScanInSeconds,
    dataSource: 'CLIENT_API',
  });
}

export default {
  getOdsTasksForAllDataTypes(
    config: Record<App, OdsDataType[]>,
    totalTimeToScanInSeconds: number | null,
  ): OdsRequest[] {
    return getAllDataTypes(config).map((dataType) =>
      getOdsRequest(dataType, totalTimeToScanInSeconds));
  },

  getOdsTasks(
    tenantId: string | null,
    config: Record<App, OdsDataType[]>,
    totalTimeToScanInSeconds: number | null,
  ): OdsRequest[] {
    return Object.entries(config).flatMap(([appId, dataTypes]) =>
      dataTypes.map((dataType) =>
        getOdsRequest(dataType, totalTimeToScanInSeconds, { appId, tenantId })));
  },
};
