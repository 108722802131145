import React from 'react';

import Spinner from 'common-ui-components/Spinner';
import { AtomsCount } from 'global/api/controller/AtomController';
import SingularPluralNouns from 'global/lists/SingularPluralNouns';
import Size from 'global/lists/Size';
import {
  useCustomAtomsFilters,
} from 'screens/platform/cross-platform-components/context/CustomFilters/CustomAtomsFilters/CustomAtomsFiltersContextProvider';
import { usePlatformContext } from 'screens/platform/cross-platform-components/context/platform/PlatformContext';
import useInteractionsCount
  from 'screens/platform/cross-platform-components/Interactions/components/InteractionsTitle/InteractionsCount/InteractionsCountHook';

import style
  from 'screens/platform/cross-platform-components/Interactions/components/InteractionsTitle/InteractionsCount/style.module.scss';

export function getCountLabel(count: number, maxAtomsThreshold: number): string {
  return (count >= maxAtomsThreshold ? `>${maxAtomsThreshold}` : count.toString());
}

const RESULT_LABELS = SingularPluralNouns.result.toLowerCase();
const getResultLabel = (count: number): string => (
  count === 1
    ? RESULT_LABELS.singular
    : RESULT_LABELS.plural
);

function getTotalAtomsCountLabel(count: number, maxAtomsThreshold: number): string {
  return `${getCountLabel(count, maxAtomsThreshold)} ${getResultLabel(count)}`;
}

function getNotAllowedAtomsLabel(restrictedAtoms: AtomsCount['restrictedAtoms'], maxAtomsThreshold: number): string | null {
  const { exists, count } = restrictedAtoms;
  if (!exists) {
    return null;
  }
  if (count === null) {
    return ' (not including private interactions)';
  }
  return ` (including ${getCountLabel(count, maxAtomsThreshold)} private interactions)`;
}

export default function InteractionsCountLabel() {
  const {
    interactionsTable: { maxAtomsThreshold, limit },
  } = usePlatformContext();
  const { filteredAtomsCount } = useCustomAtomsFilters();

  const {
    restrictedAtomsCount,
    totalAtomsCount,
  } = useInteractionsCount();

  const loading = filteredAtomsCount === null
    || maxAtomsThreshold === undefined
    || totalAtomsCount === undefined
    || restrictedAtomsCount === undefined;
  if (loading) {
    return (
      <span className={style.spinner}>
        <Spinner size={Size.SMALL} />
      </span>
    );
  }

  const limitedTotalAtomsCount = Math.min(
    maxAtomsThreshold,
    limit ?? totalAtomsCount, // If limit is undefined - we can ignore it
    totalAtomsCount,
  );

  const { allowedAtomsCount, restrictedAtoms } = filteredAtomsCount;
  if (allowedAtomsCount === 0 && restrictedAtoms.exists && restrictedAtomsCount > 0) {
    return (
      <>
        {`${getCountLabel(limitedTotalAtomsCount, maxAtomsThreshold)} private ${getResultLabel(limitedTotalAtomsCount)}`}
      </>
    );
  }

  return (
    <>
      {getTotalAtomsCountLabel(limitedTotalAtomsCount, maxAtomsThreshold)}
      {limitedTotalAtomsCount < totalAtomsCount ? '' : getNotAllowedAtomsLabel(restrictedAtoms, maxAtomsThreshold)}
    </>
  );
}
