import { initialState, UserState } from 'screens/platform/cross-platform-components/context/user/UserContext';

export type Action =
| { type: 'UPDATE_USER'; payload: Partial<UserState> }
| { type: 'LOGOUT' }
| { type: 'SET_FIREBASE_ID'; payload: { firebaseId: string } };

function updateUser(state, payload) {
  const newState = { ...state };

  const optionalUpdatedFields = Object.keys(initialState);
  optionalUpdatedFields.forEach((fieldKey) => {
    const updatedFieldValue = payload[fieldKey];
    if (updatedFieldValue) {
      newState[fieldKey] = updatedFieldValue;
    }
  });

  return newState;
}

const reducer = (state: UserState, action: Action) => {
  switch (action.type) {
    case 'UPDATE_USER': return updateUser(state, action.payload);

    case 'LOGOUT':
      return {
        ...state,
        isLoggedIn: false,
      };

    case 'SET_FIREBASE_ID':
      return {
        ...state,
        firebaseId: action.payload.firebaseId,
      };

    default:
      return state;
  }
};

export default reducer;
