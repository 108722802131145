import classNames from 'classnames';
import React from 'react';

import {
  useCustomAtomsFilters,
} from 'screens/platform/cross-platform-components/context/CustomFilters/CustomAtomsFilters/CustomAtomsFiltersContextProvider';
import InteractionsCountContent
  from 'screens/platform/cross-platform-components/Interactions/components/InteractionsTitle/InteractionsCount/InteractionsCountContent';

import style
  from 'screens/platform/cross-platform-components/Interactions/components/InteractionsTitle/InteractionsCount/style.module.scss';

export default function InteractionsCount({ visible }: { visible: boolean }) {
  const { filteredAtomsCount } = useCustomAtomsFilters();

  return (
    <span
      className={classNames(
        style.interactionsCount,
        visible && style.visible,
      )}
    >
      <InteractionsCountContent
        allowedAtomsCount={filteredAtomsCount?.allowedAtomsCount}
      />
    </span>
  );
}
