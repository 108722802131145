import { Colord, colord, extend } from 'colord';
import { useMemo } from 'react';

import mixPlugin from 'colord/plugins/mix';
import colors from 'global/colors';
import ColorUtils from 'utils/ColorUtils';

extend([mixPlugin]);

/**
 * @property {string} color           - A tag badge's text color, calculated by the category color.
 * @property {string} backgroundColor - A tag badge's background color.
 *                                      calculated by the category color.
 * @property {string} uniqueColor     - A unique shade of the category's color which is calculated
 *                                      deterministically and is assigned to the tag.
 */
export interface TagColors {
  color: string;
  backgroundColor: string;
  uniqueColor: string;
}

export const defaultTagColors: TagColors = {
  color: colors.DARK_GRAY,
  backgroundColor: colors.GRAY_4,
  uniqueColor: colors.DARK_GRAY,
};

const MAX_UNIQUE_COLORS = 7;
const isLight = (color: Colord) => color.brightness() > 0.8;
const isDark = (color: Colord) => color.brightness() < 0.5;

function getTagUniqueColor(
  categoryColor: Colord,
  tagIndex: number,
  tagsAmount: number,
): string {
  if (tagsAmount === 1) return categoryColor.toHex();

  const brightness = categoryColor.brightness();
  const uniqueColors = Math.min(tagsAmount, MAX_UNIQUE_COLORS);
  const changeFactor = Math.abs(brightness - 0.5) + 0.4;
  const step = ((tagIndex % uniqueColors) / uniqueColors) * changeFactor;

  return ColorUtils.getColorShade(categoryColor.toHex(), step);
}

function getTagsColorsFromCategoryColor(
  categoryColor: string,
  tags: string[],
): Record<string, TagColors> {
  const categoryColord = colord(categoryColor);
  let backgroundLightenFactor = 0.25;

  if (isDark(categoryColord)) {
    backgroundLightenFactor = 0.35;
  } else if (isLight(categoryColord)) {
    backgroundLightenFactor = 0.005;
  }

  const backgroundDarkenFactor = isLight(categoryColord) ? 0.35 : 0.1;
  return tags.reduce((acc, tag, index) => {
    acc[tag] = {
      color: categoryColord.darken(backgroundDarkenFactor).toHex(),
      backgroundColor: categoryColord.lighten(backgroundLightenFactor).toHex(),
      uniqueColor: getTagUniqueColor(categoryColord, index, tags.length),
    };
    return acc;
  }, {});
}

export default function useTagsColorsByCategory(
  categories: Record<string, string[]>,
  categoriesColors: Record<string, string>,
): Record<string, Record<string, TagColors>> {
  return useMemo(() => {
    const entries = Object.entries(categories)
      .filter(([category]) => Boolean(categoriesColors[category]))
      .map(([category, tags]) => {
        const color = categoriesColors[category];
        const categoryTagColors = getTagsColorsFromCategoryColor(color, tags);
        return [category, categoryTagColors];
      });

    return Object.fromEntries(entries);
  }, [categories, categoriesColors]);
}
