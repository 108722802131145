import { useCallback, useMemo } from 'react';

import App from 'global/lists/apps';
import HierarchicalMasterFilter
  from 'screens/platform/cross-platform-components/context/MasterFiltersContext/HierarchicalMasterFilter';
import {
  useMasterFilters,
} from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFiltersContext';
import {
  MetadataContextType,
  useMetadataContext,
} from 'screens/platform/cross-platform-components/context/metadata/MetadataContext';
import {
  isGithubSubDivision,
} from 'screens/platform/cross-platform-components/MasterFiltersPanel/masterFiltersUtils/GithubChannelsUtils';
import {
  isSlackSubDivision,
} from 'screens/platform/cross-platform-components/MasterFiltersPanel/masterFiltersUtils/SlackChannelsUtils';
import ArrayUtils from 'utils/ArrayUtils';
import HierarchicalGroup from 'utils/HierarchicalDataStructures/HierarchicalGroup';

export const UNIDENTIFIED_APPS_LABEL = 'Unidentified Apps';

export function isAppIdentified(appId: string): boolean {
  return Object.values(App).includes(Number(appId));
}

interface AppsClassification {
  identifiedAppsIds: string[];
  unidentifiedAppsIds: string[];
}

export function useIdentifiedAppsClassification(): AppsClassification {
  const { currentFilters } = useMasterFilters();
  const { isAppConnected } = useMetadataContext();

  return useMemo(() => currentFilters.apps.getChildrenEntries()
    .reduce<AppsClassification>((acc, [appId]) => {
      const isIdentified = isAppConnected(Number(appId))
          || isSlackSubDivision(appId)
          || isGithubSubDivision(appId)
          || isAppIdentified(appId);
      if (isIdentified) {
        acc.identifiedAppsIds.push(appId);
      } else {
        acc.unidentifiedAppsIds.push(appId);
      }
      return acc;
    }, { identifiedAppsIds: [], unidentifiedAppsIds: [] }), [currentFilters.apps]);
}

export function useAppsFilterSelection(
  appsClassification: AppsClassification,
): HierarchicalMasterFilter {
  const { currentFilters } = useMasterFilters();
  const { identifiedAppsIds, unidentifiedAppsIds } = appsClassification;

  return useMemo(() => {
    const visibleAppsGroups = identifiedAppsIds
      .map((groupId) => currentFilters.apps.getChild(groupId))
      .filter(ArrayUtils.isDefined);

    const hasUnidentifiedApps = visibleAppsGroups.length < currentFilters.apps.getChildren().length;
    if (hasUnidentifiedApps) {
      const isSelected = unidentifiedAppsIds.every((groupId) => {
        const group = currentFilters.apps.getChild(groupId);
        return group!.isSelected;
      });
      const unidentifiedAppsGroup = new HierarchicalGroup(
        UNIDENTIFIED_APPS_LABEL,
        [],
        isSelected,
      );
      visibleAppsGroups.push(unidentifiedAppsGroup);
    }

    return new HierarchicalMasterFilter(
      currentFilters.apps.id,
      visibleAppsGroups,
      currentFilters.apps.isSelected,
    );
  }, [currentFilters.apps, identifiedAppsIds, unidentifiedAppsIds]);
}

function getChannels(
  appsToChannelsMap: MetadataContextType['channels']['appsToChannelsMap'],
  appGroupId: string,
) {
  const channels = appsToChannelsMap[appGroupId];
  if (channels === undefined) return [appGroupId];
  return channels ?? [appGroupId];
}

export function useAppsFilterConversionToMasterFilter(
  appsClassification: AppsClassification,
): (appsFilterToConvert: HierarchicalMasterFilter) => HierarchicalMasterFilter {
  const { currentFilters: { apps } } = useMasterFilters();
  const { channels: { appsToChannelsMap } } = useMetadataContext();
  const { unidentifiedAppsIds } = appsClassification;

  return useCallback((appsFilterToConvert: HierarchicalMasterFilter) => {
    const children = appsFilterToConvert.getChildrenEntries()
      .filter(([groupId]) => groupId !== UNIDENTIFIED_APPS_LABEL)
      .map(([, group]) => group);

    if (unidentifiedAppsIds.length > 0) {
      const shouldSelectUnidentifiedApps = appsFilterToConvert
        .getChild(UNIDENTIFIED_APPS_LABEL)?.isSelected;
      const unidentifiedAppsGroups = unidentifiedAppsIds.map((appGroupId) => new HierarchicalGroup(
        appGroupId,
        getChannels(appsToChannelsMap, appGroupId),
        shouldSelectUnidentifiedApps ?? false,
      ));
      unidentifiedAppsGroups.forEach((appGroup) => children.push(appGroup));
    }

    return new HierarchicalMasterFilter(apps.id, children, appsFilterToConvert.isSelected);
  }, [apps, unidentifiedAppsIds]);
}
