import classNames from 'classnames';
import React, { useRef, useState } from 'react';

import Button from 'common-ui-components/Button';
import SearchInput from 'common-ui-components/SearchInput';
import { ColumnConfiguratorHierarchicalItemType } from 'screens/platform/cross-platform-components/ColumnsConfigurator';
import useColumnsConfiguratorItemsByGroups, {
  getColumnsSelection,
} from 'screens/platform/cross-platform-components/ColumnsConfigurator/columnsConfiguratorSelectionItemsHook';
import HierarchicalSearchResults from 'screens/platform/cross-platform-components/HierarchicalSearchInput/components/HierarchicalSearchResults';
import { ColumnsOrder } from 'screens/platform/directory/components/GenericDirectoryScreen/context/DirectoryContext';

import style from 'screens/platform/cross-platform-components/ColumnsConfigurator/style.module.scss';
import hierarchicalSearchInputStyles from 'screens/platform/cross-platform-components/HierarchicalSearchInput/style.module.scss';

type Props<T extends ColumnsOrder> = {
  allColumnsByGroups: Partial<Record<string, string[]>>;
  selectedColumns: string[];
  type: ColumnConfiguratorHierarchicalItemType;
  onNextColumnsConfig: (nextColumns: T) => void;
  closePopover: () => void;
};

export default function ColumnsConfiguratorPopoverContent<T extends ColumnsOrder>({
  allColumnsByGroups,
  selectedColumns,
  type,
  onNextColumnsConfig,
  closePopover,
}: Props<T>) {
  const [searchQuery, setSearchQuery] = useState('');
  const [isSelectionEmpty, setSelectionEmpty] = useState(false);

  const selection = useRef(
    getColumnsSelection(allColumnsByGroups, selectedColumns),
  );
  const itemsByGroups = useColumnsConfiguratorItemsByGroups(
    allColumnsByGroups,
    searchQuery,
    type,
  );

  const onClickDone = () => {
    const selectionMap = selection.current.getSelectionMap({ alwaysReturnMap: true });
    const nextColumns = Object.values(selectionMap!)
      .flat()
      .reduce(
        (acc, column, index) => ({
          ...acc,
          [column]: index,
        }),
        {},
      ) as T;
    onNextColumnsConfig(nextColumns);
    closePopover();
  };

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className={
        hierarchicalSearchInputStyles.searchableHierarchicalResultsContainer
      }
    >
      <h4 className={style.title}>Columns</h4>
      <div className={hierarchicalSearchInputStyles.searchInputWrapper}>
        <SearchInput
          setValue={setSearchQuery}
          placeholder="Search for attributes"
        />
      </div>
      <div
        className={classNames(
          hierarchicalSearchInputStyles.content,
          hierarchicalSearchInputStyles.scrollable,
        )}
      >
        <HierarchicalSearchResults
          items={itemsByGroups}
          selection={selection.current}
          handleEmptySelectionChange={setSelectionEmpty}
          type={type}
          showChildrenCount={false}
          initiallyOpen
        />
      </div>
      <div className={hierarchicalSearchInputStyles.bottomMenu}>
        <Button
          color="blue"
          className={hierarchicalSearchInputStyles.done}
          onClick={onClickDone}
          disabled={isSelectionEmpty}
        >
          Done
        </Button>
      </div>
    </div>
  );
}
