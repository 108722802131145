import React, { useMemo } from 'react';

import MultiPersonsImages from 'common-ui-components/MultiImages/MultiPersonsImages';
import useAsync from 'react-use/lib/useAsync';
import ItemListing from 'screens/platform/cross-platform-components/HierarchicalSearchInput/components/ItemListing';
import CheckboxTooltip
  from 'screens/platform/cross-platform-components/HierarchicalSearchInput/components/ItemListing/CheckboxTooltip';
import useItemData
  from 'screens/platform/cross-platform-components/HierarchicalSearchInput/components/ItemListing/ItemDataHook';
import {
  HierarchicalItemType,
  useHierarchicalItemTypeImplementations,
} from 'screens/platform/cross-platform-components/HierarchicalSearchInput/HierarchicalItemTypeConfig';
import HierarchicalInterface from 'utils/HierarchicalDataStructures/HierarchicalInterface';

interface ItemListingProps {
  item: HierarchicalInterface;
  type: HierarchicalItemType;
  onToggle?: (id: string) => void;
}

const PRIVATE_CHANNEL_ERROR = 'Private interactions in which this user is a collaborator';

export default function TypedItemListing({
  item,
  type,
  onToggle,
}: ItemListingProps) {
  const { getItemLabel } = useHierarchicalItemTypeImplementations(type);

  const {
    isActivePerson, isPrivateChannel, isBotChannel,
  } = useItemData(item.id, type);

  const itemId = item.id;
  const { value: label } = useAsync(async () => getItemLabel(itemId), [itemId]);

  const isDisabled = isBotChannel;
  const isInactive = !isActivePerson || isBotChannel;

  const tooltipContent = useMemo<string | undefined>(() => {
    if (isPrivateChannel) return PRIVATE_CHANNEL_ERROR;
    if (isBotChannel) return PRIVATE_CHANNEL_ERROR;

    return undefined;
  }, [isPrivateChannel, isBotChannel]);

  const checkboxTooltipWrapper = useMemo(() => (tooltipContent ? (tooltipWrapperProps) => (
    <CheckboxTooltip content={tooltipContent}>
      {tooltipWrapperProps.children}
    </CheckboxTooltip>
  ) : undefined), [tooltipContent]);

  const itemImage = useMemo(
    () => (
      type === HierarchicalItemType.PERSON ? <MultiPersonsImages ids={[itemId]} /> : undefined),
    [type, itemId],
  );

  return (
    <ItemListing
      item={item}
      checkboxWrapper={checkboxTooltipWrapper}
      disabled={isDisabled}
      inactive={isInactive}
      onToggle={onToggle}
      labelImage={itemImage}
      label={label || (type === HierarchicalItemType.ORGANIZATION ? item.id : '')}
    />
  );
}
