import React from 'react';

import AppIcon from 'common-ui-components/AppIcon';
import SourceBadge from 'common-ui-components/Sources/SourceBadge/index';
import Analytics, { AnalyticsEvent } from 'global/Analytics';
import { AtomSource } from 'global/api/controller/EnterpriseSearchController';
import getAppDisplayName from 'global/lists/appsNames';
import Size from 'global/lists/Size';
import InteractionDetails from 'screens/platform/cross-platform-components/InteractionDetails';

interface Props {
  source: AtomSource;
}

export default function AtomBadge({ source }: Props) {
  const { data: atom, type } = source;

  const {
    appId, permalink, isPublic, mimeType,
  } = atom;

  const analyticsPayload = {
    sourceType: type,
    sourceId: atom.id,
    dataSource: getAppDisplayName(appId),
  };

  function handlePopoverHover() {
    Analytics.trackEvent(
      AnalyticsEvent.ES_SOURCE_TOOLTIP_USED,
      analyticsPayload,
    );
  }

  function handleSourceBadgeClick() {
    Analytics.trackEvent(
      AnalyticsEvent.ES_SOURCE_BADGE_CLICK,
      analyticsPayload,
    );
  }

  return (
    <SourceBadge
      type={type}
      redirect={permalink}
      image={(
        <AppIcon
          appId={appId}
          isPublic={isPublic}
          hasBorder={false}
          size={Size.SMALL}
          mimeType={mimeType}
        />
        )}
      title={atom.title}
      popoverContent={<InteractionDetails atom={atom} />}
      handlePopoverHover={handlePopoverHover}
      handleSourceClick={handleSourceBadgeClick}
    />
  );
}
