export enum GithubSubDivisionId {
  GITHUB_PRIVATE_REPOSITORIES = '130-1',
  GITHUB_PUBLIC_REPOSITORIES = '130-2'
}

export const GithubSubDivisions = {
  [GithubSubDivisionId.GITHUB_PRIVATE_REPOSITORIES]: 'Github Private',
  [GithubSubDivisionId.GITHUB_PUBLIC_REPOSITORIES]: 'Github Public',
};

export function isGithubSubDivision(appId: string): boolean {
  return Object.values<string>(GithubSubDivisionId).includes(appId);
}
