import {
  HttpResponse, postRequest,
} from 'global/api/platformApiHelpers';
import { GraphEdge } from 'model/GraphEdge';
import { GraphNode } from 'model/GraphNode';
import Tenant from 'model/Tenant';
import { MasterFilters } from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFilters';
import convertMasterFilters from 'screens/platform/cross-platform-components/context/MasterFiltersContext/utils/MasterFiltersApiConversionUtils';

type GraphNodesAndEdges = {
  nodes: Record<string, GraphNode>;
  edges: GraphEdge[];
}

export type GraphDataResponse = {
  clustersToNodes: Record<string, string[]>;
  personsGraph: GraphNodesAndEdges;
  clustersGraph: GraphNodesAndEdges;
}

const GraphDataController = {
  async getGraphDataForCommChart(
    tenantId: Tenant['id'],
    currentFilters: MasterFilters,
    clusteringType: string,
    shouldGroupExternalUsersByOrgs: boolean,
    includeCommunicationOutsideFilteredGroup = false,
  ): HttpResponse<GraphDataResponse> {
    return postRequest(
      'graphData',
      {
        masterFilter: convertMasterFilters(
          currentFilters,
          includeCommunicationOutsideFilteredGroup,
        ),
        clusteringType,
        shouldGroupExternalUsersByOrgs,
      },
      { params: { tenantId } },
    );
  },
};

export default GraphDataController;
