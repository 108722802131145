import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import addDays from 'date-fns/addDays';
import subDays from 'date-fns/subDays';
import { AppScreensConfig } from 'global/lists/AppScreen';
import TimelineEvent from 'model/Events/Event';
import { CustomEventsFiltersState } from 'screens/platform/cross-platform-components/context/CustomFilters/CustomEventsFilters/CustomEventsFiltersReducer';
import {
  useDefaultMasterFilters,
} from 'screens/platform/cross-platform-components/context/MasterFiltersContext/utils/DefaultMasterFiltersUtils';
import useTenantContext from 'screens/platform/cross-platform-components/context/tenant/TenantContext';
import {
  flattenMasterFiltersToParams, generateUrlParams,
} from 'screens/platform/cross-platform-components/context/UrlParams/convertMasterFiltersToUrlParams';
import { ActionText, InternalLink } from 'screens/platform/cross-platform-components/EventsPanel/EventCell/EventActionText/actionText';
import { buildCustomFilters } from 'screens/platform/cross-platform-components/EventsPanel/EventCell/EventActionText/EventActionTextUtils';
import useEventsCustomMasterFilters
  from 'screens/platform/cross-platform-components/EventsPanel/EventsCustomMasterFiltersHook';
import DebuggerConsole from 'utils/DebuggerConsole';

import style from 'screens/platform/cross-platform-components/EventsPanel/EventCell/EventActionText/EventActionLink/style.module.scss';

interface Props {
    event: TimelineEvent;
    link: ActionText['link'];
    linkText: string;
}

export default function EventActionLink({ link, linkText, event }: Props) {
  const { tenant } = useTenantContext();
  const defaultMasterFilters = useDefaultMasterFilters();
  const buildCustomMasterFilters = useEventsCustomMasterFilters(defaultMasterFilters);

  function generateUrlParamsByFilters(customFilters: CustomEventsFiltersState): string {
    const masterFilter = buildCustomMasterFilters(customFilters);
    const flatMasterFilters = flattenMasterFiltersToParams(masterFilter, tenant.departments);
    const flatDefaultMasterFilters = flattenMasterFiltersToParams(
      defaultMasterFilters,
      tenant.departments,
    );
    return generateUrlParams(
      flatMasterFilters,
      flatDefaultMasterFilters,
      defaultMasterFilters.datesRange,
    );
  }

  function buildEventsCustomFilters(
    filters: InternalLink['filters'],
    expandDatesRange: InternalLink['expandDatesRange'],
  ): CustomEventsFiltersState {
    const customFilters = buildCustomFilters(event, filters);
    const { datesRange } = customFilters;
    if (datesRange && expandDatesRange) {
      const { daysToAddAfter, daysToAddBefore } = expandDatesRange;
      return {
        ...customFilters,
        datesRange: {
          from: subDays(datesRange.from, daysToAddBefore),
          to: addDays(datesRange.to, daysToAddAfter),
        },
      };
    }
    return customFilters;
  }

  const toLink = useMemo(() => {
    if (link.isExternal) {
      return { pathname: link.url, search: '' };
    }

    const {
      appScreen, filters, expandDatesRange,
    } = link;
    const config = AppScreensConfig[appScreen];
    if (!config) {
      DebuggerConsole.error(`Error finding app screen config ${appScreen}`);
      return null;
    }
    const customFilters = buildEventsCustomFilters(filters, expandDatesRange);
    const urlParamsFromFilters = generateUrlParamsByFilters(customFilters);
    return {
      pathname: config.url,
      search: urlParamsFromFilters,
    };
  }, [link]);

  return toLink ? (
    <span className={style.link}>
      <Link to={{ ...toLink }} target="_blank">
        { linkText }
      </Link>
    </span>
  ) : <div>{linkText}</div>;
}
