import ArrayUtils from 'utils/ArrayUtils';
import CachedLoadingQueue, { Task } from 'utils/CachedLoadingQueue';
import DebuggerConsole from 'utils/DebuggerConsole';

type MetadataItemsCacheConfig<T> = {
  fetchItemsByIds: (
    ids: string[],
  ) => Promise<Partial<Record<string, T>> | undefined>;
  fetchDebounceTimeInMillis?: number;
};

const DEFAULT_DEBOUNCE_TIME_IN_MILLIS = 200;

export default class MetadataItemsCache<ItemType> extends CachedLoadingQueue<ItemType> {
  private readonly fetchItemsByIds: (
    ids: string[], abortSignal?: AbortSignal
  ) => Promise<Partial<Record<string, ItemType>> | undefined>;
  private timeoutId: ReturnType<typeof setTimeout> | undefined;
  private readonly fetchDebounceTimeInMillis: number;
  private abortController: AbortController;

  constructor({
    fetchItemsByIds,
    fetchDebounceTimeInMillis = DEFAULT_DEBOUNCE_TIME_IN_MILLIS,
  }: MetadataItemsCacheConfig<ItemType>) {
    super({
      asyncLoadingFunction: async () => undefined,
    });
    this.fetchItemsByIds = fetchItemsByIds;
    this.fetchDebounceTimeInMillis = fetchDebounceTimeInMillis;
    this.abortController = new AbortController();
  }

  override get(key: string): Promise<ItemType | undefined> {
    return super.get(key, key);
  }

  async abort() {
    this.abortController.abort();
    super.abort();
    this.abortController = new AbortController();
  }

  updateItemCache(key: string, nextItem: ItemType | undefined) {
    this.updateCache(key, nextItem);
  }

  getAllCachedItems(): ItemType[] {
    return Object.values(this.cache).filter(ArrayUtils.isDefined);
  }

  protected override wakeUp(): void {
    if (!this.timeoutId) {
      this.timeoutId = setTimeout(
        () => this.consumeAllTasks(),
        this.fetchDebounceTimeInMillis,
      );
    }
  }

  private async consumeAllTasks() {
    let nextTasks: Task<ItemType>[] | undefined;
    await this.currentlyHandledTasks.performWhileLocked(() => {
      nextTasks = this.queue;
      this.queue = [];
      this.timeoutId = undefined;
      if (nextTasks.length > 0) {
        nextTasks.forEach(({ key }) => {
          this.currentlyHandledTasks.initKey(key);
        });
      }
    });

    if (!nextTasks || nextTasks.length === 0) return;
    const existingItems = Object.fromEntries(
      nextTasks.map(({ key }) => [key, this.getCached(key)]),
    );
    const keysToFetch = Object.entries(existingItems)
      .filter(([_, resource]) => !resource)
      .map(([key]) => key);

    let fetchedItems: Partial<Record<string, ItemType>> | undefined;
    if (keysToFetch.length > 0) {
      try {
        fetchedItems = await this.fetchItemsByIds(keysToFetch, this.abortController.signal);
      } catch (error) {
        DebuggerConsole.error('Failed to fetch batch of cached resources');
      }
    }

    Promise.all(
      nextTasks.map(async ({ key, callbacks }) => {
        const nextItem = existingItems[key] || fetchedItems?.[key];
        if (nextItem && !existingItems[key]) {
          this.updateCache(key, nextItem);
        }
        callbacks.forEach((callback) => callback(key, nextItem));
        await this.currentlyHandledTasks.run(key, nextItem);
      }),
    );
  }
}
