import FilterableProperty from 'global/api/controller/utils/filtering/FilterableProperty';
import { BaseFilterCondition, FilterFieldType } from 'global/api/controller/utils/filtering/FilterCondition';

interface StringFilterCondition<FieldNameType extends FilterableProperty>
  extends BaseFilterCondition<FieldNameType, string> {
  fieldType: FilterFieldType.STRING;
  operator: StringFilterOperator;
}

export enum StringFilterOperator {
  CONTAINS = 'CONTAINS',
}

const operatorLabels: Record<StringFilterOperator, string> = {
  [StringFilterOperator.CONTAINS]: 'contains',
};
export function getStringFilterOperatorLabel(operator: StringFilterOperator) {
  return operatorLabels[operator];
}

export default StringFilterCondition;
