import React from 'react';

import Badge from 'common-ui-components/Badge';
import Size from 'global/lists/Size';
import useTenantContext from 'screens/platform/cross-platform-components/context/tenant/TenantContext';

import commonStyle from 'common-ui-components/Badge/commonStyle.module.scss';

interface Props {
  department: string;
  size?: Size.SMALL | Size.MEDIUM | Size.BIG;
}

export default function DepartmentBadge({ department, size = Size.MEDIUM }: Props) {
  const { getDepartmentColor } = useTenantContext();
  const departmentColor = getDepartmentColor(department);
  return (
    <Badge
      text={department}
      color={departmentColor}
      className={commonStyle.badge}
      shouldFixLongText={false}
      size={size}
    />
  );
}
