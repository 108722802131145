import classNames from 'classnames';
import React, { FC, ReactNode } from 'react';

import Spinner from 'common-ui-components/Spinner';

import style from 'common-ui-components/Switch/style.module.scss';

interface Props extends Omit<React.HTMLProps<HTMLButtonElement>, 'onChange'> {
  active: boolean;
  onChange?: (value: boolean) => void;
  title?: string;
  disabled?: boolean;
  loading?: boolean;
  color?: 'blue' | 'yellow';
  customContent?: ReactNode;
  ballIcon?: string;
}

const Switch: FC<Props> = ({
  active,
  onChange,
  title,
  disabled = false,
  loading = false,
  color = 'blue',
  customContent,
  ballIcon = undefined,
  ...buttonProps
}) => {
  const className = classNames(style.switchWrapper, {
    [style.selected]: active,
    [style.disabled]: disabled,
    [style.loading]: loading,
    [style.customContent]: customContent !== undefined,
    [style.yellow]: color === 'yellow',
  });

  return (
    <button
      {...buttonProps}
      type="button"
      className={className}
      onClick={() => {
        if (!disabled && onChange) {
          onChange(!active);
        }
      }}
      disabled={disabled}
      title={title}
    >
      {customContent && (
        <span className={style.insideText}>
          {customContent}
        </span>
      )}
      <div className={classNames(style.switchBall, ballIcon && style.transparentContent)}>
        {loading
          ? <div className={style.spinner} data-testid="switch-loading"><Spinner color={active ? 'blue' : 'gray'} /></div>
          : ballIcon && <img className={style.customIcon} src={ballIcon} alt="" />}
      </div>
    </button>
  );
};

export default Switch;
