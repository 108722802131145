import React, { useMemo } from 'react';

import TagBadge from 'common-ui-components/Badge/TagBadge';
import ExpandableCell from 'common-ui-components/Table/ExpandableCell';
import { Atom } from 'model/Atom';
import { TagColors } from 'screens/platform/cross-platform-components/context/metadata/hooks/TagsColorsHook';
import { useMetadataContext } from 'screens/platform/cross-platform-components/context/metadata/MetadataContext';

type Props = {
  tags: Atom['tags'];
};

export default function InteractionCategories({ tags }: Props) {
  const { getTagColors, tagsToCategories } = useMetadataContext();

  const categoriesToColors = useMemo(
    () =>
      Object.keys(tags).reduce((acc, tag) => {
        // Product decision is to use same badge design for tags and categories for now
        // That's why we are using getTagColors for a category
        tagsToCategories[tag]?.forEach((category) => {
          acc[category] = getTagColors(tag);
        });
        return acc;
      }, {} as Record<string, TagColors>),
    [tags, tagsToCategories],
  );

  return Object.keys(categoriesToColors).length > 0 ? (
    <ExpandableCell>
      {Object.entries(categoriesToColors).map(([category, colors]) => (
        <TagBadge key={category} tag={category} colors={colors} clickable={false} />
      ))}
    </ExpandableCell>
  ) : null;
}
