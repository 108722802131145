import classNames from 'classnames';
import React, { ReactNode } from 'react';

import Tooltip from 'common-ui-components/Tooltip';

import style from 'screens/platform/cross-platform-components/HierarchicalSearchInput/components/ItemListing/style.module.scss';

interface Props {
  content: ReactNode;
  disabled?: boolean;
  interactive?: boolean;
  children: ReactNode;
}

export default function CheckboxTooltip({
  content,
  disabled = false,
  interactive = false,
  children,
}: Props) {
  return (
    <Tooltip
      placement="right"
      content={(
        <span className={style.tooltip}>
          {content}
        </span>
      )}
      interactive={interactive}
      hideOnClick={!interactive}
    >
      <div className={classNames(disabled && style.notAllowed)}>
        {children}
      </div>
    </Tooltip>
  );
}
