import React from 'react';

import ErrorMessage from 'screens/platform/cross-platform-components/ErrorMessage';

interface Props {
  className?: string;
}

export default function NetworkErrorMessage({ className }: Props) {
  return (
    <ErrorMessage
      heading="Seems like there's something wrong."
      subtitle="But we believe in 2nd chances, please try again later."
      className={className}
    />
  );
}
