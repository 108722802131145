import { useCallback, useEffect } from 'react';

import { SorterResult } from 'antd/lib/table/interface';
import useSorting, { SortingConfig } from 'common-ui-components/Table/sortingHook';
import { Atom } from 'model/Atom';
import useMaxAtomsCount from 'screens/platform/cross-platform-components/context/platform/MaxAtomsConfigHook';
import useColumnsConfig, {
  defaultSorting,
} from 'screens/platform/cross-platform-components/Interactions/components/InteractionsTable/columnsConfigUtils';
import InteractionsTableColumn
  from 'screens/platform/cross-platform-components/Interactions/components/InteractionsTable/InteractionsTableColumn';
import InteractionsCountValidator
  from 'screens/platform/cross-platform-components/Interactions/InteractionsCountValidator';
import {
  InteractionsTableState,
} from 'screens/platform/cross-platform-components/Panels/BottomPanel/CollapsibleInteractionsDrawer';
import { useMountedState } from 'utils/hooks';
import { StorageKeySorting } from 'utils/LocalStorageUtils';

interface InteractionsTableConfig {
  openState: InteractionsTableState;
  setOpenState: (next: InteractionsTableState, expectedInteractionCount: number | null) => void;
  sorting: SortingConfig<string> | undefined;
  setSorting: (next: SorterResult<Atom>) => void;
  sortByColumn: (columnToSortBy: InteractionsTableColumn) => void;
  limit: number | null;
  setLimit: (nextLimit: number | null) => void;
  maxAtomsThreshold: number | undefined;
}

export default function useInteractionsTableConfig(): InteractionsTableConfig {
  const [openState, setOpenState] = useMountedState(InteractionsTableState.CLOSED);

  const [limit, setLimit] = useMountedState<number | null>(null);
  const [sorting, setSorting] = useSorting<string>(
    defaultSorting,
    StorageKeySorting.INTERACTION_TABLE_SORTING,
  );
  const maxAtomsThreshold = useMaxAtomsCount();
  const allInteractionsTableColumns = useColumnsConfig();

  const sortByColumn = useCallback((interactionsTableColumn: InteractionsTableColumn) => {
    const config = allInteractionsTableColumns[interactionsTableColumn];
    if (config) {
      setSorting({
        columnKey: config.key,
        order: 'descend',
      });
    }
  }, [setSorting]);

  useEffect(() => {
    if (openState === InteractionsTableState.CLOSED) {
      setLimit(null);
    }
  }, [openState]);

  const setOpenStateAndConfirmInteractionsCount = useCallback((
    nextOpenState: InteractionsTableState,
    expectedInteractionsCount: number | null,
  ) => {
    setOpenState(nextOpenState);
    InteractionsCountValidator.setExpectedInteractionsCount(expectedInteractionsCount);
  }, [setOpenState]);

  return {
    openState,
    setOpenState: setOpenStateAndConfirmInteractionsCount,
    sorting,
    setSorting,
    sortByColumn,
    limit,
    setLimit,
    maxAtomsThreshold,
  };
}
