export default class PersonMetadata {
  readonly department: string;
  private readonly teams: string[] | null;

  constructor(department: string, teams: string[] | null) {
    this.department = department;
    this.teams = teams;
  }

  getTeams(): string[] | null { // null means no teams are defined
    return this.teams === null || this.teams.length === 0
      ? null
      : this.teams;
  }
}
