import { SortingConfig } from 'common-ui-components/Table/sortingHook';
import DebuggerConsole from 'utils/DebuggerConsole';

export type RequestPaging = { skip: number; limit: number };

export type RequestSorting = {
  sortField: string;
  sortOrder: 'asc' | 'desc';
}

const antSortOrderToRequestSortOrder: Record<
  SortingConfig['order'],
  RequestSorting['sortOrder']
  > = {
    ascend: 'asc',
    descend: 'desc',
  };

export function convertSortingConfig<T>(
  sorting: SortingConfig<T>,
): RequestSorting {
  return {
    sortField: sorting.columnKey.toString(),
    sortOrder: antSortOrderToRequestSortOrder[sorting.order],
  };
}

export function convertCustomSortingConfig<T extends Object>(
  sorting: SortingConfig<T>,
): Pick<RequestSorting, 'sortOrder'> & T {
  const config = convertSortingConfig(sorting);

  if (!sorting.customConfig) {
    DebuggerConsole.error('Trying to convert custom SortingConfig with undefined customConfig', sorting);
  }

  return {
    sortOrder: config.sortOrder,
    ...sorting.customConfig!,
  };
}
