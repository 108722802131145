import classNames from 'classnames';
import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';

import Dropdown from 'common-ui-components/Dropdown';
import useKeyboardNavigation from 'common-ui-components/Dropdown/keyboardNavigationHook';
import SearchInput from 'common-ui-components/SearchInput';
import Spinner from 'common-ui-components/Spinner';
import { ListOptions } from 'global/ListOptions';
import { useDebounce } from 'utils/hooks';

import 'common-ui-components/SearchableSelectionDropdown/style.scss';

interface SearchableSelectionDropdownProps<T> {
  options: null | ListOptions<T>;
  selectedValue: T;
  selectValue: (value: T) => void;
  onSearchTermChange?: (searchTerm: string) => void;
  searchPlaceholder?: string;
  emptyText?: string;
  searchInputClassName?: string;
  dropdownClassName?: string;
  dropdownOpenOnDefault?: boolean;
  onDropdownClose?: () => void;
  transparentSearchInput?: boolean;
  acceptCustomValues?: boolean;
}

export default function SearchableSelectionDropdown<T>({
  options,
  selectedValue,
  selectValue,
  onSearchTermChange,
  searchPlaceholder,
  emptyText,
  searchInputClassName: className,
  dropdownClassName,
  dropdownOpenOnDefault = false,
  onDropdownClose = () => null,
  transparentSearchInput,
  acceptCustomValues = false,
}: SearchableSelectionDropdownProps<T>) {
  const [visibleOptions, setVisibleOptions] = useState<
    { value: T; label?: string }[]
  >(options || []);
  const [isDropdownOpen, setIsDropdownOpen] = useState(dropdownOpenOnDefault);
  const [searchQuery, setSearchQuery] = useState('');
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const isFirstRender = useRef(true);

  const selectedLabel = useMemo(() => {
    const option = options?.find(({ value }) => value === selectedValue);
    return option?.label || (option?.value as unknown as string);
  }, [selectedValue]);

  useEffect(() => {
    if (isDropdownOpen) {
      setVisibleOptions(
        (options || []).filter((option) => {
          const label = option.label || option.value;
          return (label as string)
            .toLowerCase()
            .includes(searchQuery.toLowerCase());
        }),
      );
    }
    if (acceptCustomValues) {
      selectValue(searchQuery as any);
    }
  }, [searchQuery, options]);

  const debauncedSearchTerm = useDebounce(searchQuery);

  useEffect(() => {
    if (onSearchTermChange) {
      onSearchTermChange(searchQuery);
    }
  }, [debauncedSearchTerm]);

  useEffect(() => {
    if (!dropdownOpenOnDefault && isFirstRender.current) {
      setSearchQuery(selectedLabel || '');
      isFirstRender.current = false;
    }
  }, [selectedLabel]);

  useKeyboardNavigation({
    isDropdownOpen,
    menuElement: containerRef,
    closeDropdown: () => setIsDropdownOpen(false),
    selectedOptionClassName: 'highlightedOption',
    inputRef,
  });

  return (
    <div className="searchable-selection-dropdown">
      <Dropdown
        toggleButtonElement={(
          <SearchInput
            value={selectedLabel}
            setValue={setSearchQuery}
            placeholder={
              selectedLabel || searchPlaceholder || 'Search...'
            }
            onFocus={() => setIsDropdownOpen(true)}
            ref={inputRef}
            transparent={transparentSearchInput}
            className={className}
          />
        )}
        dropdownClassName={classNames(!options && 'loading', dropdownClassName)}
        onDropdownClose={() => {
          if (!acceptCustomValues) {
            setSearchQuery('');
            setVisibleOptions(options || []);
          }
          inputRef.current?.blur();
        }}
        isDropdownOpen={isDropdownOpen}
        closeDropdown={() => {
          setIsDropdownOpen(false);
          onDropdownClose();
        }}
        placement="bottom-start"
        strategy="fixed"
      >
        <div ref={containerRef}>
          {options && options.length === 0 && (
            <span className="no-options">{emptyText || 'No options'}</span>
          )}
          {options === null ? (
            <div className="spinner">
              <Spinner />
            </div>
          ) : (
            options.length > 0
            && visibleOptions.map((option) => (
              <span
                key={option.value as unknown as string}
                className="option"
                onClick={() => {
                  selectValue(option.value);
                  setIsDropdownOpen(false);
                }}
              >
                {option.label || option.value}
              </span>
            ))
          )}
        </div>
      </Dropdown>
    </div>
  );
}
