import { useMemo } from 'react';
import { useAsync } from 'react-use';

import TagsSetController from 'global/api/controller/TagsSetController';
import { DateFilterOperator } from 'global/api/controller/utils/filtering/DateFilterCondition';
import { FilterFieldType } from 'global/api/controller/utils/filtering/FilterCondition';
import { IdFilterOperator } from 'global/api/controller/utils/filtering/IdFilterCondition';
import TagFilterableProperty from 'global/api/controller/utils/filtering/TagFilterableProperty';
import { ListOptions } from 'global/ListOptions';
import { UNASSOCIATED_CATEGORY } from 'model/Tenant';
import { useMetadataContext } from 'screens/platform/cross-platform-components/context/metadata/MetadataContext';
import useTenantContext from 'screens/platform/cross-platform-components/context/tenant/TenantContext';
import FilterConfig, {
  DynamicListOptions,
  FiltersConfig,
} from 'screens/platform/directory/components/GenericDirectoryScreen/FiltersList/FilterConfig';
import {
  TopicsDirectoryTableColumnId,
} from 'screens/platform/directory/topics/TopicsDirectoryScreen/TopicsTable/utils/TopicsDirectoryTableColumn';
import DirectoryTimePeriod, {
  dateRangeFromOptions,
  getFromDateByTimePeriod,
} from 'screens/platform/directory/utils/DirectoryTimePeriod';
import StringUtils from 'utils/StringUtils';

export type TopicFilterableColumnId =
  | TopicsDirectoryTableColumnId.TOPIC
  | TopicsDirectoryTableColumnId.KEYWORDS
  | TopicsDirectoryTableColumnId.LAST_ACTIVITY
  | TopicsDirectoryTableColumnId.CATEGORIES;

export type TopicsFilterValues = Partial<{
  [TopicsDirectoryTableColumnId.LAST_ACTIVITY]: DirectoryTimePeriod;
  [TopicsDirectoryTableColumnId.CATEGORIES]: string[];
}>;

const timePeriodFilterConfig: FilterConfig<DirectoryTimePeriod> = {
  label: 'Timespan',
  options: new DynamicListOptions(dateRangeFromOptions),
  multiSelect: false,
  removable: false,
  defaultValue: DirectoryTimePeriod.LAST_3_MONTHS,
  toFilterCondition: (value) => ({
    // Because we have only one TagActivityType,
    // we can hardcode the fieldName to LAST_INTERACTION
    // If we add more TagActivityType, we must refactor this filter condition here
    fieldName: TagFilterableProperty.LAST_INTERACTION,
    fieldType: FilterFieldType.DATE,
    operator: DateFilterOperator.IS_AFTER,
    value: getFromDateByTimePeriod(value),
  }),
};

const useCategoriesFilterConfig = (): FilterConfig<string> => {
  const { categories } = useMetadataContext();

  const categoriesOptions: ListOptions<string> = useMemo(
    () =>
      Object.keys(categories).map((category) => ({
        value: category,
        label: category,
      })),
    [categories],
  );

  return useMemo(
    () => ({
      label: 'Categories',
      options: new DynamicListOptions(categoriesOptions),
      multiSelect: true,
      removable: true,
      defaultValue: [],
      toFilterCondition: (value) => ({
        fieldName: TagFilterableProperty.CATEGORIES,
        fieldType: FilterFieldType.ID,
        operator: IdFilterOperator.IS_ANY_OF,
        value: value.map((category) =>
          (category === UNASSOCIATED_CATEGORY ? null : category)),
      }),
    }),
    [categoriesOptions],
  );
};

const useKeywordsFilterConfig = (): FilterConfig<string> => {
  const {
    tenant: { id: tenantId },
  } = useTenantContext();
  const { value: keywordsOptions } = useAsync(async () => {
    const response = await TagsSetController.getTagsData(tenantId);
    const uniqueKeywords = new Set(response?.data.flatMap((t) => t.keywords));
    return Array.from(uniqueKeywords)
      .sort(StringUtils.alphabeticalDescendingSorter)
      .map((keyword) => ({
        value: keyword,
        label: keyword,
      }));
  });

  return useMemo(
    () => ({
      label: 'Keywords',
      options: new DynamicListOptions(keywordsOptions || []),
      multiSelect: true,
      removable: true,
      defaultValue: [],
      toFilterCondition: (value) => ({
        fieldName: TagFilterableProperty.KEYWORDS,
        fieldType: FilterFieldType.ID,
        operator: IdFilterOperator.IS_ANY_OF,
        value,
      }),
    }),
    [keywordsOptions],
  );
};

export const useTopicValueFilterConfig = (hiddenOption?: string): FilterConfig<string> => {
  const { tagsToCategories } = useMetadataContext();

  const tagsOptions = Object.keys(tagsToCategories)
    .sort(StringUtils.alphabeticalDescendingSorter)
    .filter((value) => value !== hiddenOption)
    .map((t) => ({
      label: t,
      value: t,
    }));

  return useMemo(
    () => ({
      label: 'Topic',
      options: new DynamicListOptions(tagsOptions),
      multiSelect: true,
      removable: true,
      defaultValue: [],
      toFilterCondition: (value) => ({
        fieldName: TagFilterableProperty.VALUE,
        fieldType: FilterFieldType.ID,
        operator: IdFilterOperator.IS_ANY_OF,
        value,
      }),
    }),
    [tagsToCategories],
  );
};

const useTopicsFiltersConfig = (): FiltersConfig<TopicFilterableColumnId> => {
  const categoriesFilterConfig = useCategoriesFilterConfig();
  const keywordsFilterConfig = useKeywordsFilterConfig();
  const topicValueFilterConfig = useTopicValueFilterConfig();

  return useMemo(
    () => ({
      [TopicsDirectoryTableColumnId.TOPIC]: topicValueFilterConfig,
      [TopicsDirectoryTableColumnId.LAST_ACTIVITY]: timePeriodFilterConfig,
      [TopicsDirectoryTableColumnId.CATEGORIES]: categoriesFilterConfig,
      [TopicsDirectoryTableColumnId.KEYWORDS]: keywordsFilterConfig,
    }),
    [categoriesFilterConfig, keywordsFilterConfig, topicValueFilterConfig],
  );
};

export default useTopicsFiltersConfig;
