import classNames from 'classnames';
import React, { ReactNode } from 'react';

import style from 'screens/platform/cross-platform-components/Panels/RightPanel/style.module.scss';

interface Props {
  children: ReactNode;
  className?: string;
}

export default function RightPanel({ children, className }: Props) {
  return (
    <div className={classNames(style.rightPanel, className)}>
      {children}
    </div>
  );
}
