import classNames from 'classnames';
import React, { FC, forwardRef } from 'react';

import Button from 'common-ui-components/Button';

import style from 'common-ui-components/IconButton/style.module.scss';

type Props = {
  Icon: FC;
  title?: string;
  onClick?: () => void;
  className?: string;
};

const IconButton = forwardRef<HTMLButtonElement, Props>(
  ({
    Icon, title, onClick, className,
  }, ref) => (
    <Button
      ref={ref}
      title={title}
      onClick={onClick}
      className={classNames(
        style.iconButton,
        !onClick && style.notClickable,
        className,
      )}
      rounded
    >
      <Icon />
    </Button>
  ),
);

export default IconButton;
