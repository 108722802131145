enum OrganizationFilterableProperty {
  ID = 'ID',
  NAME = 'NAME',
  SIZE = 'SIZE',
  RISK = 'RISK',
  LAST_ENGAGEMENT = 'LAST_ENGAGEMENT',
  LAST_MENTION = 'LAST_MENTION',
  LAST_MODIFICATION = 'LAST_MODIFICATION',
  SEGMENTS = 'SEGMENTS',
  STAGE = 'STAGE',
  OWNERS = 'OWNERS',
  DISPLAY_NAME = 'DISPLAY_NAME'
}

export const organizationFilterablePropertyLabels: Record<
  OrganizationFilterableProperty,
  string
> = {
  [OrganizationFilterableProperty.ID]: 'Organization',
  [OrganizationFilterableProperty.NAME]: 'Name',
  [OrganizationFilterableProperty.SIZE]: 'Size',
  [OrganizationFilterableProperty.RISK]: 'Risk',
  [OrganizationFilterableProperty.LAST_ENGAGEMENT]: 'Last Engagement',
  [OrganizationFilterableProperty.SEGMENTS]: 'Segments',
  [OrganizationFilterableProperty.STAGE]: 'Stage',
  [OrganizationFilterableProperty.LAST_MENTION]: 'Last Mention',
  [OrganizationFilterableProperty.LAST_MODIFICATION]: 'Last Modification',
  [OrganizationFilterableProperty.OWNERS]: 'Owners',
  [OrganizationFilterableProperty.DISPLAY_NAME]: 'Display Name',
};

export type OrganizationFilterablePropertyOfTypeID =
  | OrganizationFilterableProperty.ID
  | OrganizationFilterableProperty.SIZE
  | OrganizationFilterableProperty.RISK;

export default OrganizationFilterableProperty;
