import NullableValuesSelection
  from 'screens/platform/cross-platform-components/context/MasterFiltersContext/NullableValuesSelection';
import HierarchicalGroup from 'utils/HierarchicalDataStructures/HierarchicalGroup';

type Writeable<T> = { -readonly [P in keyof T]: T[P] };

export default {
  fillValues<T>(object: Record<string, any>, value: T): Record<string, T> {
    const ans = {};
    Object.keys(object).forEach((key) => {
      ans[key] = value;
    });
    return ans;
  },

  filterUniqueEntries<T>(objectToFilter: Record<string, T>, existingKeys: Set<string> | string[]):
    Record<string, T> {
    function includes(list: Set<string> | string[], member: string) {
      if (list instanceof Set) return list.has(member);
      return list.includes(member);
    }

    const uniqueMembers = {};
    Object.keys(objectToFilter).forEach((key) => {
      if (!includes(existingKeys, key)) {
        uniqueMembers[key] = objectToFilter[key];
      }
    });
    return uniqueMembers;
  },

  isEmpty(obj: object) {
    return Object.keys(obj).length === 0;
  },

  isNotEmpty(obj: object) {
    return !this.isEmpty(obj);
  },

  deepClone<T extends object>(obj: T): Writeable<T> {
    return Object.entries(obj).reduce((acc, [key, value]) => {
      if (value === null) {
        acc[key] = null;
      } else if (value instanceof Set) {
        acc[key] = new Set([...value]);
      } else if (value instanceof HierarchicalGroup) {
        acc[key] = value.clone();
      } else if (value instanceof NullableValuesSelection) {
        acc[key] = new NullableValuesSelection(value.selected, value.includeUndefined);
      } else if (value instanceof Date) {
        acc[key] = new Date(value.getTime());
      } else if (value instanceof Array) {
        acc[key] = [...value];
      } else if (typeof value === 'object') {
        acc[key] = value.clone ? value.clone() : this.deepClone(value);
      } else {
        acc[key] = value;
      }
      return acc;
    }, {} as typeof obj);
  },

  compareByKeyAlphabetically<T>(key: keyof T, isDescending = true): (a: T, b: T) => number {
    const isNullish = (x) => x === null || x === undefined;
    return (a, b) => {
      const aKey = a[key];
      const bKey = b[key];

      if (isNullish(aKey) && isNullish(bKey)) return 0;
      if (isNullish(aKey)) return isDescending ? -1 : 1;
      if (isNullish(bKey)) return isDescending ? 1 : -1;

      const collator = new Intl.Collator('en', { numeric: true, sensitivity: 'base' });
      return collator.compare((aKey as any).toString(), (bKey as any)
        .toString()) * (isDescending ? 1 : -1);
    };
  },

  sortByKeys<T>(object: Record<string, T>): Record<string, T> {
    const sortedKeys = Object.keys(object).sort();
    return sortedKeys.reduce((acc, key) => ({
      ...acc,
      [key]: object[key],
    }), {});
  },

  filter<T>(
    object: Record<string, T>,
    filterFunc: (([k, v]: [string, T]) => boolean),
  ): Record<string, T> {
    return Object.entries(object)
      .filter((entry) => filterFunc(entry))
      .reduce((map, [k, v]) => ({ ...map, [k]: v }), {});
  },

  calculateObjectWithFallback<T extends object>(value: Partial<T>, fallback: T): T {
    return Object.keys(fallback).reduce((acc, key) => {
      if (fallback[key] instanceof Object && value[key] instanceof Object) {
        acc[key] = this.calculateObjectWithFallback(value[key], fallback[key]);
      } else if (value[key] !== undefined) {
        acc[key] = value[key];
      }
      return acc;
    }, fallback);
  },

  mapEntries<T, S>(
    object: Record<string, T>,
    mapper: (entry: [string, T]) => [string, S],
  ): Record<string, S> {
    return Object.fromEntries(
      Object.entries(object).map(mapper),
    );
  },

  mapValues<T, S>(
    object: Record<string, T>,
    mapper: (value: T) => S,
  ): Record<string, S> {
    return Object.fromEntries(
      Object.entries(object)
        .map(([key, value]) => [key, mapper(value)]),
    );
  },

  /**
   * Groups a map of objects by each object's field value, or by the map's original keys.
   * If the mapped key is an array, then its element are grouped uniquely.
   * @param objects The map of objects to group.
   * @param groupingKeyMapper A function that maps an object to its key.
   * @param groupedValueMapper A function that maps an object to its value.
   */
  groupByField<T>(
    objects: Record<string, T>,
    groupingKeyMapper: (object: T) => string | string[],
    groupedValueMapper?: (object: T) => string,
  ): Record<string, string[]> {
    type SetsMap = Record<string, Set<string>>;
    const map = Object.entries(objects).reduce<SetsMap>((acc, [originalKey, originalValue]) => {
      const mappedKey = groupingKeyMapper(originalValue);
      const mappedValue = groupedValueMapper ? groupedValueMapper(originalValue) : originalKey;

      const mapKeyToValue = (k, v) => {
        acc[k] = (acc[k] ? acc[k].add(v) : new Set([v]));
      };

      if (Array.isArray(mappedKey)) {
        mappedKey.forEach((mappedKeyElement) => mapKeyToValue(mappedKeyElement, mappedValue));
      } else {
        mapKeyToValue(mappedKey, mappedValue);
      }

      return acc;
    }, {});
    return this.mapEntries(map, ([k, set]) => [k, Array.from(set)]);
  },

  parseEnum<T extends object>(
    enumerator: T,
    value: string | undefined,
    defaultValue: T[keyof T],
  ): T[keyof T] {
    const matchingKey = Object.keys(enumerator).find((key) => value === enumerator[key]);
    return value !== undefined && matchingKey
      ? enumerator[matchingKey]
      : defaultValue;
  },

  equals(a: object | null, b: object | null): boolean {
    if (a === null && b === null) return true;
    if (a === null || b === null) return false;

    if (Object.keys(a).sort().toString() !== Object.keys(b).sort().toString()) return false;
    return Object.keys(a).every((key) => {
      const valueA = a[key];
      const valueB = b[key];

      if ([valueA, valueB].every((x) => x === null)) return true;
      if ([valueA, valueB].every(Array.isArray)) {
        return valueA.sort().toString() === valueB.sort().toString();
      }
      if ([valueA, valueB].every((x) => x instanceof HierarchicalGroup)) {
        return (valueA as HierarchicalGroup).equals(valueB);
      }
      if ([valueA, valueB].every((x) => x instanceof Date)) {
        return (new Date(valueA).toDateString() === new Date(valueB).toDateString());
      }
      if ([valueA, valueB].every((x) => typeof x === 'object')) {
        return this.equals(valueA, valueB);
      }
      return valueA === valueB;
    });
  },

  isObject(val: any): val is object {
    return val instanceof Object;
  },

  pick<T, K extends keyof T>(obj: T, keys: K[]): Pick<T, K> {
    const result = {} as Pick<T, K>;
    keys.forEach((key) => {
      result[key] = obj[key];
    });
    return result;
  },
};
