import {
  AnalyticsWidgetConfig, isGroupType, WidgetType,
} from 'screens/platform/contentScreens/AnalyticsScreen/widgets/widgetConfig';
import DebuggerConsole from 'utils/DebuggerConsole';

const VISIBLE_WIDGET_SEARCH_QUERY = 'visibleWidgets';

export const ANALYTICS_WIDGETS_SEARCH_PARAMS: string[] = [
  VISIBLE_WIDGET_SEARCH_QUERY,
  ...Object.values(WidgetType).map(widgetTypeToUrlParamKey),
];

export type VisibleWidgetConfig = Pick<AnalyticsWidgetConfig, 'isVisible' | 'group'>;
export type WidgetConfigParams = Record<WidgetType, VisibleWidgetConfig>;

export function getUrlParamsWithVisibleWidgets(
  visibleWidgets: WidgetType[],
  initialSearch?: string,
) {
  const resultParams = new URLSearchParams(initialSearch);
  resultParams.set(
    VISIBLE_WIDGET_SEARCH_QUERY,
    visibleWidgets.map(widgetTypeToUrlParam).join(','),
  );
  return resultParams.toString();
}

function widgetTypeToUrlParam(widgetType: WidgetType): string {
  return String(widgetType.toLowerCase());
}

function urlParamToWidgetType(urlParam: string): WidgetType {
  return WidgetType[urlParam.toUpperCase()];
}

function widgetTypeToUrlParamKey(widgetType: WidgetType): string {
  return `${widgetType.toLowerCase()}Widget`;
}

function parseUrlParams(urlParams: URLSearchParams): Partial<WidgetConfigParams> {
  const config: Partial<WidgetConfigParams> = {};
  const visibleWidgets = getVisibleWidgets(urlParams);

  if (!visibleWidgets) return {};

  Object.values(WidgetType).forEach((widgetType) => {
    const isVisible = visibleWidgets.includes(widgetType);
    const groupUrlParam = widgetTypeToUrlParamKey(widgetType);
    const group = urlParams.get(groupUrlParam);
    config[widgetType] = {
      isVisible,
      group: isGroupType(group) ? group : null,
    };
  });

  DebuggerConsole.log('parsed config', config, 'from url params with visible widgets', visibleWidgets);
  return config;
}

export function configFromUrlParams(search: string): Partial<WidgetConfigParams> | undefined {
  if (!search.includes(`${VISIBLE_WIDGET_SEARCH_QUERY}=`)) return undefined;
  const currentUrlParams = new URLSearchParams(search);
  return parseUrlParams(currentUrlParams);
}

export const clearConfigFromUrlParams = (search: string): string => {
  const urlParams = new URLSearchParams(search);

  ANALYTICS_WIDGETS_SEARCH_PARAMS.forEach((widget) => {
    if (urlParams.has(widget)) {
      urlParams.delete(widget);
    }
  });

  DebuggerConsole.log('URL PARAMS: reset search params', search);
  return urlParams.toString();
};

export function generateUrlParamsFromConfig(config: WidgetConfigParams, search: string): string {
  const visibleWidgetTypes = filterWidgitsByVisibility(config, true);

  const urlParams = new URLSearchParams(search);

  if (visibleWidgetTypes.length > 0) {
    urlParams.set(VISIBLE_WIDGET_SEARCH_QUERY, visibleWidgetTypes.map(widgetTypeToUrlParam).join(','));

    visibleWidgetTypes.forEach(addWidgetGroupToUrl(config, urlParams));
  } else {
    urlParams.delete(VISIBLE_WIDGET_SEARCH_QUERY);
  }

  cleanUpHiddenWidgetGroupParams(config, urlParams);

  DebuggerConsole.log('URL PARAMS: generate url params for widgets', visibleWidgetTypes, 'from config', config);
  return urlParams.toString();
}

function addWidgetGroupToUrl(config: WidgetConfigParams, urlParams: URLSearchParams) {
  return (widgetType: WidgetType) => {
    const widgetConfig = config[widgetType];
    if (widgetConfig && widgetConfig.group !== undefined && widgetConfig.group !== null) {
      const groupUrlParam = widgetTypeToUrlParamKey(widgetType);
      urlParams.set(groupUrlParam, widgetConfig.group.toString());
    }
  };
}

function filterWidgitsByVisibility(config: WidgetConfigParams, isVisible: boolean) {
  return Object.entries(config)
    .filter(([_, widgetConfig]) => widgetConfig.isVisible === isVisible)
    .map(([widgetType]) => widgetType as WidgetType);
}

function cleanUpHiddenWidgetGroupParams(config: WidgetConfigParams, urlParams: URLSearchParams) {
  const hiddenWidgets = filterWidgitsByVisibility(config, false);

  hiddenWidgets.forEach((widgetType) => {
    const groupUrlParam = widgetTypeToUrlParamKey(widgetType);
    urlParams.delete(groupUrlParam);
  });
}

function getVisibleWidgets(searchParams: URLSearchParams): WidgetType[] {
  const isVisibleParam = searchParams.get(VISIBLE_WIDGET_SEARCH_QUERY);
  if (isVisibleParam) {
    return isVisibleParam.split(',').map((widgetParam) => urlParamToWidgetType(widgetParam));
  }
  return [];
}
