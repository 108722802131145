import { ReactNode } from 'react';

import { useCustomReducer } from 'utils/hooks';

export interface GlobalModalReducerState {
  isOpen: boolean;
  title?: string;
  content: ReactNode;
  actionButton?: {
    text: string;
    onClickAction: () => void;
  };
  preventInteractionsDrawerClosing?: boolean;
  titleSize?: 'small' | 'medium';
}

export type OpenGlobalModalArgs = Omit<GlobalModalReducerState, 'isOpen'>;

export type GlobalModalReducerAction =
| { type: 'OPEN_GLOBAL_MODAL'; payload: OpenGlobalModalArgs }
| { type: 'CLOSE_GLOBAL_MODAL' }

function GlobalModalReducer(
  state: GlobalModalReducerState,
  action: GlobalModalReducerAction,
): GlobalModalReducerState {
  switch (action.type) {
    case 'OPEN_GLOBAL_MODAL': return { isOpen: true, ...action.payload };
    case 'CLOSE_GLOBAL_MODAL': return { ...state, isOpen: false, preventInteractionsDrawerClosing: false };
    default: return state;
  }
}

export default function useGlobalModalReducer() {
  const initialState: GlobalModalReducerState = {
    isOpen: false,
    title: '',
    content: '',
    preventInteractionsDrawerClosing: false,
  };
  return useCustomReducer(
    GlobalModalReducer,
    initialState,
  );
}
