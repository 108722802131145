import React, { useCallback, useState } from 'react';

import Spinner from 'common-ui-components/Spinner';
import { GroupedPersons } from 'global/api/controller/PersonController';
import Api from 'global/api/platformApi';
import { HttpResponse } from 'global/api/platformApiHelpers';
import {
  NullableValuesSelectionConstants,
} from 'screens/platform/cross-platform-components/context/MasterFiltersContext/NullableValuesSelection';
import PeopleMasterFilterHierarchyGroup
  from 'screens/platform/cross-platform-components/context/MasterFiltersContext/PeopleMasterFilter/PeopleMasterFilterHierarchyGroup';
import useTenantContext from 'screens/platform/cross-platform-components/context/tenant/TenantContext';
import useHierarchicalSearchInput
  from 'screens/platform/cross-platform-components/HierarchicalSearchInput/HierarchicalSearchInputHook';
import HierarchicalSearchInputWrapper from 'screens/platform/cross-platform-components/HierarchicalSearchInput/HierarchicalSearchInputWrapper';
import ColumnsHierarchicalSearchInputWrapper
  from 'screens/platform/cross-platform-components/MasterFiltersPanel/FilterSection/ColumnsHierarchicalSearchInputWrapper/index';
import PeopleHierarchicalSearchResults
  from 'screens/platform/cross-platform-components/MasterFiltersPanel/FilterSection/PeopleFilterSection/components/PeopleHierarchicalSearchResults';
import { useAsyncFetchingByQuery } from 'utils/hooks';

interface Props {
  selection: PeopleMasterFilterHierarchyGroup;
  onClickDone: () => void;
  searchPlaceholder?: string;
  className?: string;
  shouldClearSearchInput?: boolean;
  allowEmptySelection?: boolean;
  showDepartmentsOnly?: boolean;
}

const {
  UNDEFINED_PROPERTY,
  UNDEFINED_PROPERTY_LABEL,
} = NullableValuesSelectionConstants;

export default function PeopleHierarchicalSearchInput({
  selection,
  searchPlaceholder,
  onClickDone,
  className,
  shouldClearSearchInput,
  allowEmptySelection = false,
  showDepartmentsOnly = false,
}: Props) {
  const [searchQuery, setSearchQuery] = useState('');
  const [itemsByGroups, setItemsByGroups] = useState<null | GroupedPersons>(null);
  const { tenant } = useTenantContext();

  const {
    isDisabled, emptySelectionHandler, searchInputRef,
  } = useHierarchicalSearchInput({
    isSelectionEmpty: selection.isNotSelected(),
    allowEmptySelection,
    shouldClearSearchInput,
    setSearchQuery,
  });

  const peopleQuery = useCallback(async (query): HttpResponse<GroupedPersons> => {
    const shouldIncludeUndefined = UNDEFINED_PROPERTY_LABEL
      .toLowerCase()
      .includes(query.toLowerCase());
    const additionalArray = shouldIncludeUndefined ? [UNDEFINED_PROPERTY] : [];

    const result = await Api.Person.getPeopleByQuery(tenant.id, { query });
    if (result?.data) {
      return {
        ...result,
        data: {
          ...result.data,
          teams: [...result.data.teams, ...additionalArray],
        },
      };
    }
    return result;
  }, [tenant.id]);

  useAsyncFetchingByQuery(
    searchQuery,
    setItemsByGroups,
    peopleQuery,
  );

  const isLoading = itemsByGroups === null;
  const WrapperComponent = showDepartmentsOnly
    ? HierarchicalSearchInputWrapper
    : ColumnsHierarchicalSearchInputWrapper;

  return (
    <WrapperComponent
      onClickDone={onClickDone}
      setSearchQuery={setSearchQuery}
      searchPlaceholder={searchPlaceholder}
      disabled={isDisabled}
      className={className}
      ref={searchInputRef}
      scrollable={showDepartmentsOnly}
    >
      {isLoading ? <Spinner /> : (
        <PeopleHierarchicalSearchResults
          visibleItems={itemsByGroups}
          selection={selection}
          handleEmptySelectionChange={emptySelectionHandler}
          showDepartmentsOnly={showDepartmentsOnly}
        />
      )}
    </WrapperComponent>
  );
}
