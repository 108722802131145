import React, { Dispatch, ReactNode, SetStateAction } from 'react';

import Dropdown from 'common-ui-components/Dropdown';
import SettingsButton from 'common-ui-components/SettingsPopover/SettingsButton';
import {
  SimpleSettingsPopoverProps,
} from 'common-ui-components/SettingsPopover/SimpleSettingsPopover';

import style from 'common-ui-components/SettingsPopover/style.module.scss';

interface Props extends SimpleSettingsPopoverProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  buttonClassName?: string;
  children: ReactNode;
}

export default function SettingsPopover({
  isOpen,
  setIsOpen,
  buttonClassName,
  children,
}: Props) {
  const togglePopover = () => setIsOpen((prev) => !prev);

  return (
    <Dropdown
      isDropdownOpen={isOpen}
      closeDropdown={togglePopover}
      placement="left-start"
      dropdownClassName={style.popoverContent}
      toggleButtonElement={(
        <SettingsButton
          onClick={togglePopover}
          className={buttonClassName}
        />
      )}
    >
      {children}
    </Dropdown>
  );
}
