import React, { ReactElement, Suspense } from 'react';
import { RouteProps } from 'react-router-dom';

import Role from 'global/lists/Role';
import ProtectedRoute from 'routers/ProtectedRoute';
import { WithRequired } from 'utils/TypescriptTricks';

type LazyRouteProps = WithRequired<Omit<RouteProps, 'component'>, 'path'> & {
  component: ReactElement;
  minimumRole?: Role;
}

export default function LazyRoute(props: LazyRouteProps) {
  const {
    component, minimumRole = Role.USER, ...inheritedProps
  } = props;
  return (
    <ProtectedRoute
      minimumRole={minimumRole}
      render={() => (
        <Suspense fallback={null}>
          {component}
        </Suspense>
      )}
      {...inheritedProps}
    />
  );
}
