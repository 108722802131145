import classNames from 'classnames';
import React, { useEffect, useMemo, useRef } from 'react';

import ItemsByGroup from 'global/ItemsByGroup';
import CollapsibleGroup
  from 'screens/platform/cross-platform-components/HierarchicalSearchInput/components/HierarchicalSearchResults/CollapsibleGroup';
import useCollapsibleGroupSelectionStatus
  from 'screens/platform/cross-platform-components/HierarchicalSearchInput/components/HierarchicalSearchResults/CollapsibleGroup/CollapsibleGroupSelectionStatusHook';
import DirectionalityCollapsibleItems
  from 'screens/platform/cross-platform-components/HierarchicalSearchInput/components/HierarchicalSearchResults/DirectionalityCollapsibleItems';
import HierarchicalSearchResultsUtils
  from 'screens/platform/cross-platform-components/HierarchicalSearchInput/components/HierarchicalSearchResults/HierarchicalSearchResultsUtils';
import RootGroupTitleRow
  from 'screens/platform/cross-platform-components/HierarchicalSearchInput/components/HierarchicalSearchResults/RootCollapsibleGroup/RootGroupTitleRow';
import {
  HierarchicalItemType,
} from 'screens/platform/cross-platform-components/HierarchicalSearchInput/HierarchicalItemTypeConfig';
import {
  SlackSubDivisionId,
} from 'screens/platform/cross-platform-components/MasterFiltersPanel/masterFiltersUtils/SlackChannelsUtils';
import DebuggerConsole from 'utils/DebuggerConsole';
import HierarchicalGroup from 'utils/HierarchicalDataStructures/HierarchicalGroup';
import { useToggle } from 'utils/hooks';

import style from 'screens/platform/cross-platform-components/HierarchicalSearchInput/style.module.scss';

interface HierarchicalSearchResultsProps {
  items: ItemsByGroup[];
  selection: HierarchicalGroup;
  type: Exclude<HierarchicalItemType, HierarchicalItemType.PERSON>;
  handleEmptySelectionChange?: (isEmpty: boolean) => void;
  initiallyOpen: boolean | { root: boolean; children: boolean };
  showEmptyGroupsAsCollapsible?: boolean;
  showChildrenCount?: boolean;
}

export default function HierarchicalSearchResults({
  items,
  selection,
  handleEmptySelectionChange,
  type,
  initiallyOpen,
  showEmptyGroupsAsCollapsible = true,
  showChildrenCount = true,
}: HierarchicalSearchResultsProps) {
  const {
    isRootInitiallyOpen,
    areChildrenInitiallyOpen,
  } = HierarchicalSearchResultsUtils.parseInitiallyOpen(initiallyOpen);

  const [isOpen, toggleIsOpen] = useToggle(isRootInitiallyOpen);
  const isInitialRender = useRef(true);

  const customToggleAll = useMemo<(() => void) | undefined>(() => {
    switch (type) {
      case HierarchicalItemType.CHANNEL: return () => {
        if (selection.isNotSelected()) {
          selection.select();
          const botChannels = selection.getChild(SlackSubDivisionId.SLACK_BOT_CHANNELS);
          botChannels?.deselect();
        } else selection.deselect();
      };
      default: return undefined;
    }
  }, [type, selection]);

  const {
    selectionStatus,
    updateSelectionStatus,
    toggleAll,
  } = useCollapsibleGroupSelectionStatus({
    group: selection,
    customToggleAll,
  });

  useEffect(() => {
    if (!isInitialRender.current && handleEmptySelectionChange) {
      handleEmptySelectionChange(selection.isNotSelected());
    }
    isInitialRender.current = false;
  }, [selectionStatus]);

  return (
    <div className={style.collapsibleRootGroup}>
      <RootGroupTitleRow
        isOpen={isOpen}
        toggleIsOpen={toggleIsOpen}
        selection={selection}
        label={selection.id}
        onToggle={(_id) => toggleAll()}
      />
      <div
        className={classNames(style.collapsibleItemsSection, !isOpen && style.close)}
      >
        {
          type === HierarchicalItemType.DIRECTIONALITY
            ? (
              <DirectionalityCollapsibleItems
                selection={selection}
                isOpen={isOpen}
                updateParentSelectionStatus={updateSelectionStatus}
              />
            )
            : items.map(({ group: groupId, items: nestedItems }) => {
              const group = selection.getChild(groupId);

              const isValidGroup = group !== undefined && group instanceof HierarchicalGroup;
              if (!isValidGroup) {
                DebuggerConsole.error('Tried to render an invalid group in CollapsibleGroup', { groupId, group });
                return null;
              }

              return (
                <CollapsibleGroup
                  key={groupId}
                  parentGroupSelection={selectionStatus}
                  updateParentSelectionStatus={updateSelectionStatus}
                  group={group}
                  visibleItemsIds={nestedItems}
                  open={areChildrenInitiallyOpen}
                  showEmptyGroupAsCollapsible={showEmptyGroupsAsCollapsible}
                  showGroupSize={showChildrenCount}
                  type={type}
                />
              );
            })
        }
      </div>
    </div>
  );
}
